import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { BedBlockStrength } from '@sqior/viewmodels/location';
import { LocationEntities, LocationInterfaces } from './location-definitions';

/* Bed ID entity */

export type BedIdEntity = Entity & { id: string };
export const BedIdModel: EntityModel = {
  type: LocationEntities.BedId,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};
export function BedId(id: string): BedIdEntity {
  return { entityType: LocationEntities.BedId, id: id };
}

/* Type for beds container */

export type BedsEntity = Entity & { beds: Entity[] };

/* Reasons for blocked beds */

export enum BedBlockCause {
  Construction = 'construction',
  Unserviced = 'unserviced',
  Disturbance = 'disturbance',
  Unknown = 'blocked',
  Medical = 'medical',
  MedicalSocial = 'medical-social',
  PrivateInsurance = 'private',
  Isolation = 'isolation',
}
export type BedBlockEntity = Entity & { cause: string; start?: Entity; plannedEnd?: Entity };
export const BedBlockModel: EntityModel = {
  type: LocationEntities.BedBlock,
  props: ['cause', 'start', 'plannedEnd'],
  keys: ['cause'],
  unclassified: true,
};
export function makeBedBlock(
  cause: string,
  time?: { plannedEnd?: Entity; start?: Entity }
): BedBlockEntity {
  const res: BedBlockEntity = { entityType: LocationEntities.BedBlock, cause: cause };
  if (time?.start) res.start = time.start;
  if (time?.plannedEnd) res.plannedEnd = time.plannedEnd;
  return res;
}

/* Bed availability */

export enum BedState {
  Available = 'available', // Bed is available
  Occupied = 'occupied', // Bed is assigned to a patient
  Reserved = 'reserved', // Bed is reserved for a patient
  Blocked = 'blocked', // Bed is blocked for unknown reasons
}
export type BedStateEntity = Entity & { state: string; plannedEnd?: Entity; blockCause?: Entity };
export const BedStateModel: EntityModel = {
  type: LocationEntities.BedState,
  props: ['state', 'plannedEnd', 'blockCause'],
  keys: ['state'],
  unclassified: true,
};
export function makeBedState(state: string | Entity, plannedEnd?: Entity): BedStateEntity {
  const res: BedStateEntity = {
    entityType: LocationEntities.BedState,
    state: typeof state === 'string' ? state : BedState.Blocked,
  };
  if (plannedEnd) res.plannedEnd = plannedEnd;
  if (typeof state !== 'string') res.blockCause = state;
  return res;
}

/* Bed block strength */

export type BedBlockStrengthEntity = Entity & { strength: string };
export const BedBlockStrengthModel: EntityModel = {
  type: LocationEntities.BedBlockStrength,
  props: ['strength'],
};
export function makeBedBlockStrength(strength: string): BedBlockStrengthEntity {
  return { entityType: LocationEntities.BedBlockStrength, strength: strength };
}

export function combineBedBlockStrength<
  Type extends [Entity, BedBlockStrengthEntity] | BedBlockStrengthEntity
>(a: Type, b: Type): Type {
  const aS = Array.isArray(a) ? a[1].strength : a.strength;
  const bS = Array.isArray(b) ? b[1].strength : b.strength;
  if (aS === BedBlockStrength.Hard) return a;
  if (bS === BedBlockStrength.Hard || bS === BedBlockStrength.Medium) return b;
  return a;
}

/* Interface for determining the ward of a bed */

export const BedsWardModel: Interface = {
  type: LocationInterfaces.BedsWard,
  requires: [LocationInterfaces.ClusterKey],
};
export const BedsRoomModel: Interface = {
  type: LocationInterfaces.BedsRoom,
  requires: [LocationInterfaces.Key],
};

/* Unspecific blocks */

export type SpecialtyUnspecificBlock = EntityHeader & {
  specialty: Entity;
  blockedBeds: number;
};
export const SpecialtyUnspecificBlockModel: EntityModel = {
  type: LocationEntities.UnspecificBlock,
  props: ['specialty', 'blockedBeds'],
};
export function makeSpecialtyUnspecificBlock(
  specialty: Entity,
  blockedBeds: number
): SpecialtyUnspecificBlock {
  return {
    entityType: LocationEntities.UnspecificBlock,
    specialty: specialty,
    blockedBeds: blockedBeds,
  };
}

export type SpecialtyUnspecificBlocks = EntityHeader & { blocks: Entity[] };
export const SpecialtyUnspecificBlocksModel: EntityModel = {
  type: LocationEntities.UnspecificBlocks,
  props: ['blocks'],
};
export function makeSpecialtyUnspecificBlocks(blocks: Entity[]): SpecialtyUnspecificBlocks {
  return { entityType: LocationEntities.UnspecificBlocks, blocks: blocks };
}

/* Interface to get related data of BedBlock.interface.type but to inject customer specific handling of blocks - eg LMU to inject patient specific blocks */
export const BedBlockInterfaceModel: Interface = {
  type: LocationInterfaces.BedBlock,
  requires: LocationEntities.BedBlock,
};
