import { CRUDInterface } from './crud-interface';

export const VersionCollectionName = 'versions';

type VersionInfo = { domain: string; system: string; version: number };

export async function ensureVersion(
  db: CRUDInterface,
  domain: string,
  system: string,
  version: number,
  func: () => Promise<void>
): Promise<void> {
  /* Get existing version information if applicable */
  const searchParam = { domain: domain };
  const versionInfo = await db.findOne<VersionInfo>(VersionCollectionName, searchParam);
  /* Check if the system and version is the same */
  if (versionInfo && versionInfo.obj.system === system && versionInfo.obj.version === version)
    return;
  /* Perform action */
  await func();
  /* Store version */
  await db.set<VersionInfo>(VersionCollectionName, searchParam, {
    domain: domain,
    system: system,
    version: version,
  });
}
