import { FactoryProps } from '@sqior/react/factory';
import styles from './specialty-selection-page.module.css';
import { ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useDynamicState } from '@sqior/react/state';
import ActiveItemsSelection, { ItemsData } from '../active-items-selection/active-items-selection';
import {
  ActiveSpecialtyPath,
  AddSpecialty,
  AvailableSpecialtiesPath,
  RemoveSpecialty,
} from '@sqior/viewmodels/user';

export function SpecialtySelectionPage(props: FactoryProps) {
  const textDict = useTextResources();
  const activeItems = useDynamicState<ItemsData>(ActiveSpecialtyPath, []);
  const availableItems = useDynamicState<ItemsData>(AvailableSpecialtiesPath, []);

  function onClose() {
    props.onClose?.(true, undefined);
  }

  return (
    <ClosablePage onClose={onClose}>
      <div className={styles['container']}>
        <ActiveItemsSelection
          activeItems={activeItems}
          availableItems={availableItems}
          addOperation={AddSpecialty}
          removeOperation={RemoveSpecialty}
          title={'specialty_selection_title'}
          description={'specialty_selection_description'}
          defaultSelectionText={'specialty_selection_title'}
        />
        <div className={styles['bottombar']}>
          <HighlightButton onClick={onClose}>{textDict.get('close')}</HighlightButton>
        </div>
      </div>
    </ClosablePage>
  );
}

export default SpecialtySelectionPage;
