import { arrayConcatAsync } from '@sqior/js/async';
import { Entity } from '@sqior/js/entity';
import { AnchorModel, Interface, RelatedDataDomain, RelatedDataModel } from '@sqior/js/meta';
import {
  Gender,
  GenderEntity,
  LanguageEntities,
  LanguageInterfaces,
  makeGender,
  Verbosity,
  VerbosityContextProperty,
  VerbosityEntity,
} from '@sqior/plugins/language';
import { makeSpecialties, SpecialtyEntities } from '@sqior/plugins/specialty';
import {
  LocationClusterFunction,
  LocationClusterFunctionEntity,
  LocationClusterFunctionModel,
  LocationClusterIdModel,
  LocationClustersEntity,
  LocationOrCluster,
} from './location-cluster';
import {
  GetLocationClusterFunctionModel,
  LocationEntities,
  LocationInterfaces,
  LocationsEntity,
  LocationsInterface,
} from './location-definitions';
import { LocationAnchor, LocationAssociatedLocations, LocationFunction } from './location-domain';
import { LocationAndFunction, RoomFunction } from './room-function';
import { makeSpecialtyUnspecificBlocks } from './bed-definitions';

export const LocationClusterDomainName = 'LocationCluster';
export const LocationClusterAnchor = new AnchorModel(
  LocationClusterDomainName,
  LocationInterfaces.ClusterKey
);
export const LocationClusterName = new RelatedDataModel(
  LocationClusterAnchor,
  'Name',
  LanguageEntities.AnonymizedText
);
export const LocationClusterShortName = new RelatedDataModel(
  LocationClusterAnchor,
  'ShortName',
  LanguageEntities.AnonymizedText
);
export const ClusterLocations = new RelatedDataModel(
  LocationClusterAnchor,
  'Locations',
  LocationEntities.Locations,
  true,
  'locations',
  LocationInterfaces.LocationOrCluster
);
export const LocationClusterSpecialties = new RelatedDataModel(
  LocationClusterAnchor,
  'Specialties',
  SpecialtyEntities.Specialties,
  true,
  'specialties',
  SpecialtyEntities.Specialty
);
export const LocationClusterRelatedFunction = new RelatedDataModel(
  LocationClusterAnchor,
  'RelatedFunction',
  LocationEntities.ClusterFunction,
  true
);
export const LocationClusterUnspecificBlocks = new RelatedDataModel(
  LocationClusterAnchor,
  'UnspecificBlocks',
  LocationEntities.UnspecificBlocks
);

export const ContainingClustersModel: Interface = {
  type: LocationInterfaces.ContainingClusters,
  requires: LocationClusterAnchor.containerModel.type,
};

export class LocationClusterDomain extends RelatedDataDomain {
  constructor() {
    /* Definitions */
    super(LocationClusterAnchor, {
      entities: [LocationClusterIdModel, LocationClusterFunctionModel],
      interfaces: [
        LocationOrCluster,
        ContainingClustersModel,
        LocationsInterface,
        GetLocationClusterFunctionModel,
      ],
      relatedData: [
        LocationClusterName,
        LocationClusterShortName,
        ClusterLocations,
        LocationClusterSpecialties,
        LocationClusterRelatedFunction,
        LocationClusterUnspecificBlocks,
      ],
    });
    /* Mappings */
    this.addTrivialMapping(LocationEntities.ClusterId, LocationInterfaces.ClusterKey);
    this.addTrivialMapping(LocationInterfaces.Key, LocationInterfaces.LocationOrCluster);
    this.addTrivialMapping(LocationInterfaces.ClusterKey, LocationInterfaces.LocationOrCluster);
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationInterfaces.ClusterFunction,
      (cluster, mapper) => {
        /* Return the related data by default */
        return mapper.tryMap(cluster, LocationClusterRelatedFunction.interface.type);
      },
      { weight: 2 }
    );
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LanguageEntities.AnonymizedText,
      async (entity, mapper) => {
        const verbosity = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        const type =
          !verbosity || verbosity.verbosity !== Verbosity.Short
            ? LocationClusterName.interface.type
            : LocationClusterShortName.interface.type;
        return mapper.tryMap(entity, type);
      },
      { context: VerbosityContextProperty }
    );
    this.addBasicMapping<LocationClusterFunctionEntity, GenderEntity>(
      LocationEntities.ClusterFunction,
      LanguageEntities.Gender,
      (entity) => {
        return LocationClusterDomain.FemaleClusters.has(entity.function)
          ? makeGender(Gender.Female)
          : makeGender(Gender.Male);
      }
    );
    this.addEntityMapping<GenderEntity>(
      LocationInterfaces.ClusterKey,
      LanguageInterfaces.InsidePreposition,
      (cluster, mapper) => {
        return mapper.tryMapChain(cluster, [
          LocationInterfaces.ClusterFunction,
          LanguageInterfaces.InsidePreposition,
        ]);
      }
    );
    this.addEntityMapping<GenderEntity>(
      LocationInterfaces.ClusterKey,
      LanguageInterfaces.IntoPreposition,
      (cluster, mapper) => {
        return mapper.tryMapChain(cluster, [
          LocationInterfaces.ClusterFunction,
          LanguageInterfaces.IntoPreposition,
        ]);
      }
    );

    /* Determine the actual locations associated with the cluster */
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationEntities.Locations,
      async (entity, mapper) => {
        /* Get the cluster associated locations */
        const clusterLocs = await mapper.tryMap<LocationsEntity>(
          entity,
          ClusterLocations.interface.type
        );
        return LocationAnchor.makeContainer(
          clusterLocs
            ? await arrayConcatAsync(clusterLocs.locations, async (cl) => {
                /* Recurse */
                const locs = await mapper.tryMap<LocationsEntity>(cl, LocationEntities.Locations);
                return locs ? locs.locations : [];
              })
            : []
        );
      },
      { weight: 2 }
    );

    /* Determines the actual locations contained in a set of clusters */
    this.addEntityMapping<LocationClustersEntity>(
      LocationEntities.Clusters,
      LocationInterfaces.Locations,
      async (clusters, mapper) => {
        return LocationAnchor.makeContainer(
          await arrayConcatAsync(clusters.locationclusters, async (cluster) => {
            return (
              (await mapper.tryMap<LocationsEntity>(cluster, LocationEntities.Locations))
                ?.locations ?? []
            );
          })
        );
      },
      { weight: 2 }
    );

    // Returns (recursively) all clusters which are part of the specified cluster
    // Note: the specified cluster is part of the resulting mapping
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationInterfaces.ContainingClusters,
      async (entity, mapper) => {
        /* Get the cluster associated locations */
        const clusterLocs = await mapper.tryMap<LocationsEntity>(
          entity,
          ClusterLocations.interface.type
        );
        const clusters = clusterLocs
          ? clusterLocs.locations.filter((v) => mapper.represents(v, LocationInterfaces.ClusterKey))
          : [];
        return LocationClusterAnchor.makeContainer(
          clusters && clusters.length
            ? await arrayConcatAsync(clusters, async (cl) => {
                /* Recurse */
                const locs = await mapper.tryMap<LocationClustersEntity>(
                  cl,
                  LocationInterfaces.ContainingClusters
                );
                return locs?.locationclusters.length ? locs.locationclusters : [entity];
              })
            : [entity]
        );
      }
    );

    /* Determine the locations of the provided function that are associated with the specified reference location e.g. induction rooms associated with an OR */
    this.addEntityMapping<LocationAndFunction>(
      LocationEntities.LocationAndFunction,
      LocationEntities.Locations,
      async (locAndFunc, mapper) => {
        const func = await mapper.map(locAndFunc.func, LocationEntities.RoomFunction);
        const funcKey = mapper.makeKey(func);

        // Check if room itself fullfills the desired function
        const locationsFunc = await mapper.tryMapChain(locAndFunc.location, [
          LocationInterfaces.Key,
          LocationFunction.interface.type,
        ]);
        if (mapper.isEqual(locationsFunc, func))
          return LocationAnchor.makeContainer(locAndFunc.location);

        /* Check if there are specific associated locations to the procedure location */
        const assocLocs = await mapper.tryMap<LocationsEntity>(
          locAndFunc.location,
          LocationAssociatedLocations.interface.type
        );
        if (assocLocs) {
          const locations: Entity[] = [];
          /* Resolve pot. clusters */
          for (const locOrCluster of assocLocs.locations) {
            const concLocs = await mapper.tryMap<LocationsEntity>(
              locOrCluster,
              LocationEntities.Locations
            );
            if (concLocs)
              for (const loc of concLocs.locations) {
                const funcEnt = await mapper.tryMap<RoomFunction>(
                  loc,
                  LocationFunction.interface.type
                );
                if (funcEnt && mapper.makeKey(funcEnt) === funcKey) locations.push(loc);
              }
          }
          /* If locations of the specified function are found, use these */
          if (locations && locations.length > 0) return LocationAnchor.makeContainer(locations);
        }
        return mapper.tryMap(locAndFunc.func, LocationFunction.anchorsType);
      }
    );
    this.addEntityMapping(
      LocationEntities.LocationAndFunction,
      LocationInterfaces.Key,
      async (locAndFunc, mapper) => {
        const locs = await mapper.map<LocationsEntity>(locAndFunc, LocationEntities.Locations);
        if (locs.locations.length === 1) return locs.locations[0];
        return undefined;
      },
      { weight: 2 }
    );

    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      SpecialtyEntities.Specialties,
      async (cluster, mapper) => {
        return (
          (await mapper.tryMap(cluster, LocationClusterSpecialties.interface.type)) ||
          makeSpecialties([])
        );
      },
      { weight: 10 }
    );

    // Return unspeciifc blocks from related data (was directly from SAP previously)
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationEntities.UnspecificBlocks,
      async (cl, mapper) => {
        const blocks = await mapper.tryMap(cl, LocationClusterUnspecificBlocks.interface.type);
        return blocks ? blocks : makeSpecialtyUnspecificBlocks([]);
      }
    );
  }

  private static FemaleClusters = new Set<string>([
    LocationClusterFunction.AllOperatingRooms,
    LocationClusterFunction.ICU,
    LocationClusterFunction.ORAssociatedRooms,
    LocationClusterFunction.OperatingRoomCluster,
    LocationClusterFunction.PatientWard,
    LocationClusterFunction.PatientWardCluster,
  ]);
}
