import { FactoryProps } from '@sqior/react/factory';
import { WardSPIStatsInfoListViewEntity } from '@sqior/viewmodels/occupancy';
import styles from './ward-spi-stats.module.css';

export type WardSpiStatsProps = FactoryProps<WardSPIStatsInfoListViewEntity>;

export function WardSpiStats(props: WardSpiStatsProps) {
  const warn = props.data.spiMaxCountLimit < props.data.spiCountLimit;
  return (
    <div className={styles['container']}>
      <div>Patienten SPI &le; {props.data.spiValueLimit}</div>
      <div>
        <span className={styles[warn ? 'warning' : 'normal']}>{props.data.spiCountLimit}</span>
        <span> / {props.data.spiMaxCountLimit}</span>
      </div>
    </div>
  );
}

export default WardSpiStats;
