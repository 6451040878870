/** This class allows to register accesses to exclusive items that can cause a deadlock, this class will detect the deadlock and let the caller handle it */

export class DeadlockDetector<Type = string> {
  /** Registers an access */
  register(accessor: DeadlockAccessor, item: Type) {
    /* Check if this is alredy registered */
    if (this.registry.has(item)) return undefined;
    /* Register the accessor */
    this.registry.set(item, accessor);
    /* Return lambda freeing access again */
    return () => {
      this.registry.delete(item);
    };
  }

  /** Checks for a deadlock to be expected when accessing the registered item */
  checkAccess(item: Type, accessors: DeadlockAccessor[]): DeadlockAccessor[] | undefined {
    /** Get entry */
    const blocker = this.registry.get(item);
    if (!blocker) return undefined;
    /* Check if this or any ancestor waits on the blocker or any of it blocking accesors */
    let walkBlockers: DeadlockAccessor | undefined = blocker;
    while (walkBlockers) {
      /* Check if this or any ancestor depends on the blocking accessor */
      if (
        accessors.find((acc) => {
          return acc === walkBlockers;
        })
      ) {
        /* Return the chain of blocking accessors */
        const blockingChain: DeadlockAccessor[] = [];
        let chainWalker: DeadlockAccessor | undefined = blocker;
        while (chainWalker && chainWalker !== walkBlockers) {
          blockingChain.push(chainWalker);
          chainWalker = chainWalker.blockingAccessor;
        }
        if (chainWalker) blockingChain.push(chainWalker);
        return blockingChain;
      }
      walkBlockers = walkBlockers.blockingAccessor;
    }
    /* Register the blocker */
    accessors[accessors.length - 1].blockingAccessor = blocker;
    return undefined;
  }

  /** Indicates the end of an item access */
  releaseAccess(accessors: DeadlockAccessor[]) {
    accessors[accessors.length - 1].blockingAccessor = undefined;
  }

  /** Registry of accesses */
  private registry = new Map<Type, DeadlockAccessor>();
}

/** This type represents one client accessing exclusive items */

export type DeadlockAccessor = {
  id: string;
  blockingAccessor?: DeadlockAccessor;
};
