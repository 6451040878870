import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';

export function AppVersionOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  /* Create overlay resetting the app version update */
  const overlay = StateOverlay.createRaw((value) => {
    /* Disable the overlay if the check is no longer found or has the correct state */
    if (value === undefined) setTimeout(() => overlay.clear(), 0);
    return undefined;
  });

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
  });

  return overlay;
}
