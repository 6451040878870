export class KeyPairMap<FirstKeyType, SecondKeyType, ValueType> {
  constructor() {
    this.map = new Map<FirstKeyType, Map<SecondKeyType, ValueType>>();
  }

  has(first: FirstKeyType, second: SecondKeyType) {
    const outer = this.map.get(first);
    if (!outer) return false;
    return outer.has(second);
  }

  get(first: FirstKeyType, second: SecondKeyType) {
    const outer = this.map.get(first);
    if (!outer) return undefined;
    return outer.get(second);
  }

  set(first: FirstKeyType, second: SecondKeyType, value: ValueType) {
    let outer = this.map.get(first);
    if (!outer) this.map.set(first, (outer = new Map<SecondKeyType, ValueType>()));
    outer.set(second, value);
  }

  delete(first: FirstKeyType, second: SecondKeyType) {
    const outer = this.map.get(first);
    if (!outer) return;
    outer.delete(second);
    if (outer.size === 0) this.map.delete(first);
  }

  /** Returns the total count of leafs */
  get size() {
    let count = 0;
    for (const val of this.map.values()) count += val.size;
    return count;
  }

  readonly map: Map<FirstKeyType, Map<SecondKeyType, ValueType>>;
}
