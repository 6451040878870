import { classes } from '@sqior/react/utils';
import styles from './button-close.module.css';
import { ReactComponent as CloseIcon } from './button-close.svg';

/* eslint-disable-next-line */
export interface ButtonCloseProps {
  additionalClassName?: string;
  onClick: () => void;
}

export function ButtonClose(props: ButtonCloseProps) {
  return (
    <button
      className={classes(styles['button'], props.additionalClassName)}
      onClick={props.onClick}
    >
      <CloseIcon className={styles['icon']} viewBox="0 0 48 48" />
    </button>
  );
}

export default ButtonClose;
