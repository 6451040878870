import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { AddressSelectionType } from '@sqior/viewmodels/user';
import AddressSelectionControl from './address-selection-control/address-selection-control';
import { ClosableChat } from './closable-chat/closable-chat';
import { PageChatType } from '@sqior/viewmodels/communication';

export function UIChatPlugin(context: PluginContext & FactoryContext) {
  context.factory.add(AddressSelectionType, AddressSelectionControl);
  context.factory.add(PageChatType, ClosableChat);
}
