import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { PersonEntities } from './person-definitions';
import { PersonSex } from '@sqior/viewmodels/person';

export type PersonSexEntity = Entity & { sex: string };
export const PersonSexEntityModel: EntityModel = {
  type: PersonEntities.PersonSex,
  props: ['sex'],
  unclassified: true,
};
export function makePersonSex(sex: string, type = PersonEntities.PersonSex): PersonSexEntity {
  return { entityType: type, sex: sex };
}

export function mapTypicalSex(sex: string) {
  const sexLower = sex.toLowerCase();
  if (sexLower === 'm') return PersonSex.Male;
  else if (sexLower === 'w' || sex === 'f') return PersonSex.Female;
  else if (sexLower === 'd') return PersonSex.Diverse;

  return undefined;
}

export const PersonSexSymbolModel: EntityModel = {
  type: PersonEntities.PersonSexSymbol,
  props: ['sex'],
  unclassified: true,
};
