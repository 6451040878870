import { ComponentFactory } from '@sqior/react/factory';
import { useDynamicState } from '@sqior/react/state';
import { ContainerWithShadow, SearchInput } from '@sqior/react/uibase';
import { ListViewItem } from '@sqior/viewmodels/visual';
import { useContext, useState } from 'react';
import styles from './dashboard-list.module.css';
import Fuse from 'fuse.js';
import { v4 as uuidv4 } from 'uuid';

export interface DashboardListProps {
  path: string;
  className?: string;
}

const hasSearchInput = (list: ListViewItem[]) => {
  return list.some((item) => item?.searchKeys?.length && item.searchKeys.length > 0);
};

const SHADOW_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
};

const CONTAINER_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
};

export function DashboardList(props: DashboardListProps) {
  const list = useDynamicState<ListViewItem[]>(props.path, []);
  const FactoryComponent = useContext(ComponentFactory);

  const [inputValue, setInputValue] = useState('');

  const hasSearch = hasSearchInput(list);

  const fuse = new Fuse(list, {
    includeMatches: true,
    includeScore: true,
    shouldSort: true,
    threshold: 0.4,
    keys: ['searchKeys'],
  });

  const getNewList = () => {
    if (inputValue === '') {
      return list;
    } else {
      return fuse.search(inputValue).map((item) => item.item);
    }
  };

  return (
    <div className={props.className} style={CONTAINER_STYLE}>
      {hasSearch && <SearchInput value={inputValue} setValue={setInputValue} />}
      <ContainerWithShadow style={SHADOW_STYLE}>
        <div className={styles['scroll-container']}>
          {getNewList().map((item) => (
            <FactoryComponent data={item} key={uuidv4()} />
          ))}
        </div>
      </ContainerWithShadow>
    </div>
  );
}

export default DashboardList;
