import styles from './op-config-view.module.css';
import SpecialtyView from '../specialty-view/specialty-view';
import { motion } from 'framer-motion';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { memo, useContext } from 'react';
import { ORWorkflowOverviewDashboardORData } from '@sqior/viewmodels/orworkflow';
import {
  DailyStartConfig,
  DailyStartConfigItem,
  DailyStartConfigItemTypes,
  EnableDailyStart,
  makeUpdateLocationData,
  makeUpdateLocationIndividualTime,
  UpdateLocation,
} from '@sqior/viewmodels/location';
import { OperationContext } from '@sqior/react/operation';
import { SwitchControl, useTextResources } from '@sqior/react/uibase';
import { OP_CONFIG_ANIMATION_DURATION } from '../orworkflow-overview-dashboard/constants';
import { TimePicker } from '@mui/x-date-pickers';
import { isValid, set } from 'date-fns';
import { IdEntity } from '@sqior/js/entity';

export interface OpConfigViewProps {
  openConfig?: boolean;
  or: ORWorkflowOverviewDashboardORData;
  firstItem: boolean;
  lastItem: boolean;
  empty?: boolean;
}

const getPriorityGroups = (dailyStartConfig?: DailyStartConfig): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const priorityIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.TransferPriorityGroup
  );
  if (priorityIndex === -1) return null;
  return dailyStartConfig.configs[priorityIndex];
};

const getIndividualTime = (dailyStartConfig?: DailyStartConfig): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const idx = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.IndividualTime
  );
  if (idx === -1) return null;
  return dailyStartConfig.configs[idx];
};

const getTransferLocations = (dailyStartConfig?: DailyStartConfig): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const transferLocationIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.TransferLocation
  );
  if (transferLocationIndex === -1) return null;
  return dailyStartConfig.configs[transferLocationIndex];
};

const getInductionLocations = (
  dailyStartConfig?: DailyStartConfig
): DailyStartConfigItem | null => {
  if (!dailyStartConfig) return null;
  const inductionLocationIndex = dailyStartConfig.configs.findIndex(
    (config) => config.configType === DailyStartConfigItemTypes.InductionLocation
  );
  if (inductionLocationIndex === -1) return null;
  return dailyStartConfig.configs[inductionLocationIndex];
};

const getSelectedValue = (dailyStartConfigItem: DailyStartConfigItem): string => {
  if (dailyStartConfigItem.selected === undefined) return '';
  return dailyStartConfigItem.options[dailyStartConfigItem.selected].text;
};

const getDateFromPriorityDescription = (description?: string): Date | null => {
  if (!description) return null;
  const [firstTime] = description.split('-');

  const [hours, minutes] = firstTime.split(':').map(Number);

  const now = new Date();

  const startDate = set(now, {
    hours: hours,
    minutes: minutes,
    seconds: 0,
    milliseconds: 0,
  });
  if (!isValid(startDate)) return null;
  return startDate;
};

const getSelectedTimeValue = (dailyStartConfigItem: DailyStartConfigItem | null): Date | null => {
  if (!dailyStartConfigItem || !dailyStartConfigItem.indiviualTime) return null;

  // Get the current date
  const now = new Date();

  // Set the time using the 'set' function from date-fns
  const startDate = set(now, {
    hours: dailyStartConfigItem.indiviualTime.hours,
    minutes: dailyStartConfigItem.indiviualTime.minutes,
    seconds: 0,
    milliseconds: 0,
  });

  // checkif the date is a valid date
  if (!startDate || isNaN(startDate.getTime())) return null;
  if (!isValid(startDate)) return null;

  return startDate;
};

const TODAY = new Date();

const ALLOWED_INTERVAL = {
  start: set(TODAY, { hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }),
  end: set(TODAY, { hours: 11, minutes: 0, seconds: 0, milliseconds: 0 }),
};

export const OpConfigView = ({ openConfig, or, firstItem, lastItem, empty }: OpConfigViewProps) => {
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const { dailyStartConfig } = or;
  const location = or.location as IdEntity;

  const priorityGroup = getPriorityGroups(dailyStartConfig);
  const individualTime = getIndividualTime(dailyStartConfig);
  const transferLocation = getTransferLocations(dailyStartConfig);
  const inductionLocation = getInductionLocations(dailyStartConfig);

  const [timeValue, setTimeValue] = React.useState<Date | null>(
    getSelectedTimeValue(individualTime)
  );

  const controlActive = dailyStartConfig?.enabled;

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // G1 G2 G3
    if (!priorityGroup) return;

    const priorityIndex = priorityGroup.options.findIndex(
      (group) => group.text === event.target.value
    );

    dispatcher.start(
      UpdateLocation(makeUpdateLocationData(location.id, priorityGroup.configType, priorityIndex))
    );
    const selectedPriority = priorityGroup.options[priorityIndex].description;
    const selectedTime = getDateFromPriorityDescription(selectedPriority);
    setTimeValue(selectedTime);
  };
  const handleTransferLocationChange = (event: SelectChangeEvent<string>) => {
    if (!transferLocation) return;
    const transferLocationIndex = transferLocation.options.findIndex(
      (group) => group.text === event.target.value
    );
    dispatcher.start(
      UpdateLocation(
        makeUpdateLocationData(location.id, transferLocation.configType, transferLocationIndex)
      )
    );
  };
  const handleInductionLocationChange = (event: SelectChangeEvent<string>) => {
    if (!inductionLocation) return;
    const inductionLocationIndex = inductionLocation.options.findIndex(
      (group) => group.text === event.target.value
    );
    dispatcher.start(
      UpdateLocation(
        makeUpdateLocationData(location.id, inductionLocation.configType, inductionLocationIndex)
      )
    );
  };
  const handleCustomTimeChange = (date: Date | null) => {
    if (!date) return;
    dispatcher.start(
      UpdateLocation(
        makeUpdateLocationIndividualTime(location.id, {
          hours: date.getHours(),
          minutes: date.getMinutes(),
        })
      )
    );
    setTimeValue(date);
  };

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    dispatcher.start(EnableDailyStart(location.id, value));
  };

  return (
    <motion.div
      layout
      initial={{ width: '100%', backgroundColor: undefined }}
      animate={{
        backgroundColor: openConfig ? 'rgba(34,40,63,0.68)' : undefined,
        borderBottomRightRadius: lastItem ? 10 : 0,
        borderBottomLeftRadius: firstItem ? 10 : 0,
      }}
      style={{
        overflow: 'hidden',
        boxShadow: openConfig ? '0px 0px 5px 0px #000000' : 'none',
        marginBottom: 10,
        opacity: empty ? 0 : 1,
      }}
    >
      <div className={styles['container']}>
        <div className={styles['or-name']}>{or.name}</div>
        <div className={styles['speciality-container']}>
          {or.specialty && <SpecialtyView specialty={or.specialty} fontSize={14} />}
        </div>
      </div>
      {(priorityGroup || transferLocation || inductionLocation) && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            height: openConfig ? 'auto' : 0,
            opacity: openConfig ? 1 : 0,
            transition: { duration: OP_CONFIG_ANIMATION_DURATION },
          }}
        >
          <div style={{ padding: 10 }}>
            <div className={styles['control-label-container']}>
              <div lang={textDict.language()}>{textDict.get('automatic_onboarding')}</div>
              <SwitchControl checked={controlActive} onChange={onSwitchChange} />
            </div>
            {individualTime && (
              <div style={{ marginBottom: 10 }}>
                <TimePicker
                  label="Zeit"
                  disabled={!controlActive}
                  value={timeValue}
                  minTime={ALLOWED_INTERVAL.start}
                  maxTime={ALLOWED_INTERVAL.end}
                  onChange={handleCustomTimeChange}
                  sx={{
                    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: 'yellow',
                      },
                  }}
                />
              </div>
            )}
            {priorityGroup && (
              <FormControl fullWidth disabled={!controlActive}>
                <FormLabel style={{ marginBottom: 5 }}>{textDict.get('priority_group')}</FormLabel>
                <RadioGroup
                  aria-labelledby={textDict.get('priority_group')}
                  name="row-radio-buttons-group"
                  onChange={handlePriorityChange}
                  value={getSelectedValue(priorityGroup)}
                >
                  {priorityGroup.options
                    .filter((item) => item.text !== '---')
                    .map((group) => (
                      <div key={group.text} className={styles['priority-group-container']}>
                        <FormControlLabel
                          value={group.text}
                          control={<Radio />}
                          label={
                            <div className={styles['priority-group-label-container']}>
                              <p>{group.text}</p>
                              <div className={styles['priority-group-description-container']}>
                                <p className={styles['priority-group-description']}>
                                  {group.description}
                                </p>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    ))}
                </RadioGroup>
              </FormControl>
            )}
          </div>
          {transferLocation && (
            <div style={{ padding: 10 }}>
              <FormControl fullWidth disabled={!controlActive}>
                <InputLabel>{textDict.get('location_transfer')}</InputLabel>
                <Select
                  label={textDict.get('location_transfer')}
                  variant="outlined"
                  value={getSelectedValue(transferLocation)}
                  onChange={handleTransferLocationChange}
                >
                  {transferLocation.options.map((location) => (
                    <MenuItem key={location.text} value={location.text}>
                      {location.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {inductionLocation && (
            <div style={{ padding: 10 }}>
              <FormControl fullWidth disabled={!controlActive}>
                <InputLabel>{textDict.get('location_induction')}</InputLabel>
                <Select
                  label={textDict.get('location_induction')}
                  variant="outlined"
                  value={getSelectedValue(inductionLocation)}
                  onChange={handleInductionLocationChange}
                >
                  {inductionLocation.options.map((location) => (
                    <MenuItem key={location.text} value={location.text}>
                      {location.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default memo(OpConfigView);
