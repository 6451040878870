import { ButtonClose, HighlightButton, useTextResources } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { AskUserPushNotificationCB, SetAskUserPushNotification } from '@sqior/web/push-firebase';
import React, { useEffect, useState } from 'react';
import styles from './ask-push-permission.module.css';

/* eslint-disable-next-line */
export interface AskPushPermissionProps {
  className?: string;
}

// For some reason it is not possible to store this callback as a react state ... thus, it is a global variable
let askForPermissionCB: AskUserPushNotificationCB | undefined = undefined;

export function AskPushPermission(props: AskPushPermissionProps) {
  const textResources = useTextResources();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    SetAskUserPushNotification((cb: AskUserPushNotificationCB) => {
      askForPermissionCB = cb;
      setTimeout(() => {
        setDisplay(cb !== undefined);
      }, 1000);
    });
  }, []);

  function grant() {
    setDisplay(false);
    if (askForPermissionCB !== undefined) {
      askForPermissionCB('granted');
    }
  }
  function deny() {
    setDisplay(false);
    if (askForPermissionCB !== undefined) {
      askForPermissionCB('denied');
    }
  }

  return (
    <div
      className={classes(
        styles['container'],
        props.className,
        styles[display ? 'visible' : 'invisible']
      )}
    >
      <div className={classes(styles['message-container'])}>
        <div className={styles['message']}>
          {textResources.get('grant_permission_notification')}
        </div>
        <HighlightButton additionalClassName={styles['button-grant']} onClick={grant}>
          {textResources.get('grant_permission')}
        </HighlightButton>
      </div>
      <ButtonClose additionalClassName={styles['btn-close']} onClick={deny} />
    </div>
  );
}

export default AskPushPermission;
