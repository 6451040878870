import { Listener } from './listener';

export class Emitter<Type extends unknown[] = []> {
  emit(...args: Type) {
    /* Emit on all listeners */
    if (this.listeners) for (const list of this.listeners.values()) list(...args);
  }

  /** Listens to an event, the listener is called after all existing listeners */
  on(list: Listener<Type>) {
    if (!this.listeners) this.listeners = new Map<number, Listener<Type>>();
    const id = this.listenerId++;
    this.listeners.set(id, list);
    return () => {
      this.off(id);
    };
  }

  /** Listens to an event, the listener is called before all existing listeners */
  onFront(list: Listener<Type>) {
    /* Simply insert if there is no other listener */
    if (!this.listeners) return this.on(list);
    /* As the iterator order is in the order of insertion, a new map needs to be created */
    const listeners = new Map<number, Listener<Type>>();
    const [first] = this.listeners;
    /* Insert new first element */
    const id = first[0] - 1;
    listeners.set(id, list);
    /* Copy existing elements */
    for (const value of this.listeners) listeners.set(value[0], value[1]);
    this.listeners = listeners;
    return () => {
      this.off(id);
    };
  }

  /** Removes a listener */
  private off(id: number) {
    if (!this.listeners) return;
    this.listeners.delete(id);
    if (!this.listeners.size) this.listeners = undefined;
  }

  clear() {
    this.listeners = undefined;
  }
  empty() {
    return this.listeners === undefined;
  }

  private listenerId = 0;
  private listeners?: Map<number, Listener<Type>>;
}
