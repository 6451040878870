import {
  AddressInfoVM,
  CreateAndDisplayChat,
  OnlineStatus as OnlineStat,
} from '@sqior/viewmodels/communication';
import {
  Button,
  HighlightButton,
  SvgIconCall2,
  SvgIconChatEmpty,
  SvgIconEmail,
  useTextResources,
} from '@sqior/react/uibase';
import styles from './address-item.module.css';
import { useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { ValueObject } from '@sqior/js/data';
import { classes, getPrettyTimeString } from '@sqior/react/utils';
import { useIsMobile } from '@sqior/react/hooks';
import { motion } from 'framer-motion';
import { Interweave } from 'interweave';

export interface AddressItemProps {
  item: AddressInfoVM;
  relatedId?: ValueObject; // If specified, this reference be used as related entity to e.g. start a chat
}

export function AddressItem(props: AddressItemProps) {
  const isMobile = useIsMobile();
  const debug = false;
  const dispatcher = useContext(OperationContext);

  const [open, setOpen] = useState(false);

  const textDict = useTextResources();

  function onEmail() {
    document.location.href = `mailto:${props.item.email}`;
  }
  function onChat() {
    if (props.item.chatAddress !== undefined && props.relatedId !== undefined)
      dispatcher.start(CreateAndDisplayChat(props.relatedId, [props.item.chatAddress], true));
  }

  const getName = (item: AddressInfoVM) => {
    if (!item.name) return textDict.get('unknown_user');
    return item.name;
  };

  const onPhoneIconClick = () => {
    if (isMobile && props.item.phoneNumbers.length === 1) {
      document.location.href = `tel:${props.item.phoneNumbers[0].phoneNumber}`;
      return;
    }
    setOpen((prev) => !prev);
  };

  const onCall = (phoneNumber: string) => {
    document.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className={styles['column-container']}>
      <div className={styles['container']}>
        <div className={styles['status']}>
          <OnlineStatus status={props.item.onlineStatus} />
        </div>
        <div className={styles['names']}>
          <div className={props.item.name ? styles['name'] : styles['gray-name']}>
            {getName(props.item)}
          </div>
          <div className={styles['role']}>
            <div>{props.item.subname}</div>
            {debug && props.item.lastConnect && `(${getPrettyTimeString(props.item.lastConnect)})`}
          </div>
        </div>
        <div className={styles['buttons']}>
          {props.item.phoneNumbers.length > 0 && (
            <Button
              className={styles['button']}
              onClick={onPhoneIconClick}
              style={{
                opacity: open ? 0.5 : 1,
              }}
            >
              <SvgIconCall2 />
            </Button>
          )}

          {props.item.chatAddress && (
            <Button className={styles['button']} onClick={onChat}>
              <SvgIconChatEmpty />
            </Button>
          )}
          {false && props.item.email && (
            <Button className={styles['button']} onClick={onEmail}>
              <SvgIconEmail />
            </Button>
          )}
        </div>
      </div>
      {props.item.phoneNumbers.length > 0 && open && (
        <motion.div
          className={styles['phone-numbers-container']}
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          style={{ overflow: 'hidden' }}
        >
          {props.item.phoneNumbers.map((phoneNumberPair) => (
            <HighlightButton
              secondary={true}
              onClick={() => onCall(phoneNumberPair.phoneNumber)}
              style={{ fontWeight: 'normal', flexShrink: 0 }}
            >
              <Interweave content={phoneNumberPair.phoneNumberVis} />
            </HighlightButton>
          ))}
        </motion.div>
      )}
    </div>
  );
}

interface OnlineStatusProps {
  status: OnlineStat;
}

function OnlineStatus(props: OnlineStatusProps) {
  function statusClass(status: OnlineStat) {
    switch (status) {
      case OnlineStat.NeverSeen:
        return styles['status-offline'];
      case OnlineStat.Offline:
        return styles['status-offline'];
      case OnlineStat.Online:
        return styles['status-online'];
      case OnlineStat.Away:
        return styles['status-away'];
    }
  }

  return <span className={classes(styles['online-status'], statusClass(props.status))} />;
}

export default AddressItem;
