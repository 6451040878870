import { InterpretationResult } from '@sqior/js/conversation';
import { ensureArray } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { EntityModel, makeParameter } from '@sqior/js/meta';
import { InputEntities } from './input-definitions';

/* Entity representing a list of interpreters */

export type InterpretersEntity = Entity & { interpreters: Entity[] };
export const InterpretersModel: EntityModel = {
  type: InputEntities.Interpreters,
  props: ['interpreters'],
};
export function makeInterpretersEntity(interpreters: Entity[]): InterpretersEntity {
  return { entityType: InputEntities.Interpreters, interpreters: interpreters };
}

/* Switch interpreter entity */

export type SimpleInterpreterEntity = Entity & {
  texts: Entity[];
  commands: InterpretationResult[];
  sender?: Entity;
  feedback?: Entity;
};
export const SimpleInterpreterModel: EntityModel = {
  type: InputEntities.SimpleInterpreter,
  props: ['texts', 'commands', 'sender', 'feedback'],
};
export function makeSimpleInterpreter(
  texts: Entity | Entity[],
  commands: InterpretationResult | InterpretationResult[],
  sender?: Entity,
  feedback?: Entity
): SimpleInterpreterEntity {
  const res: SimpleInterpreterEntity = {
    entityType: InputEntities.SimpleInterpreter,
    texts: ensureArray(texts),
    commands: ensureArray(commands),
  };
  if (sender) res.sender = sender;
  if (feedback) res.feedback = feedback;
  return res;
}
export function makeBasicSimpleInterpreter(
  entity: Entity,
  sender?: Entity,
  feedback?: Entity
): SimpleInterpreterEntity {
  return makeSimpleInterpreter(entity, entity, sender, feedback);
}

/* Command interpreter entity */

export type SubInterpreter = {
  texts: Entity[];
  interpreter: Entity | [string, Entity][];
  title?: Entity;
};
export type CommandInterpreterEntity = Entity & {
  subInterpreters: SubInterpreter[];
  commands: InterpretationResult[];
  sender?: Entity;
  feedback?: Entity;
};
export const CommandInterpreterModel: EntityModel = {
  type: InputEntities.CommandInterpreter,
  props: ['subInterpreters', 'commands', 'sender', 'feedback'],
};
export function makeCommandInterpreter(
  subs: SubInterpreter[],
  entities: InterpretationResult | InterpretationResult[],
  sender?: Entity,
  feedback?: Entity
): CommandInterpreterEntity {
  const res: CommandInterpreterEntity = {
    entityType: InputEntities.CommandInterpreter,
    subInterpreters: subs,
    commands: ensureArray(entities),
  };
  if (sender) res.sender = sender;
  if (feedback) res.feedback = feedback;
  return res;
}

/* Person interpreter entity */

export type PersonInterpreterEntity = Entity & { persons: Entity[] };
export const PersonInterpreterModel: EntityModel = {
  type: InputEntities.PersonInterpreter,
  props: ['persons'],
};
export function makePersonInterpreter(persons: Entity[]): PersonInterpreterEntity {
  return { entityType: InputEntities.PersonInterpreter, persons: persons };
}

/* Switch interpreter entity */

export type SwitchInterpreterEntity = Entity & { subInterpreters: Entity[] };
export const SwitchInterpreterModel: EntityModel = {
  type: InputEntities.SwitchInterpreter,
  props: ['subInterpreters'],
};
export function makeSwitchInterpreter(subs: Entity[]): SwitchInterpreterEntity {
  return { entityType: InputEntities.SwitchInterpreter, subInterpreters: subs };
}

/* FreeText interpreter entity */

export type FreeTextInterpreterEntity = Entity & {
  commands: InterpretationResult | InterpretationResult[];
};
export const FreeTextInterpreterModel: EntityModel = {
  type: InputEntities.FreeTextInterpreter,
  props: ['commands'],
};
export function makeFreeTextInterpreter(
  commands: InterpretationResult | InterpretationResult[] = makeParameter('text')
): FreeTextInterpreterEntity {
  return { entityType: InputEntities.FreeTextInterpreter, commands: commands };
}

/* ConfidenceFallback interpreter entity */

export type ConfidenceFallbackInterpreterEntity = Entity & {
  subInterpreters: Entity[];
  threshold: number;
};
export const ConfidenceFallbackInterpreterModel: EntityModel = {
  type: InputEntities.ConfidenceFallbackInterpreter,
  props: ['subInterpreters', 'threshold'],
  unclassified: true,
};
export function makeConfidenceFallbackInterpreter(
  subs: Entity[],
  threshold: number
): ConfidenceFallbackInterpreterEntity {
  return {
    entityType: InputEntities.ConfidenceFallbackInterpreter,
    subInterpreters: subs,
    threshold: threshold,
  };
}
