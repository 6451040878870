import { AddressGroupInfoVM } from '@sqior/viewmodels/communication';
import { classes } from '@sqior/react/utils';
import AddressItem from '../address-item/address-item';
import styles from './address-list.module.css';
import { ValueObject } from '@sqior/js/data';

export interface AddressListProps {
  className?: string;

  items: AddressGroupInfoVM[];

  relatedId?: ValueObject; // If specified, this reference be used as related entity to e.g. start a chat
}

export function AddressList(props: AddressListProps) {
  return (
    <div className={classes(styles['container'], props.className)}>
      {props.items.map((ag, idx1) => (
        <dl key={ag.key} className={styles['remove-space']}>
          {ag.name && <dt className={styles['list-header']}>{ag.name}</dt>}
          <dd
            className={styles['remove-space']}
            style={{
              paddingLeft: ag.name ? 10 : 0,
            }}
          >
            {ag.items.map((ai, idx2) => (
              <AddressItem key={ai.key} item={ai} relatedId={props.relatedId} />
            ))}
          </dd>
        </dl>
      ))}
    </div>
  );
}

export default AddressList;
