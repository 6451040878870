import { EntityHTML, EntityHTMLParams } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { VisualEntities, VisualInterfaces } from './visual-definitions';

export type EntityHTMLEntity = Entity & EntityHTML;
export const EntityHTMLEntityModel: EntityModel = {
  type: VisualEntities.EntityHTML,
  props: ['template', 'params'],
};
export function createEntityHTMLEntity(
  template: string,
  params: EntityHTMLParams = {}
): EntityHTMLEntity {
  return { entityType: VisualEntities.EntityHTML, template: template, params: params };
}

/** Interface anonymizing HTML */
export const AnonymizedEntityHTMLModel: Interface = {
  type: VisualInterfaces.AnonymizedEntityHTML,
  requires: VisualEntities.EntityHTML,
};
