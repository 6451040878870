import { ClockTimestamp, clone, shallowClone } from '@sqior/js/data';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import { PatientOverview } from '@sqior/viewmodels/patient';
import {
  getWorkflowPathFromOverview,
  WorkflowPathResponse,
  WorkflowPathStepLabelType,
} from './workflow-path';

export function WorkflowPathOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  const resp = op.data as WorkflowPathResponse;

  /* Create overlay inserting temporary string if not found */
  let timestamp: ClockTimestamp | undefined;
  const overlay = StateOverlay.create<PatientOverview>(
    (value) => {
      /* Try to find the corresponding button */
      const workflow = getWorkflowPathFromOverview(value);
      if (value.content && workflow)
        for (let i = 0; i < workflow.steps.length; i++) {
          const step = workflow.steps[i];
          if (step.id === resp.id && step.label.type === WorkflowPathStepLabelType.Button) {
            timestamp = workflow.timestamp;
            if (
              !resp.timestamp ||
              op.state !== OperationState.Completed ||
              (timestamp && timestamp <= resp.timestamp)
            ) {
              const res = shallowClone(value);
              res.content = shallowClone(value.content);
              const newWorkflow = (res.content['workflow'] = shallowClone(workflow));
              newWorkflow.steps = shallowClone(workflow.steps);
              newWorkflow.steps[i] = clone(workflow.steps[i]);
              newWorkflow.steps[i].label.pending = true;
              return res;
            }
          }
        }
      /* Disable the overlay if the button is no longer found */
      setTimeout(() => overlay.clear(), 0);
      return value;
    },
    { info: { entityType: '', name: '' } }
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (
      state === OperationState.Failed ||
      (state === OperationState.Completed &&
        timestamp &&
        resp.timestamp &&
        timestamp > resp.timestamp)
    )
      overlay.clear();
  });

  return overlay;
}
