import { Operation } from '@sqior/js/operation';
import { ValueInspectionStateOverlay } from '@sqior/js/state-operation';

export function PhoneNumberEntryOverlay(op: Operation) {
  return ValueInspectionStateOverlay<string, boolean>(
    op,
    false,
    (state) => {
      return !state;
    },
    () => {
      return false;
    }
  );
}
