import { useEffect, useState } from 'react';

/* Helper class that makes sure that the initialization is only performed once */
class InitHelper<Type> {
  constructor(initializer: () => Type) {
    this.initializer = initializer;
    this.value = undefined;
  }

  init() {
    /* Make sure this is only executed once */
    if (this.value) return this.value;
    /* Create the value */
    this.value = this.initializer();

    return this.value;
  }

  private initializer: () => Type;
  private value?: Type;
}

/* Variant of the useState hook that takes a lambda function to not execute the constructor
   of a default value more than once */
export function useStateOnce<Type>(initializer: () => Type, destructor?: (value: Type) => void) {
  /* Call the normal useState hook for a helper object that keeps us track if this is called
     for the first time */
  const [helper] = useState(new InitHelper(initializer));

  /* Create the normal useState for the production value, only exeucting the lambda if this is
     the first call */
  const value = useState(helper.init());

  /* Register a destructor via useEffect() if one is desired */
  /* (Note: return value of useEffect-function is the clean-up code of the effect, thus destructor) */
  useEffect(() => {
    return () => {
      if (destructor) destructor(value[0]);
    };
  }, []);

  return value;
}

export default useStateOnce;
