import { WardSpecialtiesStatsInfoEntity } from '@sqior/viewmodels/occupancy';
import { FC } from 'react';
import BedSpecialityView, {
  BlockedBed,
  FreeBed,
  OccupiedBed,
  OverflowBed,
} from './components/bed-speciality-view/bed-speciality-view';
import styles from './bed-chart-statistic.module.css';
import { classes } from '@sqior/react/utils';

export interface Props {
  data: WardSpecialtiesStatsInfoEntity;
}

export const BedChartStatistic: FC<Props> = ({ data }) => {
  const free = data.totals.freeBedsLater;
  const blocked = data.totals.quota - data.totals.availableBedsLater;
  const occupied = data.totals.patientsLater;
  const overflow = Math.max(-data.totals.patientsLater - data.totals.availableBedsLater, 0);
  return (
    <>
      {data.specialties.map((specialty) => (
        <BedSpecialityView data={specialty} key={specialty.specialty} />
      ))}

      {false && (
        <div className={styles['z-table']}>
          <LegendView icon={<OccupiedBed />} label="Belegt" count={occupied} align="top-left" />
          <LegendView icon={<FreeBed />} label="Frei" count={free} align="top-right" />
          <LegendView
            icon={<OverflowBed />}
            label="Überbelegt"
            count={overflow}
            align="bottom-left"
          />
          <LegendView
            icon={<BlockedBed size={14} />}
            label="Gesperrt"
            count={blocked}
            align="bottom-right"
          />
        </div>
      )}
    </>
  );
};

export default BedChartStatistic;

interface LegendViewProps {
  icon: React.ReactNode;
  label: string;
  count: number;
  align: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const LegendView: FC<LegendViewProps> = ({ icon, label, count, align }) => {
  const getContainerClass = (): string => {
    switch (align) {
      case 'top-left':
        return classes(styles['cell'], styles['top-left']);
      case 'top-right':
        return classes(styles['cell'], styles['top-right']);
      case 'bottom-left':
        return classes(styles['cell'], styles['bottom-left']);
      case 'bottom-right':
        return classes(styles['cell'], styles['bottom-right']);
      default:
        return '';
    }
  };

  return (
    <div className={getContainerClass()}>
      <div className={styles['legend']}>{icon}</div>
      <div>{label}</div>
      <div className={styles['count']}>{count}</div>
    </div>
  );
};
