import { EntityModel, Interface, TextEntity } from '@sqior/js/meta';
import { VisualEntities, VisualInterfaces } from './visual-definitions';

/** Entity containing HTML text */
export const HTMLEntityModel: EntityModel = { type: VisualEntities.HTML, props: ['text'] };
export function makeHTML(html: string): TextEntity {
  return { entityType: VisualEntities.HTML, text: html };
}

/** Entity containing HTML text without classified data */
export const AnonymousHTMLModel: EntityModel = {
  type: VisualEntities.AnonymousHTML,
  props: ['text'],
  unclassified: true,
};
export function makeAnonymousHTML(html: string): TextEntity {
  return { entityType: AnonymousHTMLModel.type, text: html };
}

/** Interface anonymizing HTML */
export const AnonymizedHTMLModel: Interface = {
  type: VisualInterfaces.AnonymizedHTML,
  requires: VisualEntities.AnonymousHTML,
};
