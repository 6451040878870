import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { PersonEntities, PersonInterfaces } from './person-definitions';

/* Name of a human person */

export type PersonName = Entity & { firstName: string; lastName: string };
export const PersonNameModel: EntityModel = {
  type: PersonEntities.PersonName,
  props: ['firstName', 'lastName'],
};
export function createPersonName(firstName: string, lastName: string): PersonName {
  return { entityType: PersonEntities.PersonName, firstName: firstName, lastName: lastName };
}

/* Interface to get fluent name (in spoken order) of person */

export const FluentNameModel: Interface = {
  type: PersonInterfaces.FluentName,
  requires: CoreEntities.Text,
};

/* Interface to get person name */

export const GetPersonNameModel: Interface = {
  type: PersonInterfaces.Name,
  requires: PersonEntities.PersonName,
};

/* Interface to get person name with first name shortened via initials */

export const ShortPersonNameModel: Interface = {
  type: PersonInterfaces.ShortName,
  requires: CoreEntities.Text,
};
