import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { TrafficLightState } from '@sqior/viewmodels/visual';
import { VisualEntities } from './visual-definitions';

/** Entity representing the states of a traffic light (red/yellow/green) */
export type TrafficLightEntity = EntityHeader & { state: TrafficLightState };
export const TrafficLightModel: EntityModel = {
  type: VisualEntities.TrafficLight,
  props: ['state'],
};
export function makeTrafficLight(state: TrafficLightState): TrafficLightEntity {
  return { entityType: TrafficLightModel.type, state };
}
