import { ValueObject } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { ComponentFactory } from '@sqior/react/factory';
import { useState, useRef, useContext } from 'react';

/* eslint-disable-next-line */
export interface SelectionStackProps {
  data: [SelectionStackData, SelectionStackItem[]];
}

export function SelectionStack(props: SelectionStackProps) {
  const FactoryComponent = useContext(ComponentFactory);
  return (
    (props.data.length && (
      <>
        {props.data[1].map((sel, index) => (
          <FactoryComponent
            key={index}
            onClose={(ok, data) => {
              sel.cb(ok ? ok : false, data);
              props.data[0].remove(index);
            }}
            data={sel.data}
          />
        ))}
      </>
    )) ||
    null
  );
}

export type SelectionStackItem = {
  data: Entity;
  cb: (ok: boolean, data?: ValueObject) => void;
};

export function useSelectionStack(): [SelectionStackData, SelectionStackItem[]] {
  const [stackData, setStackData] = useState<SelectionStackItem[]>([]);

  const stack = useRef<SelectionStackData>(
    new SelectionStackData((items: SelectionStackItem[]) => {
      setStackData([...items]); // Create new object here, otherwise the setState() will not trigger a re-render
    })
  );

  return [stack.current, stackData];
}

export class SelectionStackData {
  constructor(changeCB: (items: SelectionStackItem[]) => void) {
    this.stack = [];
    this.onChanged = changeCB;
  }

  add(item: SelectionStackItem) {
    this.stack.push(item);
    this.onChanged(this.stack);
  }

  remove(index: number) {
    this.stack.splice(index);
    this.onChanged(this.stack);
  }

  clear() {
    this.stack.length = 0;
    this.onChanged(this.stack);
  }

  readonly onChanged: (items: SelectionStackItem[]) => void;
  readonly stack: SelectionStackItem[];
}

export default SelectionStack;
