import {
  getSelectionOptionMixIn,
  SelectionFromOptions,
  SelectionInput,
} from '@sqior/viewmodels/input';
import { FactoryProps } from '@sqior/react/factory';
import { SelectionAreaProps } from '../selection-area';
import styles from './options-input-area.module.css';
import { ValueObject } from '@sqior/js/data';

type OptionsInputAreaProps = FactoryProps<SelectionFromOptions> & SelectionAreaProps;

export function OptionsInputArea(props: OptionsInputAreaProps) {
  return (
    <div className={styles['container']}>
      <OptionsInput options={props.data.options} onClick={(v) => props.onSelection(v)} />
    </div>
  );
}

export type OptionsInputParams = {
  options: SelectionInput[];
  onClick: (option: ValueObject, completeOption?: SelectionInput) => void;
};
export function OptionsInput(props: OptionsInputParams) {
  return (
    <>
      {props.options.map((option, index) => (
        <button
          className={styles[index > 0 ? 'option' : 'option-top']}
          onClick={() => {
            props.onClick(getSelectionOptionMixIn(option), option);
          }}
          key={option.visual}
        >
          <div className={styles['option-text']}>{option.visual}</div>
        </button>
      ))}
    </>
  );
}

export default OptionsInputArea;
