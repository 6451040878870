import { FormControl, OutlinedSelectProps, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

export interface SqiorSelectProps extends OutlinedSelectProps {
  children: React.ReactNode;
  value: string;
  onChange?: (event: SelectChangeEvent) => void;
  defaultValue?: string;
}

const SX = {
  backgroundColor: '#121930',
  borderWidth: 2,
  borderRadius: '10px',
  minWidth: '120px',
  '& .MuiSelect-icon': {
    color: '#51535E', // You can use theme colors or any CSS color value
  },
  '.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#242A42',
      borderRadius: '10px',
      borderWidth: '2px',
    },
    '&:hover fieldset': {
      borderColor: '#242A42',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#242A42',
    },
  },
};

export const SqiorSelect: FC<SqiorSelectProps> = ({
  children,
  value,
  onChange,
  defaultValue,
  ...rest
}) => {
  return (
    <FormControl sx={SX}>
      <Select
        style={{ height: 45 }}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default SqiorSelect;
