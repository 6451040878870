import { ClockTimestamp } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel, TextTemplate } from '@sqior/js/meta';
import { resourceTextTemplate } from '@sqior/plugins/language';
import { TimeEntities } from './time-definitions';
import { ProbabilisticTimestamp } from './timestamp';

/* Duration measures in milliseconds */

export type DurationEntity = EntityHeader & { duration: number };
export const DurationEntityModel: EntityModel = {
  type: TimeEntities.Duration,
  props: ['duration'],
  unclassified: true,
};
export function makeDuration(duration: number, type = TimeEntities.Duration): DurationEntity {
  return { entityType: type, duration: duration };
}

/** Array of durations */

export type DurationsEntity = EntityHeader & { durations: Entity[] };
export const DurationsModel: EntityModel = {
  type: TimeEntities.Durations,
  props: ['durations'],
};
export function makeDurations(durations: Entity[]): DurationsEntity {
  return { entityType: DurationsModel.type, durations };
}

/* Cachable timer measures in milliseconds */

export const TimerDurationEntityModel: EntityModel = {
  type: TimeEntities.TimerDuration,
  props: ['duration'],
  keys: ['duration'],
  unclassified: true,
};

/* Probabilistic duration */

export type ProbabilisticDuration = {
  duration: ClockTimestamp;
  low: ClockTimestamp;
  high: ClockTimestamp;
};
export type ProbabilisticDurationEntity = DurationEntity & ProbabilisticDuration;
export const ProbabilisticDurationModel: EntityModel = {
  type: TimeEntities.ProbabilisticDuration,
  props: ['low', 'high'],
  extends: TimeEntities.Duration,
};
export function makeProbabilisticDuration(dur: ProbabilisticDuration): ProbabilisticDurationEntity {
  return { entityType: TimeEntities.ProbabilisticDuration, ...dur };
}

export function addProbabilisticDuration(
  probTimestamp: ProbabilisticTimestamp,
  probDuration: ProbabilisticDuration
): ProbabilisticTimestamp {
  const low =
    Math.pow(probTimestamp.timestamp - probTimestamp.low, 2) + Math.pow(probDuration.duration, 2);
  const high =
    Math.pow(probTimestamp.high - probTimestamp.timestamp, 2) + Math.pow(probDuration.high, 2);
  const mean = probTimestamp.timestamp + probDuration.duration;
  return {
    timestamp: mean,
    low: mean + (low > 0 ? Math.sqrt(low) : 0),
    high: mean + (high > 0 ? Math.sqrt(high) : 0),
  };
}

/* Function adding a duration to a timestamp */

export type AddDurationEntity = EntityHeader & { timestamp: Entity; duration: Entity };
export const AddDurationModel: EntityModel = {
  type: TimeEntities.AddDuration,
  props: ['timestamp', 'duration'],
};
export function addDuration(timestamp: Entity, duration: Entity): AddDurationEntity {
  return { entityType: TimeEntities.AddDuration, timestamp: timestamp, duration: duration };
}

/* Function determining a duration from the difference of two timestamps */

export type TimestampDifferenceEntity = EntityHeader & { earlier: Entity; later: Entity };
export const TimestampDifferenceDurationModel: EntityModel = {
  type: TimeEntities.TimestampDifference,
  props: ['earlier', 'later'],
};
export function timestampDifference(earlier: Entity, later: Entity): TimestampDifferenceEntity {
  return { entityType: TimeEntities.TimestampDifference, earlier: earlier, later: later };
}

/* Function performing a less than comparison of two durations */

export type DurationLessThan = EntityHeader & { value: Entity; ref: Entity };
export const DurationLessThanModel: EntityModel = {
  type: TimeEntities.DurationLessThan,
  props: ['value', 'ref'],
};
export function durationLessThan(value: Entity, ref: Entity): DurationLessThan {
  return { entityType: TimeEntities.DurationLessThan, value: value, ref: ref };
}

/* Text informing that something will happen in a certain duration */

export function inDurationText(duration: Entity): TextTemplate {
  return resourceTextTemplate('in_duration', { dur: duration });
}
