import { ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import { InterweaveExt } from '@sqior/react/uibase';
import QRVideoControl from '../qrvideo-control/qrvideo-control';
import styles from './qrpage.module.css';
import { FactoryProps } from '@sqior/react/factory';

/* eslint-disable-next-line */
export interface QRPageProps {
  title: string;
  description: string;
  onClose: () => void;
}

export function QRPage(props: QRPageProps) {
  const textDict = useTextResources();
  return (
    <ClosablePage onClose={props.onClose}>
      <div className={styles['title']}>{textDict.get(props.title)}</div>
      <div className={styles['container']}>
        <QRVideoControl onClose={props.onClose} />
        <InterweaveExt
          className={styles['description']}
          content={textDict.get(props.description)}
          disableMatchers
        />
        <div className={styles['bottombar']}>
          <HighlightButton onClick={props.onClose}>{textDict.get('cancel')}</HighlightButton>
        </div>
      </div>
    </ClosablePage>
  );
}

export function QRUserPage(props: FactoryProps) {
  return (
    <QRPage
      title="qr_code_scan"
      description="qr_code_scan_description"
      onClose={() => props.onClose?.(true, undefined)}
    />
  );
}

export default QRPage;
