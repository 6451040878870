import { TemporaryText } from '@sqior/viewmodels/input';
import { isFinal, StreamOperation } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import { useContext, useEffect, useState } from 'react';
import { ConversationContext } from '../conversation-context';
import { InputPart } from '../input-control/input-control-processor';
import InputProposalSelection from '../input-proposal-selection/input-proposal-selection';
import styles from './simple-interpreter-menu.module.css';

export interface SimpleInterpreterMenuProps {
  interpreterKey: string;
  itemSelected?: () => void;
}

export function SimpleInterpreterMenu(props: SimpleInterpreterMenuProps) {
  const inputPanelLogic = useContext(ConversationContext);

  const tempTextState = useDynamicState<TemporaryText[]>(
    `context-command-state/${props.interpreterKey}`,
    []
  );

  const dispatcher = useContext(OperationContext);
  const [operation, setOperation] = useState<StreamOperation | undefined>(undefined);
  useEffect(() => {
    function startOperation() {
      const op = new StreamOperation({ interpreterKey: props.interpreterKey });
      op.stateChange.on((state) => {
        if (isFinal(state)) {
          setOperation(undefined);
        }
      });
      dispatcher.handle(op, `context-command/${props.interpreterKey}`);
      setOperation(op);
    }

    if (operation === undefined && props.interpreterKey !== undefined && dispatcher !== undefined)
      startOperation();

    return () => {
      // Cleanup
      if (operation) operation.close();
    };
  }, [props.interpreterKey, dispatcher, operation]);

  function handleItemSelected(inputPart: InputPart, value: string) {
    if (tempTextState.length === 0) return;
    /* Find selected value */
    let complexInput = tempTextState.length > 1;
    if (tempTextState[0].text instanceof Array)
      for (const o of tempTextState[0].text)
        if (o.option === value) {
          complexInput = o.moreToCome || complexInput;
          break;
        }
    console.log('handleItemSelected', inputPart, value, complexInput);
    if (complexInput) {
      inputPanelLogic?.takeOverInterpreter(props.interpreterKey, value);
    } else {
      operation?.send({ finalize: value });
    }

    props.itemSelected?.();
  }

  return (
    <div className={styles['container']}>
      {tempTextState.length > 0 && (
        <InputProposalSelection
          part={{ text: '', item: tempTextState[0] }}
          itemSelected={handleItemSelected}
        />
      )}
    </div>
  );
}

export default SimpleInterpreterMenu;
