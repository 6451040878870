import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import TreatmentPathPatientListItem from './treatment-path-patient-list-item/treatment-path-patient-list-item';
import {
  ReleaseTreatmentPathOverlay,
  TreatmentPathItemAdaptType,
  TreatmentPathOverviewType,
  TreatmentPathPatientListItemType,
  TreatmentPathReleasePath,
} from '@sqior/viewmodels/treatment-path';
import TreatmentPathOverview from './treatment-path-overview/treatment-path-overview';
import TreatmentPathItemAdapt from './treatment-path-item-adapt/treatment-path-item-adapt';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { PatientOverviewPath } from '@sqior/viewmodels/patient';

/** Plugin for react components related to treatment path handling */

export function UITreatmentPathPlugin(
  context: PluginContext & FactoryContext & StateOverlayContext
) {
  context.plugins.require(UIVisualPlugin, context);
  /* Factory components */
  context.factory.add(TreatmentPathItemAdaptType, TreatmentPathItemAdapt);
  context.factory.add(TreatmentPathPatientListItemType, TreatmentPathPatientListItem);
  context.factory.add(TreatmentPathOverviewType, TreatmentPathOverview);
  /* State overlays */
  context.stateOverlays.add(
    TreatmentPathReleasePath,
    ReleaseTreatmentPathOverlay,
    PatientOverviewPath
  );
}
