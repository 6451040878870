import styles from './sqior-file-upload.module.css';
import { ReactComponent as CameraIcon } from './images/camera.svg';
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import SqiorPhotoShoot, { FileAttachment } from '../sqior-photo-shoot/sqior-photo-shoot';
import { UploadMedia } from '@sqior/js/media';
import { Bytes } from '@sqior/js/data';
import { OperationState } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import SqiorFileUploadGallery from '../sqior-file-upload-gallery/sqior-file-upload-gallery';
import InteractiveImagePreview from '../interactive-image-preview/interactive-image-preview';
import SvgButton from '../svg-button/svg-button';
import { Emitter } from '@sqior/js/event';

interface Props {
  setAttachments: Dispatch<SetStateAction<FileAttachment[]>>;
  iconClassName: string;
  disabled?: boolean;
  trigger?: Emitter;
}

export const SqiorFileUpload: FC<Props> & {
  Gallery: typeof SqiorFileUploadGallery;
  Preview: typeof InteractiveImagePreview;
} = ({ setAttachments, iconClassName, disabled, trigger }) => {
  const dispatcher = useContext(OperationContext);
  const [openTakePhoto, setOpenTakePhoto] = useState(false);

  /* Connect to trigger signal, if applicable */
  useEffect(() => {
    if (trigger && !openTakePhoto && !disabled)
      return trigger.on(() => {
        setOpenTakePhoto(true);
      });
    return undefined;
  }, [trigger, setOpenTakePhoto, disabled, openTakePhoto]);

  const onPhotoSubmit = (image: FileAttachment) => {
    setAttachments((prev) => [...prev, image]);

    const uploadOperation = dispatcher.start(
      UploadMedia(Date.now(), image.mimeType, new Bytes(image.blob))
    );

    uploadOperation.progressChange.on((progress) => {
      setAttachments((prev) => {
        return prev.map((_) => {
          if (_.id === image.id) {
            return {
              ..._,
              progress,
            };
          }
          return _;
        });
      });
    });

    uploadOperation.stateChange.on((state) => {
      if (state === OperationState.Completed && 'finalId' in uploadOperation) {
        setAttachments((prev) => {
          return prev.map((_) => {
            if (_.id === image.id) {
              return {
                ..._,
                finalId: uploadOperation.finalId as string,
              };
            }
            return _;
          });
        });
      }
    });
  };

  return (
    <>
      {openTakePhoto && (
        <SqiorPhotoShoot
          onClose={() => {
            setOpenTakePhoto(false);
          }}
          onPhotoSubmit={onPhotoSubmit}
        />
      )}
      <SvgButton className={styles['button-container']}>
        <CameraIcon
          className={iconClassName}
          onClick={() => {
            setOpenTakePhoto(!disabled);
          }}
        />
      </SvgButton>
    </>
  );
};

export default SqiorFileUpload;

SqiorFileUpload.Gallery = SqiorFileUploadGallery;
SqiorFileUpload.Preview = InteractiveImagePreview;
