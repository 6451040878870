import { EntityHeader } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel } from './entity';

/* Definition of the basic notification entity */

export type NotificationEntity = EntityHeader;
export const NotificationEntityModel: EntityModel = { type: CoreEntities.Notification, props: [] };
export function createNotificationEntity(type: string): NotificationEntity {
  return { entityType: type };
}
