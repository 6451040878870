import { FC } from 'react';
import styles from './next-bed-view.module.css';
import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import { PillColor } from '../bed-view/bed-view';
import { PersonSex } from '@sqior/viewmodels/person';
import { useTextResources } from '@sqior/react/uibase';

interface NextBedViewProps {
  bed: BedAllocationWardListItemBed;
  height: number;
}

const getPillColor = (sex: PersonSex) => {
  switch (sex) {
    case PersonSex.Male:
      return PillColor.Male;
    case PersonSex.Female:
      return PillColor.Female;
    default:
      return PillColor.Unisex;
  }
};

const NextBedView: FC<NextBedViewProps> = ({ bed, height }) => {
  const textDic = useTextResources();
  const currentBed = bed.current;
  const nextBed = bed.later;
  if (
    currentBed.state === BedAllocationWardListItemBedState.Occupied &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return (
      <PillWrapper bed={bed} height={height}>
        {textDic.get('discharge_today')}
      </PillWrapper>
    );
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Blocked &&
    nextBed.state === BedAllocationWardListItemBedState.Blocked
  ) {
    return (
      <PillWrapper bed={bed} height={height}>
        {textDic.get('will_be_blocked')}
      </PillWrapper>
    );
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Free &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return (
      <PillWrapper bed={bed} height={height}>
        {textDic.get('will_be_free')}
      </PillWrapper>
    );
  }

  return null;
};

export default NextBedView;

const PillWrapper: FC<{
  bed: BedAllocationWardListItemBed;
  children: React.ReactNode;
  height: number;
}> = ({ bed, children, height }) => {
  return (
    <div
      className={styles['e-wrapper']}
      style={{
        backgroundColor: getPillColor(bed?.later?.sex as PersonSex),
        height,
      }}
    >
      {children}
    </div>
  );
};
