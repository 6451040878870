export function longesCommonSubsequenceArr(a: string[], b: string[]): string[] {
  const m = a.length;
  const n = b.length;
  let i,
    j = 0;
  const C: number[][] = [];

  for (i = 0; i <= m; i++) C.push([0]);
  for (j = 0; j < n; j++) C[0].push(0);

  for (i = 0; i < m; i++)
    for (j = 0; j < n; j++)
      C[i + 1][j + 1] = a[i] === b[j] ? C[i][j] + 1 : Math.max(C[i + 1][j], C[i][j + 1]);

  return (function bt(i, j): string[] {
    if (i * j === 0) {
      return [];
    }
    if (a[i - 1] === b[j - 1]) {
      return [...bt(i - 1, j - 1), a[i - 1]];
    }
    return C[i][j - 1] > C[i - 1][j] ? bt(i, j - 1) : bt(i - 1, j);
  })(m, n);
}

/** Implementation of Longest Common Subsequence algorithm used by convertAndMapToInputChain to correlate previous and current InputChain
 */
export function longesCommonSubsequence(a: string, b: string): string {
  const m = a.length;
  const n = b.length;
  let i,
    j = 0;
  const C: number[][] = [];

  for (i = 0; i <= m; i++) C.push([0]);
  for (j = 0; j < n; j++) C[0].push(0);

  for (i = 0; i < m; i++)
    for (j = 0; j < n; j++)
      C[i + 1][j + 1] = a[i] === b[j] ? C[i][j] + 1 : Math.max(C[i + 1][j], C[i][j + 1]);

  return (function bt(i, j): string {
    if (i * j === 0) {
      return '';
    }
    if (a[i - 1] === b[j - 1]) {
      return bt(i - 1, j - 1) + a[i - 1];
    }
    return C[i][j - 1] > C[i - 1][j] ? bt(i, j - 1) : bt(i - 1, j);
  })(m, n);
}
