import styles from './bottom-navigation.module.css';
import {
  Badge,
  BadgeOrigin,
  BottomNavigation as MUIBottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { MessengerTab, MessengerTabStackItem } from '@sqior/viewmodels/app';
import { SvgIconChat, SvgIconDashboard, SvgIconTasks, useTextResources } from '@sqior/react/uibase';
import { SyntheticEvent } from 'react';
import { useDynamicState } from '@sqior/react/state';
import { ChatListPath, EnrichedChatVM } from '@sqior/viewmodels/communication';
import { getUnreadMessages } from '../app-drawer/app-drawer';

const ANCHOR_ORIGIN: BadgeOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const COMPONENT_PROPS = {
  badge: {
    style: {
      color: 'white',
      top: 8,
    },
  },
};

const NAVIGATION_STYLE = {
  backgroundColor: 'transparent',
  height: '100%',
  width: '100%',
};

export interface BottomNavigationProps {
  tasksCount?: number;
  listCount?: number;
  activeMessengerTab: MessengerTabStackItem;
  setActiveMessengerTab: (tab: MessengerTab) => void;
}

export function BottomNavigation({
  setActiveMessengerTab,
  activeMessengerTab,
  listCount,
  tasksCount,
}: BottomNavigationProps) {
  const chatList = useDynamicState<EnrichedChatVM[]>(ChatListPath, []);
  const textDict = useTextResources();

  const tabs = [
    {
      label: textDict.get('tab_task_label'),
      value: MessengerTab.Todo,
      icon: (
        <SvgIconTasks
          className={styles[activeMessengerTab === MessengerTab.Todo ? 'tab-active' : 'tab-icon']}
        />
      ),
      badgeContent: tasksCount,
    },
    {
      label: textDict.get('tab_list_label'),
      value: MessengerTab.List,
      icon: (
        <SvgIconDashboard
          className={styles[activeMessengerTab === MessengerTab.List ? 'tab-active' : 'tab-icon']}
        />
      ),
      badgeContent: listCount,
    },
    {
      label: textDict.get('tab_chat_label'),
      value: MessengerTab.Conversation,
      icon: (
        <SvgIconChat
          className={
            styles[activeMessengerTab === MessengerTab.Conversation ? 'tab-active' : 'tab-icon']
          }
        />
      ),
      badgeContent: getUnreadMessages(chatList),
    },
  ];

  const handleTabChange = (event: SyntheticEvent, newValue: MessengerTab) =>
    setActiveMessengerTab(newValue);

  return (
    <MUIBottomNavigation
      value={activeMessengerTab}
      onChange={handleTabChange}
      style={NAVIGATION_STYLE}
    >
      {tabs.map(({ label, value, icon, badgeContent }) => (
        <BottomNavigationAction
          key={label}
          label={label}
          value={value}
          icon={
            <Badge
              color="primary"
              showZero={false}
              componentsProps={COMPONENT_PROPS}
              badgeContent={badgeContent}
              anchorOrigin={ANCHOR_ORIGIN}
            >
              {icon}
            </Badge>
          }
        />
      ))}
    </MUIBottomNavigation>
  );
}

export default BottomNavigation;
