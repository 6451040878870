import { Eternity, NoTimestamp, LogicalTimestamp, Value } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';

export type OverlayDestructor = () => void;
export type DefaultedValueTransformation<Type = Value> = (
  value: Type,
  timestamp: LogicalTimestamp
) => Type;
export type ValueTransformation<Type = Value> = (
  value: Type | undefined,
  timestamp: LogicalTimestamp
) => Type | undefined;

export class StateOverlay {
  static create<Type extends Value>(
    trans: DefaultedValueTransformation<Type>,
    defValue: Type,
    timestamp = Eternity,
    destructor?: OverlayDestructor
  ) {
    return new StateOverlay(
      (value, timestamp) => {
        return trans(value === undefined ? defValue : (value as Type), timestamp);
      },
      timestamp,
      destructor
    );
  }
  static createRaw<Type extends Value>(
    trans: ValueTransformation<Type>,
    timestamp = Eternity,
    destructor?: OverlayDestructor
  ) {
    return new StateOverlay(
      (value, timestamp) => {
        return trans(value as Type, timestamp);
      },
      timestamp,
      destructor
    );
  }

  constructor(
    trans: ValueTransformation,
    timestamp: LogicalTimestamp,
    destructor?: OverlayDestructor
  ) {
    this.trans = trans;
    this.transformationChange = new Emitter();
    this.timestamp = timestamp;
    this.timestampChange = new Emitter<[LogicalTimestamp]>();
    this.destructor = destructor;
  }

  /* Notifies about a change concerning the transformation */
  notify() {
    this.transformationChange.emit();
  }

  /* Clears an overlay (by internally setting the timestamp to a value that cannot be underrun) */
  clear() {
    this.setTimestamp(NoTimestamp);
  }

  setTimestamp(timestamp: LogicalTimestamp) {
    /* Remember */
    this.timestamp = timestamp;

    /* Emit event for pot. listeners */
    this.timestampChange.emit(timestamp);
  }

  /* Clean up method only to be called by the managing entity of this (usually the State) */
  destroy() {
    /* Make sure no more listeners are called */
    this.transformationChange.clear();
    this.timestampChange.clear();

    /* Call destructing method */
    if (this.destructor) this.destructor();
  }

  readonly trans: ValueTransformation;
  readonly transformationChange: Emitter;
  timestamp: LogicalTimestamp;
  readonly timestampChange: Emitter<[LogicalTimestamp]>;
  private destructor?: OverlayDestructor;
}
