import { OperationSpec, OperationType } from '@sqior/js/operation';

export enum PushSystem {
  APN = 'APN',
  Firebase = 'Firebase',
  Test = 'Test',
}
export type PushDevice = { system: PushSystem; token: string };

export type PushDeviceRegistration = { device: PushDevice; mobile: boolean };
export const RegisterPushDevicePath = 'device-token';
export function RegisterPushDevice(
  device: PushDevice,
  mobile: boolean
): OperationSpec<PushDeviceRegistration> {
  return {
    type: OperationType.Add,
    path: RegisterPushDevicePath,
    data: { device: device, mobile: mobile },
  };
}
