import { useDynamicState } from '@sqior/react/state';
import ChatList from '../chat-list/chat-list';
import styles from './chat-page.module.css';
import { ChatListPath, EnrichedChatVM } from '@sqior/viewmodels/communication';
import { CHAT_PERSIST_ROOT_KEY } from '../chat/chat';
import { useContext } from 'react';
import { AuthContext } from '@sqior/react/uiauth';

/* eslint-disable-next-line */
export interface ChatPageProps {}

interface PersistedKey {
  key: string;
  userId: string;
  chatId: string;
}

const getAllChatPersistedKeys = (): PersistedKey[] => {
  const allPersistedKeys: PersistedKey[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) continue;
    if (!key.startsWith(CHAT_PERSIST_ROOT_KEY)) continue;

    const parts = key.split('?');
    if (parts.length !== 3) continue;

    const persistedKey: PersistedKey = {
      key,
      chatId: parts[1],
      userId: parts[2],
    };

    allPersistedKeys.push(persistedKey);
  }

  return allPersistedKeys;
};

const getNonEmptyChats = (chats: EnrichedChatVM[], userId?: string): EnrichedChatVM[] => {
  if (!userId) {
    console.error('userId is not defined');
    return [];
  }

  const persistedKeys = getAllChatPersistedKeys();

  const filteredChats: EnrichedChatVM[] = [];

  chats.forEach((chat) => {
    if (chat.showAlways) {
      filteredChats.push(chat);
    } else {
      const chatId = chat.id['id'];
      const hasPersistedValue = persistedKeys.find(
        (key) => key.chatId === chatId && key.userId === userId
      );
      const isEmpty = chat.isEmpty;
      if (isEmpty && hasPersistedValue) filteredChats.push(chat);
      if (!isEmpty) filteredChats.push(chat);
    }
  });

  return filteredChats;
};

export function ChatPage(props: ChatPageProps) {
  const context = useContext(AuthContext);
  const userId = context.provider.userInfo.userId;

  const chatList = useDynamicState<EnrichedChatVM[]>(ChatListPath, []);
  return (
    <div className={styles['container']}>
      <ChatList chats={getNonEmptyChats(chatList, userId)} />
    </div>
  );
}

export default ChatPage;
