import { Entity, IdEntity } from '@sqior/js/entity';
import { PhoneNumberPair } from '@sqior/viewmodels/user';
import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { OperationSpec } from '@sqior/js/operation';
import { URLEntity } from '@sqior/viewmodels/visual';

export type ChatListVM = ChatVM[];

export const PageChatType = 'page.chat';

export enum ChatVMType {
  Person,
  Group,
  Info,
  PatientRelated,
}
export type ChatVM<IdType extends Entity = IdEntity, URLType extends Entity = URLEntity> = {
  id: IdType;
  listName: string;
  pageName?: string;
  listSubtitle?: string | [string, string]; // Subtitle to display on the chat list
  pageSubtitle?: string; // Subtitle to display on the chat page
  type: ChatVMType;
  typeUrl?: URLType;
  timestamp?: ClockTimestamp;
  lastMessageTimestamp?: ClockTimestamp;
};
export type EnrichedChatVM<
  IdType extends Entity = IdEntity,
  URLType extends Entity = URLEntity
> = ChatVM<IdType, URLType> & {
  select: OperationSpec<ValueObject>;
  unread: number;
  isEmpty?: boolean;
  showAlways?: boolean;
};

// Conatains details about the chat, used at the chat page to dsiplay all details
export type ChatDetailsVM<IdType extends Entity = IdEntity, URLType extends Entity = URLEntity> = {
  selectEntities?: Entity; // Entity being feed into SelectPatient when clicked on header
  chatVM: ChatVM<IdType, URLType>; // Detail information on chat
  participants?: AddressGroupInfoVM[]; // Information on chat participants (to display participant list)
  addresses?: AddressInfoVM[];
  ownAddressIndex?: number; // Index of the own address in the addresses array
};

export enum OnlineStatus {
  NeverSeen,
  Offline,
  Online,
  Away,
}

export enum AddressInfoVMType {
  Role = 'role',
  User = 'user',
}

export type AddressInfoVM = {
  id?: Entity;
  key: string;
  name: string; // Main name to display, e.g. Dr. Hans Maier
  subname?: string; // Subname to display, e.g. a role like Anesthesist
  lastConnect?: number;
  onlineStatus: OnlineStatus;
  phoneNumbers: PhoneNumberPair[];
  email?: string;
  chatAddress?: Entity; // An entity referencing an Address to be used to communicate via a chat, typically used to create a chat
  profilePicture?: URLEntity; // An entity referencing a profile picture
  type?: AddressInfoVMType;
};

export type AddressGroupInfoVM = {
  key: string;
  name?: string; // If not set, the items shall not be displayed hierachically
  chatAddress?: Entity; // An entity referencing an Address to be used to communicate via a chat, typically used to create a chat
  items: AddressInfoVM[];
};

export function addressesFromAddressGroups(groups: AddressGroupInfoVM[]): AddressInfoVM[] {
  return groups.reduce<AddressInfoVM[]>((prev, curr) => prev.concat(curr.items), []);
}
