import styles from './horizontal-line.module.css';

/* eslint-disable-next-line */
export interface HorizontalLineProps {
  className: string;
}

export function HorizontalLine(props: HorizontalLineProps) {
  return <div className={`${props.className} ${styles['line']}`} />;
}

export default HorizontalLine;
