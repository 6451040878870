import { useTimer } from '@sqior/react/state';
import HighlightButton from '../highlight-button/highlight-button';
import { ReactComponent as IconBack } from './backspace.svg';
import styles from './num-pad.module.css';
import { useEffect, useRef } from 'react';
import { addMilliseconds } from '@sqior/js/data';

export interface NumPadProps {
  select: (num: number) => void;
  back: () => void;
}

export function NumPad(props: NumPadProps) {
  const { timer } = useTimer();
  /* Auto-focus */
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    return timer.schedule(() => {
      container.current?.focus();
    }, addMilliseconds(250));
  }, [timer]);
  return (
    <div
      className={styles['container']}
      ref={container}
      onKeyDown={(ev) => {
        const num = Number.parseInt(ev.key);
        if (!Number.isInteger(num)) return;
        ev.stopPropagation();
        props.select(num);
      }}
      tabIndex={0}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
        <div key={num} className={styles['num-button']} style={{ gridColumn: num ? undefined : 2 }}>
          <HighlightButton
            secondary={true}
            onClick={() => {
              props.select(num);
            }}
          >
            {num}
          </HighlightButton>
        </div>
      ))}
      <IconBack
        className={styles['back']}
        onClick={() => {
          props.back();
        }}
      />
    </div>
  );
}

export default NumPad;
