import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { LocationEntities } from './location-definitions';

/* Location date */
export type LocationDate = EntityHeader & { location: Entity; date: Entity };
export const LocationDateModel: EntityModel = {
  type: LocationEntities.LocationDate,
  props: ['location', 'date'],
  keys: ['location', 'date'],
};
export function makeLocationDate(location: Entity, date: Entity): LocationDate {
  return { entityType: LocationDateModel.type, location: location, date: date };
}
