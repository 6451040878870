import { isEqual, shallowClone } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import { PatientOverview } from '@sqior/viewmodels/patient';
import { TreatmentPathOverviewEntity, TreatmentPathOverviewType } from './treatment-path-vm';

export function ReleaseTreatmentPathOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  const patient = op.data as Entity;
  /* Create overlay checking the item if not checked yet */
  const overlay = StateOverlay.create<PatientOverview>(
    (value) => {
      /* Check if the content actually is a treatment path overview */
      const overview = value.content as TreatmentPathOverviewEntity;
      if (
        overview &&
        overview.entityType === TreatmentPathOverviewType &&
        isEqual(overview.patient, patient)
      ) {
        /* Check if the change flag is still active, if yes, reset it */
        if (overview.changed) {
          const resOverview = shallowClone(overview);
          resOverview.changed = false;
          const res = shallowClone(value);
          res.content = resOverview;
          return res;
        }
      }
      /* Disable the overlay if the overview is no longer found or has the correct state */
      setTimeout(() => overlay.clear(), 0);
      return value;
    },
    { info: { entityType: '', name: '' } }
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
  });

  return overlay;
}
