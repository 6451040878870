import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { VisualEntities } from './visual-definitions';
import { ArraySource, ensureArray } from '@sqior/js/data';

export type AttachmentsEntity = EntityHeader & {
  attachments?: Entity[];
};
export const AttachmentContainerModel: EntityModel = {
  type: VisualEntities.Attachments,
  props: ['attachments'],
};
export function makeAttachments(attachments: ArraySource<Entity>): AttachmentsEntity {
  return { entityType: VisualEntities.Attachments, attachments: ensureArray(attachments) };
}
