import { Entity, SubjectsEntity } from '@sqior/js/entity';
import { CoreEntities, CoreInterfaces } from './core-definitions';
import { EntityModel } from './entity';

/** Entity interface that matches any entity */

export const EntityInterface = { type: CoreInterfaces.Entity };

/** Subjects model */

export const SubjectsModel: EntityModel = { type: CoreEntities.Subjects, props: ['subjects'] };
export function makeSubjects(subjects: Entity[]): SubjectsEntity {
  return { entityType: SubjectsModel.type, subjects };
}
