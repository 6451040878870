import CardLayout from '../card-layout/card-layout';
import StatisticsComparisonPanelItem, {
  StatisticsComparisonPanelItemData,
} from '../statistics-comparison-panel-item/statistics-comparison-panel-item';
import styles from './statistics-comparison-panel.module.css';

/* eslint-disable-next-line */
export interface StatisticsComparisonPanelProps {
  data: StatisticsComparisonPanelItemData[];
}

export function StatisticsComparisonPanel({ data }: StatisticsComparisonPanelProps) {
  return (
    <CardLayout>
      <div className={styles['container']}>
        {data.map((item, index, arr) => (
          <StatisticsComparisonPanelItem
            key={item.title}
            data={item}
            lastItem={index === arr.length - 1}
          />
        ))}
      </div>
    </CardLayout>
  );
}

export default StatisticsComparisonPanel;
