import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { LocationEntities, LocationInterfaces } from './location-definitions';

/* Location function */

export enum RoomFunctions {
  Reception = 'Reception',
  SameDaySurgeryCenter = 'SameDaySurgery',
  HoldingArea = 'Holding',
  Induction = 'Induction',
  Operating = 'OperatingRoom',
  Recovery = 'Recovery',
  Transfer = 'Transfer',
  Ward = 'Ward',
  ICU = 'ICU',
  ER = 'ER',
}
export type RoomFunction = Entity & { func: string };
export const RoomFunctionModel: EntityModel = {
  type: LocationEntities.RoomFunction,
  props: ['func'],
  keys: ['func'],
  unclassified: true,
};
export function makeRoomFunction(func: string): RoomFunction {
  return { entityType: LocationEntities.RoomFunction, func: func };
}

/* Interface representing either a location or function */

export const LocationOrFunction: Interface = {
  type: LocationInterfaces.LocationOrFunction,
};

/* Pair of location and function */

export type LocationAndFunction = Entity & { location: Entity; func: Entity };
export const LocationAndFunctionModel: EntityModel = {
  type: LocationEntities.LocationAndFunction,
  props: ['location', 'func'],
  keys: ['location', 'func'],
};
export function makeLocationFunction(location: Entity, func: Entity): LocationAndFunction {
  return { entityType: LocationEntities.LocationAndFunction, location: location, func: func };
}

/** Test location */

export type TestLocationEntity = EntityHeader & { func: string; index: number };
export const TestLocationModel: EntityModel = {
  type: LocationEntities.TestLocation,
  props: ['func', 'index'],
  unclassified: true,
};
export function makeTestLocation(func: string, index = 0): TestLocationEntity {
  return { entityType: TestLocationModel.type, func: func, index: index };
}
