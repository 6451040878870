import { ensureArray, makeImmutable } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { CoreEntities, CoreInterfaces } from './core-definitions';
import { EntityModel } from './entity';
import { Interface } from './interface';
import { SQIORAssistant } from './sqior-assistant';

/* Result interface */

export const ResultModel: Interface = { type: CoreInterfaces.Result };

/** Success interface */

export const SuccessModel: Interface = { type: CoreInterfaces.Success };

/* Evaluating Function entity */

export type EvaluateEntity = Entity & { entity: Entity };
export const EvaluateEntityModel: EntityModel = {
  type: CoreEntities.Evaluate,
  props: ['entity'],
};
export function makeEvaluation(entity: Entity): EvaluateEntity {
  return { entityType: CoreEntities.Evaluate, entity: entity };
}

/* Mapping Function entity */

export type MapEntity = Entity & { entity: Entity | Entity[]; targetTypes: string[] };
export const MapEntityModel: EntityModel = {
  type: CoreEntities.Map,
  props: ['entity', 'targetTypes'],
};
export function makeMapping(entity: Entity | Entity[], type: string | string[]): MapEntity {
  return { entityType: CoreEntities.Map, entity: entity, targetTypes: ensureArray(type) };
}

/* If-then-else entity */

export type IfEntity = Entity & { condition: Entity; yes?: Entity; no?: Entity };
export const IfEntityModel: EntityModel = {
  type: CoreEntities.If,
  props: ['condition', 'yes', 'no'],
};
export function makeIf(condition: Entity, yes?: Entity, no?: Entity): IfEntity {
  const res: IfEntity = { entityType: CoreEntities.If, condition: condition };
  if (yes) res.yes = yes;
  if (no) res.no = no;
  return res;
}
export function makeNot(condition: Entity) {
  return makeIf(condition, undefined, SQIORAssistant);
}

/* Backup Function entity */

export type BackupEntity = Entity & { entities: Entity[] };
export const BackupEntityModel: EntityModel = {
  type: CoreEntities.Backup,
  props: ['entities'],
};
export function makeBackup(entities: Entity[]): BackupEntity {
  return { entityType: CoreEntities.Backup, entities: entities };
}

/* Lookup function entity */

export type LookupOption = { reference: Entity; result: Entity };
export type LookupEntity = Entity & { options: LookupOption[]; selected: Entity };
export const LookupModel = {
  type: CoreEntities.Lookup,
  props: ['options', 'selected'],
};
export function makeLookup(options: LookupOption[], selected: Entity): LookupEntity {
  return { entityType: CoreEntities.Lookup, options: options, selected: selected };
}

/* Undefined entity */

export const UndefinedEntityModel: EntityModel = { type: CoreEntities.Undefined, props: [] };
export const Undefined = makeImmutable({ entityType: CoreEntities.Undefined } as Entity);
