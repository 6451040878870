import { FactoryProps } from '@sqior/react/factory';
import {
  Checkbox,
  HighlightButton,
  OnOffSwitch,
  SelectControl,
  SelectModel,
  useTextResources,
} from '@sqior/react/uibase';
import { SelectionAreaProps } from '@sqior/react/uiselection';
import {
  TransportOrderInput,
  TransportOrderParameters,
  TransportOrderParametersType,
} from '@sqior/viewmodels/patient';
import { useState } from 'react';
import styles from './transport-order-input-control.module.css';
import { TextField } from '@mui/material';

export type TransportOrderInputControlProps = FactoryProps<TransportOrderInput> &
  SelectionAreaProps;

export function TransportOrderInputControl(props: TransportOrderInputControlProps) {
  const textDict = useTextResources();
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [comment, setComment] = useState('');
  const fromSelect: SelectModel = {
    items: props.data.from.map((loc) => {
      return loc.label;
    }),
    selected: from,
  };
  const toSelect: SelectModel = {
    items: props.data.to.map((loc) => {
      return loc.label;
    }),
    selected: to,
  };
  const prios = [1, 2, 3, 4, 5];
  const [prio, setPrio] = useState(3);
  const [infectious, setInfectious] = useState(false);

  const onReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  return (
    <div className={styles['container']}>
      <div className={styles['header-container']}>
        <div className={styles['title']}>{textDict.get('pick_up_at')}</div>
        <SelectControl
          className={styles['location']}
          data={fromSelect}
          onSelect={(value) => {
            setFrom(value);
          }}
        />
      </div>
      <div className={styles['header-container']}>
        <div className={styles['title']}>{textDict.get('transport_to')}</div>
        <SelectControl
          className={styles['location']}
          data={toSelect}
          onSelect={(value) => {
            setTo(value);
          }}
        />
      </div>
      <div className={styles['priority-container']}>
        <div className={styles['title']}>{textDict.get('priority')}</div>
        <div className={styles['priorities']}>
          {prios.map((item) => (
            <Checkbox
              key={item.toString()}
              checked={item === prio}
              onChanged={() => {
                setPrio(item);
              }}
              label={item.toString()}
            />
          ))}
        </div>
      </div>
      <div className={styles['infectious-container']}>
        <div className={styles['title']}>{textDict.get('infectious')}</div>
        <OnOffSwitch
          active={infectious}
          onChanged={(active) => {
            setInfectious(active);
          }}
        />
      </div>
      <TextField
        variant="outlined"
        value={comment}
        onChange={onReasonChange}
        label="Kommentar [optional]"
        multiline
        maxRows={4}
      />

      <div className={styles['button-container']}>
        <HighlightButton
          onClick={() => {
            const params: TransportOrderParameters = {
              entityType: TransportOrderParametersType,
              from: props.data.from[from].select,
              to: props.data.to[to].select,
              priority: 5,
              infectious: infectious,
              comment,
            };
            if (props.onSelection) props.onSelection({ entity: params });
          }}
        >
          {textDict.get('order')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default TransportOrderInputControl;
