import styles from './dashboard-display-info.module.css';
import React, { FC, useEffect, useState } from 'react';
import { DailyStartHeaderItem, ProgressData } from '@sqior/viewmodels/orworkflow';
import { MENU_WIDTH } from '../orworkflow-overview-dashboard/constants';
import MarioIcon from './mario.svg';
import { SqiorProgressBar } from '@sqior/react/uibase';
import { ColorCodes } from '@sqior/viewmodels/visual';

export interface DashboardDisplayInfoProps {
  empty?: boolean;
  data?: DailyStartHeaderItem;
}

const HEIGHT = 97;
const BRAKE_POINT = 166;

const DETAIL_BRAKE_POINT = 165;

const automaticStyle = {
  backgroundImage: `url(${MarioIcon})`,
  backgroundSize: '50% auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'calc(100% + 20px) center',
};

export const DashboardDisplayInfo: FC<DashboardDisplayInfoProps> = ({ empty, data }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [groupText, setGroupText] = useState<string>(data?.title || '');
  const [detailText, setDetailText] = useState<string>(data?.detail || '');

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        if (width < BRAKE_POINT) {
          setGroupText(data?.shortTitle || data?.shortTitle || '');
        }

        if (width >= BRAKE_POINT) {
          setGroupText(data?.title || data?.title || '');
        }

        if (width < DETAIL_BRAKE_POINT) {
          setDetailText(data?.shortDetail || data?.shortDetail || '');
        }

        if (width >= DETAIL_BRAKE_POINT) {
          setDetailText(data?.detail || data?.detail || '');
        }
      }
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [data]);

  if (empty) return <Header />;
  if (!data) return <Empty />;

  const currentActiveLine = getCurrentActiveLine(data.progress);
  const lineColors = data.progress?.map((p) => p.color);

  const color = getCurrentLineColor(currentActiveLine, lineColors);

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      style={{
        height: HEIGHT,
        opacity: data?.fadeOut ? 0.5 : 1,
      }}
    >
      <div className={styles['info-card']} style={data.automatic ? automaticStyle : undefined}>
        <div className={styles['colored-title']} style={{ color }}>
          {data.header}
        </div>
        <div className={styles['progress-bar-container']}>
          {data.progress && (
            <SqiorProgressBar
              maxLines={data.progress.length}
              currentLine={currentActiveLine}
              lineColors={lineColors}
            />
          )}
        </div>

        <div className={styles['group-time-container']}>
          <div className={styles['group-text']}>{groupText}</div>
          <div className={styles['time-text']}>{data.timeRange}</div>
        </div>
        <div className={styles['description-container']}>
          <div id="dashboard-header-description" className={styles['description']}>
            {detailText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDisplayInfo;

export const Header: FC = () => {
  return (
    <div
      className={styles['empty-container']}
      style={{
        height: HEIGHT,
        width: MENU_WIDTH,
      }}
    ></div>
  );
};

export const Empty: FC = () => {
  return (
    <div
      className={styles['empty-container']}
      style={{
        height: HEIGHT,
      }}
    ></div>
  );
};

export const getCurrentActiveLine = (progress?: ProgressData[]): number => {
  if (!progress) return 0;
  // Find the last active line
  let activeLine = 0;
  for (let i = 0; i < progress.length; i++) {
    if (progress[i].active) {
      activeLine += 1;
    }
  }

  return activeLine;
};

export const getCurrentLineColor = (
  currentActiveLine: number,
  lineColors?: (ColorCodes | undefined)[]
): string | undefined => {
  const colorCode = lineColors ? lineColors[currentActiveLine - 1] : undefined;

  switch (colorCode) {
    case ColorCodes.Blue:
      return '#1cade4';
    default:
      return undefined;
  }
};
