import { Bytes, Key, Value, ValueObject, ValueOrNothing } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';

/* Definition of an object of entities */

export type EntityRecord = Record<string, Entity>;

/* Definition of the entity model meta data */

export type EntityModel = {
  type: string; // Type ID of the entity
  props: string[]; // Properties of the entity excluding properties of a base entity
  extends?: string; // Base entity type ID
  keys?: string[]; // Properties of the entity to represent in the key including key properties of the base entity
  keysCaseInsensitive?: boolean; // Flag specifying that the strings contained in the key properties shall be matched case-insensitively
  unclassified?: boolean; // Flag specifying that all non-entity parameters of the entity do not contain sensitive information
};

/* Creates a map of entity models */

export function makeEntityModelMap(...models: EntityModel[]) {
  const map = new Map<string, EntityModel>();
  for (const model of models) map.set(model.type, model);
  return map;
}

/* Narrows an entity to a base entity type */

export function narrowEntity(models: Map<string, EntityModel>, entity: Entity, type: string) {
  /* Make sure that the provided entity actually extends the desired target type */
  for (let source = entity.entityType; source !== type; ) {
    const model = models.get(source);
    if (!model)
      throw new Error(
        'Entity of type: ' +
          entity.entityType +
          ' cannot be narrowed to: ' +
          type +
          ' as the extension chain contains the unknown entity: ' +
          source
      );
    if (!model.extends)
      throw new Error(
        'Entity of type: ' +
          entity.entityType +
          ' cannot be narrowed as it does not extend entity: ' +
          source
      );
    source = model.extends;
  }
  /* Get the model of the desired target type */
  let model = models.get(type);
  if (!model)
    throw new Error(
      'Entity of type: ' +
        entity.entityType +
        ' cannot be narrowed as the target entity is unknown: ' +
        type
    );
  /* Create a new instance of the desired type and copy all registered properties */
  const res: Entity = { entityType: type };
  while (model) {
    for (const p of model.props) res[p] = entity[p];
    if (!model.extends) break;
    const base = model.extends;
    model = models.get(model.extends);
    if (!model)
      throw new Error(
        'Entity of type: ' +
          entity.entityType +
          ' cannot be narrowed as the extension chain of the target entity: ' +
          type +
          ' contains the unknown entity: ' +
          base
      );
  }
  return res;
}

/* Creates a key for an entity */

export function makeKey(models: Map<string, EntityModel>, entity: Entity): Key {
  const model = models.get(entity.entityType);
  if (!model)
    throw new Error(
      'Model of entity provided to makeKey() not found - provided type: ' + entity.entityType
    );
  /* Check if there is a definition of a sub-set of key properties */
  let res = entity.entityType;
  if (model.keys) {
    /* Create a sub-set of the object */
    for (const key of model.keys) {
      res += '|';
      if (entity[key])
        res += transformEntityKey(models, entity[key], model.keysCaseInsensitive || false);
    }
  } else res = JSON.stringify(entity); // Use complete object as key
  return res;
}

function transformEntityKey(
  models: Map<string, EntityModel>,
  value: Value,
  caseInsensitive: boolean
): string {
  /* If this is not an object, convert strings to lower case if case insensitive matching is requested */
  if (typeof value === 'string') return caseInsensitive ? value.toLowerCase() : value;
  else if (typeof value !== 'object' || value instanceof Bytes) return JSON.stringify(value);
  /* Check if this an array, if yes transform all values */
  if (value instanceof Array) {
    const arr: string[] = [];
    for (const a of value) arr.push(transformEntityKey(models, a, caseInsensitive));
    return JSON.stringify(arr);
  }
  /* Check if this is an entity */
  if (value['entityType']) return '{' + makeKey(models, value as Entity) + '}';
  /* This is a normal object, transform all of its values */
  const obj: ValueObject = {};
  for (const key in value) obj[key] = transformEntityKey(models, value[key], caseInsensitive);
  return JSON.stringify(obj);
}

/* Visits all entities inside the object */

export enum VisitEntitiesResult {
  Continue,
  Recurse,
  Exit,
}
export function visitEntities(
  obj: Value,
  callback: (entity: Entity) => VisitEntitiesResult,
  applyToRoot = true
) {
  /* Check if this is a native type */
  if (typeof obj !== 'object' || obj instanceof Bytes) return true;
  /* Check if this is an array */
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) if (!visitEntities(obj[i], callback)) return false;
  } else if (obj === null) {
    Logger.error('Detected null value in visitEntities()');
  } else {
    /* Check if this is an entity by itself */
    if (typeof obj['entityType'] === 'string' && applyToRoot) {
      const res = callback(obj as Entity);
      if (res !== VisitEntitiesResult.Recurse) return res === VisitEntitiesResult.Continue;
    }
    /* Loop all keys */
    for (const key in obj)
      if (obj[key] === null)
        Logger.error(
          ['Detected null value in visitEntities with key:', key],
          ['Detected null value in visitEntities in object:', obj]
        );
      else if (!visitEntities(obj[key], callback)) return false;
  }

  return true;
}

/* Transforms entities in an object by another entity or eliminates them */

export function transformEntitiesBase<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(obj: Type, transform: (entity: Entity) => MapType, applyToRoot = true): Type | MapType {
  /* Check if this is a native type */
  if (typeof obj !== 'object' || obj instanceof Bytes) return obj;
  /* Check if this is an array */
  if (obj instanceof Array) {
    let arr: Value[] | undefined;
    for (let i = 0; i < obj.length; i++) {
      const subst = transformEntitiesBase(obj[i], transform);
      if (arr !== undefined || subst !== obj[i]) {
        /* Initialize array with unchanged items */
        if (arr === undefined) arr = obj.slice(0, i);
        if (subst !== undefined) arr.push(subst);
      }
    }
    return arr !== undefined ? (arr as Type) : obj;
  } else if (obj === null) {
    Logger.error('Detected null value in visitEntities()');
    return obj;
  }

  /* Check if this is an entity */
  const type = obj['entityType'];
  if (type && typeof type === 'string' && applyToRoot) return transform(obj as Entity);

  /* Recurse */
  let res: Record<string, Value> | undefined;
  for (const key in obj) {
    const subst = transformEntitiesBase(obj[key] as unknown as Value, transform);
    if (res !== undefined || subst !== obj[key]) {
      /* Check if a result object needs to be created */
      if (res === undefined) {
        res = {};
        for (const prevKey in obj)
          if (key === prevKey) break;
          else res[prevKey] = obj[prevKey] as Value;
      }
      if (subst !== undefined) res[key] = subst;
    }
  }
  return res !== undefined ? (res as Type) : obj;
}

/** Transformation function with additional recursion option */

export function transformEntities<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(
  obj: Type,
  transform: (entity: Entity) => [MapType, boolean],
  applyToRoot = true
): Type | MapType {
  return transformEntitiesBase(
    obj,
    (ent) => {
      const res = transform(ent);
      return res[0] !== undefined && res[1] ? transformEntities(res[0], transform, false) : res[0];
    },
    applyToRoot
  );
}

export async function transformEntity<MapType extends ValueOrNothing = Entity | undefined>(
  obj: Entity,
  transform: (entity: Entity) => Promise<[MapType, boolean]>
): Promise<MapType> {
  const res = await transform(obj);
  /* Check if the internals shall also be transformed */
  return res[0] !== undefined && res[1]
    ? await transformEntitiesAsync(res[0], transform, false)
    : res[0];
}

export async function transformEntitiesAsync<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(
  obj: Type,
  transform: (entity: Entity) => Promise<[MapType, boolean]>,
  applyToRoot = true
): Promise<Type | MapType> {
  /* In order to void to inspect every value with an asynchronous function, this first gathers all entities to transform
     and then replaces them in a second round */
  const transformations = new Map<Entity, Promise<MapType>>();
  visitEntities(
    obj,
    (ent) => {
      if (!transformations.has(ent))
        transformations.set(ent, transformEntity<MapType>(ent, transform));
      return VisitEntitiesResult.Continue;
    },
    applyToRoot
  );
  /* Stop if nothing to be transformed */
  if (!transformations.size) return obj;
  /* Wait for all transformations */
  const transformed = new Map<Entity, MapType>();
  for (const transformation of transformations) {
    const res = await transformation[1];
    if (res === (transformation[0] as unknown as MapType)) continue;
    transformed.set(transformation[0], res);
  }
  /* Stop if nothing changed */
  if (!transformed.size) return obj;
  /* Replace */
  return transformEntitiesBase<Type, MapType>(
    obj,
    (ent) => {
      /* Check if this shall be transformed */
      return (transformed.has(ent) ? transformed.get(ent) : ent) as unknown as MapType;
    },
    applyToRoot
  );
}

/* Substitutes entities in an object by another entity or eliminates them */

export function substituteEntities<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(obj: Type, replacers: Record<string, (entity: Entity) => MapType>): Type | MapType {
  return transformEntities<Type, MapType>(obj, (entity): [MapType, boolean] => {
    /* Check if a replacing function is found */
    const replacer = replacers[entity.entityType];
    if (replacer) return [replacer(entity), false];
    else return [entity as unknown as MapType, true];
  });
}

/** Finds a entity with specified type in array of entities
 */
export function findEntity(entities: Entity[], entityType: string): Entity | undefined {
  return entities.find((entity) => {
    return entity.entityType === entityType;
  });
}
