import { NumberInput, SelectionEntities } from '@sqior/viewmodels/input';
import { FactoryProps } from '@sqior/react/factory';
import { HighlightButton } from '@sqior/react/uibase';
import { useEffect, useRef, useState } from 'react';
import { SelectionAreaProps } from '../selection-area';
import styles from './numerical-input-area.module.css';

type NumericalInputAreaProps = FactoryProps<NumberInput> & SelectionAreaProps;

export function NumericalInputArea(props: NumericalInputAreaProps) {
  /* State of input field */
  const [input, setInput] = useState(props.data.default?.toString() ?? '');
  const inputField = useRef<HTMLInputElement | null>(null);
  /* Validation of input field */
  const [valid, setValid] = useState(false);
  useEffect(() => {
    const value = parseInt(input);
    setValid(value >= props.data.min && value <= props.data.max);
  }, [props, input]);
  const confirm = () => {
    if (inputField.current)
      props.onSelection(
        props.data.numericalResult
          ? {
              entity: {
                entityType: SelectionEntities.NumericalResult,
                number: parseInt(inputField.current.value),
              },
            }
          : { text: inputField.current.value }
      );
  };
  return (
    <div className={styles['unit-input']}>
      <div className={styles['unit-default-title']}>{props.data.defaultTitle}</div>
      {props.data.default && (
        <div className={styles['unit-input-line']}>
          <span className={styles['unit-default-field']}>{props.data.default}</span>
          <span>{props.data.unit}</span>
        </div>
      )}
      <div className={styles['unit-input-line']}>
        <input
          ref={inputField}
          type="text"
          value={input}
          className={`${styles['unit-input-field']} ${!valid ? styles['unit-input-invalid'] : ''}`}
          autoFocus={true}
          inputMode="numeric"
          onFocus={(ev) => {
            setTimeout(() => {
              ev.target.select();
            });
          }}
          onChange={(ev) => {
            setInput(ev.target.value.replace(/\D/g, ''));
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' || ev.key === 'Tab') confirm();
          }}
        />
        <span>{props.data.unit}</span>
      </div>
      <HighlightButton
        onClick={() => {
          confirm();
        }}
        disabled={!valid}
      >
        Bestätigen
      </HighlightButton>
    </div>
  );
}

export default NumericalInputArea;
