import styles from './zoom-level-view.module.css';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

export interface ZoomLevelViewProps {
  isZoomVisible: boolean;
  zoomLevel: number;
  MIN_ZOOM_LEVEL: number;
  MAX_ZOOM_LEVEL: number;
}

export function ZoomLevelView({
  isZoomVisible,
  zoomLevel,
  MIN_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
}: ZoomLevelViewProps) {
  const controls = useAnimation();

  useEffect(() => {
    if (zoomLevel === MIN_ZOOM_LEVEL || zoomLevel === MAX_ZOOM_LEVEL) {
      controls.start({
        scale: [1, 1.1, 1, 1.1, 1],
        backgroundColor: ['rgba(255,255,255,0.3)', 'rgba(253,56,56,0.3)'],
        transition: { duration: 0.5 },
      });
    } else {
      controls.start({
        scale: 1,
        backgroundColor: 'rgba(255,255,255,0.3)',
        transition: { duration: 0.5 },
      });
    }
  }, [controls, zoomLevel, MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL]);

  return (
    <AnimatePresence>
      {isZoomVisible && (
        <motion.div
          className={styles['container']}
          animate={controls}
          transformTemplate={(_, generated) => `translateX(-50%) ${generated}`}
        >
          <p>Zoomstufe</p>
          <strong>{`${Number(100 / zoomLevel).toFixed(0)}%`}</strong>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ZoomLevelView;
