import { FactoryProps } from '@sqior/react/factory';
import styles from './text-input-area.module.css';
import { TextInput } from '@sqior/viewmodels/input';
import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { SelectionAreaProps } from '../selection-area';
import { useRef } from 'react';

export type TextInputAreaProps = FactoryProps<TextInput> & SelectionAreaProps;

export function TextInputArea(props: TextInputAreaProps) {
  const textDict = useTextResources();
  const ref = useRef<HTMLTextAreaElement>(null);
  return (
    <div className={styles['container']}>
      <div className={styles['border']}>
        <textarea
          autoFocus
          spellCheck
          rows={8}
          cols={32}
          className={styles['input']}
          ref={ref}
          defaultValue={props.data.text}
          onFocus={() => {
            setTimeout(() => {
              if (ref.current) ref.current.selectionStart = ref.current.value.length;
            }, 10);
          }}
        />
      </div>
      <div className={styles['button']}>
        <HighlightButton
          onClick={() => {
            if (ref.current) props.onSelection({ text: ref.current.value });
            else if (props.onClose) props.onClose(false);
          }}
        >
          {textDict.get('confirm')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default TextInputArea;
