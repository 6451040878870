import {
  CoreEntities,
  EntityModel,
  EntityRecord,
  Interface,
  TextEntity,
  TextTemplate,
} from '@sqior/js/meta';
import { LanguageEntities, LanguageInterfaces } from './language-definitions';
import { EntityHeader } from '@sqior/js/entity';

/* Entity to represent anonymized text */

export const AnonymizedTextModel: EntityModel = {
  type: LanguageEntities.AnonymizedText,
  props: ['text'],
};
export function makeAnonymizedText(text: string): TextEntity {
  return { entityType: LanguageEntities.AnonymizedText, text: text };
}

/* Interface to map to in order to get an anonimized text */

export const AnonymizedModel: Interface = {
  type: LanguageInterfaces.Anonymized,
  requires: LanguageEntities.AnonymizedText,
};

/** Creates an anonymous text template */
export function makeAnonymousTextTemplate(text: string, params: EntityRecord): TextTemplate {
  return {
    entityType: CoreEntities.TextTemplate,
    text: typeof text === 'string' ? makeAnonymizedText(text) : text,
    params: params,
  };
}

/** Anonymization level */

export enum AnonymizationLevel {
  Anonymous = 'anonymous',
  Normal = 'normal',
}
export type AnonymizationLevelEntity = EntityHeader & { level: string };
export const AnonymizationLevelModel: EntityModel = {
  type: LanguageEntities.AnonymizationLevel,
  props: ['level'],
  keys: ['level'],
};
export function makeAnonymizationLevel(level: AnonymizationLevel): AnonymizationLevelEntity {
  return { entityType: AnonymizationLevelModel.type, level };
}
