import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { DeviceEntities } from './device-definitions';
import { ArraySource, ensureArray } from '@sqior/js/data';

/** Definition of equipment as a list of devices or device functions */

export type EquipmentEntity = EntityHeader & { equipment: Entity[] };
export const EquipmentModel: EntityModel = { type: DeviceEntities.Equipment, props: ['equipment'] };
export function makeEquipment(equipment: ArraySource<Entity>): EquipmentEntity {
  return { entityType: EquipmentModel.type, equipment: ensureArray(equipment) };
}
