import { useEffect } from 'react';

/** Watches for ESC key pressed eventy and calls the specfied callback
 *
 * @param onCancel The callback to call
 */
export function useOnCancel(onCancel: () => void) {
  useEffect(() => {
    function closeOnEscape(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onCancel();
        e.preventDefault();
      }
    }
    document.addEventListener('keydown', closeOnEscape);

    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [onCancel]);
}
