import { FoldableText, SvgIconNext, useTextResources } from '@sqior/react/uibase';
import { WorkflowPath } from '@sqior/react/uiworkflow';
import { ReactComponent as ChecklistIcon } from './checklist.svg';
import { ReactComponent as DescriptionIcon } from './description.svg';
import { ReactComponent as AnesthesiaIcon } from './anesthesia.svg';
import { ReactComponent as LocationIcon } from './location.svg';
import styles from './orworkflow-area.module.css';
import { ORWorkflowOverview } from '@sqior/viewmodels/orworkflow';
import WorkflowBaseArea from '../workflow-base-area/workflow-base-area';
import SpecialtyView from '../specialty-view/specialty-view';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { classes } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface ORWorkflowAreaProps {
  data: ORWorkflowOverview;
}

export function ORWorkflowArea(props: ORWorkflowAreaProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  return (
    <WorkflowBaseArea
      relatedId={props.data.relatedId}
      team={props.data.team}
      headers={[
        (props.data.shortDescription || props.data.longDescription) && (
          <div key="description" className={styles['description-header']}>
            <DescriptionIcon className={styles['description-icon']} />
            <FoldableText
              shortClassName={styles['description']}
              longClassName={styles['long-description']}
              short={props.data.shortDescription}
              long={props.data.longDescription}
            />
          </div>
        ),
        props.data.anesthesiaMethod && (
          <div key="description" className={styles['description-header']}>
            <AnesthesiaIcon className={styles['description-icon']} />
            <p className={styles['location-text']}>{props.data.anesthesiaMethod}</p>
          </div>
        ),
        props.data.location && (
          <div key="location" className={styles['description-header']}>
            <LocationIcon className={styles['description-icon']} />
            <div className={styles['location']}>
              <p className={styles['location-text']}>{props.data.location}</p>
              <SpecialtyView
                specialty={props.data.specialty}
                style={{
                  lineHeight: '18px',
                  marginTop: 3,
                }}
              />
            </div>
          </div>
        ),
      ]}
      headersEnd={[
        props.data.checklist && (
          <div className={styles['description-header']}>
            <div className={styles['checklist-icon-container']}>
              <ChecklistIcon className={styles['checklist-icon']} />
              <div
                className={classes(
                  styles['checklist-badge'],
                  styles[props.data.checklistState?.toLowerCase() ?? 'initial']
                )}
              />
            </div>
            <div
              className={styles['checklist']}
              onClick={() => {
                if (props.data.checklist) dispatcher.start(SelectPatient(props.data.checklist));
              }}
            >
              <p className={styles['location-text']}>{textDict.get('or_checklist')}</p>
              <SvgIconNext className={styles['next-icon']} />
            </div>
          </div>
        ),
      ]}
      caveInfos={props.data.caveInfos}
    >
      {props.data.workflow && (
        <WorkflowPath path={props.data.workflow} className={styles['content-workflow']} />
      )}
    </WorkflowBaseArea>
  );
}

export default ORWorkflowArea;
