import {
  ChecklistItemBlocking,
  ChecklistItemViewModel,
  ChecklistOptionSentiment,
  ChecklistOptionViewModel,
  ChecklistSetData,
  SetChecklistPhotoType,
} from '@sqior/viewmodels/checklist';
import { useContext, useEffect, useRef, useState } from 'react';
import ChecklistOptionControl from '../checklist-option-control/checklist-option-control';
import styles from './checklist-item-control.module.css';
import {
  FileAttachment,
  FileAttachmentType,
  ImageMimeType,
  SqiorFileUpload,
  SqiorFileUploadGallery,
  SvgCheckBox,
  SvgComment,
  SvgTodo,
  useTextResources,
} from '@sqior/react/uibase';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { SelectionEntities, SelectionMenuType } from '@sqior/viewmodels/input';
import { ConfigContext } from '@sqior/react/utils';
import { isEqual } from '@sqior/js/data';
import { ReactComponent as ProblemIcon } from './problem.svg';

export interface ChecklistItemControlProps {
  data: ChecklistItemViewModel;
  readOnly: boolean;
  select: (data: ChecklistSetData) => void;
}

function isBlocking(item: ChecklistItemViewModel, selected?: ChecklistOptionViewModel) {
  return (
    selected &&
    selected.sentiment === ChecklistOptionSentiment.Negative &&
    (item.blocking === ChecklistItemBlocking.Always ||
      (item.blocking === ChecklistItemBlocking.Comment && !item.comment))
  );
}

export function ChecklistItemControl(props: ChecklistItemControlProps) {
  const textDict = useTextResources();
  const selContext = useContext(SelectionPageContext);
  const configContext = useContext(ConfigContext);
  const scrollElement = useRef<HTMLDivElement>(null);
  /* Check if one of the options is checked */
  const indexOptions: [ChecklistOptionViewModel, number][] = props.data.options.map(
    (option, index) => {
      return [option, index];
    }
  );
  const selected = indexOptions.find((option) => {
    return option[0].checked;
  });
  const [fold, setFold] = useState(true);
  const [createdAttachments, setCreatedAttachments] = useState<FileAttachment[]>([]);
  /* Calculate the overall attachments */
  const [attachments, setAttachments] = useState<FileAttachment[]>([]);
  const uploadedImages = props.data.attachments;
  const uploadedAttachments: FileAttachment[] = uploadedImages.map((am) => {
    return {
      id: am.ref.id,
      type: FileAttachmentType.image,
      mimeType: ImageMimeType.jpeg,
      imageURL: configContext.getEndpoint(am.download.url).toString(),
      blob: null,
    };
  });
  useEffect(() => {
    const ams = createdAttachments.concat(uploadedAttachments);
    if (
      !isEqual(
        attachments.map((am) => {
          return am.id;
        }),
        ams.map((am) => {
          return am.id;
        })
      )
    )
      setAttachments(ams);
  }, [createdAttachments, uploadedAttachments, attachments]);
  /* Performs the upload if attachments get created */
  const selectFunc = props.select;
  useEffect(() => {
    /* Set the attachment information */
    const photos: SetChecklistPhotoType[] = [];
    const uploadingAttachments: FileAttachment[] = [];
    for (const ca of createdAttachments)
      if (ca.finalId) photos.push(ca.finalId);
      else uploadingAttachments.push(ca);
    if (photos.length) {
      setCreatedAttachments(uploadingAttachments);
      selectFunc({
        photos: uploadedImages
          .map((im) => {
            return im.ref.id;
          })
          .concat(photos),
      });
    }
  }, [createdAttachments, uploadedImages, selectFunc]);
  return (
    <div className={styles['outer-container']}>
      <div className={styles['inner-container']}>
        {selected && !isBlocking(props.data, selected[0]) && (
          <SvgCheckBox
            className={
              styles[
                selected[0].sentiment === ChecklistOptionSentiment.Negative
                  ? 'checkbox-problem-icon'
                  : 'checkbox-icon'
              ]
            }
          />
        )}
        {selected && isBlocking(props.data, selected[0]) && (
          <ProblemIcon className={styles['problem-icon']} />
        )}
        {!selected && <SvgTodo className={styles['todo-icon']} />}
        <div className={styles['container']}>
          <div className={styles['text-more-container']}>
            <div
              className={styles['text-container']}
              onClick={() => {
                if (!props.readOnly) setFold(!fold);
              }}
            >
              <span
                className={
                  styles[
                    selected && selected[0].sentiment === ChecklistOptionSentiment.Neutral
                      ? 'shallow-description'
                      : isBlocking(props.data, selected?.[0])
                      ? 'problem-description'
                      : 'description'
                  ]
                }
              >
                {props.data.text + ':'}
              </span>
              {selected && (
                <span className={styles[selected[0].sentiment]}>
                  {selected[0].feedbackText ?? selected[0].text}
                </span>
              )}
            </div>
          </div>
          <div className={styles['options-container']}>
            <SqiorFileUploadGallery
              attachments={attachments}
              removeAttachment={(id) => {
                /* Check if this was an already uploaded attachment */
                const remainingImages = uploadedImages.filter((im) => {
                  return im.ref.id !== id;
                });
                if (remainingImages.length !== uploadedImages.length)
                  selectFunc({
                    photos: remainingImages.map((im) => {
                      return im.ref.id;
                    }),
                  });
                /* Remove from created attachments */
                setCreatedAttachments(
                  createdAttachments.filter((ca) => {
                    return ca.id !== id;
                  })
                );
              }}
              containerClassName={styles['image-container']}
              itemWrapperClassName={styles['image']}
              itemClassName={styles['image']}
            />
            {(props.data.comment ||
              (!fold && !attachments.length) ||
              (selected &&
                selected[0].sentiment === ChecklistOptionSentiment.Negative &&
                props.data.blocking !== ChecklistItemBlocking.Never)) && (
              <div
                className={styles['comment-text']}
                onClick={() => {
                  if (!props.readOnly) setFold(!fold);
                }}
              >
                {props.data.comment
                  ? '"' + props.data.comment + '"'
                  : textDict.get(
                      selected &&
                        selected[0].sentiment === ChecklistOptionSentiment.Negative &&
                        props.data.blocking === ChecklistItemBlocking.Comment
                        ? 'comment_required_for_release'
                        : selected &&
                          selected[0].sentiment === ChecklistOptionSentiment.Negative &&
                          props.data.blocking === ChecklistItemBlocking.Always
                        ? 'release_not_possible'
                        : 'add_comment_or_photo'
                    )}
              </div>
            )}
            {!fold && (
              <button
                className={styles['comment-button']}
                onClick={() => {
                  const selPage = {
                    entityType: SelectionMenuType,
                    title: textDict.get('enter_comment'),
                    selection: {
                      entityType: SelectionEntities.Text,
                      text: props.data.comment ?? '',
                    },
                  };
                  selContext(selPage, (ok, data) => {
                    if (ok && data) {
                      const text = data['text'] as string;
                      props.select({ comment: text });
                    }
                  });
                }}
              >
                <SvgComment className={styles['comment-icon']} />
              </button>
            )}
            {!fold && (
              <SqiorFileUpload
                setAttachments={setCreatedAttachments}
                iconClassName={
                  styles[attachments.length >= 2 ? 'disabled-camera-icon' : 'camera-icon']
                }
              />
            )}
          </div>
          {(!selected || !fold) && (
            <div className={styles['button-container']}>
              {indexOptions.map((option) => (
                <ChecklistOptionControl
                  data={option[0]}
                  key={option[1]}
                  disabled={props.readOnly}
                  select={(check, data) => {
                    props.select({ option: option[1], check, value: data });
                    if (!fold) setFold(true);
                    if (check)
                      scrollElement.current?.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                      });
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div ref={scrollElement} className={styles['scroll-element']}>
        &nbsp;
      </div>
    </div>
  );
}

export default ChecklistItemControl;
