import { FactoryProps } from '@sqior/react/factory';
import styles from './ward-overview-dashboard.module.css';
import {
  BedAllocationWardListItemEntity,
  WardOverviewDashboardVM,
  WardSPIAndSpecialtiesInfoEntity,
  WardSPIAndSpecialtiesInfoType,
} from '@sqior/viewmodels/occupancy';
import BedView from './components/bed-view/bed-view';
import { FC } from 'react';
import { useUIGlobalState } from '@sqior/react/state';
import { AnimatePresence, motion } from 'framer-motion';
import PatientsInFlightView from '../patients-in-flight-view/patients-in-flight-view';
import { makeId } from '@sqior/js/meta';
import CombinedSPIAndSpecialties from '../combined-spiand-specialties/combined-spiand-specialties';

export type WardOverviewDashboardProps = FactoryProps<WardOverviewDashboardVM>;

export function WardOverviewDashboard(props: WardOverviewDashboardProps) {
  const { UIGlobalState } = useUIGlobalState();

  const data: WardSPIAndSpecialtiesInfoEntity = {
    id: makeId('WardSPIAndSpecialtiesInfoEntity'),
    entityType: WardSPIAndSpecialtiesInfoType,
    specialties: props.data.statistics,
    spiStats: props.data.spiStats,
  };

  return (
    <div className={styles['container']}>
      <div className={styles['rooms-wrapper-container']}>
        <div
          className={styles['rooms-container']}
          style={{
            gridTemplateColumns: `repeat(auto-fill, minmax(350px, 1fr)`,
            width: '100%',
          }}
        >
          <RoomView data={props.data.rooms} />
        </div>
      </div>
      <AnimatePresence>
        {UIGlobalState.bmRightDrawerOpen && (
          <motion.div className={styles['infos']}>
            <CombinedSPIAndSpecialties data={data} />
            <PatientsInFlightView data={props.data.patientsInFlight} withSections />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default WardOverviewDashboard;

interface RoomViewProps {
  data: BedAllocationWardListItemEntity[];
}

const RoomView: FC<RoomViewProps> = ({ data }) => {
  return (
    <>
      {data.map((room) => (
        <motion.div layout="position" key={room.id.id} className={styles['room-wrapper']}>
          <div className={styles['room-label']}>{room.label}</div>
          <div className={styles['bed-container']}>
            {room.beds.map((bed, index) => (
              <BedView key={bed.id} bed={bed} />
            ))}
          </div>
        </motion.div>
      ))}
    </>
  );
};
