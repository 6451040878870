import { LogRetainer, LogTextData, TextLogBackend } from '@sqior/js/log';
import { State } from '@sqior/js/state';

/** Log implementation adding the log entry to a state */
export const LogPath = 'log';
export class LogState extends TextLogBackend {
  constructor(length = 100) {
    super();
    this.retainer = new LogRetainer(length);
  }
  logText(data: LogTextData) {
    /* Add to retainer */
    this.retainer.logText(data);
    /* Update state */
    this.state.set(this.retainer.logs);
  }
  private retainer: LogRetainer;
  readonly state = new State();
}
