import { SelectControl, SelectModel, useTextResources } from '@sqior/react/uibase';
import { ListViewInfoVector } from '@sqior/viewmodels/visual';
import DashboardList from '../dashboard-list/dashboard-list';
import styles from './dashboard-switch.module.css';
import { SwipeDirection, useSwipe } from '@sqior/react/hooks';
import { useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

export interface DashboardSwitchProps {
  data: ListViewInfoVector;
  stage: string;
  setStage: (stage: string) => void;
}

const ANIMATION_DURATION = 0.1;

export function DashboardSwitch(props: DashboardSwitchProps) {
  const ref = useRef<HTMLDivElement>(null);
  const overlayOpenRef = useRef<boolean>(false);

  const controls = useAnimation();
  const textResources = useTextResources();

  const selectModel = getSelectModel(props.data, props.stage);

  useSwipe({
    ref,
    onMove,
    onReset,
    onSwipe,
    options: { clampDistance: 100 },
  });

  function onMove(x: number) {
    if (overlayOpenRef.current) return;
    controls.set({ x });
  }

  function onReset() {
    controls.start({ x: 0 });
  }

  function onSwipe(direction: SwipeDirection) {
    if (overlayOpenRef.current) return;
    switch (direction) {
      case SwipeDirection.LEFT:
        controls.start({ x: '-110%' }, { duration: ANIMATION_DURATION }).then(() => {
          controls.set({ x: '110%' });
          changeStage(getWrappedIndex(direction, selectModel));
        });
        break;
      case SwipeDirection.RIGHT:
        controls.start({ x: '110%' }, { duration: ANIMATION_DURATION }).then(() => {
          controls.set({ x: '-110%' });
          changeStage(getWrappedIndex(direction, selectModel));
        });
        break;
    }
  }

  const changeStage = (index: number) => {
    props.setStage(props.data[index].title);
    controls.start({ x: 0 }, { duration: ANIMATION_DURATION });
  };

  const onOverlayOpen = () => {
    overlayOpenRef.current = true;
  };

  const onOverlayClose = () => {
    overlayOpenRef.current = false;
  };

  return (
    <motion.div animate={controls} ref={ref} className={styles['container']}>
      {props.data.length > 0 && (
        <SelectControl
          data={selectModel}
          onSelect={(index) => {
            props.setStage(props.data[index].title);
          }}
          onOpen={onOverlayOpen}
          onClose={onOverlayClose}
        />
      )}

      {selectModel.items.length > 0 && props.data[selectModel.selected].entries > 0 && (
        <DashboardList
          key={props.data[selectModel.selected].path}
          className={styles['list']}
          path={props.data[selectModel.selected].path}
        />
      )}
      {(selectModel.items.length === 0 || props.data[selectModel.selected].entries === 0) && (
        <div className={styles['empty']}>{textResources.get('no_entries')}</div>
      )}
    </motion.div>
  );
}

export default DashboardSwitch;

const getSelectModel = (data: ListViewInfoVector, stage: string): SelectModel => {
  const selectModel: SelectModel = { items: [], selected: 0 };
  for (let i = 0; i < data.length; i++) {
    selectModel.items.push(
      data[i].showCount ? `${data[i].title} (${data[i].entries})` : data[i].title
    );
    if (data[i].title === stage) selectModel.selected = i;
  }
  return selectModel;
};

const getWrappedIndex = (direction: SwipeDirection, selectModel: SelectModel) => {
  const newIndex =
    direction === SwipeDirection.LEFT ? selectModel.selected + 1 : selectModel.selected - 1;
  return newIndex < 0 ? selectModel.items.length - 1 : newIndex % selectModel.items.length;
};
