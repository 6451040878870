import { classes } from '@sqior/react/utils';
import { ItemState } from '@sqior/viewmodels/visual';
import styles from './pie-circle.module.css';

export interface PieCircleProps {
  className: string;
  percentage: number;
  state?: ItemState;
  textLarge?: string;
  textSmall?: string;
}

const getTimerColorClassName = (state: ItemState | undefined): string => {
  switch (state) {
    case ItemState.Hot:
      return styles['front-circle-hot'];
    case ItemState.Emergency:
      return styles['front-circle-emergency'];
    case ItemState.Highlighted:
      return styles['front-circle'];
    case ItemState.Normal:
      return styles['front-circle'];
    case ItemState.Greyed:
      return styles['front-circle'];
    case ItemState.Challenged:
      return styles['front-circle'];
    default:
      return styles['front-circle'];
  }
};

const getAnimationClassName = (percentage: number): string => {
  if (percentage === 0) return '';
  return styles['shiny'];
};

/** Renders a PieCircle
 */
export function PieCircle(props: PieCircleProps) {
  const radius = 45;
  const strokeWidth = 5;

  const percentage = Math.min(Math.max(0, Math.round(props.percentage * 10) / 10), 100); // clip percentage, round in order to reduce re-renderings
  const canvasSize = 2 * radius + 2 * strokeWidth;
  const middlePoint = canvasSize / 2;
  const arcLength = 2 * radius * Math.PI;
  const arcOffset = (arcLength * (100 - percentage)) / 100;
  const drawHighlightArc = 0 < percentage;

  const fontSizeTextLarge = 0.3 * radius;
  const fontSizeTextSmall = 0.2 * radius;

  return (
    <div className={classes(styles['pie-circle'], props.className)}>
      <div className={getAnimationClassName(percentage)} style={{ width: radius, height: radius }}>
        <svg viewBox={`${0} ${0} ${canvasSize} ${canvasSize}`}>
          <circle
            className={styles['back-circle']}
            cx={middlePoint}
            cy={middlePoint}
            r={radius}
            strokeWidth={strokeWidth * 2}
          />
          {drawHighlightArc && (
            <circle
              className={getTimerColorClassName(props.state)}
              strokeWidth={strokeWidth * 2}
              strokeDasharray={arcLength}
              strokeDashoffset={arcOffset}
              cx={middlePoint}
              cy={middlePoint}
              r={radius}
            />
          )}
        </svg>
      </div>
      <svg viewBox={`${0} ${0} ${canvasSize} ${canvasSize}`}>
        <circle
          className={styles['back-circle']}
          strokeWidth={strokeWidth * 2}
          strokeDasharray={arcLength}
          strokeDashoffset={arcLength + arcOffset}
          cx={middlePoint}
          cy={middlePoint}
          r={radius}
        />
      </svg>

      <div className={styles['text-container']}>
        <p
          className={styles['text-large']}
          style={{ fontSize: fontSizeTextLarge, lineHeight: `${fontSizeTextLarge}px` }}
        >
          {props.textLarge}
        </p>
        <p
          className={styles['text-small']}
          style={{ fontSize: fontSizeTextSmall, lineHeight: `${fontSizeTextSmall}px` }}
        >
          {props.textSmall}
        </p>
      </div>
    </div>
  );
}

export default PieCircle;
