import { OccupancyAnalyticsDashboardVM } from '@sqior/viewmodels/analytics-dashboard';
import styles from './react-ui-analytics.module.css';
import { FactoryProps } from '@sqior/react/factory';
import AnalyticHeader from './analytic-header/analytic-header';
import MetricCard, { MetricCardProps } from './metric-card/metric-card';
import PieChartCard from './pie-chart-card/pie-chart-card';
import TimeSeriesLineChart, {
  BenchmarkData,
} from './time-series-line-chart/time-series-line-chart';
import { extractFakeData, FAKE_DATA, PieChartData } from './demo-analytics-data';
import PerformanceIndicators, {
  PerformanceIndicator,
} from './performance-indicators/performance-indicators';
import StatisticsComparisonPanel from './statistics-comparison-panel/statistics-comparison-panel';
import { useTextResources } from '@sqior/react/uibase';
import { WithSelection } from './utils';
import { useState } from 'react';
import { TimeRangeSelect } from './range-picker/range-picker';
import { StatisticsComparisonPanelItemData } from './statistics-comparison-panel-item/statistics-comparison-panel-item';

export type ReactUiAnalyticsProps = FactoryProps<OccupancyAnalyticsDashboardVM>;

export type AnalyticsData = {
  tabs: WithSelection<string[]>;
  stations: WithSelection<string[]>;
  timeRanges: WithSelection<TimeRangeSelect[]>;
  metrics: MetricCardProps[];
  patients: PieChartData[];
  benchmark: BenchmarkData;
  staticComparison: StatisticsComparisonPanelItemData[];
  performanceIndicators: PerformanceIndicator[];
};

export function ReactUiAnalytics(props: ReactUiAnalyticsProps) {
  const textDict = useTextResources();

  const [data, setData] = useState<AnalyticsData>(FAKE_DATA);

  const onTabChange = (index: number) => {
    // change the selected tab
    // setData((prev) => ({ ...prev, tabs: { ...prev.tabs, selected: index } }));
  };

  const onStationChange = (index: number) => {
    setData((prev) => ({ ...prev, stations: { ...prev.stations, selected: index } }));

    updateData(
      data.tabs.value[data.tabs.selected],
      data.stations.value[index],
      data.timeRanges.value[data.timeRanges.selected]
    );
  };

  const onTimeRangeChange = (index: number) => {
    setData((prev) => ({ ...prev, timeRanges: { ...prev.timeRanges, selected: index } }));

    updateData(
      data.tabs.value[data.tabs.selected],
      data.stations.value[data.stations.selected],
      data.timeRanges.value[index]
    );
  };

  const updateData = (tab: string, station: string, timeRange: TimeRangeSelect) => {
    const newData = extractFakeData(tab, station, timeRange);
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{textDict.get('occupancy_management')}</div>
      <AnalyticHeader
        tabs={data.tabs}
        stations={data.stations}
        timeRanges={data.timeRanges}
        onTabChange={onTabChange}
        onStationChange={onStationChange}
        onTimeRangeChange={onTimeRangeChange}
      />
      <div className={styles['body']}>
        <div className={styles['metrics']}>
          {data.metrics.map((metric) => (
            <MetricCard key={metric.title} {...metric} />
          ))}
          <PieChartCard title={textDict.get('patients')} data={data.patients} />
          <div className={styles['grid-span-4']}>
            <TimeSeriesLineChart data={data.benchmark} />
          </div>
          <div className={styles['grid-title']}>{textDict.get('compliance')}</div>
          <div className={styles['grid-span-3']}>
            <StatisticsComparisonPanel data={data.staticComparison} />
          </div>
          <PerformanceIndicators data={data.performanceIndicators} />
        </div>
      </div>
    </div>
  );
}

export default ReactUiAnalytics;
