import { Emitter } from '@sqior/js/event';
import { Temporary } from './temporary';

export class TemporaryValue {
  constructor(value: string) {
    this.current = value;
    this.change = new Emitter<[string?]>();
    this.failed = false;
    this.temp = Temporary.get(value);
    if (this.temp)
      this.temp.final.on((value) => {
        if (value) this.current = value;
        else this.failed = true;
        this.temp = undefined;
        this.change.emit(value);
      });
  }

  get final() {
    return this.temp === undefined;
  }

  current: string;
  private temp?: Temporary;
  readonly change: Emitter<[string?]>;
  failed: boolean;
}
