export enum ConnectionState {
  NOT_CONNECTED,
  CONNECTED_HANDSHAKE,
  CONNECTED,
  ERROR,
  ERROR_AUTH_FAILED, // Authentication failed - the login process should be repeated
  ERROR_AUTH_EXPIRED, // Authentication token expired - the token should be generated anew
  ERROR_AUTH_RESET, // Authentication failed - the user should be logged out and the login process should be repeated
  ERROR_INCOMPATIBLE, // Versions of client and server are not compatible
}
