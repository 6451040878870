import { LogicalTimestamp, PatchValue, Value } from '@sqior/js/data';
import { Message } from '@sqior/js/message';

export type StateReplaceData = {
  path: string;
  value?: Value;
  timestamp: LogicalTimestamp;
  onDemand?: boolean;
};
export interface StateReplaceMessage extends Message {
  states: StateReplaceData[];
}
export interface StateUpdateMessage extends Message {
  path: string;
  patch?: PatchValue;
  timestamp: LogicalTimestamp;
  onDemand?: boolean;
}
export interface StateConfirmMessage extends Message {
  path: string;
}
export interface StateUseMessage extends Message {
  path: string;
  used: boolean;
}

export enum StateMessageType {
  ReplaceState = 'state.replace',
  UpdateState = 'state.update',
  ConfirmState = 'state.confirm',
  UpdateUse = 'state.use',
}
