import { Entity } from '@sqior/js/entity';
import {
  CoreEntities,
  EntityModel,
  EntityRecord,
  makeTextTemplate,
  TextTemplate,
} from '@sqior/js/meta';
import { LanguageEntities } from './language-definitions';

/* Text resource with ID referencing a true resource */
export type TextResource = Entity & { id: string };
export const TextResourceModel: EntityModel = {
  type: LanguageEntities.TextResource,
  props: ['id'],
  keys: ['id'],
  extends: CoreEntities.Key,
  unclassified: true,
};
export function textResource(id: string): TextResource {
  return { entityType: LanguageEntities.TextResource, id: id };
}
export function resourceTextTemplate(id: string, params: EntityRecord): TextTemplate {
  return makeTextTemplate(textResource(id), params);
}
