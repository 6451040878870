import { UserInfo } from '@sqior/js/authbase';
import { DatabaseInterface } from '@sqior/js/db';
import { Entity } from '@sqior/js/entity';
import { Emitter } from '@sqior/js/event';
import { Dispatcher } from '@sqior/js/operation';
import { SessionObjectStore, SessionObjectStoreInterface, UserSession } from '@sqior/js/session';
import { State } from '@sqior/js/state';
import { StateHandlerFactory } from '@sqior/js/state-operation';
import { UID } from '@sqior/js/uid';
import { DeviceType } from '@sqior/plugins/device';
import { Interface } from '@sqior/js/meta';
import { UserInterfaces } from './user-definitions';
import { TimeEntities } from '@sqior/plugins/time';
import { SessionMapping } from '@sqior/plugins/visual';

/* Typical context of a user session */

export interface UserSessionContext extends SessionObjectStoreInterface, UserSession {
  readonly user: Entity;
  readonly userInfo: UserInfo;
  readonly db: DatabaseInterface;
  readonly state: State;
  readonly dispatcher: Dispatcher;
  readonly stateHandlerFactory: StateHandlerFactory;
  readonly sessionMapper: SessionMapping;
}

/* Context for a user connection */

export interface UserConnectionContext extends SessionObjectStoreInterface {
  readonly connectionId: UID;
  readonly session: UserSessionContext;
  readonly user: Entity;
  readonly db: DatabaseInterface;
  readonly state: State;
  readonly dispatcher: Dispatcher;
  readonly deviceDefined: Emitter<[Entity, DeviceType]>;
  readonly onClose: Emitter<[UID, boolean]>;
}

/** Standard implementation of a UserSessionContext */

export class BasicUserSessionContext extends SessionObjectStore implements UserSessionContext {
  constructor(
    user: Entity,
    userInfo: UserInfo,
    db: DatabaseInterface,
    sessionMapper: SessionMapping,
    state = new State(),
    dispatcher = new Dispatcher()
  ) {
    super();
    this.user = user;
    this.userInfo = userInfo;
    this.db = db;
    this.state = state;
    this.dispatcher = dispatcher;
    this.sessionMapper = sessionMapper;
    this.add(sessionMapper); // Register for closing
  }

  readonly user: Entity;
  userInfo: UserInfo;
  readonly db: DatabaseInterface;
  readonly state: State;
  readonly dispatcher: Dispatcher;
  readonly stateHandlerFactory = new StateHandlerFactory();
  readonly sessionMapper: SessionMapping;
}

/** Interface to deduce the maximum user session duration */

export const MaxUserSessionDurationModel: Interface = {
  type: UserInterfaces.MaxSessionDuration,
  requires: TimeEntities.Duration,
};
