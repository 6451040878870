import styles from './preliminary-procedures-view.module.css';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { ORWorkflowOverviewDashboardProcedureData } from '@sqior/viewmodels/orworkflow';
import { AnimationDefinition, motion, useAnimation } from 'framer-motion';
import ProcedureView from '../procedure-view/procedure-view';
import { useTextResources } from '@sqior/react/uibase';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useDynamicStateRaw, useSyncAnimation } from '@sqior/react/state';
import { ViewportSize } from '@sqior/web/utils';
import { useMouseScroll } from '@sqior/react/hooks';

export interface PreliminaryProceduresViewProps {
  data: ORWorkflowOverviewDashboardProcedureData[];
  autoScroll?: boolean;
}

const CLOSED_HEIGHT = 30;
const MIN_WIDTH = 250;
const MAX_WIDTH = 250;

export const PreliminaryProceduresView: FC<PreliminaryProceduresViewProps> = ({
  data,
  autoScroll,
}) => {
  const { addStartTime } = useSyncAnimation();
  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const textDict = useTextResources();

  useMouseScroll(contentRef);

  const [open, setOpen] = useAutomaticOpen(data);
  const [openHeight, setOpenHeight] = useState<number>(0);

  const controls = useAnimation();

  const toggleOpen = () => setOpen((prev) => !prev);

  const getTitle = () => {
    const length = getLength(data);
    if (length === 0) return textDict.get('no_late_registrations').toUpperCase();
    if (length === 1) return textDict.get('one_late_registration').toUpperCase();
    return `${length} ${textDict.get('late_registrations').toUpperCase()}`;
  };

  useEffect(() => {
    const getHighestChild = (content: HTMLDivElement) => {
      let highestChild = 0;
      const children = content.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const height = child.getBoundingClientRect().height;
        if (height > highestChild) {
          highestChild = height;
        }
      }
      return highestChild;
    };
    const makeAnimation = (openHeight: number) => {
      if (autoScroll === false) return;

      const createAnimationConfig = (
        scrollDistance: number,
        pages: number
      ): AnimationDefinition => {
        const moveDuration = 1; // Duration to move from one page to another in seconds
        const pauseDuration = 2; // Duration to pause at each page in seconds

        // Create keyframes for Y values (forward and reverse)
        const yValues = [];
        for (let i = 0; i < pages; i++) {
          yValues.push(-scrollDistance * i); // Position
          yValues.push(-scrollDistance * i); // Pausing at the same position
        }
        // Add reverse phase
        for (let i = pages - 1; i >= 0; i--) {
          yValues.push(-scrollDistance * i); // Position
          yValues.push(-scrollDistance * i); // Pausing at the same position
        }

        // Create the transition configuration
        const totalDuration = (moveDuration + pauseDuration) * pages * 2;
        const movePhaseDuration = moveDuration / totalDuration;
        const pausePhaseDuration = pauseDuration / totalDuration;
        const transitionTimes: number[] = [];

        for (let i = 0; i < yValues.length; i++) {
          const phaseDuration = i % 2 === 0 ? movePhaseDuration : pausePhaseDuration;
          transitionTimes.push((i === 0 ? 0 : transitionTimes[i - 1]) + phaseDuration);
        }

        return {
          y: yValues,
          transition: {
            duration: totalDuration,
            times: transitionTimes,
            ease: 'linear',
            loop: Infinity,
            repeat: Infinity,
          },
        };
      };

      if (!contentRef.current || !data?.length || data?.length === 0) return;
      const content = contentRef.current;

      const { width } = content.getBoundingClientRect();

      const maxFitInRow = Math.floor(width / MIN_WIDTH);

      if (data.length > maxFitInRow) {
        const pages = Math.ceil(data.length / maxFitInRow);
        const animationConfig = createAnimationConfig(openHeight, pages);
        addStartTime();
        controls.start(animationConfig);
      } else {
        controls.stop();
      }
    };

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === contentRef.current) {
          makeAnimation(openHeight);
        }
      }
    });

    if (!contentRef.current) return;

    const height = getHighestChild(contentRef.current);
    if (height !== 0) contentRef.current.style.gridAutoRows = `${height}px`;
    else contentRef.current.style.gridAutoRows = 'auto';
    setOpenHeight(height);

    observer.observe(contentRef.current);

    return () => {
      if (!contentRef.current) return;
      observer.unobserve(contentRef.current);
    };
  }, [autoScroll, openHeight, viewport]);

  const getWrapperHeight = () => {
    if (autoScroll === false && open) return 'auto';
    if (autoScroll === false && !open) return CLOSED_HEIGHT;

    if (open) return openHeight + CLOSED_HEIGHT;
    return CLOSED_HEIGHT;
  };

  return (
    <motion.div
      initial={{ height: getWrapperHeight() }}
      animate={{
        height: getWrapperHeight(),
      }}
      style={{
        zIndex: 30,
        boxShadow: '0px -5px 32px 0px #000000',
      }}
    >
      <div ref={containerRef} className={styles['container']}>
        <div
          className={styles['title']}
          onClick={toggleOpen}
          style={{
            height: CLOSED_HEIGHT,
          }}
        >
          {getTitle()}
          {open ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
        </div>
        <motion.div
          ref={contentRef}
          animate={controls}
          className={autoScroll === false ? styles['content-scroll'] : styles['content-animate']}
        >
          {data?.map((item, index) => (
            <ProcedureView
              key={item.id}
              noBottomSpace={true}
              data={item}
              minWidth={MIN_WIDTH}
              maxWidth={autoScroll === false ? MAX_WIDTH : undefined}
              borderRight={hasRightBorder(data, index)}
            />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default memo(PreliminaryProceduresView);

const hasRightBorder = (data: ORWorkflowOverviewDashboardProcedureData[], index: number) => {
  if (index === 0 && data.length === 1) return false;
  return index !== data.length - 1;
};

const getLength = (data?: ORWorkflowOverviewDashboardProcedureData[]) => {
  return data?.length || 0;
};

const useAutomaticOpen = (data?: ORWorkflowOverviewDashboardProcedureData[]) => {
  const [open, setOpen] = useState<boolean>(getLength(data) > 0);

  useEffect(() => {
    if (getLength(data) > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data]);

  return [open, setOpen] as const;
};
