import { TimePositionCalculator } from './TimePositionCalculator';
import { ClockTimestamp } from '@sqior/js/data';
import { DateData } from '@sqior/viewmodels/time';
import { isWithinInterval } from 'date-fns';
import { ALLOWED_INTERVAL, BaseIntervalAllowed } from './constants';

interface ICalculatePositionAndTimeParams {
  currentTime: ClockTimestamp;
  selectedDate: DateData;
  core: { startHour: number; endHour: number };
  scrollMin: number;
  zoomLevel: number;
  target: { hours: number; minutes: number } | undefined;
  interval: BaseIntervalAllowed;
  dragging: boolean;
}
export const calculatePositionAndTime = ({
  currentTime,
  selectedDate,
  core,
  scrollMin,
  zoomLevel,
  target,
  interval,
  dragging,
}: ICalculatePositionAndTimeParams) => {
  /* Define the reference time as the current time or as the start of the core hours on the other dates */
  let referenceDate = new Date(currentTime);
  if (
    selectedDate &&
    (referenceDate.getDate() !== selectedDate.day ||
      referenceDate.getMonth() + 1 !== selectedDate.month ||
      referenceDate.getFullYear() !== selectedDate.year)
  )
    referenceDate = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day,
      core.startHour
    );
  const referenceTime = referenceDate.getTime();

  /* Decide for time range, show the main day programm in the core hours */
  const timeCalc = TimePositionCalculator.fromCoreHours({
    core,
    currentTime: referenceTime,
    scrollMin,
    zoomLevel,
    selectedDateDate: new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day),
    interval,
    dragging,
  });
  const currentPos = timeCalc.convertTimestamp(currentTime);
  /* Set the target line */
  const targetDate = new Date(referenceTime);
  if (target) {
    targetDate.setHours(target.hours);
    targetDate.setMinutes(target.minutes);
    targetDate.setSeconds(0);
    targetDate.setMilliseconds(0);
  }
  return { currentPos, timeCalc, targetDate };
};

export const havePropsChanged = (prevProps: any | undefined, nextProps: any | undefined) =>
  JSON.stringify(prevProps) !== JSON.stringify(nextProps);

export interface ISTodayParams {
  day: number;
  month: number;
  year: number;
  currentTime: ClockTimestamp;
  interval: BaseIntervalAllowed;
}
export const isToday = ({ day, month, year, currentTime, interval }: ISTodayParams): boolean => {
  if (!currentTime || !day || !month || !year) return false;
  const selectedDate = new Date(year, month - 1, day);
  return isWithinInterval(
    new Date(currentTime),
    ALLOWED_INTERVAL({ selectedDate: selectedDate, interval })
  );
};

export const formatTime = (hours: number, minutes: number) =>
  hours + ':' + minutes.toString().padStart(2, '0');
