import { ConfigContext, classes } from '@sqior/react/utils';
import styles from './profile-picture.module.css';
import { useContext } from 'react';

export interface ProfilePictureProps {
  url: string;
  className?: string;
}

export function ProfilePicture(props: ProfilePictureProps) {
  const configContext = useContext(ConfigContext);
  return (
    <img
      className={classes(styles['image'], props.className)}
      src={configContext.getEndpoint(props.url).toString()}
      alt=""
    />
  );
}

export default ProfilePicture;
