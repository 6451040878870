import styles from './metric-card.module.css';
import { classes } from '@sqior/react/utils';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import MetricCardHeader from '../metric-card-header/metric-card-header';
import CardLayout from '../card-layout/card-layout';
import AnimatedNumbers from 'react-animated-numbers';

type ChartType = {
  data: number[];
  categories: string[];
};

export interface MetricCardProps {
  title: string;
  value: number;
  startValueUnit?: string;
  endValueUnit?: string;
  unit?: string;
  trend: {
    percentage: number;
    hasIncreased: boolean;
    text: string;
  };
  chart?: ChartType;
}

export function MetricCard({
  trend: { hasIncreased, percentage, text },
  title,
  value,
  startValueUnit,
  endValueUnit,
  unit,
  chart,
}: MetricCardProps) {
  const percentageClassname = hasIncreased
    ? classes(styles['percentage'], styles['active'])
    : classes(styles['percentage'], styles['inactive']);

  const chartData = chart ? generateAreaChart(title, chart) : null;
  return (
    <CardLayout>
      <MetricCardHeader title={title} />
      <div className={styles['info-container']}>
        <div className={styles['value-container']}>
          <div className={styles['value']}>
            {startValueUnit && `${startValueUnit} `}
            <AnimatedNumbers
              includeComma
              transitions={(index) => ({
                type: 'spring',
                duration: 0.5,
              })}
              animateToNumber={value}
            />{' '}
            {endValueUnit}
          </div>
          <div className={styles['unit']}>{unit}</div>
        </div>
        <div className={styles['trend-container']}>
          <div className={percentageClassname}>{percentage}%</div>
          <div className={styles['percentage-description']}>{text}</div>
        </div>
      </div>
      <div className={styles['chart-position']}>
        <div className={styles['chart-wrapper']}>
          {chartData && (
            <Chart
              options={chartData}
              series={chartData.series}
              type="area"
              width="100%"
              height="100%"
            />
          )}
        </div>
      </div>
    </CardLayout>
  );
}

export default MetricCard;

const generateAreaChart = (title: string, chart: ChartType): ApexOptions => {
  return {
    series: [
      {
        name: title,
        // data: [30, 40, 35, 40, 56],
        data: chart.data,
      },
    ],
    chart: {
      id: 'basic-bar',
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },

    theme: {
      mode: 'dark',
    },

    colors: ['#2E93fA', '#FFFFFF00'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7, // Starting opacity
        opacityTo: 0.0, // Ending opacity (transparent)
        stops: [0, 100], // Defines the range of the gradient
      },
    },
    xaxis: {
      categories: chart.categories,
      labels: {
        show: false, // Hides the X-axis labels
      },
      axisBorder: {
        show: false, // Hides the X-axis border
      },
      axisTicks: {
        show: false, // Hides the X-axis ticks
      },
    },
    yaxis: {
      labels: {
        show: false, // Hides the Y-axis labels
      },
      axisBorder: {
        show: false, // Hides the Y-axis border
      },
      axisTicks: {
        show: false, // Hides the Y-axis ticks
      },
    },
    grid: {
      show: false, // Hides the grid lines
    },
  };
};
