import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel } from '@sqior/js/meta';
import { LocationEntities } from './location-definitions';

export type RoomNumber = Entity & { number: string };
export const RoomNumberModel: EntityModel = {
  type: LocationEntities.RoomNumber,
  props: ['number'],
  keys: ['number'],
  extends: CoreEntities.Key,
};
export function makeRoomNumber(number: string): RoomNumber {
  return { entityType: LocationEntities.RoomNumber, number: number };
}
