/** Enum for units of performance measures */
export enum PerformanceUnit {
  Memory = 'memory', // Memory (RAM) consumption measured in bytes
  Bytes = 'bytes', // Bytes of unknown ancestory
  Count = 'count', // Count of unknown items
}

/** Type for performance metrics */
export type PerformanceMetric = { id: string; value: number; unit: PerformanceUnit };

/** Function providing performance metrics */
export type PerformanceMetricProvider = () => PerformanceMetric[];

/** Interface for performance monitors,  */
export interface PerformanceMonitorInterface {
  /** Allows different domains to register providers for performance metrics */
  register(name: string, metricProvider: PerformanceMetricProvider): void;
}
