import { ReactComponent as InsuranceIcon } from './insurance.svg';
import styles from './insurance-info.module.css';

export interface InsuranceInfoProps {
  text: string;
}

export function InsuranceInfo(props: InsuranceInfoProps) {
  return (
    <div className={styles['container']}>
      <InsuranceIcon className={styles['icon']} />
      <div className={styles['description']}>{props.text}</div>
    </div>
  );
}

export default InsuranceInfo;
