import { OperationContext } from '@sqior/react/operation';
import { BedAllocationInFlightPatientInfoEntity } from '@sqior/viewmodels/occupancy';
import { SelectPatient } from '@sqior/viewmodels/patient';
import {
  InterweaveExt,
  SqiorBedLineView,
  SqiorChip,
  SqiorClockProgress,
} from '@sqior/react/uibase';
import { useContext } from 'react';
import styles from './bed-allocation-in-flight-patient-info.module.css';
import { PersonSex } from '@sqior/viewmodels/person';
import { SpecialtyView } from '@sqior/react/uiorworkflow';

const getBackgroundColor = (sex: PersonSex) => {
  switch (sex) {
    case PersonSex.Female:
      return '#ED6F8D';
    case PersonSex.Male:
      return '#1CADE4';
    default:
      return '#AEB0BD';
  }
};

export type BedAllocationInFlightPatientInfoProps = {
  data: BedAllocationInFlightPatientInfoEntity;
  style?: React.CSSProperties;
};

export function BedAllocationInFlightPatientInfo({
  data: {
    name,
    birthday,
    requestSource,
    stepInfo,
    patient,
    sex,
    stepInfoSeverity,
    id,
    entityType,
    select,
    challenged,
    priority,
    specialty,
    nursingComplexity,
    monitoring,
    isolation,
  },
  style,
}: BedAllocationInFlightPatientInfoProps) {
  const dispatcher = useContext(OperationContext);

  return (
    <div
      className={styles['container']}
      onClick={() => {
        dispatcher.start(SelectPatient(select));
      }}
      style={style}
    >
      <SqiorBedLineView backgroundColor={getBackgroundColor(sex as PersonSex)} />
      <div className={styles['info-container']}>
        <div className={styles['name-birthday']}>
          <InterweaveExt content={name} className={styles['name']} />
          <div className={styles['birthday']}>{birthday}</div>
        </div>
        <div className={styles['chips-container']}>
          <SpecialtyView
            uppercase
            specialty={specialty}
            style={{ borderRadius: 2, padding: '2px 4px', fontSize: 9 }}
          />
          {isolation && (
            <SqiorChip
              label="Iso"
              icon={<div style={{ color: 'rgba(255, 192, 0, 1)', fontSize: 9 }}>!</div>}
            />
          )}
          {monitoring && (
            <SqiorChip
              label="Mon"
              icon={<div style={{ color: 'rgba(255, 192, 0, 1)', fontSize: 9 }}>!</div>}
            />
          )}
          {priority && (
            <SqiorChip
              label="Prio"
              icon={<div style={{ color: 'rgba(245, 93, 93, 1)', fontSize: 9 }}>!</div>}
            />
          )}
          <SqiorClockProgress value={nursingComplexity} crossColor="#121830" fontSize={8} />
        </div>
        <div className={styles['step-info']}>
          <div>{stepInfo}</div>
        </div>
      </div>
    </div>
  );
}

export default BedAllocationInFlightPatientInfo;
