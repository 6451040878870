import { ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader, IdEntity } from '@sqior/js/entity';
import { ItemState, ListViewItem } from '@sqior/viewmodels/visual';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinUrlPath } from '@sqior/js/url';

/** Describing the content of a patient list item for treatments paths */

export const TreatmentPathPatientListItemType = 'TreatmentPathListItem';

export type TreatmentPathPatientListItemEntity<IdType = IdEntity> = ListViewItem<IdType> & {
  patient: string; // HTML description of the patient
  diagnosis?: string; // Text description of the primary diagnosis
  pathText: string; // Treatment path state description
  pathState: ItemState; // Treatment path item state
  select: Entity; // Patient entity to provide for selection
};

/** Treatment path overview */

export const TreatmentPathOverviewType = 'TreatmentPathOverview';

export type TreatmentPathItemViewModel = {
  title: string;
  comment?: string;
  change?: string;
  adapt?: Entity;
};

export type TreatmentPathOverviewEntity = EntityHeader & {
  patient: Entity; // Patient to show the treatment path for
  diagnosis?: string; // Text description of the primary diagnosis
  stay?: string; // Text describing the expected stay
  path: TreatmentPathItemViewModel[]; // Treatment path items
  changed: boolean; // Flag if the treatment path was modified
};

/* Treatment path item adapt */

export const TreatmentPathItemAdaptType = 'TreatmentPathAdapt';

export type TreatmentPathItemField = {
  id: string;
  name: string;
  current: string;
  selection?: Entity;
};

export type TreatmentPathItemAdaptEntity = EntityHeader & {
  patient: Entity;
  id: string;
  title: string;
  titleComment?: string;
  fields: TreatmentPathItemField[];
};

export const TreatmentPathOperationPath = 'treatment-path';

export type TreatmentPathItemAdaptChangeData = { prop: string; change: ValueObject };
export type TreatmentPathItemAdaptData = {
  patient: Entity;
  item: string;
  modifications: TreatmentPathItemAdaptChangeData[];
};
export const TreatmentPathAdaptSuffix = 'adapt';
export const TreatmentPathAdaptPath = joinUrlPath(
  TreatmentPathOperationPath,
  TreatmentPathAdaptSuffix
);

export function AdaptTreatmentPathItem(
  patient: Entity,
  item: string,
  modifications: TreatmentPathItemAdaptChangeData[]
): OperationSpec<TreatmentPathItemAdaptData> {
  return {
    type: OperationType.Add,
    path: TreatmentPathAdaptPath,
    data: { patient: patient, item: item, modifications: modifications },
  };
}

export type TreatmentPathItemAdaptChange = { patient: Entity; item: string; change: ValueObject };
export type TreatmentPathItemChangeResult = TreatmentPathItemField & { change: ValueObject };

/* Release treatment path */

export const TreatmentPathReleaseSuffix = 'release';
export const TreatmentPathReleasePath = joinUrlPath(
  TreatmentPathOperationPath,
  TreatmentPathReleaseSuffix
);

export function ReleaseTreatmentPath(patient: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: TreatmentPathReleasePath, data: patient };
}
