import styles from './demo-phone.module.css';

/* eslint-disable-next-line */
export interface DemoPhoneProps {
  user: string;
}

export function DemoPhone(props: DemoPhoneProps) {
  return (
    <div className={styles['container']}>
      <img className={styles['phone']} alt="" src={'./demo_phone.jpg'} />
      <iframe
        className={styles['app']}
        title={props.user}
        src={window.location.origin + window.location.pathname + '?testUser=' + props.user}
      />
    </div>
  );
}

export default DemoPhone;
