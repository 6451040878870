import styles from './react-uiselection.module.css';

/* eslint-disable-next-line */
export interface ReactUiselectionProps {}

export function ReactUiselection(props: ReactUiselectionProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUiselection!</h1>
    </div>
  );
}

export default ReactUiselection;
