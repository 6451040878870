/** Custom websocket closing codes */

export enum WebsocketCloseCode {
  LogOut = 3000,
}

/** Interface to wrap the different websocket implementation (module ws, dom)
 *  The interface only supports the needed functionality used by sqior ws* modules
 */
export interface WebSocketIF {
  close(code?: number, data?: string): void;

  send(data: unknown): void;

  onopen?: () => void;
  onerror?: () => void;
  onclose?: () => void;
  onmessage?: (event: MessageEvent) => void;
}

/** MessageEvent independent from any websocket implementation
 */
export interface MessageEvent {
  data: unknown;
}
