import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { LocationEntities } from './location-definitions';

/** Status of a transport (e.g. patient, material etc.) */

export enum TransportStatus {
  Created = 'created',
  Confirmed = 'confirmed',
  PickedUp = 'picked_up',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

/** Entity representing the status of a transport (e.g. patient, material etc.) */

export type TransportStatusEntity = EntityHeader & { status: string };
export const TransportStatusModel: EntityModel = {
  type: LocationEntities.TransportStatus,
  props: ['status'],
  unclassified: true,
};
export function makeTransportStatus(status: TransportStatus): TransportStatusEntity {
  return { entityType: TransportStatusModel.type, status: status };
}
