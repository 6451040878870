import { WardSpecialtiesStatsInfoItem } from '@sqior/viewmodels/occupancy';
import { FC } from 'react';
import styles from './bed-speciality-view.module.css';
import { SqiorChartBlock } from '@sqior/react/uibase';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  data: WardSpecialtiesStatsInfoItem;
}

enum BedState {
  Free = 'free',
  Blocked = 'blocked',
  Occupied = 'occupied',
  Overflow = 'overflow',
}

interface SpecialityBedState {
  state: BedState;
}

const BedSpecialityView: FC<Props> = ({ data }) => {
  const freeBeds = [...Array(Math.max(data.freeBedsLater, 0))].map(
    (_): SpecialityBedState => ({
      state: BedState.Free,
    })
  );

  const blockedBeds = [...Array(Math.max(0, data.quota - data.availableBedsLater))].map(
    (_): SpecialityBedState => ({
      state: BedState.Blocked,
    })
  );

  const occupiedBeds = [
    ...Array(Math.max(0, Math.max(0, Math.min(data.patientsLater, data.availableBedsLater)))),
  ].map(
    (_): SpecialityBedState => ({
      state: BedState.Occupied,
    })
  );

  const overflow = [
    ...Array(Math.max(0, data.patientsLater - Math.max(data.availableBedsLater, 0))),
  ].map(
    (_): SpecialityBedState => ({
      state: BedState.Overflow,
    })
  );
  const all = [...blockedBeds, ...occupiedBeds, ...freeBeds, ...overflow];
  const drawWithinQuota = all.slice(0, data.quota);
  const drawOverflow = all.slice(data.quota);

  const renderItem = (item: SpecialityBedState) => {
    switch (item.state) {
      case BedState.Free:
        return <FreeBed />;
      case BedState.Blocked:
        return <BlockedBed size={18} />;
      case BedState.Occupied:
        return <OccupiedBed />;
      case BedState.Overflow:
        return <OverflowBed />;
      default:
        return null;
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>{data.specialty}</div>
        <div className={styles['result']}>
          <span className={data.patientsLaterWarning ? styles['label-warning'] : undefined}>
            {data.patientsLater}
          </span>
          <span>{`/${data.quota}`}</span>
        </div>
      </div>
      <SqiorChartBlock
        data={drawWithinQuota}
        overflow={drawOverflow}
        renderItem={renderItem}
        height={24}
        width={24}
      />
    </div>
  );
};

export default BedSpecialityView;

interface BlockedBedProps {
  size?: number;
}

export const BlockedBed: FC<BlockedBedProps> = ({ size }) => {
  return (
    <div className={styles['blocked-bed']}>
      <CloseRoundedIcon style={{ color: 'rgba(255,255,255,0.5)', fontSize: size }} />
    </div>
  );
};

export const OccupiedBed = () => {
  return <div className={styles['occupied-bed']} />;
};

export const FreeBed = () => {
  return <div className={styles['free-bed']} />;
};

export const OverflowBed = () => {
  return <div className={styles['overflow-bed']} />;
};
