import { clone } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import {
  ClosablePage,
  ContainerWithShadow,
  HighlightButton,
  SelectControl,
  SelectModel,
  useTextResources,
} from '@sqior/react/uibase';
import { DiagnosisInfo, PatientHeader } from '@sqior/react/uipatient';
import { BedAllocationTargetWardInput } from '@sqior/viewmodels/occupancy';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext, useState } from 'react';
import BedAllocationParameterInfo from '../bed-allocation-parameter-info/bed-allocation-parameter-info';
import styles from './bed-allocation-target-ward-input-area.module.css';

export type BedAllocationTargetWardInputAreaProps = FactoryProps<BedAllocationTargetWardInput>;

export function BedAllocationTargetWardInputArea(props: BedAllocationTargetWardInputAreaProps) {
  const [ward, setWard] = useState(props.data.defaultWard);
  const textDict = useTextResources();
  const selectModel: SelectModel = {
    items: props.data.wards.map((item) => {
      return item.name;
    }),
    selected: Math.max(
      0,
      props.data.wards.findIndex((w) => {
        return w.name === ward;
      })
    ),
  };
  /* Operation emitted */
  const dispatcher = useContext(OperationContext);
  const confirm = (entity: Entity) => {
    const op = clone(props.data.operation);
    op.data = { ...op.data, entity: entity };
    dispatcher.start(op);
    if (props.onClose) props.onClose(true);
  };
  return (
    <ClosablePage
      onClose={() => {
        if (props.onClose) props.onClose(false);
      }}
    >
      <PatientHeader data={props.data.info} />
      <ContainerWithShadow className={styles['shadow-container']}>
        <div className={styles['scroll-container']}>
          <div className={styles['container']}>
            {props.data.diagnosis && <DiagnosisInfo diagnosis={props.data.diagnosis} />}
            <BedAllocationParameterInfo data={props.data.requirements} />
            <SelectControl
              data={selectModel}
              onSelect={(index) => {
                setWard(props.data.wards[index].name);
              }}
            />
            <InterweaveExt
              className={styles['text']}
              content={props.data.wards[selectModel.selected].text}
            />
            <HighlightButton
              onClick={() => {
                confirm(props.data.wards[selectModel.selected].id);
              }}
            >
              {textDict.get('request_ward')}
            </HighlightButton>
          </div>
        </div>
      </ContainerWithShadow>
    </ClosablePage>
  );
}

export default BedAllocationTargetWardInputArea;
