import {
  WorkflowPathModel,
  WorkflowPathStepLabelType,
  WorkflowPathStepState,
} from '@sqior/viewmodels/workflow';
import WorkflowPathStep from '../workflow-path-step/workflow-path-step';
import styles from './workflow-path.module.css';

/* eslint-disable-next-line */
export interface WorkflowPathProps {
  path: WorkflowPathModel;
  className?: string;
}

function checkTopLine(path: WorkflowPathModel, index: number) {
  /* Check if there are only buttons on top */
  let allButtons = true;
  for (let i = index - 1; i >= 0; i--)
    if (
      path.steps[i].label.type === WorkflowPathStepLabelType.Text ||
      path.steps[i].state === WorkflowPathStepState.Todo
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  /* Check if there are only buttons on bottom */
  allButtons = true;
  for (let i = index; i < path.steps.length; i++)
    if (
      path.steps[i].state === WorkflowPathStepState.Todo ||
      (path.steps[i].state !== WorkflowPathStepState.Comment &&
        path.steps[i].label.type === WorkflowPathStepLabelType.Text)
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  /* Check if there is a problem directly before or only separated by comments */
  for (let i = index - 1; i >= 0; i--)
    if (path.steps[i].state === WorkflowPathStepState.Problem) return false;
    else if (path.steps[i].state !== WorkflowPathStepState.Comment) break;
  return index + 1 < path.steps.length || path.steps[index].state !== WorkflowPathStepState.Comment;
}
function checkActiveTop(path: WorkflowPathModel, index: number) {
  for (let i = index - 1; i >= 0; i--)
    if (path.steps[i].state === WorkflowPathStepState.Todo) return false;
    else if (path.steps[i].state === WorkflowPathStepState.Done) return true;
  return false;
}
function checkBottomLine(path: WorkflowPathModel, index: number) {
  /* Check if there are only buttons on bottom */
  let allButtons = true;
  for (let i = index + 1; i < path.steps.length; i++)
    if (
      path.steps[i].state === WorkflowPathStepState.Todo ||
      (path.steps[i].state !== WorkflowPathStepState.Comment &&
        path.steps[i].label.type === WorkflowPathStepLabelType.Text)
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  return (
    index + 1 < path.steps.length &&
    path.steps[index].state !== WorkflowPathStepState.Problem &&
    (index + 2 < path.steps.length || path.steps[index + 1].state !== WorkflowPathStepState.Comment)
  );
}

export function WorkflowPath(props: WorkflowPathProps) {
  return (
    <div className={`${styles['container']} ${props.className}`}>
      {props.path.steps.map((item, index) => (
        <WorkflowPathStep
          key={item.id}
          model={item}
          topLine={checkTopLine(props.path, index)}
          activeTop={checkActiveTop(props.path, index)}
          bottomLine={checkBottomLine(props.path, index)}
          timestamp={props.path.timestamp}
          additionalClassName={
            styles[
              index + 1 < props.path.steps.length &&
              props.path.steps[index + 1].label.type === WorkflowPathStepLabelType.Button &&
              props.path.steps[index + 1].group !== item.group &&
              item.label.type !== WorkflowPathStepLabelType.Button
                ? 'button-gap'
                : ''
            ]
          }
        />
      ))}
    </div>
  );
}

export default WorkflowPath;
