import { Closable } from '@sqior/js/async';
import { CacheAccess, CacheCleanup } from '@sqior/js/cache';
import { addHours, ValueObject } from '@sqior/js/data';
import { DatabaseInterface } from './database-interface';
import { DocumentLoader } from './document-loader';

export class PrivateCollectionCache<Type extends ValueObject = ValueObject> implements Closable {
  constructor(db: DatabaseInterface, path: string, keyProp: string) {
    this.loader = new DocumentLoader<Type>(db, path, keyProp);
  }

  async close() {
    /* Make sure no more documents are loaded */
    await this.loader.close();
    /* Stop all cache resets */
    this.clear();
    /* Close cache cleanup */
    await this.cleanUp.close();
  }

  private insert(key: string, value: Type | undefined) {
    this.docs.set(key, [
      value,
      new CacheAccess(this.cleanUp, () => {
        this.docs.delete(key);
      }),
    ]);
  }

  async get(key: string) {
    /* Check if the document is in the cache */
    const cacheEntry = this.docs.get(key);
    if (cacheEntry) {
      /* Register access to cache */
      cacheEntry[1].notify();
      return cacheEntry[0];
    }
    /* Otherwise fetch from disk */
    const doc = await this.loader.get(key);
    /* Insert into cache */
    const value = doc ? doc.obj : undefined;
    this.insert(key, value);
    return value;
  }

  set(key: string, value: Type | undefined) {
    /* Check if there is an existing cache entry, if yes - update it */
    const cacheEntry = this.docs.get(key);
    if (cacheEntry) {
      cacheEntry[0] = value;
      cacheEntry[1].notify();
      return;
    }
    /* Otherwise insert */
    this.insert(key, value);
  }

  delete(key: string) {
    this.set(key, undefined);
  }

  clear() {
    /* Stop all cache resets */
    for (const entries of this.docs.values()) entries[1].reset();
    this.docs.clear();
  }

  get size() {
    return this.docs.size;
  }
  get memory() {
    let bytes = 0;
    for (const doco of this.docs.values()) if (doco[0]) bytes += JSON.stringify(doco[0]).length;
    return bytes;
  }

  private loader: DocumentLoader<Type>;
  private cleanUp = new CacheCleanup(addHours(1));
  private docs = new Map<string, [Type | undefined, CacheAccess]>();
}
