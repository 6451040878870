import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { DisplayTimePath, SetDisplayTime } from '@sqior/viewmodels/time';
import { OperationContext } from '@sqior/react/operation';
import { ClockTimestamp, now, StdTimer, TestTimer, TimerInterface } from '@sqior/js/data';
import { DemoModePath } from '@sqior/viewmodels/app';
import useDynamicState from '../state-user/state-user';

interface TimerContextProps {
  timer: TimerInterface;
  setTimerValue: (timestamp: ClockTimestamp) => void;
  dragging: boolean;
  setDragging: (dragging: boolean) => void;
}

const TimerContext = createContext<TimerContextProps | undefined>(undefined);

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export const useCustomTimer = (milliseconds = 100) => {
  const timer = useTimer().timer;
  const [customTimer, setCustomTimer] = useState(timer.now);

  /* Sample the timer periodically */
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCustomTimer(timer.now);
    }, milliseconds);
    return () => clearInterval(intervalId);
  }, [timer, setCustomTimer, milliseconds]);

  /* Also listen to jumps in timer */
  useEffect(() => {
    if (!(timer instanceof TestTimer)) return;
    return timer.modified.on(() => {
      setCustomTimer(timer.now);
    });
  }, [timer, setCustomTimer]);

  return customTimer;
};

interface TimerProviderProps {
  children: ReactNode;
}

export const TimerProvider: FC<TimerProviderProps> = ({ children }) => {
  const dispatcher = useContext(OperationContext);
  const demoMode = useDynamicState<boolean>(DemoModePath, false);
  const serverTime = useDynamicState<ClockTimestamp>(DisplayTimePath, now());
  const [timer, setTimer] = useState<TimerInterface>(
    demoMode ? new TestTimer(serverTime, 1) : new StdTimer()
  );
  const [dragging, setDragging] = useState<boolean>(false);

  /* Ensures the correct timer type */
  useEffect(() => {
    if (demoMode && (!(timer instanceof TestTimer) || timer.closed))
      setTimer(new TestTimer(now(), 1));
    else if (!demoMode && timer instanceof TestTimer) {
      timer.close();
      setTimer(new StdTimer());
    }
    return () => {
      if (timer instanceof TestTimer) timer.close();
    };
  }, [demoMode, timer, setTimer]);

  /* Reacts to updates of the server time */
  useEffect(() => {
    /* Do not update as long as the drag movement is done */
    if (dragging || !serverTime || !(timer instanceof TestTimer)) return;
    timer.set(serverTime);
  }, [dragging, serverTime, timer]);

  const setTimerValue = (now: ClockTimestamp) => {
    if (!(timer instanceof TestTimer)) return;
    /* Set server time */
    dispatcher.start(SetDisplayTime(now));
    /* Set local time while dragging */
    if (dragging) timer.set(now);
  };

  return (
    <TimerContext.Provider
      value={{
        timer,
        setTimerValue,
        dragging,
        setDragging,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
