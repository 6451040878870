import { EntityHeader } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel } from './entity';

/* Definition of the basic command entity */

export type CommandEntity = EntityHeader;
export const CommandEntityModel: EntityModel = { type: CoreEntities.Command, props: [] };
export function createCommandEntity(type: string): CommandEntity {
  return { entityType: type };
}
