import styles from './warn-spi-chart.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { WardSPIStatsInfoListViewEntity } from '@sqior/viewmodels/occupancy';
import SPIChart from '../ward-overview-dashboard/components/SPIChart/spi-chart';

export type WarnSpiChartProps = FactoryProps<WardSPIStatsInfoListViewEntity>;

export function WarnSpiChart(props: WarnSpiChartProps) {
  return (
    <div className={styles['container']}>
      <SPIChart {...props.data} />
    </div>
  );
}

export default WarnSpiChart;
