import { useContext, useState } from 'react';
import {
  InformProjectionVM,
  mainInfoContains,
  ReadConfirmationTypes,
} from '@sqior/viewmodels/communication';
import { useTabTitleCounter } from '@sqior/react/notifications';
import { OperationContext } from '@sqior/react/operation';
import { useDynamicState, useDynamicStateIfAvailable } from '@sqior/react/state';
import { TopBar } from '@sqior/react/uiadvanced';
import { ContainerWithShadow } from '@sqior/react/uibase';
import { MainInfo, UndoInfoToast, useAutoMarkRead } from '@sqior/react/uiconversation';
import {
  AllListViewsIndexStatePath,
  DashboardStatePath,
  ListViewInfos,
} from '@sqior/viewmodels/visual';
import {
  ClosePage,
  MessengerPages,
  MessengerTab,
  MessengerTabPageSpec,
  MessengerTabStack,
  OpenPage,
  SelectMessengerTab,
} from '@sqior/viewmodels/app';
import { DashboardSwitch } from '@sqior/react/uivisual';
import { ComponentFactory } from '@sqior/react/factory';
import { ChatPage } from '@sqior/react/uichat';

import { MessengerPage } from '../messenger/messenger';
import {
  firstPage,
  isMessengerMenuOpen,
  isMessengerTabPageSpec,
  lastPage,
} from '../messenger-tab-stack';
import styles from './messenger-main-page.module.css';
import { classes, ZIndex } from '@sqior/react/utils';
import { PagePatientOverviewType } from '@sqior/viewmodels/patient';
import BottomNavigation from '../bottom-navigation/bottom-navigation';
import SpeedDialFloatingButton from '../speed-dial-floating-button/speed-dial-floating-button';

/* eslint-disable-next-line */
export interface MessengerMainPageProps {
  fullDashboard: boolean;
  tabStack: MessengerTabStack;
  page: MessengerPage;
}

export function MessengerMainPage(props: MessengerMainPageProps) {
  const FactoryComponent = useContext(ComponentFactory);
  const dispatcher = useContext(OperationContext);

  const activeMessengerTab = firstPage(props.tabStack);
  const popupPage = isMessengerTabPageSpec(lastPage(props.tabStack));
  const menuOpen = isMessengerMenuOpen(props.tabStack);

  function setActiveMessengerTab(tab: MessengerTab) {
    dispatcher.start(SelectMessengerTab(tab));
  }
  function openSimplePage(entityTpe: string) {
    dispatcher.start(OpenPage({ entityType: entityTpe, data: {} }));
  }
  function closePopupPage(page: MessengerTabPageSpec) {
    dispatcher.start(ClosePage(page));
  }

  const mainInfo = useDynamicState<InformProjectionVM[]>('main-info/items', []);
  useAutoMarkRead(mainInfo, ReadConfirmationTypes.Implicit);
  useTabTitleCounter(mainInfo.filter((item) => mainInfoContains(item)).length);

  /* Reduce dashboard if applicable */
  let lists = useDynamicState<ListViewInfos>(AllListViewsIndexStatePath, {
    entityType: '',
    lists: [],
  }).lists;
  const dashboardLists = useDynamicStateIfAvailable<ListViewInfos>(DashboardStatePath, {
    entityType: '',
    lists: [],
  }).lists;
  if (!props.fullDashboard && dashboardLists)
    lists = lists.filter((list) => {
      return (
        dashboardLists.findIndex((dList) => {
          return dList.path === list.path;
        }) < 0
      );
    });
  const [stage, setStage] = useState('');

  const tasksCount = mainInfo.filter((item) => mainInfoContains(item)).length;

  return (
    <div className={styles['container']}>
      <div className={styles['topbar']}>
        <TopBar
          onAbout={() => {
            dispatcher.start(OpenPage(MessengerTab.Menu));
          }}
          onUser={() => {
            openSimplePage(MessengerPages.QRScan);
          }}
          onLocation={() => {
            openSimplePage(MessengerPages.ChoseRoom);
          }}
        />
      </div>
      <div className={styles['content']}>
        {activeMessengerTab === MessengerTab.Todo && (
          <ContainerWithShadow>
            <MainInfo items={mainInfo} />
          </ContainerWithShadow>
        )}
        {activeMessengerTab === MessengerTab.List && (
          <DashboardSwitch data={lists} stage={stage} setStage={setStage} />
        )}
        {activeMessengerTab === MessengerTab.Conversation && <ChatPage />}
        {activeMessengerTab !== MessengerTab.List && <SpeedDialFloatingButton />}
      </div>

      <div style={{ zIndex: ZIndex.BottomTabs }} className={styles['content-tabs']}>
        <BottomNavigation
          tasksCount={tasksCount}
          activeMessengerTab={activeMessengerTab}
          setActiveMessengerTab={setActiveMessengerTab}
        />
      </div>

      {isMessengerTabPageSpec(popupPage)?.entityType === PagePatientOverviewType && (
        <UndoInfoToast className={styles['undo-info-toast']} />
      )}

      {popupPage && (
        <div
          className={classes(styles['popup-page'], menuOpen && styles['popup-page-long'])}
          style={{
            zIndex: ZIndex.PopupPageMainMessenger,
          }}
        >
          <FactoryComponent
            data={popupPage}
            onClose={() => {
              closePopupPage(popupPage);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MessengerMainPage;
