import { useState, useEffect } from 'react';

export const useMouseScroll = (ref: React.RefObject<HTMLElement>) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  useEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => {
      if (isDragging) {
        const currentX = e.pageX;
        const moveDistance = currentX - startX;
        if (ref.current) {
          ref.current.scrollLeft -= moveDistance;
        }
        setStartX(currentX);
      }
    };

    const mouseUpHandler = () => {
      if (isDragging) {
        setIsDragging(false);
      }
    };

    const mouseDownHandler = (e: MouseEvent) => {
      // Right mouse button (context menu)
      setIsDragging(true);
      setStartX(e.pageX);
      e.preventDefault(); // Prevent context menu
    };

    // Attach the event listeners to the element
    const element = ref.current;
    element?.addEventListener('mousedown', mouseDownHandler);
    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);

    // Clean up event listeners on unmount
    return () => {
      element?.removeEventListener('mousedown', mouseDownHandler);
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [ref, isDragging, startX]); // Dependencies array includes ref, isDragging, and startX

  // Disable the context menu on the ref element to prevent it from appearing on right click
  useEffect(() => {
    const contextMenuHandler = (e: Event) => {
      // e.preventDefault();
    };

    const element = ref.current;
    element?.addEventListener('contextmenu', contextMenuHandler);

    return () => {
      element?.removeEventListener('contextmenu', contextMenuHandler);
    };
  }, [ref]); // Dependency array includes ref to re-apply if the ref changes
};

export default useMouseScroll;
