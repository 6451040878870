import styles from './main-info-tab-icon-with-counter.module.css';
import { InformProjectionVM, mainInfoContains } from '@sqior/viewmodels/communication';
import { SvgIconTasks } from '@sqior/react/uibase';

/* eslint-disable-next-line */
export interface MainInfoTabIconWithCounterProps {
  items: InformProjectionVM[];
}

export function MainInfoTabIconWithCounter(props: MainInfoTabIconWithCounterProps) {
  const count = props.items.filter((item) => mainInfoContains(item)).length;

  return (
    <div className={styles['container']}>
      <SvgIconTasks />
      {count > 0 && <div className={styles['counter']}>{count}</div>}
    </div>
  );
}

export default MainInfoTabIconWithCounter;
