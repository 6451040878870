import styles from './notification-status.module.css';

import { ReactComponent as NotificationIcon } from './icon-notification.svg';
import { useDynamicState } from '@sqior/react/state';
import { NoTimestamp } from '@sqior/js/data';
import { AlarmVM } from '@sqior/viewmodels/communication';

/* eslint-disable-next-line */
export interface NotificationStatusProps {}

export function NotificationStatus(props: NotificationStatusProps) {
  const notState = useDynamicState<AlarmVM>('notification', { count: 0, timestamp: NoTimestamp });

  return (
    <div className={styles['notification']}>
      <NotificationIcon viewBox="0 0 40 40" className={styles['img-notification']} fill="#ffffff" />
      {notState.count > 0 && <div className={styles['counter']}>{notState.count}</div>}
    </div>
  );
}

export default NotificationStatus;
