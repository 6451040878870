import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  ORWorkflowInteractiveDashboardClusterSelectionData,
  SelectORWorkflowInteractiveDashboardCluster,
} from '@sqior/viewmodels/orworkflow';
import { useContext, useEffect } from 'react';
import { OperationContext } from '@sqior/react/operation';
import styles from './cluster-selection-view.module.css';
import CheckIcon from '@mui/icons-material/Check';

export interface ClusterSelectionViewProps {
  clusterSelection?: ORWorkflowInteractiveDashboardClusterSelectionData;
}

const MenuStyle: Partial<MenuProps> = {
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
  slotProps: {
    paper: {
      style: {
        backgroundColor: 'transparent',
        minWidth: 200,
        flexShrink: 0,
        color: 'white',
        border: '0.5px solid #ffffff7f',
        borderRadius: 11,
        background: 'rgba(9, 15, 35, 0.8)',
        backdropFilter: 'blur(5px)',
        boxShadow: 'none',
        padding: 0,
      },
    },
  },
};

const getNextIndex = (length: number, currentIndex: number): number => {
  return (currentIndex + 1) % length;
};

const getPreviousIndex = (length: number, currentIndex: number): number => {
  return currentIndex === 0 ? length - 1 : currentIndex - 1;
};

enum Key {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

const getIndexBasedOnKeyPress = (key: Key, selectedIndex: number, length: number): number => {
  switch (key) {
    case Key.ArrowDown:
      return getNextIndex(length, selectedIndex);
    case Key.ArrowUp:
      return getPreviousIndex(length, selectedIndex);
    default:
      return selectedIndex;
  }
};

export function ClusterSelectionView({ clusterSelection }: ClusterSelectionViewProps) {
  const dispatcher = useContext(OperationContext);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value; // Cluster ID as string
    dispatcher.start(SelectORWorkflowInteractiveDashboardCluster(value));
  };

  useEffect(() => {
    const onkeydown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA') return;

      if (
        !clusterSelection ||
        clusterSelection.selected === undefined ||
        clusterSelection.clusters.length === undefined
      ) {
        return;
      }
      const { selected, clusters } = clusterSelection;
      const key = event.key as Key;

      if (key !== Key.ArrowDown && key !== Key.ArrowUp) {
        return;
      }
      const calculatedIndex = getIndexBasedOnKeyPress(key, selected, clusters.length);
      const selectedClusterId = clusters[calculatedIndex].id;

      dispatcher.start(SelectORWorkflowInteractiveDashboardCluster(selectedClusterId));
    };

    window.addEventListener('keydown', onkeydown);

    return () => {
      window.removeEventListener('keydown', onkeydown);
    };
  }, [clusterSelection]);

  return (
    <Box sx={{ minWidth: 200, height: '100%' }}>
      <FormControl
        fullWidth
        disabled={!clusterSelection}
        sx={{
          '& .MuiList-root-MuiMenu-list': {
            backgroundColor: 'red',
          },
        }}
      >
        <InputLabel>Cluster-Auswahl</InputLabel>
        <Select
          value={clusterSelection?.clusters[clusterSelection.selected]?.id ?? ''}
          label="Cluster-Auswahl"
          onChange={handleChange}
          renderValue={() => {
            return (
              <p style={{ margin: 0, padding: 0 }}>
                {clusterSelection?.clusters[clusterSelection.selected].label}
              </p>
            );
          }}
          MenuProps={MenuStyle}
        >
          {clusterSelection?.clusters.map((cluster, index) => (
            <MenuItem
              selected={false}
              key={cluster.id}
              value={cluster.id}
              style={{
                borderBottom:
                  index !== clusterSelection.clusters.length - 1 ? '1px solid #ffffff7f' : 'none',
                backgroundColor: 'transparent',
              }}
            >
              <div className={styles['icon-container']}>
                {clusterSelection.selected === index && <CheckIcon style={{ fontSize: 20 }} />}
              </div>
              <div className={styles['item-content']}>
                <p style={{ padding: 0, margin: 0, fontSize: 20 }}>{cluster.label}</p>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ClusterSelectionView;
