import styles from './statistics-comparison-panel-item.module.css';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TrendType } from '../performance-indicators/performance-indicators';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

export type StatisticsComparisonPanelItemData = {
  title: string;
  valueBefore: number;
  valueUnit: string;
  valueAfter: number;
  trend?: TrendType;
};

export interface StatisticsComparisonPanelItemProps {
  data: StatisticsComparisonPanelItemData;
  lastItem: boolean;
}

export function StatisticsComparisonPanelItem({
  data,
  lastItem,
}: StatisticsComparisonPanelItemProps) {
  return (
    <div
      className={styles['container']}
      style={{
        borderBottom: lastItem ? 'none' : undefined,
      }}
    >
      <div className={styles['title']}>{data.title}</div>
      <div className={styles['stat-container']}>
        <div className={styles['item-value']}>{`${data.valueBefore}${data.valueUnit}`}</div>
        <div>
          {data.trend === 'up' ? (
            <ArrowOutwardRoundedIcon color="success" />
          ) : data.trend === 'down' ? (
            <ArrowOutwardRoundedIcon
              color="warning"
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          ) : null}
        </div>
        <div className={styles['item-value']}>{`${data.valueAfter}${
          data.valueUnit ? data.valueUnit : ''
        }`}</div>
      </div>
      <div className={styles['more-icon-placeholder']}></div>

      <div className={styles['more-icon']}>
        <IconButton>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

export default StatisticsComparisonPanelItem;
