import { FactoryProps } from '@sqior/react/factory';
import styles from './collpased-standard-info-item.module.css';
import {
  CollpasedStandardInfoItemVM,
  MarkRead,
  PageStandardInfoThreadType,
  ReadConfirmationTypes,
  StandardInfoPath,
} from '@sqior/viewmodels/communication';
import {
  HighlightButton,
  SvgIconNavigateBefore,
  SvgIconNavigateNext,
  useTextResources,
} from '@sqior/react/uibase';
import EntityVisual from '../entity-visual/entity-visual';
import { OperationContext } from '@sqior/react/operation';
import { useContext, useRef, useState } from 'react';
import { OpenPage } from '@sqior/viewmodels/app';
import { getPrettyTimeOrDayString } from '@sqior/react/utils';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { now } from '@sqior/js/data';

/* eslint-disable-next-line */
export type CollpasedStandardInfoItemProps = FactoryProps<CollpasedStandardInfoItemVM>;

export function CollpasedStandardInfoItem(props: CollpasedStandardInfoItemProps) {
  //Add a state that will force a re-render
  const [, setInit] = useState(false);

  const [curItem, setCurItem] = useState(0);
  const dispatcher = useContext(OperationContext);
  function markRead() {
    dispatcher.start(
      MarkRead({
        id: props.data.ids,
        confirmationType: ReadConfirmationTypes.Explicit,
        timestamp: now(),
      })
    );
  }
  function openMessages(e: React.MouseEvent) {
    dispatcher.start(
      OpenPage({
        entityType: PageStandardInfoThreadType,
        data: { stateBasePath: StandardInfoPath },
      })
    );
  }

  const textDict = useTextResources();

  const navRefBefore = useRef(null);
  const navRefNext = useRef(null);
  const numItems = props.data.message.length;
  return (
    <div className={styles['main-info-item']}>
      <div className={styles['content']}>
        <div className={styles['header-inner']}>
          <div
            className={
              styles[
                props.data.ids.length > 1 && curItem > 0
                  ? 'navigation-before'
                  : 'navigation-before-disabled'
              ]
            }
            ref={navRefBefore}
          >
            <SvgIconNavigateBefore />
          </div>
          <div className={styles['header']}>
            Nachricht {curItem + 1} / {numItems}
          </div>
          <div
            className={
              styles[
                props.data.ids.length > 1 && curItem + 1 < props.data.ids.length
                  ? 'navigation-next'
                  : 'navigation-next-disabled'
              ]
            }
            ref={navRefNext}
          >
            <SvgIconNavigateNext />
          </div>
        </div>
        <div>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              enabled: true,
              nextEl: props.data.ids.length > 1 ? navRefNext.current : undefined,
              prevEl: props.data.ids.length > 1 ? navRefBefore.current : undefined,
            }}
            onInit={() => setInit(true)}
            onSlideChange={(sw) => {
              setCurItem(sw.activeIndex);
            }}
            modules={[Navigation]}
          >
            {props.data.message.map((i, idx) => (
              <SwiperSlide key={idx} className={styles['swiper-page']}>
                <div className={styles['message']}>
                  <div onClick={openMessages}>
                    <EntityVisual content={i.message} />
                  </div>
                  <div className={styles['message-time']}>
                    <div>{getPrettyTimeOrDayString(i.timestamp)}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles['buttons']}>
          <HighlightButton secondary={false} onClick={() => markRead()}>
            {textDict.get('mark_read')}
          </HighlightButton>
        </div>
      </div>
    </div>
  );
}

export default CollpasedStandardInfoItem;
