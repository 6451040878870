import { OperationHandler } from './handler';
import { Operation, OperationSpec, OperationState } from './operation';
import { OperationEmitter } from './operation-emitter';
import { OperationFactory } from './operation-factory';

export type OperationListener = (op: Operation, path: string) => void;
type Entry = { terminator?: OperationListener; listeners: OperationEmitter };
export type DispatcherContext = { dispatcher: Dispatcher };

export class Dispatcher implements OperationHandler {
  constructor() {
    this.handlers = new Map<string, Entry>();
  }

  register(handler: OperationHandler | OperationListener, path = '') {
    const item = this.ensureEmitter(path);
    if (item.terminator)
      throw new Error(
        'It is not valid to define multiple terminating handlers for the same path: ' + path
      );
    if (typeof handler === 'object')
      item.terminator = (op, path) => {
        handler.handle(op, path);
      };
    else item.terminator = handler;
  }

  listen(handler: OperationHandler | OperationListener, path = '') {
    const handlers = this.ensureEmitter(path);
    if (typeof handler === 'object') handlers.listeners.attach(handler);
    else handlers.listeners.operation.on(handler);
  }

  handle(op: Operation, path?: string) {
    /* Inform all handlers on the path about the operation */
    if (!path) path = '';
    let subPath = '';
    let terminated = false;
    for (;;) {
      /* Check handlers on this path */
      const item = this.handlers.get(path);
      if (item) {
        if (item.terminator && !terminated) {
          item.terminator(op, subPath);
          terminated = true;
        }
        item.listeners.operation.emit(op, subPath);
      }

      /* Check if this is the root */
      if (path.length === 0) break;
      /* Advance to next level up */
      const index = path.lastIndexOf('/');
      if (index >= 0) {
        subPath =
          subPath.length > 0
            ? path.substring(index + 1) + '/' + subPath
            : path.substring(index + 1);
        path = path.substring(0, index);
      } else {
        subPath = subPath.length > 0 ? path + '/' + subPath : path;
        path = '';
      }
    }

    /* Check if the operation was terminated, if not set it to failed */
    if (!terminated) op.fail();
  }

  /* Convience helper to start an operation from a POD service definition */
  start(
    opSpec: OperationSpec,
    stateList?: (state: OperationState) => void,
    progressList?: (progress: number) => void
  ) {
    const op = OperationFactory.create(opSpec.type, opSpec.data);
    if (progressList) op.progressChange.on(progressList);
    if (stateList) op.stateChange.on(stateList);
    this.handle(op, opSpec.path);
    return op;
  }

  private ensureEmitter(path: string) {
    let entry = this.handlers.get(path);
    if (entry) return entry;
    entry = { listeners: new OperationEmitter() };
    this.handlers.set(path, entry);
    return entry;
  }

  private handlers: Map<string, Entry>;
}
