import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';

/* Entities */

export enum LanguageEntities {
  TextResource = 'TextResource',
  Gender = 'Gender',
  AnonymizedText = 'AnonymizedText',
  Verbosity = 'Verbosity',
  Case = 'GrammaticalCase',
  Texts = 'Texts',
  AlternativeTexts = 'AlternativeTexts',
  ConcatenationTexts = 'ConcatenationTexts',
  AnonymizationLevel = 'AnonymizationLevel', // Level of anonymization to apply
}

/* Interfaces */

export enum LanguageInterfaces {
  Anonymized = 'AnonymizeText', // Anonymized text version of an entity
  InsidePreposition = 'InsidePreposition', // Preposition to use if something is inside e.g. "im" or "in die" (de)
  IntoPreposition = 'IntoPreposition', // Preposition to use if something is put into e.g. "in den", "in die" or "in das" (de)
  FromPreposition = 'FromPreposition', // Preposition to use if something is picked up from e.g. "vom", "von der" (de)
  ToPreposition = 'ToPreposition', // Preposition to use if someone is going to e.g. "zum", "zur" (de)
  PlainIdText = 'PlainIdText', // Returns the plain text of an identifier and not a nicely human readable text
}

/* Context properties */

export const AnonymizationContextProperty = 'anonymize';
export const GenderContextProperty = 'gender';
export const GrammaticalCaseContextProperty = 'grammaticalCase';
export const VerbosityContextProperty = 'verbosity';

/* Entity representing a list of texts */

export type TextsEntity = Entity & { texts: Entity[] };
export const TextsModel: EntityModel = { type: LanguageEntities.Texts, props: ['texts'] };
export function makeTexts(texts: Entity[], type: string = TextsModel.type): TextsEntity {
  return { entityType: type, texts: texts };
}

/** Entity representing a list of texts that are catenated using 'or' instead of 'and' */

export const AlternativeTextsModel: EntityModel = {
  type: LanguageEntities.AlternativeTexts,
  props: ['texts'],
};
export const ConcatenationTextsModel: EntityModel = {
  type: LanguageEntities.ConcatenationTexts,
  props: ['texts'],
};

/* Interface to get access to the plain patient id text */

export const PlainIdTextModel: Interface = {
  type: LanguageInterfaces.PlainIdText,
  requires: CoreEntities.Text,
};
