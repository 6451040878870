import { ArraySource, ensureArray } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import {
  LocationClusterFunction,
  LocationEntities,
  LocationInterfaces,
  LocationsEntity,
  RoomFunctions,
} from '@sqior/plugins/location';
import { UserEntities, UserInterfaces } from './user-definitions';
import { RoleTextResources } from './user-text-resources';

export enum Roles {
  NewEmployee = 'NewEmployee', // In same cases, there must be an initial role without any access rights
  ORCoordinator = 'ORCoordinator',
  ORManager = 'ORManager', // Usually similar to ORCoordinator, but not in the communication loop
  SDSEmployee = 'SDSEmployee',
  HoldingEmployee = 'Holding',
  ORDisponent = 'ORDisponent',
  ORDisponentLead = 'ORDisponentLead', // Coordinates the activities of a group of OR disponents
  Anesthesist = 'Anesthesist',
  AnesthesistDirector = 'AnesthesistDirector',
  AnesthesistGroupLead = 'AnesthesistGroupLead',
  AnesthesiaNurse = 'AnesthesiaNurse',
  AnesthesiaNurseDirector = 'AnesthesiaNurseDirector',
  AnesthesiaNurseWardLead = 'AnesthesiaNurseWardLead',
  AnesthesiaNurseGroupLead = 'AnesthesiaNurseGroupLead',
  Surgeon = 'Surgeon', // Physician who is operating in the OR
  WardPhysician = 'WardPhysician', // Physicians at Ward, but also emergency room, not operating
  PhysicianOnDuty = 'PhysicianOnDuty', // Physician on duty
  ORNurse = 'ORNurse',
  ORNurseDirector = 'ORNurseDirector',
  ORNurseGroupLead = 'ORNurseGroupLead', // Coordinates the activities of a group of OR nurses
  RecoveryEmployee = 'Recovery',
  ORSpectator = 'ORSpectator',
  CleaningStaff = 'CleaningStaff',
  OccupancyManager = 'OccupancyManager',
  CaseManager = 'CaseManager',
  WardNurse = 'WardNurse',
  Transport = 'Transport',
  Tester = 'Tester',
  SqiorEmployee = 'SqiorEmployee',
  NeuromonitoringEmployee = 'NeuromonitoringEmployee', // Operates the neuro-monitoring device and analyzes the output
  NeuromonitoringDirector = 'NeuromonitoringDirector', // Manages a group of neuro-monitoring employees
}

export const RolesList = [
  Roles.Anesthesist,
  Roles.AnesthesistGroupLead,
  Roles.AnesthesistDirector,
  Roles.AnesthesiaNurse,
  Roles.AnesthesiaNurseGroupLead,
  Roles.AnesthesiaNurseDirector,
  Roles.AnesthesiaNurseWardLead,
  Roles.Surgeon,
  Roles.WardPhysician,
  Roles.PhysicianOnDuty,
  Roles.ORCoordinator,
  Roles.ORManager,
  Roles.ORDisponent,
  Roles.ORDisponentLead,
  Roles.ORNurse,
  Roles.ORNurseGroupLead,
  Roles.ORNurseDirector,
  Roles.ORSpectator,
  Roles.SDSEmployee,
  Roles.HoldingEmployee,
  Roles.RecoveryEmployee,
  Roles.Transport,
  Roles.CaseManager,
  Roles.OccupancyManager,
  Roles.WardNurse,
  Roles.CleaningStaff,
  Roles.NewEmployee,
  Roles.NeuromonitoringEmployee,
  Roles.NeuromonitoringDirector,
  Roles.Tester,
  Roles.SqiorEmployee,
];

export const RoleTexts = new Map<string, string>([
  [Roles.AnesthesiaNurse, 'anesthesia_nurse'],
  [Roles.AnesthesiaNurseDirector, 'anesthesia_nurse_director'],
  [Roles.AnesthesiaNurseGroupLead, 'anesthesia_nurse_group_lead'],
  [Roles.AnesthesiaNurseWardLead, 'anesthesia_nurse_ward_lead'],
  [Roles.Anesthesist, 'anesthesist'],
  [Roles.AnesthesistGroupLead, 'anesthesist_group_lead'],
  [Roles.AnesthesistDirector, 'anesthesist_director'],
  [Roles.CaseManager, 'case_manager'],
  [Roles.CleaningStaff, 'cleaning_staff'],
  [Roles.NewEmployee, 'new_employee'],
  [Roles.HoldingEmployee, 'holding_employee'],
  [Roles.NeuromonitoringDirector, RoleTextResources.NeuromonitoringDirector],
  [Roles.NeuromonitoringEmployee, RoleTextResources.NeuromonitoringEmployee],
  [Roles.OccupancyManager, 'occupancy_manager'],
  [Roles.ORCoordinator, 'or_coordinator'],
  [Roles.ORManager, 'or_manager'],
  [Roles.ORDisponent, 'or_disponent'],
  [Roles.ORDisponentLead, 'or_disponent_lead'],
  [Roles.ORNurse, 'or_nurse'],
  [Roles.ORNurseGroupLead, 'or_nurse_group_lead'],
  [Roles.ORNurseDirector, 'or_nurse_director'],
  [Roles.ORSpectator, 'or_spectator'],
  [Roles.PhysicianOnDuty, 'physician_on_duty'],
  [Roles.RecoveryEmployee, 'recovery_employee'],
  [Roles.SDSEmployee, 'sds_employee'],
  [Roles.Surgeon, 'surgeon'],
  [Roles.SqiorEmployee, 'sqior_employee'],
  [Roles.Tester, 'tester'],
  [Roles.Transport, 'transport_service'],
  [Roles.WardNurse, 'ward_nurse'],
  [Roles.WardPhysician, 'physician'],
]);

/* Role entity */

export type RoleEntity = Entity & { role: string };
export const RoleEntityModel: EntityModel = {
  type: UserEntities.Role,
  props: ['role'],
  keys: ['role'],
  unclassified: true,
};
export function makeRoleEntity(role: string): RoleEntity {
  return { entityType: UserEntities.Role, role: role };
}
export const RoleSqiorEmployee = makeRoleEntity(Roles.SqiorEmployee);

/* Roles entity */

export type RolesEntity = Entity & { roles: Entity[] };
export const RolesEntityModel: EntityModel = { type: UserEntities.Roles, props: ['roles'] };
export const ActiveLocationRolesModel: EntityModel = {
  type: UserEntities.ActiveLocationRoles,
  props: ['roles'],
};
export const EffectiveRolesModel: Interface = {
  type: UserInterfaces.EffectiveRoles,
  requires: [UserEntities.Roles],
};
export function makeRolesEntity(
  roles: ArraySource<Entity>,
  type: string = UserEntities.Roles
): RolesEntity {
  return { entityType: type, roles: ensureArray(roles) };
}
export function hasRole(roles: RolesEntity | undefined, role: string | string[]) {
  if (!roles) return false;
  if (typeof role === 'string')
    return (
      roles.roles.findIndex((value) => {
        return value.entityType === UserEntities.Role && (value as RoleEntity).role === role;
      }) >= 0
    );
  else
    for (const r of role)
      if (
        roles.roles.findIndex((value) => {
          return value.entityType === UserEntities.Role && (value as RoleEntity).role === r;
        }) >= 0
      )
        return true;
  return false;
}
export function extractRoles(roles: RolesEntity) {
  const res: string[] = [];
  for (const role of roles.roles)
    if (role.entityType === UserEntities.Role) res.push((role as RoleEntity).role);
    else if (role.entityType === UserEntities.LocationRole)
      res.push(((role as LocationRole).role as RoleEntity).role);
  return res;
}
export function intersectRoles(roles: RolesEntity, check: string[]) {
  const matchRoles: Entity[] = [];
  for (const roleEnt of roles.roles) {
    let role: string;
    if (roleEnt.entityType === UserEntities.Role) role = (roleEnt as RoleEntity).role;
    else if (roleEnt.entityType === UserEntities.LocationRole)
      role = ((roleEnt as LocationRole).role as RoleEntity).role;
    else continue;
    if (
      check.findIndex((el) => {
        return el === role;
      }) >= 0
    )
      matchRoles.push(roleEnt);
  }
  return matchRoles;
}

/** Roles options entity - a selection of alternative role sets */

export type RolesOptions = Entity & { options: Entity[] };
export const RolesOptionsModel: EntityModel = {
  type: UserEntities.RolesOptions,
  props: ['options'],
};
export function makeRolesOptions(options: ArraySource<Entity>): RolesOptions {
  return { entityType: UserEntities.RolesOptions, options: ensureArray(options) };
}
export const SelectableRolesOptionsModel: Interface = {
  type: UserInterfaces.SelectableRolesOptions,
  requires: UserEntities.RolesOptions,
};

/* Active locations */

export const ActiveLocationsModel: EntityModel = {
  type: UserEntities.ActiveLocations,
  props: ['locations'],
};
export function makeActiveLocations(locations: Entity | Entity[]): LocationsEntity {
  return { entityType: UserEntities.ActiveLocations, locations: ensureArray(locations) };
}
export const ActiveLocationLabel: Interface = {
  type: UserInterfaces.ActiveLocationLabel,
};

/* Available locations */

export const AvailableLocationsModel: Interface = {
  type: UserInterfaces.AvailableLocations,
  requires: LocationEntities.Locations,
};
export const AvailableLocationsForUserModel: Interface = {
  type: UserInterfaces.AvailableLocationsForUser,
  requires: LocationEntities.Locations,
};
export const UserActiveLocationsModel: Interface = {
  type: UserInterfaces.ActiveLocations,
  requires: UserEntities.ActiveLocations,
};
export const EffectiveLocationModel: Interface = {
  type: UserInterfaces.EffectiveLocation,
  requires: LocationInterfaces.Key,
};
export const EffectiveLocationsModel: Interface = {
  type: UserInterfaces.EffectiveLocations,
  requires: LocationEntities.Locations,
};
export const PermanentLocationModel: Interface = {
  type: UserInterfaces.PermanentLocation,
  requires: [LocationInterfaces.LocationOrCluster],
};

export const AllowedLocationsModel: Interface = {
  type: UserInterfaces.AllowedLocations,
  requires: LocationEntities.Locations,
};

/* Location role */

export type LocationRole = Entity & { location: Entity; role: Entity };
export const LocationRoleModel: EntityModel = {
  type: UserEntities.LocationRole,
  props: ['location', 'role'],
  keys: ['location', 'role'],
};
export function makeLocationRole(location: Entity, role: Entity): LocationRole {
  return { entityType: UserEntities.LocationRole, location: location, role: role };
}

/* Specialty Location role */

export type SpecialtyLocationRole = Entity & { specialty: Entity; location: Entity; role: Entity };
export const SpecialtyLocationRoleModel: EntityModel = {
  type: UserEntities.SpecialtyLocationRole,
  props: ['specialty', 'location', 'role'],
  keys: ['specialty', 'location', 'role'],
};
export function makeSpecialtyLocationRole(
  specialty: Entity,
  location: Entity,
  role: Entity
): SpecialtyLocationRole {
  return {
    entityType: UserEntities.SpecialtyLocationRole,
    specialty: specialty,
    location: location,
    role: role,
  };
}

/* Concrete role, e.g. a plain role, a location role or a specialty location role based on the organizational definitions */

export type LocationRoleTemplate = Roles | [Roles, RoomFunctions | LocationClusterFunction];
export const EffectiveRoleModel: Interface = {
  type: UserInterfaces.EffectiveRole,
  requires: [UserEntities.Role, UserEntities.LocationRole, UserEntities.SpecialtyLocationRole],
};

/* Interface to determine the base role of a role or location role */

export const BaseRoleModel: Interface = {
  type: UserInterfaces.BaseRole,
  requires: UserEntities.Role,
};

/* Interface to determine a role or location role of an address */

export const AssociatedRoleModel: Interface = { type: UserInterfaces.AssociatedRole };

/* Interface to determine a role of an address if available */

export const RoleIfAvailableModel: Interface = {
  type: UserInterfaces.RoleIfAvailable,
};

/* Interface to check if a user possesses a role */

export const HasRoleModel: Interface = { type: UserInterfaces.HasRole };

/* Interface returning all possible roles for a user */

export const AvailableRolesModel: Interface = {
  type: UserInterfaces.AvailableRoles,
  requires: UserEntities.Roles,
};
