import styles from './react-uipatient.module.css';

/* eslint-disable-next-line */
export interface ReactUipatientProps {}

export function ReactUipatient(props: ReactUipatientProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUipatient!</h1>
    </div>
  );
}

export default ReactUipatient;
