/* Entities */

import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';

export enum DeviceEntities {
  PushDevice = 'PushDevice',
  PushDevices = 'PushDevices',
  Type = 'DeviceType',
  PhoneNumber = 'PhoneNumber',
  PhoneNumberType = 'PhoneNumberType',
  PhoneNumbers = 'PhoneNumbers',
  PhoneNumbersVM = 'PhoneNumbersVM',
  Equipment = 'Equipment', // Set of devices or device functions
  Function = 'DeviceFunction', // Function of a device
}

/* Interfaces */

export enum DeviceInterfaces {
  Key = 'DeviceKey',
  PhoneNumberEntryDesired = 'PhoneNumberEntryDesired', // Flag if phone number entry is desired
  PhoneNumbers = 'IfPhoneNumbers',
  FacilityBasePhoneNumber = 'FacilityBasePhoneNumber',
}

/* Device type */

export enum DeviceType {
  Mobile = 'MobilePhone',
  Desktop = 'DesktopComputer',
}
export type DeviceTypeEntity = Entity & { deviceType: string };
export const DeviceTypeModel: EntityModel = {
  type: DeviceEntities.Type,
  props: ['deviceType'],
  unclassified: true,
};
export function makeDeviceType(type: string): DeviceTypeEntity {
  return { entityType: DeviceEntities.Type, deviceType: type };
}

export const FacilityBasePhoneNumberInterfaceModel: Interface = {
  type: DeviceInterfaces.FacilityBasePhoneNumber,
  requires: DeviceEntities.PhoneNumber,
};

/** Phone number entry desired flag */

export const PhoneNumberEntryDesiredModel: Interface = {
  type: DeviceInterfaces.PhoneNumberEntryDesired,
};

export const PhoneNumbersInterfaceModel: Interface = {
  type: DeviceInterfaces.PhoneNumbers,
  represents: DeviceEntities.PhoneNumbers,
};
