import { EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';
import { AllListViewsIndexStatePath, ListViewInfos } from '@sqior/viewmodels/visual';
import { State } from '@sqior/js/state';
import { IdEntity } from '@sqior/js/entity';

export type TestUser = IdEntity;
export const TestUserModel: EntityModel = {
  type: UserEntities.TestUser,
  props: ['id'],
  keys: ['id'],
};
export function makeTestUser(id: string): TestUser {
  return { entityType: UserEntities.TestUser, id: id };
}

export function getAllListViewInfos(state: State): ListViewInfos | undefined {
  const subState = state.subState(AllListViewsIndexStatePath);
  /* Make sure sub state is used if it is calculated on demand */
  if (!subState.used) subState.use();
  return subState.getRaw<ListViewInfos>();
}
