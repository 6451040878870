import { Interface } from '@sqior/js/meta';

export enum PersonEntities {
  PersonName = 'PersonName',
  PersonSex = 'PersonSex',
  PersonSexSymbol = 'PersonSexSymbol',
  DateOfBirth = 'DateOfBirth',
  Age = 'Age',
}

export enum PersonInterfaces {
  Name = 'GetPersonName',
  FluentName = 'FluentName',
  RandomName = 'RandomName',
  ShortName = 'ShortPersonName', // Shortened person name using initials for first name
}

/** Interface returning a random name */

export const RandomNameModel: Interface = {
  type: PersonInterfaces.RandomName,
  requires: PersonEntities.PersonName,
};
