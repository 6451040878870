import { Bytes, Value } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel, substituteEntities, visitEntities, VisitEntitiesResult } from './entity';
import { Undefined } from './function';

/* Definition of a place-holder that is supposed to be replaced by dynamic parameters */

export type ParameterEntity = Entity & { name: string; preserveCount: number };
export const ParameterEntityModel: EntityModel = {
  type: CoreEntities.Parameter,
  props: ['name', 'preserveCount'],
  unclassified: true,
};
export function makeParameter(name: string, preserveCount = 0): ParameterEntity {
  return { entityType: CoreEntities.Parameter, name: name, preserveCount: preserveCount };
}

/* Substitutes dynamic parameter entities in an object */

export enum SubstituteParametersUnknownHandling {
  Throw,
  Preserve,
  Substitute,
}
export function substituteParameters<Type extends Value = Value>(
  obj: Type,
  params: Record<string, Entity | null>,
  unknownHandling = SubstituteParametersUnknownHandling.Throw
): Type | undefined {
  const res = substituteEntities<Type>(obj, {
    Parameter: (entity) => {
      const paramEnt = entity as ParameterEntity;
      /* Check if the parameter shall be preserved */
      if (paramEnt.preserveCount > 0)
        return makeParameter(paramEnt.name, paramEnt.preserveCount - 1);
      const subs = params[paramEnt.name];
      if (subs !== undefined) return subs !== null ? subs : undefined;
      else if (unknownHandling === SubstituteParametersUnknownHandling.Preserve) return entity;
      else if (unknownHandling === SubstituteParametersUnknownHandling.Substitute) return Undefined;
      else
        throw new Error(
          'Cannot substitute parameter because it is not found in parameter map - provided name: ' +
            paramEnt.name
        );
    },
  });
  return res ? (res as Type) : undefined;
}

/* Extracts the actual parameters from a an entity instance based on a filter object */

export function extractParameters(filter: Value, entity: Value): [string, Entity][] {
  let res: [string, Entity][] = [];

  /* Check if these are primitive types */
  if (typeof filter !== typeof entity)
    throw new Error(
      'Types of property of the filter and the entity in extractParameters() do not match'
    );
  if (
    typeof filter !== 'object' ||
    typeof entity !== 'object' ||
    filter instanceof Bytes ||
    entity instanceof Bytes
  )
    return res;

  /* Check if this is an array and recurse */
  if (filter instanceof Array || entity instanceof Array) {
    if (!(filter instanceof Array) || !(entity instanceof Array))
      throw new Error(
        'Types of property of the filter and the entity in extractParameters() do not match'
      );
    if (filter.length > entity.length)
      throw new Error(
        'Length of array property of the filter exceeds the length of the entity in extractParameters()'
      );
    for (let i = 0; i < filter.length; i++)
      res = res.concat(extractParameters(filter[i], entity[i]));
    return res;
  }

  /* Check if this a filter for a parameter */
  if (
    'entityType' in filter &&
    filter['entityType'] === CoreEntities.Parameter &&
    'name' in filter
  ) {
    if (!('entityType' in entity) || typeof entity['entityType'] !== 'string')
      throw new Error(
        'Filter expects parameter in extractParameters() but entity is not matched - expected parameter: ' +
          filter['name']
      );
    return [[filter['name'] as string, entity as Entity]];
  }

  /* Loop all keys of the filer and recurse */
  for (const key in filter) {
    const fValue = filter[key];
    const eValue = entity[key];
    if (eValue === undefined)
      throw new Error(
        'Provided entity lacks a property of the filter in extractParameters() - key is: ' + key
      );
    res = res.concat(extractParameters(fValue, eValue));
  }

  return res;
}

/** Visits all active parameters of an entity */

export function visitParameters(entity: Value, callback: (param: string) => boolean) {
  return visitEntities(entity, (ent) => {
    /* Check if entity is a parameter */
    if (ent.entityType !== CoreEntities.Parameter) return VisitEntitiesResult.Recurse;
    const param = ent as ParameterEntity;
    /* Do not trigger on parameters that have a preservation count */
    if (param.preserveCount) return VisitEntitiesResult.Continue;
    return callback(param.name) ? VisitEntitiesResult.Continue : VisitEntitiesResult.Exit;
  });
}

/** Checks if an entity contains parameters */

export function hasParameters(entity: Value) {
  return !visitParameters(entity, () => {
    return false;
  });
}

/** Checks if an entity contains the specified parameter */

export function hasParameter(entity: Value, paramName: string) {
  return !visitParameters(entity, (param) => {
    return param !== paramName;
  });
}
