import { ValueObject } from '@sqior/js/data';
import { Dispatcher, Operation } from '@sqior/js/operation';
import { State, StateOverlay } from '@sqior/js/state';

export type StateOverlayCreator = (
  op: Operation,
  path: string,
  curVal: ValueObject | undefined
) => StateOverlay | undefined;

export class StateOverlayMap {
  constructor() {
    this.creators = [];
  }

  add(opPath: string, creator: StateOverlayCreator, statePath = '/' + opPath) {
    this.creators.push({ opPath: opPath, statePath: statePath, creator: creator });
  }

  configure(state: State, disp: Dispatcher) {
    /* Loop all entries  */
    for (const entry of this.creators) {
      disp.listen((op, path) => {
        /* Create overlay and register if applicable */
        const subState = state.subState(entry.statePath);
        const overlay = entry.creator(op, path, subState.getRaw());
        if (overlay) subState.addOverlay(overlay);
      }, entry.opPath);
    }
  }

  private creators: { opPath: string; statePath: string; creator: StateOverlayCreator }[];
}

export type StateOverlayContext = { stateOverlays: StateOverlayMap };
