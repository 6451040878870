import { ReadConfirmationTypes, SendChat } from '@sqior/viewmodels/communication';
import { OperationContext } from '@sqior/react/operation';
import {
  ButtonX,
  ContainerWithShadow,
  ExpandableTextarea,
  FileAttachment,
  FileAttachmentStatus,
  getMultiAttachmentStatus,
  SqiorFileUpload,
} from '@sqior/react/uibase';
import { InputMainButton, Thread } from '@sqior/react/uiconversation';
import React, { FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import styles from './chat.module.css';
import { classes } from '@sqior/react/utils';
import { AuthContext } from '@sqior/react/uiauth';
import { useUIGlobalState } from '@sqior/react/state';

export interface PersistedData {
  inputValue: string;
  createdAt: number;
}

interface AuthPersist {
  chatId: string;
  userId: string;
}

export const CHAT_PERSIST_ROOT_KEY = 'chat-input';
const DAYS_TO_KEEP = 7; // days
const EMPTY = '';

const makeKey = ({ chatId, userId }: AuthPersist): string => {
  return `${CHAT_PERSIST_ROOT_KEY}?${chatId}?${userId}`;
};

const getFinalIds = (attachments: FileAttachment[]): string[] => {
  const result: string[] = [];
  for (const attachment of attachments) {
    if (attachment.finalId) result.push(attachment.finalId);
  }
  return result;
};

const persistInputValue = (persistedData: PersistedData, auth: AuthPersist) => {
  try {
    const { inputValue } = persistedData;
    const key = makeKey({ chatId: auth.chatId, userId: auth.userId });
    if (inputValue === '') {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, JSON.stringify(persistedData));
  } catch (e) {
    console.error(e);
  }
};

const getPersistedInputValue = (auth: AuthPersist): PersistedData => {
  const key = makeKey(auth);
  const rawData = localStorage.getItem(key);
  if (!rawData) return { inputValue: '', createdAt: Date.now() };
  try {
    return JSON.parse(rawData) as PersistedData;
  } catch (e) {
    return { inputValue: 'Error', createdAt: Date.now() };
  }
};

const removeOldPersistedInputValue = () => {
  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('chat-input')) {
        const persistedData = localStorage.getItem(key);
        if (!persistedData) continue;
        const obj = JSON.parse(persistedData);
        if ('createdAt' in obj) {
          const createdAt = obj.createdAt as number;
          const now = Date.now();
          const diff = now - createdAt;
          const days = diff / (1000 * 60 * 60 * 24);
          if (days > DAYS_TO_KEEP) localStorage.removeItem(key);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const getAllowSend = (input: string, attachments: FileAttachment[]) => {
  if (input === '' && attachments.length === 0) return false;

  if (
    attachments.length !== 0 &&
    getMultiAttachmentStatus(attachments) !== FileAttachmentStatus.DONE
  )
    return false;

  return true;
};

export interface ChatProps {
  className?: string;
  chatId?: string;
}

export const Chat: FC<ChatProps> = ({ className, chatId }) => {
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const dispatcher = useContext(OperationContext);
  const context = useContext(AuthContext);
  const userId = context.provider.userInfo.userId;

  const [attachments, setAttachments] = useState<FileAttachment[]>([]);
  const [input, setInput] = useState<string>(EMPTY);

  const finalIDs = getFinalIds(attachments);
  const allowSend = getAllowSend(input, attachments);

  useEffect(() => {
    return () => {
      setUIGlobalState((prev) => ({ ...prev, previewSrc: undefined }));
    };
  }, []);

  useEffect(() => {
    if (!chatId || !userId) return;
    const persistedValue = getPersistedInputValue({ chatId, userId });
    setInput(persistedValue.inputValue);
  }, [chatId, userId]);

  useEffect(() => {
    if (!chatId || !userId) return;
    removeOldPersistedInputValue();
  }, [chatId, userId]);

  const onInputChanged = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setInput(value);
    if (!chatId || !userId) return;
    persistInputValue({ inputValue: value, createdAt: Date.now() }, { chatId, userId });
  };

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      send();
    }
  }

  function cancelInput() {
    setInput(EMPTY);
    if (!chatId || !userId) return;
    persistInputValue({ inputValue: EMPTY, createdAt: Date.now() }, { chatId, userId });
  }

  function send() {
    if (!allowSend) return;
    setInput(EMPTY);
    dispatcher.start(SendChat(input, finalIDs));
    setAttachments([]);
    if (!chatId || !userId) return;
    persistInputValue({ inputValue: EMPTY, createdAt: Date.now() }, { chatId, userId });
  }

  return (
    <div className={classes(styles['container'], className)}>
      <ContainerWithShadow className={styles['thread-area']}>
        <Thread stateBasePath="current-chat/data" autoMarkReadAs={ReadConfirmationTypes.Explicit} />
      </ContainerWithShadow>

      <div className={styles['input-panel']}>
        <div className={styles['left-actions']}>
          <SqiorFileUpload setAttachments={setAttachments} iconClassName={styles['camera-icon']} />
        </div>
        <div className={styles['input-editor-frame']}>
          <ExpandableTextarea
            className={styles['input-control']}
            classNameEdit={styles['input-control-textarea']}
            value={input}
            onInput={onInputChanged}
            onKeyDown={onKeyDown}
          />
          {input !== '' && (
            <ButtonX onClick={cancelInput} additionalClassName={styles['cancelbutton']} />
          )}
        </div>
        <div className={styles['input-panel-button']}>
          <InputMainButton onClick={send} enabled={allowSend} />
        </div>
      </div>
      <SqiorFileUpload.Gallery
        attachments={attachments}
        removeAttachment={(id) => {
          setAttachments(
            attachments.filter((at) => {
              return at.id !== id;
            })
          );
        }}
      />
      {UIGlobalState.previewSrc && (
        <SqiorFileUpload.Preview
          src={UIGlobalState.previewSrc}
          setSrc={(newValue) => {
            setUIGlobalState((prev) => ({ ...prev, previewSrc: newValue }));
          }}
        />
      )}
    </div>
  );
};

export default Chat;
