import styles from './orworkflow-overview-dashboard-tablet.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { factoryGetProp } from '@sqior/react/factory';
import { ReactComponent as TargetIcon } from '../orworkflow-overview-dashboard/target.svg';
import ZoomLevelView from '../zoom-level-view/zoom-level-view';
import ProcedureWrapper from '../procedure-wrapper/procedure-wrapper';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { ZIndex } from '@sqior/react/utils';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { ALLOWED_INTERVAL } from '../orworkflow-overview-dashboard/constants';
import { formatTime, isToday } from '../orworkflow-overview-dashboard/utils';
import OpConfigView from '../op-config-view/op-config-view';
import {
  MotionLayout,
  ORWorkflowOverviewDashboardProps,
} from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { useCustomTimer, useTimer } from '@sqior/react/state';
import CurrentTimer from '../current-timer/current-timer';
import { addSeconds } from '@sqior/js/data';
import PreliminaryProceduresView from '../preliminary-procedures-view/preliminary-procedures-view';
import { ORWorkflowOverviewDashboardORItemData } from '@sqior/viewmodels/orworkflow';

const MIN_WIDTH_CARD = 158; // min width of a card in px
const MENU_WIDTH = 64; // width of the menu in px
const MIN_ZOOM_LEVEL = 100;
const DEFAULT_ZOOM_LEVEL = 400;
const MAX_ZOOM_LEVEL = 1000;

const SCALE_FACTOR = 0.1; // Adjust this value to control zoom speed

export function ORWorkflowOverviewDashboardTablet(props: ORWorkflowOverviewDashboardProps) {
  const {
    data: { preliminaryProcedures, ors, interval, core, target },
  } = props;

  const { dragging, setDragging } = useTimer();
  const currentTime = useCustomTimer(addSeconds(10));

  const containerRef = useRef<HTMLDivElement>(null);
  const currentTimeRef = useRef<HTMLDivElement>(null);
  const menuContentRef = useRef<HTMLDivElement>(null);

  const initialDistance = useRef<number>(0);

  const autoScroll = factoryGetProp<boolean | undefined>('autoScroll', props);
  const openConfig = factoryGetProp<boolean | undefined>('openConfig', props);
  const showChallenges = factoryGetProp<boolean | undefined>('showChallenges', props);

  const selectedDate = props.data.date;

  const [zoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM_LEVEL);
  const [isZoomVisible, setIsZoomVisible] = useState(false);
  const [motionLayout, setMotionLayout] = useState<MotionLayout | undefined>('position');
  const [timeCalc, setTimeCalc] = useState<TimePositionCalculator>();
  const [targetDate, setTargetDate] = useState<Date>(new Date());

  const { ref: contentRef, height: contentHeight = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    const calInterval = ALLOWED_INTERVAL({
      selectedDate: new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day),
      interval,
    });
    setTimeCalc(new TimePositionCalculator(calInterval.start.getTime(), calInterval.end.getTime()));
    if (target) {
      setTargetDate(
        new Date(
          selectedDate.year,
          selectedDate.month - 1,
          selectedDate.day,
          target.hours,
          target.minutes
        )
      );
    }
  }, [currentTime, selectedDate, core, zoomLevel, target, interval]);

  const firstScroll = useRef(true);

  useEffect(() => {
    if (!currentTimeRef.current || !firstScroll.current) return;
    currentTimeRef.current.scrollIntoView({
      block: 'center',
    });
    firstScroll.current = false;
  }, [timeCalc]);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length !== 2) return;
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];

    // Calculate distance between the two fingers
    initialDistance.current = Math.sqrt(
      Math.pow(touch1.pageX - touch2.pageX, 2) + Math.pow(touch1.pageY - touch2.pageY, 2)
    );
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length !== 2) return;
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];

    // Calculate distance between the two fingers
    const currentDistance = Math.sqrt(
      Math.pow(touch1.pageX - touch2.pageX, 2) + Math.pow(touch1.pageY - touch2.pageY, 2)
    );

    const changedDistance = (currentDistance - initialDistance.current) * SCALE_FACTOR;

    setZoomLevel((prev) => {
      const newZoom = prev + changedDistance;
      if (newZoom < MIN_ZOOM_LEVEL) return MIN_ZOOM_LEVEL;
      if (newZoom > MAX_ZOOM_LEVEL) return MAX_ZOOM_LEVEL;
      return newZoom;
    });
  };

  const handleTouchEnd = () => {
    initialDistance.current = 0;
  };

  const currentPos = timeCalc?.convertTimestamp(currentTime);

  return (
    <>
      <ZoomLevelView
        isZoomVisible={isZoomVisible}
        zoomLevel={zoomLevel}
        MAX_ZOOM_LEVEL={MAX_ZOOM_LEVEL}
        MIN_ZOOM_LEVEL={MIN_ZOOM_LEVEL}
      />
      <div
        ref={containerRef}
        className={styles['grid-container']}
        style={{
          gridTemplateColumns: `minmax(${MENU_WIDTH}px, ${MENU_WIDTH}px) 1fr`,
        }}
      >
        <div className={styles['empty']} />

        <div
          className={styles['or-header']}
          style={{ gridAutoColumns: `minmax(${MIN_WIDTH_CARD}px, 1fr)` }}
        >
          {ors.map((or, index) => (
            <OpConfigView
              key={or.name}
              or={or}
              openConfig={openConfig}
              firstItem={index === 0}
              lastItem={ors.length - 1 === index}
            />
          ))}
        </div>

        <div
          ref={menuContentRef}
          className={styles['menu-content']}
          style={{ height: `${zoomLevel}%` }}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className={styles['menu']}>
            {timeCalc &&
              timeCalc.hours.map((t) => (
                <div
                  key={t}
                  className={styles['time']}
                  style={{
                    top: timeCalc.convertTimestamp(t),
                  }}
                >
                  {formatTime(new Date(t).getHours(), 0)}
                </div>
              ))}

            {timeCalc &&
              timeCalc.hours.map((t) => (
                <div
                  key={t}
                  className={styles['time-line']}
                  style={{
                    top: timeCalc.convertTimestamp(t),
                    zIndex: ZIndex.DashboardProcedureContent,
                  }}
                />
              ))}

            {timeCalc && (
              <CurrentTimer
                currentTime={currentTime}
                selectedDate={selectedDate}
                interval={interval}
                currentPos={timeCalc.convertTimestamp(currentTime)}
                timeCalc={timeCalc}
                contentHeight={contentHeight}
                scrollContainerRef={menuContentRef}
                dragging={dragging}
                setDragging={setDragging}
                tablet={true}
              />
            )}

            {timeCalc && target && (
              <TargetIcon
                className={styles['target-icon']}
                style={{
                  top: timeCalc.convertTimestamp(targetDate.getTime()),
                  zIndex: ZIndex.DashboardTargetIcon,
                }}
              />
            )}
          </div>
          <div ref={contentRef} className={styles['content']}>
            {timeCalc &&
              timeCalc.hours.map((t) => (
                <div
                  key={t}
                  className={styles['time-line-soft']}
                  style={{ top: timeCalc.convertTimestamp(t) }}
                />
              ))}
            {timeCalc && target && (
              <div
                className={styles['target-time-line']}
                style={{ top: timeCalc.convertTimestamp(targetDate.getTime()) }}
              />
            )}

            {timeCalc &&
              isToday({
                day: selectedDate.day,
                month: selectedDate.month,
                year: selectedDate.year,
                currentTime,
                interval,
              }) && (
                <>
                  <div
                    className={styles['current-time-line']}
                    style={{ top: currentPos, zIndex: ZIndex.DashboardCurrentTimeLine }}
                  >
                    <div className={styles['current-time-line-fill']} />
                    <div className={styles['current-time-line-center']} />
                    <div className={styles['current-time-line-fill']} />
                  </div>

                  <div className={styles['current-time-indicator']} style={{ top: currentPos }} />
                </>
              )}

            <div
              className={styles['or-items-outer']}
              style={{
                gridAutoColumns: `minmax(${MIN_WIDTH_CARD}px, 1fr)`,
              }}
            >
              {ors.map((or) => (
                <div key={or.name} className={styles['or-items']}>
                  {timeCalc &&
                    or.items.map((item, index) => (
                      <ProcedureWrapper
                        key={item.component.id}
                        autoScroll={autoScroll}
                        motionLayout={motionLayout}
                        timeCalcStart={timeCalc.start}
                        timeCalcEnd={timeCalc.end}
                        showChallenges={showChallenges}
                        item={item}
                        contentHeight={contentHeight}
                        zoomLevel={zoomLevel}
                        nextExtension={getNextExtension(or.items, index)}
                        cardMinWidth={MIN_WIDTH_CARD}
                      />
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {preliminaryProcedures && (
        <PreliminaryProceduresView data={preliminaryProcedures} autoScroll={autoScroll} />
      )}
    </>
  );
}

export default ORWorkflowOverviewDashboardTablet;

const getNextExtension = (
  arr: ORWorkflowOverviewDashboardORItemData[],
  currentIndex: number
): undefined | number => {
  const currentItem = arr[currentIndex];
  const nextItem = arr[currentIndex + 1];
  if (!nextItem) return undefined;
  return nextItem.extension;
};
