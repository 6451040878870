import { Bytes, ClockTimestamp } from '@sqior/js/data';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { UID } from '@sqior/js/uid';

export const MediaPath = 'media';

/* Specification of the media upload operation */
export type MediaInfo = { timestamp: ClockTimestamp; type: string; filename?: string; data: Bytes };
export function UploadMedia(
  timestamp: number,
  type: string,
  binaryData: Bytes,
  filename?: string
): OperationSpec<MediaInfo> {
  const operationData: MediaInfo = {
    timestamp: timestamp,
    type: type,
    data: binaryData,
  };
  if (filename) operationData.filename = filename;
  return {
    type: OperationType.Add,
    path: MediaPath,
    data: operationData,
  };
}
export function DeleteMedia(id: UID): OperationSpec<UID> {
  return { type: OperationType.Delete, path: MediaPath, data: id };
}

/* Specification of the media integration operation */
export type MediaIntegrationInfo = { id: UID; title: string; width: number; height: number };
export function IntegrateMedia(
  id: UID,
  title: string,
  width: number,
  height: number
): OperationSpec<MediaIntegrationInfo> {
  return {
    type: OperationType.Add,
    path: 'integrate-media',
    data: { id: id, title: title, width: width, height: height },
  };
}

/** Loads media */
export function LoadMedia(id: string): OperationSpec<string> {
  return { type: OperationType.Read, path: MediaPath, data: id };
}
