import { joinPath } from '@sqior/js/url';
import {
  InformProjectionVM,
  ReadConfirmationTypes,
  hasOpenResponseOptions,
  isEscalation,
  isFlaggedActive,
  isImportant,
  isInformation,
  isMyReadConfirmationTypeAtLeast,
  isTask,
} from './inform-projection';
import { CollpasedStandardInfoItemVM, CommunicationVMTypes } from './communication-vm';

export const MainInfoPath = 'main-info';
export const MainInfoItemsPath = joinPath(MainInfoPath, 'items');

export function mainInfoContains(itemIn: InformProjectionVM | CollpasedStandardInfoItemVM) {
  if (
    itemIn.entityType === CommunicationVMTypes.MainInfoItemVMType ||
    itemIn.entityType === CommunicationVMTypes.InformItemVMType
  ) {
    const item = itemIn as InformProjectionVM;
    return (
      ((isTask(item) && hasOpenResponseOptions(item)) ||
        ((isEscalation(item) || (isInformation(item) && isImportant(item))) &&
          item.sentByMyself === false &&
          !isMyReadConfirmationTypeAtLeast(item, ReadConfirmationTypes.Explicit))) &&
      isFlaggedActive(item)
    );
  }
  return true;
}
