import { Entity, EntityHeader } from '@sqior/js/entity';
import { Interface } from '@sqior/js/meta';
import { LanguageInterfaces } from '@sqior/plugins/language';

export enum LocationEntities {
  RoomId = 'RoomId',
  RoomFunction = 'RoomFunction',
  RoomNumber = 'RoomNumber',
  ClusterId = 'LocationCluster',
  Locations = 'Locations',
  LocationAndFunction = 'LocationAndFunction',
  BedId = 'BedId',
  BedBlock = 'BedBlockCause',
  UnspecificBlock = 'UnspecificBlock',
  UnspecificBlocks = 'UnspecificBlocks',
  BedState = 'BedState',
  ClusterFunction = 'LocationClusterFunction',
  BedBlockStrength = 'BedBlockStrength',
  Clusters = 'LocationClusters', // List of location clusters
  TestLocation = 'TestLocation', // Test location of a certain function
  TransportStatus = 'TransportStatus', // Status of a (patient, material etc.) transport action
  TransferPriorityGroup = 'TransferPriorityGroup',
  IndividualTimeTransferPriorityGroup = 'IndividualTransferPriorityGroup',
  TransferPriorityGroupVM = 'TransferPriorityGroupVM',
  DailyStartConfigItem = 'DailyStartConfigItem',
  DailyStartConfig = 'DailyStartConfig',
  LocationDailyStartConfig = 'LocationDailyStartConfigEntity',
  LocationDate = 'LocationDate', // Pair of location and date
  Movements = 'Movements', // Array of movements
  MovementAdmission = 'MovementAdmission', // Movement representing the admission to a location
  MovementTransfer = 'MovementTransfer', // Movement representing the transfer from one to another location
  MovementDischarge = 'MovementDischarge', // Movement representing the discharge from a location
  MovementVisit = 'MovementVisit', // Movement representing a visit at a department or function without changing the admission status
}
export enum LocationInterfaces {
  Key = 'LocationKey',
  LocationOrFunction = 'LocationOrFunction',
  ContainingClusters = 'ContainingClusters',
  ClusterKey = 'LocationClusterKey',
  LocationOrCluster = 'LocationOrCluster',
  BedKey = 'BedKey',
  OtherBeds = 'OtherBeds',
  BedsRoom = 'BedsRoom',
  BedsWard = 'BedsWard',
  BlockedBeds = 'BlockedBeds',
  BedBlock = 'IFBedBlock',
  Obvious = 'ObviousLocation', // Location which does not need to be explicitly named because it is so obvious
  DefaultDailyStartConfig = 'DefaultDailyStartConfig',
  DailyStartConfigOptionDescription = 'DailyStartConfigOptionDescription',
  TransferPriorityGroupIF = 'TransferPriorityGroupIF',
  DailyStartInductionLocation = 'DailyStartInductionLocation',
  DailyStartTransferLocation = 'DailyStartTransferLocation',
  DailyStartStartTimestamp = 'DailyStartStartTimestamp',
  DailyStartEndTimestamp = 'DailyStartEndTimestamp',
  DailyStartLastCallTimestamp = 'DailyStartLastCallTimestamp',
  CurrentRoom = 'CurrentRoom', // Current room of a person or device
  DashboardName = 'IFDashboardName', // The dashboard name if set, otherwise the short name or the name of a location
  Locations = 'GetLocations', // Returns the locations
  ClusterFunction = 'GetLocationClusterFunction', // Returns the function of a cluster
}

/* Locations */

export type LocationsEntity = EntityHeader & { locations: Entity[] };

export const LocationsInterface: Interface = { type: LocationInterfaces.Locations };

/** Returns the location cluster function */

export const GetLocationClusterFunctionModel: Interface = {
  type: LocationInterfaces.ClusterFunction,
  requires: LocationEntities.ClusterFunction,
};

/* Obvious location interface */

export const ObviousLocationModel: Interface = { type: LocationInterfaces.Obvious };

export const DashboardNameModel: Interface = {
  type: LocationInterfaces.DashboardName,
  requires: LanguageInterfaces.Anonymized,
};
