import { EntityHeader } from '@sqior/js/entity';
import { EntityModel, EntityRecord } from '@sqior/js/meta';
import { VisualEntities } from './visual-definitions';
import { URLEntity } from '@sqior/viewmodels/visual';

/** Entity containing an URL */

export const URLEntityModel: EntityModel = { type: VisualEntities.URL, props: ['url'] };
export function makeURLEntity(url: string, type: string = URLEntityModel.type): URLEntity {
  return { entityType: type, url: url };
}

/** Entity representing an URL template with parameters */

export type URLTemplate = EntityHeader & { template: string; params: EntityRecord };
export const URLTemplateModel: EntityModel = {
  type: VisualEntities.URLTemplate,
  props: ['template', 'params'],
  unclassified: true,
};
export function makeURLTemplate(template: string, params: EntityRecord): URLTemplate {
  return { entityType: URLTemplateModel.type, template, params };
}
