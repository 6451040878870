export enum TemporaryTextType {
  Fixed = 'fixed',
  Suggestion = 'suggestion',
  Selection = 'selection',
  Pending = 'pending',
  Example = 'example',
}
export type TemporaryTextOption = { option: string; confidence: number; moreToCome?: boolean };
export type TemporaryText = {
  type: TemporaryTextType;
  text: string | TemporaryTextOption[];
  match: string;
};
