import {
  ArraySource,
  ClockTimestamp,
  clone,
  ensureArray,
  isEqual,
  lessThan,
  lessThanPred,
  now,
  Order,
  shallowClone,
  SortedArray,
  ValueObject,
} from '@sqior/js/data';
import {
  CollectionCache,
  DatabaseIndexSpec,
  DatabaseInterface,
  PrivateCollectionCache,
} from '@sqior/js/db';
import { Entity } from '@sqior/js/entity';
import { Logger, PerformanceMetric, PerformanceUnit } from '@sqior/js/log';
import { Message } from '@sqior/js/message';
import { CoreEntities } from './core-definitions';
import { Domain } from './domain';
import { DomainInterface } from './domain-interface';
import { EntityModel, EntityRecord } from './entity';
import { createInvokeEntity } from './event';
import { Undefined } from './function';
import { Interface } from './interface';
import { ProjectionDispatcher } from './projection-dispatcher';
import { ProjectionInterface } from './projection-view';
import { AnchorModel, RelateDataNotification, RelatedDataModel } from './related-data-model';
import { ContextPropertyModel } from './context-property';
import { EventKey, Sequenced, SequencedEvent, SequencedObject } from '@sqior/js/event-stream';
import { RelatedDataLibraryItem, RelatedDataRegistry } from './related-data-registry';
import { arrayTransformAsync } from '@sqior/js/async';

type UndoMessage = Message & { invocation: string; data: string[] } & ValueObject;
type UntypedDataObject = SequencedObject;
type HistoryEntry = { data: Entity; eventId: string; notification: string };
type DataObjectItem = { history: HistoryEntry[]; key?: string | string[] };
type UpdateDataObject = Sequenced & {
  data: Record<string, DataObjectItem>;
  writeTimestamp: ClockTimestamp;
  cleanupUsedUntil?: ClockTimestamp; // Timestamp used for cleanup, the related data item is at least used until the specified time (next cleanup check time)
  lastNotification?: string | { origNotification: string; entity: Entity };
};
type NewDataObject = Entity & { anchor: Entity; key: string } & UpdateDataObject;
type DataObject = NewDataObject;
export type RelatedDataObject = DataObject;

export const RelatedDataDocumentModel: EntityModel = {
  type: 'RelatedDataDocument',
  props: ['sequenceNumber', 'anchor', 'key', 'data', 'writeTimestamp'],
};

function makeKeyName(name: string) {
  return name + 'Key';
}

/** Defines the relation of the related data registry to the individualized stored data */
export enum RelatedDataRegistryMode {
  Exclusive, // Data is exclusively provided by the registry, pot. existing stored data is not accessible anymore
  Primary, // Registry data is provided if defined, stored data is used as back-up
  Base, // Registry data is provided, if no data is stored
}

/** Related data domain base class providing access to hierarchically structured data that has a common key (=anchor) element */

export class RelatedDataDomain extends Domain {
  constructor(
    anchor: AnchorModel,
    options: {
      entities?: ArraySource<EntityModel>;
      interfaces?: ArraySource<Interface>;
      contextProperties?: ArraySource<ContextPropertyModel>;
      relatedData?: ArraySource<RelatedDataModel>;
      cleanUp?: boolean;
      cleanUpTimestamp?: { absoluteTime?: ClockTimestamp; relativeTime?: ClockTimestamp };
    } = {}
  ) {
    super(anchor.name, {
      entities: ensureArray(options.entities).concat([
        anchor.containerModel,
        anchor.setDataModel,
        anchor.insertDataModel,
        anchor.replaceDataModel,
        anchor.addDataModel,
        anchor.removeDataModel,
        anchor.dataUpdateModel,
        anchor.deleteCommandModel,
        anchor.deleteNotificationModel,
        anchor.deleteAllCommandModel,
      ]),
      interfaces: options.interfaces,
      contextProperties: options.contextProperties,
      dbType: RelatedDataDocumentModel.type,
      migrateUntyped: async (obj) => {
        return RelatedDataDomain.convertUntyped(obj as DataObject, anchor);
      },
    });
    this.anchor = anchor;
    this.doCleanUp = options.cleanUp || false;
    this.cleanUpTimestamp = options.cleanUpTimestamp;
    /* Automatically register base types, if base name coincides with name */
    if (anchor.prop === anchor.name.toLowerCase()) {
      /* Autmatically add a key interface */
      this.addInterface({ type: anchor.type });
      /* Automatically add a mapping that creates an array of keys from a key instance */
      this.addEntityMapping(anchor.type, anchor.containerModel.type, async (loc) => {
        return anchor.makeContainer([loc]);
      });
    }
    /* Create the dispatcher for projection messages */
    this.projectionDispatcher = new ProjectionDispatcher();
    this.projectionDispatcher.addTo(this);
    this.relatedDatas = new Map<string, RelatedDataModel>();
    /* Define a command and related notification for setting related data */
    this.addInvokeHandler({ entityType: this.anchor.setDataModel.type }, async (entity, mapper) => {
      return this.setDataEventHandler(entity, mapper);
    });
    this.addUndoHandler(
      { entityType: this.anchor.setDataModel.type },
      async (entity, invocation, mapper) => {
        return this.undoSetDataEventHandler(entity, invocation, mapper);
      }
    );
    this.addInvokeHandler(
      { entityType: this.anchor.insertDataModel.type },
      async (entity, mapper) => {
        return this.insertDataEventHandler(entity, mapper);
      }
    );
    this.addUndoHandler(
      { entityType: this.anchor.insertDataModel.type },
      async (entity, invocation, mapper) => {
        return this.undoSetDataEventHandler(
          entity,
          invocation,
          mapper,
          this.anchor.insertDataModel.type
        );
      }
    );
    this.addInvokeHandler(
      { entityType: this.anchor.replaceDataModel.type },
      async (entity, mapper) => {
        return this.replaceDataEventHandler(entity, mapper);
      }
    );
    this.addUndoHandler(
      { entityType: this.anchor.replaceDataModel.type },
      async (entity, invocation, mapper) => {
        return this.undoSetDataEventHandler(
          entity,
          invocation,
          mapper,
          this.anchor.replaceDataModel.type
        );
      }
    );
    this.addInvokeHandler({ entityType: this.anchor.addDataModel.type }, async (entity, mapper) => {
      return this.addDataEventHandler(entity, mapper);
    });
    this.addUndoHandler(
      { entityType: this.anchor.addDataModel.type },
      async (entity, invocation, mapper) => {
        return this.undoSetDataEventHandler(entity, invocation, mapper);
      }
    );
    this.addInvokeHandler(
      { entityType: this.anchor.removeDataModel.type },
      async (entity, mapper) => {
        return this.removeDataEventHandler(entity, mapper);
      }
    );
    this.addUndoHandler(
      { entityType: this.anchor.removeDataModel.type },
      async (entity, invocation, mapper) => {
        return this.undoSetDataEventHandler(entity, invocation, mapper);
      }
    );
    /* Register the specific msg handler at the projection dispatcher */
    this.projectionDispatcher.addHandler(this.anchor.setDataModel.type, async (db, msg, mapper) => {
      return this.handleMessage(db, msg, async (key, eventKey, prev) => {
        return this.setDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
      });
    });
    this.projectionDispatcher.addHandler(
      this.anchor.insertDataModel.type,
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.insertDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
        });
      }
    );
    this.projectionDispatcher.addHandler(
      this.anchor.replaceDataModel.type,
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.replaceDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
        });
      }
    );
    this.projectionDispatcher.addHandler<SequencedEvent & UndoMessage>(
      this.anchor.setDataModel.type + 'Undo',
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.undoSetDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
        });
      }
    );
    this.projectionDispatcher.addHandler<SequencedEvent & UndoMessage>(
      this.anchor.insertDataModel.type + 'Undo',
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.undoSetDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
        });
      }
    );
    this.projectionDispatcher.addHandler<SequencedEvent & UndoMessage>(
      this.anchor.replaceDataModel.type + 'Undo',
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.undoSetDataProjectionHandler(db, mapper, msg, key, eventKey, prev, true);
        });
      }
    );
    this.projectionDispatcher.addHandler(this.anchor.addDataModel.type, async (db, msg, mapper) => {
      return this.handleMessage(db, msg, async (key, eventKey, prev) => {
        return this.addDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
      });
    });
    this.projectionDispatcher.addHandler(
      this.anchor.removeDataModel.type,
      async (db, msg, mapper) => {
        return this.handleMessage(db, msg, async (key, eventKey, prev) => {
          return this.removeDataProjectionHandler(db, mapper, msg, key, eventKey, prev);
        });
      }
    );
    /* Define a command and related notification for deleting an instance of the data specified by its key */
    this.addInvokeHandler(
      { entityType: this.anchor.deleteCommandModel.type },
      async (entity, mapper) => {
        const msg: Message & ValueObject & EventKey = { type: this.anchor.deleteCommandModel.type };
        const obj = await mapper.map(entity[this.anchor.prop] as Entity, this.anchor.type);
        msg[this.anchor.prop] = obj;
        msg[this.anchor.prop + 'Key'] = msg.eventKey = mapper.makeKey(obj);
        return msg;
      }
    );
    /* Define a command for deleting all instances of this data */
    this.addInvokeHandler({ entityType: this.anchor.deleteAllCommandModel.type }, async () => {
      return { type: this.anchor.deleteAllCommandModel.type };
    });
    /* Register the provided related data */
    for (const rd of ensureArray(options.relatedData)) this.addRelatedDataModel(rd);
  }

  override addRelatedDataModel(model: RelatedDataModel): void {
    super.addRelatedDataModel(model);
    /* Remember */
    this.relatedDatas.set(model.prop, model);
    /* Ensure that all related data models agree on the same key name */
    if (model.anchor !== this.anchor)
      throw new Error(
        'All related data objects of the same domain need to have the same anchor: ' +
          this.anchor.name +
          ' - provided: ' +
          model.anchor.name
      );
    /* Add the mapping that extracts the related data for a specific anchor value */
    this.addEntityMapping<Entity, Entity>(
      model.anchor.type,
      model.interface.type,
      async (entity, mapper) => {
        if (!this.collCache)
          throw new Error(
            'Cannot map entity of type: ' +
              entity.entityType +
              ' because the cache is not initialized, yet'
          );
        /* Get the data from the database */
        const obj = await this.collCache.getValue(
          mapper.makeKey(entity),
          'data.' + model.prop + '.history.0.data',
          mapper
        );
        let res = obj as Entity;
        if (!obj || !res.entityType) return undefined;
        /* Check if this is a multi-valued data element - detect and filter potential container in container scenarios which is
         a bug that has been observed, but the root cause is still unclear */
        if (model.multiValueEntityProp) {
          const prop = res[model.multiValueEntityProp] as Entity[];
          if (
            prop.find((item) => {
              return item.entityType === model.type;
            })
          ) {
            /* Log */
            Logger.reportError([
              'Folded multi-valued related data detected - anchor:',
              model.anchor.name,
              ' - element:',
              model.prop,
              ' - id:',
              entity,
            ]);
            /* Filter out the illegal elements */
            res = shallowClone(res);
            res[model.multiValueEntityProp] = prop.filter((item) => {
              return item.entityType !== model.type;
            });
          }
        }
        return res.entityType !== CoreEntities.Undefined ? res : undefined;
      },
      { weight: 2 }
    );
    /* Add the mapping that returns all anchors featuring a specific indexed related data value */
    if (model.type && model.anchorsInterface && !model.multiValueEntityProp)
      this.addEntityMapping<Entity, Entity>(
        model.type,
        model.anchorsInterface.type,
        async (entity, mapper) => {
          if (!this.collCache)
            throw new Error(
              'Cannot map entity of type: ' +
                entity.entityType +
                ' because the cache is not initialized, yet'
            );
          const res = model.anchor.makeContainer(
            await this.collCache.getKeys(
              'data.' + model.prop + '.key',
              mapper.makeKey(entity),
              mapper
            )
          );
          return res;
        },
        { weight: 2 }
      );
    else if (model.multiValueEntityType && model.anchorsInterface)
      this.addEntityMapping<Entity, Entity>(
        model.multiValueEntityType,
        model.anchorsInterface.type,
        async (entity, mapper) => {
          if (!this.collCache)
            throw new Error(
              'Cannot map entity of type: ' +
                entity.entityType +
                ' because the cache is not initialized, yet'
            );
          const res = model.anchor.makeContainer(
            await this.collCache.getKeys(
              'data.' + model.prop + '.key',
              mapper.makeKey(entity),
              mapper
            )
          );
          return res;
        },
        { weight: 2 }
      );
  }

  /** Sets the related data items that are provided by the registry */
  setRelatedDataRegistryModels(
    models: RelatedDataModel[],
    mode = RelatedDataRegistryMode.Exclusive
  ) {
    this.registryModels = models;
    this.registryMode = mode;
  }

  /** Checks if the anchors do actually map to a specified entity */
  private checkAnchors(mapper: DomainInterface, anchors: Entity[], type: string, ref: Entity) {
    const refKey = mapper.makeKey(ref);
    return arrayTransformAsync(anchors, async (anchor) => {
      const res = await mapper.tryMap(anchor, type);
      return res && mapper.makeKey(res) === refKey ? anchor : undefined;
    });
  }
  /** Checks if the anchors do actually map to a specified entity for a mulit-valued property */
  private checkMultiAnchors(
    mapper: DomainInterface,
    anchors: Entity[],
    type: string,
    prop: string,
    ref: Entity
  ) {
    const refKey = mapper.makeKey(ref);
    return arrayTransformAsync(anchors, async (anchor) => {
      const res = await mapper.tryMap(anchor, type);
      const mv = res ? (res[prop] as Entity[]) : [];
      for (const v of mv) if (mapper.makeKey(v) === refKey) return anchor;
      return undefined;
    });
  }

  /** Creates the related data registry and registers the appropriate mappings */
  private createRegistry(mapper: DomainInterface): RelatedDataRegistry {
    const registry = new RelatedDataRegistry(mapper, this.registryModels);
    for (const model of this.registryModels) {
      /* Define the mapping retrieving the value, concrete implementation depends on the mode */
      if (this.registryMode === RelatedDataRegistryMode.Exclusive)
        this.addSyncMapping(model.anchor.type, model.interface.type, (entity, mapper) => {
          return registry.get(entity, model.prop, mapper);
        });
      else if (this.registryMode === RelatedDataRegistryMode.Primary)
        this.addEntityMapping(model.anchor.type, model.interface.type, (entity, mapper) => {
          const res = registry.get(entity, model.prop, mapper);
          return res ? res : mapper.trySuperseded(entity);
        });
      else
        this.addEntityMapping(model.anchor.type, model.interface.type, async (entity, mapper) => {
          const res = await mapper.trySuperseded(entity);
          return res ? res : registry.get(entity, model.prop, mapper);
        });
      /* Define the mapping retrieving the anchors referencing value, concrete implementation depends on the mode */
      if (model.type && model.anchorsInterface)
        if (this.registryMode === RelatedDataRegistryMode.Exclusive)
          this.addSyncMapping(
            model.multiValueEntityType ?? model.type,
            model.anchorsType,
            (entity, mapper) => {
              const res = registry.anchors(model.prop, entity, mapper);
              return res ? model.anchor.makeContainer(res) : undefined;
            }
          );
        else if (this.registryMode === RelatedDataRegistryMode.Primary) {
          const multiProp = model.multiValueEntityProp;
          if (model.multiValueEntityType && multiProp)
            this.addEntityMapping(
              model.multiValueEntityType,
              model.anchorsType,
              async (entity, mapper) => {
                /* Get both the registry as well as the store result */
                const res = registry.anchors(model.prop, entity, mapper);
                const storeRes = await mapper.trySuperseded(entity);
                /* Directly return registry result if nothing found in store */
                const storeAnchors = storeRes
                  ? (storeRes[model.anchor.containerModel.props[0]] as Entity[])
                  : undefined;
                if (!storeAnchors || !storeAnchors.length)
                  return res ? model.anchor.makeContainer(res) : undefined;
                /* This checks if the anchors provided by the secondary source actually map to the desired value,
               note that this is likely not efficient if there are multiple hits provided from the secondary source */
                return model.anchor.makeContainer(
                  mapper.eliminateDuplicates([
                    ...(res ?? []),
                    ...(await this.checkMultiAnchors(
                      mapper,
                      storeAnchors,
                      model.interface.type,
                      multiProp,
                      entity
                    )),
                  ])
                );
              }
            );
          else
            this.addEntityMapping(model.type, model.anchorsType, async (entity, mapper) => {
              /* Get both the registry as well as the store result */
              const res = registry.anchors(model.prop, entity, mapper);
              const storeRes = await mapper.trySuperseded(entity);
              /* Directly return registry result if nothing found in store */
              const storeAnchors = storeRes
                ? (storeRes[model.anchor.containerModel.props[0]] as Entity[])
                : undefined;
              if (!storeAnchors || !storeAnchors.length)
                return res ? model.anchor.makeContainer(res) : undefined;
              /* This checks if the anchors provided by the secondary source actually map to the desired value,
                 note that this is likely not efficient if there are multiple hits provided from the secondary source */
              return model.anchor.makeContainer(
                mapper.eliminateDuplicates([
                  ...(res ?? []),
                  ...(await this.checkAnchors(mapper, storeAnchors, model.interface.type, entity)),
                ])
              );
            });
        } else {
          const multiProp = model.multiValueEntityProp;
          if (model.multiValueEntityType && multiProp)
            this.addEntityMapping(
              model.multiValueEntityType,
              model.anchorsType,
              async (entity, mapper) => {
                /* Get both the registry as well as the store result */
                const storeRes = await mapper.trySuperseded(entity);
                const res = registry.anchors(model.prop, entity, mapper);
                /* Directly return store result if nothing found in registry */
                if (!res) return storeRes;
                /* This checks if the anchors provided by the secondary source actually map to the desired value,
                 note that this is likely not efficient if there are multiple hits provided from the secondary source */
                const storeAnchors = storeRes
                  ? (storeRes[model.anchor.containerModel.props[0]] as Entity[])
                  : undefined;
                return model.anchor.makeContainer(
                  mapper.eliminateDuplicates([
                    ...(storeAnchors ?? []),
                    ...(await this.checkMultiAnchors(
                      mapper,
                      res,
                      model.interface.type,
                      multiProp,
                      entity
                    )),
                  ])
                );
              }
            );
          else
            this.addEntityMapping(model.type, model.anchorsType, async (entity, mapper) => {
              /* Get both the registry as well as the store result */
              const storeRes = await mapper.trySuperseded(entity);
              const res = registry.anchors(model.prop, entity, mapper);
              /* Directly return store result if nothing found in registry */
              if (!res) return storeRes;
              /* This checks if the anchors provided by the secondary source actually map to the desired value,
                 note that this is likely not efficient if there are multiple hits provided from the secondary source */
              const storeAnchors = storeRes
                ? (storeRes[model.anchor.containerModel.props[0]] as Entity[])
                : undefined;
              return model.anchor.makeContainer(
                mapper.eliminateDuplicates([
                  ...(storeAnchors ?? []),
                  ...(await this.checkAnchors(mapper, res, model.interface.type, entity)),
                ])
              );
            });
        }
    }
    return registry;
  }

  /** Sets related library data */
  setLibrary(items: RelatedDataLibraryItem[]) {
    this.registry?.set(items);
  }

  override init(db: DatabaseInterface, mapper: Domain): void {
    /* Create collection cache */
    this.collCache = new CollectionCache(db, this.name, 'anchor', 'key');
    /* Create private collection cache */
    const cache = (this.privCache = new PrivateCollectionCache(db, this.name, 'key'));
    /* Creates the related data registry, if applicable */
    if (this.registryModels.length) this.registry = this.createRegistry(mapper);
    /* Register the handler for the delete command */
    this.projectionDispatcher.addHandler(this.anchor.deleteCommandModel.type, async (db, msg) => {
      /* Delete the object */
      const deleted = await db.deleteOne({ key: msg[this.anchor.prop + 'Key'] });
      /* Emit notification, if applicable */
      if (deleted)
        await db.invoke(
          this.anchor.deleteNotification(msg[this.anchor.prop] as Entity),
          'deleteNotification'
        );
      cache.delete(msg[this.anchor.prop + 'Key'] as string);
    });
    /* Register the handler for the delete all command */
    this.projectionDispatcher.addHandler(
      this.anchor.deleteAllCommandModel.type,
      async (db, ev, mapper) => {
        /* Find all objects (required for notifications) */
        const objs: Entity[] = [];
        for await (const findRes of await db.find<{ anchor: Entity }>({}, { include: ['anchor'] }))
          if (findRes) objs.push(findRes.obj.anchor);
        /* Delete all objects */
        await db.delete({});
        /* Emit notifications */
        for (const obj of objs)
          await db.invoke(
            this.anchor.deleteNotification(obj),
            'deleteNotification' + mapper.makeKey(obj)
          );
        cache.clear();
      }
    );
    /* Call base class to activate */
    super.init(db, mapper);
  }

  /** Called to give the domain the opportunity to define the database indexes */
  override async ensureIndexes(db: DatabaseInterface) {
    /* Ensure index on the key property and all data keys if the model specifies an index */
    const indexes: DatabaseIndexSpec[] = [
      { index: { key: Order.Ascending }, unique: true },
      'writeTimestamp',
    ];
    for (const rd of this.relatedDatas)
      if (rd[1].index) indexes.push('data.' + rd[1].prop + '.key');
    await db.ensureIndexes(this.name, indexes);
  }

  /** Calculates the cleanup timestamp to use depending on configuration of domain */
  protected calcCleanUpTimestamp(timestamp: ClockTimestamp) {
    if (this.cleanUpTimestamp?.absoluteTime !== undefined)
      return this.cleanUpTimestamp.absoluteTime;
    if (this.cleanUpTimestamp?.relativeTime !== undefined) {
      if (this.cleanUpTimestamp?.relativeTime > 0)
        Logger.warn([
          'Specified relativeTime for domain',
          this.name,
          ' is not negative - this is a uncommon use case',
        ]);
      return this.steadyTimer.now + this.cleanUpTimestamp?.relativeTime;
    }
    return timestamp;
  }

  override async cleanUp(db: DatabaseInterface, timestamp: ClockTimestamp, sequenceNumber: number) {
    sequenceNumber;
    /* Cleaning up entries if configured */
    if (this.doCleanUp) {
      const cleanUpTimestamp = this.calcCleanUpTimestamp(timestamp);
      Logger.debug([
        'Cleaning up related data domain:',
        this.name,
        'keeping items from:',
        new Date(cleanUpTimestamp).toLocaleDateString(),
      ]);
      await db.delete(this.name, { writeTimestamp: lessThan(cleanUpTimestamp) });
    }
  }

  /** Deactivates the domain */
  override async close() {
    if (this.collCache) await this.collCache.close();
    if (this.privCache) await this.privCache.close();
    super.close();
  }

  private makeDataKey(
    model: RelatedDataModel,
    mapper: DomainInterface,
    data: Entity
  ): string | string[] {
    /* Check if this refers to multi-valued entity property */
    if (model.multiValueEntityProp) {
      const keys: string[] = [];
      if (data.entityType !== CoreEntities.Undefined)
        for (const el of data[model.multiValueEntityProp] as Entity[])
          SortedArray.insert(keys, mapper.makeKey(el), lessThanPred);
      return keys;
    }
    return mapper.makeKey(data);
  }

  private async modifyDataEventHandler(
    entity: Entity,
    mapper: DomainInterface,
    transform: (model: RelatedDataModel, mapper: DomainInterface, entity: Entity) => Promise<Entity>
  ) {
    const msg: Message & ValueObject & EventKey = { type: entity.entityType };
    const keyEntity = await this.getKey(entity, mapper);
    msg[this.anchor.prop] = keyEntity;
    msg[makeKeyName(this.anchor.prop)] = msg.eventKey = mapper.makeKey(keyEntity);
    const data = entity['data'] as EntityRecord;
    if (!data) throw new Error('No data found in set processor of a related data element');
    for (const prop in data) {
      const rd = this.relatedDatas.get(prop);
      if (rd) {
        const dataValue = data[prop];
        try {
          data[prop] = await transform(rd, mapper, dataValue);
        } catch (e) {
          delete data[prop];
          Logger.warn(
            ['Exception when transforming data item:', prop, '- Exception:', Logger.exception(e)],
            [
              'Exception when transforming data item:',
              prop,
              'with value:',
              dataValue,
              '- Exception:',
              Logger.exception(e),
            ]
          );
        }
      } else delete data[prop];
    }
    msg['data'] = data;
    return msg;
  }
  private setDataEventHandler(entity: Entity, mapper: DomainInterface) {
    return this.modifyDataEventHandler(entity, mapper, async (model, mapper, entity) => {
      if (entity.entityType === CoreEntities.Undefined) return entity;
      else if (model.type) return mapper.map(entity, model.type);
      else return mapper.evaluateAll(entity);
    });
  }
  private insertDataEventHandler(entity: Entity, mapper: DomainInterface) {
    return this.modifyDataEventHandler(entity, mapper, async (model, mapper, entity) => {
      if (entity.entityType === CoreEntities.Undefined) return entity;
      else if (model.type) return mapper.map(entity, model.type);
      else return mapper.evaluateAll(entity);
    });
  }
  private replaceDataEventHandler(entity: Entity, mapper: DomainInterface) {
    return this.modifyDataEventHandler(entity, mapper, async (model, mapper, entity) => {
      if (entity.entityType === CoreEntities.Undefined) return entity;
      else if (model.type) return mapper.map(entity, model.type);
      else return mapper.evaluateAll(entity);
    });
  }
  private addDataEventHandler(entity: Entity, mapper: DomainInterface) {
    return this.modifyDataEventHandler(entity, mapper, async (model, mapper, entity) => {
      return mapper.evaluateAll(entity);
    });
  }
  private removeDataEventHandler(entity: Entity, mapper: DomainInterface) {
    return this.modifyDataEventHandler(entity, mapper, async (model, mapper, entity) => {
      return mapper.evaluateAll(entity);
    });
  }

  private async undoSetDataEventHandler(
    entity: Entity,
    invocation: string,
    mapper: DomainInterface,
    type?: string
  ) {
    const msg: UndoMessage & EventKey = {
      type: (type ? type : this.anchor.setDataModel.type) + 'Undo',
      invocation: invocation,
      data: [],
    };
    const keyEntity = await this.getKey(entity, mapper);
    msg[this.anchor.prop] = keyEntity;
    msg[makeKeyName(this.anchor.prop)] = msg.eventKey = mapper.makeKey(keyEntity);
    /* Extract the data names that are supposed to be undone */
    const data = entity['data'] as EntityRecord;
    if (!data)
      throw new Error('No data found in undo processor of a related data element of: ' + this.name);
    for (const prop in data) msg.data.push(prop);
    return msg;
  }

  private async modifyDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined,
    transform: (
      data: Entity,
      old: Entity | undefined,
      model: RelatedDataModel
    ) => Entity | undefined,
    resetOthers = false
  ): Promise<DataObject | undefined> {
    /* Transform the object */
    const data = msg['data'] as EntityRecord;
    const old: EntityRecord = {},
      oldNotify: EntityRecord = {},
      newNotify: EntityRecord = {};
    let hasOld = false,
      hasNew = false;
    /* Reset all other properties if specified */
    if (resetOthers && prev)
      for (const prop of this.relatedDatas.keys())
        if (prev.data[prop] && data[prop] === undefined) {
          const oldHistory = prev.data[prop].history;
          if (oldHistory.length > 0 && oldHistory[0].data.entityType !== CoreEntities.Undefined) {
            /* Check if the value to erase is a multi-valued entity - in this case, empty the array */
            const rdModel = this.relatedDatas.get(prop);
            if (rdModel && rdModel.multiValueEntityProp) {
              data[prop] = shallowClone(oldHistory[0].data);
              data[prop][rdModel.multiValueEntityProp] = [];
            } else data[prop] = Undefined;
          }
        }
    /* Transform the specified properties */
    const keys: Record<string, string | string[]> = {};
    for (const prop in data) {
      const model = this.relatedDatas.get(prop);
      if (!model) throw new Error('Related data model not found for property: ' + prop);
      const oldHistory = prev && prev.data[prop] ? prev.data[prop].history : undefined;
      const oldData = oldHistory && oldHistory.length > 0 ? oldHistory[0].data : undefined;
      const modData = transform(data[prop], oldData, model);
      if (modData) {
        data[prop] = modData;
        if (model.index) keys[prop] = this.makeDataKey(model, mapper, modData);
        if (oldData) {
          old[prop] = oldData;
          if (oldData.entityType !== CoreEntities.Undefined) {
            oldNotify[prop] = oldData;
            hasOld = true;
          }
        }
        if (modData.entityType !== CoreEntities.Undefined) {
          newNotify[prop] = modData;
          hasNew = true;
        }
      } else delete data[prop];
    }
    /* Define the notification event id */
    if (!hasOld && !hasNew && !prev) return undefined;
    const notification = hasOld || hasNew ? db.derivedUID('notification') : undefined;
    const currentTime = this.steadyTimer.now;
    /* Loop all data objects to update */
    const obj: DataObject = prev
      ? shallowClone(prev)
      : {
          entityType: RelatedDataDocumentModel.type,
          sequenceNumber: msg.sequenceNumber,
          anchor: key,
          key: eventKey,
          writeTimestamp: currentTime,
          data: {},
        };
    if (prev) {
      obj.sequenceNumber = msg.sequenceNumber;
      const updateProps: UpdateDataObject = {
        sequenceNumber: msg.sequenceNumber,
        writeTimestamp: currentTime,
        data: {},
      };
      if (notification) {
        updateProps.lastNotification = notification;
        for (const prop in data) {
          const oldHist = prev && prev.data[prop] ? prev.data[prop].history : [];
          const item: DataObjectItem = {
            history: [
              { data: data[prop], eventId: msg.eventId, notification: notification },
              ...oldHist.slice(0, 10),
            ],
          };
          if (keys[prop]) item.key = keys[prop];
          updateProps.data[prop] = obj.data[prop] = item;
        }
      }
      await db.update({ key: eventKey }, updateProps, { data: {} });
    } else if (notification) {
      obj.lastNotification = notification;
      /* If no object is found, initialize it */
      for (const prop in data) {
        const item: DataObjectItem = {
          history: [{ data: data[prop], eventId: msg.eventId, notification: notification }],
        };
        if (keys[prop]) item.key = keys[prop];
        obj.data[prop] = item;
      }
      await db.set({ key: eventKey }, obj);
    }
    /* Send out a notification, if there is a change at all */
    if (hasOld || hasNew) {
      /* Prepare the notification */
      const notify: RelateDataNotification = { entityType: this.anchor.dataUpdateModel.type };
      notify[this.anchor.prop] = key;
      if (hasNew) notify.new = newNotify;
      if (hasOld) notify.old = oldNotify;
      await db.emitDerived([
        createInvokeEntity(notify, mapper.displayTimer.now, undefined, notification),
      ]);
    }
    return obj;
  }

  private async setDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined
  ): Promise<DataObject | undefined> {
    return this.modifyDataProjectionHandler(db, mapper, msg, key, eventKey, prev, (data, old) => {
      if ((old && isEqual(data, old)) || (!old && data.entityType === CoreEntities.Undefined))
        return undefined;
      return data;
    });
  }

  private async insertDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined
  ): Promise<DataObject | undefined> {
    return this.modifyDataProjectionHandler(db, mapper, msg, key, eventKey, prev, (data, old) => {
      if (old) return undefined;
      return data;
    });
  }

  private async replaceDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined
  ): Promise<DataObject | undefined> {
    return this.modifyDataProjectionHandler(
      db,
      mapper,
      msg,
      key,
      eventKey,
      prev,
      (data, old) => {
        if ((old && isEqual(data, old)) || (!old && data.entityType === CoreEntities.Undefined))
          return undefined;
        return data;
      },
      true
    );
  }

  private async addDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined
  ): Promise<DataObject | undefined> {
    return this.modifyDataProjectionHandler(
      db,
      mapper,
      msg,
      key,
      eventKey,
      prev,
      (data, old, model) => {
        /* Check if this actually is a multi-valued type */
        if (!model.type)
          throw new Error(
            'Related data model needs to specify data type for adding data: ' + model.prop
          );
        if (!model.multiValueEntityProp)
          throw new Error(
            'Related data model needs to specify multi-valued entity property name for adding data: ' +
              model.prop
          );
        /* Check if there is existing data */
        if (!old) {
          const res: Entity = { entityType: model.type };
          res[model.multiValueEntityProp] = [data];
          return res;
        }
        /* Check if the value is already contained */
        const oldArray = old[model.multiValueEntityProp] as Entity[];
        for (const d of oldArray) if (isEqual(data, d)) return undefined;
        /* Append */
        const res: Entity = { entityType: model.type };
        res[model.multiValueEntityProp] = oldArray.concat(data);
        return res;
      }
    );
  }

  private async removeDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: SequencedEvent & ValueObject,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined
  ): Promise<DataObject | undefined> {
    return this.modifyDataProjectionHandler(
      db,
      mapper,
      msg,
      key,
      eventKey,
      prev,
      (data, old, model) => {
        /* Check if sufficient information about the entity is provided to do our job */
        if (!model.type)
          throw new Error(
            'Related data model needs to specify data type for removing data: ' + model.prop
          );
        if (!model.multiValueEntityProp)
          throw new Error(
            'Related data model needs to specify multi-valued entity property name for removing data: ' +
              model.prop
          );
        /* Check if there is existing data */
        if (!old) return undefined;
        /* Check if the value is contained at all */
        const oldArray = old[model.multiValueEntityProp] as Entity[];
        for (let i = 0; i < oldArray.length; i++)
          if (isEqual(data, oldArray[i])) {
            const res: Entity = { entityType: model.type };
            const copyArray = clone(oldArray);
            copyArray.splice(i, 1);
            res[model.multiValueEntityProp] = copyArray;
            return res;
          }
        return undefined;
      }
    );
  }

  private async undoSetDataProjectionHandler(
    db: ProjectionInterface,
    mapper: DomainInterface,
    msg: Sequenced & UndoMessage,
    key: Entity,
    eventKey: string,
    prev: DataObject | undefined,
    undoOthers = false
  ): Promise<DataObject | undefined> {
    /* If there is not an existing object, the undo cannot be performed */
    if (!prev)
      throw new Error(
        'Related data cannot be undone as there is no information on it available anymore'
      );
    /* Check if the undo action relates to the latest entry, if not, this cannot be undone */
    const obj = clone(prev);
    obj.sequenceNumber = msg.sequenceNumber;
    const updateProps: UpdateDataObject = {
      sequenceNumber: msg.sequenceNumber,
      writeTimestamp: this.steadyTimer.now,
      data: {},
    };
    let notification: string | undefined;
    const newData: EntityRecord = {},
      oldData: EntityRecord = {};
    let hasOld = false,
      hasNew = false;
    for (const prop of undoOthers ? this.relatedDatas.keys() : msg.data) {
      const model = this.relatedDatas.get(prop);
      if (!model) throw new Error('Related data model not found for property: ' + prop);
      const data = prev.data[prop] ? prev.data[prop].history : undefined;
      if (!data || data.length === 0)
        throw new Error('There is no data history that can be undone');
      /* Check if this data element seemed to have changed */
      const changeIndex = data.findIndex((item) => {
        return item.eventId === msg.invocation;
      });
      if (changeIndex < 0) continue;
      if (changeIndex > 0 || (notification && data[0].notification !== notification))
        throw new Error(
          'The undo action does not relate to the last update (notification mismatch) - no undo can be done'
        );
      else if (!notification) notification = data[0].notification;
      const item: DataObjectItem = { history: data.slice(1) };
      if (data.length > 1) item.key = this.makeDataKey(model, mapper, data[1].data);
      updateProps.data[prop] = obj.data[prop] = item;
      if (data[0].data.entityType !== CoreEntities.Undefined) {
        newData[prop] = data[0].data;
        hasNew = true;
      }
      if (data.length > 1 && data[1].data.entityType !== CoreEntities.Undefined) {
        oldData[prop] = data[1].data;
        hasOld = true;
      }
    }
    if (!notification) return undefined;
    /* Prepare the notification and store it with the update in case it needs to replayed */
    const undoNotify: RelateDataNotification = { entityType: this.anchor.dataUpdateModel.type };
    if (hasNew) undoNotify.new = newData;
    if (hasOld) undoNotify.old = oldData;
    undoNotify[this.anchor.prop] = key;
    updateProps.lastNotification = { origNotification: notification, entity: undoNotify };
    /* Store the changes */
    await db.update({ key: eventKey }, updateProps, { data: {} });
    /* Send out a notification */
    if (undoNotify) await db.undo(undoNotify, notification);
    return obj;
  }

  /** Reconstructs and replays the notification message */
  private async replayNotification(
    db: ProjectionInterface,
    eventId: string,
    anchor: Entity,
    data: Record<string, DataObjectItem>
  ) {
    /* Prepare the notification */
    const notify: RelateDataNotification = { entityType: this.anchor.dataUpdateModel.type };
    notify[this.anchor.prop] = anchor;
    /* Check if this element is associated with the specified notification */
    for (const key in data)
      if (data[key].history.length && data[key].history[0].notification === eventId) {
        /* Check if a value is set */
        if (data[key].history[0].data.entityType !== CoreEntities.Undefined) {
          if (!notify.new) notify.new = {};
          notify.new[key] = data[key].history[0].data;
        }
        /* Check if a value existed before */
        if (
          data[key].history.length > 1 &&
          data[key].history[1].data.entityType !== CoreEntities.Undefined
        ) {
          if (!notify.old) notify.old = {};
          notify.old[key] = data[key].history[1].data;
        }
      }
    return db.emitDerived([createInvokeEntity(notify, this.displayTimer.now, undefined, eventId)]);
  }

  private async handleMessage(
    db: ProjectionInterface,
    msg: SequencedEvent & ValueObject,
    process: (
      key: Entity,
      eventKey: string,
      old: DataObject | undefined
    ) => Promise<DataObject | undefined>
  ) {
    /* Get the object referenced by the key */
    const keyEntity = msg[this.anchor.prop];
    const eventKey = msg[makeKeyName(this.anchor.prop)];
    if (!keyEntity || !eventKey || typeof eventKey !== 'string')
      throw new Error(
        'No key: ' + this.anchor.prop + ' provided in projection message for setting related data'
      );
    const cache = this.privCache;
    if (!cache) throw new Error('Cannot handle message because the cache is not initialized');
    const prev = await cache.get(eventKey);
    /* Check if this update is already factored in */
    if (prev) {
      if (prev.sequenceNumber > msg.sequenceNumber) return;
      if (prev.sequenceNumber === msg.sequenceNumber && prev.lastNotification) {
        /* If this a replay of the last message, re-emit */
        if (typeof prev.lastNotification === 'string')
          await this.replayNotification(db, prev.lastNotification, prev.anchor, prev.data);
        else await db.undo(prev.lastNotification.entity, prev.lastNotification.origNotification);
        return;
      }
    }
    /* Actually process the message */
    const obj = await process(keyEntity as Entity, eventKey, prev);
    if (obj) cache.set(eventKey, obj);
  }

  private async getKey(entity: Entity, mapper: DomainInterface) {
    if (!entity[this.anchor.prop])
      throw new Error('Key is not found in related data object - looking for: ' + this.anchor.prop);
    return mapper.map(entity[this.anchor.prop] as Entity, this.anchor.type);
  }

  static convertUntyped(obj: UntypedDataObject, anchor: AnchorModel): NewDataObject | undefined {
    const ent = obj[anchor.prop];
    const key = obj[makeKeyName(anchor.prop)];
    if (typeof ent !== 'object' || !(ent as Entity).entityType || typeof key !== 'string')
      throw new Error('Anchor entity or key not correct');
    const res: NewDataObject = {
      entityType: RelatedDataDocumentModel.type,
      sequenceNumber: obj.sequenceNumber,
      anchor: ent as Entity,
      key: key,
      data: {},
      writeTimestamp: typeof obj['writeTimestamp'] === 'number' ? obj['writeTimestamp'] : now(),
    };
    /* Loop all other properties, do not trigger on keys */
    for (const prop in obj)
      if (!res[prop] && obj[prop] instanceof Array && !prop.endsWith('Key')) {
        const item: DataObjectItem = { history: obj[prop] as HistoryEntry[] };
        const itemKey = obj[makeKeyName(prop)];
        if (typeof itemKey === 'string') item.key = itemKey;
        else if (itemKey instanceof Array) item.key = itemKey as string[];
        res.data[prop] = item;
      }
    return res;
  }

  /** Determines performance metrics for the related data domain */
  override collectMetrics(): PerformanceMetric[] {
    const metrics: PerformanceMetric[] = this.collCache ? this.collCache.collectMetrics() : [];
    if (this.privCache) {
      metrics.push({
        id: 'EventDocuments',
        value: this.privCache.size,
        unit: PerformanceUnit.Count,
      });
      metrics.push({
        id: 'EventDocumentMemory',
        value: this.privCache.memory,
        unit: PerformanceUnit.Memory,
      });
    }
    return metrics;
  }

  readonly anchor: AnchorModel;
  protected projectionDispatcher: ProjectionDispatcher;
  private relatedDatas: Map<string, RelatedDataModel>;
  private privCache?: PrivateCollectionCache<Sequenced & DataObject>;
  private collCache?: CollectionCache<Sequenced & DataObject, Entity>;
  private doCleanUp: boolean;
  private cleanUpTimestamp?: { absoluteTime?: ClockTimestamp; relativeTime?: ClockTimestamp };
  /** Registry related members */
  private registry?: RelatedDataRegistry;
  private registryModels: RelatedDataModel[] = [];
  private registryMode = RelatedDataRegistryMode.Exclusive;
}
