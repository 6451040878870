import { WorkflowPath } from '@sqior/react/uiworkflow';
import { FactoryProps } from '@sqior/react/factory';
import { BedAllocationOverviewEntity } from '@sqior/viewmodels/occupancy';
import { WorkflowBaseArea } from '@sqior/react/uiorworkflow';
import styles from './bed-allocation-overview.module.css';
import { DiagnosisInfo, InsuranceInfo } from '@sqior/react/uipatient';
import { SpiInfoView } from '@sqior/react/uipatient';
import { SpecialtyView } from '@sqior/react/uiorworkflow';

export type BedAllocationOverviewProps = FactoryProps<BedAllocationOverviewEntity>;

export function BedAllocationOverview(props: BedAllocationOverviewProps) {
  const headers: React.ReactNode[] = [];

  if (props.data.insurance)
    headers.push(<InsuranceInfo key="insurance" text={props.data.insurance} />);
  if (props.data.diagnosis)
    headers.push(
      <div key="diagnosis" className={styles['diagnosis-container']}>
        <DiagnosisInfo diagnosis={props.data.diagnosis} />
        <div className={styles['speciality']}>
          <SpecialtyView specialty={props.data.specialty} />
        </div>
      </div>
    );
  if (props.data.spi) headers.push(<SpiInfoView value={Math.round(props.data.spi)} />);

  return (
    <WorkflowBaseArea relatedId={props.data.relatedId} team={props.data.team} headers={headers}>
      {props.data.workflow && (
        <WorkflowPath path={props.data.workflow} className={styles['workflow-path']} />
      )}
    </WorkflowBaseArea>
  );
}

export default BedAllocationOverview;
