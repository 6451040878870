import { useEffect } from 'react';

const originalTitle = 'sqior Assistant';

export function useTabTitleCounter(count: number, title?: string) {
  useEffect(() => {
    if (count > 0) document.title = `(${count}) ${title ? title : originalTitle}`;
    else document.title = originalTitle;
  }, [count, title]);
}
