import { State } from '@sqior/js/state';
import { Logger } from '@sqior/js/log';
import { TextResourceMap, TextState } from './text-resource-map';

export class MergingTextResourceState {
  constructor(baseStates: State[]) {
    this.state = new State();
    this.baseStates = baseStates;

    for (const bs of this.baseStates) {
      bs.on(() => {
        this.mergeStates();
      });
    }
    this.mergeStates();
  }

  protected mergeStates() {
    const textMaps = this.baseStates
      .map((bs) => bs.get<TextState>({ lang: '', resources: {} }))
      .filter((bs) => {
        return bs.lang !== '';
      });

    const mergedState: { lang: string; resources: TextResourceMap } = { lang: '', resources: {} };
    for (const tm of textMaps) {
      if (mergedState.lang === '') {
        mergedState.lang = tm.lang;
      } else if (tm.lang !== mergedState.lang) {
        Logger.warn(
          `MergingTextResourceState - inconsistent languages to merge: ${mergedState.lang} vs. ${tm.lang}`
        );
      }

      for (const key in tm.resources) {
        mergedState.resources[key] = tm.resources[key];
      }
    }

    this.state.set(mergedState);
  }

  baseStates: State[];
  readonly state: State;
}
