import styles from './procedure-wrapper.module.css';
import { motion } from 'framer-motion';
import React, { memo, useContext, useRef, useState } from 'react';
import {
  ORWorkflowOverviewDashboardMarkerGroup,
  ORWorkflowOverviewDashboardMarkerType,
  ORWorkflowOverviewDashboardORItemData,
  ORWorkflowOverviewDashboardProcedureData,
  ORWorkflowOverviewDashboardState,
} from '@sqior/viewmodels/orworkflow';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { ComponentFactory } from '@sqior/react/factory';
import { MotionLayout } from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { ZIndex } from '@sqior/react/utils';
import { addSeconds, ClockTimestamp } from '@sqior/js/data';
import { useCustomTimer } from '@sqior/react/state';
import { SqiorMarker } from '@sqior/react/uibase';

const colors = {
  CARD_DEFAULT: '#303859',

  CARD_ACTIVE: '#1cade4',
  CARD_ACTIVE_BACKGROUND: 'rgb(7,81,117)',

  CARD_INACTIVE: 'rgba(48,56,89,0.5)',

  EXTENSION_DARK: '#5e315d',
  EXTENSION_ACTIVE: '#ED7BE8',
};

type Border =
  | {
      color: string | undefined;
      opacity: number | undefined;
    }
  | undefined;

export interface ProcedureWrapperProps {
  item: ORWorkflowOverviewDashboardORItemData;
  timeCalcStart: ClockTimestamp;
  timeCalcEnd: ClockTimestamp;
  autoScroll?: boolean;
  showChallenges?: boolean;
  motionLayout?: MotionLayout;
  contentHeight: number;
  zoomLevel?: number;
  nextExtension?: number;
  cardMinWidth: number;
}

const EMERGENCY_BASE_COLOR = '245, 93, 93';
const CARD_HOVER_BACKGROUND_COLOR = 'rgba(9,15,35)';
const SPACE_ON_OVERFLOW = 10; // in px

const getEmergencyColorWithOpacity = (opacity: undefined | number = 1): string => {
  return `rgba(${EMERGENCY_BASE_COLOR}, ${opacity})`;
};

const getDefaultBorderColor = (state: ORWorkflowOverviewDashboardState, border: Border) => {
  if (state === ORWorkflowOverviewDashboardState.Completed) {
    if (!border || !border.opacity) return colors.CARD_INACTIVE;
    return `${getEmergencyColorWithOpacity(border.opacity - 0.5)}`;
  }

  if (!border || !border.opacity) return colors.CARD_DEFAULT;
  return `${getEmergencyColorWithOpacity(border.opacity)}`;
};

enum BorderType {
  Card = 'Card',
  Card_Deactivated = 'Card_Deactivated',
  Default = 'Default',
  Extension = 'Extension',
}

const getInactiveColor = (border: BorderType) => {
  switch (border) {
    case BorderType.Default:
      return colors.CARD_ACTIVE_BACKGROUND;
    case BorderType.Extension:
      return colors.EXTENSION_DARK;
    case BorderType.Card:
      return colors.CARD_DEFAULT;
    case BorderType.Card_Deactivated:
      return colors.CARD_INACTIVE;
    default:
      return 'green';
  }
};

const getActiveColor = (border: BorderType) => {
  switch (border) {
    case BorderType.Default:
      return colors.CARD_ACTIVE;
    case BorderType.Extension:
      return colors.EXTENSION_ACTIVE;
    case BorderType.Card:
      return colors.CARD_DEFAULT;
    default:
      return 'green';
  }
};

interface GetAdaptedNextExtensionProps {
  start: number;
  end: number;
  nextExtension?: number;
}

const getAdaptedNextExtension = ({ start, end, nextExtension }: GetAdaptedNextExtensionProps) => {
  if (!nextExtension) return undefined;
  if (nextExtension >= start && nextExtension <= end) return nextExtension;
  return undefined;
};

const LINE_WIDTH = 4;

export function ProcedureWrapper({
  item,
  timeCalcStart,
  timeCalcEnd,
  autoScroll,
  showChallenges,
  motionLayout,
  contentHeight,
  zoomLevel,
  nextExtension,
  cardMinWidth,
}: ProcedureWrapperProps) {
  const currentTime = useCustomTimer(addSeconds(10));
  const wrapperRef = useRef<HTMLDivElement>(null);
  const FactoryComponent = useContext(ComponentFactory);
  const timeCalc = new TimePositionCalculator(timeCalcStart, timeCalcEnd);
  const [tabAnimation, setTabAnimation] = useState<boolean>(true);

  const procedureData = item.component as ORWorkflowOverviewDashboardProcedureData;
  const procedureState = procedureData.state;

  const onePixelInTime = (timeCalcEnd - timeCalcStart) / contentHeight;

  const updatePaddingTop = () => {
    const top = timeCalc.convertTimestampNumber(item.start); // in % of the total height of the father
    const fatherElement = wrapperRef.current?.parentElement;
    if (!wrapperRef.current || !fatherElement) return;
    const parentHeight = contentHeight || fatherElement.clientHeight;
    if (!parentHeight) return;
    const missingPixel = top * parentHeight * -1 - SPACE_ON_OVERFLOW;
    if (missingPixel > 0) wrapperRef.current.style.paddingTop = `${missingPixel}px`;
    else wrapperRef.current.style.removeProperty('padding-top');
  };

  updatePaddingTop();

  const getMotionPosition = () => ({
    top: timeCalc.convertTimestamp(item.start),
    bottom: timeCalc.convertTimestampBottom(item.end),
    zIndex: 15,
    right: procedureData.entityType !== 'ORWorkflowOverviewProcedure' ? 0 : undefined,
  });

  const extension = item.extension;

  const adaptedNextExtension = getAdaptedNextExtension({
    start: item.start,
    end: item.end,
    nextExtension,
  });

  const getSplitTopHeight = (): {
    splitHeightTop: number;
    extensionWithTolerance: number;
  } => {
    let distance = 0;
    const tolerance = 12 * onePixelInTime;

    let withNTolerance = 0;

    if (!adaptedNextExtension) return { splitHeightTop: 100, extensionWithTolerance: item.end };

    for (const marker of item.markers)
      if (
        marker.timestamp > adaptedNextExtension - tolerance &&
        marker.timestamp < adaptedNextExtension + tolerance
      ) {
        distance = Math.abs(adaptedNextExtension - marker.timestamp);
        withNTolerance += distance;
      }

    const extensionWithTolerance = adaptedNextExtension - 2 * onePixelInTime - withNTolerance;

    const splitHeightTop = timeCalc.convertMarkerTimestampToNumber({
      markerTimeStamp: extensionWithTolerance,
      itemStart: item.start,
      itemEnd: item.end,
    });

    return { splitHeightTop, extensionWithTolerance: extensionWithTolerance };
  };

  const { splitHeightTop, extensionWithTolerance } = getSplitTopHeight();

  const splitHeightBottom = 100 - splitHeightTop;

  const getBorderBottomColor = () => {
    if (currentTime > item.end && splitHeightTop !== 100) {
      return getInactiveColor(BorderType.Default);
    }

    if (currentTime > item.end && splitHeightTop === 100) {
      return getDefaultBorderColor(procedureState, procedureData.border);
    }

    if (currentTime < item.start && splitHeightTop === 100)
      return getDefaultBorderColor(procedureState, procedureData.border);

    if (currentTime < item.start && splitHeightTop !== 100)
      return getInactiveColor(BorderType.Default);

    if (
      currentTime >= extensionWithTolerance - (LINE_WIDTH - 2) * onePixelInTime &&
      currentTime <= item.end &&
      splitHeightTop !== 100
    ) {
      return getActiveColor(BorderType.Default);
    }

    if (currentTime >= item.start && currentTime <= item.end && splitHeightTop === 100) {
      return getDefaultBorderColor(procedureState, procedureData.border);
    }

    if (
      currentTime >= item.start &&
      currentTime < extensionWithTolerance &&
      splitHeightTop !== 100
    ) {
      return getInactiveColor(BorderType.Default);
    }

    return getActiveColor(BorderType.Default);
  };

  const adaptTimeBorder = (time: number) => {
    if (splitHeightTop === 100) return time + onePixelInTime;
    return time - onePixelInTime * 2;
  };

  const endAnesthesia = item.markers.find((marker) => {
    return (
      marker.group === ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
      marker.type === ORWorkflowOverviewDashboardMarkerType.End
    );
  });

  const getBottomTimeAnesthesia = () => {
    if (endAnesthesia) {
      if (endAnesthesia.timestamp < item.start) return item.start + 2 * onePixelInTime;

      return endAnesthesia.timestamp + 8 * onePixelInTime;
    }
    return item.start + 2 * onePixelInTime;
  };

  const getAnestesiaStartEndMarkers = () => {
    return item.markers
      .filter((marker) => {
        return (
          marker.group === ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
          (marker.type === ORWorkflowOverviewDashboardMarkerType.Start ||
            marker.type === ORWorkflowOverviewDashboardMarkerType.End)
        );
      })
      .filter((marker) => {
        if (!extensionWithTolerance) return true;
        return marker.timestamp <= extensionWithTolerance;
      });
  };

  return (
    <>
      {extension && (
        <div
          className={styles['extension-border']}
          style={{
            top: timeCalc.convertTimestamp(extension),
            bottom: timeCalc.convertTimestampBottom(getBottomTimeAnesthesia()),
          }}
        >
          {getAnestesiaStartEndMarkers().map((marker) => (
            <SqiorMarker
              key={marker.label}
              data={marker}
              start={extension}
              end={getBottomTimeAnesthesia()}
              startMarginRight={-2}
              endMarginRight={2}
              stopActiveColor={getBottomTimeAnesthesia()}
            />
          ))}
          <SplitBorder
            noBorder={item.start === extension}
            body
            activeUtilTime={item.start}
            start={extension}
            end={getBottomTimeAnesthesia()}
            currentTime={currentTime}
            defaultColor={getInactiveColor(BorderType.Extension)}
            activeColor={getActiveColor(BorderType.Extension)}
            inactiveColor={getInactiveColor(BorderType.Extension)}
            width={LINE_WIDTH}
          />
        </div>
      )}

      <motion.div
        ref={wrapperRef}
        whileHover={{
          backgroundColor: CARD_HOVER_BACKGROUND_COLOR,
        }}
        whileTap={{ scale: tabAnimation ? 0.9 : 1 }}
        className={styles['or-item']}
        style={getMotionPosition()}
      >
        <FactoryComponent
          data={item.component}
          autoScroll={autoScroll}
          showChallenges={showChallenges}
          motionLayout={motionLayout}
          setTabAnimation={setTabAnimation}
          cardMinWidth={cardMinWidth}
          doppleBorder={splitHeightTop !== 100}
        />
        {procedureData.entityType === 'ORWorkflowOverviewProcedure' && (
          <div className={styles['cut-container']}>
            <div className={styles['n-container']}>
              {item.markers
                .filter((marker) => {
                  return (
                    marker.timestamp >= item.start &&
                    splitHeightTop <
                      timeCalc.convertMarkerTimestampToNumber({
                        markerTimeStamp: marker.timestamp,
                        itemStart: item.start,
                        itemEnd: item.end,
                      })
                  );
                })
                .map((marker) => (
                  <SqiorMarker
                    key={marker.label}
                    data={marker}
                    start={item.start}
                    end={item.end}
                    endMarginRight={4}
                    stopActiveColor={item.end}
                  />
                ))}

              <div
                className={styles['fillerNTop']}
                style={{
                  height: `${splitHeightTop}%`,
                }}
              />
              <div
                className={styles['fillerNBottom']}
                style={{
                  height: `${splitHeightBottom}%`,
                }}
              >
                <SplitBorder
                  start={extensionWithTolerance}
                  end={item.end}
                  currentTime={currentTime}
                  activeUtilTime={item.end}
                  defaultColor={getInactiveColor(BorderType.Card)}
                  activeColor={getActiveColor(BorderType.Default)}
                  inactiveColor={getInactiveColor(BorderType.Default)}
                  width={LINE_WIDTH}
                />
              </div>
            </div>
            <div className={styles['s-container']}>
              {item.markers
                .filter((marker) => {
                  return (
                    marker.timestamp >= item.start &&
                    marker.group !== ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
                    splitHeightTop >=
                      timeCalc.convertMarkerTimestampToNumber({
                        markerTimeStamp: marker.timestamp,
                        itemStart: item.start,
                        itemEnd: item.end,
                      })
                  );
                })
                .map((marker) => (
                  <SqiorMarker
                    key={marker.label}
                    data={marker}
                    start={item.start}
                    end={item.end}
                    stopActiveColor={item.end + onePixelInTime}
                    startMarginRight={-4}
                  />
                ))}

              <div
                className={styles['fillerSTop']}
                style={{
                  position: 'relative',
                  borderTopRightRadius: 0,
                  height: `${splitHeightTop}%`,
                }}
              >
                <BackgroundLayer zIndex={-1} />
                <SplitBorder
                  start={item.start}
                  end={adaptTimeBorder(extensionWithTolerance)}
                  currentTime={currentTime}
                  activeUtilTime={item.end}
                  defaultColor={getInactiveColor(BorderType.Card)}
                  activeColor={getActiveColor(BorderType.Default)}
                  inactiveColor={getInactiveColor(BorderType.Default)}
                  width={LINE_WIDTH}
                />

                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: splitHeightTop === 100 ? 2 : 4,
                    backgroundColor: getBorderBottomColor(),
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 2,
                    backgroundColor: getDefaultBorderColor(procedureState, procedureData.border),
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <BackgroundLayer zIndex={ZIndex.ProcedureBackground} />
      </motion.div>
    </>
  );
}

export default memo(ProcedureWrapper);

// Background Layer
const BackgroundLayer: React.FC<{ zIndex: number }> = ({ zIndex }) => (
  <div
    className={styles['or-item-background']}
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      zIndex,
    }}
  />
);

const SplitBorder: React.FC<{
  start: number;
  end: number;
  currentTime: number; // current time
  defaultColor: string; // color that would be used in normal dashboard
  activeColor: string; // color that would be used if the card is active
  inactiveColor: string; // color that would be used if the card is inactive
  activeUtilTime: number; // until witch time has the line to be active
  width: number;
  body?: boolean;
  noBorder?: boolean;
}> = ({
  start,
  end,
  currentTime,
  defaultColor,
  activeColor,
  inactiveColor,
  activeUtilTime,
  width,
  body,
  noBorder,
}) => {
  const getBorderRadius = () => {
    if (noBorder) return 0;
    if (body) return width;
    return undefined;
  };

  if (currentTime < start || currentTime > end) {
    const divColor =
      currentTime <= activeUtilTime && currentTime >= start ? activeColor : inactiveColor;

    return (
      <div
        style={{
          height: '100%',
          borderRight: body ? 'none' : `${width}px solid ${divColor}`,
          zIndex: 21,
          backgroundColor: body ? divColor : undefined,
          width: body ? width : undefined,
          borderTopRightRadius: getBorderRadius(),
          borderTopLeftRadius: getBorderRadius(),
        }}
      />
    );
  }

  const topPercentage = ((currentTime - start) / (end - start)) * 100;
  const bottomPercentage = 100 - topPercentage;

  return (
    <>
      <div
        style={{
          height: `${topPercentage}%`,
          borderRight: body ? 'none' : `${width}px solid ${activeColor}`,
          zIndex: 21,
          // width: body ? width : undefined,
          backgroundColor: body ? activeColor : undefined,
          // backgroundColor: activeColor,
          width: body ? width : undefined,
          borderTopRightRadius: getBorderRadius(),
          borderTopLeftRadius: getBorderRadius(),
        }}
      />
      <div
        style={{
          height: `${bottomPercentage}%`,
          borderRight: body ? 'none' : `${width}px solid ${inactiveColor}`,
          zIndex: 21,
          width: body ? width : undefined,
          backgroundColor: body ? inactiveColor : undefined,
        }}
      />
    </>
  );
};
