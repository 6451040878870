import { useMediaQuery } from '@mui/material';

/* eslint-disable-next-line */
interface UseIsMobileProps {}

const MOBILE_BRAKE_POINT = 600;

export function useIsMobile(props?: UseIsMobileProps) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BRAKE_POINT}px)`);
  return isMobile;
}

export default useIsMobile;
