import { arrayFilterAsync, arrayTransformAsync } from '@sqior/js/async';
import { Entity } from '@sqior/js/entity';
import {
  AnchorModel,
  CoreEntities,
  Interface,
  RelatedDataDomain,
  RelatedDataModel,
  makeTextEntity,
  makeTextTemplate,
} from '@sqior/js/meta';
import { BedBlockStrength } from '@sqior/viewmodels/location';
import {
  BedBlockCause,
  BedBlockEntity,
  BedBlockInterfaceModel,
  BedBlockModel,
  BedBlockStrengthModel,
  BedIdEntity,
  BedIdModel,
  BedsEntity,
  BedsRoomModel,
  BedStateModel,
  BedsWardModel,
  makeBedBlockStrength,
  makeSpecialtyUnspecificBlocks,
  SpecialtyUnspecificBlockModel,
  SpecialtyUnspecificBlocksModel,
} from './bed-definitions';
import { LocationClustersEntity } from './location-cluster';
import { ClusterLocations } from './location-cluster-domain';
import { LocationEntities, LocationInterfaces, LocationsEntity } from './location-definitions';
import { LocationAnchor } from './location-domain';
import {
  LanguageEntities,
  Verbosity,
  VerbosityContextProperty,
  VerbosityEntity,
  textResource,
} from '@sqior/plugins/language';
import { SpecialtyEntities, makeSpecialtyQuotas } from '@sqior/plugins/specialty';
import { Logger } from '@sqior/js/log';

export const BedDomainName = 'Bed';
export const BedAnchor = new AnchorModel(BedDomainName, LocationInterfaces.BedKey);
export const LocationBeds = new RelatedDataModel(
  LocationAnchor,
  'Beds',
  BedAnchor.containerModel.type,
  true,
  'beds',
  LocationInterfaces.BedKey
);
export const BedBlock = new RelatedDataModel(BedAnchor, 'Block', LocationEntities.BedBlock, true);

/* Interface for determining the other beds of a room */
export const OtherBedsModel: Interface = {
  type: LocationInterfaces.OtherBeds,
  requires: [BedAnchor.containerModel.type],
};

/* Interface for determining blocked beds */
export const BlockedBedsModel: Interface = {
  type: LocationInterfaces.BlockedBeds,
  requires: [BedAnchor.containerModel.type],
};

const BedBlockCauseStrengthMap = new Map([
  [BedBlockCause.Construction, BedBlockStrength.Hard],
  [BedBlockCause.Disturbance, BedBlockStrength.Medium],
  [BedBlockCause.Isolation, BedBlockStrength.Hard],
  [BedBlockCause.Medical, BedBlockStrength.Medium],
  [BedBlockCause.MedicalSocial, BedBlockStrength.Medium],
  [BedBlockCause.PrivateInsurance, BedBlockStrength.Medium],
  [BedBlockCause.Unknown, BedBlockStrength.Hard],
  [BedBlockCause.Unserviced, BedBlockStrength.Hard],
]);
const BedBlockCauseTextMap = new Map([
  [BedBlockCause.Construction, 'bed_block_cause_construction'],
  [BedBlockCause.Disturbance, 'bed_block_cause_disturbance'],
  [BedBlockCause.Isolation, 'bed_block_cause_isolation'],
  [BedBlockCause.Medical, 'bed_block_cause_medical'],
  [BedBlockCause.MedicalSocial, 'bed_block_cause_medicalsocial'],
  [BedBlockCause.PrivateInsurance, 'bed_block_cause_privateinsurance'],
  [BedBlockCause.Unknown, 'bed_block_cause_unknown'],
  [BedBlockCause.Unserviced, 'bed_block_cause_unserviced'],
]);

export class BedDomain extends RelatedDataDomain {
  constructor() {
    /* Definitions */
    super(BedAnchor, {
      entities: [
        BedIdModel,
        BedBlockModel,
        BedStateModel,
        BedBlockStrengthModel,
        SpecialtyUnspecificBlockModel,
        SpecialtyUnspecificBlocksModel,
      ],
      interfaces: [
        OtherBedsModel,
        BedsWardModel,
        BedsRoomModel,
        BlockedBedsModel,
        BedBlockInterfaceModel,
      ],
      relatedData: BedBlock,
    });

    /* Mappings */
    this.addTrivialMapping(LocationEntities.BedId, LocationInterfaces.BedKey);
    this.addTrivialMapping(BedBlock.interface.type, LocationEntities.BedBlock);
    this.addEntityMapping(
      LocationInterfaces.BedKey,
      LocationInterfaces.BedBlock,
      async (bed, mapper) => mapper.tryMap<BedBlockEntity>(bed, BedBlock.interface.type)
    );

    this.addEntityMapping<LocationsEntity>(
      LocationEntities.Locations,
      BedAnchor.containerModel.type,
      async (locsEnt, mapper) => {
        /* Determine concurrently all beds of all provided locations */
        const bedsEnts = await arrayTransformAsync(locsEnt.locations, async (loc) => {
          return await mapper.tryMap<BedsEntity>(loc, LocationBeds.interface.type);
        });
        let beds: Entity[] = [];
        for (const bedsEnt of bedsEnts) beds = beds.concat(bedsEnt.beds);
        return BedAnchor.makeContainer(beds);
      }
    );

    /* Determining the other beds of a room */
    this.addEntityMapping(
      LocationInterfaces.BedKey,
      LocationInterfaces.OtherBeds,
      async (bedEnt, mapper) => {
        const bedLocs = await mapper.tryMap<LocationsEntity>(bedEnt, LocationBeds.anchorsType);
        if (!bedLocs || bedLocs.locations.length !== 1) return undefined;
        const bedsEnt = await mapper.map<BedsEntity>(
          bedLocs.locations[0],
          LocationBeds.interface.type
        );
        return BedAnchor.makeContainer(
          bedsEnt.beds.filter((otherBedEnt) => {
            return !mapper.isEqual(bedEnt, otherBedEnt);
          })
        );
      }
    );

    // Returns all bed with a specific block reason
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationInterfaces.BlockedBeds,
      async (loc, mapper) => {
        const beds = await mapper.map<BedsEntity>(loc, BedAnchor.containerModel.type);
        const blockedBeds = await arrayFilterAsync(beds.beds, async (bed) => {
          const bedBlock = await mapper.tryMap(bed, BedBlock.interface.type);
          return bedBlock !== undefined;
        });
        return BedAnchor.makeContainer(blockedBeds);
      }
    );

    /* Defines the strength of a bed block cause */
    this.addEntityMapping<BedBlockEntity>(
      LocationEntities.BedBlock,
      LocationEntities.BedBlockStrength,
      async (blockEnt) => {
        return makeBedBlockStrength(
          BedBlockCauseStrengthMap.get(blockEnt.cause as BedBlockCause) ?? BedBlockStrength.Hard
        );
      }
    );
    /* Defines the strength of a bed block cause */
    this.addEntityMapping<BedBlockEntity>(
      LocationEntities.BedBlock,
      LanguageEntities.TextResource,
      async (blockEnt) => {
        return textResource(
          BedBlockCauseTextMap.get(blockEnt.cause as BedBlockCause) ?? 'bed_block_cause_unknown'
        );
      }
    );
    /* Returns the room of a bed */
    this.addEntityMapping(
      LocationInterfaces.BedKey,
      LocationInterfaces.BedsRoom,
      async (bedEnt, mapper) => {
        const bedLocs = await mapper.tryMap<LocationsEntity>(bedEnt, LocationBeds.anchorsType);
        if (!bedLocs) return undefined;
        if (bedLocs.locations.length > 1) {
          Logger.warn(['Bed', bedEnt, 'has more than one assigned location']);
          return undefined;
        }
        return bedLocs.locations[0];
      }
    );
    /* Returns the cluster / ward of a bed */
    this.addEntityMapping(
      LocationInterfaces.BedKey,
      LocationInterfaces.BedsWard,
      async (bedEnt, mapper) => {
        const bedRoom = await mapper.tryMap<LocationsEntity>(bedEnt, LocationInterfaces.BedsRoom);
        const clusters =
          bedRoom &&
          (await mapper.tryMap<LocationClustersEntity>(bedRoom, ClusterLocations.anchorsType));
        if (!clusters || clusters.locationclusters.length !== 1) return undefined;
        return clusters.locationclusters[0];
      }
    );

    // Default mappings for deriving quotas and unspecific blocks
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      LocationEntities.UnspecificBlocks,
      async () => {
        return makeSpecialtyUnspecificBlocks([]);
      },
      { weight: 1000 }
    );
    this.addEntityMapping(
      LocationInterfaces.ClusterKey,
      SpecialtyEntities.Quotas,
      async () => {
        return makeSpecialtyQuotas([]);
      },
      { weight: 1000 }
    );

    this.addEntityMapping<BedIdEntity>(
      LocationEntities.BedId,
      CoreEntities.TextTemplate,
      async (bed, mapper) => {
        const verbosity = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        if (verbosity?.verbosity === Verbosity.Verbose) {
          const room = await mapper.tryMap(bed, LocationInterfaces.BedsRoom);
          return makeTextTemplate('<e>room</e> <e>bed</e>', {
            room: room ?? makeTextEntity('???'),
            bed: makeTextEntity(bed.id.substring(bed.id.length - 1).toLocaleUpperCase()),
          });
        }
        // per default only return the bed label without room
        return makeTextTemplate('<e>bed</e>', {
          bed: makeTextEntity(bed.id.substring(bed.id.length - 1).toLocaleUpperCase()),
        });
      },
      { weight: -9.0, context: VerbosityContextProperty }
    );
  }
}
