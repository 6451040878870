import { CaveSeverity } from '@sqior/viewmodels/patient';
import Cave from '../cave/cave';
import styles from './cave-info.module.css';

export interface CaveInfoProps {
  severity: CaveSeverity;
  description?: string;
  showMultiple?: CaveSeverity[];
}

export function CaveInfo(props: CaveInfoProps) {
  const severityColors: { [key: string]: string } = {
    info: '28, 173, 228',
    warning: '255, 192, 0',
    critical: '245, 93, 93',
  };
  const primaryColor = severityColors[props.severity || 'info'];

  const color = `rgb(${primaryColor})`;
  const backgroundColor = `rgba(${primaryColor}, 0.1)`;

  return (
    <div className={styles['container']} style={{ backgroundColor: backgroundColor, color: color }}>
      <div className={styles['symbol-container']}>
        <Cave severity={props.severity} showMultiple={props.showMultiple}></Cave>
      </div>
      <div className={styles['text-container']}>
        <span>{props.description}</span>
      </div>
      {props.showMultiple && props.showMultiple?.length > 0 ? (
        <div className={styles['multiple-info']}>
          <span>+{props.showMultiple.length + 1}</span>
        </div>
      ) : undefined}
    </div>
  );
}

export default CaveInfo;
