import { Value } from '@sqior/js/data';
import { AddOperation } from './add-operation';
import { DeleteOperation } from './delete-operation';
import { OperationType } from './operation';
import { ReadOperation } from './read-operation';
import { StreamOperation } from './stream-operation';

export class OperationFactory {
  static create(type: string, json?: Value) {
    if (json === undefined)
      throw new Error('Cannot reconstruct operation because no data is provided for type: ' + type);
    switch (type) {
      case OperationType.Add:
        return AddOperation.fromJSON(json);
      case OperationType.Stream:
        return StreamOperation.fromJSON(json);
      case OperationType.Read:
        return ReadOperation.fromJSON(json);
      case OperationType.Delete:
        return DeleteOperation.fromJSON(json);
    }
    throw new Error('Cannot reconstruct operation because of unknown operation type: ' + type);
  }
}
