import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import React, { FC } from 'react';
import styles from './current-bed-view.module.css';
import { Interweave } from 'interweave';
import { SpecialtyView } from '@sqior/react/uiorworkflow';
import { useUIGlobalState } from '@sqior/react/state';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SqiorChip } from '@sqior/react/uibase';

interface Props {
  bed: BedAllocationWardListItemBed;
  expanded: boolean;
}

const CurrentBedView: FC<Props> = ({ bed, expanded }) => {
  const { UIGlobalState } = useUIGlobalState();

  if (!bed?.current) {
    return <div className={styles['center-row']}>Keine Daten</div>;
  }

  if (bed.current.state === BedAllocationWardListItemBedState.Free) {
    return <div className={styles['center']}>Frei</div>;
  }

  if (bed.current.state === BedAllocationWardListItemBedState.Blocked) {
    return (
      <div className={styles['blocked-bed']}>
        <CloseRoundedIcon style={{ fontSize: 16 }} />
        {bed.current?.blockInfo?.reason && <div>{bed.current.blockInfo.reason}</div>}
      </div>
    );
  }

  return (
    <div className={styles['info-container']} style={{ paddingBottom: hasNextBed(bed) ? 5 : 0 }}>
      <Interweave content={getPatientNamePlaceholder(bed)} className={styles['name']} />
      <div className={styles['birthday-container']}>
        {bed?.current.patient?.dob && (
          <div
            className={styles['birthday']}
            style={{ bottom: hasNextBed(bed) && !expanded ? -2 : 0 }}
          >
            {bed.current.patient.dob}
          </div>
        )}

        {bed.current.patient?.specialty && UIGlobalState.bmShowSpeciality && !expanded && (
          <SpecialtyView uppercase specialty={bed.current.patient?.specialty} />
        )}
      </div>

      {expanded && (
        <div className={styles['chips-container']}>
          {bed.current.patient?.specialty && UIGlobalState.bmShowSpeciality && expanded && (
            <SpecialtyView uppercase specialty={{ ...bed.current.patient?.specialty }} />
          )}
          {bed.current.patient?.insurance && UIGlobalState.bmShowInsurance && expanded && (
            <SqiorChip label={`${bed.current.patient?.insurance}`} />
          )}
          {bed.current.patient?.spi && UIGlobalState.bmShowSpi && expanded && (
            <SqiorChip label={`SPI: ${Math.round(bed.current.patient.spi)}`} />
          )}
        </div>
      )}
    </div>
  );
};

export default CurrentBedView;

const getPatientNamePlaceholder = (bed: BedAllocationWardListItemBed) => {
  if (!bed.current.patient) return '';
  return bed.current.patient.name;
};

export const hasNextBed = (bed: BedAllocationWardListItemBed): boolean => {
  const currentBed = bed.current;
  const nextBed = bed.later;
  if (
    currentBed.state === BedAllocationWardListItemBedState.Occupied &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return true;
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Blocked &&
    nextBed.state === BedAllocationWardListItemBedState.Blocked
  ) {
    return true;
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Free &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return true;
  }

  return false;
};
