import { ReactComponent as Blocked } from '../svg-pills/blocked.svg';
import { ReactComponent as BlockedFreeFemale } from '../svg-pills/blocked-free-female.svg';
import { ReactComponent as BlockedFreeMale } from '../svg-pills/blocked-free-male.svg';
import { ReactComponent as BlockedFreeUnisex } from '../svg-pills/blocked-free-unisex.svg';
import { ReactComponent as BlockedOccupiedFemale } from '../svg-pills/blocked-occupied-female.svg';
import { ReactComponent as BlockedOccupiedMale } from '../svg-pills/blocked-occupied-male.svg';
import { ReactComponent as BlockedOccupiedUnisex } from '../svg-pills/blocked-occupied-unisex.svg';

import { ReactComponent as FreeBlockedFemale } from '../svg-pills/free-blocked-female.svg';
import { ReactComponent as FreeBlockedMale } from '../svg-pills/free-blocked-male.svg';
import { ReactComponent as FreeBlockedUnisex } from '../svg-pills/free-blocked-unisex.svg';

import { ReactComponent as FreeFreeFemale } from '../svg-pills/free-free-female.svg';
import { ReactComponent as FreeFreeMale } from '../svg-pills/free-free-male.svg';
import { ReactComponent as FreeFreeUnisex } from '../svg-pills/free-free-unisex.svg';

import { ReactComponent as FreeOccupiedFemaleFemale } from '../svg-pills/free-occupied-female-female.svg';
import { ReactComponent as FreeOccupiedFemaleMale } from '../svg-pills/free-occupied-female-male.svg';
import { ReactComponent as FreeOccupiedFemaleUnisex } from '../svg-pills/free-occupied-female-unisex.svg';

import { ReactComponent as FreeOccupiedMaleFemale } from '../svg-pills/free-occupied-male-female.svg';
import { ReactComponent as FreeOccupiedMaleMale } from '../svg-pills/free-occupied-male-male.svg';
import { ReactComponent as FreeOccupiedMaleUnisex } from '../svg-pills/free-occupied-male-unisex.svg';

import { ReactComponent as FreeOccupiedUnisexFemale } from '../svg-pills/free-occupied-unisex-female.svg';
import { ReactComponent as FreeOccupiedUnisexMale } from '../svg-pills/free-occupied-unisex-male.svg';
import { ReactComponent as FreeOccupiedUnisexUnisex } from '../svg-pills/free-occupied-unisex-unisex.svg';

import { ReactComponent as NoData } from '../svg-pills/no-data.svg';

import { ReactComponent as OccupiedBlockedFemale } from '../svg-pills/occupied-blocked-female.svg';
import { ReactComponent as OccupiedBlockedMale } from '../svg-pills/occupied-blocked-male.svg';
import { ReactComponent as OccupiedBlockedUnisex } from '../svg-pills/occupied-blocked-unisex.svg';

import { ReactComponent as OccupiedFemale } from '../svg-pills/occupied-female.svg';
import { ReactComponent as OccupiedMale } from '../svg-pills/occupied-male.svg';
import { ReactComponent as OccupiedUnisex } from '../svg-pills/occupied-unisex.svg';

import { ReactComponent as OccupiedFreeFemaleFemale } from '../svg-pills/occupied-free-female-female.svg';
import { ReactComponent as OccupiedFreeFemaleFemaleE } from '../svg-pills/occupied-free-female-female-e.svg';
import { ReactComponent as OccupiedFreeFemaleMale } from '../svg-pills/occupied-free-female-male.svg';
import { ReactComponent as OccupiedFreeFemaleMaleE } from '../svg-pills/occupied-free-female-male-e.svg';
import { ReactComponent as OccupiedFreeFemaleUnisex } from '../svg-pills/occupied-free-female-unisex.svg';
import { ReactComponent as OccupiedFreeFemaleUnisexE } from '../svg-pills/occupied-free-female-unisex-e.svg';

import { ReactComponent as OccupiedFreeMaleFemale } from '../svg-pills/occupied-free-male-female.svg';
import { ReactComponent as OccupiedFreeMaleFemaleE } from '../svg-pills/occupied-free-male-female-e.svg';
import { ReactComponent as OccupiedFreeMaleMale } from '../svg-pills/occupied-free-male-male.svg';
import { ReactComponent as OccupiedFreeMaleMaleE } from '../svg-pills/occupied-free-male-male-e.svg';
import { ReactComponent as OccupiedFreeMaleUnisex } from '../svg-pills/occupied-free-male-unisex.svg';
import { ReactComponent as OccupiedFreeMaleUnisexE } from '../svg-pills/occupied-free-male-unisex-e.svg';

import { ReactComponent as OccupiedFreeUnisexFemale } from '../svg-pills/occupied-free-unisex-female.svg';
import { ReactComponent as OccupiedFreeUnisexFemaleE } from '../svg-pills/occupied-free-unisex-female-e.svg';
import { ReactComponent as OccupiedFreeUnisexMale } from '../svg-pills/occupied-free-unisex-male.svg';
import { ReactComponent as OccupiedFreeUnisexMaleE } from '../svg-pills/occupied-free-unisex-male-e.svg';
import { ReactComponent as OccupiedFreeUnisexUnisex } from '../svg-pills/occupied-free-unisex-unisex.svg';
import { ReactComponent as OccupiedFreeUnisexUnisexE } from '../svg-pills/occupied-free-unisex-unisex-e.svg';

import { FC, SVGProps } from 'react';
import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import { PersonSex } from '@sqior/viewmodels/person';

interface Props extends SVGProps<SVGSVGElement> {
  bed: BedAllocationWardListItemBed;
  occupiedFrom?: PersonSex;
}

export const PillIcon: FC<Props> = ({ bed: { current, later }, occupiedFrom, ...rest }) => {
  if (occupiedFrom) {
    switch (occupiedFrom) {
      case PersonSex.Female:
        return <OccupiedFemale {...rest} />;
      case PersonSex.Male:
        return <OccupiedMale {...rest} />;
      default:
        return <OccupiedUnisex {...rest} />;
    }
  }

  // BLOCKED --> BLOCKED
  if (
    current.state === BedAllocationWardListItemBedState.Blocked &&
    later.state === BedAllocationWardListItemBedState.Blocked
  ) {
    return <Blocked {...rest} />;
  }

  // BLOCKED --> FREE
  if (
    current.state === BedAllocationWardListItemBedState.Blocked &&
    later.state === BedAllocationWardListItemBedState.Free
  ) {
    switch (later.sex) {
      case PersonSex.Male:
        return <BlockedFreeMale {...rest} />;
      case PersonSex.Female:
        return <BlockedFreeFemale {...rest} />;
      default:
        return <BlockedFreeUnisex {...rest} />;
    }
  }

  // BLOCKED --> OCCUPIED
  if (
    current.state === BedAllocationWardListItemBedState.Blocked &&
    later.state === BedAllocationWardListItemBedState.Occupied
  ) {
    switch (later.sex) {
      case PersonSex.Male:
        return <BlockedOccupiedMale {...rest} />;
      case PersonSex.Female:
        return <BlockedOccupiedFemale {...rest} />;
      default:
        return <BlockedOccupiedUnisex {...rest} />;
    }
  }

  // FREE --> BLOCKED
  if (
    current.state === BedAllocationWardListItemBedState.Free &&
    later.state === BedAllocationWardListItemBedState.Blocked
  ) {
    switch (current.sex) {
      case PersonSex.Male:
        return <FreeBlockedMale {...rest} />;
      case PersonSex.Female:
        return <FreeBlockedFemale {...rest} />;
      default:
        return <FreeBlockedUnisex {...rest} />;
    }
  }

  // FREE --> FREE
  if (
    current.state === BedAllocationWardListItemBedState.Free &&
    later.state === BedAllocationWardListItemBedState.Free
  ) {
    switch (current.sex) {
      case PersonSex.Male:
        return <FreeFreeMale {...rest} />;
      case PersonSex.Female:
        return <FreeFreeFemale {...rest} />;
      default:
        return <FreeFreeUnisex {...rest} />;
    }
  }

  // FREE --> OCCUPIED
  if (
    current.state === BedAllocationWardListItemBedState.Free &&
    later.state === BedAllocationWardListItemBedState.Occupied
  ) {
    if (current.sex === PersonSex.Male && later.sex === PersonSex.Female)
      return <FreeOccupiedMaleFemale {...rest} />;
    if (current.sex === PersonSex.Male && later.sex === PersonSex.Male)
      return <FreeOccupiedMaleMale {...rest} />;
    if (current.sex === PersonSex.Male && later.sex === PersonSex.Diverse && !later.sex)
      return <FreeOccupiedMaleUnisex {...rest} />;

    if (current.sex === PersonSex.Female && later.sex === PersonSex.Female)
      return <FreeOccupiedFemaleFemale {...rest} />;
    if (current.sex === PersonSex.Female && later.sex === PersonSex.Male)
      return <FreeOccupiedFemaleMale {...rest} />;
    if (current.sex === PersonSex.Female && later.sex === PersonSex.Diverse && !later.sex)
      return <FreeOccupiedFemaleUnisex {...rest} />;

    if ((current.sex === PersonSex.Diverse || !current.sex) && later.sex === PersonSex.Male)
      return <FreeOccupiedUnisexMale {...rest} />;
    if ((current.sex === PersonSex.Diverse || !current.sex) && later.sex === PersonSex.Female)
      return <FreeOccupiedUnisexFemale {...rest} />;
    if (
      (current.sex === PersonSex.Diverse || !current.sex) &&
      (later.sex === PersonSex.Diverse || !later.sex)
    )
      return <FreeOccupiedUnisexUnisex {...rest} />;
    return <div>ERROR</div>;
  }

  // OCCUPIED --> BLOCKED
  if (
    current.state === BedAllocationWardListItemBedState.Occupied &&
    later.state === BedAllocationWardListItemBedState.Blocked
  ) {
    switch (current.sex) {
      case PersonSex.Male:
        return <OccupiedBlockedMale {...rest} />;
      case PersonSex.Female:
        return <OccupiedBlockedFemale {...rest} />;
      default:
        return <OccupiedBlockedUnisex {...rest} />;
    }
  }

  // OCCUPIED --> FREE
  if (
    current.state === BedAllocationWardListItemBedState.Occupied &&
    later.state === BedAllocationWardListItemBedState.Free
  ) {
    if (current.sex === PersonSex.Male && later.sex === PersonSex.Female) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeMaleFemaleE {...rest} />;
      return <OccupiedFreeMaleFemale {...rest} />;
    }

    if (current.sex === PersonSex.Male && later.sex === PersonSex.Male) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeMaleMaleE {...rest} />;
      return <OccupiedFreeMaleMale {...rest} />;
    }

    if (current.sex === PersonSex.Male && (later.sex === PersonSex.Diverse || !later.sex)) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeMaleUnisexE {...rest} />;
      return <OccupiedFreeMaleUnisex {...rest} />;
    }

    if (current.sex === PersonSex.Female && later.sex === PersonSex.Female) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeFemaleFemaleE {...rest} />;
      return <OccupiedFreeFemaleFemale {...rest} />;
    }

    if (current.sex === PersonSex.Female && later.sex === PersonSex.Male) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeFemaleMaleE {...rest} />;
      return <OccupiedFreeFemaleMale {...rest} />;
    }

    if (current.sex === PersonSex.Female && (later.sex === PersonSex.Diverse || !later.sex)) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeFemaleUnisexE {...rest} />;
      return <OccupiedFreeFemaleUnisex {...rest} />;
    }

    if ((current.sex === PersonSex.Diverse || !current.sex) && later.sex === PersonSex.Male) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeUnisexMaleE {...rest} />;
      return <OccupiedFreeUnisexMale {...rest} />;
    }

    if ((current.sex === PersonSex.Diverse || !current.sex) && later.sex === PersonSex.Female) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeUnisexFemaleE {...rest} />;
      return <OccupiedFreeUnisexFemale {...rest} />;
    }

    if (
      (current.sex === PersonSex.Diverse || !current.sex) &&
      (later.sex === PersonSex.Diverse || !later.sex)
    ) {
      if (current.dischargeInfo?.plannedDate) return <OccupiedFreeUnisexUnisexE {...rest} />;
      return <OccupiedFreeUnisexUnisex {...rest} />;
    }
  }

  // OCCUPIED --> OCCUPIED
  if (
    current.state === BedAllocationWardListItemBedState.Occupied &&
    later.state === BedAllocationWardListItemBedState.Occupied
  ) {
    switch (current.sex) {
      case PersonSex.Male:
        return <OccupiedMale {...rest} />;
      case PersonSex.Female:
        return <OccupiedFemale {...rest} />;
      default:
        return <OccupiedUnisex {...rest} />;
    }
  }

  return <NoData {...rest} />;
};

export default PillIcon;
