import { FunctionQueue } from '@sqior/js/async';
import { ValueObject } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';
import { CRUDInterface } from './crud-interface';
import { ensureVersion } from './version';

/* Pool of asynchronous migration actions */
const MigrationPool = new FunctionQueue(undefined, 50);

/** Function migrating the objects of a collection in case that the specified version does not match the recorded one */
export async function migrateCollection<
  SourceType extends ValueObject = ValueObject,
  TargetType extends ValueObject = ValueObject
>(
  db: CRUDInterface,
  path: string,
  version: string,
  adapt: (obj: SourceType) => Promise<TargetType | undefined>
) {
  /* Check the version */
  Logger.debug(['Checking database collection for pot. migration:', path]);
  return ensureVersion(db, path, version, 1, async () => {
    const promises: Promise<void>[] = [];
    let log = false;
    let changed = 0;
    for await (const entry of await db.find<SourceType>(path, {}))
      if (entry) {
        if (!log) {
          Logger.info(['Migrating database collection:', path, 'to new scheme:', version]);
          log = true;
        }
        promises.push(
          MigrationPool.add(async () => {
            /* If the migration function returns undefined, the object is left untouched */
            const migrated = await adapt(entry.obj);
            if (migrated) {
              await db.set(path, entry.id, migrated);
              changed++;
            }
          })
        );
      }
    await Promise.all(promises);
    if (log)
      Logger.info([
        'Migration of database collection:',
        path,
        'completed - records changed:',
        changed,
      ]);
  });
}
