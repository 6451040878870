import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import {
  ItemState,
  ListHeader,
  ListHeaderType,
  ListViewInfo,
  ListViewInfos,
} from '@sqior/viewmodels/visual';
import { VisualEntities, VisualInterfaces } from './visual-definitions';

/* A single list view */

export type ListView = Entity & { title: Entity; items: Entity[] };
export const ListViewModel: EntityModel = { type: VisualEntities.List, props: ['title', 'items'] };
export function makeListView(title: Entity, items: Entity[]): ListView {
  return { entityType: VisualEntities.List, title: title, items: items };
}

/* Root entities for all list views of a user */

export type ListViewRoots = Entity & { roots: Entity[] };
export const ListViewRootsModel: EntityModel = { type: VisualEntities.ListRoots, props: ['roots'] };
export function makeListViewRoots(roots: Entity[]): ListViewRoots {
  return { entityType: VisualEntities.ListRoots, roots: roots };
}

/* Index entity listing list title and entry count */

export const ListViewInfoModel: EntityModel = {
  type: VisualEntities.ListInfo,
  props: ['path', 'title', 'entries', 'showCount'],
  unclassified: true,
};
export function makeListViewInfo(
  path: string,
  title: string,
  entries: number,
  showCount = true
): ListViewInfo {
  return {
    entityType: VisualEntities.ListInfo,
    path: path,
    title: title,
    entries: entries,
    showCount: showCount,
  };
}

/* List of index entities */

export const ListViewInfosModel: EntityModel = { type: VisualEntities.ListInfos, props: ['lists'] };
export function makeListViewInfos(lists: ListViewInfo[]): ListViewInfos {
  return { entityType: VisualEntities.ListInfos, lists: lists };
}

/* Dashboard type to display */

export const DashboardModel: Interface = {
  type: VisualInterfaces.Dashboard,
  requires: VisualEntities.StateHandlerType,
};

/* Entity representing an item state */

export type ItemStateEntity = Entity & { state: ItemState };
export const ItemStateModel: EntityModel = { type: VisualEntities.ItemState, props: ['state'] };
export function makeItemState(state: ItemState): ItemStateEntity {
  return { entityType: VisualEntities.ItemState, state: state };
}

/** List header entity */

export const ListHeaderModel: EntityModel = { type: ListHeaderType, props: ['id', 'title'] };

/* Checks whether there are challenges */

export const IsChallengedModel: Interface = {
  type: VisualInterfaces.IsChallenged,
};

export const makeGroupedList = (title: Entity, items: Entity[], groupedKey: string): Entity => {
  const groupedItems = new Map<string, Entity[]>();

  // Group items based on the key or assign 'Unknown' if the key is missing
  items.forEach((item) => {
    const keyValue = item[groupedKey] ? item[groupedKey].toString() : 'Unbekannt';
    if (!groupedItems.has(keyValue)) {
      groupedItems.set(keyValue, []);
    }
    groupedItems.get(keyValue)?.push(item);
  });

  const newItems: Entity[] = [];

  // Construct the grouped list
  groupedItems.forEach((group, keyValue) => {
    const header: ListHeader = {
      id: keyValue,
      title: keyValue,
      entityType: ListHeaderType,
    };
    newItems.push(header);
    newItems.push(...group);
  });

  return makeListView(title, newItems);
};
