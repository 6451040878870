import { FactoryContext } from '@sqior/react/factory';
import AboutInfo from './about-info/about-info';
import { PluginContext } from '@sqior/js/plugin';
import { UIAuthPlugin } from '@sqior/react/uiauth';

export enum AdvancedPages {
  AboutInfo = 'page.about-info',
}

export function UIAdvancedPlugin(context: PluginContext & FactoryContext) {
  context.plugins.require(UIAuthPlugin, context);
  context.factory.add(AdvancedPages.AboutInfo, AboutInfo);
}
