import styles from './sqior-chip.module.css';
import React, { FC, ReactNode } from 'react';

export interface SqiorChipProps {
  label: string;
  backgroundColor?: string;
  icon?: ReactNode;
}

export const SqiorChip: FC<SqiorChipProps> = ({ label, backgroundColor, icon }) => {
  return (
    <div
      className={styles['container']}
      style={{
        backgroundColor,
      }}
    >
      {icon}
      <div>{label}</div>
    </div>
  );
};

export default SqiorChip;
