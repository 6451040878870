import styles from './free-beds-view.module.css';
import { WardSpecialtiesStatsInfoEntity } from '@sqior/viewmodels/occupancy';

export function FreeBedsView({
  totals: { freeBedsLaterWarning, freeBedsLater },
}: WardSpecialtiesStatsInfoEntity) {
  return (
    <div className={styles['header']}>
      <div className={styles['title']}>Freie Betten nach Belegung</div>
      <div className={styles['result']}>
        <span className={freeBedsLaterWarning ? styles['label-warning'] : undefined}>
          {freeBedsLater}
        </span>
      </div>
    </div>
  );
}

export default FreeBedsView;
