import styles from './range-picker.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { Menu, MenuItem } from '@mui/material';

export type TimeRangeSelect = {
  label: string;
  value: string;
  start: Date;
  end: Date;
};

export interface RangePickerProps {
  options: TimeRangeSelect[];
  value?: TimeRangeSelect;
  disabled?: boolean;
  onChange?: (value: TimeRangeSelect) => void;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  menuWidth?: number;
}

export function RangePicker({
  disabled,
  options,
  onChange,
  value,
  icon,
  style,
  menuWidth,
}: RangePickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClose = () => setAnchorEl(null);
  const onOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const onItemClick = (option: TimeRangeSelect) => {
    onChange?.(option);
    onClose();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{ width: menuWidth }}>
          {options?.map((option) => (
            <MenuItem
              selected={value?.label === option.label}
              onClick={() => onItemClick(option)}
              key={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
      <div
        className={styles['multiselect-button']}
        onClick={onOpen}
        style={{ opacity: disabled ? 0.5 : 1, ...style }}
      >
        {icon}
        <div>{value?.value}</div>
        {anchorEl ? (
          <ArrowDropUpIcon style={{ color: '#51535E' }} />
        ) : (
          <ArrowDropDownIcon style={{ color: '#51535E' }} />
        )}
      </div>
    </>
  );
}

export default RangePicker;
