import { EntityHeader } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { UserEntities, UserInterfaces } from './user-definitions';

/** PIN code type */

export type PINCode = EntityHeader & { code: string; length: number };
export const PINCodeModel: EntityModel = { type: UserEntities.PINCode, props: ['code', 'length'] };
export function makePINCode(code: string, length: number): PINCode {
  return { entityType: PINCodeModel.type, code, length };
}

/** Interface checking if the PIN code function shall be available for a user */

export const PINAvailableModel: Interface = { type: UserInterfaces.PINAvailable };
