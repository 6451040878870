import React from 'react';
import { AuthProvider, TestAuthProvider } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';

/** Return type of auth context */

export type AuthConfirmTriggerType = [string, () => void];

export type AuthContextData = {
  provider: AuthProvider;
  confirmIdentity: Emitter<AuthConfirmTriggerType>;
};

/** AuthContext which shall be used by sqior components to get the token */

export const AuthContext = React.createContext<AuthContextData>({
  provider: new TestAuthProvider(),
  confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
});
