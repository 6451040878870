import { EnrichedChatVM } from '@sqior/viewmodels/communication';
import ChatItem from '../chat-item/chat-item';
import styles from './chat-list.module.css';
import { ContainerWithShadow, useTextResources } from '@sqior/react/uibase';

export interface ChatListProps {
  chats: EnrichedChatVM[];
}

export function ChatList(props: ChatListProps) {
  const textRes = useTextResources();
  return (
    <ContainerWithShadow className={styles['list-container']}>
      <div className={styles['container']}>
        {props.chats.map((item) => {
          return <ChatItem key={item.id.id} item={item} />;
        })}
        {props.chats.length === 0 && (
          <div className={styles['hint']}>
            <div className={styles['hint-text']}>{textRes.get('no_chats')}</div>
          </div>
        )}
      </div>
    </ContainerWithShadow>
  );
}

export default ChatList;
