import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { LocationEntities, LocationInterfaces } from './location-definitions';

export type RoomId = Entity & { id: string };
export const RoomIdModel: EntityModel = {
  type: LocationEntities.RoomId,
  props: ['id'],
  keys: ['id'],
  extends: CoreEntities.Key,
  unclassified: true,
};
export function makeRoomId(id: string): RoomId {
  return { entityType: LocationEntities.RoomId, id: id };
}

/** Interface providing the current room */

export const CurrentRoomModel: Interface = {
  type: LocationInterfaces.CurrentRoom,
  requires: LocationInterfaces.Key,
};
