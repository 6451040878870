import {Bytes} from './binary';
import {Value, ValueOrNothing} from './value';

/** Makes a value immutable so that it cannot be altered anymore */

export function makeImmutable<Type extends ValueOrNothing>(value: Type): Type {
  /* Nothing to do for primitve types */
  if (typeof value !== 'object') return value;
  /* Make this immutable */
  Object.freeze(value);
  /* Make all elements of an array immutable */
  if (value instanceof Array) for (const el of value) makeImmutable(el);
  else if (!(value instanceof Bytes))
    /* Make all elements of an object immutable */
    for (const key in value) makeImmutable(value[key] as Value);
  return value;
}
