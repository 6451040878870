import styles from './spi-chart.module.css';
import { FC } from 'react';
import { SqiorChartBlock } from '@sqior/react/uibase';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

interface Props {
  spiValueLimit: number;
  spiMaxCountLimit: number;
  spiCountLimit: number;
}

enum SPIStateType {
  Filled = 'filled',
  Empty = 'empty',
  Overflow = 'overflow',
}

interface SPIState {
  state: SPIStateType;
}

const SPIChart: FC<Props> = ({ spiValueLimit, spiCountLimit, spiMaxCountLimit }) => {
  const { data, overflow } = getValues({
    value: spiCountLimit,
    max: spiMaxCountLimit,
  });

  const renderItem = (item: SPIState) => {
    switch (item.state) {
      case SPIStateType.Filled:
        return <Filled />;
      case SPIStateType.Empty:
        return <Empty />;
      case SPIStateType.Overflow:
        return <Overflow />;
      default:
        console.error('Unknown state');
        return null;
    }
  };

  return (
    <div>
      <div className={styles['header']}>
        <div className={styles['title']}>{`Patienten SPI ≤ ${spiValueLimit}`}</div>
        <div className={styles['result']}>
          <span className={overflow.length > 0 ? styles['label-warning'] : undefined}>
            {spiCountLimit}
          </span>
          <span>{`/${spiMaxCountLimit}`}</span>
        </div>
      </div>

      <SqiorChartBlock
        data={data}
        overflow={overflow}
        renderItem={renderItem}
        height={24}
        width={24}
      />
    </div>
  );
};

export default SPIChart;

interface GetValuesParams {
  value: number;
  max: number;
}

interface ReturnValues {
  data: SPIState[];
  overflow: SPIState[];
}

const getValues = ({ value, max }: GetValuesParams): ReturnValues => {
  const filled = [...Array(Math.min(value, max))].map(
    (_): SPIState => ({
      state: SPIStateType.Filled,
    })
  );
  const empty = [...Array(getEmptyValue({ value, max }))].map(
    (_): SPIState => ({
      state: SPIStateType.Empty,
    })
  );

  const overflow = [...Array(getOverflowValue({ value, max }))].map(
    (_): SPIState => ({
      state: SPIStateType.Overflow,
    })
  );
  return {
    data: [...filled, ...empty],
    overflow,
  };
};

const Filled = () => (
  <div className={styles['centered']}>
    <PersonRoundedIcon style={{ color: '#8ED4A7', paddingBottom: 8 }} />
  </div>
);

const Empty = () => (
  <div className={styles['centered']}>
    <PersonRoundedIcon style={{ color: 'rgba(174,176,189,0.4)', paddingBottom: 8 }} />
  </div>
);

const Overflow = () => (
  <div className={styles['centered']}>
    <PersonRoundedIcon style={{ color: '#FFC000', paddingBottom: 8 }} />
  </div>
);

interface GetEmptyValueParams {
  value: number;
  max: number;
}

const getEmptyValue = ({ value, max }: GetEmptyValueParams) => {
  const _ = max - value;
  return _ > 0 ? _ : 0;
};

const getOverflowValue = ({ value, max }: GetEmptyValueParams) => {
  const _ = max - value;
  return _ < 0 ? Math.abs(_) : 0;
};
