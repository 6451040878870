import styles from './orworkflow-overview-dashboard.module.css';
import React, { startTransition, useEffect, useRef, useState } from 'react';
import {
  ORWorkflowOverviewDashboardData,
  ORWorkflowOverviewDashboardORItemData,
} from '@sqior/viewmodels/orworkflow';
import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { ReactComponent as TargetIcon } from './target.svg';
import { AnimatePresence, motion } from 'framer-motion';
import ZoomLevelView from '../zoom-level-view/zoom-level-view';
import {
  ALLOWED_INTERVAL,
  INIT_SCROLL_MIN,
  INIT_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MENU_WIDTH,
  MIN_ZOOM_LEVEL,
  SCROLL_STEP,
  VISIBLE_ZOOM_VIEW_TIMEOUT,
  ZOOM_STEP,
} from './constants';
import { TimePositionCalculator } from './TimePositionCalculator';
import { calculatePositionAndTime, formatTime, isToday } from './utils';
import OpConfigView from '../op-config-view/op-config-view';
import { useCustomTimer, useDynamicStateRaw, useTimer } from '@sqior/react/state';
import { ViewportSize } from '@sqior/web/utils';

import ProcedureWrapper from '../procedure-wrapper/procedure-wrapper';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { ZIndex } from '@sqior/react/utils';
import CurrentTimer from '../current-timer/current-timer';
import { addSeconds } from '@sqior/js/data';
import PreliminaryProceduresView from '../preliminary-procedures-view/preliminary-procedures-view';
import DashboardDisplayInfo from '../dashboard-display-info/dashboard-display-info';

export type ORWorkflowOverviewDashboardProps = FactoryProps<ORWorkflowOverviewDashboardData>;
export type MotionLayout = boolean | 'position' | 'size' | 'preserve-aspect';

let visibleTimeout: NodeJS.Timeout;

const MIN_WIDTH_CARD = 158; // min width of a card in px
const FRICTION = 0.95;

interface GetMinutesFromPixelParams {
  deltaY: number;
  containerHeight: number;
  timeCalc?: TimePositionCalculator;
}

const getMinutesFromPixel = ({ deltaY, containerHeight, timeCalc }: GetMinutesFromPixelParams) => {
  if (!timeCalc) return deltaY;
  const visualMillis = timeCalc.end - timeCalc.start; // in milliseconds
  const visualMinutes = visualMillis / 1000 / 60; // in minutes
  const minutePerPixel = visualMinutes / containerHeight;
  return deltaY * minutePerPixel;
};

const updateScrollBarView = (
  scrollContainer: HTMLDivElement
): {
  thumbWidth: number;
  scrollLeftThumb: number;
} => {
  if (!scrollContainer) return { thumbWidth: 0, scrollLeftThumb: 0 };

  const containerWidth = scrollContainer.offsetWidth; // width of the container in px
  const contentWidth = scrollContainer.scrollWidth; // width of the content in px
  const visibleRatio = containerWidth / contentWidth; // visible ratio of the content
  const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth; // max scroll left
  const scrollLeft = Math.min(Math.max(scrollContainer.scrollLeft, 0), maxScrollLeft); // current scroll left

  const thumbWidth = visibleRatio * containerWidth; // width of the thumb in px
  const scrollLeftThumb = scrollLeft * visibleRatio; // scroll left of the thumb in px
  return { thumbWidth: thumbWidth === containerWidth ? 0 : thumbWidth, scrollLeftThumb };
};

const isInputOrTextareaActive = () => {
  const activeElement = document.activeElement;
  return activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA';
};

export function ORWorkflowOverviewDashboard(props: ORWorkflowOverviewDashboardProps) {
  const {
    data: { preliminaryProcedures, ors, interval, core, target },
  } = props;

  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');

  const { setTimerValue, dragging, setDragging } = useTimer();
  const currentTime = useCustomTimer(addSeconds(10));

  const dynamicScrollStep = useRef<number>(SCROLL_STEP);
  const startY = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const velocity = useRef<number>(0);
  const lastTouchY = useRef<number>(0);

  const autoScroll = factoryGetProp<boolean | undefined>('autoScroll', props);
  const openConfig = factoryGetProp<boolean | undefined>('openConfig', props);
  const showChallenges = factoryGetProp<boolean | undefined>('showChallenges', props);

  const selectedDate = props.data.date;
  const selectedDateDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);

  const [zoomLevel, setZoomLevel] = useState<number>(INIT_ZOOM_LEVEL);
  const [isZoomVisible, setIsZoomVisible] = useState(false);
  const [motionLayout, setMotionLayout] = useState<MotionLayout | undefined>('position');
  const [scrollMin, setScrollMin] = useState<number>(INIT_SCROLL_MIN);
  const [currentPos, setCurrentPos] = useState<string>('0%');
  const [timeCalc, setTimeCalc] = useState<TimePositionCalculator>();
  const [targetDate, setTargetDate] = useState<Date>(new Date());
  const [thumbWidth, setThumbWidth] = useState<number>(0);

  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [horizontalDragging, setHorizontalDragging] = useState<boolean>(false);
  const [scrollStart, setScrollStart] = useState<number>(0);
  const [startX, setStartX] = useState<number>(0);

  const [cardMinWidth, setCardMinWidth] = useState<number>(MIN_WIDTH_CARD);

  const [disableGrab, setDisableGrab] = useState<boolean>(false);

  const { ref: contentRef, height: contentHeight = 1 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    const { currentPos, timeCalc, targetDate } = calculatePositionAndTime({
      currentTime,
      selectedDate,
      core,
      scrollMin,
      zoomLevel,
      target,
      interval,
      dragging,
    });
    setCurrentPos(currentPos);
    setTimeCalc(timeCalc);
    setTargetDate(targetDate);
  }, [currentTime, selectedDate, core, scrollMin, zoomLevel, target, interval]);

  const startPositionX = useRef<number>(0);
  const scrollLeftPosition = useRef<number>(0);
  const [isDown, setIsDown] = useState<boolean>(false);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (dragging) return;
      setMotionLayout(undefined);
      event.preventDefault();
      event.stopPropagation();

      if (event.ctrlKey || event.metaKey || event.shiftKey) handleZoom(event);
      else handleScroll(event);

      startTransition(() => {
        setMotionLayout('position');
      });
    };

    const handleZoom = (event: WheelEvent) => {
      if (visibleTimeout) clearTimeout(visibleTimeout);
      setIsZoomVisible(true);
      const direction = (event.deltaY || event.deltaX) > 0 ? ZOOM_STEP : -ZOOM_STEP;

      setZoomLevel((prev) => {
        let newZoomLevel = Math.round((prev + direction) * 10) / 10;
        if (newZoomLevel > MAX_ZOOM_LEVEL) newZoomLevel = MAX_ZOOM_LEVEL; // set max zoom level to 2
        if (newZoomLevel < MIN_ZOOM_LEVEL) newZoomLevel = MIN_ZOOM_LEVEL; // set min zoom level to 0.5
        dynamicScrollStep.current = SCROLL_STEP * newZoomLevel;
        return newZoomLevel;
      });
      visibleTimeout = setTimeout(() => {
        setIsZoomVisible(false);
      }, VISIBLE_ZOOM_VIEW_TIMEOUT);
    };

    const handleScroll = (event: WheelEvent) => {
      const delta = getMinutesFromPixel({
        deltaY: event.deltaY,
        containerHeight: contentHeight,
        timeCalc,
      });
      if (delta > 0) {
        setScrollMin((prev) => {
          const newScrollMin = prev + delta;
          const { timeCalc } = calculatePositionAndTime({
            currentTime,
            selectedDate,
            core,
            scrollMin: newScrollMin,
            zoomLevel,
            target,
            interval,
            dragging,
          });
          if (
            new Date(timeCalc.end).getTime() ===
            ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end.getTime()
          )
            return prev;
          return newScrollMin;
        });
      }
      if (delta < 0) {
        setScrollMin((prev) => {
          const newScrollMin = prev + delta;
          const { timeCalc } = calculatePositionAndTime({
            currentTime,
            selectedDate,
            core,
            scrollMin: newScrollMin,
            zoomLevel,
            target,
            interval,
            dragging,
          });
          if (
            new Date(timeCalc.start).getTime() ===
            ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start.getTime()
          )
            return prev;
          return newScrollMin;
        });
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      const currentTouchY = event.touches[0].clientY;

      // Calculate the velocity based on the distance moved
      // in this particular touchmove event
      velocity.current = currentTouchY - lastTouchY.current;

      // Update lastTouchY for the next calculation
      lastTouchY.current = currentTouchY;

      if (event.touches.length === 1) {
        // Single touch
        const moveY = event.touches[0].clientY;
        const delta = startY.current - moveY;
        const newWheelEvent = new WheelEvent('wheel', {
          deltaY: delta,
          deltaX: 0,
        });
        handleWheel(newWheelEvent);
        // Update the start Y position for the next comparison
        startY.current = moveY;
      }
    };

    const handleTouchStart = (event: TouchEvent) => {
      const touch = event.touches[0];
      startY.current = touch.clientY;
    };

    const handleDeceleration = () => {
      if (!containerRef.current) return;
      if (Math.abs(velocity.current) > 0.5) {
        handleWheel(new WheelEvent('wheel', { deltaY: -velocity.current }));
        velocity.current *= FRICTION;
        requestAnimationFrame(handleDeceleration);
      }
    };

    const handleTouchEnd = () => {
      handleDeceleration();
    };

    const handleHorizontalScroll = (event: Event) => {
      const node = event.target as HTMLDivElement;
      if (!node) return;
      const { thumbWidth, scrollLeftThumb } = updateScrollBarView(node);
      setThumbWidth(thumbWidth);
      setScrollLeft(scrollLeftThumb);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!scrollContainerRef.current || isInputOrTextareaActive()) return;

      switch (event.key) {
        case 'ArrowRight': {
          scrollContainerRef.current.scrollBy({
            left: 100,
            behavior: 'auto',
          });
          break;
        }

        case 'ArrowLeft': {
          scrollContainerRef.current.scrollBy({
            left: -100,
            behavior: 'auto',
          });
          break;
        }
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      if (!scrollContainerRef.current) return;
      setIsDown(true);
      startPositionX.current = e.pageX;
      scrollLeftPosition.current = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.style.cursor = 'grabbing';
    };

    const onMouseUp = () => {
      setIsDown(false);
      if (!scrollContainerRef.current) return;
      scrollContainerRef.current.style.cursor = 'grab';
    };

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (!isDown || !scrollContainerRef.current || !timeCalc) return;
      const x = e.pageX;
      const y = e.movementY;
      const walk = x - startPositionX.current;

      scrollContainerRef.current.scrollLeft = scrollLeftPosition.current - walk;

      handleWheel(
        new WheelEvent('wheel', {
          deltaY: -y,
          deltaX: 0,
        })
      );
    };

    const container = containerRef.current;
    const scrollContainer = scrollContainerRef.current;
    if (!container || !scrollContainer) return;

    const removeAllListeners = () => {
      container.removeEventListener('wheel', handleWheel);
      scrollContainer.removeEventListener('touchmove', handleTouchMove);
      scrollContainer.removeEventListener('touchstart', handleTouchStart);
      scrollContainer.removeEventListener('touchend', handleTouchEnd);
      scrollContainer.removeEventListener('scroll', handleHorizontalScroll);
      window.removeEventListener('keydown', handleKeyDown);

      scrollContainer.removeEventListener('mousedown', onMouseDown);
      scrollContainer.removeEventListener('mouseup', onMouseUp);
      scrollContainer.removeEventListener('mousemove', onMouseMove);
    };

    if (disableGrab) {
      removeAllListeners();
      return;
    }

    container.addEventListener('wheel', handleWheel, { passive: false });
    scrollContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
    scrollContainer.addEventListener('touchend', handleTouchEnd);
    scrollContainer.addEventListener('touchmove', handleTouchMove);
    scrollContainer.addEventListener('scroll', handleHorizontalScroll);

    window.addEventListener('keydown', handleKeyDown);

    scrollContainer.addEventListener('mousedown', onMouseDown, { passive: false });
    scrollContainer.addEventListener('mouseup', onMouseUp);
    scrollContainer.addEventListener('mousemove', onMouseMove);

    return () => {
      removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    core,
    currentTime,
    scrollMin,
    zoomLevel,
    selectedDate,
    interval,
    target,
    dragging,
    isDown,
    disableGrab,
  ]);

  useEffect(() => {
    if (!scrollContainerRef.current) return;
    handleStateChangeScrollBarView(scrollContainerRef.current);
  }, [viewport, props]);

  const handleStateChangeScrollBarView = (node: HTMLDivElement) => {
    const { scrollLeftThumb, thumbWidth } = updateScrollBarView(node);
    setScrollLeft(scrollLeftThumb);
    setThumbWidth(thumbWidth);
  };

  const handleCardMinWidth = (node: HTMLDivElement) => {
    const boundingRect = node.getBoundingClientRect();
    const visibleScrollWidth = boundingRect.width;
    const clustersLength = ors.length;

    const fitWidth = visibleScrollWidth / clustersLength - 4;

    const virtualWidth = clustersLength * MIN_WIDTH_CARD;
    const virtualHidden = virtualWidth - visibleScrollWidth;

    if (virtualHidden > MIN_WIDTH_CARD) setCardMinWidth(MIN_WIDTH_CARD + 50);
    else setCardMinWidth(fitWidth);
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === scrollContainerRef.current) {
          handleCardMinWidth(scrollContainerRef.current);
          setTimeout(() => {
            if (!scrollContainerRef.current) return;
            handleStateChangeScrollBarView(scrollContainerRef.current);
          }, 500);
        }
      }
    });

    if (scrollContainerRef.current) {
      observer.observe(scrollContainerRef.current);
    }

    return () => {
      if (scrollContainerRef.current) {
        observer.unobserve(scrollContainerRef.current);
      }
    };
  }, [ors]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setHorizontalDragging(true);
    setStartX(e.clientX);
    if (scrollContainerRef.current) {
      setScrollStart(scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setHorizontalDragging(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (horizontalDragging && scrollContainerRef.current && scrollBarRef.current) {
      const deltaX = e.clientX - startX;
      const maxScrollLeft =
        scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollLeft = Math.min(
        Math.max(scrollStart + deltaX, 0),
        maxScrollLeft
      );
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [horizontalDragging]);

  const menuRef = useRef<HTMLDivElement>(null);

  const onMouseMove = (e: React.MouseEvent) => {
    if (!dragging || !timeCalc || !menuRef.current) return;

    const boundingRect = menuRef.current.getBoundingClientRect();
    const menuHeight = boundingRect.height;
    const offset = boundingRect.top;

    const { start, end } = timeCalc;
    const y = e.clientY - offset;

    const allMilliseconds = end - start;
    const onePixelPerMilliseconds = allMilliseconds / menuHeight;
    const milliseconds = y * onePixelPerMilliseconds;

    setTimerValue(start + milliseconds);
  };

  const onContextMenu = (e: React.MouseEvent) => {
    // e.preventDefault();
  };

  const showHeaderInfo = ors.some((or) => or.headerItem);

  return (
    <>
      <ZoomLevelView
        isZoomVisible={isZoomVisible}
        zoomLevel={zoomLevel}
        MAX_ZOOM_LEVEL={MAX_ZOOM_LEVEL}
        MIN_ZOOM_LEVEL={MIN_ZOOM_LEVEL}
      />
      <div
        ref={containerRef}
        className={styles['grid-container']}
        style={{
          gridTemplateColumns: `minmax(${MENU_WIDTH}px, ${MENU_WIDTH}px) 1fr`,
        }}
      >
        <div className={styles['empty']}>
          <div
            className={styles['or-header']}
            style={{ gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)` }}
          >
            {ors.map((or, index) => (
              <OpConfigView
                key={or.name}
                empty
                or={or}
                openConfig={openConfig}
                firstItem={index === 0}
                lastItem={ors.length - 1 === index}
              />
            ))}
          </div>

          <AnimatePresence>
            {showHeaderInfo && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                className={styles['or-header']}
                style={{ gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)`, overflow: 'hidden' }}
              >
                {ors.map((or) => (
                  <DashboardDisplayInfo key={or.name} empty data={or.headerItem} />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div ref={menuRef} className={styles['menu']} onMouseMove={onMouseMove}>
          {timeCalc &&
            timeCalc.hours.map((t) => (
              <div
                key={t}
                className={styles['time']}
                style={{
                  top: timeCalc.convertTimestamp(t),
                }}
              >
                {formatTime(new Date(t).getHours(), 0)}
              </div>
            ))}

          {timeCalc &&
            timeCalc.hours.map((t) => (
              <div
                key={t}
                className={styles['time-line']}
                style={{
                  top: timeCalc.convertTimestamp(t),
                  zIndex: ZIndex.DashboardProcedureContent,
                }}
              />
            ))}

          <CurrentTimer
            currentTime={currentTime}
            selectedDate={selectedDate}
            interval={interval}
            currentPos={currentPos}
            timeCalc={timeCalc}
            contentHeight={contentHeight}
            dragging={dragging}
            setDragging={setDragging}
            tablet={false}
          />

          {timeCalc && target && (
            <TargetIcon
              className={styles['target-icon']}
              style={{
                top: timeCalc.convertTimestamp(targetDate.getTime()),
                zIndex: ZIndex.DashboardTargetIcon,
              }}
            />
          )}
        </div>
        <div
          onContextMenu={onContextMenu}
          ref={scrollContainerRef}
          className={styles['header-content']}
        >
          <div
            className={styles['header']}
            onMouseEnter={() => {
              setDisableGrab(true);
              if (!scrollContainerRef.current) return;
              scrollContainerRef.current.style.cursor = 'auto';
            }}
            onMouseLeave={() => {
              setDisableGrab(false);
              if (!scrollContainerRef.current) return;
              scrollContainerRef.current.style.cursor = 'grab';
            }}
          >
            <div
              className={styles['or-header']}
              style={{ gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)` }}
            >
              {ors.map((or, index) => (
                <OpConfigView
                  key={or.name}
                  or={or}
                  openConfig={openConfig}
                  firstItem={index === 0}
                  lastItem={ors.length - 1 === index}
                />
              ))}
            </div>
            <AnimatePresence>
              {showHeaderInfo && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  className={styles['or-header']}
                  style={{ gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)`, overflow: 'hidden' }}
                >
                  {ors.map((or) => (
                    <DashboardDisplayInfo key={or.name} data={or.headerItem} />
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div ref={contentRef} className={styles['content']}>
            {timeCalc &&
              timeCalc.hours.map((t) => (
                <div
                  key={t}
                  className={styles['time-line-soft']}
                  style={{ top: timeCalc.convertTimestamp(t) }}
                />
              ))}
            {timeCalc && target && (
              <div
                className={styles['target-time-line']}
                style={{ top: timeCalc.convertTimestamp(targetDate.getTime()) }}
              />
            )}

            {isToday({
              day: selectedDate.day,
              month: selectedDate.month,
              year: selectedDate.year,
              currentTime,
              interval,
            }) && (
              <>
                <div
                  className={styles['current-time-line']}
                  style={{ top: currentPos, zIndex: ZIndex.DashboardCurrentTimeLine }}
                >
                  <div className={styles['current-time-line-fill']} />
                  <div className={styles['current-time-line-center']} />
                  <div className={styles['current-time-line-fill']} />
                </div>

                <div className={styles['current-time-indicator']} style={{ top: currentPos }} />
              </>
            )}

            <div
              className={styles['or-items-outer']}
              style={{
                gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)`,
              }}
            >
              {ors.map((or) => (
                <div key={or.name} className={styles['or-items']}>
                  {timeCalc &&
                    or.items.map((item, index) => (
                      <ProcedureWrapper
                        key={item.component.id}
                        autoScroll={autoScroll}
                        motionLayout={motionLayout}
                        timeCalcStart={timeCalc.start}
                        timeCalcEnd={timeCalc.end}
                        showChallenges={showChallenges}
                        item={item}
                        contentHeight={contentHeight}
                        zoomLevel={zoomLevel}
                        nextExtension={getNextExtension(or.items, index)}
                        cardMinWidth={cardMinWidth}
                      />
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <motion.div
          ref={scrollBarRef}
          className={styles['scroll-bar']}
          onMouseDown={handleMouseDown}
          animate={{
            left: scrollLeft + MENU_WIDTH,
            transition: {
              duration: 0,
            },
          }}
          style={{
            width: thumbWidth,
            zIndex: ZIndex.DashboardVirtualScroll,
            position: 'absolute',
          }}
        />
      </div>
      {preliminaryProcedures && (
        <PreliminaryProceduresView autoScroll={autoScroll} data={preliminaryProcedures} />
      )}
    </>
  );
}

export default ORWorkflowOverviewDashboard;

const getNextExtension = (
  arr: ORWorkflowOverviewDashboardORItemData[],
  currentIndex: number
): undefined | number => {
  const nextItem = arr[currentIndex + 1];
  if (!nextItem) return undefined;
  return nextItem.extension;
};
