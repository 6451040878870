import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { TimeEntities } from './time-definitions';

/** Hours (0-23) time component */

export type HoursEntity = EntityHeader & { hours: number };
export const HoursModel: EntityModel = {
  type: TimeEntities.Hours,
  props: ['hours'],
  keys: ['hours'],
  unclassified: true,
};
export function makeHours(hours: number): HoursEntity {
  return { entityType: TimeEntities.Hours, hours };
}

/* Time in a day */

export type TimeEntity = HoursEntity & { minutes: number };
export const TimeEntityModel: EntityModel = {
  type: TimeEntities.Time,
  props: ['hours', 'minutes'],
  unclassified: true,
};
export function makeTimeEntity(hours: number, minutes: number): TimeEntity {
  return { entityType: TimeEntities.Time, hours, minutes };
}
export function makeTimeFromTimestamp(timestamp: number): TimeEntity {
  const d = new Date(timestamp);
  return makeTimeEntity(d.getHours(), d.getMinutes());
}
export function makeTimeFromJS(date: Date): TimeEntity {
  return makeTimeEntity(date.getHours(), date.getMinutes());
}

export type TimeRangeEntity = EntityHeader & { start: TimeEntity; end: TimeEntity };
export const TimeRangeEntityModel: EntityModel = {
  type: TimeEntities.TimeRange,
  props: ['start', 'end'],
};
export function makeTimeRangeEntity(start: TimeEntity, end: TimeEntity): TimeRangeEntity {
  return { entityType: TimeEntities.TimeRange, start, end };
}

/** Function comparing two times */
export function isTimeBefore(a: TimeEntity, b: TimeEntity) {
  return a.hours < b.hours || (a.hours === b.hours && a.minutes < b.minutes);
}
