import { TreatmentPathItemViewModel } from '@sqior/viewmodels/treatment-path';
import styles from './treatment-path-item.module.css';
import { VerticalLine } from '@sqior/react/uiworkflow';
import { ReactComponent as Icon } from './todo-box.svg';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext } from 'react';
import { SelectionPageContext } from '@sqior/react/uiselection';

export interface TreatmentPathItemProps {
  model: TreatmentPathItemViewModel;
  topLine: boolean;
  bottomLine: boolean;
}

export function TreatmentPathItem(props: TreatmentPathItemProps) {
  const selContext = useContext(SelectionPageContext);
  return (
    <div
      className={styles['main-container']}
      onClick={() => {
        if (props.model.adapt)
          selContext(props.model.adapt, () => {
            /* NYI */
          });
      }}
    >
      <div className={styles['container']}>
        <div className={styles['icon-container']}>
          {props.topLine && <VerticalLine className={styles['line']} />}
          {!props.topLine && <div className={styles['line']} />}
          <Icon className={styles['icon']} />
          {props.bottomLine && <VerticalLine className={styles['line']} />}
          {!props.bottomLine && <div className={styles['line']} />}
        </div>
        <div className={styles['text-container']}>
          <InterweaveExt className={styles['text']} content={props.model.title} />
        </div>
      </div>
      <div className={styles['container']}>
        <div className={styles['icon-container']}>
          {props.bottomLine && <VerticalLine className={styles['line']} />}
          {!props.bottomLine && <div className={styles['line']} />}
        </div>
        <div className={styles['add-text-container']}>
          {props.model.comment && (
            <div className={styles['comment-text']}>{props.model.comment}</div>
          )}
          {props.model.change && <div className={styles['changes-text']}>{props.model.change}</div>}
        </div>
      </div>
    </div>
  );
}

export default TreatmentPathItem;
