import { LanguageTextResourceMap } from '@sqior/js/language';

export enum RoleTextResources {
  NeuromonitoringDirector = 'neuromonitoring_director',
  NeuromonitoringEmployee = 'neuromonitoring_employee',
}

export const UserTextResources: LanguageTextResourceMap = {
  de: {
    anesthesia_nurse: {
      '': 'AnästhesiepflegerIn',
      male: 'Anästhesiepfleger',
      female: 'Anästhesiepflegerin',
    },
    anesthesia_nurse_director: {
      '': 'AnästhesiepflegeleiterIn',
      male: 'Anästhesiepflegeleiter',
      female: 'Anästhesiepflegeleiterin',
    },
    anesthesia_nurse_ward_lead: {
      '': 'AnästhesiepflegestationsleiterIn',
      male: 'Anästhesiepflegestationsleiter',
      female: 'Anästhesiepflegestationsleiterin',
    },
    anesthesia_nurse_group_lead: {
      '': 'AnästhesiepflegegruppenleiterIn',
      male: 'Anästhesiepflegegruppenleiter',
      female: 'Anästhesiepflegegruppenleiterin',
    },
    anesthesist_group_lead: {
      '': 'Anästhesisten-Gruppenleitung',
      male: 'Anästhesisten-Gruppenleitung',
      female: 'Anästhesisten-Gruppenleitung',
    },
    anesthesist_director: {
      '': 'Anästhesie-Oberarzt/Ärztin',
      male: 'Anästhesie-Oberarzt',
      female: 'Anästhesie-Oberärztin',
    },
    anesthesist: { '': 'AnästhesistIn', male: 'Anästhesist', female: 'Anästhesistin' },
    by_role: 'vom/von der <e case=dative-soft>role</e>',
    by_user: 'von <e>user</e>',
    case_manager: { '': 'Case-ManagerIn', male: 'Case-Manager', female: 'Case-Managerin' },
    cleaning_staff: {
      '': 'ReinigungsmitarbeiterIn',
      male: 'Reinigungsmitarbeiter',
      female: 'Reinigungsmitarbeiterin',
    },
    new_employee: {
      '': 'Neuer MitarbeiterIn',
      male: 'Neuer Mitarbeiter',
      female: 'Neue Mitarbeiterin',
    },
    sqior_employee: {
      '': 'sqior MitarbeiterIn',
      male: 'sqior Mitarbeiter',
      female: 'sqior Mitarbeiterin',
    },
    holding_employee: {
      '': 'Holding-MitarbeiterIn',
      male: 'Holding-Mitarbeiter',
      female: 'Holding-Mitarbeiterin',
    },
    neuromonitoring_director: {
      '': 'IOM-Arzt/Ärztin',
      male: 'IOM-Arzt',
      female: 'IOM-Ärztin',
    },
    neuromonitoring_employee: {
      '': 'IOM-MTAF',
      male: 'IOM-MTAF',
      female: 'IOM-MTAF',
    },
    no_location_assigned: 'Kein Raum zugeordnet',
    occupancy_manager: {
      '': 'BelegungsmanagerIn',
      male: 'Belegungsmanager',
      female: 'Belegungsmanagerin',
    },
    or_coordinator: { '': 'OP-KoordinatorIn', male: 'OP-Koordinator', female: 'OP-Koordinatorin' },
    or_manager: { '': 'OP-ManagerIn', male: 'OP-Manager', female: 'OP-Managerin' },
    or_disponent: { '': 'OP-DisponentIn', male: 'OP-Disponent', female: 'OP-Disponentin' },
    or_disponent_lead: {
      '': 'OP-DisponentenleiterIn',
      male: 'OP-Disponentenleiter',
      female: 'OP-Disponentenleiterin',
    },
    or_nurse: { '': 'OP-PflegerIn', male: 'OP-Pfleger', female: 'OP-Pflegerin' },
    or_nurse_group_lead: {
      '': 'OP-PflegegruppenleiterIn',
      male: 'OP-Pflegegruppenleiter',
      female: 'OP-Pflegegruppenleiterin',
    },
    or_nurse_director: {
      '': 'OP-PflegeleiterIn',
      male: 'OP-Pflegeleiter',
      female: 'OP-Pflegeleiterin',
    },
    or_spectator: { '': 'OP-BeobachterIn', male: 'OP-Beobachter', female: 'OP-Beobachterin' },
    physician: { '': 'Arzt/Ärztin', male: 'Arzt', female: 'Ärztin' },
    physician_on_duty: { '': 'Dienstarzt/Ärztin', male: 'Dienstarzt', female: 'Dienstärztin' },
    recovery_employee: {
      '': 'AWR-MitarbeiterIn',
      male: 'AWR-Mitarbeiter',
      female: 'AWR-Mitarbeiterin',
    },
    role_of_location: '<e>role</e> von <e>loc</e>',
    role_of_location_of_specialty: '<e>role</e> von <e>loc</e> (<e>spec</e>)',

    role_user: '<e>user</e> als <e>role</e>',
    sds_employee: { '': 'SDS-MitarbeiterIn', male: 'SDS-Mitarbeiter', female: 'SDS-Mitarbeiterin' },
    specialtyrole_of_location: '<e>specialtyrole</e> von <e>loc</e>',
    super_user: { '': 'Super-BenutzerIn', male: 'Super-Benutzer', female: 'Super-Benutzerin' },
    surgeon: {
      '': 'Operierende(r) Arzt/Ärztin',
      male: 'Operierender Arzt',
      female: 'Operierende Ärztin',
      'dative-soft': 'operierenden Arzt/Ärztin',
      'dative-soft-male': 'operierenden Arzt',
      'dative-soft-female': 'operierenden Ärztin',
    },
    tester: { '': 'TesterIn', male: 'Tester', female: 'Testerin' },
    transport_service: {
      '': 'TransportmitarbeiterIn',
      male: 'Transportmitarbeiter',
      female: 'Transportmitarbeiterin',
    },
    user: { '': 'BenutzerIn', male: 'Benutzer', female: 'Benutzerin' },
    user_unknown: 'unbekannt',
    ward_nurse: { '': 'StationspflegerIn', male: 'Stationspfleger', female: 'Stationspflegerin' },
    ward_control: 'Leitstelle',
    ward_control_with_loc: 'Leitstelle von <e>loc</e>',
  },
};

export const UserClientTextResources: LanguageTextResourceMap = {
  de: {
    deselect_all_roles: 'Alle Rollen abwählen',
    enter_password_instead: 'Stattdessen Passwort eingeben',
    pin_change: 'PIN-Code abändern',
    pin_change_failed: 'PIN-Änderung fehlgeschlagen',
    pin_changed: 'PIN-Code gesetzt',
    pin_confirm: 'Neuen PIN-Code wiederholen',
    pin_definition: 'PIN-Code festlegen',
    pin_enter: 'Neuen PIN-Code eingeben',
    pin_enter_old: 'Alten PIN-Code eingeben',
    pin_incorrect: 'PIN-Code inkorrekt',
    pin_not_match: 'PIN-Codes verschieden',
    pin_verification_failed: 'PIN-Verifikation fehlgeschlagen',
    select_all_roles: 'Alle Rollen anwählen',
    team_header: 'Team:',
  },
};
