/** Enum specifying abstract colors */

// similar to: https://learn.microsoft.com/en-us/dotnet/api/system.windows.media.colors?view=windowsdesktop-8.0

export enum ColorCodes {
  Green = 'green',
  Yellow = 'yellow',
  Pink = 'pink',
  Red = 'red',
  Orange = 'orange',
  Purple = 'purple',
  Violet = 'violet',
  Blue = 'blue',
  Cyan = 'cyan',
  White = 'white',
  Gray = 'gray',
  Salmon = 'salmon',
  Brown = 'brown',
  LightGreen = 'light-green',
  Teal = 'teal',

  // Color codes for a green-red-scale with 4 level, level 1=green, level 4 = red
  ScaleRG4Level1 = 'scale-rg4-level1',
  ScaleRG4Level2 = 'scale-rg4-level2',
  ScaleRG4Level3 = 'scale-rg4-level3',
  ScaleRG4Level4 = 'scale-rg4-level4',

  ScaleRG3Level1 = 'scale-rg3-level1',
  ScaleRG3Level2 = 'scale-rg3-level2',
  ScaleRG3Level3 = 'scale-rg3-level3',
}
