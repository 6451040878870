import { LogTextData, TextLogBackend } from './text-log-backend';
import { Emitter } from '@sqior/js/event';

export type RetainedLogData = { sequenceNumber: number } & LogTextData;

/** Log backend storing log entries up to a certain limit */
export class LogRetainer extends TextLogBackend {
  constructor(capacity = 100, logs: RetainedLogData[] = []) {
    super();
    this.capacity = capacity;
    this.sequenceNumber = logs.length ? logs[logs.length - 1].sequenceNumber + 1 : 0;
    this.logs = logs;
  }

  /** Log backend implementation */
  logText(data: LogTextData) {
    /* Purge items, if applicable */
    this.logs =
      this.logs.length >= this.capacity
        ? this.logs.slice(1 + this.logs.length - this.capacity)
        : [...this.logs];
    /* Add to the end */
    this.logs.push({ sequenceNumber: this.sequenceNumber++, ...data });
    /* Signal that a log was added */
    this.added.emit(this.logs[this.logs.length - 1]);
  }

  /** Purges all retained log entires up to (and including) a certain sequence number */
  purge(sequenceNumber: number) {
    this.logs = this.logs.filter((entry) => {
      return entry.sequenceNumber > sequenceNumber;
    });
  }

  readonly capacity: number;
  private sequenceNumber: number;
  logs: RetainedLogData[];
  readonly added = new Emitter<[RetainedLogData]>();
}
