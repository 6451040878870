import { SvgButton } from '@sqior/react/uibase';
import { ReactComponent as Logo } from './send.svg';
import styles from './input-mainbutton.module.css';
import { classes } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface InputMainButtonProps {
  onClick?: () => void;
  enabled?: boolean;
}

export function InputMainButton(props: InputMainButtonProps) {
  return (
    <SvgButton
      onClick={props.onClick}
      className={classes(styles['mainbutton'], props.enabled ? undefined : styles['disabled'])}
    >
      <Logo fill="#ffffff" viewBox="0 0 40 40" />
    </SvgButton>
  );
}

export default InputMainButton;
