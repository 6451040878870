import React, {
  useRef,
  useState,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useContext,
  MouseEvent,
} from 'react';
import { ButtonX } from '@sqior/react/uibase';
import {
  useGetCursorPosition,
  ConversationEntities,
} from '../conversation-entities/conversation-entities';
import { OperationContext } from '@sqior/react/operation';
import { UploadFile } from '@sqior/js/media';
import { Bytes } from '@sqior/js/data';
import { InputControlLogic } from './input-control-logic';
import { classes, ErrorBoundary } from '@sqior/react/utils';
import { InputChain } from './input-control-processor';
import styles from './input-control.module.css';

export interface InputControlProps {
  icLogic: InputControlLogic;
  onInputTextChanged?: (text: string) => void; // Callback if text representation changed (typically because of editing)
}

export function InputControl(props: InputControlProps) {
  const enableDebugging = false;
  const icLogic = props.icLogic;

  // DOM reference to input DIV
  const inputDivRef = useRef<HTMLDivElement>(null);
  icLogic.setReferenceToDiv(inputDivRef);
  useEffect(() => {
    icLogic.afterRender();
  });

  const dispatcher = useContext(OperationContext);

  const [isComposing, setComposing] = useState(false);
  const [inputChainCache, setInputChainCache] = useState<InputChain>([]);

  console.log('icLogic InputChain', icLogic.inputChain);
  useEffect(() => {
    if (!isComposing) {
      console.log('setInputChainCache', icLogic.inputChain);
      setInputChainCache(icLogic.inputChain);
    }
  }, [icLogic.inputChain, isComposing]);

  const renderInputChain = isComposing ? inputChainCache : icLogic.inputChain;
  console.log('render InputChain', renderInputChain);

  // Current cursor position (might return invalid if clicked outside the inputDiv)
  const [curPos] = useGetCursorPosition(inputDivRef);
  icLogic.setCurPosUI(curPos);

  function handleFocusPreview(e: FocusEvent) {
    console.log('handleFocusPreview', e);
    icLogic.focusAndReposition();
    icLogic.setFocus(true);
  }

  function handleFocusEdit(e: FocusEvent) {
    // FocusEvent typically is set to sub-controls (childs). This leads to the effect that the cursor gets stuck at the begin or end of the correspdoning child element.
    // => If that's the case, this method sets the focus to the parent so that the cursor movement behaves correctly.
    console.log('handleFocusEdit', e.target, e.currentTarget, e.relatedTarget);

    inputDivRef.current?.focus();
    icLogic.setFocus(true);
  }

  function handleClickInDiv() {
    // (Re-)Signal editing status
    icLogic.setFocus(true);
  }

  // If focus leaves the InputControl => Invalidate lastCurPos and set focusInInputDiv to false => Suggestions will disappear
  function handleBlur(e: FocusEvent<HTMLDivElement>) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // e.relatedTarget points to the element getting the focus => check whether outside of input-control (e.currentTarget)
      icLogic.setFocus(false);
    }
  }

  function cancelInput(e?: MouseEvent) {
    icLogic.cancel();
    e?.preventDefault();
  }

  function onInputDiv(e: React.FormEvent<HTMLDivElement>) {
    const text = e.currentTarget.innerText.replace(/\n/g, '');
    console.log(`onInputDiv: '${text}'`, e);
    icLogic.setEditText(text);
  }

  function onKeyDown(e: KeyboardEvent<HTMLElement>) {
    icLogic.handleKeyDown(e);

    if (e.isDefaultPrevented()) return;

    // Perform default actions associated with key presses
    if (e.key === 'Enter') {
      icLogic.sendFinal();
    } else if (e.key === 'Escape') {
      cancelInput();
    }
  }

  /* For drag and drop of files */
  function onDragOver(ev: React.DragEvent) {
    if (ev.dataTransfer.files.length > 0) ev.preventDefault();
  }
  function onDrop(ev: React.DragEvent) {
    ev.preventDefault();
    for (let i = 0; i < ev.dataTransfer.files.length; i++)
      dispatcher.start(
        UploadFile(
          ev.dataTransfer.files[i].name,
          ev.dataTransfer.files[i].lastModified,
          ev.dataTransfer.files[i].type,
          new Bytes(ev.dataTransfer.files[i])
        )
      );
  }

  function onCompositionStart(e: React.CompositionEvent) {
    console.log(`onCompositionStart(${e.data})`, e);
    setComposing(true);
    icLogic.setComposing(true);
  }
  function onCompositionUpdate(e: React.CompositionEvent) {
    console.log(`onCompositionUpdate(${e.data})`, e);
    setComposing(true);
    icLogic.setComposing(true);
  }
  function onCompositionEnd(e: React.CompositionEvent) {
    console.log(`onCompositionEnd(${e.data})`, e);
    setComposing(false);
    icLogic.setComposing(false);
  }

  icLogic.beforeRender();
  const [closestInputPart] = icLogic.getClosestInputPartWithItem() || [undefined];

  const displayCancel = icLogic.editActive;

  return (
    <div
      tabIndex={0}
      className={styles['input-control-root']}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <div className={styles['input-editor-frame']}>
        <div className={styles['input-editor']}>
          <div
            className={classes(styles['input-editor-preview'], styles['input-editor-common'])}
            onFocus={handleFocusPreview}
          >
            <ErrorBoundary>
              <ConversationEntities
                input={icLogic.inputChain}
                activeInputPart={closestInputPart}
                debug={enableDebugging}
                renderPropsal={true}
              />
            </ErrorBoundary>
          </div>

          <div
            tabIndex={0}
            className={classes(styles['input-editor-edit'], styles['input-editor-common'])}
            contentEditable
            suppressContentEditableWarning
            ref={inputDivRef}
            onFocus={handleFocusEdit}
            onBlur={handleBlur}
            onClick={handleClickInDiv}
            onInput={onInputDiv}
            onKeyDown={onKeyDown}
            onCompositionStart={onCompositionStart}
            onCompositionUpdate={onCompositionUpdate}
            onCompositionEnd={onCompositionEnd}
          >
            <ErrorBoundary>
              <ConversationEntities
                input={renderInputChain}
                activeInputPart={closestInputPart}
                debug={enableDebugging}
              />
            </ErrorBoundary>
          </div>
        </div>
        {displayCancel && (
          <ButtonX onClick={cancelInput} additionalClassName={styles['cancelbutton']} />
        )}
      </div>
      {enableDebugging && (
        <div className={styles['debug-div']}>
          <div>
            <pre>UI: {JSON.stringify(icLogic.curPosUI)}</pre>
            <pre>LAST: {JSON.stringify(icLogic.lastCurPos)}</pre>
            <pre>DESW: {JSON.stringify(icLogic.curPosDesired)}</pre>
            <pre>DESE: {JSON.stringify(icLogic.curPosDesiredEffective)}</pre>
            <pre>MANI: {icLogic.lastManipulationType}</pre>
            <pre>FOCU: {icLogic.editActive ? 'edit active' : 'edit not active'}</pre>
            <pre>REND: '{icLogic.renderedText}'</pre>
            <pre>EDIT: '{icLogic.inputStateLocalAsText}'</pre>
            {<pre>{JSON.stringify(icLogic.inputChain, null, 2)}</pre>}
          </div>
        </div>
      )}
    </div>
  );
}

export default InputControl;
