import { TestAuthProvider } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { AuthConfirmTriggerType, AuthContext, AuthFrame } from '@sqior/react/uiauth';
import { KeycloakConfirmProvider, OAuthWrapper } from '@sqior/react/uiauthkc';
import { MainFrame } from '@sqior/react/uibase';
import { VersionInfo } from '@sqior/react/utils';
import CoreServices, { CoreServicesConfig } from '../core-services/core-services';

export enum AuthenticationSystem {
  None,
  Auth0,
  OAuth,
  KeycloakConfirm,
}
export type AppConfig = {
  core: CoreServicesConfig;
  auth: AuthenticationSystem;
  authScopes?: string[];
};

export interface AppFrameProps {
  children: React.ReactNode;
  config: AppConfig;
  version: VersionInfo;
}

const keycloakConfirmProvider = new KeycloakConfirmProvider();

export function AppFrame(props: AppFrameProps) {
  /* Check if a test user is specified in the URL */
  const params = new URLSearchParams(window.location.search);

  const testUser = params.get('testUser');
  const kiosk = params.get('kiosk');
  const token = params.get('token');

  /* Do not instantiate auth0 if window.crypto is not available which is the case with jsdom */
  if (window.crypto && props.config.auth === AuthenticationSystem.Auth0)
    return <h1>Auth0 is disabled!</h1>;
  /*
    return (
      <AuthWrapper>
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </AuthWrapper>
    );
  */ else if (testUser || (kiosk && token))
    return (
      <AuthContext.Provider
        value={{
          provider: new TestAuthProvider(
            testUser ? 'test-' + testUser : 'kiosk-' + kiosk + '-' + token
          ),
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        <MainFrame>
          <CoreServices config={props.config.core} version={props.version}>
            {props.children}
          </CoreServices>
        </MainFrame>
      </AuthContext.Provider>
    );
  else if (props.config.auth === AuthenticationSystem.OAuth)
    return (
      <OAuthWrapper scopes={props.config.authScopes}>
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core} version={props.version}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </OAuthWrapper>
    );
  else if (props.config.auth === AuthenticationSystem.KeycloakConfirm)
    return (
      <OAuthWrapper authProvider={keycloakConfirmProvider} scopes={props.config.authScopes}>
        <div />
      </OAuthWrapper>
    );
  else
    return (
      <MainFrame>
        <CoreServices config={props.config.core} version={props.version}>
          {props.children}
        </CoreServices>
      </MainFrame>
    );
}

export default AppFrame;
