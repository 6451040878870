import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { ColorCodes, DashboardStatePath } from '@sqior/viewmodels/visual';
import { EmergencyClassificationVM } from './orworkflow-overview';
import { CaveSeverity, SpecialtyVM } from '@sqior/viewmodels/patient';
import { DateData } from '@sqior/viewmodels/time';
import { joinPath } from '@sqior/js/url';
import { DailyStartConfig, TransferPriorityGroupVM } from '@sqior/viewmodels/location';

export const ORWorkflowDashboardType = 'ORWorkflowDashboard';
export const OrWorkflowOverviewDashboardResponsive = 'ORWorkflowOverviewDashboardResponsive';
export const ORWorkflowOverviewORItemType = 'ORWorkflowOverviewORItem';
export const ORWorkflowOverviewProcedureType = 'ORWorkflowOverviewProcedure';
export const ORWorkflowOverviewPreliminaryProcedureType = 'ORWorkflowOverviewPreliminaryProcedure';
export const ORWorkflowOverviewSutureIncisionDurationType =
  'ORWorkflowOverviewSutureIncisionDuration';

/* Data describing the overview dashboard */
export type ORWorkflowOverviewDashboardComponent = EntityHeader & { id: string };
export enum ORWorkflowOverviewDashboardMarkerType {
  Start = 'start',
  End = 'end',
}
export enum ORWorkflowOverviewDashboardMarkerGroup {
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}
export type ORWorkflowOverviewDashboardMarker = {
  label: string;
  timestamp: ClockTimestamp;
  type: ORWorkflowOverviewDashboardMarkerType;
  group: ORWorkflowOverviewDashboardMarkerGroup;
};

export type ORWorkflowOverviewDashboardORItemData = EntityHeader & {
  component: ORWorkflowOverviewDashboardComponent;
  start: ClockTimestamp;
  end: ClockTimestamp;
  extension?: ClockTimestamp;
  markers: ORWorkflowOverviewDashboardMarker[];
  opaque: boolean;
};

export type ProgressData = {
  color?: ColorCodes;
  active: boolean;
};

export type DailyStartHeaderItem = EntityHeader & {
  automatic: boolean;
  header: string;
  title: string;
  shortTitle?: string;
  timeRange?: string;
  detail?: string;
  shortDetail?: string;
  progress?: ProgressData[];
  fadeOut?: boolean;
};

export type ORWorkflowOverviewDashboardORData = EntityHeader & {
  name: string;
  items: ORWorkflowOverviewDashboardORItemData[];
  specialty?: SpecialtyVM;
  dailyStartConfig?: DailyStartConfig;
  location: Entity;
  headerItem?: DailyStartHeaderItem;
};
export type ORWorkflowOverviewDashboardData = EntityHeader & {
  ors: ORWorkflowOverviewDashboardORData[]; // Operating rooms to display
  date: DateData; // Date of the data displayed
  interval: { startHour: number; endHour: number }; // Hours of the OR schedule that shall be displayed (e.g. 0 to 30 means 00:00 to 06:00 of the next day)
  core: { startHour: number; endHour: number }; // Core hours of the OR schedule
  target?: { hours: number; minutes: number }; // Target time to optimize the schedule for
  preliminaryProcedures?: ORWorkflowOverviewDashboardProcedureData[]; // If this is not set, preliminary procedures are not supported, if empty, there are no preliminary procedures present
};
export enum ORWorkflowOverviewDashboardState {
  Completed = 'completed',
  Running = 'running',
  Planned = 'planned',
}
export enum ORWorkflowOverviewDashboardStage {
  Normal = 'normal',
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}

export type CountdownType = ClockTimestamp | number | 'Infinity';

export type ORWorkflowOverviewDashboardProcedureData = ORWorkflowOverviewDashboardComponent & {
  name: string; // Patient name
  sex: string; // Patient sex symbol
  dob: string; // Patient date of birth
  description: string; // Procedure description
  state: ORWorkflowOverviewDashboardState; // Procedure state (planned, running, completed)
  duration?: number; // Procedure duration in min
  step: string; // Description of current step
  stage: ORWorkflowOverviewDashboardStage; // Stage of procedure
  countdown?: CountdownType; // Countdown timer to be display, if applicable
  countdownDuration?: number; // Duration of the countdown timer in min
  team?: string[]; // Names of the surgeons
  caveSeverity?: CaveSeverity;
  specialty?: SpecialtyVM; // Specialty of the procedure (e.g. Orthopedics)
  emergencyClassification?: EmergencyClassificationVM;
  prioGroup?: TransferPriorityGroupVM;
  border?: {
    color: string;
    opacity: number;
  };
  select: OperationSpec<ValueObject>;
  shortcutCommand?: {
    text: string;
    select: OperationSpec;
  };
  preSurgicalWard?: string;
  postSurgicalWard?: string;
  postSurgicalCareLevel?: string;
  challenged?: boolean; // Flag if there are challenges with this procedure, so that some kind of warning indicator shall be displayed
};
export type ORWorkflowOverviewSutureIncisionDurationData = ORWorkflowOverviewDashboardComponent & {
  duration: number; // Suture to incision of the next procedure duration in min
};

/** Interactive dashboard */

export const ORWorkflowInteractiveDashboardType = 'ORWorkflowInteractiveDashboard';
export type ORWorkflowInteractiveDashboardClusterData = {
  id: string;
  label: string;
};
export type ORWorkflowInteractiveDashboardClusterSelectionData = {
  clusters: ORWorkflowInteractiveDashboardClusterData[];
  selected: number;
};
export type ORWorkflowInteractiveDashboardData = EntityHeader & {
  date: DateData;
  prevDate: boolean;
  nextDate: boolean;
  clusterSelection?: ORWorkflowInteractiveDashboardClusterSelectionData;
  reportURL?: string;
  dashboard?: Entity;
  oldDashboard?: Entity;
};

export enum ORWorkflowInteractiveDashboardPaths {
  NextDay = 'next',
  PrevDay = 'prev',
  SelectCluster = 'selectCluster',
}
export function NextDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.NextDay),
    data: '',
  };
}
export function PrevDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.PrevDay),
    data: '',
  };
}

/** Selects the cluster for display in the interactive OR dashboard */
export function SelectORWorkflowInteractiveDashboardCluster(
  cluster: string
): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(DashboardStatePath, ORWorkflowInteractiveDashboardPaths.SelectCluster),
    data: cluster,
  };
}
