import { OperationSpec, OperationType } from '@sqior/js/operation';

export const PhoneNumberEntryPath = 'phone-number-entry';

export function EnterPhoneNumber(number: string): OperationSpec<string> {
  return { type: OperationType.Add, path: PhoneNumberEntryPath, data: number };
}
export function ClosePhoneNumber(): OperationSpec<string> {
  return EnterPhoneNumber('');
}
