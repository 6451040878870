import styles from './metric-card-header.module.css';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface MetricCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  legend?: React.ReactNode;
}

export function MetricCardHeader({ title, legend, ...rest }: MetricCardHeaderProps) {
  return (
    <div className={styles['title-container']} {...rest}>
      <div className={styles['title']}>{title}</div>

      <div className={styles['legend-more-button']}>
        <div className={styles['legend-container']}>{legend}</div>

        <div className={styles['more-icon']}>
          <IconButton>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default MetricCardHeader;
