import styles from './combined-spiand-specialties.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { WardSPIAndSpecialtiesInfoEntity } from '@sqior/viewmodels/occupancy';
import BedChartStatistic from '../ward-overview-dashboard/components/bed-chart-statistic/bed-chart-statistic';
import SPIChart from '../ward-overview-dashboard/components/SPIChart/spi-chart';
import FreeBedsView from '../free-beds-view/free-beds-view';

export type CombinedSPIAndSpecialtiesProps = FactoryProps<WardSPIAndSpecialtiesInfoEntity>;

export function CombinedSPIAndSpecialties({ data }: CombinedSPIAndSpecialtiesProps) {
  const { specialties, spiStats } = data;

  return (
    <div className={styles['container']} style={{ width: 250 }}>
      <SPIChart {...spiStats} />
      <FreeBedsView {...specialties} />
      <BedChartStatistic data={specialties} />
    </div>
  );
}

export default CombinedSPIAndSpecialties;
