import { Bytes, ClockTimestamp } from '@sqior/js/data';
import { OperationSpec, OperationType } from '@sqior/js/operation';

/* Specification of an upload file operation */
export type FileInfo = { title: string; timestamp: ClockTimestamp; type: string; data: Bytes };
export function UploadFile(
  title: string,
  timestamp: number,
  type: string,
  data: Bytes
): OperationSpec<FileInfo> {
  return {
    type: OperationType.Add,
    path: 'upload',
    data: { title: title, timestamp: timestamp, type: type, data: data },
  };
}
