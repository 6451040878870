import { BiChannel } from '@sqior/js/message';
import { isFinal, Operation, OperationHandler, StreamOperation } from '@sqior/js/operation';
import {
  OperationMessageType,
  OperationProgressMessage,
  OperationRequestMessage,
  OperationStateMessage,
  OperationStreamMessage,
} from './operation-message';

export class OperationSender implements OperationHandler {
  constructor(channel: BiChannel) {
    this.channel = channel;
    this.count = 1;
    this.ops = new Map<number, Operation>();
    /* Listen for a close of the channel, clear out all operations in this case */
    this.channel.onClose(() => {
      this.clear();
    });
    /* Listen for progress messages */
    this.channel.in.on<OperationProgressMessage>(OperationMessageType.Progress, (progMsg) => {
      const op = this.ops.get(progMsg.id);
      if (op) op.setProgress(progMsg.progress);
    });
    /* Listen for result messages */
    this.channel.in.on<OperationStateMessage>(OperationMessageType.State, (resMsg) => {
      const op = this.ops.get(resMsg.id);
      if (op) {
        op.completeFromJSON(resMsg.data);
        if (isFinal(op.state)) this.ops.delete(resMsg.id);
      }
    });
  }

  clear() {
    for (const op of this.ops) op[1].fail();
    this.ops.clear();
  }

  handle(op: Operation, path = '') {
    /* Do not handle if channels are closed */
    if (!this.channel.allOpen) {
      op.fail();
      return;
    }
    /* Keep message */
    const id = this.count++;
    this.ops.set(id, op);
    /* Register for stream messages if this is a streaming operation */
    if (op instanceof StreamOperation)
      op.stream.on((value) => {
        if (this.channel.out.isOpen)
          this.channel.out.send<OperationStreamMessage>({
            type: OperationMessageType.Stream,
            id: id,
            data: value,
          });
      });
    /* Send operation */
    this.channel.out.send<OperationRequestMessage>({
      type: OperationMessageType.Request,
      opType: op.type,
      id: id,
      path: path,
      data: op.toJSON(),
    });
  }

  private channel: BiChannel;
  private count: number;
  private ops: Map<number, Operation>;
}
