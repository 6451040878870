import { clone, isEqual, shallowClone } from '@sqior/js/data';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import { PatientOverview } from '@sqior/viewmodels/patient';
import {
  ChecklistConfirmInfo,
  ChecklistConfirmRole,
  ChecklistSetInfo,
  ChecklistViewModel,
  ChecklistViewModelType,
} from './checklist-vm';

export function ChecklistItemOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  const resp = op.data as ChecklistSetInfo;
  /* Create overlay checking the item if not checked yet */
  const overlay = StateOverlay.create<PatientOverview>(
    (value) => {
      /* Check if the content actually is a checklist */
      const checklist = value.content as ChecklistViewModel;
      if (
        checklist &&
        checklist.entityType === ChecklistViewModelType &&
        isEqual(checklist.ref, resp.ref)
      ) {
        const section = checklist.sections[resp.section];
        if (section) {
          const paragraph = section.paragraphs[resp.paragraph];
          if (paragraph) {
            const item = paragraph.items[resp.item];
            if (item) {
              const option = item.options[resp.option];
              if (option && option.checked !== resp.check) {
                const newChecklist = shallowClone(checklist);
                newChecklist.sections = shallowClone(newChecklist.sections);
                newChecklist.sections[resp.section] = shallowClone(section);
                newChecklist.sections[resp.section].paragraphs = shallowClone(
                  newChecklist.sections[resp.section].paragraphs
                );
                newChecklist.sections[resp.section].paragraphs[resp.paragraph] =
                  shallowClone(paragraph);
                newChecklist.sections[resp.section].paragraphs[resp.paragraph].items = shallowClone(
                  newChecklist.sections[resp.section].paragraphs[resp.paragraph].items
                );
                const newItem = clone(item);
                for (let i = 0; i < newItem.options.length; i++)
                  newItem.options[i].checked = i === resp.option ? resp.check : false;
                newChecklist.sections[resp.section].paragraphs[resp.paragraph].items[resp.item] =
                  newItem;
                const res = shallowClone(value);
                res.content = newChecklist;
                return res;
              }
            }
          }
        }
      }
      /* Disable the overlay if the check is no longer found or has the correct state */
      setTimeout(() => overlay.clear(), 0);
      return value;
    },
    { info: { entityType: '', name: '' } }
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
  });

  return overlay;
}

export function ChecklistSectionOverlay(op: Operation) {
  if (!(op instanceof AddOperation)) return undefined;
  const resp = op.data as ChecklistConfirmInfo;
  /* Create overlay checking the item if not checked yet */
  const overlay = StateOverlay.create<PatientOverview>(
    (value) => {
      /* Check if the content actually is a checklist */
      const checklist = value.content as ChecklistViewModel;
      if (
        checklist &&
        checklist.entityType === ChecklistViewModelType &&
        isEqual(checklist.ref, resp.ref)
      ) {
        const section = checklist.sections[resp.section];
        if (
          section &&
          ((!section.completed && resp.role !== ChecklistConfirmRole.Prep) ||
            (!section.prepped && resp.role === ChecklistConfirmRole.Prep))
        ) {
          const newChecklist = shallowClone(checklist);
          newChecklist.sections = shallowClone(newChecklist.sections);
          newChecklist.sections[resp.section] = shallowClone(section);
          if (resp.role !== ChecklistConfirmRole.Prep)
            newChecklist.sections[resp.section].completed = true;
          else newChecklist.sections[resp.section].prepped = true;
          const res = shallowClone(value);
          res.content = newChecklist;
          return res;
        }
      }
      /* Disable the overlay if the check is no longer found or has the correct state */
      setTimeout(() => overlay.clear(), 0);
      return value;
    },
    { info: { entityType: '', name: '' } }
  );

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
  });

  return overlay;
}
