import { PluginContext } from '@sqior/js/plugin';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import { OccupancyAnalyticsDashboard } from '@sqior/viewmodels/analytics-dashboard';
import ReactUiAnalytics from './react-ui-analytics';

/** Plugin for react components related to treatment path handling */

export function UIAnalyticsPlugin(context: PluginContext & FactoryContext & StateOverlayContext) {
  context.plugins.require(UIVisualPlugin, context);
  /* Factory components */
  context.factory.add(OccupancyAnalyticsDashboard, ReactUiAnalytics);
}
