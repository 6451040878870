import React, { FC, useContext, useState } from 'react';
import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import styles from './bed-view.module.css';
import { OperationContext } from '@sqior/react/operation';
import { OperationSpec } from '@sqior/js/operation';
import { ValueObject } from '@sqior/js/data';
import { PersonSex } from '@sqior/viewmodels/person';
import { useUIGlobalState } from '@sqior/react/state';
import { motion } from 'framer-motion';
import NextBedView from '../next-bed-view/next-bed-view';
import { SqiorBedLineView } from '@sqior/react/uibase';
import CurrentBedView from '../current-bed-view/current-bed-view';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

export enum PillColor {
  Male = '#1CADE4',
  Female = '#ED6F8D',
  Unisex = '#AEB0BD',
}

type Props = {
  bed: BedAllocationWardListItemBed;
};

const MINIMAL_HEIGHT = 55;
const EXPANDED_HEIGHT = 75;

const NEXT_BED_HEIGHT = 18;

const BedView: FC<Props> = ({ bed }) => {
  const { UIGlobalState } = useUIGlobalState();
  const dispatcher = useContext(OperationContext);

  const [selectedId, setSelectedId] = useState<string>('');

  const onBedClick = () => {
    if (!bed) return;
    const currentPatientId = getCurrentPatientId(bed);
    const otherPatientId = getOtherPatientId(bed);

    if (hasDoubleAllocation(bed) && otherPatientId && selectedId === currentPatientId) {
      const otherSelect = createSelectionForOtherPatient(bed);
      if (!otherSelect) return;
      setSelectedId(otherPatientId);
      dispatcher.start(otherSelect);
      return;
    }
    if (!currentPatientId || !bed.select) return;
    setSelectedId(currentPatientId);
    dispatcher.start(bed.select);
  };

  const getSexColor = () => {
    if (bed?.current.sex === PersonSex.Male) return PillColor.Male;
    if (bed?.current.sex === PersonSex.Female) return PillColor.Female;
    return PillColor.Unisex;
  };

  const getShowCount = () => {
    const values: boolean[] = [
      UIGlobalState.bmShowSpeciality,
      UIGlobalState.bmShowInsurance,
      UIGlobalState.bmShowSpi,
    ];

    return values.filter((value) => value).length;
  };

  const showCount = getShowCount();

  const expanded = showCount > 1;

  const getBackgroundColor = () => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return 'rgb(35,44,75)';
      default:
        return undefined;
    }
  };

  const getBorderColor = () => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return 'rgba(0,0,0,0.29)';
      default:
        return undefined;
    }
  };

  const getWhileTab = () => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return undefined;
      case BedAllocationWardListItemBedState.Blocked:
        return undefined;
      default:
        return { scale: 0.95 };
    }
  };

  const getLineVisibility = (): boolean | undefined => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Blocked:
        return false;
      default:
        return undefined;
    }
  };

  return (
    <motion.div
      whileTap={getWhileTab()}
      onMouseDown={onBedClick}
      layout="preserve-aspect"
      className={styles['container-wrapper']}
    >
      <div
        className={styles['container']}
        style={{
          height: expanded ? EXPANDED_HEIGHT : MINIMAL_HEIGHT,
          backgroundColor: getBackgroundColor(),
          borderColor: getBorderColor(),
        }}
      >
        <div className={styles['content']}>
          <SqiorBedLineView backgroundColor={getSexColor()} visibility={getLineVisibility()} />
          <CurrentBedView bed={bed} expanded={expanded} />
        </div>

        <div
          className={styles['room-index']}
          style={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          {hasDoubleAllocation(bed) && (
            <SupervisorAccountOutlinedIcon
              style={{
                fontSize: 11,
                color: 'rgba(255, 255, 255, 0.5)',
              }}
            />
          )}
          {bed.current.dischargeInfo?.annotation && <div className={styles['bubble']} />}
          <div>{bed.label}</div>
        </div>
      </div>
      <div
        className={styles['next-bed-wrapper']}
        style={{
          height: NEXT_BED_HEIGHT / 2,
        }}
      >
        <NextBedView bed={bed} height={NEXT_BED_HEIGHT} />
      </div>
    </motion.div>
  );
};

export default BedView;

const hasDoubleAllocation = (bed: BedAllocationWardListItemBed) => bed.other.length > 0;
const getOtherPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.other[0]?.id;
const getCurrentPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.current.patient?.id;

const createSelectionForOtherPatient = (bed: BedAllocationWardListItemBed) => {
  if (!hasDoubleAllocation(bed)) return null;
  if (!bed.other[0].id) return null;
  const otherPatientId = getOtherPatientId(bed);
  if (!otherPatientId) return null;
  if (!bed.select?.data) return null;

  const select: OperationSpec<ValueObject> = {
    ...bed.select,
    data: { ...bed.select.data, id: otherPatientId },
  };

  return select;
};
