import { UID } from '@sqior/js/uid';
import {
  LanguageCode,
  LanguageTextResourceMap,
  TextResourceMap,
  TextResourceValue,
} from './text-resource-map';

export class LanguageTextResourceManager {
  constructor() {
    this.textResources = new Map<LanguageCode, Map<UID, string>>();
  }

  addLanguageResources(map: LanguageTextResourceMap) {
    for (const lang in map) this.addResourcesForLanguage(lang, map[lang]);
  }

  addResourcesForLanguage(lang: LanguageCode, map: TextResourceMap) {
    let entry = this.textResources.get(lang);
    if (!entry) this.textResources.set(lang, (entry = new Map<string, TextResourceValue>()));
    for (const id in map) entry.set(id, map[id]);
  }

  private generalize(lang: LanguageCode): LanguageCode | undefined {
    if (!lang.length) return undefined;
    const dashPos = lang.indexOf('-');
    if (dashPos >= 0) return lang.substring(0, dashPos);
    return '';
  }

  get(lang: LanguageCode, id: UID) {
    for (;;) {
      const map = this.textResources.get(lang);
      if (map) {
        const text = map.get(id);
        if (text) return text;
      }
      const nextLang = this.generalize(lang);
      if (!nextLang) break;
      lang = nextLang;
    }
    return '--' + id + '--';
  }

  getMap(lang: LanguageCode): TextResourceMap {
    const map: TextResourceMap = {};
    for (;;) {
      const langMap = this.textResources.get(lang);
      if (langMap) for (const res of langMap) if (map[res[0]] === undefined) map[res[0]] = res[1];
      const nextLang = this.generalize(lang);
      if (!nextLang) break;
      lang = nextLang;
    }
    return map;
  }

  private textResources: Map<LanguageCode, Map<UID, TextResourceValue>>;
}
