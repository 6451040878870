import SvgButton from '../svg-button/svg-button';
import { ReactComponent as Logo } from './undo.svg';

import styles from './button-undo.module.css';
import React from 'react';

/* eslint-disable-next-line */
export interface ButtonUndoProps {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}

export function ButtonUndo(props: ButtonUndoProps) {
  return (
    <SvgButton className={props.className} onClick={props.onClick}>
      <Logo className={styles['svg']} fill="#ffffff" viewBox="0 0 40 40" />
    </SvgButton>
  );
}

export default ButtonUndo;
