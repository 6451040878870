import { arrayTransformAsync } from '@sqior/js/async';
import { CoreEntities, Domain, DomainInterface, TextEntity } from '@sqior/js/meta';
import {
  LanguageEntities,
  makeAnonymizedText,
  makeVerbosity,
  textResource,
  Verbosity,
  VerbosityContextProperty,
  VerbosityEntity,
} from '@sqior/plugins/language';
import {
  AvailableSpecialtiesModel,
  PredominantSpecialtiyModel,
  Specialties,
  SpecialtiesEntity,
  SpecialtiesModel,
  SpecialtyEntities,
  SpecialtyEntity,
  SpecialtyInterfaces,
  SpecialtyModel,
  SpecialtyNameAsPersonModel,
  SpecialtyNameModel,
  SpecialtyQuotaModel,
  SpecialtyQuotasModel,
} from './specialty-definitions';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { ColorCodeEntity, VisualEntities, makeColorCode } from '@sqior/plugins/visual';
import { Entity } from '@sqior/js/entity';
import { SpecialtyVM } from '@sqior/viewmodels/patient';

export const SpecialtyDomainName = 'Specialty';

const SpecialtyNames = new Map<string, string>([
  [Specialties.Cardiology, 'specialty_cardiology'],
  [Specialties.ENT, 'specialty_ent'],
  [Specialties.CMF, 'specialty_cmf'],
  [Specialties.GastroEnterology, 'specialty_gastroenterology'],
  [Specialties.GeneralSurgery, 'specialty_general_surgery'],
  [Specialties.Gynaecology, 'specialty_gynaecology'],
  [Specialties.HeartSurgery, 'specialty_heartsurgery'],
  [Specialties.Internal, 'specialty_internal'],
  [Specialties.Neurosurgery, 'specialty_neurosurgery'],
  [Specialties.Orthopedics, 'specialty_orthopedics'],
  [Specialties.PediatricSurgery, 'specialty_pediatric_surgery'],
  [Specialties.Plastic, 'specialty_plasticsurgery'],
  [Specialties.Thoracic, 'specialty_thoracicsurgery'],
  [Specialties.Trauma, 'specialty_trauma'],
  [Specialties.Urology, 'specialty_urology'],
  [Specialties.Vascular, 'specialty_vascular'],
  [Specialties.Visceral, 'specialty_visceral'],
]);
const SpecialtyColors = new Map<string, ColorCodes>([
  [Specialties.Cardiology, ColorCodes.Cyan],
  [Specialties.ENT, ColorCodes.Orange],
  [Specialties.CMF, ColorCodes.Orange],
  [Specialties.GastroEnterology, ColorCodes.Blue],
  [Specialties.GeneralSurgery, ColorCodes.Violet],
  [Specialties.Gynaecology, ColorCodes.White],
  [Specialties.HeartSurgery, ColorCodes.Cyan],
  [Specialties.Internal, ColorCodes.Blue],
  [Specialties.Neurosurgery, ColorCodes.Green],
  [Specialties.Orthopedics, ColorCodes.Yellow],
  [Specialties.PediatricSurgery, ColorCodes.White],
  [Specialties.Plastic, ColorCodes.Yellow],
  [Specialties.Thoracic, ColorCodes.Cyan],
  [Specialties.Trauma, ColorCodes.Yellow],
  [Specialties.Urology, ColorCodes.Purple],
  [Specialties.Vascular, ColorCodes.Violet],
  [Specialties.Visceral, ColorCodes.Violet],
]);

export class SpecialtyDomain extends Domain {
  constructor() {
    /* Register entities and interfaces */
    super(SpecialtyDomainName, {
      entities: [SpecialtyModel, SpecialtiesModel, SpecialtyQuotaModel, SpecialtyQuotasModel],
      interfaces: [
        SpecialtyNameModel,
        SpecialtyNameAsPersonModel,
        AvailableSpecialtiesModel,
        PredominantSpecialtiyModel,
      ],
    });

    this.addTrivialMapping(SpecialtyInterfaces.SpecialtyName, LanguageEntities.AnonymizedText);
    this.addEntityMapping<SpecialtyEntity>(
      SpecialtyEntities.Specialty,
      SpecialtyInterfaces.SpecialtyName,
      async (entity, mapper) => {
        const res = SpecialtyNames.get(entity.specialty);
        const verbosityEnt = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        const suffix = verbosityEnt && verbosityEnt.verbosity === Verbosity.Short ? '_short' : '';
        return res
          ? mapper.map(textResource(res + suffix), LanguageEntities.AnonymizedText)
          : undefined;
      },
      { context: VerbosityContextProperty, weight: 2 }
    );

    this.addBasicMapping<SpecialtyEntity>(
      SpecialtyEntities.Specialty,
      SpecialtyInterfaces.SpecialtyNameAsPerson,
      (entity) => {
        switch (entity.specialty) {
          case Specialties.Internal:
            return textResource('specialty_person_internal');
          case Specialties.Orthopedics:
            return textResource('specialty_person_orthopedics');
        }
        return undefined;
      }
    );

    this.addEntityMapping<SpecialtiesEntity>(
      SpecialtyEntities.Specialties,
      LanguageEntities.AnonymizedText,
      async (entity, mapper) => {
        return makeAnonymizedText(
          (
            await arrayTransformAsync(entity.specialties, async (spec) => {
              return (await mapper.tryMap<TextEntity>(spec, LanguageEntities.AnonymizedText))?.text;
            })
          ).join(', ')
        );
      }
    );

    /* Determining a color for a specialty */
    this.addEntityMapping<SpecialtyEntity>(
      SpecialtyEntities.Specialty,
      VisualEntities.ColorCode,
      async (specEnt) => {
        const col = SpecialtyColors.get(specEnt.specialty);
        return col ? makeColorCode(col) : undefined;
      },
      { weight: 2 }
    );
  }

  /** Extracts the properties for a specialty and sets them to an object */
  static async getSpecialtyVM(mapper: DomainInterface, specialty: Entity) {
    const res: SpecialtyVM = {};
    const textEnt = await mapper.tryMap<TextEntity>(specialty, CoreEntities.Text, {
      verbosity: makeVerbosity(Verbosity.Short),
    });
    if (textEnt) res.specialty = textEnt.text;
    const colorEnt = await mapper.tryMap<ColorCodeEntity>(specialty, VisualEntities.ColorCode);
    if (colorEnt) res.specialtyColor = colorEnt.color;
    const textEntLong = await mapper.tryMap<TextEntity>(specialty, CoreEntities.Text);
    if (colorEnt) res.specialtyLong = textEntLong?.text;
    return res;
  }
}
