import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from './entity';
import { CoreEntities } from './core-definitions';

/* Tuple entity */

export type TupleEntity<Type extends Entity[] = Entity[]> = EntityHeader & { components: Type };
export const TupleModel: EntityModel = {
  type: CoreEntities.Tuple,
  props: ['components'],
  keys: ['components'],
};
export function makeTuple<Type extends Entity[] = Entity[]>(
  components: Type,
  type = TupleModel.type
): TupleEntity<Type> {
  return { entityType: type, components: components };
}

/* Creates a specific type name from multiple component types */

export function tupleName(components: string[]) {
  return '[' + components.join(',') + ']';
}
