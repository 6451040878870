import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';

// AccountName represent the user's account name (aka login name) of the underlaying Identity Management System
export type AccountName = Entity & { accountName: string };
export const AccountNameModel: EntityModel = {
  type: UserEntities.AccountName,
  props: ['accountName'],
  keys: ['accountName'],
  keysCaseInsensitive: true,
  extends: CoreEntities.Key,
  unclassified: false,
};
export function makeAccountName(accountName: string): AccountName {
  return { entityType: UserEntities.AccountName, accountName: accountName };
}
