/* Core entities */

import { IdEntity } from '@sqior/js/entity';
import { EntityModel } from './entity';
import { Interface } from './interface';

export enum CoreEntities {
  SQIORAssistant = 'SQIORAssistant',
  Event = 'Event',
  Invoke = 'Invoke',
  Undo = 'Undo',
  Redo = 'Redo',
  Stop = 'Stop',
  EventId = 'EventId',
  Command = 'Command',
  Notification = 'Notification',
  Text = 'Text',
  TextTemplate = 'TextTemplate',
  Console = 'Console',
  Key = 'Key',
  Map = 'Map',
  Evaluate = 'Evaluate',
  Undefined = 'Undefined',
  Lookup = 'Lookup',
  Backup = 'Backup',
  If = 'If',
  Condition = 'Condition',
  Parameter = 'Parameter',
  MatchEqual = 'MatchEqual',
  MatchNotEqual = 'MatchNotEqual',
  MatchSubset = 'MatchSubset',
  MatchSubsetNotEqual = 'MatchSubsetNotEqual',
  Tuple = 'Tuple', // Aggregate of multiple entity components
  Subjects = 'Subjects', // Multiple entities
  Id = 'Id', // Generic ID
}

/* Core interfaces - 'Entity' represents all entities */

export enum CoreInterfaces {
  Entity = 'Entity',
  Result = 'Result',
  Success = 'Success', // Defined mapping result to be interpreted as success in conditions etc.
  Anonymized = 'AnonymizedEntity', // Anonymized version of the entity
}

/** Generic ID */

export const IdModel: EntityModel = { type: CoreEntities.Id, props: ['id'], keys: ['id'] };
export function makeId(id: string, type = IdModel.type): IdEntity {
  return { entityType: type, id };
}

/** Anonymized version of an entity */

export const AnonymizedEntityModel: Interface = { type: CoreInterfaces.Anonymized };
