import { Logger } from '@sqior/js/log';
import {
  AnchorModel,
  CoreEntities,
  RelatedDataDomain,
  RelatedDataModel,
  TextEntity,
  Undefined,
  makeTextTemplate,
} from '@sqior/js/meta';
import {
  Gender,
  GenderEntity,
  LanguageEntities,
  makeAnonymizedText,
  makeGender,
  resourceTextTemplate,
  TextResource,
  textResource,
  Verbosity,
  VerbosityContextProperty,
  VerbosityEntity,
} from '@sqior/plugins/language';
import { makeHTML, VisualEntities } from '@sqior/plugins/visual';
import {
  DashboardNameModel,
  LocationEntities,
  LocationInterfaces,
  LocationsEntity,
  ObviousLocationModel,
} from './location-definitions';
import {
  LocationAndFunctionModel,
  LocationOrFunction,
  RoomFunction,
  RoomFunctionModel,
  RoomFunctions,
  TestLocationEntity,
  TestLocationModel,
} from './room-function';
import { CurrentRoomModel, makeRoomId, RoomIdModel } from './room-id';
import { RoomNumberModel } from './room-number';
import { TransportStatusModel } from './transport-status';
import {
  DailyStartConfigEntity,
  DailyStartConfigItemModel,
  DailyStartConfigModel,
  DailyStartConfigOptionDescriptionModel,
  DailyStartEndTimestampModel,
  DailyStartInductionLocationModel,
  DailyStartLastCallTimestampModel,
  DailyStartStartTimestampModel,
  DailyStartTransferLocationModel,
  DefaultDailyStartConfigModel,
  IndividualTimeTransferPriorityGroupModel,
  LocationDailyStartConfigEntity,
  LocationDailyStartConfigModel,
  TransferPriorityGroupIFModel,
  TransferPriorityGroupModel,
  TransferPriorityGroupVMModel,
} from './daily-start-config';
import { DailyStartConfigItemTypes, TransferPriorityGroup } from '@sqior/viewmodels/location';
import { addMinutes, clone, isEqual } from '@sqior/js/data';
import {
  addDuration,
  makeDuration,
  makeHours,
  makeTimestampFromDateTime,
  TimeEntities,
  TimeEntity,
  TimeInterfaces,
  TimeRangeEntity,
} from '@sqior/plugins/time';
import { LocationDate, LocationDateModel } from './location-date';
import { Entity } from '@sqior/js/entity';
import {
  MovementAdmissionModel,
  MovementDischargeModel,
  MovementsModel,
  MovementTransferModel,
  MovementVisitModel,
} from './movement';

export const LocationDomainName = 'Location';
export const LocationAnchor = new AnchorModel(LocationDomainName, LocationInterfaces.Key);
export const LocationName = new RelatedDataModel(
  LocationAnchor,
  'Name',
  LanguageEntities.AnonymizedText
);
export const LocationShortName = new RelatedDataModel(
  LocationAnchor,
  'ShortName',
  LanguageEntities.AnonymizedText
);
export const LocationDashboardName = new RelatedDataModel(
  LocationAnchor,
  'DashboardName',
  LanguageEntities.AnonymizedText
);
export const LocationFunction = new RelatedDataModel(
  LocationAnchor,
  'Function',
  LocationEntities.RoomFunction,
  true
);
export const LocationNumber = new RelatedDataModel(
  LocationAnchor,
  'Number',
  LocationEntities.RoomNumber,
  true
);
export const LocationAssociatedLocations = new RelatedDataModel(
  LocationAnchor,
  'AssociatedLocations',
  LocationEntities.Locations,
  true,
  'locations',
  LocationInterfaces.LocationOrCluster
);
export const LocationDailyStartConfig = new RelatedDataModel(
  LocationAnchor,
  'DailyStartConfig',
  LocationEntities.LocationDailyStartConfig
);

const locFuncTexts = new Map<string, string>([
  [RoomFunctions.SameDaySurgeryCenter, 'location_sds'],
  [RoomFunctions.HoldingArea, 'location_holding'],
  [RoomFunctions.Transfer, 'location_transfer'],
  [RoomFunctions.Induction, 'location_induction'],
  [RoomFunctions.Operating, 'location_or'],
  [RoomFunctions.Recovery, 'location_recovery'],
  [RoomFunctions.Ward, 'location_ward'],
  [RoomFunctions.ICU, 'location_icu'],
  [RoomFunctions.ER, 'location_er'],
]);

export class LocationDomain extends RelatedDataDomain {
  constructor() {
    /* Models */
    super(LocationAnchor, {
      entities: [
        RoomIdModel,
        RoomFunctionModel,
        RoomNumberModel,
        LocationAndFunctionModel,
        TestLocationModel,
        TransportStatusModel,
        DailyStartConfigModel,
        DailyStartConfigItemModel,
        TransferPriorityGroupModel,
        IndividualTimeTransferPriorityGroupModel,
        TransferPriorityGroupVMModel,
        LocationDateModel,
        LocationDailyStartConfigModel,
        MovementsModel,
        MovementAdmissionModel,
        MovementTransferModel,
        MovementDischargeModel,
        MovementVisitModel,
      ],
      interfaces: [
        DailyStartConfigOptionDescriptionModel,
        DailyStartInductionLocationModel,
        DailyStartLastCallTimestampModel,
        DailyStartTransferLocationModel,
        DailyStartStartTimestampModel,
        DailyStartEndTimestampModel,
        DefaultDailyStartConfigModel,
        LocationOrFunction,
        ObviousLocationModel,
        TransferPriorityGroupIFModel,
        CurrentRoomModel,
        DashboardNameModel,
      ],
      relatedData: [
        LocationName,
        LocationShortName,
        LocationDashboardName,
        LocationFunction,
        LocationNumber,
        LocationAssociatedLocations,
        LocationDailyStartConfig,
      ],
    });

    /* Mappings */
    this.addTrivialMapping(LocationEntities.RoomId, LocationInterfaces.Key);
    this.addTrivialMapping(LocationInterfaces.Key, LocationInterfaces.LocationOrFunction);
    this.addEntityMapping(
      LocationEntities.RoomNumber,
      LocationInterfaces.Key,
      async (entity, mapper) => {
        const locs = await mapper.map<LocationsEntity>(entity, LocationNumber.anchorsType);
        return locs.locations.length === 1 ? locs.locations[0] : undefined;
      },
      { weight: 10 }
    );
    this.addEntityMapping(
      LocationInterfaces.Key,
      LanguageEntities.AnonymizedText,
      async (entity, mapper) => {
        const verbosity = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        const type =
          !verbosity || verbosity.verbosity !== Verbosity.Short
            ? LocationName.interface.type
            : LocationShortName.interface.type;
        return mapper.tryMap(entity, type);
      },
      { context: VerbosityContextProperty }
    );
    this.addEntityMapping(
      LocationInterfaces.Key,
      LocationInterfaces.DashboardName,
      async (loc, mapper) => {
        let dashboardName: Entity | undefined = await mapper.tryMap(
          loc,
          LocationDashboardName.interface.type
        );
        if (dashboardName) return dashboardName;
        dashboardName = await mapper.tryMap(loc, LocationName.interface.type);
        return dashboardName;
      }
    );
    this.addEntityMapping(LocationInterfaces.Key, VisualEntities.HTML, async (entity, mapper) => {
      const textEnt = await mapper.map<TextEntity>(entity, CoreEntities.Text);
      return makeHTML('<span><em>' + textEnt.text + '</em></span>');
    });
    this.addEntityMapping(
      LocationInterfaces.Key,
      LocationEntities.RoomFunction,
      async (entity, mapper) => {
        return mapper.map(entity, LocationFunction.interface.type);
      }
    );
    this.addTrivialMapping(LocationEntities.RoomFunction, LocationInterfaces.LocationOrFunction);
    this.addBasicMapping<RoomFunction, GenderEntity>(
      LocationEntities.RoomFunction,
      LanguageEntities.Gender,
      (entity) => {
        return LocationDomain.FemaleRoomFunctions.has(entity.func)
          ? makeGender(Gender.Female)
          : makeGender(Gender.Male);
      }
    );
    this.addBasicMapping<RoomFunction, TextResource>(
      LocationEntities.RoomFunction,
      LanguageEntities.TextResource,
      (entity) => {
        const textRes = locFuncTexts.get(entity.func);
        if (textRes) return textResource(textRes);
        Logger.warn(['Unknown test resource requested for location function:', entity.func]);
        return undefined;
      }
    );

    this.addTrivialMapping(
      LocationEntities.TransferPriorityGroup,
      LocationInterfaces.TransferPriorityGroupIF
    );
    this.addTrivialMapping(
      LocationEntities.IndividualTimeTransferPriorityGroup,
      LocationInterfaces.TransferPriorityGroupIF
    );

    this.addEntityMapping(
      LocationEntities.TransferPriorityGroup,
      LocationInterfaces.DailyStartStartTimestamp,
      async (groupEnt, mapper) => {
        const today = await mapper.map(makeHours(0), TimeInterfaces.DailyTime);
        const timeRange = await mapper.tryMap<TimeRangeEntity>(
          [groupEnt, today],
          TimeEntities.TimeRange
        );
        return timeRange ? makeTimestampFromDateTime(today, timeRange.start) : undefined;
      }
    );
    this.addEntityMapping(
      LocationEntities.TransferPriorityGroup,
      LocationInterfaces.DailyStartEndTimestamp,
      async (groupEnt, mapper) => {
        const today = await mapper.map(makeHours(0), TimeInterfaces.DailyTime);
        const timeRange = await mapper.tryMap<TimeRangeEntity>(
          [groupEnt, today],
          TimeEntities.TimeRange
        );
        return timeRange ? makeTimestampFromDateTime(today, timeRange.end) : undefined;
      }
    );
    this.addEntityMapping<TimeEntity>(
      LocationEntities.IndividualTimeTransferPriorityGroup,
      LocationInterfaces.DailyStartStartTimestamp,
      async (groupEnt, mapper) => {
        const today = await mapper.map(makeHours(0), TimeInterfaces.DailyTime);
        return makeTimestampFromDateTime(today, groupEnt);
      }
    );

    this.addEntityMapping<LocationDate>(
      LocationEntities.LocationDate,
      LocationEntities.DailyStartConfig,
      async (locDateEnt, mapper) => {
        const defaultConfig = await mapper.tryMap<DailyStartConfigEntity>(
          locDateEnt,
          LocationInterfaces.DefaultDailyStartConfig
        );
        if (!defaultConfig) return undefined;

        const workingConfig = clone(defaultConfig);
        const startConfig = await mapper.tryMap<LocationDailyStartConfigEntity>(
          locDateEnt.location,
          LocationDailyStartConfig.interface.type
        );

        for (const { configType, entity } of [
          {
            configType: DailyStartConfigItemTypes.TransferPriorityGroup,
            entity: startConfig?.group,
          },
          {
            configType: DailyStartConfigItemTypes.IndividualTime,
            entity: startConfig?.group,
          },
          {
            configType: DailyStartConfigItemTypes.TransferLocation,
            entity: startConfig?.transferLocation,
          },
          {
            configType: DailyStartConfigItemTypes.InductionLocation,
            entity: startConfig?.inductionLocation,
          },
        ]) {
          const config = workingConfig.configs.find((c) => c.configType === configType);
          if (config) {
            if (configType === DailyStartConfigItemTypes.IndividualTime) {
              if (
                entity &&
                mapper.directlyRepresents(
                  entity,
                  LocationEntities.IndividualTimeTransferPriorityGroup
                )
              ) {
                const startTime = entity as TimeEntity;
                config.indiviualTime = { hours: startTime.hours, minutes: startTime.minutes };
              }
            } else {
              // Mix In Off-Switches
              //config.options.unshift({ entity: Undefined, text: '---' });
              // Set current selection
              const index = config.options.findIndex((opt) =>
                isEqual(opt.entity, entity ?? Undefined)
              );
              if (index >= 0) {
                config.selected = index;
              }
            }
          }
        }

        workingConfig.enabled = startConfig?.enabled === true;
        return workingConfig;
      }
    );

    this.addEntityMapping<LocationDailyStartConfigEntity>(
      LocationEntities.LocationDailyStartConfig,
      LocationInterfaces.DailyStartInductionLocation,
      async (cfg) => {
        return cfg.enabled ? cfg.inductionLocation : undefined;
      }
    );
    this.addEntityMapping<LocationDailyStartConfigEntity>(
      LocationEntities.LocationDailyStartConfig,
      LocationInterfaces.DailyStartTransferLocation,
      async (cfg) => {
        return cfg.enabled ? cfg.transferLocation : undefined;
      }
    );
    this.addEntityMapping<LocationDailyStartConfigEntity>(
      LocationEntities.LocationDailyStartConfig,
      LocationInterfaces.DailyStartLastCallTimestamp,
      async (cfg, mapper) => {
        if (!cfg.enabled) return undefined;
        const time =
          cfg.group &&
          (await mapper.tryMap(cfg.group, LocationInterfaces.DailyStartStartTimestamp));
        return (
          time &&
          (await mapper.tryMap(
            addDuration(time, makeDuration(addMinutes(30))),
            TimeEntities.Timestamp
          ))
        );
      }
    );

    this.addEntityMapping<TransferPriorityGroup>(
      LocationEntities.TransferPriorityGroup,
      CoreEntities.TextTemplate,
      (entity, mapper) => {
        const verbosityEnt = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        if (entity.name.startsWith('P'))
          return resourceTextTemplate(
            verbosityEnt?.verbosity === Verbosity.Normal
              ? 'transfer_priority_group_p'
              : 'transfer_priority_group_p_short',
            { number: makeAnonymizedText(entity.name.slice(1)) }
          );
        if (entity.name.startsWith('G'))
          return resourceTextTemplate(
            verbosityEnt?.verbosity === Verbosity.Normal
              ? 'transfer_priority_group_g'
              : 'transfer_priority_group_g_short',
            { number: makeAnonymizedText(entity.name.slice(1)) }
          );
        return makeTextTemplate('<e>val</e>', { val: makeAnonymizedText(entity.name) });
      },
      { context: VerbosityContextProperty }
    );
    this.addEntityMapping<TransferPriorityGroup>(
      LocationEntities.IndividualTimeTransferPriorityGroup,
      LanguageEntities.TextResource,
      async (_, mapper) => {
        const verbosityEnt = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
        return textResource(
          verbosityEnt?.verbosity === Verbosity.Normal
            ? 'transfer_priority_group_individual_time'
            : 'transfer_priority_group_individual_time_short'
        );
      },
      { context: VerbosityContextProperty }
    );

    /* Mapping from group and date to description */
    this.addTupleMapping<[TransferPriorityGroup, Entity]>(
      [LocationEntities.TransferPriorityGroup, TimeEntities.Date],
      LocationInterfaces.DailyStartConfigOptionDescription,
      async (tuple, mapper) => {
        return await mapper.tryMapChain(tuple, [TimeEntities.TimeRange, CoreEntities.Text]);
      }
    );

    /* Test OR */
    this.addEntityMapping<TestLocationEntity>(
      LocationEntities.TestLocation,
      LocationInterfaces.Key,
      async (testLocEnt) => {
        if (testLocEnt.func === RoomFunctions.Operating)
          return makeRoomId('or' + (1 + testLocEnt.index));
        return undefined;
      },
      { weight: 2 }
    );
  }

  private static FemaleRoomFunctions = new Set<string>([
    RoomFunctions.Transfer,
    RoomFunctions.Induction,
    RoomFunctions.HoldingArea,
    RoomFunctions.Ward,
    RoomFunctions.ICU,
    RoomFunctions.SameDaySurgeryCenter,
  ]);
}
