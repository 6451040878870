import { TreatmentPathPatientListItemEntity } from '@sqior/viewmodels/treatment-path';
import styles from './treatment-path-patient-list-item.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { InterweaveExt } from '@sqior/react/uibase';
import { ItemState } from '@sqior/viewmodels/visual';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { SelectPatient } from '@sqior/viewmodels/patient';

export type TreatmentPathPatientListItemProps = FactoryProps<TreatmentPathPatientListItemEntity>;

export function TreatmentPathPatientListItem(props: TreatmentPathPatientListItemProps) {
  const dispatcher = useContext(OperationContext);
  return (
    <div
      className={styles['container']}
      onClick={() => {
        dispatcher.start(SelectPatient(props.data.select));
      }}
    >
      <InterweaveExt className={styles['normal']} content={props.data.patient} />
      {props.data.diagnosis && <div>{props.data.diagnosis}</div>}
      <div
        className={
          styles[
            props.data.pathState === ItemState.Challenged
              ? 'challenge'
              : props.data.pathState === ItemState.Highlighted
              ? 'highlight'
              : 'normal'
          ]
        }
      >
        {props.data.pathText}
      </div>
    </div>
  );
}

export default TreatmentPathPatientListItem;
