import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { UserEntities, UserInterfaces } from './user-definitions';

/** E-mail address */

export type EMailAddress = Entity & { email: string };
export const EMailAddressModel: EntityModel = {
  type: UserEntities.EMail,
  props: ['email'],
  keys: ['email'],
  extends: CoreEntities.Key,
  unclassified: false,
};
export function makeEMailAddress(email: string): EMailAddress {
  return { entityType: UserEntities.EMail, email: email.toLowerCase() };
}

/** Retrieves the e-mail address */

export const GetEMailAddressModel: Interface = {
  type: UserInterfaces.EMail,
  requires: UserEntities.EMail,
};
