/* Exception type that is not supposed to be caught */

export class DoNotCatchException extends Error {
  constructor(msg: string) {
    super(msg);
  }
}

/* Transient database error */

export class TransientDatabaseError extends DoNotCatchException {
  constructor(msg: string) {
    super(msg);
  }
}
