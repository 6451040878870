import { arrayFilterAsync, arrayTransformAsync } from '@sqior/js/async';
import { TimedCacheState } from '@sqior/js/cache';
import { addHours, shallowClone } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import {
  AnchorModel,
  CoreEntities,
  DomainInterface,
  RelatedDataDomain,
  RelatedDataModel,
  SQIORAssistant,
  TextEntity,
  TrackingDomainInterface,
  Undefined,
  extractText,
} from '@sqior/js/meta';
import { createUID } from '@sqior/js/uid';
import {
  DeviceAnchor,
  DeviceEntities,
  DeviceInterfaces,
  DeviceLastConnect,
  DevicePhoneNumber,
  PhoneNumber,
  PhoneNumbers,
  PhoneNumbersVM,
  PushDevicesEntity,
  makePhoneNumbers,
  makePhoneNumbersVM,
} from '@sqior/plugins/device';
import {
  LanguageEntities,
  makeAnonymizedText,
  makeTexts,
  resourceTextTemplate,
} from '@sqior/plugins/language';
import {
  LocationClusterFunction,
  LocationClusterFunctionEntity,
  LocationClusterRelatedFunction,
  LocationInterfaces,
} from '@sqior/plugins/location';
import { PersonInterfaces } from '@sqior/plugins/person';
import { SpecialtiesEntity } from '@sqior/plugins/specialty';
import { TimestampEntity } from '@sqior/plugins/time';
import {
  AddressGroupInfoVM,
  AddressInfoVM,
  AddressInfoVMType,
  OnlineStatus,
} from '@sqior/viewmodels/communication';
import { PhoneNumberPair, PhoneType } from '@sqior/viewmodels/user';
import { URLEntity } from '@sqior/viewmodels/visual';
import { MD5 } from 'crypto-js';
import {
  AccessibleAddressesModel,
  ActualSenderModel,
  AddressInfo,
  AddressInfoModel,
  Addresses,
  AddressesInfoModel,
  OnBehalf,
  OnBehalfModel,
  ReplyToAddressModel,
  SenderModel,
  makeAddressInfo,
  makeAddresses,
  makeAddressesInfo,
} from './addresses';
import { EMailAddress } from './email-address';
import {
  CommAddressOptions,
  RealUser,
  RealUserMapOptions,
  RealUserMapOptionsModel,
  RealUserModel,
  RealUsers,
  RealUsersModel,
  addRealUsers,
  makeRealUser,
  makeRealUsers,
  preferRole,
  prefersRole,
} from './real-user';
import {
  LocationRole,
  RoleEntity,
  RoleSqiorEmployee,
  Roles,
  RolesEntity,
  SpecialtyLocationRole,
  makeLocationRole,
  makeRoleEntity,
  makeRolesEntity,
} from './role-entity';
import { RoleUser } from './role-user';
import {
  NullAddressModel,
  OnlineStatusEntity,
  RealUserContextProperty,
  TelemetryAddressModel,
  UserEntities,
  UserInterfaces,
  UsersEntity,
  makeTelemetryAddress,
} from './user-definitions';
import {
  UserActiveLocations,
  UserDomain,
  UserPhoneNumbers,
  UserRoles,
  UserSpecialties,
} from './user-domain';
import {
  WardAlternativeUser,
  WardAlternativeUserModel,
  makeWardAlternativeUser,
} from './ward-alternative-user';

export const AddressDomainName = 'Address';
export const AddressAnchor = new AnchorModel(AddressDomainName, UserInterfaces.Address);
export const AddressPushDevices = new RelatedDataModel(
  AddressAnchor,
  'PushDevices',
  DeviceEntities.PushDevices,
  true,
  'pushDevices',
  DeviceEntities.PushDevice
);

export const AddressPhoneNumbers = new RelatedDataModel(
  AddressAnchor,
  'PhoneNumber',
  DeviceEntities.PhoneNumbers,
  true,
  'phoneNumbers',
  DeviceEntities.PhoneNumber
);
export const HoursUntilOffline = 12;

export class AddressDomain extends RelatedDataDomain {
  constructor() {
    /* Definitions */
    super(AddressAnchor, {
      entities: [
        OnBehalfModel,
        AddressInfoModel,
        RealUserModel,
        RealUsersModel,
        RealUserMapOptionsModel,
        AddressesInfoModel,
        NullAddressModel,
        TelemetryAddressModel,
        WardAlternativeUserModel,
      ],
      interfaces: [SenderModel, ActualSenderModel, AccessibleAddressesModel, ReplyToAddressModel],
      relatedData: [AddressPushDevices, AddressPhoneNumbers],
      contextProperties: { name: RealUserContextProperty, autoForward: true },
    });

    /* Mappings */
    this.addTrivialMapping(UserInterfaces.Key, UserInterfaces.Address); // Each user corresponds to a valid communication address
    this.addTrivialMapping(UserEntities.Role, UserInterfaces.Address); // Also roles can be used as a communication address
    this.addEntityMapping<RoleUser>(
      UserEntities.RoleUser,
      UserInterfaces.Address,
      async (roleUser) => {
        return roleUser.role;
      }
    );
    this.addTrivialMapping(UserEntities.LocationRole, UserInterfaces.Address); // Also location roles can be used as a communication address
    this.addTrivialMapping(UserEntities.SpecialtyLocationRole, UserInterfaces.Address); // Also specialty location roles can be used as a communication address
    this.addTrivialMapping(UserEntities.NullAddress, UserInterfaces.Address); // The null address is also an address

    this.addTrivialMapping(UserInterfaces.Key, UserInterfaces.Sender);
    this.addTrivialMapping(CoreEntities.SQIORAssistant, UserInterfaces.Sender); // The SQIOR assistant sends information but cannot be contacted
    this.addTrivialMapping(UserEntities.RoleUser, UserInterfaces.Sender);
    this.addTrivialMapping(UserEntities.OnBehalf, UserInterfaces.Sender);
    this.addTrivialMapping(UserInterfaces.Key, UserInterfaces.ActualSender);
    this.addEntityMapping<RoleUser>(
      UserEntities.RoleUser,
      UserInterfaces.ActualSender,
      async (roleUser) => {
        return roleUser.user;
      }
    );
    this.addEntityMapping<OnBehalf>(
      UserEntities.OnBehalf,
      UserInterfaces.ActualSender,
      async (onBehalf) => {
        return onBehalf.actual;
      }
    );

    this.addTrivialMapping(UserInterfaces.Address, UserInterfaces.ReplyToAddress);
    this.addEntityMapping<OnBehalf>(
      UserEntities.OnBehalf,
      UserInterfaces.ReplyToAddress,
      async (onBehalf) => {
        return onBehalf.represented;
      }
    );
    this.addEntityMapping(UserEntities.NullAddress, LanguageEntities.AnonymizedText, async () => {
      return makeAnonymizedText('/dev/null');
    });

    this.addEntityMapping<Addresses>(
      UserEntities.Addresses,
      CoreEntities.TextTemplate,
      async (addresses) => {
        return makeTexts(addresses.addresses);
      },
      { weight: 10 }
    );

    /* Map different addresses to real users - Fallback Mapping*/
    this.addEntityMapping(
      UserInterfaces.Key,
      UserEntities.RealUser,
      async (user, mapper) => {
        const roles = await mapper.tryMap<RolesEntity>(user, UserInterfaces.EffectiveRoles);
        const context = await UserDomain.getGenderContext(mapper, user);
        const rolesText = await mapper.tryMap<TextEntity>(
          roles,
          LanguageEntities.AnonymizedText,
          context
        );

        return makeRealUser(user, user, rolesText);
      },
      { weight: 10 }
    );

    /* Map a specific RoleUser to real users */
    this.addEntityMapping<RoleUser>(
      UserEntities.RoleUser,
      UserEntities.RealUser,
      async (roleUser, mapper) => {
        // Check whether
        return makeRealUser(
          roleUser.user,
          await roleOrUserToCommAddr(mapper, roleUser.role, roleUser.user),
          await mapper.tryMap<TextEntity>(
            roleUser.role,
            CoreEntities.TextTemplate,
            await UserDomain.getGenderContext(mapper, roleUser.user)
          ),
          mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)?.commAddressOption
        );
      },
      { context: RealUserContextProperty }
    );

    this.addEntityMapping<OnBehalf>(
      UserEntities.OnBehalf,
      UserEntities.RealUser,
      async (onBehalf, mapper) => {
        return mapper.map(onBehalf.actual, UserEntities.RealUser);
      }
    );

    this.addEntityMapping<LocationRole>(
      UserEntities.LocationRole,
      UserEntities.RealUsers,
      async (locRole, mapper) => {
        const res = await this.mapLocationRolesToRealUsers(locRole, mapper);

        if (mapper.isEqual(locRole.role, makeRoleEntity(Roles.WardNurse))) {
          await this.replaceDummyWardEntry(res.users, locRole, mapper);
        }

        return res;
      },
      { context: RealUserContextProperty }
    );

    this.addEntityMapping<SpecialtyLocationRole>(
      UserEntities.SpecialtyLocationRole,
      UserEntities.RealUsers,
      async (locRole, mapper) => {
        return this.mapLocationRolesToRealUsers(locRole, mapper);
      },
      { context: RealUserContextProperty }
    );

    this.addEntityMapping<RoleEntity>(
      UserEntities.Role,
      UserEntities.RealUsers,
      async (role, mapper) => {
        mapper.cacheAccess(
          new TimedCacheState(addHours(1, mapper.steadyTimer.now), mapper.steadyTimer)
        ); // This mapping is time dependent
        const matchingUsers: UsersEntity | undefined = await mapper.tryMap<UsersEntity>(
          role,
          UserRoles.anchorsType
        );
        if (matchingUsers?.users && matchingUsers?.users.length > 0) {
          const matchingUserWithTime = await arrayTransformAsync(
            matchingUsers.users,
            async (user) => {
              return {
                u: user,
                lc:
                  (await mapper.tryMap<TimestampEntity>(user, UserInterfaces.LastConnect))
                    ?.timestamp || 0,
              };
            }
          );
          matchingUserWithTime.sort((a, b) => -1 * (a.lc - b.lc)); // Sort by time DESC
          const final = matchingUserWithTime.filter(
            (r) => mapper.steadyTimer.now - r.lc < addHours(HoursUntilOffline)
          );
          if (final.length > 0) {
            return makeRealUsers(
              await arrayTransformAsync(final, async (r) => {
                const roleText = await mapper.map<TextEntity>(
                  role,
                  LanguageEntities.AnonymizedText,
                  await UserDomain.getGenderContext(mapper, r.u)
                );
                return makeRealUser(
                  r.u,
                  await roleOrUserToCommAddr(mapper, role, r.u),
                  roleText,
                  mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)?.commAddressOption
                );
              })
            );
          }
        }
        return makeRealUsers([
          makeRealUser(
            undefined,
            await roleOrUserToCommAddr(mapper, role, undefined),
            await mapper.map(role, CoreEntities.TextTemplate),
            mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)?.commAddressOption
          ),
        ]);
      },
      { context: RealUserContextProperty }
    );
    this.addEntityMapping<RealUser>(
      UserEntities.RealUser,
      UserEntities.RealUsers,
      async (roleUser) => {
        return makeRealUsers([roleUser]);
      }
    );

    this.addEntityMapping<OnBehalf>(
      UserEntities.OnBehalf,
      CoreEntities.TextTemplate,
      async (onBehalf, mapper) => {
        return mapper.tryMap(onBehalf.represented, CoreEntities.TextTemplate);
      }
    );
    this.addEntityMapping<Entity, Addresses>(
      UserInterfaces.Key,
      UserInterfaces.AccessibleAddresses,
      async (entity, mapper) => {
        /* Always add user entity */
        let addresses: Entity[] = [entity];
        /* Get all effective roles, filter out the surgeon role as it is not a function role that all recipients should receive messages for */
        const effectiveRoles = await mapper.map<RolesEntity>(entity, UserInterfaces.EffectiveRoles);
        addresses = addresses.concat(
          effectiveRoles.roles.filter((role) => {
            return (
              role.entityType !== UserEntities.Role || (role as RoleEntity).role !== Roles.Surgeon
            );
          })
        );
        return makeAddresses(addresses);
      }
    );

    this.addEntityMapping(
      UserInterfaces.Address,
      DeviceInterfaces.PhoneNumbers,
      async (entity, mapper) => {
        // Get the user's phone number, if available

        // const userPhone = (
        //   await mapper.tryMapChain<PhoneNumbers>(entity, [
        //     UserInterfaces.Key,
        //     UserPhoneNumbers.interface.type,
        //   ])
        // )?.phoneNumbers;
        const addressPhoneNumbers = (
          await mapper.tryMap<PhoneNumbers>(entity, AddressPhoneNumbers.interface.type)
        )?.phoneNumbers;

        // Get the phone number of devices which has been used last time
        const devices = await mapper.tryMapChain<PushDevicesEntity>(entity, [
          AddressPushDevices.interface.type,
        ]);
        let devicePhone: PhoneNumber | undefined = undefined;
        if (devices !== undefined) {
          const phoneNumbers = await arrayTransformAsync(devices.pushDevices, async (dev) => {
            const pnEntity = await mapper.tryMap<PhoneNumber>(
              dev,
              DevicePhoneNumber.interface.type
            );
            const lcEntity = await mapper.tryMap<TimestampEntity>(
              dev,
              DeviceLastConnect.interface.type
            );
            if (pnEntity !== undefined && lcEntity !== undefined)
              return {
                phoneNumberEntity: pnEntity,
                lastConnect: lcEntity.timestamp,
              };
            return undefined;
          });

          phoneNumbers.sort((a, b) => a.lastConnect - b.lastConnect);
          if (phoneNumbers.length > 0)
            devicePhone = phoneNumbers[phoneNumbers.length - 1].phoneNumberEntity;
        }

        let numbers: PhoneNumber[] = [];
        // if (userPhone) numbers.push(...userPhone);
        if (addressPhoneNumbers) numbers.push(...addressPhoneNumbers);
        if (devicePhone) numbers.push(devicePhone);

        // Filter duplicated numbers
        numbers = numbers.filter(
          (val, idx) => numbers.findIndex((val2) => val2.phoneNumber === val.phoneNumber) === idx
        );

        return makePhoneNumbers(numbers);
      }
    );

    this.addEntityMapping<WardAlternativeUser>(
      UserEntities.WardAlternativeUser,
      CoreEntities.TextTemplate,
      async (entity, mapper) => {
        const station = await mapper.tryMap(entity.role, UserInterfaces.EffectiveLocation);
        if (!station) return resourceTextTemplate('ward_control', {});
        return resourceTextTemplate('ward_control_with_loc', { loc: station });
      }
    );

    this.addEntityMapping<WardAlternativeUser>(
      UserEntities.WardAlternativeUser,
      UserInterfaces.Address,
      async (entity, mapper) => {
        return entity.role;
      }
    );

    this.addEntityMapping<PhoneNumbers, PhoneNumbersVM>(
      DeviceEntities.PhoneNumbers,
      DeviceEntities.PhoneNumbersVM,
      async (entity, mapper) => {
        const numberPairs: PhoneNumberPair[] = [];
        const prefix = (
          await mapper.map<PhoneNumber>(Undefined, DeviceInterfaces.FacilityBasePhoneNumber)
        ).phoneNumber;

        for (const num of entity.phoneNumbers) {
          let displayNumber: string;
          if (num.phoneNumber.startsWith(prefix)) {
            const prefixIndex = num.phoneNumber.indexOf(prefix);
            const stationNumber = num.phoneNumber.substring(prefixIndex + prefix.length);
            displayNumber = `${prefix} <b>${stationNumber}</b>`;
          } else {
            displayNumber = num.phoneNumber;
          }

          numberPairs.push({
            type: PhoneType.Device,
            phoneNumber: num.phoneNumber,
            phoneNumberVis: displayNumber.replace(/^\+[\d]{2}/, '0'),
          });
        }
        return makePhoneNumbersVM(numberPairs);
      }
    );

    /* Mapping of Address to AddressInfo */
    this.addEntityMapping<RealUser>(
      UserEntities.RealUser,
      UserEntities.AddressInfo,
      async (ent, mapper) => {
        const user = ent.user;
        if (mapper.isEqual(user, SQIORAssistant)) return undefined;

        const genderContext = user ? await UserDomain.getGenderContext(mapper, user) : {};
        const userText =
          user &&
          ((await mapper.tryMapChain<TextEntity>(user, [
            PersonInterfaces.Name,
            PersonInterfaces.FluentName,
            CoreEntities.Text,
          ])) ||
            (await mapper.tryMapChain<TextEntity>(user, [CoreEntities.Text])));

        const roleText = ent.roleText
          ? await mapper.map<TextEntity>(ent.roleText, CoreEntities.Text, genderContext)
          : undefined;
        const phoneNumbers =
          user &&
          (await mapper.tryMapChain<PhoneNumbersVM>(user, [
            DeviceInterfaces.PhoneNumbers,
            DeviceEntities.PhoneNumbersVM,
          ]));
        const email = user && (await mapper.tryMap<EMailAddress>(user, UserInterfaces.EMail));
        const lastConnect =
          user && (await mapper.tryMap<TimestampEntity>(user, UserInterfaces.LastConnect));
        const onlineStatus =
          user && (await mapper.tryMap<OnlineStatusEntity>(user, UserEntities.OnlineStatus));

        const roleOnTop = ent.commAddressOption === CommAddressOptions.PreferRole;

        const key = MD5(
          (ent.user ? mapper.makeKey(ent.user) : '') +
            (ent.roleText ? mapper.makeKey(ent.roleText) : '')
        ).toString();

        /* Get the profile picture */
        const urlEnt = await mapper.tryMap<URLEntity>(
          user,
          UserInterfaces.ProfilePictureURLTemplate
        );

        const addressInfo: AddressInfoVM = {
          name: roleOnTop ? (roleText ? roleText.text : '') : userText ? userText.text : '',
          key: key,
          subname: roleOnTop ? (userText ? userText.text : '') : roleText ? roleText.text : '',
          onlineStatus: OnlineStatus.NeverSeen,
          phoneNumbers: phoneNumbers?.numbers || [],
          type: AddressInfoVMType.User,
        };
        if (urlEnt) addressInfo.profilePicture = urlEnt;

        if (user && userText) addressInfo.id = user;
        if (email !== undefined) addressInfo.email = email.email;
        if (lastConnect !== undefined) {
          addressInfo.lastConnect = lastConnect.timestamp;
          addressInfo.onlineStatus = onlineStatus?.onlineStatus || OnlineStatus.Offline;
          // This mapping is time dependent => set expire time
          if (mapper.steadyTimer.now < addHours(HoursUntilOffline, lastConnect.timestamp))
            mapper.cacheAccess(
              new TimedCacheState(
                addHours(HoursUntilOffline, lastConnect.timestamp),
                mapper.steadyTimer
              )
            );
        }
        const chatAddress =
          ent.commAddress && (await mapper.tryMap(ent.commAddress, UserInterfaces.Address));
        if (chatAddress) addressInfo.chatAddress = chatAddress;

        return makeAddressInfo(addressInfo);
      }
    );

    this.addEntityMapping<RealUsers>(
      UserEntities.RealUsers,
      UserEntities.AddressesInfo,
      async (users, mapper) => {
        const realUsersSqior = await AddressDomain.doSqiorEmployeeHandling(users.users, mapper);

        // Sort into groups (Note: Array is used to preserve the order)
        const groupedRealUsers: {
          groupKey: string | undefined;
          group: Entity | undefined;
          realUsers: RealUser[];
        }[] = [];
        for (const a of realUsersSqior) {
          if (!a) continue;
          const groupKey = a.group && mapper.makeKey(a.group);
          const g = groupedRealUsers.find((b) => b.groupKey === groupKey);
          if (g) g.realUsers.push(a);
          else
            groupedRealUsers.push({
              groupKey: groupKey,
              group: a.group,
              realUsers: [a],
            });
        }

        // Helper function to condense duplicate users to one user and accumulate their roles
        function condenseSameUsers(realUsers: RealUser[]): RealUser[] {
          const condensedRealUsers = new Map<string, [RealUser, Entity[]]>();
          let i = 0;
          for (const a of realUsers) {
            i++;
            if (!a) continue;

            const k = (a.user && mapper.makeKey(a.user)) || `${i}`; // User alternative key if user not existing
            if (condensedRealUsers.has(k)) {
              if (a.roleText /* TODO test for actual role */)
                condensedRealUsers.get(k)?.[1].push(a.roleText);
              else condensedRealUsers.set(`${k}_${i}`, [a, a.roleText ? [a.roleText] : []]);
            } else {
              condensedRealUsers.set(k, [a, a.roleText ? [a.roleText] : []]);
            }
          }
          return Array.from(condensedRealUsers.values()).map((m) => {
            if (m[1].length <= 1) return m[0];
            const ruNew = shallowClone(m[0]);
            ruNew.roleText = makeRolesEntity(m[1]);
            return ruNew;
          });
        }
        const condensedGroupedRealUsers = groupedRealUsers.map((gru) => {
          return {
            groupKey: gru.groupKey,
            group: gru.group,
            realUsers: condenseSameUsers(gru.realUsers),
          };
        });

        const groupedAddressInfo: {
          groupKey: string | undefined;
          group: Entity | undefined;
          addrInfo: AddressInfoVM[];
        }[] = await arrayTransformAsync(condensedGroupedRealUsers, async (gru) => {
          return {
            groupKey: gru.groupKey,
            group: gru.group,
            addrInfo: await arrayTransformAsync(gru.realUsers, async (ru) => {
              const addrInfo = await mapper.tryMap<AddressInfo>(ru, UserEntities.AddressInfo);
              return addrInfo?.info;
            }),
          };
        });

        return makeAddressesInfo(
          await arrayTransformAsync(groupedAddressInfo, async (g) => {
            const res: AddressGroupInfoVM = {
              key: g.groupKey ?? createUID(),
              items: sortUnknownAddressesLast(g.addrInfo),
            };
            if (g.group) res.name = await extractText(mapper, g.group);
            return res;
          })
        );
      }
    );

    // TODO: add phone number
    this.addEntityMapping<RealUsers>(
      UserInterfaces.AssociatedRole,
      UserEntities.AddressInfo,
      async (role, mapper) => {
        const name = await mapper.tryMap<TextEntity>(role, CoreEntities.Text);

        let roleEnt: TextEntity | undefined = undefined;
        // Add current logged on users as text in the second line
        const currentUsers = await mapper.map<RealUsers>(role, UserEntities.RealUsers);
        const userTexts = await arrayTransformAsync(
          currentUsers.users,
          async (u) => u.user && (await mapper.tryMap<TextEntity>(u.user, CoreEntities.Text))
        );
        roleEnt = await mapper.map<TextEntity>(makeTexts(userTexts), CoreEntities.Text);

        if (!name) return undefined;

        const addressInfo: AddressInfoVM = {
          id: role,
          key: mapper.makeKey(role),
          name: name.text,
          subname: roleEnt?.text || '',
          onlineStatus: OnlineStatus.NeverSeen,
          phoneNumbers: [],
          chatAddress: role,
          type: AddressInfoVMType.Role,
        };

        return makeAddressInfo(addressInfo);
      }
    );

    /* Plural mapping */
    this.addEntityMapping<Addresses>(
      UserEntities.Addresses,
      UserEntities.AddressesInfo,
      async (addresses, mapper) => {
        const realUsers = await arrayTransformAsync(addresses.addresses, async (v) => {
          return (await mapper.tryMap<RealUsers>(v, UserEntities.RealUsers))?.users;
        });
        // Flatten
        const realUsersReduced: RealUser[] = realUsers.reduce(function (prev, curr) {
          return prev.concat(curr);
        });

        return mapper.tryMap(makeRealUsers(realUsersReduced), UserEntities.AddressesInfo);
      }
    );

    /* Determines the telemetry address for different types */
    this.addBasicMapping(UserEntities.Role, UserEntities.TelemetryAddress, (role) => {
      return makeTelemetryAddress(role);
    });
    this.addEntityMapping<LocationRole>(
      UserEntities.LocationRole,
      UserEntities.TelemetryAddress,
      async (locRole, mapper) => {
        /* Check if the location is a cluster */
        let sub: Entity | undefined;
        const clusterEnt = await mapper.tryMap(locRole.location, LocationInterfaces.ClusterKey);
        if (clusterEnt) {
          /* Check the cluster function */
          const func = await mapper.tryMap<LocationClusterFunctionEntity>(
            clusterEnt,
            LocationClusterRelatedFunction.interface.type
          );
          if (
            func &&
            (func.function === LocationClusterFunction.OperatingRoomCluster ||
              func.function === LocationClusterFunction.PatientWard)
          )
            sub = locRole.location;
        }
        return makeTelemetryAddress(locRole.role, sub);
      },
      { weight: 2 }
    );
    this.addBasicMapping<SpecialtyLocationRole>(
      UserEntities.SpecialtyLocationRole,
      UserEntities.TelemetryAddress,
      (specLocRole) => {
        return makeTelemetryAddress(specLocRole.role, specLocRole.specialty);
      }
    );
    this.addEntityMapping(
      UserInterfaces.Key,
      UserEntities.TelemetryAddress,
      async (user, mapper) => {
        /* Get roles */
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
        if (!rolesEnt || !rolesEnt.roles.length) return undefined;
        /* Check if this is a plain role */
        if (rolesEnt.roles[0].entityType !== UserEntities.Role)
          return mapper.tryMap(rolesEnt.roles[0], UserEntities.TelemetryAddress);
        /* Try to determine specialty to augment role */
        const specsEnt = await mapper.tryMap<SpecialtiesEntity>(
          user,
          UserSpecialties.interface.type
        );
        return makeTelemetryAddress(rolesEnt.roles[0], specsEnt?.specialties[0]);
      }
    );

    // Watch for "Device Delete" notifications and delete the PushDevice from the Addresses containing the device
    this.addInvokeHandler(
      { entityType: DeviceAnchor.deleteNotificationModel.type },
      async (entity, mapper) => {
        const device = entity[DeviceAnchor.deleteNotificationModel.props[0]] as Entity;
        const deviceAddresses = await mapper.map<Addresses>(device, AddressPushDevices.anchorsType);
        for (const add of deviceAddresses.addresses) {
          this.invoke(AddressPushDevices.remove(add, device));
        }
        return undefined;
      }
    );
  }

  static async doSqiorEmployeeHandling(
    realUsers: (RealUser | undefined)[],
    mapper: DomainInterface
  ) {
    // Filter out sqior Employees
    const groups = new Map<string, Entity | undefined>();
    const realUsersWOSqior = await arrayFilterAsync(realUsers, async (ru) => {
      let filter = true;
      const roles = ru?.user && (await mapper.tryMap<RolesEntity>(ru?.user, UserEntities.Roles));
      if (roles && roles.roles.findIndex((r) => mapper.isEqual(r, RoleSqiorEmployee)) >= 0) {
        filter = false;
        groups.set(ru.group ? mapper.makeKey(ru.group) : '', ru.group);
      }
      return filter;
    });
    for (const [, g] of groups) {
      addRealUsers(
        realUsersWOSqior,
        await mapper.tryMap<RealUsers>(
          makeRoleEntity(Roles.SqiorEmployee),
          UserEntities.RealUsers,
          preferRole()
        ),
        g
      );
    }
    return realUsersWOSqior;
  }

  private async mapLocationRolesToRealUsers(
    locRole: LocationRole,
    mapper: TrackingDomainInterface
  ) {
    type Helper = {
      u: Entity;
      lc: number;
      r: RoleEntity[];
      rt: Entity;
    };
    mapper.cacheAccess(
      new TimedCacheState(addHours(1, mapper.steadyTimer.now), mapper.steadyTimer)
    ); // This mapping is time dependent
    // Find users which have something to do with the requested location
    const matchingUsers: UsersEntity = await mapper.map<UsersEntity>(
      locRole.location,
      UserActiveLocations.anchorsType
    );
    // Map some attributes we need later
    const matchingUserRoles: Helper[] = await arrayTransformAsync(
      matchingUsers.users,
      async (user) => {
        return {
          u: user,
          lc:
            (await mapper.tryMap<TimestampEntity>(user, UserInterfaces.LastConnect))?.timestamp ||
            0,
          r: (await mapper.tryMap<RolesEntity>(user, UserInterfaces.EffectiveRoles))
            ?.roles as RoleEntity[],
          rt: locRole,
        };
      }
    );
    // Filter out the user which haven't the right role
    const matchingUsersByRole: Helper[] = matchingUserRoles.filter(
      (mur) => mur.r?.findIndex((r) => mapper.makeKey(r) === mapper.makeKey(locRole)) >= 0
    );
    // Add users with permanent location
    const permLoc = await mapper.tryMap<Entity>(locRole.role, UserInterfaces.PermanentLocation);
    const permLocMatch = permLoc && (await mapper.isEquivalent(permLoc, locRole.location));
    if (permLocMatch) {
      const matchingUsersPL: UsersEntity | undefined = await mapper.map<UsersEntity>(
        locRole.role,
        UserRoles.anchorsType
      );
      const matchingUserRolesPL = await arrayTransformAsync(matchingUsersPL.users, async (user) => {
        return {
          u: user,
          lc:
            (await mapper.tryMap<TimestampEntity>(user, UserInterfaces.LastConnect))?.timestamp ||
            0,
          r: (await mapper.tryMap<RolesEntity>(user, UserEntities.Roles))?.roles as RoleEntity[],
          rt: locRole.role,
        };
      });
      matchingUsersByRole.push(...matchingUserRolesPL);
    }
    // Find the role which is/was most recently online
    matchingUsersByRole.sort((a, b) => -1 * (a.lc - b.lc)); // Sort by time DESC
    const final = matchingUsersByRole.filter(
      (r) => mapper.steadyTimer.now - r.lc < addHours(HoursUntilOffline)
    );
    if (final.length > 0) {
      return makeRealUsers(
        final.map((r) =>
          makeRealUser(
            r.u,
            prefersRole(mapper.tryContext<RealUserMapOptions>(RealUserContextProperty))
              ? locRole
              : r.u,
            r.rt,
            mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)?.commAddressOption
          )
        )
      );
    }
    return makeRealUsers([
      makeRealUser(
        undefined,
        prefersRole(mapper.tryContext<RealUserMapOptions>(RealUserContextProperty))
          ? locRole
          : undefined,
        await mapper.map(permLocMatch ? locRole.role : locRole, CoreEntities.TextTemplate),
        mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)?.commAddressOption
      ),
    ]);
  }

  private async replaceDummyWardEntry(
    realUsers: RealUser[],
    originalRole: LocationRole,
    mapper: TrackingDomainInterface
  ) {
    const numbers = await mapper.tryMap<PhoneNumbers>(originalRole, DeviceInterfaces.PhoneNumbers);

    if (!numbers || !numbers.phoneNumbers.length) return;
    const index = realUsers.findIndex((e) => e.user === undefined);
    if (~index) {
      realUsers[index].user = makeWardAlternativeUser(originalRole); // makeStationUser(originalRole)
      delete realUsers[index].roleText;
    } else realUsers.push(makeRealUser(makeWardAlternativeUser(originalRole)));
  }
}

// Helper function to determine the correct communication address
// Enusres, a role is mapped to corresponding locationRole with PermanentLocation if applicable
async function roleOrUserToCommAddr(
  mapper: TrackingDomainInterface,
  role: Entity,
  user: Entity | undefined
) {
  let commAddress: Entity = role;
  const permLoc = await mapper.tryMap<Entity>(role, UserInterfaces.PermanentLocation);
  if (permLoc) commAddress = makeLocationRole(permLoc, role);

  if (prefersRole(mapper.tryContext<RealUserMapOptions>(RealUserContextProperty)))
    return commAddress;
  else return user;
}

function sortUnknownAddressesLast(addrs: AddressInfoVM[]) {
  return [...addrs.filter((e) => e.id !== undefined), ...addrs.filter((e) => e.id === undefined)];
}
