import { Entity } from '@sqior/js/entity';
import { URLEntity } from './url-vm';

export enum AttachmentContentType {
  Image = 'image',
}

export type AttachmentVM<URLType extends Entity = URLEntity> = {
  url: URLType; // URL to retrieve the attachment, might be auth protected by e.g. Bearer token
  type: AttachmentContentType; // Sqior specific type for easier interpretation of attachment
  contentType: string; // Content / MIME type as defined by IANA (e.g. image/jpeg)
};
