import { ClockTimestamp } from '@sqior/js/data';
import { SQIORDate, makeCurrentDate } from './date';
import { makeTimeEntity } from './time';
import { makeTimestampFromDateTime } from './timestamp';

/** Helper class for the demo mode */
export class DemoTime {
  /** Separating timestamp from demo dates to real dates */
  private static DemoTimestampSeparator = new Date(2023, 0, 1).getTime();

  /** Shift to apply to move a timestamp in the past to the current time */
  private static DemoTimestampShift =
    makeTimestampFromDateTime(makeCurrentDate(), makeTimeEntity(1, 0)).timestamp -
    makeTimestampFromDateTime(SQIORDate, makeTimeEntity(1, 0)).timestamp;

  /** Shifts a demo timestamp, if applicable */
  static shiftTimestamp(ts: ClockTimestamp) {
    if (ts > this.DemoTimestampSeparator) return ts;
    return ts + this.DemoTimestampShift;
  }

  /** Shifts a timestamp back to demo time, if applicable */
  static shiftTimestampBack(ts: ClockTimestamp, ref: ClockTimestamp) {
    if (ts <= this.DemoTimestampSeparator || ref > this.DemoTimestampSeparator) return ts;
    return ts - this.DemoTimestampShift;
  }
}
