import { AddressGroupInfoVM, AddressInfoVM } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface, SQIORAssistant } from '@sqior/js/meta';
import { UserEntities, UserInterfaces } from './user-definitions';

/* List of addresses */

export type Addresses = Entity & { addresses: Entity[] };
export function makeAddresses(addresses: Entity[]): Addresses {
  return { entityType: UserEntities.Addresses, addresses: addresses };
}

export type AddressInfo = Entity & { info: AddressInfoVM };
export const AddressInfoModel: EntityModel = { type: UserEntities.AddressInfo, props: ['info'] };
export function makeAddressInfo(info: AddressInfoVM): AddressInfo {
  return { entityType: UserEntities.AddressInfo, info: info };
}

export type AddressesInfo = Entity & { infos: AddressGroupInfoVM[] };
export const AddressesInfoModel: EntityModel = {
  type: UserEntities.AddressesInfo,
  props: ['infos'],
};
export function makeAddressesInfo(infos: AddressGroupInfoVM[]): AddressesInfo {
  return { entityType: UserEntities.AddressesInfo, infos: infos };
}

/* Addresses accessible to a user */

export const AccessibleAddressesModel: Interface = {
  type: UserInterfaces.AccessibleAddresses,
  requires: UserInterfaces.Address,
};

/** Each Sender is mapped to an ReplyToAddress which represents a valid address to reply - mostly Role if available or the individum */
export const ReplyToAddressModel: Interface = {
  type: UserInterfaces.ReplyToAddress,
  requires: UserInterfaces.Address,
};

/* Sender interface - a sender can both be an address or a role user (only a role by itself is considered a valid address) */

export const SenderModel: Interface = {
  type: UserInterfaces.Sender,
  requires: [UserInterfaces.Key, UserEntities.RoleUser],
};
export const ActualSenderModel: Interface = {
  type: UserInterfaces.ActualSender,
  requires: UserInterfaces.Key,
};

/* Sender on behalf of another user */

export type OnBehalf = Entity & { represented: Entity; actual: Entity };
export const OnBehalfModel: EntityModel = {
  type: UserEntities.OnBehalf,
  props: ['represented', 'actual'],
  keys: ['represented', 'actual'],
};
export function onBehalfOf(represented: Entity, actual = SQIORAssistant): OnBehalf {
  return { entityType: UserEntities.OnBehalf, represented: represented, actual: actual };
}
