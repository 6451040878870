import { AsyncIterator, Closable } from '@sqior/js/async';
import { CacheCleanup, CacheHolder, CacheState, CacheTracer } from '@sqior/js/cache';
import {
  addMinutes,
  isEqual,
  KeyPairMap,
  memoryConsumption,
  tryWalk,
  unflatten,
  Value,
  ValueObject,
} from '@sqior/js/data';
import { DatabaseInterface } from './database-interface';
import { DocumentLoader } from './document-loader';
import { PerformanceMetric, PerformanceUnit } from '@sqior/js/log';
import { SequencedObject } from '@sqior/js/event-stream';

interface ChangeObserver {
  changed(id: string, doc: ValueObject): boolean;
}
interface RemovalObserver {
  removed(id: string): void;
}

interface CollectionCacheDocInterface<Type extends SequencedObject> {
  loader: DocumentLoader<Type>;
  docInitialized(obs: DocumentObserver<Type>): void;
  observeID(id: string, ro: RemovalObserver): void;
  unobserveID(id: string, ro: RemovalObserver): void;
}

interface CollectionCacheValueInterface<Type> {
  get(key: string, tracer?: CacheTracer): Promise<Type | undefined>;
}

interface CollectionCacheKeysInterface<Type extends SequencedObject, KeyType> {
  findKeys(prop: string, value: string): Promise<AsyncIterator<[string, number, KeyType]>>;
  keysInitialized(ko: KeysObserver<Type, KeyType>): void;
  observeID(id: string, ro: RemovalObserver, co?: ChangeObserver): void;
  unobserveID(id: string, ro: RemovalObserver, co?: ChangeObserver): void;
}

class DocumentObserver<Type extends SequencedObject> implements RemovalObserver {
  constructor(collCache: CollectionCacheDocInterface<Type>, key: string, cleanUp: CacheCleanup) {
    this.collCache = collCache;
    this.key = key;
    this.cache = new CacheHolder(cleanUp);
    this.cache.closed?.on(() => {
      if (this.id) this.collCache.unobserveID(this.id, this);
    });
    this.docPromise = this.initDoc();
  }

  private async initDoc() {
    /* Try to load the document from the DB */
    const res = await this.collCache.loader.get(this.key);
    /* Check if this document is more recent then a document received by a watch in the meantime, if applicable.
       Do not use the document if it was removed in the meantime  */
    if (
      res &&
      (!this.doc || res.obj.sequenceNumber > this.doc.sequenceNumber) &&
      !this.removals.has(res.id)
    ) {
      this.doc = res.obj;
      this.id = res.id;
    }
    /* Indicate that the initialization phase is over */
    this.docPromise = undefined;
    this.removals.clear();
    this.collCache.docInitialized(this);
    /* Register for updates to this ID if already set */
    if (this.id) this.collCache.observeID(this.id, this);
  }

  set(doc: Type, id: string) {
    /* Check if this document is more recent than a potential existing one */
    if (this.doc && this.doc.sequenceNumber >= doc.sequenceNumber) return;
    /* Take over document */
    this.doc = doc;
    this.id = id;
    /* Check if the initialization phase is over */
    if (this.docPromise) return;
    /* Register for updates to this ID */
    this.collCache.observeID(id, this);
    /* Indicate that this changed */
    this.cache.touch();
  }

  removed(id: string) {
    /* Check if this relates to the current document */
    if (this.id === id) {
      this.doc = undefined;
      this.id = undefined;
      /* Check if the initialization phase is over */
      if (!this.docPromise) {
        /* Indicate that this changed */
        this.cache.touch();
        return;
      }
    }
    /* During the initialization phase, all removals need to be remembered as they could relate to the document fetched */
    if (this.docPromise) this.removals.add(id);
  }

  async document(tracer?: CacheTracer) {
    /* Wait for the initialization if applicable */
    if (this.docPromise) await this.docPromise;
    this.cache.access(tracer);
    return this.doc;
  }

  get memory() {
    return memoryConsumption(this.doc);
  }

  private collCache: CollectionCacheDocInterface<Type>;
  private key: string;

  private docPromise: Promise<void> | undefined;
  private removals = new Set<string>();

  private doc: Type | undefined;
  private id: string | undefined;
  cache: CacheHolder;
}

class ValueObserver<Type extends ValueObject> implements CacheTracer {
  constructor(
    collCache: CollectionCacheValueInterface<Type>,
    key: string,
    prop: string,
    cleanUp: CacheCleanup
  ) {
    this.collCache = collCache;
    this.key = key;
    this.prop = prop;
    this.cache = new CacheHolder(cleanUp);
    this.cache.closed?.on(() => {
      this.closed = true;
    });
    this.valuePromise = this.detValue();
  }

  private async detValue() {
    /* Wait for the document to be loaded */
    const doc = await this.collCache.get(this.key, this);
    if (this.closed) return;
    this.valuePromise = undefined;
    const value = doc ? tryWalk(doc, this.prop) : undefined;
    /* Redetermine the value, if the document changes or if is already invalid */
    if (this.docCache) {
      if (this.docCache.valid)
        this.docCache.invalidated?.on(() => {
          if (!this.closed) this.valuePromise = this.detValue();
        });
      else this.valuePromise = this.detValue();
    }
    /* Update the value cache and signal change only if value changed */
    if (!isEqual(value, this.val)) {
      this.val = value;
      this.cache.touch();
    }
  }

  cacheAccess(cs: CacheState) {
    this.docCache = cs;
  }

  async value(tracer?: CacheTracer) {
    if (this.valuePromise) await this.valuePromise;
    this.cache.access(tracer);
    return this.val;
  }

  private collCache: CollectionCacheValueInterface<Type>;
  private key: string;
  private prop: string;

  private valuePromise: Promise<void> | undefined;
  private val: Value | undefined;

  private docCache: CacheState | undefined;
  cache: CacheHolder;
  private closed = false;
}

class KeysObserver<Type extends SequencedObject, KeyType>
  implements ChangeObserver, RemovalObserver, Closable
{
  constructor(
    collCache: CollectionCacheKeysInterface<Type, KeyType>,
    prop: string,
    value: string,
    keyProp: string,
    cleanUp: CacheCleanup
  ) {
    this.collCache = collCache;
    this.prop = prop;
    this.value = value;
    this.keyProp = keyProp;
    this.keysPromise = this.initKeys();
    this.cache = new CacheHolder(cleanUp);
    this.cache.closed?.on(() => {
      this.closed = true;
      for (const id of this.keyMap.keys()) this.collCache.unobserveID(id, this, this);
    });
  }

  async close() {
    this.closed = true;
    await this.keys();
  }

  async keys(tracer?: CacheTracer) {
    /* Wait for the initialization to complete, if applicable */
    if (this.keysPromise) await this.keysPromise;
    /* Create vector of keys */
    const res: KeyType[] = [];
    for (const key of this.keyMap.values()) res.push(key);
    /* Register cache access */
    this.cache.access(tracer);
    return res;
  }

  async initKeys() {
    /* Find all existing keys featuring this value */
    const ks = new Map<string, [number, KeyType]>();
    for await (const entry of await this.collCache.findKeys(this.prop, this.value))
      if (entry) ks.set(entry[0], [entry[1], entry[2]]);
    /* Do not continue if this is already closed */
    if (this.closed) return;
    /* Loop for all keys found and check if they have been altered or removed in the meantime */
    for (const entry of ks) {
      const altered = this.alteredKeys.get(entry[0]);
      if (!this.removedKeys.has(entry[0]) && (!altered || altered < entry[1][0]))
        this.keyMap.set(entry[0], entry[1][1]);
    }
    /* Loop for all added additional keys */
    for (const entry of this.addedKeys)
      if (!this.keyMap.has(entry[0])) this.keyMap.set(entry[0], entry[1]);
    /* Indicate that the initialization is now finalized */
    this.keysPromise = undefined;
    this.addedKeys.clear();
    this.alteredKeys.clear();
    this.removedKeys.clear();
    this.collCache.keysInitialized(this);
    /* Register for the observation of the removal of all collected IDs */
    for (const ids of this.keyMap.keys()) this.collCache.observeID(ids, this, this);
  }

  private matches(doc: Type) {
    const value = tryWalk(doc, this.prop);
    /* Check if this is an array */
    if (value instanceof Array) {
      for (const el of value) if (el === this.value) return true;
      return false;
    }
    return value === this.value;
  }

  update(doc: Type, id: string) {
    /* Check if the document has the desired value */
    if (this.matches(doc)) {
      this.addedKeys.set(id, doc[this.keyProp] as unknown as KeyType);
      this.alteredKeys.delete(id);
    } else {
      this.addedKeys.delete(id);
      this.alteredKeys.set(id, doc.sequenceNumber);
    }
  }

  added(doc: ValueObject, id: string) {
    this.keyMap.set(id, doc[this.keyProp] as unknown as KeyType);
    this.cache.touch();
    /* Observe this ID from now on to be notified if it is removed or altered */
    this.collCache.observeID(id, this, this);
  }

  changed(id: string, doc: Type): boolean {
    /* Do not continue if this is already closed */
    if (this.closed) return false;
    /* Check if the document still has the correct value */
    if (this.matches(doc)) return true;
    this.keyMap.delete(id);
    this.cache.touch();
    return false;
  }

  removed(id: string): void {
    /* Do not continue if this is already closed */
    if (this.closed) return;
    /* Check if this is still in the initialization phase */
    if (this.keysPromise) {
      this.addedKeys.delete(id);
      this.alteredKeys.delete(id);
      this.removedKeys.add(id);
    } else {
      this.keyMap.delete(id);
      this.cache.touch();
    }
  }

  private collCache: CollectionCacheKeysInterface<Type, KeyType>;
  private prop: string;
  private value: string;
  private keyProp: string;

  private keysPromise: Promise<void> | undefined;
  private addedKeys = new Map<string, KeyType>();
  private alteredKeys = new Map<string, number>();
  private removedKeys = new Set<string>();

  private keyMap = new Map<string, KeyType>();
  cache: CacheHolder;

  private closed = false;
}

export class CollectionCache<
  Type extends SequencedObject = SequencedObject,
  KeyType extends Value = Value
> implements
    CollectionCacheDocInterface<Type>,
    CollectionCacheKeysInterface<Type, KeyType>,
    Closable
{
  constructor(
    db: DatabaseInterface,
    path: string,
    keyValueProp: string,
    keyProp: string,
    cleanUpTimeout = addMinutes(5)
  ) {
    this.keyValueProp = keyValueProp;
    this.keyProp = keyProp;
    this.loader = new DocumentLoader<Type>(db, path, keyProp);
    this.cleanUp = new CacheCleanup(cleanUpTimeout);

    this.watch = db.watch<Type>(path, {}, (watchRes) => {
      /* Check if the object was added or changed */
      if (watchRes.obj) {
        /* Check if the key property is contained */
        const key = watchRes.obj[this.keyProp];
        if (!key || typeof key !== 'string') return;
        /* Inform a potential observer for this document */
        const docObs = this.docObservers.get(key);
        if (docObs) docObs.set(watchRes.obj, watchRes.id);
        /* Inform all keys observers that are still initializing, as a document that featured the desired value could have been altered */
        for (const obs of this.keysObserversInitializing) obs.update(watchRes.obj, watchRes.id);
        /* Inform all change observers that have registered for this ID */
        const chgObs = this.changeObservers.get(watchRes.id);
        if (chgObs)
          /* Eliminate change observer from this set based on the return code */
          for (const obs of chgObs)
            if (!obs.changed(watchRes.id, watchRes.obj)) {
              chgObs.delete(obs);
              if (chgObs.size === 0) this.changeObservers.delete(watchRes.id);
            }
        /* Check all observed values and inform the matching ones */
        for (const entry of this.keysObservers.map) {
          const value = tryWalk(watchRes.obj, entry[0]);
          if (typeof value === 'string') {
            const obs = entry[1].get(value);
            if (obs && (!chgObs || !chgObs.has(obs))) obs.added(watchRes.obj, watchRes.id);
          } else if (value instanceof Array)
            for (const el of value)
              if (typeof el === 'string') {
                const obs = entry[1].get(el);
                if (obs && (!chgObs || !chgObs.has(obs))) obs.added(watchRes.obj, watchRes.id);
              }
        }
      } else {
        /* Inform all observers that are in their initialization phases */
        for (const obs of this.docObserversInitializing) obs.removed(watchRes.id);
        for (const obs of this.keysObserversInitializing) obs.removed(watchRes.id);
        /* Inform all observers that have registered for this ID - automatically unregister as this ID cannot be seen again */
        const remObs = this.removalObservers.get(watchRes.id);
        if (remObs) {
          for (const obs of remObs) obs.removed(watchRes.id);
          this.removalObservers.delete(watchRes.id);
        }
      }
    });
  }

  observeID(id: string, ro: RemovalObserver, co?: ChangeObserver): void {
    /* Add to existing set or establish new set */
    const obs = this.removalObservers.get(id);
    if (obs) obs.add(ro);
    else this.removalObservers.set(id, new Set<RemovalObserver>([ro]));
    /* If applicable, also add to the change observers */
    if (co) {
      const cobs = this.changeObservers.get(id);
      if (cobs) cobs.add(co);
      else this.changeObservers.set(id, new Set<ChangeObserver>([co]));
    }
  }

  unobserveID(id: string, ro: RemovalObserver, co?: ChangeObserver): void {
    /* Remove removal observer */
    const obs = this.removalObservers.get(id);
    if (obs) {
      obs.delete(ro);
      if (obs.size === 0) this.removalObservers.delete(id);
    }
    /* If applicable, also remove the change observers */
    if (co) {
      const cobs = this.changeObservers.get(id);
      if (cobs) {
        cobs.delete(co);
        if (cobs.size === 0) this.changeObservers.delete(id);
      }
    }
  }

  async close() {
    /* Close all currently ongoing loads */
    await this.loader.close();
    /* Close all keys observers that are in their initial phase */
    for (const ko of this.keysObserversInitializing) await ko.close();
    await this.watch.close();
    /* Close cache cleanup */
    await this.cleanUp.close();
  }

  async get(key: string, tracer?: CacheTracer): Promise<Type | undefined> {
    /* Find or create a document observer for this key */
    let obs = this.docObservers.get(key);
    if (!obs) {
      this.docObservers.set(key, (obs = new DocumentObserver<Type>(this, key, this.cleanUp)));
      this.docObserversInitializing.add(obs);
      obs.cache.closed?.on(() => {
        this.docObservers.delete(key);
      });
    }
    return obs.document(tracer);
  }
  docInitialized(obs: DocumentObserver<Type>) {
    this.docObserversInitializing.delete(obs);
  }

  async getValue(key: string, prop: string, tracer?: CacheTracer): Promise<Value | undefined> {
    /* Find or create a value observer for this key and property */
    let obs = this.valueObservers.get(key, prop);
    if (!obs) {
      this.valueObservers.set(
        key,
        prop,
        (obs = new ValueObserver<Type>(this, key, prop, this.cleanUp))
      );
      obs.cache.closed?.on(() => {
        /* Remove this specific value observer */
        this.valueObservers.delete(key, prop);
        /* In case there is no value observer left, the document observer can also be closed */
        if (!this.valueObservers.map.get(key)) {
          const docObs = this.docObservers.get(key);
          if (docObs) docObs.cache.closed?.emit();
        }
      });
    }
    return obs.value(tracer);
  }

  async findKeys(prop: string, value: string): Promise<AsyncIterator<[string, number, KeyType]>> {
    const search: ValueObject = {};
    unflatten(search, prop, value);
    const it = (
      await this.loader.db.find<Type>(this.loader.path, search, {
        include: ['sequenceNumber', this.keyValueProp],
      })
    ).it;
    return new AsyncIterator(async () => {
      const res = await it();
      if (res) return [res.id, res.obj.sequenceNumber, res.obj[this.keyValueProp] as KeyType];
      else return null;
    });
  }
  keysInitialized(ko: KeysObserver<Type, KeyType>): void {
    this.keysObserversInitializing.delete(ko);
  }

  async getKeys(prop: string, value: string, tracer?: CacheTracer): Promise<KeyType[]> {
    /* Find or create a keys observer for this property value pair */
    let obs = this.keysObservers.get(prop, value);
    if (!obs) {
      this.keysObservers.set(
        prop,
        value,
        (obs = new KeysObserver<Type, KeyType>(this, prop, value, this.keyValueProp, this.cleanUp))
      );
      this.keysObserversInitializing.add(obs);
      obs.cache.closed?.on(() => {
        this.keysObservers.delete(prop, value);
      });
    }
    return obs.keys(tracer);
  }

  /** Collects performance metrics */
  collectMetrics(): PerformanceMetric[] {
    let docMem = 0;
    for (const doco of this.docObservers.values()) docMem += doco.memory;
    return [
      { id: 'Documents', value: this.docObservers.size, unit: PerformanceUnit.Count },
      { id: 'DocumentMemory', value: docMem, unit: PerformanceUnit.Memory },
      { id: 'ValueObservers', value: this.valueObservers.size, unit: PerformanceUnit.Count },
      { id: 'KeysObservers', value: this.keysObservers.size, unit: PerformanceUnit.Count },
    ];
  }

  private keyValueProp: string;
  private keyProp: string;

  private watch: Closable;
  loader: DocumentLoader<Type>;
  private cleanUp: CacheCleanup;

  private changeObservers = new Map<string, Set<ChangeObserver>>();
  private removalObservers = new Map<string, Set<RemovalObserver>>();

  private docObservers = new Map<string, DocumentObserver<Type>>();
  private docObserversInitializing = new Set<DocumentObserver<Type>>();

  private valueObservers = new KeyPairMap<string, string, ValueObserver<Type>>();

  private keysObservers = new KeyPairMap<string, string, KeysObserver<Type, KeyType>>();
  private keysObserversInitializing = new Set<KeysObserver<Type, KeyType>>();
}
