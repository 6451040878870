import { now } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { DateEntity, makeDateFromTimestamp, TimeEntities } from '@sqior/plugins/time';
import { PersonEntities } from './person-definitions';

/* Date of birth */

export type DateOfBirth = DateEntity;
export const DateOfBirthModel: EntityModel = {
  type: PersonEntities.DateOfBirth,
  props: [],
  keys: ['day', 'month', 'year'],
  extends: TimeEntities.Date,
  unclassified: false,
};

/* Age */

export type AgeEntity = Entity & { age: number };
export const AgeModel = { type: PersonEntities.Age, props: ['age'] };
export function makeAgeEntity(age: number): AgeEntity {
  return { entityType: PersonEntities.Age, age: age };
}
export function makeCurrentAge(dob: DateOfBirth, current = now()): AgeEntity {
  const date = makeDateFromTimestamp(current);
  let age = date.year - dob.year;
  if (date.month < dob.month || (date.month === dob.month && date.day < dob.day)) age--;
  return makeAgeEntity(age);
}
