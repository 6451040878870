import { LogTextData, TextLogBackend } from '@sqior/js/log';

export class NativeDeviceLogger extends TextLogBackend {
  constructor() {
    super();
    /* Check for iOS log interface */
    const win: any = window;
    if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.log) {
      const logObj = win.webkit.messageHandlers.log;
      this.specLog = (text) => {
        logObj.postMessage(text);
      };
    }
  }

  logText(msg: LogTextData) {
    if (!msg.text.length) return;
    /* Log to device specific log system, if applicable */
    if (this.specLog) this.specLog(msg.text);
  }

  private specLog?: (text: string) => void;
}
