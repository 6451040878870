/* Helper type to use for binary values - on browsers this will wrap a Blob and with node.js this will be a Buffer */
export class Bytes {
  constructor(buf: unknown) {
    this.buffer = buf;
  }

  as<Type>() {
    return this.buffer as Type;
  }

  buffer: unknown;
}
