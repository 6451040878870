import { ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { SelectionMenu } from '@sqior/viewmodels/input';
import { EntityHTML, EntityHTMLWithInterpreter } from './entity-html';
import { AttachmentVM } from '@sqior/viewmodels/visual';

export enum ReadConfirmationTypes {
  Implicit = 'Implicit',
  Explicit = 'Explicit',
}
export enum ReadConfirmationStatus {
  Unknown = 'Unknown',
  ImplicitPartially = 'ImplicitPartially',
  Implicit = 'Implicit',
  Explicit = 'Explicit',
  ExplicitPartially = 'ExplicitPartially',
}

export type ResponseOptionVM = {
  id: string; // InformProjectionVM ID
  responseId: number; // Index of response, zero-based
  text: EntityHTML; // Visual text of option
  addOptionsInterpreterKey?: string;
  addOptionsMenu?: SelectionMenu;
};
export type ResponseOptionsVM = {
  options: ResponseOptionVM[]; // Potential response options
  timeout?: number | 'Infinity'; // Time until an answer must be given (ms since epoch)
  relativeTimeout?: number | 'Infinity'; // Time until an answer must be given (ms since message sent time)

  response?: EntityHTMLWithInterpreter; // Sent response as visual
  undoInterpreterKey?: string; // If undoable, this contains the undo InterpreterId
  responseTimestamp?: number; // Sent response time (ms since epoch)
};

export enum InformProjectionFlag {
  Normal,
  Undo,
  Stop,
}
export enum InformProjectionImportance {
  Normal,
  Important,
}
export enum InformProjectionCategory {
  Escalation,
  Task,
  Information,
  ChatMessage,
  CommandFeedback,
}

/** State ViewModel of an inform message
 */
export type InformProjectionVM = EntityHeader & {
  id: string; // InformProjectionVM ID
  additionalIds?: string[]; // Additional InformProjectionVM IDs if this is an artificial InformProjectionVM combined out of several objects
  reference: ValueObject; // InformProjectionVM ID as Entity
  timestamp: number; // ms since epoch
  sequenceId: number; // Unique identifier of the message in the system
  related?: Entity; // Entity referencing the related object
  undoInterpreterKey?: string; // If undoable, this contains the undo InterpreterId
  categorie?: InformProjectionCategory; // Escalation, Task, Information
  sender: EntityHTMLWithInterpreter; // sender
  recipient: EntityHTMLWithInterpreter; // recipient
  message: EntityHTMLWithInterpreter | EntityHTMLWithInterpreter[]; // the message to inform
  attachments?: AttachmentVM[]; // Added attachments

  responseOptions?: ResponseOptionsVM[]; // ResponseOptions from InformEntity incl. sent response as visual
  checkBack?: boolean; // Flag whether there should be the option to check back with the sender
  readConfirmationStatus: ReadConfirmationStatus;
  myReadConfirmationStatus?: ReadConfirmationTypes;
  sentByMyself: boolean;
  flag?: InformProjectionFlag; // Flag indicating the status of this message
  importance: InformProjectionImportance; // Flag indicating the importance
  priority: string; // Priority of the item
};

export type InformGroupVM = {
  name: EntityHTML;
  id?: ValueObject;
  items: InformProjectionVM[];
};

export type ConversationStateData = InformGroupVM[];

export function isEscalation(item: InformProjectionVM) {
  return item.categorie === InformProjectionCategory.Escalation;
}
export function isInformation(item: InformProjectionVM) {
  return isPureInformation(item) || isChatMessage(item);
}
export function isCommandFeedback(item: InformProjectionVM) {
  return item.categorie === InformProjectionCategory.CommandFeedback;
}
export function isPureInformation(item: InformProjectionVM) {
  return item.categorie === InformProjectionCategory.Information;
}
export function isChatMessage(item: InformProjectionVM) {
  return item.categorie === InformProjectionCategory.ChatMessage;
}
export function isTask(item: InformProjectionVM) {
  return item.categorie === InformProjectionCategory.Task;
}
export function isImportant(item: InformProjectionVM) {
  return item.importance === InformProjectionImportance.Important;
}
/** Checks whether the item is normal but not stop or undo */
export function isFlaggedActive(item: InformProjectionVM) {
  return item.flag === undefined || item.flag === InformProjectionFlag.Normal;
}
/** Checks whether the item is normal or stop but not undo */
export function isFlaggedVisible(item: InformProjectionVM) {
  return (
    item.flag === undefined ||
    item.flag === InformProjectionFlag.Normal ||
    item.flag === InformProjectionFlag.Stop
  );
}
/** Returns true if myReadConfirmationStatus of the specified item has at least the desired read confirmation level
 *  Order is defined as: undefined, Implicit, Explicit
 */
export function isMyReadConfirmationTypeAtLeast(
  item: InformProjectionVM,
  confirmationType: ReadConfirmationTypes
) {
  if (confirmationType === undefined) return true;

  if (confirmationType === ReadConfirmationTypes.Implicit)
    return (
      item.myReadConfirmationStatus === ReadConfirmationTypes.Implicit ||
      item.myReadConfirmationStatus === ReadConfirmationTypes.Explicit
    );

  if (confirmationType === ReadConfirmationTypes.Explicit)
    return item.myReadConfirmationStatus === ReadConfirmationTypes.Explicit;

  return false;
}

export function hasOpenResponseOptions(item: InformProjectionVM) {
  if (!Array.isArray(item.responseOptions)) return false;

  for (const ro of item.responseOptions) if (ro.response === undefined) return true;

  return false;
}

/** Data sent by the Operation setting the response
 */
export type ResponseData = {
  id: string; // InformProjectionVM ID
  sequenceId: number; // InformProjectionVM sequence number
  responseIndex: number; // Zero based index to the ResponseOptions[] referenced by sequenceId
  responseId: number; // Zero based index of response within referenced ResponseOptions by responseIndex
  addResponseId?: number; // Zero based index of additional response within referenced ResponseOption by responseId and responseIndex
  entity?: Entity; // Selected entity for complex selection options
  timestamp: number; // Client timestamp when selecting response
};

export type MarkReadData = {
  id: string[]; // InformProjectionVM ID
  confirmationType: ReadConfirmationTypes;
  timestamp: number; // Client timestamp when marked read
};

export function ResponseCommand(data: ResponseData): OperationSpec<ResponseData> {
  return { type: OperationType.Add, path: 'communication-response', data: data };
}

export function MarkRead(data: MarkReadData): OperationSpec<MarkReadData> {
  return { type: OperationType.Add, path: 'communication-read-confirmation', data: data };
}
