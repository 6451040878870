import {
  ChecklistOptionSentiment,
  ChecklistParagraphViewModel,
  ChecklistSetData,
} from '@sqior/viewmodels/checklist';
import ChecklistItemControl from '../checklist-item-control/checklist-item-control';
import styles from './checklist-paragraph-control.module.css';
import { useEffect, useState } from 'react';
import { SvgIconExpandLess, SvgIconExpandMore } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';

export type ChecklistParagraphControlProps = {
  data: ChecklistParagraphViewModel;
  select: (item: number, data: ChecklistSetData) => void;
  first: boolean;
  readOnly: boolean;
  completed: boolean;
  initFold?: boolean;
  fold?: boolean;
  setFold?: (state: boolean) => void;
};

export function ChecklistParagraphControl(props: ChecklistParagraphControlProps) {
  /* Check if there are uncompleted items, in this no folding is allowed */
  const allOK = !props.data.items.find((item) => {
    for (const option of item.options)
      if (option.checked) return option.sentiment === ChecklistOptionSentiment.Negative;
    return true;
  });
  const checkedCount = props.data.items.filter((item) => {
    return item.options.find((opt) => {
      return opt.checked;
    });
  }).length;
  const negative = props.data.items.find((item) => {
    for (const option of item.options)
      if (option.checked && option.sentiment === ChecklistOptionSentiment.Negative) return true;
    return false;
  });
  /* Paragraph is folded by default if it is completed */
  const [localFold, setLocalFold] = useState(props.initFold ?? props.completed);
  const fold = props.fold ?? localFold;
  const setFold = props.setFold ?? setLocalFold;
  useEffect(() => {
    if (props.completed) setFold(true);
  }, [props.completed, setFold]);
  return (
    <div
      className={classes(
        styles[fold ? 'container-folded' : 'container'],
        props.first ? undefined : styles['separator']
      )}
    >
      <div className={styles['header-outer-container']}>
        <div
          className={styles['header-container']}
          onClick={() => {
            setFold(!fold);
          }}
        >
          <div className={styles['header-text-container']}>
            <span
              className={classes(
                styles['header'],
                allOK && fold
                  ? styles['completed-text']
                  : negative && fold
                  ? styles['challenged-text']
                  : undefined
              )}
            >
              {props.data.header}
            </span>
            {!props.completed && (
              <span className={styles['header-count']}>
                {'(' + checkedCount + '/' + props.data.items.length + ')'}
              </span>
            )}
          </div>
          {fold ? (
            <SvgIconExpandMore className={styles['more-button']} />
          ) : (
            <SvgIconExpandLess className={styles['less-button']} />
          )}
        </div>
        {!fold && <div className={styles['shadow-bottom']} />}
      </div>
      {!fold &&
        props.data.items.map((item, index) => (
          <ChecklistItemControl
            data={item}
            key={index}
            readOnly={props.readOnly}
            select={(data) => {
              if (!props.readOnly) {
                if (fold) setFold(false);
                props.select(index, data);
              }
            }}
          />
        ))}
    </div>
  );
}

export default ChecklistParagraphControl;
