import { addHours, ClockTimestamp } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { DateEntity, isDateBefore, makeDateFromTimestamp } from './date';
import { makeTimeEntity, TimeEntity } from './time';
import { TimeEntities } from './time-definitions';

/* Raw timestamp entity */

export type TimestampEntity = EntityHeader & { timestamp: ClockTimestamp };
export const TimestampEntityModel: EntityModel = {
  type: TimeEntities.Timestamp,
  props: ['timestamp'],
  unclassified: true,
};
export function makeTimestampEntity(timestamp: Date | ClockTimestamp): TimestampEntity {
  return {
    entityType: TimeEntities.Timestamp,
    timestamp: timestamp instanceof Date ? timestamp.getTime() : timestamp,
  };
}
export function extractTimestamp(ts: TimestampEntity): ClockTimestamp {
  if (typeof ts.timestamp === 'string') return new Date(ts.timestamp).getTime();
  return ts.timestamp;
}
export function addToTimestampEntity(te: TimestampEntity, ms: number): TimestampEntity {
  return makeTimestampEntity(extractTimestamp(te) + ms);
}

export function laterTimestamp(...timestamps: (TimestampEntity | undefined)[]) {
  const later = timestamps.reduce((prev, cur) => {
    if (prev === undefined) return cur;
    if (cur === undefined) return prev;
    return prev.timestamp >= cur.timestamp ? prev : cur;
  });
  if (later === undefined) throw new Error('at least one timestamp must be passed');
  return later;
}

/** Timestamps entity */

export type Timestamps = EntityHeader & { timestamps: Entity[] };
export const TimestampsModel: EntityModel = {
  type: TimeEntities.Timestamps,
  props: ['timestamps'],
};
export function makeTimestamps(timestamps: Entity[]): Timestamps {
  return { entityType: TimestampsModel.type, timestamps };
}

/* Function entity returning a timestamp from the time of calling plus a certain number of minutes */

export type InSecondsEntity = EntityHeader & { seconds: number };
export const InSecondsModel: EntityModel = {
  type: TimeEntities.InSeconds,
  props: ['seconds'],
};
export function inMinutesEntity(minutes: number): InSecondsEntity {
  return { entityType: TimeEntities.InSeconds, seconds: minutes * 60 };
}

/* Probabilistic timestamp storing a additional 95% CI bounds represented as the delta to the mean timestamp in ms */

export type ProbabilisticTimestamp = {
  timestamp: ClockTimestamp;
  low: ClockTimestamp;
  high: ClockTimestamp;
};
export type ProbabilisticTimestampEntity = TimestampEntity & ProbabilisticTimestamp;
export const ProbabilisticTimestampModel: EntityModel = {
  type: TimeEntities.ProbabilisticTimestamp,
  props: ['low', 'high'],
  extends: TimeEntities.Timestamp,
};
export function makeProbabilisticTimestamp(
  timestamp: ClockTimestamp | ProbabilisticTimestamp
): ProbabilisticTimestampEntity {
  if (typeof timestamp === 'object')
    return { entityType: TimeEntities.ProbabilisticTimestamp, ...timestamp };
  else
    return {
      entityType: TimeEntities.ProbabilisticTimestamp,
      timestamp: timestamp,
      low: timestamp,
      high: timestamp,
    };
}

/* Function performing a less than comparison of two timestamps */

export type TimestampLessThan = EntityHeader & { value: Entity; ref: Entity };
export const TimestampLessThanModel: EntityModel = {
  type: TimeEntities.TimestampLessThan,
  props: ['value', 'ref'],
};
export function timestampLessThan(value: Entity, ref: Entity): TimestampLessThan {
  return { entityType: TimeEntities.TimestampLessThan, value: value, ref: ref };
}

/* Combine date and time into a timestamp */

export function makeTimestampFromDateTime(dateEnt: Entity, timeEnt: Entity): TimestampEntity {
  const date = dateEnt as DateEntity,
    time = timeEnt as TimeEntity;
  return makeTimestampEntity(
    new Date(date.year, date.month - 1, date.day, time.hours, time.minutes)
  );
}

/* Check if timestamp is before another */

export function isTimestampBefore(first: Entity, second: Entity) {
  return (first as TimestampEntity).timestamp < (second as TimestampEntity).timestamp;
}

/* Check if a date or timestamp is before another */

export function isDateOrTimestampBefore(first: Entity, second: Entity) {
  /* Check the types provided */
  if (first.entityType === TimeEntities.Date)
    if (second.entityType === TimeEntities.Date)
      return isDateBefore(first as DateEntity, second as DateEntity);
    else
      return isDateBefore(
        first as DateEntity,
        makeDateFromTimestamp((second as TimestampEntity).timestamp)
      );
  else if (second.entityType === TimeEntities.Date)
    return !isDateBefore(
      second as DateEntity,
      makeDateFromTimestamp((first as TimestampEntity).timestamp)
    );
  return isTimestampBefore(first, second);
}

/* Returns the next date after the specified one */

export function relativeDate(date: DateEntity, offset: number): DateEntity {
  return makeDateFromTimestamp(
    addHours(24 * offset, makeTimestampFromDateTime(date, makeTimeEntity(12, 0)).timestamp)
  );
}
export function nextDate(date: DateEntity): DateEntity {
  return relativeDate(date, 1);
}
