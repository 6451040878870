import { Emitter } from '@sqior/js/event';
import { FacingMode, QRScannerControl } from '@sqior/react/qrcode';

/* eslint-disable-next-line */
export interface QRVideoControlProps {
  onClose: () => void;
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
}

export function QRVideoControl(props: QRVideoControlProps) {
  return (
    <QRScannerControl
      takePhoto={props.takePhoto}
      onPhoto={props.onPhoto}
      facing={props.facing}
      onFacing={props.onFacing}
      onClose={props.onClose}
    />
  );
}

export default QRVideoControl;
