import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-overview-suture-incision-duration.module.css';
import { ORWorkflowOverviewSutureIncisionDurationData } from '@sqior/viewmodels/orworkflow';
import { useTextResources } from '@sqior/react/uibase';
import { ZIndex } from '@sqior/react/utils';

export type ORWorkflowOverviewSutureIncisionDurationProps =
  FactoryProps<ORWorkflowOverviewSutureIncisionDurationData>;

export function ORWorkflowOverviewSutureIncisionDuration(
  props: ORWorkflowOverviewSutureIncisionDurationProps
) {
  const textDict = useTextResources();
  const cardWidth = factoryGetProp<number>('cardMinWidth', props);

  return (
    <div className={styles['container']} style={{ zIndex: ZIndex.SutureIncisionDuration }}>
      <div className={styles['sub-container']}>
        <span className={styles['duration']}>
          {props.data.duration + ' ' + textDict.get('min')}{' '}
          <span className={styles['description']}>{textDict.get('suture_incision_time')}</span>
        </span>
      </div>
    </div>
  );
}

export default ORWorkflowOverviewSutureIncisionDuration;
