import styles from './react-operation.module.css';

/* eslint-disable-next-line */
export interface ReactOperationProps {}

export function ReactOperation(props: ReactOperationProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactOperation!</h1>
    </div>
  );
}

export default ReactOperation;
