import { ClockTimestamp, ValueOrNothing } from '@sqior/js/data';

export enum LogLevel {
  Fatal,
  Critical,
  Warning,
  Info,
  Debug,
  Trace,
}
export type LogContent = string | ValueOrNothing[];
export type LogData = { level: LogLevel; content: LogContent; timestamp?: ClockTimestamp };
