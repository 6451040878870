import { AddOperation } from '@sqior/js/operation';
import { EmptyFilteredSetStateStatus, FilteredSetStateStatus } from '@sqior/js/state';
import { MaxCountFilteredSetStateData } from '@sqior/js/state-operation';
import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import { classes } from '@sqior/react/utils';
import { useContext } from 'react';
import styles from './filtered-set-state-status-load-more.module.css';
import { joinPath } from '@sqior/js/url';

/* eslint-disable-next-line */
export interface FilteredSetStateStatusLoadMoreProps {
  stateBasePath: string;
  operationPath?: string;
  className?: string;
  children?: React.ReactNode;
}

export function FilteredSetStateStatusLoadMore(props: FilteredSetStateStatusLoadMoreProps) {
  const stateBasePath = props.stateBasePath;
  const stateStatusPath = joinPath(stateBasePath, 'status');
  const operationPath = props.operationPath || joinPath(stateBasePath, 'maxCount');

  const dispatcher = useContext(OperationContext);
  const status = useDynamicState<FilteredSetStateStatus>(
    stateStatusPath,
    EmptyFilteredSetStateStatus
  );

  function loadMore() {
    const data: MaxCountFilteredSetStateData = { maxCountDelta: +30 };
    console.log(operationPath, data);
    dispatcher.handle(new AddOperation(data), operationPath);
  }

  const moreAvailable = status.numLimitted < status.numFiltered;
  return moreAvailable ? (
    <div className={classes(styles['container'], props.className)} onClick={loadMore}>
      {props.children || '... mehr'}
    </div>
  ) : null;
}
