import { Entity, EntityHeader } from '@sqior/js/entity';
import { DomainInterface, EntityModel, EntityRecord } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';
import { shallowClone } from '@sqior/js/data';
import { LocationRoleTemplate } from './role-entity';
import { arrayTransformAsync } from '@sqior/js/async';
import { UserDomain } from './user-domain';

export type RealUsers = Entity & { users: RealUser[] };
export const RealUsersModel: EntityModel = { type: UserEntities.RealUsers, props: ['users'] };
export function makeRealUsers(users: RealUser[]): RealUsers {
  const ret: RealUsers = { entityType: UserEntities.RealUsers, users: users };
  return ret;
}

/** Represents a real user
 *  By mapping from a role (or simlar) to RealUsers, the actual user representing a role is returned. These can be several users. Such an entry is represented by the Entity RealUser (or RealUsers respectively)
 */
export type RealUser = EntityHeader & {
  user?: Entity; // The actual user
  commAddress?: Entity; // The communication address - might be the user, but might also be a role specific address
  roleText?: Entity; // Role as text representation
  commAddressOption?: CommAddressOptions; // Flag indicating whether the user or role is preferred to be displayed (typically specified as context for a mapping call from Role to RealUser)
  group?: Entity;
};
export const RealUserModel: EntityModel = {
  type: UserEntities.RealUser,
  props: ['user', 'commAddress', 'roleText', 'commAddressOption', 'group'],
};
export function makeRealUser(
  user?: Entity,
  commAddress?: Entity,
  roleText?: Entity,
  commAddressOption?: CommAddressOptions,
  group?: Entity
): RealUser {
  const ret: RealUser = { entityType: UserEntities.RealUser };
  if (user !== undefined) ret.user = user;
  if (commAddress !== undefined) ret.commAddress = commAddress;
  if (roleText !== undefined) ret.roleText = roleText;
  if (commAddressOption) ret.commAddressOption = commAddressOption;
  if (group) ret.group = group;
  return ret;
}

export function addRealUsers(
  realUsers: (RealUser | undefined)[],
  realUsersToAdd: undefined | RealUsers | RealUser | (RealUser | undefined)[],
  group?: Entity
) {
  if (realUsersToAdd) {
    const arrRu = Array.isArray(realUsersToAdd)
      ? realUsersToAdd
      : 'users' in realUsersToAdd
      ? realUsersToAdd.users
      : [realUsersToAdd];
    for (const ru of arrRu) {
      if (ru) {
        const ruAdd = shallowClone(ru);
        ruAdd.group = group;
        realUsers.push(ruAdd);
      }
    }
  }
  return realUsers;
}

export enum CommAddressOptions {
  PreferUser,
  PreferRole,
}
export type RealUserMapOptions = Entity & {
  commAddressOption: CommAddressOptions;
};
export const RealUserMapOptionsModel: EntityModel = {
  type: UserEntities.RealUserMapOptions,
  props: ['commAddressOption'],
};
function makeRealUserMapOption(commAddressOption: CommAddressOptions): RealUserMapOptions {
  return { entityType: UserEntities.RealUserMapOptions, commAddressOption: commAddressOption };
}

export function preferRole(): EntityRecord {
  return { realUserOption: makeRealUserMapOption(CommAddressOptions.PreferRole) };
}

export function prefersRole(options?: RealUserMapOptions) {
  return options && options.commAddressOption === CommAddressOptions.PreferRole;
}

export type CommunicationRoles = EntityHeader & {
  roles: { role: Entity; group?: Entity }[];
};
export const CommunicationRolesModel: EntityModel = {
  type: UserEntities.CommunicationRoles,
  props: ['roles'],
};
export function makeCommunicationRoles(
  roles: { role: Entity; group?: Entity }[]
): CommunicationRoles {
  return { entityType: UserEntities.CommunicationRoles, roles: roles };
}

/** Helper function to create CommunicationRoles from LocationRoleTemplate */
export async function constructCommunicationRoles(
  mapper: DomainInterface,
  loc: Entity,
  roleTemplates: { role: LocationRoleTemplate; group?: Entity }[]
) {
  const commRolesWithGroup = await arrayTransformAsync(roleTemplates, async (rt) => {
    const role = await UserDomain.constructRole(mapper, loc, rt.role);
    return role && { role: role, group: rt.group };
  });
  return makeCommunicationRoles(commRolesWithGroup);
}
