import { addHours, addMinutes, ClockTimestamp } from '@sqior/js/data';
import {
  ALLOWED_INTERVAL,
  BASE_END_MINUTES,
  BASE_START_MINUTES,
  BaseIntervalAllowed,
} from './constants';
import { isAfter, isBefore } from 'date-fns';

interface convertMarkerTimestampParams {
  markerTimeStamp: ClockTimestamp;
  itemStart: ClockTimestamp;
  itemEnd: ClockTimestamp;
}

interface fromCoreHoursParams {
  core: { startHour: number; endHour: number };
  currentTime: ClockTimestamp;
  scrollMin?: number;
  zoomLevel?: number;
  selectedDateDate: Date;
  interval: { startHour: number; endHour: number };
  dragging: boolean;
}

/** Class calculating a relative position from a time range and a timestamp */
export class TimePositionCalculator {
  /** Creates the time position calculator from the core hours and the current time */
  public readonly start: ClockTimestamp;
  public readonly end: ClockTimestamp;
  private static lastCalculated: number | undefined;

  constructor(start: ClockTimestamp, end: ClockTimestamp) {
    this.start = start;
    this.end = end;
  }

  static fromCoreHours({
    core,
    currentTime,
    scrollMin = 0,
    zoomLevel = 1,
    selectedDateDate,
    interval,
    dragging,
  }: fromCoreHoursParams) {
    const calculatedStartMinutes = BASE_START_MINUTES * zoomLevel + scrollMin;
    const calculatedEndMinutes = BASE_END_MINUTES * zoomLevel + scrollMin;

    const adjustedDate = this.adjustCurrentDate(core, currentTime, dragging);

    const { timePosition } = this.adjustBoundaries(
      new TimePositionCalculator(
        new Date(addMinutes(calculatedStartMinutes, adjustedDate.getTime())).getTime(),
        new Date(addMinutes(calculatedEndMinutes, adjustedDate.getTime())).getTime()
      ),
      selectedDateDate,
      interval
    );

    return timePosition;
  }

  get hours(): ClockTimestamp[] {
    const res: ClockTimestamp[] = [];
    const start = new Date(this.start);
    start.setMinutes(0);
    for (let i = start.getTime(); i <= this.end; i = addHours(1, i))
      if (i >= this.start) res.push(i);
    return res;
  }

  convertTimestamp(timestamp: ClockTimestamp) {
    return ((timestamp - this.start) / (this.end - this.start)) * 100 + '%';
  }

  convertTimestampNumber(timestamp: ClockTimestamp) {
    return (timestamp - this.start) / (this.end - this.start);
  }

  convertMarkerTimestamp({ markerTimeStamp, itemStart, itemEnd }: convertMarkerTimestampParams) {
    const markerProportion = (markerTimeStamp - itemStart) / (itemEnd - itemStart);
    return markerProportion * 100 + '%';
  }

  convertMarkerTimestampToNumber({
    markerTimeStamp,
    itemStart,
    itemEnd,
  }: convertMarkerTimestampParams): number {
    const markerProportion = (markerTimeStamp - itemStart) / (itemEnd - itemStart);
    return markerProportion * 100;
  }

  convertTimestampBottom(timestamp: ClockTimestamp) {
    return ((this.end - timestamp) / (this.end - this.start)) * 100 + '%';
  }

  private static roundToFullHour(date: Date): Date {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  // utility method to adjust current date based on hours and core hours
  public static adjustCurrentDate(
    core: { startHour: number; endHour: number },
    time: ClockTimestamp,
    dragging: boolean
  ): Date {
    const currDate = this.roundToFullHour(new Date(time));

    if (dragging) return this.lastCalculated ? new Date(this.lastCalculated) : currDate;

    const hours = currDate.getHours();
    /* Start with the current hour in the hour prior to the core period */
    if (hours + 1 !== core.startHour) {
      if (hours >= core.startHour && hours < core.endHour) {
        /* Start with the core start hour within the core period - advance linearly if the core period is more than 8 hours */
        const advHours = Math.max(0, core.endHour - core.startHour - 8) + 1;
        currDate.setHours(
          core.startHour +
            Math.floor((advHours * (hours - core.startHour)) / (core.endHour - 1 - core.startHour))
        );
      } else {
        currDate.setTime(currDate.getTime() - addHours(2));
      }
    }
    this.lastCalculated = currDate.getTime();
    return currDate;
  }

  // utility method to check if calculatedInterval is within ALLOWED_INTERVAL, and adjust if necessary
  static adjustBoundaries = (
    calculatedInterval: TimePositionCalculator,
    selectedDateDate: Date,
    interval: BaseIntervalAllowed
  ): { status: boolean; timePosition: TimePositionCalculator } => {
    let status = false;
    let newStart = new Date(calculatedInterval.start);
    let newEnd = new Date(calculatedInterval.end);

    const diffInterval = newEnd.getTime() - newStart.getTime();

    if (
      isAfter(new Date(newEnd), ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end)
    ) {
      newEnd = ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end;
      newStart = new Date(newEnd.getTime() - diffInterval);
      if (
        isBefore(newStart, ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start)
      ) {
        newStart = ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start;
      }
      status = true;
    }
    if (isBefore(newStart, ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start)) {
      newStart = ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).start;
      newEnd = new Date(newStart.getTime() + diffInterval);
      if (isAfter(newEnd, ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end)) {
        newEnd = ALLOWED_INTERVAL({ selectedDate: selectedDateDate, interval }).end;
      }
      status = true;
    }
    return {
      status: status,
      timePosition: new TimePositionCalculator(newStart.getTime(), newEnd.getTime()),
    };
  };
}
