import { Entity } from '@sqior/js/entity';
import {
  makeTextEntity,
  EntityModel,
  EntityRecord,
  substituteTextParams,
  DomainInterface,
  TextEntity,
  CoreEntities,
  TextTemplate,
} from '@sqior/js/meta';
import { VisualEntities } from './visual-definitions';
import { makeHTML } from './html';

export type HTMLTemplate = Entity & {
  template: Entity;
  params: EntityRecord;
  interpreters?: EntityRecord;
};
export const HTMLTemplateModel: EntityModel = {
  type: VisualEntities.HTMLTemplate,
  props: ['template', 'params', 'interpreters'],
};
export function makeHTMLTemplate(
  template: string | Entity,
  params: EntityRecord,
  interpreters?: EntityRecord
): HTMLTemplate {
  const res: HTMLTemplate = {
    entityType: VisualEntities.HTMLTemplate,
    template: typeof template === 'string' ? makeTextEntity(template) : template,
    params: params,
  };
  if (interpreters) res.interpreters = interpreters;
  return res;
}
/** Returns all entities as an array
 */
export function extractParamEntities(htmlTemplate: HTMLTemplate): Entity[] {
  const res: Entity[] = [];
  for (const param in htmlTemplate.params) {
    res.push(htmlTemplate.params[param]);
  }
  return res;
}

export async function mergeHTMLTemplates(
  mapper: DomainInterface,
  template: string | Entity,
  params: EntityRecord,
  interpreters?: EntityRecord
): Promise<HTMLTemplate> {
  const mergedParams: EntityRecord = { ...params };
  const mergedInterpreters: EntityRecord = { ...interpreters };

  const templateText = (
    await mapper.map<TextEntity>(
      typeof template === 'string' ? makeTextEntity(template) : template,
      VisualEntities.HTML
    )
  ).text;
  const mergedTemplate = await substituteTextParams(templateText, async (param, opt) => {
    if (params[param].entityType === VisualEntities.HTMLTemplate) {
      // Insert template with scoped variables
      const innerTemplate = params[param] as HTMLTemplate;
      const innerTemplateText = (
        await mapper.map<TextEntity>(innerTemplate.template, VisualEntities.HTML)
      ).text;
      // Adapt template
      const newInnerTemplate = substituteTextParams(
        innerTemplateText,
        async (innerParam, innerOpt) => {
          return innerOpt
            ? `<e ${innerOpt}>${param + '_' + innerParam}</e>`
            : `<e>${param + '_' + innerParam}</e>`;
        }
      );
      // Merge params
      for (const key in innerTemplate.params)
        mergedParams[param + '_' + key] = innerTemplate.params[key];
      // Merge interpreters
      for (const key in innerTemplate.params)
        mergedInterpreters[param + '_' + key] = innerTemplate.params[key];

      return newInnerTemplate;
    } else if (params[param].entityType === CoreEntities.TextTemplate) {
      // Insert template with scoped variables
      const innerTemplate = params[param] as TextTemplate;
      const innerTemplateText = (
        await mapper.map<TextEntity>(innerTemplate.text, CoreEntities.Text)
      ).text;
      // Adapt template
      const newInnerTemplate = substituteTextParams(
        innerTemplateText,
        async (innerParam, innerOpt) => {
          return innerOpt
            ? `<e ${innerOpt}>${param + '_' + innerParam}</e>`
            : `<e>${param + '_' + innerParam}</e>`;
        }
      );
      // Merge params
      for (const key in innerTemplate.params)
        mergedParams[param + '_' + key] = innerTemplate.params[key];
      // Merge interpreters
      for (const key in innerTemplate.params)
        mergedInterpreters[param + '_' + key] = innerTemplate.params[key];

      return newInnerTemplate;
    } else {
      // Insert unchanged parameters
      return opt ? `<e ${opt}>${param}</e>` : `<e>${param}</e>`;
    }
  });

  const res: HTMLTemplate = {
    entityType: VisualEntities.HTMLTemplate,
    template: makeHTML(mergedTemplate),
    params: mergedParams,
  };
  if (mergedInterpreters) res.interpreters = mergedInterpreters;

  return res;
}
