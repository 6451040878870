import { Closable } from '@sqior/js/async';
import { ValueObject } from '@sqior/js/data';
import { FindResult } from './crud-interface';
import { DatabaseInterface } from './database-interface';

export class DocumentLoader<Type extends ValueObject = ValueObject> implements Closable {
  constructor(db: DatabaseInterface, path: string, keyProp: string) {
    this.db = db;
    this.path = path;
    this.keyProp = keyProp;
  }

  async close() {
    for (const prom of this.loads.values()) await prom;
  }

  async get(key: string) {
    /* Load from DB */
    const search: ValueObject = {};
    search[this.keyProp] = key;
    const loadEntry = this.db.findOne<Type>(this.path, search);
    this.loads.add(loadEntry);
    /* Wait for the load */
    const dbEntry = await loadEntry;
    /* De-list */
    this.loads.delete(loadEntry);
    return dbEntry;
  }

  readonly db: DatabaseInterface;
  readonly path: string;
  readonly keyProp: string;
  private loads = new Set<Promise<FindResult<Type> | undefined>>();
}
