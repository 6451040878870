import { Entity, EntityHeader } from '@sqior/js/entity';
import { LocationEntities, LocationInterfaces } from './location-definitions';
import {
  DailyStartConfig,
  DailyStartConfigItem,
  DailyStartConfigItemTypes,
  TransferPriorityGroup,
} from '@sqior/viewmodels/location';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { TimeEntities, TimeEntity } from '@sqior/plugins/time';

/* Transfer Priority Group */
export const TransferPriorityGroupModel: EntityModel = {
  type: LocationEntities.TransferPriorityGroup,
  props: ['name'],
  unclassified: true,
};
export function makeTransferPriorityGroup(name: string): TransferPriorityGroup {
  return {
    entityType: LocationEntities.TransferPriorityGroup,
    name: name,
  };
}
// Individual priority group has a dedicated time
export const IndividualTimeTransferPriorityGroupModel: EntityModel = {
  type: LocationEntities.IndividualTimeTransferPriorityGroup,
  props: ['hours', 'minutes'],
};
export function makeIndividualTimeTransferPriorityGroup(
  hours: number,
  minutes: number
): TimeEntity {
  return {
    entityType: LocationEntities.IndividualTimeTransferPriorityGroup,
    hours: hours,
    minutes: minutes,
  };
}

export const TransferPriorityGroupIFModel: Interface = {
  type: LocationInterfaces.TransferPriorityGroupIF,
  requires: [
    LocationEntities.IndividualTimeTransferPriorityGroup,
    LocationEntities.TransferPriorityGroup,
  ],
};

export const DailyStartConfigItemModel: EntityModel = {
  type: LocationEntities.DailyStartConfigItem,
  props: ['configType', 'options', 'selected'],
};
export function makeDailyStartConfigItem(
  configType: DailyStartConfigItemTypes,
  options: {
    entity: Entity;
    text: string;
    description?: string;
  }[],
  selected?: number
): DailyStartConfigItem {
  return {
    configType: configType,
    options: options,
    ...(selected !== undefined && { selected }),
  };
}
/* Daily Start Config */
export type DailyStartConfigEntity = EntityHeader & DailyStartConfig;
export const DailyStartConfigModel: EntityModel = {
  type: LocationEntities.DailyStartConfig,
  props: ['configs', 'enabled'],
};
export function makeDailyStartConfig(
  configs: DailyStartConfigItem[],
  enabled: boolean
): DailyStartConfigEntity {
  return {
    entityType: LocationEntities.DailyStartConfig,
    configs: configs,
    enabled: enabled,
  };
}

export const DefaultDailyStartConfigModel: Interface = {
  type: LocationInterfaces.DefaultDailyStartConfig,
  requires: LocationEntities.DailyStartConfig,
};

export const DailyStartConfigOptionDescriptionModel: Interface = {
  type: LocationInterfaces.DailyStartConfigOptionDescription,
  requires: CoreEntities.Text,
};

export const DailyStartInductionLocationModel: Interface = {
  type: LocationInterfaces.DailyStartInductionLocation,
  requires: LocationInterfaces.Key,
};

export const DailyStartTransferLocationModel: Interface = {
  type: LocationInterfaces.DailyStartTransferLocation,
  requires: LocationInterfaces.Key,
};

/* Transfer priority group VM */
export const TransferPriorityGroupVMModel: EntityModel = {
  type: LocationEntities.TransferPriorityGroupVM,
  props: ['label', 'state'],
  unclassified: true,
};

export type LocationDailyStartConfigEntity = EntityHeader & {
  group?: Entity;
  transferLocation?: Entity;
  inductionLocation?: Entity;
  enabled?: boolean;
};
export const LocationDailyStartConfigModel: EntityModel = {
  type: LocationEntities.LocationDailyStartConfig,
  props: ['group', 'transferLocation', 'inductionLocation', 'enabled'],
  unclassified: true,
};

export const DailyStartStartTimestampModel: Interface = {
  type: LocationInterfaces.DailyStartStartTimestamp,
  represents: TimeEntities.Timestamp,
};
export const DailyStartEndTimestampModel: Interface = {
  type: LocationInterfaces.DailyStartEndTimestamp,
  represents: TimeEntities.Timestamp,
};
/** The last call time for automatic daily start */
export const DailyStartLastCallTimestampModel: Interface = {
  type: LocationInterfaces.DailyStartLastCallTimestamp,
  represents: TimeEntities.Timestamp,
};
