import { FactoryProps } from '@sqior/react/factory';
import { classes } from '@sqior/react/utils';
import {
  WardSpecialtiesStatsInfoEntity,
  WardSpecialtiesStatsInfoItem,
} from '@sqior/viewmodels/occupancy';
import styles from './ward-specialties-stats.module.css';
import { Divider, PopperProps } from '@mui/material';
import { SqiorTooltip } from '@sqior/react/uibase';
import { useIsTablet } from '@sqior/react/hooks';

const popperProps: Partial<PopperProps> = {
  style: {
    pointerEvents: 'none',
  },
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -8],
      },
    },
  ],
};

export type WardSpecialtiesStatsProps = FactoryProps<WardSpecialtiesStatsInfoEntity>;
export function WardSpecialtiesStats(props: WardSpecialtiesStatsProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>Aufnahmen / Entlassungen</div>
      <table className={styles['stats-table']}>
        <tbody>
          {props.data.specialties.map((item) => {
            return <WardSpecialtiesRow key={item.specialty} item={item} />;
          })}
          {props.data.specialties.length > 1 && (
            <WardSpecialtiesRow item={props.data.totals} total={true} />
          )}
        </tbody>
      </table>
    </div>
  );
}

type WardSpecialtiesRowProps = {
  item: WardSpecialtiesStatsInfoItem;
  total?: boolean;
};
function WardSpecialtiesRow({ item, total }: WardSpecialtiesRowProps) {
  const isTablet = useIsTablet();

  const toolTipTable = (
    <table className={styles['customTable']}>
      <thead>
        <tr>
          <th className={styles['leftAligned']} style={{ fontSize: 16 }}>
            Belegung
          </th>
          <th className={styles['rightAligned']} style={{ fontSize: 16 }}>
            {item.specialty}
          </th>
        </tr>
        <br />
      </thead>
      <tbody>
        <tr>
          <td className={styles['leftAligned']}>Alle Betten</td>
          <td className={styles['rightAligned']}>{item.quota}</td>
        </tr>
        {item.blockedSpecificly !== undefined && (
          <tr>
            <td className={styles['leftAligned']}>Gesperrte Betten</td>
            <td className={styles['rightAligned']}>{item.blockedSpecificly}</td>
          </tr>
        )}
        {item.blockedUnspecificly !== undefined && (
          <tr>
            <td className={styles['leftAligned']}>Gesperrte Kontingente</td>
            <td className={styles['rightAligned']}>{item.blockedUnspecificly}</td>
          </tr>
        )}
        {item.availableBeds !== undefined && (
          <tr>
            <td className={styles['leftAligned']}>Verfügbare Betten</td>
            <td className={styles['rightAligned']}>{item.availableBeds}</td>
          </tr>
        )}

        <tr>
          <td className={styles['leftAligned']}>Aktuell belegte Betten</td>
          <td
            className={styles['rightAligned']}
            style={{
              color: item.patientsWarning ? '#f9c80e' : 'white',
            }}
          >
            {item.patients}
          </td>
        </tr>
        <tr>
          <td className={styles['leftAligned']}>Aktuell freie Betten</td>
          <td
            className={styles['rightAligned']}
            style={{
              color: item.freeBedsWarning ? '#f9c80e' : 'white',
            }}
          >
            {item.freeBeds}
          </td>
        </tr>
        <tr>
          <td className={styles['leftAligned']}>Abgänge</td>
          <td className={styles['rightAligned']}>{item.leaving}</td>
        </tr>
        <tr>
          <td className={styles['leftAligned']}>Zugänge</td>
          <td className={styles['rightAligned']}>{item.arriving}</td>
        </tr>
        <tr>
          <td className={styles['leftAligned']}>Vorauss. bel. Betten</td>
          <td
            className={styles['rightAligned']}
            style={{
              color: item.patientsLaterWarning ? '#f9c80e' : 'white',
            }}
          >
            {item.patientsLater}
          </td>
        </tr>
        <tr>
          <td className={styles['leftAligned']}>Voruass. freie Betten</td>
          <td
            className={styles['rightAligned']}
            style={{
              color: item.freeBedsLaterWarning ? '#f9c80e' : 'white',
            }}
          >
            {item.freeBedsLater}
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <>
      {total && (
        <tr>
          <td colSpan={7}>
            <Divider
              style={{
                marginTop: 3,
                marginBottom: 3,
              }}
            />
          </td>
        </tr>
      )}
      <SqiorTooltip
        disableTouchListener={isTablet}
        enterTouchDelay={0}
        title={toolTipTable}
        placement="bottom"
        PopperProps={popperProps}
        leaveTouchDelay={10000}
      >
        <tr className={total ? styles['stats-row-total'] : undefined}>
          <td className={styles['stats-cell-name']}>{item.specialty}</td>

          <td className={styles['stats-cell-values-quota']}>{item.availableBeds}</td>
          <td className={styles['stats-cell-values-current']}>
            <span className={classes(styles[item.patientsWarning ? 'warning' : 'normal'])}>
              {item.patients}
            </span>
          </td>
          <td className={styles['stats-cell-values-arriving']}>
            {item.arriving > 0 ? `+${item.arriving}` : <>&nbsp;-&nbsp;</>}
          </td>
          <td className={styles['stats-cell-values-middle']}>/</td>
          <td className={styles['stats-cell-values-leaving']}>
            {item.leaving > 0 ? `-${item.leaving}` : <>&nbsp;-&nbsp;</>}
          </td>
          <td
            className={styles['stats-cell-values-future']}
            style={{
              textAlign: 'right',
            }}
          >
            <span className={classes(styles[item.patientsLaterWarning ? 'warning' : 'normal'])}>
              {item.patientsLater}
            </span>
          </td>
        </tr>
      </SqiorTooltip>
    </>
  );
}

export default WardSpecialtiesStats;
