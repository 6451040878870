export enum CaveSeverity {
  Info = 'info',
  Warning = 'warning',
  Critical = 'critical',
}

export type CaveInfoVM = {
  severity: CaveSeverity;
  description?: string;
};
