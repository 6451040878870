import { compareProperty, Comparison } from './comparison';
import { RecIF } from './value';

/* Ordering attribute */
export enum Order {
  Ascending = 1,
  Descending = -1,
}

/* Order specification, basically an object whose properties have order values or nested order specifications */
export type OrderSpec = RecIF<Order | OrderSpec>;

/* Comparison operator */
export function compare<Type extends Record<string, unknown>>(
  first: Type,
  second: Type,
  order: OrderSpec
): Comparison {
  /* Loop for all keys of the ordering object */
  for (const key in order) {
    let comp = Comparison.Equal;
    /* Check if the first and/or second object is undefined */
    if (first === undefined) comp = second === undefined ? Comparison.Equal : Comparison.Less;
    else if (second === undefined) comp = Comparison.Greater;
    /* Check if this is a sub-object */ else if (typeof order[key] === 'object') {
      comp = compare(
        first[key] as Record<string, unknown>,
        second[key] as Record<string, unknown>,
        order[key] as OrderSpec
      );
      if (comp !== Comparison.Equal) return comp;
    } else comp = compareProperty(first, second, key);
    if (comp === Comparison.Equal) continue;
    return comp === (order[key] as Order).valueOf() ? Comparison.Greater : Comparison.Less;
  }

  return Comparison.Equal;
}

/* Less than comparison operator */
export function lessThanOrder<Type extends Record<string, unknown>>(
  first: Type,
  second: Type,
  order: OrderSpec
) {
  return compare(first, second, order) === Comparison.Less;
}
