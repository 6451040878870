import { ContextMenu, Position } from '@sqior/react/uibase';
import { InterweaveExt } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { MatcherInterface } from 'interweave';
import { EmailMatcher, IpMatcher, UrlMatcher } from 'interweave-autolink';
import { MouseEvent, useState } from 'react';
import SimpleInterpreterMenu from '../simple-interpreter-menu/simple-interpreter-menu';
import styles from './entity-visual-element.module.css';

/* eslint-disable-next-line */
export interface EntityVisualElementProps {
  content: string;
  entityKey?: string;
  interpreterKey?: string;
  className?: string;
}

const stdMatchers: MatcherInterface[] = [
  new EmailMatcher('email'),
  new IpMatcher('ip'),
  new UrlMatcher('url'),
];

export function EntityVisualElement(props: EntityVisualElementProps) {
  const contextActionPossible = props.entityKey !== undefined && props.interpreterKey !== undefined;
  const styleContext = contextActionPossible ? styles['context-action-defined'] : '';

  const [contextMenuPosition, setContextMenuPosition] = useState<Position | undefined>(undefined);
  function onClickEntity(e: MouseEvent) {
    if (contextActionPossible && contextMenuPosition === undefined) {
      e.stopPropagation();
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
    }
  }

  return (
    <span className={classes(props.className, styleContext)} onClick={onClickEntity}>
      <InterweaveExt content={props.content.toString()} matchers={stdMatchers} newWindow={true} />
      {contextMenuPosition && props.interpreterKey && (
        <ContextMenu
          position={contextMenuPosition}
          hideContextMenu={() => setContextMenuPosition(undefined)}
        >
          <SimpleInterpreterMenu
            interpreterKey={props.interpreterKey}
            itemSelected={() => setContextMenuPosition(undefined)}
          />
        </ContextMenu>
      )}
    </span>
  );
}

export default EntityVisualElement;
