import { ChatDetailsVM } from '@sqior/viewmodels/communication';
import { useDynamicStateRaw } from '@sqior/react/state';
import { PageHeader } from '@sqior/react/uiadvanced';
import { ClosablePage } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { OperationContext } from '@sqior/react/operation';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { useContext, useState } from 'react';
import { TeamAddresses } from '../team/team';
import Chat from '../chat/chat';
import styles from './closable-chat.module.css';
import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { EntityHeader } from '@sqior/js/entity';
import ExpandableImage from '../expandable-image/expandable-image';
import ChatSettings, { getStructuredAddresses } from '../chat-settings/chat-settings';

const isSameContent = (a?: string, b?: string) => {
  if (!a || !b) return false;
  const cleanA = a.toLowerCase().replace('-', '').replace(',', '').replace(' ', '').trim();
  const cleanB = b.toLowerCase().replace('-', '').replace(',', '').replace(' ', '').trim();
  return cleanA === cleanB;
};

const getPageSubTitle = (chatDetails: ChatDetailsVM) => {
  const brake = 30;
  const addresses = getStructuredAddresses(chatDetails);

  if (addresses.others.length > 2) {
    const text = addresses.others
      .map((_) => _.name)
      .join(', ')
      .substring(0, brake)
      .trim();

    if (text.length < brake) return text;
    return text + '...';
  }

  if (isSameContent(chatDetails.chatVM.pageName, chatDetails.chatVM?.pageSubtitle)) return null;
  const pageSubtitle = chatDetails.chatVM.pageSubtitle;
  // check how many characters are in the subtitle
  const subtitleLength = pageSubtitle?.length ?? 0;
  if (subtitleLength < brake) return pageSubtitle;
  return pageSubtitle?.substring(0, brake).trim() + '...';
};

/* eslint-disable-next-line */
export type ClosableChatProps = EntityHeader & {};

export function ClosableChat(props: FactoryProps<ClosableChatProps>) {
  const chatDetails = useDynamicStateRaw<ChatDetailsVM>('current-chat/selected-details');
  const [displayTeam, setDisplayTeam] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const dispatcher = useContext(OperationContext);

  function switchDisplayTeam() {
    setDisplayTeam(() => !displayTeam);
  }
  function selectRelated() {
    if (chatDetails?.selectEntities) {
      dispatcher.start(SelectPatient(chatDetails.selectEntities));
    }
  }

  if (chatDetails === undefined || chatDetails.chatVM === undefined) return <></>;

  return (
    <ClosablePage
      onClose={() => {
        props.onClose?.();
      }}
      className={classes(factoryGetProp<string>('className', props), styles['container'])}
      actions={
        <ChatSettings open={openSettings} setOpen={setOpenSettings} closeChat={props.onClose} />
      }
    >
      <PageHeader
        onClick={selectRelated}
        icon={
          <ExpandableImage type={chatDetails.chatVM.type} url={chatDetails.chatVM.typeUrl?.url} />
        }
        title={
          <div className={styles['title']} onClick={() => setOpenSettings(true)}>
            {chatDetails.chatVM.pageName}
            <div className={styles['page-subtitle']}>{getPageSubTitle(chatDetails)}</div>
          </div>
        }
        subTitle={
          <>
            {typeof chatDetails.chatVM.listSubtitle === 'string' && (
              <div className={styles['sub-title']}>{chatDetails.chatVM.listSubtitle}</div>
            )}
            {chatDetails.chatVM.listSubtitle instanceof Array && (
              <div className={styles['sub-title-container']}>
                <div>{chatDetails.chatVM.listSubtitle[0]}</div>
                <div>{chatDetails.chatVM.listSubtitle[1]}</div>
              </div>
            )}
          </>
        }
      />
      <div className={styles['content']}>
        <Chat className={styles['chat']} chatId={chatDetails.chatVM.id.id} />
        <TeamAddresses
          className={styles['address-list']}
          team={chatDetails?.participants}
          switchDisplayTeam={switchDisplayTeam}
          displayTeam={displayTeam}
        />
      </div>
    </ClosablePage>
  );
}

export default ClosableChat;
