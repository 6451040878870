import { FactoryProps } from '@sqior/react/factory';
import { SqiorClockProgress, useTextResources } from '@sqior/react/uibase';
import { BedAllocationRequestParametersInfoEntity } from '@sqior/viewmodels/occupancy';
import styles from './bed-allocation-parameter-info.module.css';

/* eslint-disable-next-line */
export type BedAllocationParameterInfoProps =
  FactoryProps<BedAllocationRequestParametersInfoEntity>;

export function BedAllocationParameterInfo(props: BedAllocationParameterInfoProps) {
  const textDict = useTextResources();

  return (
    <div className={styles['container']}>
      <div className={styles['table']}>
        {props.data.source && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('request_source')}</div>
            <div className={styles['value']}>{props.data.source}</div>
          </div>
        )}

        {props.data.clinic && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('clinic_selection')}</div>
            <div className={styles['value']}>{props.data.clinic}</div>
          </div>
        )}

        {props.data.priority && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('priority_requirements')}</div>
            <div className={styles[props.data.priority ? 'yes' : 'no']}>
              {textDict.get(props.data.priority ? 'yes' : 'no')}
            </div>
          </div>
        )}

        {props.data.isolation && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('isolation_requirement')}</div>
            <div className={styles[props.data.isolation ? 'yes' : 'no']}>
              {textDict.get(props.data.priority ? 'yes' : 'no')}
            </div>
          </div>
        )}

        {props.data.monitoring && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('monitoring_requirement')}</div>
            <div className={styles[props.data.priority ? 'yes' : 'no']}>
              {textDict.get(props.data.priority ? 'yes' : 'no')}
            </div>
          </div>
        )}

        {props.data.nursingComplexity && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('nursing_complexity')}</div>
            <div className={styles['value']}>
              <SqiorClockProgress
                value={props.data.nursingComplexity}
                crossColor="#090f23"
                fontSize={12}
              />
            </div>
          </div>
        )}

        {props.data.insuranceInfo && (
          <div className={styles['tr']}>
            <div className={styles['title']}>{textDict.get('insurance_status')}</div>
            <div className={styles['value']}>
              <div className={styles['no']}>
                {props.data.insuranceInfo ?? textDict.get('insurance_status_unknown')}
              </div>
            </div>
          </div>
        )}

        {props.data.notes && <div>{props.data.notes}</div>}
      </div>
    </div>
  );
}

export default BedAllocationParameterInfo;
