import CardLayout from '../card-layout/card-layout';
import MetricCardHeader from '../metric-card-header/metric-card-header';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import styles from './time-series-line-chart.module.css';
import { useCallback } from 'react';

export type SeriesChartData = {
  name: string;
  data: number[];
};

export type BenchmarkData = {
  categories: string[];
  series: SeriesChartData[];
};

export type TimeSeriesLineChartProps = {
  data: BenchmarkData;
  withMarkers?: boolean;
};

export function TimeSeriesLineChart({ data, withMarkers }: TimeSeriesLineChartProps) {
  const optionsWithMarkers: ApexOptions = withMarkers
    ? {
        markers: {
          size: [5, 5, 0],
          strokeWidth: [0, 0, 3],
          strokeColors: ['#ffffff'],
          strokeDashArray: [0, 0, 5],
          fillOpacity: 1,
          discrete: [],
          shape: 'circle',
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
      }
    : {};

  const chartOptions: ApexOptions = {
    theme: {
      mode: 'dark',
    },
    series: data.series,
    chart: {
      background: 'transparent',
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: Array(data.series.length).fill(3),
      curve: 'smooth',
      dashArray: Array(data.series.length - 1)
        .fill(0)
        .concat(5),
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      categories: data.categories,
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: 'rgba(71, 84, 103)',
          fontSize: '18px',
        },
      },
    },
    colors:
      data.series.length === 2
        ? ['#2E93fA', 'rgb(237, 123, 232)']
        : ['#2E93fA', 'rgb(237, 123, 232)', 'rgb(83, 56, 158)'],
    ...optionsWithMarkers,
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right',
      offsetX: -10,
    },
    grid: {
      show: true,
      borderColor: '#2f3859',
    },
  };

  const renderLegend = useCallback(() => {
    const colors = chartOptions.colors as string[];
    const series = chartOptions.series as SeriesChartData[];
    if (!series || !colors || series.length !== colors.length) {
      console.error('series and colors must be defined and have the same length');
      return null;
    }

    const legend = series.map((s, index) => {
      return (
        <div key={s.name} className={styles['legend-item']}>
          <div className={styles['legend-color']} style={{ backgroundColor: colors[index] }} />
          <span>{s.name}</span>
        </div>
      );
    });

    return legend;
  }, [chartOptions.colors, chartOptions.series]);

  return (
    <CardLayout>
      <MetricCardHeader title="Benchmark Belegung" legend={renderLegend()} />
      <div className={styles['chart-container']}>
        <div className={styles['chart-position']}>
          <Chart options={chartOptions} series={chartOptions.series} height="100%" width="100%" />
        </div>
      </div>
    </CardLayout>
  );
}

export default TimeSeriesLineChart;
