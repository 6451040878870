import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import { PagePatientOverviewType, TransportOrderInputType } from '@sqior/viewmodels/patient';
import TransportOrderInputControl from './transport-order-input-control/transport-order-input-control';
import PatientPage from './patient-page/patient-page';
import PatientHeader from './patient-header/patient-header';

export function UIPatientPlugin(context: PluginContext & FactoryContext) {
  context.plugins.require(UIVisualPlugin, context);
  context.factory.add(TransportOrderInputType, TransportOrderInputControl);
  context.factory.add(PagePatientOverviewType, PatientPage);
  context.factory.add('PatientInfo', PatientHeader);
}
  