import { Interpreter, InterpreterResult } from '@sqior/js/conversation';
import { TemporaryText, TemporaryTextType } from '@sqior/viewmodels/input';
import { addMinutes } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { makeDuration } from './duration';
import { TimeEntities } from './time-definitions';

export type DurationInput = {
  default: number;
  min: number;
  max: number;
  defaultTitle: Entity;
  numericalResult?: boolean;
};

/* Duration input entity */

export type DurationInputEntity = Entity & { default: Entity; model: DurationInput };
export const DurationInputModel: EntityModel = {
  type: TimeEntities.DurationInput,
  props: ['default', 'model'],
};
export function makeDurationInput(value: Entity, model: DurationInput): DurationInputEntity {
  return { entityType: TimeEntities.DurationInput, default: value, model: model };
}

/* Duration interpreter */

export class DurationInterpreter extends Interpreter {
  constructor(specs: DurationInput) {
    super();
    this.specs = specs;
  }

  override async suggest(text: string): Promise<TemporaryText[]> {
    if (text.length === 0)
      return [
        {
          type: TemporaryTextType.Suggestion,
          text: [{ option: this.specs.default.toString(), confidence: 0.0 }],
          match: '',
        },
      ];
    const dur = parseInt(text.replace(/\D/g, ''));
    if (dur < this.specs.min || dur > this.specs.max)
      return [
        {
          type: TemporaryTextType.Example,
          text:
            'Gültiger Eingabebereich von ' + this.specs.min + ' bis ' + this.specs.max + ' Minuten',
          match: '',
        },
      ];
    const match = dur.toString();
    if (match === text) return [{ type: TemporaryTextType.Fixed, text: text, match: text }];
    let common = 0;
    for (; common < text.length; common++) if (text[common] !== match[common]) break;
    return [
      {
        type: TemporaryTextType.Suggestion,
        text: [{ option: match, confidence: 1.0 }],
        match: text.substring(0, common),
      },
    ];
  }

  override async finalize(text: string): Promise<InterpreterResult> {
    const dur = parseInt(text.replace(/\D/g, ''));
    if (dur < this.specs.min || dur > this.specs.max) return this;
    return { results: [makeDuration(addMinutes(dur))] };
  }

  private specs: DurationInput;
}
