import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { VisualEntities } from './visual-definitions';

/** Type describing state handler */
export type StateHandlerType = EntityHeader & { type: string };
export const StateHandlerTypeModel: EntityModel = {
  type: VisualEntities.StateHandlerType,
  props: ['type'],
  unclassified: true,
};
export function makeStateHandlerType(type: string): StateHandlerType {
  return { entityType: StateHandlerTypeModel.type, type: type };
}
