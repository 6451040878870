import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { OperationSpec } from '@sqior/js/operation';
import { ItemState, ListViewItem } from '@sqior/viewmodels/visual';
import { EmergencyClassificationVM } from './orworkflow-overview';
import { TransferPriorityGroupVM } from '@sqior/viewmodels/location';
import { IdEntity } from '@sqior/js/entity';

/** Base OR Workflow list item */
export type ORWorkflowListItemBase<IdType = IdEntity> = ListViewItem<IdType> & {
  patient: string;
  step?: string;
  diagnosis?: string;
  description?: string;
  location?: string;
  order: string;
  select?: OperationSpec<ValueObject>;
  countdown?: ClockTimestamp | 'Infinity';
  countdownStart?: ClockTimestamp;
  countdownDuration?: number;
  countdownState?: ItemState;
  emergencyClassification?: EmergencyClassificationVM;
  prioGroup?: TransferPriorityGroupVM;
};

/** OR workflow stage list item */
export const ORWorkflowStageItemType = 'ORWorkflowStageItem';
export type ORWorkflowStageItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;

/** OR workflow OR list item */
export const ORWorkflowORListItemType = 'ORWorkflowORListItem';
export type ORWorkflowORListItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;

/** OR workflow OR cluster list item */
export const ORWorkflowORClusterListItemType = 'ORWorkflowClusterListItem';
export type ORWorkflowORClusterListItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;

/** OR workflow personal list item */
export const ORWorkflowMyListItemType = 'ORWorkflowMyListItem';
export type ORWorkflowMyListItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;

/** OR workflow intra-operative monitoring list item */
export const ORWorkflowIOMListItemType = 'ORWorkflowIOMListItem';
export type ORWorkflowIOMListItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;

/** OR workflow preliminary procedure list item */
export const PreliminaryProcedureListItemType = 'PreliminaryProcedureListItem';
export type PreliminaryProcedureListItem<IdType = IdEntity> = ORWorkflowListItemBase<IdType>;
