import styles from './patients-in-flight-view.module.css';
import { FC } from 'react';
import { BedAllocationInFlightPatientInfoEntity } from '@sqior/viewmodels/occupancy';
import BedAllocationInFlightPatientInfo from '../bed-allocation-in-flight-patient-info/bed-allocation-in-flight-patient-info';
import { createSectionList, SectionListType } from '@sqior/react/utils';
import { IdEntity } from '@sqior/js/entity';

export interface PatientsInFlightViewProps {
  data: BedAllocationInFlightPatientInfoEntity[];
  withSections?: boolean;
}

export const PatientsInFlightView: FC<PatientsInFlightViewProps> = ({ data, withSections }) => {
  const sectionList = createSectionList(data, 'requestSource');

  return (
    <div className={styles['container']}>
      {data.length !== 0 && <div className={styles['title']}>Zugänge</div>}
      {withSections ? <SectionList data={sectionList} /> : <NormalList data={data} />}
    </div>
  );
};

export default PatientsInFlightView;

const SectionList: FC<{ data: SectionListType<BedAllocationInFlightPatientInfoEntity>[] }> = ({
  data,
}) => {
  return (
    <>
      {data.map((section, index) => (
        <div
          key={section.header}
          className={styles['sections-container']}
          style={{ marginTop: index !== 0 ? 10 : 0 }}
        >
          <div className={styles['header']}>{section.header}</div>
          {section.items.map((item) => (
            <BedAllocationInFlightPatientInfo
              style={{ marginBottom: 0 }}
              data={item as BedAllocationInFlightPatientInfoEntity<IdEntity>}
              key={item.id.id}
            />
          ))}
        </div>
      ))}
    </>
  );
};

const NormalList: FC<{ data: BedAllocationInFlightPatientInfoEntity[] }> = ({ data }) => {
  return (
    <>
      {data.map((patient) => (
        <BedAllocationInFlightPatientInfo data={patient} key={patient.id.id} />
      ))}
    </>
  );
};
