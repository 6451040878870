import styles from './spi-info-view.module.css';
import { FC } from 'react';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';

export interface Props {
  value: number;
}

export const SpiInfoView: FC<Props> = ({ value }) => {
  return (
    <div className={styles['container']}>
      <WheelchairPickupIcon />
      {`SPI: ${value}`}
    </div>
  );
};

export default SpiInfoView;
