import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Type of the address selection control */

export const AddressSelectionType = 'AddressSelection';

/** Starts the stream operation searching for addresses */

export const AddressSearchPath = 'address-search';
export function AddressSearch(search: string): OperationSpec<string> {
  return { type: OperationType.Stream, path: AddressSearchPath, data: search };
}
