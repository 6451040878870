import { EntityHeader, Entity } from '@sqior/js/entity';
import { UserEntities } from './user-definitions';
import { EntityModel } from '@sqior/js/meta';
import { LocationRole } from './role-entity';

export type WardAlternativeUser = EntityHeader & {
  role: Entity;
};

export const WardAlternativeUserModel: EntityModel = {
  type: UserEntities.WardAlternativeUser,
  props: ['role'],
  keys: ['role'],
};

export function makeWardAlternativeUser(role: LocationRole): WardAlternativeUser {
  return { entityType: UserEntities.WardAlternativeUser, role };
}
