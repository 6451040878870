import { ValueObject } from '@sqior/js/data';
import { EntityHeader } from '@sqior/js/entity';
import { CoreEntities, EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';

export enum OidcEntities {
  Claims = 'OidcClaims',
}

export type OidcId = EntityHeader & { sub: string; iss: string };
export const OidcModel: EntityModel = {
  type: UserEntities.OidcId,
  props: ['sub', 'iss'],
  keys: ['sub', 'iss'],
  extends: CoreEntities.Key,
};
export function makeOidcId(sub: string, iss: string): OidcId {
  return { entityType: UserEntities.OidcId, sub: sub, iss: iss };
}

export type OidcClaims = EntityHeader & { claims: ValueObject };
export const OidcClaimsModel: EntityModel = { type: OidcEntities.Claims, props: ['claims'] };
export function makeOidcClaims(claims: ValueObject): OidcClaims {
  return { entityType: OidcEntities.Claims, claims: claims };
}
