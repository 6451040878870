import { ValueObject } from '@sqior/js/data';
import { Entity, EntityType } from '@sqior/js/entity';
import React from 'react';

export type FactoryProps<Type extends Entity = Entity> = {
  data: Type;
  onClose?: (success?: boolean, data?: ValueObject, title?: string) => void;
} & Record<string, unknown>;
export type FactoryElement<Type extends FactoryProps<Entity> = FactoryProps<Entity>> = (
  props: Type
) => JSX.Element;

/** Helper to get the additional parameters from FactoryProps */
export function factoryGetProp<Type>(name: string, props: FactoryProps) {
  if (name in props) return props[name] as Type;
  return undefined;
}

export class Factory {
  add<Type extends FactoryProps<Entity>>(type: EntityType, element: FactoryElement<Type>) {
    this.elements.set(type, element as FactoryElement);
  }

  get(): FactoryElement {
    return (props: FactoryProps) => {
      const element = this.elements.get(props.data.entityType);
      if (!element)
        throw new Error('Element type is not registered at factory: ' + props.data.entityType);
      return React.createElement(element, props);
    };
  }

  private elements = new Map<EntityType, FactoryElement>();
}

export const ComponentFactory = React.createContext<FactoryElement>(() => {
  throw new Error('Factory not initialized');
});

export default Factory;
