import { addDays, addHours } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { AnchorModel, Domain, RelatedDataDomain, RelatedDataModel } from '@sqior/js/meta';
import { LocationAnchor, LocationInterfaces } from '@sqior/plugins/location';
import { TimeEntities, TimestampEntity, Timestamps } from '@sqior/plugins/time';
import {
  DeviceEntities,
  DeviceInterfaces,
  DeviceTypeModel,
  FacilityBasePhoneNumberInterfaceModel,
  PhoneNumberEntryDesiredModel,
  PhoneNumbersInterfaceModel,
} from './device-definitions';
import { DeviceFunctionModel } from './device-function';
import { EquipmentModel } from './equipment';
import {
  PhoneNumberModel,
  PhoneNumberTypeModel,
  PhoneNumbersModel,
  PhoneNumbersVMModel,
} from './phone-number';
import { PushDeviceEntityModel, PushDevicesEntityModel } from './push-device-entity';

export const DeviceDomainName = 'Device';
export const DeviceAnchor = new AnchorModel(DeviceDomainName, DeviceInterfaces.Key);
export const DeviceRelatedType = new RelatedDataModel(
  DeviceAnchor,
  'RelatedType',
  DeviceEntities.Type
);
export const DeviceLastConnect = new RelatedDataModel(
  DeviceAnchor,
  'LastConnect',
  TimeEntities.Timestamp
);
export const DeviceLastDisconnect = new RelatedDataModel(
  DeviceAnchor,
  'LastDisconnect',
  TimeEntities.Timestamp
);
export const DevicePhoneNumber = new RelatedDataModel(
  DeviceAnchor,
  'PhoneNumber',
  DeviceEntities.PhoneNumber
);
export const DevicePhoneNumberEntryCloseTimestamps = new RelatedDataModel(
  DeviceAnchor,
  'PhoneNumberEntryCloseTimestamps',
  TimeEntities.Timestamps,
  undefined,
  'timestamps',
  TimeEntities.Timestamp
);
export const DeviceDefaultLocations = new RelatedDataModel(
  DeviceAnchor,
  'DefaultLocations',
  LocationAnchor.containerModel.type,
  true,
  'locations',
  LocationInterfaces.LocationOrCluster
);

/* Container type for DeviceAnchor */
export type DevicesEntity = Entity & { devices: Entity[] };

export class DeviceDomain extends RelatedDataDomain {
  constructor() {
    /* Definitions */
    super(DeviceAnchor, {
      entities: [
        PushDeviceEntityModel,
        PushDevicesEntityModel,
        DeviceTypeModel,
        PhoneNumberModel,
        PhoneNumbersModel,
        PhoneNumbersVMModel,
        PhoneNumberTypeModel,
        EquipmentModel,
        DeviceFunctionModel,
      ],
      interfaces: [
        PhoneNumberEntryDesiredModel,
        PhoneNumbersInterfaceModel,
        FacilityBasePhoneNumberInterfaceModel,
      ],
      relatedData: [
        DeviceRelatedType,
        DeviceLastConnect,
        DeviceLastDisconnect,
        DeviceDefaultLocations,
        DevicePhoneNumber,
        DevicePhoneNumberEntryCloseTimestamps,
      ],
      cleanUp: true,
      cleanUpTimestamp: { relativeTime: addDays(-30) },
    });

    /* Mappings */
    this.addTrivialMapping(
      DeviceEntities.PushDevice,
      DeviceInterfaces.Key
    ); /* Push device token can be used to identify a device */
    this.addTrivialMapping(DevicePhoneNumber.interface.type, DeviceEntities.PhoneNumber);
  }

  static addPhoneNumberEntryDesiredMapping(
    domain: Domain,
    tries: number,
    hoursBetweenTries: number
  ) {
    domain.addEntityMapping(
      DeviceInterfaces.Key,
      DeviceInterfaces.PhoneNumberEntryDesired,
      async (dev, mapper) => {
        // Check if a phone number exists
        if (await mapper.tryMap(dev, DevicePhoneNumber.interface.type)) return undefined;
        // Get the history of last check times
        const timestampsEnt = await mapper.tryMap<Timestamps>(
          dev,
          DevicePhoneNumberEntryCloseTimestamps.interface.type
        );
        if (timestampsEnt && timestampsEnt.timestamps.length >= tries) return undefined;
        if (!timestampsEnt || !timestampsEnt.timestamps.length) return dev;
        // Check if the last timestamp is at least 12 h before
        const lastTimestamp = await mapper.tryMap<TimestampEntity>(
          timestampsEnt.timestamps[timestampsEnt.timestamps.length - 1],
          TimeEntities.Timestamp
        );
        return !lastTimestamp ||
          mapper.displayTimer.now > addHours(hoursBetweenTries, lastTimestamp.timestamp)
          ? dev
          : undefined;
      },
      { cache: false }
    );
  }
}
