import { ensureArray } from '@sqior/js/data';
import { Entity, IdEntity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { UserEntities, UserInterfaces } from './user-definitions';

/** Key interface of a user group */

export const UserGroupModel: Interface = { type: UserInterfaces.GroupKey };

/** Group ID as basic implementation of the key interface */

export const UserGroupIdModel: EntityModel = {
  type: UserEntities.GroupId,
  props: ['id'],
  keys: ['id'],
  extends: CoreEntities.Key,
  unclassified: true,
};
export function makeUserGroupId(id: string): IdEntity {
  return { entityType: UserEntities.GroupId, id: id };
}

/** Groups entity */

export type UserGroupsEntity = Entity & { groups: Entity[] };
export const UserGroupsModel: EntityModel = { type: UserEntities.Groups, props: ['groups'] };
export function makeUserGroups(groups?: Entity | Entity[]): UserGroupsEntity {
  return { entityType: UserEntities.Groups, groups: ensureArray(groups) };
}
