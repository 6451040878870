import { ValueObject } from '@sqior/js/data';
import { OperationSpec, OperationType } from '@sqior/js/operation';

/* Conference call state VM */

export type ConferenceReference = { conference: number };
export enum ConferencePartnerRole {
  Offer = 'offer',
  Answer = 'answer',
}
export type ConferencePartnerVM = {
  role: string;
  partner: string;
  iceCandidates: string[];
  sdp: string;
  name?: string;
};
export type ICEServer = { urls: string; username: string; credential: string };
export type ConferenceVM = ConferenceReference & {
  id: string;
  iceServers?: ICEServer[];
  partners: ConferencePartnerVM[];
};

/* Command initiating a video conference */

export function InitiateConference(participants: ValueObject[]): OperationSpec<ValueObject[]> {
  return { type: OperationType.Add, path: 'conference/initiate', data: participants };
}

export function LeaveConference(conference: number): OperationSpec<string> {
  return { type: OperationType.Delete, path: 'conference/leave', data: conference.toString() };
}

/* Commands setting the connection parameters (SDP) for the call */

export type WebRTCParam = ConferenceReference & { partner: string; data: string };

export function AddICECandidate(
  conference: number,
  partner: string,
  cand: string
): OperationSpec<WebRTCParam> {
  return {
    type: OperationType.Add,
    path: 'conference/ice',
    data: { conference: conference, partner: partner, data: cand },
  };
}

export function SetWebRTCOffer(
  conference: number,
  partner: string,
  sdp: string
): OperationSpec<WebRTCParam> {
  return {
    type: OperationType.Add,
    path: 'conference/offer',
    data: { conference: conference, partner: partner, data: sdp },
  };
}
export function SetWebRTCAnswer(
  conference: number,
  partner: string,
  sdp: string
): OperationSpec<WebRTCParam> {
  return {
    type: OperationType.Add,
    path: 'conference/answer',
    data: { conference: conference, partner: partner, data: sdp },
  };
}
