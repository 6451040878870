import { EntityHeader } from '@sqior/js/entity';

export const OccupancyAnalyticsDashboard = 'OccupancyAnalyticsDashboard';

export type OccupancyAnalyticsDashboardVM = EntityHeader & {
  test?: string;
  // wardStats: WardStatData[];
  // clinicStats: WardStatDataBase;
};

export type WardStatDataBase = {
  allocationAvg: number; // 0..1
  stayDurationAvg: number; // days
  spiAvg: number;
};

export type WardStatData = WardStatDataBase & {
  id: string;
  clinicName: string;
};
