import { ReactComponent as Icon } from './escalation.svg';
import styles from './escalation-icon.module.css';

/* eslint-disable-next-line */
export interface EscalationIconProps {
  className?: string;
}

export function EscalationIcon(props: EscalationIconProps) {
  return <Icon className={`${styles['symbol']} ${props.className}`} viewBox="0 0 40 40" />;
}

export default EscalationIcon;
