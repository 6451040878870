import styles from './pie-chart-card.module.css';
import MetricCardHeader from '../metric-card-header/metric-card-header';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CardLayout from '../card-layout/card-layout';
import useResizeObserver from 'use-resize-observer/polyfilled';
import AnimatedNumbers from 'react-animated-numbers';

interface KeyValuePair {
  name: string;
  value: number;
  color?: string;
}

export interface PieChartCardProps {
  title: string;
  data: KeyValuePair[];
}

export function PieChartCard({ title, data }: PieChartCardProps) {
  const { ref, width } = useResizeObserver<HTMLDivElement>();

  const chartOptions: ApexOptions = {
    series: data.map((item) => item.value),
    labels: data.map((item) => item.name.replace(':', '')),
    chart: {
      type: 'donut',
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: false,
    },
    // tooltip: {
    //   enabled: false,
    // },
    theme: {
      mode: 'dark',
    },
    colors: data.map((item) => item.color),
    xaxis: {
      categories: data.map((item) => item.name),
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <CardLayout>
      <MetricCardHeader title={title} />
      <div className={styles['info-pie-chart']}>
        <div className={styles['key-value-pair']}>
          <table className={styles['table']}>
            <tbody>
              {data.map((item) => (
                <tr key={item.name}>
                  <td>{item.name}</td>
                  <td style={{ color: item?.color }}>
                    <AnimatedNumbers
                      includeComma
                      transitions={(index) => ({
                        type: 'spring',
                        duration: 0.3,
                      })}
                      animateToNumber={item.value}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles['pie-chart']} ref={ref}>
          {width && width > 150 && (
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type="donut"
              height="150px"
            />
          )}
        </div>
      </div>
    </CardLayout>
  );
}

export default PieChartCard;
