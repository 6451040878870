import styles from './performance-indicator-item.module.css';
import { PerformanceIndicator } from '../performance-indicators/performance-indicators';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export interface PerformanceIndicatorItemProps {
  data: PerformanceIndicator;
}

export function PerformanceIndicatorItem({ data }: PerformanceIndicatorItemProps) {
  if (data.title === 'divider') return <div className={styles['divider']} />;

  return (
    <div className={styles['container']}>
      <div>{data.title}</div>
      <div className={styles['stat-container']}>
        <div>
          {data.trend === 'up' ? (
            <ArrowUpwardRoundedIcon color="primary" />
          ) : data.trend === 'down' ? (
            <ArrowDownwardRoundedIcon color="primary" />
          ) : data.trend === 'equal' ? (
            <ArrowForwardRoundedIcon color="primary" />
          ) : null}
        </div>
        <div className={styles['item-value']}>{`${data.value}${
          data.valueUnit ? data.valueUnit : ''
        }`}</div>

        <div className={styles['more-icon-placeholder']}></div>
      </div>

      <div className={styles['more-icon']}>
        <IconButton>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

export default PerformanceIndicatorItem;
