import { EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';
import { IdEntity } from '@sqior/js/entity';

/** User for kiosk displays */

export const KioskUserModel: EntityModel = {
  type: UserEntities.Kiosk,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};
export function makeKioskUser(id: string): IdEntity {
  return { entityType: KioskUserModel.type, id: id };
}
