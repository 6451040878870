import { Emitter } from '@sqior/js/event';
import { OperationHandler } from './handler';
import { Operation } from './operation';

export class OperationEmitter {
  constructor() {
    this.operation = new Emitter<[Operation, string]>();
  }

  attach(handler: OperationHandler) {
    this.operation.on((op, path) => {
      handler.handle(op, path);
    });
    return this;
  }

  readonly operation: Emitter<[Operation, string]>;
}
