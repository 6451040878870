import {
  EscalationReasonInputType,
  SelectionEntities,
  SelectionMenuType,
} from '@sqior/viewmodels/input';
import { FactoryContext } from '@sqior/react/factory';
import NumericalInputArea from './numerical-input-area/numerical-input-area';
import OptionsInputArea from './options-input-area/options-input-area';
import SelectionPage from './selection-page/selection-page';
import TextInputArea from './text-input-area/text-input-area';
import { EscalationReasonInput } from './escalation-reason-input/escalation-reason-input';
import QRScannerInput from './qrscanner-input/qrscanner-input';

export function UISelectionPlugin(context: FactoryContext) {
  context.factory.add(SelectionEntities.OptionsViewModel, OptionsInputArea);
  context.factory.add(SelectionEntities.Numerical, NumericalInputArea);
  context.factory.add(SelectionEntities.Text, TextInputArea);
  context.factory.add(SelectionMenuType, SelectionPage);
  context.factory.add(EscalationReasonInputType, EscalationReasonInput);
  context.factory.add(SelectionEntities.QRScanner, QRScannerInput);
}
