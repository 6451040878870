/** Set of mapping context keys optimized for empty sets */
export type MappingContextKeys = string | Set<string> | undefined;

/** Creates an array from mapping context keys */
export function mappingContextKeysToArray(keys: MappingContextKeys): string[] {
  return typeof keys === 'object' ? [...keys] : keys !== undefined ? [keys] : [];
}

/** Creates mapping context keys from an array */
export function mappingContextKeysFromArray(keys: string[]): MappingContextKeys {
  return !keys.length ? undefined : keys.length === 1 ? keys[0] : new Set<string>(keys);
}

/** Clones mapping context keys */
export function mappingContextKeysClone(keys: MappingContextKeys): MappingContextKeys {
  return typeof keys === 'object' ? new Set<string>(keys) : keys;
}

/** Adds mapping context keys to another by modifying the first if necessary */
export function mappingContextKeysAdd(
  a: MappingContextKeys,
  b: MappingContextKeys
): MappingContextKeys {
  if (b === undefined) return a;
  if (a === undefined) {
    if (typeof b === 'string') return b;
    a = new Set<string>();
  } else if (typeof a === 'string') {
    if (a === b) return a;
    a = new Set<string>([a]);
  }
  if (typeof b === 'string') a.add(b);
  else for (const el of b) a.add(el);
  return a;
}

/** Removes a key from mapping context keys */
export function mappingContextKeysRemove(
  keys: MappingContextKeys,
  key: string
): MappingContextKeys {
  if (keys === undefined) return keys;
  if (typeof keys === 'string') return keys === key ? undefined : keys;
  keys.delete(key);
  return keys.size > 1 ? keys : !keys.size ? undefined : [...keys][0];
}
