import { PatientInfo } from '@sqior/viewmodels/patient';
import { ReactComponent as PatientIcon } from './patient.svg';
import { ReactComponent as PatientIdIcon } from './patient-id.svg';
import { ReactComponent as MaleIcon } from './male.svg';
import { ReactComponent as FemaleIcon } from './female.svg';
import { ReactComponent as DiverseIcon } from './diverse.svg';
import styles from './patient-header.module.css';
import { PageHeader } from '@sqior/react/uiadvanced';
import { Cave, InterweaveExt } from '@sqior/react/uibase';
import { PersonSex } from '@sqior/viewmodels/person';
import { FactoryProps } from '@sqior/react/factory';

export type PatientHeaderProps = FactoryProps<PatientInfo>;

export function PatientHeader(props: PatientHeaderProps) {
  return (
    <PageHeader
      icon={<PatientIcon className={styles['icon']} />}
      title={<InterweaveExt content={props.data.name} className={styles['name']} />}
      subTitle={
        <div className={styles['sub-meta-container']}>
          {props.data.sex && <PatientSex sex={props.data.sex} />}
          {props.data.dob && (
            <div className={styles['dob-info']}>
              <div>*{props.data.dob}</div>
            </div>
          )}
          {props.data.id && (
            <div className={styles['icon-info']}>
              <PatientIdIcon className={styles['id-icon']} />
              <div>{props.data.id}</div>
            </div>
          )}
          {props.data.caveSeverity ? <Cave severity={props.data.caveSeverity}></Cave> : ''}
        </div>
      }
    />
  );
}

export type PatientSexProps = {
  sex: PersonSex;
};
export function PatientSex(props: PatientSexProps) {
  return (
    <div className={styles['sex-info']}>
      {props.sex === PersonSex.Male && <MaleIcon className={styles['icon']} />}
      {props.sex === PersonSex.Female && <FemaleIcon className={styles['icon']} />}
      {props.sex === PersonSex.Diverse && <DiverseIcon className={styles['icon']} />}
    </div>
  );
}

export default PatientHeader;
