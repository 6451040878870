import { ClockTimestamp, StopTimer, Value, addHours } from '@sqior/js/data';
import { createUID } from '@sqior/js/uid';
import { Domain } from './domain';
import { Closable } from '@sqior/js/async';

const DEFAULT_EXPIRATION = addHours(1);

export class TokenSessionStore<Type extends Value> implements Closable {
  private domain: Domain;
  constructor(domain: Domain) {
    this.domain = domain;
  }

  async close(): Promise<void> {
    this.purgeTimer?.();
  }

  createToken(value: Type, options?: { expiresIn?: number }): string {
    const token = createUID();
    const expiresIn = options?.expiresIn ?? DEFAULT_EXPIRATION;
    const expires = this.domain.steadyTimer.now + expiresIn;
    this.tokens.set(token, { value, expires });

    if (this.purgeTimerAt > expires) this.schedulePurgeTokensAt(expires);

    return token;
  }

  get(token: string): Type | undefined {
    return this.tokens.get(token)?.value;
  }

  protected purgeTokens() {
    let nextRun = Number.MAX_VALUE;

    const time = this.domain.steadyTimer.now;
    this.tokens.forEach((v, k) => {
      if (v.expires < time) this.tokens.delete(k);
      else {
        if (nextRun > v.expires) nextRun = v.expires;
      }
    });

    this.schedulePurgeTokensAt(nextRun);
  }

  private schedulePurgeTokensAt(time: number) {
    console.log('schedulePurgeTokensAt', time);
    this.purgeTimer?.();
    this.purgeTimerAt = time;
    const timeIn = time - this.domain.steadyTimer.now;
    console.log('schedulePurgeTokensIn', timeIn);
    if (timeIn > 0)
      this.purgeTimer = this.domain.steadyTimer.schedule(() => {
        this.purgeTokens();
      }, timeIn);
  }

  private tokens = new Map<string, { value: Type; expires: ClockTimestamp }>();
  private purgeTimer?: StopTimer;
  private purgeTimerAt = Number.MAX_VALUE;
}
