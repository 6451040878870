import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { InputEntities } from './input-definitions';

export type CommandFeedback = Entity & { message: Entity; invocations: string[] };
export const CommandFeedbackModel: EntityModel = {
  type: InputEntities.CommandFeedback,
  props: ['message', 'invocations'],
  unclassified: true,
};
export function makeCommandFeedback(message: Entity, invocations: string[]): CommandFeedback {
  return { entityType: InputEntities.CommandFeedback, message: message, invocations: invocations };
}

export function removeCommandFeedback(entity: Entity): Entity {
  if (entity.entityType === InputEntities.CommandFeedback) {
    return (entity as CommandFeedback).message;
  }
  return entity;
}
