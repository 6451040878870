import { ValueObject } from '@sqior/js/data';
import { SequenceProjectionResult } from '@sqior/js/db';
import { Message, MessageDispatcher } from '@sqior/js/message';
import { Domain } from './domain';
import { DomainInterface } from './domain-interface';
import { ProjectionInterface } from './projection-view';
import { SequencedEvent } from '@sqior/js/event-stream';

export class ProjectionDispatcher {
  addHandler<MsgType extends ValueObject = ValueObject>(
    type: string,
    handler: (
      db: ProjectionInterface,
      msg: SequencedEvent & MsgType,
      mapper: DomainInterface
    ) => Promise<void>
  ) {
    this.msgDispatcher.set(type, async (msg, db, mapper) => {
      await handler(db, msg as unknown as SequencedEvent & MsgType, mapper);
      return [];
    });
  }

  addTo(domain: Domain) {
    /* Register a non-transactional event projector */
    domain.addEventProjector<Message & ValueObject>(async (db, msg, mapper) => {
      await this.msgDispatcher.handle(msg, db, mapper);
    });
  }

  private msgDispatcher = new MessageDispatcher<
    SequenceProjectionResult,
    [ProjectionInterface, DomainInterface]
  >();
}
