import { endOfDay, format, startOfDay, subMonths, subWeeks, subYears } from 'date-fns';
import { TimeRangeSelect } from './range-picker/range-picker';
import { de } from 'date-fns/locale';
import { BenchmarkData } from './time-series-line-chart/time-series-line-chart';
import { MetricCardProps } from './metric-card/metric-card';
import { PerformanceIndicator } from './performance-indicators/performance-indicators';
import { StatisticsComparisonPanelItemData } from './statistics-comparison-panel-item/statistics-comparison-panel-item';
import { AnalyticsData } from './react-ui-analytics';
import { withSelection } from './utils';

export const TABS: string[] = ['Übersicht', 'Compliance', 'Process'];
export const STATIONS: string[] = ['Alle', 'Station 1', 'Station 2', 'Station 3'];

export const BenchmarkFakeData: BenchmarkData = {
  series: [
    {
      name: 'Klinik, gesamt',
      data: [65, 67, 64, 65, 74, 73, 70, 73, 78, 80, 78, 85],
    },
    {
      name: 'Soll',
      data: [83, 83, 83, 83, 83, 83, 83, 83, 83, 83, 83, 83],
    },
  ],
  categories: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
};

export const MetricsFakeData: MetricCardProps[] = [
  {
    title: 'Durchschnittliche Belegung',
    value: 85,
    endValueUnit: '%',
    trend: {
      percentage: 11,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [85, 86, 85, 85, 86, 88, 86, 87, 88, 90, 91, 92],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Verweildauer',
    value: 3.6,
    unit: 'Tage',
    trend: {
      percentage: 6,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [3.6, 3.7, 3.6, 3.6, 3.7, 3.9, 3.6, 3.8, 3.8, 4, 4.1, 4.2],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Pflegegrad',
    value: 21.3,
    startValueUnit: '⌀',
    trend: {
      percentage: 7,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [21.3, 21.4, 21.3, 21.3, 21.4, 21.6, 21.4, 21.5, 21.6, 21.8, 21.9, 22],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
];

export type PieChartData = {
  name: string;
  value: number;
  color: string;
};

export const PieChartFakeProps = {
  title: 'Patienten',
  data: [
    { name: 'Notfall:', value: 21, color: 'rgba(237, 123, 232, 1)' },
    { name: 'Elektiv:', value: 34, color: 'rgba(83, 56, 158, 1)' },
  ],
};

export const createTimeRangeArray = (): TimeRangeSelect[] => {
  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);

  const DATE_FORMAT = 'MMM dd, yyyy';
  const locale = { locale: de };

  return [
    {
      label: 'Letzte Woche',
      value: `${format(subWeeks(todayStart, 1), DATE_FORMAT, locale)} - ${format(
        todayEnd,
        DATE_FORMAT,
        locale
      )}`,
      start: startOfDay(subWeeks(now, 1)),
      end: todayEnd,
    },
    {
      label: 'Letzter Monat',
      value: `${format(subMonths(todayStart, 1), DATE_FORMAT, locale)} - ${format(
        todayEnd,
        DATE_FORMAT,
        locale
      )}`,
      start: startOfDay(subMonths(now, 1)),
      end: todayEnd,
    },
    {
      label: 'Letzte 3 Monate',
      value: `${format(subMonths(todayStart, 3), DATE_FORMAT, locale)} - ${format(
        todayEnd,
        DATE_FORMAT,
        locale
      )}`,
      start: startOfDay(subMonths(now, 3)),
      end: todayEnd,
    },
    {
      label: 'Letzte 6 Monate',
      value: `${format(subMonths(todayStart, 6), DATE_FORMAT, locale)} - ${format(
        todayEnd,
        DATE_FORMAT,
        locale
      )}`,
      start: startOfDay(subMonths(now, 6)),
      end: todayEnd,
    },
    {
      label: 'Letztes Jahr',
      value: `${format(subYears(todayStart, 1), DATE_FORMAT, locale)} - ${format(
        todayEnd,
        DATE_FORMAT,
        locale
      )}`,
      start: startOfDay(subYears(now, 1)),
      end: todayEnd,
    },
  ];
};

export const PerformanceIndicatorFakeData: PerformanceIndicator[] = [
  {
    title: 'Prozesstreue',
    value: 92,
    valueUnit: '%',
    trend: 'equal',
  },
  {
    title: 'Verz. Prozesse',
    value: 17,
    valueUnit: '%',
    trend: 'down',
  },
  {
    title: 'Personaldifferenz',
    value: 79,
    valueUnit: '%',
  },
  {
    title: 'Aktives Personal',
    value: 28,
  },
  {
    title: 'Ø Interaktionen',
    value: 26,
  },
];

export const StatisticsComparisonPanelFakeData: StatisticsComparisonPanelItemData[] = [
  {
    title: 'Einhaltung der Checkout Zeit (10:30 Uhr)',
    valueBefore: 76,
    valueUnit: '%',
    valueAfter: 73,
    trend: 'down',
  },
  {
    title: 'Entlassung am Vortag gemeldet',
    valueBefore: 28,
    valueUnit: '%',
    valueAfter: 38,
    trend: 'up',
  },
  {
    title: 'ZNA Holding area genutzt',
    valueBefore: 56,
    valueUnit: '%',
    valueAfter: 66,
    trend: 'up',
  },
  {
    title: 'ZNA bye-bye area genutzt',
    valueBefore: 48,
    valueUnit: '%',
    valueAfter: 68,
    trend: 'up',
  },
  {
    title: 'ZNA Kontingent eingehalten',
    valueBefore: 90,
    valueUnit: '%',
    valueAfter: 95,
    trend: 'up',
  },
];

export const FAKE_DATA: AnalyticsData = {
  tabs: withSelection(TABS, 0),
  stations: withSelection(STATIONS, 0),
  timeRanges: withSelection(createTimeRangeArray(), 0),
  metrics: MetricsFakeData,
  patients: PieChartFakeProps.data,
  benchmark: BenchmarkFakeData,
  staticComparison: StatisticsComparisonPanelFakeData,
  performanceIndicators: PerformanceIndicatorFakeData,
};

export const extractFakeData = (
  tab: string,
  station: string,
  timeRange: TimeRangeSelect
): Partial<AnalyticsData> => {
  return {
    metrics: makeMetricsFakeData(station, timeRange),
    patients: makePieChartData(station, timeRange),
    benchmark: makeBenchmarkData(station, timeRange),
    staticComparison: makeStaticComparisonFakeData(station, timeRange),
    performanceIndicators: makePerformanceIndicatorFakeData(station, timeRange),
  };
};

const makePerformanceIndicatorFakeData = (
  station: string,
  timeRange: TimeRangeSelect
): PerformanceIndicator[] => {
  switch (station) {
    case STATIONS[1]:
      return [
        {
          title: 'Prozesstreue',
          value: 93,
          valueUnit: '%',
          trend: 'equal',
        },
        {
          title: 'Verz. Prozesse',
          value: 16,
          valueUnit: '%',
          trend: 'down',
        },
        {
          title: 'Personaldifferenz',
          value: 80,
        },
        {
          title: 'Aktives Personal',
          value: 27,
        },
        {
          title: 'Ø Interaktionen',
          value: 25,
        },
      ];
    case STATIONS[2]:
      return [
        {
          title: 'Prozesstreue',
          value: 92,
          valueUnit: '%',
          trend: 'equal',
        },
        {
          title: 'Verz. Prozesse',
          value: 18,
          valueUnit: '%',
          trend: 'down',
        },

        {
          title: 'Personaldifferenz',
          value: 78,
          valueUnit: '%',
        },
        {
          title: 'Aktives Personal',
          value: 29,
        },
        {
          title: 'Ø Interaktionen',
          value: 27,
        },
      ];
    case STATIONS[3]:
      return [
        {
          title: 'Prozesstreue',
          value: 91,
          valueUnit: '%',
          trend: 'equal',
        },
        {
          title: 'Verz. Prozesse',
          value: 17,
          valueUnit: '%',
          trend: 'down',
        },

        {
          title: 'Personaldifferenz',
          value: 79,
          valueUnit: '%',
        },
        {
          title: 'Aktives Personal',
          value: 28,
        },
        {
          title: 'Ø Interaktionen',
          value: 26,
        },
      ];
    default:
      return FAKE_DATA.performanceIndicators;
  }
};

const makeStaticComparisonFakeData = (
  station: string,
  timeRange: TimeRangeSelect
): StatisticsComparisonPanelItemData[] => {
  switch (station) {
    case STATIONS[1]:
      return [
        {
          title: 'Einhaltung der Checkout Zeit (10:30 Uhr)',
          valueBefore: 77,
          valueUnit: '%',
          valueAfter: 74,
          trend: 'down',
        },
        {
          title: 'Entlassung am Vortag gemeldet',
          valueBefore: 26,
          valueUnit: '%',
          valueAfter: 36,
          trend: 'up',
        },
        {
          title: 'ZNA Holding area genutzt',
          valueBefore: 54,
          valueUnit: '%',
          valueAfter: 64,
          trend: 'up',
        },
        {
          title: 'ZNA bye-bye area genutzt',
          valueBefore: 46,
          valueUnit: '%',
          valueAfter: 66,
          trend: 'up',
        },
        {
          title: 'ZNA Kontingent eingehalten',
          valueBefore: 89,
          valueUnit: '%',
          valueAfter: 94,
          trend: 'up',
        },
      ];
    case STATIONS[2]:
      return [
        {
          title: 'Einhaltung der Checkout Zeit (10:30 Uhr)',
          valueBefore: 75,
          valueUnit: '%',
          valueAfter: 72,
          trend: 'down',
        },
        {
          title: 'Entlassung am Vortag gemeldet',
          valueBefore: 30,
          valueUnit: '%',
          valueAfter: 40,
          trend: 'up',
        },
        {
          title: 'ZNA Holding area genutzt',
          valueBefore: 58,
          valueUnit: '%',
          valueAfter: 68,
          trend: 'up',
        },
        {
          title: 'ZNA bye-bye area genutzt',
          valueBefore: 50,
          valueUnit: '%',
          valueAfter: 70,
          trend: 'up',
        },
        {
          title: 'ZNA Kontingent eingehalten',
          valueBefore: 91,
          valueUnit: '%',
          valueAfter: 96,
          trend: 'up',
        },
      ];
    case STATIONS[3]:
      return [
        {
          title: 'Einhaltung der Checkout Zeit (10:30 Uhr)',
          valueBefore: 76,
          valueUnit: '%',
          valueAfter: 73,
          trend: 'down',
        },
        {
          title: 'Entlassung am Vortag gemeldet',
          valueBefore: 28,
          valueUnit: '%',
          valueAfter: 38,
          trend: 'up',
        },
        {
          title: 'ZNA Holding area genutzt',
          valueBefore: 56,
          valueUnit: '%',
          valueAfter: 66,
          trend: 'up',
        },
        {
          title: 'ZNA bye-bye area genutzt',
          valueBefore: 48,
          valueUnit: '%',
          valueAfter: 68,
          trend: 'up',
        },
        {
          title: 'ZNA Kontingent eingehalten',
          valueBefore: 90,
          valueAfter: 95,
          valueUnit: '%',
          trend: 'up',
        },
      ];
    default:
      return FAKE_DATA.staticComparison;
  }
};

const makeBenchmarkData = (station: string, timeRange: TimeRangeSelect): BenchmarkData => {
  switch (station) {
    case STATIONS[1]:
      return {
        series: [
          {
            name: STATIONS[1],
            data: [25, 26, 25, 25, 26, 28, 26, 27, 28, 30, 31, 32],
          },
          {
            name: 'Klinik, gesamt',
            data: [21, 22, 21, 21, 24, 24, 23, 24, 25, 26, 25, 28],
          },
          {
            name: 'Soll',
            data: [27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27],
          },
        ],
        categories: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
      };
    case STATIONS[2]:
      return {
        series: [
          {
            name: STATIONS[2],
            data: [25, 26, 25, 25, 27, 28, 26, 27, 29, 30, 32, 33],
          },
          {
            name: 'Klinik, gesamt',
            data: [22, 23, 21, 22, 25, 24, 23, 24, 26, 27, 26, 28],
          },
          {
            name: 'Soll',
            data: [28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28],
          },
        ],
        categories: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
      };
    case STATIONS[3]:
      return {
        series: [
          {
            name: STATIONS[3],
            data: [25, 26, 26, 25, 27, 29, 26, 28, 28, 30, 32, 33],
          },
          {
            name: 'Klinik, gesamt',
            data: [22, 22, 22, 22, 25, 25, 24, 25, 27, 27, 27, 29],
          },
          {
            name: 'Soll',
            data: [28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28],
          },
        ],
        categories: [
          'Jan',
          'Feb',
          'Mär',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
      };
    default:
      return FAKE_DATA.benchmark;
  }
};

const makePieChartData = (station: string, timeRange: TimeRangeSelect): PieChartData[] => {
  switch (station) {
    case STATIONS[1]:
      return [
        { name: 'Notfall:', value: 8, color: 'rgba(237, 123, 232, 1)' },
        { name: 'Elektiv:', value: 12, color: 'rgba(83, 56, 158, 1)' },
      ];

    case STATIONS[2]:
      return [
        { name: 'Notfall:', value: 6, color: 'rgba(237, 123, 232, 1)' },
        { name: 'Elektiv:', value: 9, color: 'rgba(83, 56, 158, 1)' },
      ];

    case STATIONS[3]:
      return [
        { name: 'Notfall:', value: 7, color: 'rgba(237, 123, 232, 1)' },
        { name: 'Elektiv:', value: 13, color: 'rgba(83, 56, 158, 1)' },
      ];

    default:
      return FAKE_DATA.patients;
  }
};

const makeMetricsFakeData = (station: string, timeRange: TimeRangeSelect): MetricCardProps[] => {
  switch (station) {
    case STATIONS[1]:
      return Station1MetricsFakeData;

    case STATIONS[2]:
      return Station2MetricsFakeData;

    case STATIONS[3]:
      return Station3MetricsFakeData;
    default:
      return FAKE_DATA.metrics;
  }
};

const Station1MetricsFakeData: MetricCardProps[] = [
  {
    title: 'Durchschnittliche Belegung',
    value: 75,
    endValueUnit: '%',
    trend: {
      percentage: 10,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [75, 60, 75, 75, 76, 78, 76, 77, 78, 80, 81, 82],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Verweildauer',
    value: 2.9,
    unit: 'Tage',
    trend: {
      percentage: 5,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [2.9, 3, 2.9, 2.9, 3, 3.2, 3, 3.1, 3.2, 3.4, 3.5, 3.6],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Pflegegrad',
    value: 16,
    startValueUnit: '⌀',
    trend: {
      percentage: 6,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [16, 16.1, 15.5, 16, 16.1, 16.3, 16.1, 16.2, 16.3, 16.5, 16.6, 16.7],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
];

const Station2MetricsFakeData: MetricCardProps[] = [
  {
    title: 'Durchschnittliche Belegung',
    value: 87,
    endValueUnit: '%',
    trend: {
      percentage: 12,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [87, 88, 87, 87, 88, 90, 88, 89, 90, 92, 93, 94],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Verweildauer',
    value: 3.7,
    unit: 'Tage',
    trend: {
      percentage: 7,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [3.7, 3.8, 3.7, 3.7, 3.8, 4, 3.7, 3.9, 3.9, 4.1, 4.2, 4.3],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Pflegegrad',
    value: 22,
    startValueUnit: '⌀',
    trend: {
      percentage: 8,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [22, 22.1, 22, 22, 22.1, 22.3, 22.1, 22.2, 22.3, 22.5, 22.6, 22.7],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
];

const Station3MetricsFakeData: MetricCardProps[] = [
  {
    title: 'Durchschnittliche Belegung',
    value: 86,
    endValueUnit: '%',
    trend: {
      percentage: 11,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [86, 87, 86, 86, 87, 89, 87, 88, 89, 91, 92, 93],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Verweildauer',
    value: 3.6,
    unit: 'Tage',
    trend: {
      percentage: 6,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [3.6, 3.7, 3.6, 3.6, 3.7, 3.9, 3.6, 3.8, 3.8, 4, 4.1, 4.2],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
  {
    title: 'Pflegegrad',
    value: 21.9,
    startValueUnit: '⌀',
    trend: {
      percentage: 7,
      hasIncreased: true,
      text: 'zum Vorjahresmonat',
    },
    chart: {
      data: [21.9, 22, 21.9, 21.9, 22, 22.2, 22, 22.1, 22.2, 22.4, 22.5, 22.6],
      categories: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
    },
  },
];
