import { ensureArray } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { PhoneNumberPair } from '@sqior/viewmodels/user';
import { DeviceEntities } from './device-definitions';

export enum NumberType {
  Private = 'Private',
  Work = 'Work',
}

export type PhoneNumberType = EntityHeader & { numberType: NumberType };

export const PhoneNumberTypeModel: EntityModel = {
  type: DeviceEntities.PhoneNumberType,
  props: ['numberType'],
};

export function makePhoneNumberType(numberType: NumberType) {
  return { entityType: DeviceEntities.PhoneNumberType, numberType };
}

export function concatPhoneNumbers(prefix: PhoneNumber, num: PhoneNumber) {
  if (num.phoneNumber === '' || num.phoneNumber.startsWith(prefix.phoneNumber)) return num;
  if (/^\+|^0/.test(num.phoneNumber)) {
    num.phoneNumber = num.phoneNumber.replace(/^(?:(?:00)|\+)(\d{2})/, '+$1').replace(/^0/, '+49');
    return num;
  }
  return makePhoneNumber(`${prefix.phoneNumber}${num.phoneNumber}`, num.numberType);
}

export type PhoneNumber = EntityHeader & { phoneNumber: string; numberType?: Entity };
export function makePhoneNumber(phoneNumber: string, numberType?: Entity): PhoneNumber {
  const number = { entityType: DeviceEntities.PhoneNumber, phoneNumber: phoneNumber };
  return numberType ? { ...number, numberType } : number;
}
export const PhoneNumberModel: EntityModel = {
  type: DeviceEntities.PhoneNumber,
  props: ['phoneNumber'],
};

export type PhoneNumbers = EntityHeader & { phoneNumbers: PhoneNumber[] };
export function makePhoneNumbers(numbers: PhoneNumber[] | PhoneNumber): PhoneNumbers {
  return { entityType: DeviceEntities.PhoneNumbers, phoneNumbers: ensureArray(numbers) };
}
export const PhoneNumbersModel: EntityModel = {
  type: DeviceEntities.PhoneNumbers,
  props: ['phoneNumbers'],
};

export type PhoneNumbersVM = EntityHeader & { numbers: PhoneNumberPair[] };
export function makePhoneNumbersVM(numbers: PhoneNumberPair | PhoneNumberPair[]): PhoneNumbersVM {
  return { entityType: DeviceEntities.PhoneNumbersVM, numbers: ensureArray(numbers) };
}
export const PhoneNumbersVMModel: EntityModel = {
  type: DeviceEntities.PhoneNumbersVM,
  props: ['numbers'],
};
