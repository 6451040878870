import { ConferenceVM, LeaveConference } from '@sqior/js/conference';
import { OperationContext } from '@sqior/react/operation';
import { ClosablePage } from '@sqior/react/uibase';
import { useContext } from 'react';
import PartnerVideo from '../partner-video/partner-video';
import styles from './conference-page.module.css';

/* eslint-disable-next-line */
export interface ConferencePageProps {
  conf: ConferenceVM;
}

export function ConferencePage(props: ConferencePageProps) {
  const dispatcher = useContext(OperationContext);

  return (
    <ClosablePage
      onClose={() => {
        dispatcher.start(LeaveConference(props.conf.conference));
      }}
    >
      <div className={styles['container']}>
        {props.conf.partners.map((item) => (
          <PartnerVideo
            key={item.partner}
            conference={props.conf.conference}
            iceServers={props.conf.iceServers}
            desc={item}
          />
        ))}
      </div>
    </ClosablePage>
  );
}

export default ConferencePage;
