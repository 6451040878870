import InputControl from '../input-control/input-control';
import InputMainButton from '../input-mainbutton/input-mainbutton';

import styles from './input-panel.module.css';
import { InputControlLogic } from '../input-control/input-control-logic';

/* eslint-disable-next-line */
export interface InputPanelProps {
  icLogic: InputControlLogic;
  onPhoto: () => void;
}

export function InputPanel(props: InputPanelProps) {
  // Reference to input control
  function onClickMainButton() {
    props.icLogic.sendFinal();
  }

  return (
    <div className={styles['input-panel']}>
      <div className={styles['input-panel-status']}></div>
      <div className={styles['input-panel-buttons']}>
        {/* <ButtonRecord onClick={ () => {;} } /> */}
        {/* <ButtonPhoto onClick={ () => { props.onPhoto(); } } /> */}
      </div>
      <div className={styles['input-control']}>
        <InputControl icLogic={props.icLogic} />
      </div>
      <div className={styles['input-panel-mainbuttons']}>
        <InputMainButton onClick={onClickMainButton} enabled={props.icLogic.isFinal()} />
      </div>
    </div>
  );
}

export default InputPanel;
