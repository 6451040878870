import { ensureArray } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { LanguageEntities, LanguageInterfaces } from '@sqior/plugins/language';

export enum SpecialtyEntities {
  Specialty = 'Specialty',
  Specialties = 'Specialties',
  Quota = 'SpecialtyQuota',
  Quotas = 'SpecialtyQuotas',
}
export enum SpecialtyInterfaces {
  SpecialtyName = 'SpecialtyName',
  SpecialtyNameAsPerson = 'SpecialtyNameAsPerson',
  AvailableSpecialties = 'AvailableSpecialties', // Specialties available for selection
  PredominantSpecialty = 'PredominantSpecialty', // Predominant specialty among a set of candidates
}

export enum Specialties {
  Cardiology = 'cardiology',
  ENT = 'ent',
  CMF = 'cmf',
  GastroEnterology = 'gastroenterology',
  GeneralSurgery = 'general-surgery',
  Gynaecology = 'gynaecology',
  HeartSurgery = 'heart-surgery',
  Internal = 'internal',
  Neurosurgery = 'neurosurgery',
  Orthopedics = 'orthopedics',
  PediatricSurgery = 'pediatric-surgery',
  Plastic = 'plastic-surgery',
  Thoracic = 'thoracic-surgery',
  Trauma = 'trauma-surgery',
  Urology = 'urology',
  Vascular = 'vascular-surgery',
  Visceral = 'visceralsurgery',
}

export type SpecialtyEntity = Entity & { specialty: string };
export const SpecialtyModel: EntityModel = {
  type: SpecialtyEntities.Specialty,
  props: ['specialty'],
  keys: ['specialty'],
  unclassified: true,
};
export function makeSpecialty(specialty: string): SpecialtyEntity {
  return { entityType: SpecialtyEntities.Specialty, specialty: specialty };
}

export type SpecialtiesEntity = Entity & { specialties: Entity[] };
export const SpecialtiesModel: EntityModel = {
  type: SpecialtyEntities.Specialties,
  props: ['specialties'],
  keys: ['specialties'],
};
export function makeSpecialties(specialties: Entity | Entity[]): SpecialtiesEntity {
  return { entityType: SpecialtyEntities.Specialties, specialties: ensureArray(specialties) };
}

export const SpecialtyNameModel: Interface = {
  type: SpecialtyInterfaces.SpecialtyName,
  requires: LanguageEntities.AnonymizedText,
};
export const SpecialtyNameAsPersonModel: Interface = {
  type: SpecialtyInterfaces.SpecialtyNameAsPerson,
  represents: LanguageInterfaces.Anonymized,
};

/* Available specialties */

export const AvailableSpecialtiesModel: Interface = {
  type: SpecialtyInterfaces.AvailableSpecialties,
  requires: SpecialtyEntities.Specialties,
};

/* Quota per specialty */

export type SpecialtyQuota = Entity & { specialty: Entity; quota: number };
export const SpecialtyQuotaModel: EntityModel = {
  type: SpecialtyEntities.Quota,
  props: ['specialty', 'quota'],
};
export function makeSpecialtyQuota(specialty: Entity, quota: number): SpecialtyQuota {
  return { entityType: SpecialtyEntities.Quota, specialty: specialty, quota: quota };
}

export type SpecialtyQuotas = Entity & { quotas: Entity[] };
export const SpecialtyQuotasModel: EntityModel = {
  type: SpecialtyEntities.Quotas,
  props: ['quotas'],
};
export function makeSpecialtyQuotas(quotas: Entity[]): SpecialtyQuotas {
  return { entityType: SpecialtyEntities.Quotas, quotas: quotas };
}

/* Predominant specialty */

export const PredominantSpecialtiyModel: Interface = {
  type: SpecialtyInterfaces.PredominantSpecialty,
  requires: SpecialtyEntities.Specialty,
};
