import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { LocationEntities } from './location-definitions';
import { ClockTimestamp } from '@sqior/js/data';

/** Movements entity */
export type Movements = EntityHeader & { movements: Entity[] };
export const MovementsModel: EntityModel = {
  type: LocationEntities.Movements,
  props: ['movements'],
};
export function makeMovements(movements: Entity[]): Movements {
  return { entityType: MovementsModel.type, movements };
}

/* Movement types */

export type MovementBase = EntityHeader & {
  movementId: string;
  timestamp: ClockTimestamp;
};

/** Admission */

export type MovementAdmission = MovementBase & { to: Entity };
export const MovementAdmissionModel: EntityModel = {
  type: LocationEntities.MovementAdmission,
  props: ['movementId', 'timestamp', 'to'],
};
export function makeMovementAdmission(
  movementId: string,
  timestamp: ClockTimestamp,
  to: Entity
): MovementAdmission {
  return { entityType: LocationEntities.MovementAdmission, movementId, timestamp, to };
}

/** Transfer */

export type MovementTransfer = MovementBase & { from?: Entity; to: Entity };
export const MovementTransferModel: EntityModel = {
  type: LocationEntities.MovementTransfer,
  props: ['movementId', 'timestamp', 'from', 'to'],
};
export function makeMovementTransfer(
  movementId: string,
  timestamp: ClockTimestamp,
  to: Entity,
  from?: Entity
): MovementTransfer {
  const res: MovementTransfer = {
    entityType: LocationEntities.MovementTransfer,
    movementId,
    timestamp,
    to,
  };
  if (from) res.from = from;
  return res;
}

/** Discharge */

export type MovementDischarge = MovementBase & { from?: Entity };
export const MovementDischargeModel: EntityModel = {
  type: LocationEntities.MovementDischarge,
  props: ['movementId', 'timestamp', 'from'],
};
export function makeMovementDischarge(
  movementId: string,
  timestamp: ClockTimestamp,
  from?: Entity
): MovementDischarge {
  const res: MovementDischarge = {
    entityType: LocationEntities.MovementDischarge,
    movementId,
    timestamp,
  };
  if (from) res.from = from;
  return res;
}

/** Visit at a department or function does not alter the general admission status */

export type MovementVisit = MovementBase & { at?: Entity };
export const MovementVisitModel: EntityModel = {
  type: LocationEntities.MovementVisit,
  props: ['movementId', 'timestamp', 'at'],
};
export function makeMovementVisit(
  movementId: string,
  timestamp: ClockTimestamp,
  at?: Entity
): MovementVisit {
  const res: MovementVisit = {
    entityType: LocationEntities.MovementVisit,
    movementId,
    timestamp,
  };
  if (at) res.at = at;
  return res;
}
