import {isEqual, Value, ValueOrNothing} from './value';

/** Template type that can be converted to an array */
export type ArraySource<Type> = Type | Type[] | undefined;

/* Provides no, a single or an array of data and provides a corresponding array */
export function ensureArray<Type>(data: ArraySource<Type>): Type[] {
  if (!data) return [];
  else if (data instanceof Array) return data;
  else return [data];
}
export function ensureAndCombineArray<Type>(...arrays: ArraySource<Type>[]): Type[] {
  const arr1 = arrays.filter((el) => el);
  const res: Type[] = [];
  for (const a of arr1) res.push(...ensureArray(a));
  return res;
}

export function ensureScalar<Type>(data: Type | Type[]): Type {
  if (Array.isArray(data)) return data[0];
  else return data;
}

export function arraySafeLength(arr: undefined | unknown[]) {
  if (arr === undefined) return 0;
  return arr.length;
}

export function arrayRemove(arr: unknown[], val: unknown) {
  const idx = arr.indexOf(val);
  if (idx >= 0) arr.splice(idx, 1);
  return arr;
}

export function arrayPushIfNotExisting<Type extends ValueOrNothing>(arr: Type[], value: Type) {
  const found = arr.find((e) => isEqual(e, value));
  if (found === undefined) arr.push(value);

  return arr;
}

/** Returns the last element of an array */

export function lastElement<Type>(arr: Type[]): Type | undefined {
  return arr[arr.length - 1];
}

/** Transforms an array thereby removing undefined values */
export function arrayTransform<SrcType, DestType>(
  list: ArraySource<SrcType>,
  func: (value: SrcType) => DestType | undefined
) {
  const res: DestType[] = [];
  for (const el of ensureArray(list)) {
    const mapped = func(el);
    if (mapped !== undefined) res.push(mapped);
  }
  return res;
}

/** Maps an array to a list of sub-arrays and catenates them */

export function arrayConcat<SrcType, DestType>(
  list: ArraySource<SrcType>,
  func: (value: SrcType) => ArraySource<DestType>
) {
  let res: DestType[] = [];
  for (const el of ensureArray(list)) res = res.concat(ensureArray(func(el)));
  return res;
}

export function arrayIntersect<Type extends Value = Value>(arr1: Type[], arr2: Type[]): Type[] {
  const intersection = arr1.filter((v1) => arr2.findIndex((v2) => isEqual(v2, v1)) >= 0);
  return intersection;
}
