import { HighlightButton, TitlePage, useTextResources } from '@sqior/react/uibase';
import styles from './mugshot-page.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { UploadMedia } from '@sqior/js/media';
import { Bytes, now } from '@sqior/js/data';
import { AddOperation, isFinal } from '@sqior/js/operation';
import { SetProfilePicture } from '@sqior/viewmodels/user';

export interface MugshotPageProps {
  photo: HTMLCanvasElement;
  onClose: () => void;
}

enum DragType {
  Shift,
  Scale,
}
type Position = { x: number; y: number };
type Size = { width: number; height: number };
type Circle = Position & { radius: number };

/** Translates the click position to a position in image coordinates */
function translateCoord(pos: Position, size: Size, imgSize: Size): Position {
  /* Determine the size of the image contained - check which dimension is limiting */
  let scale: number;
  if (size.width * imgSize.height >= size.height * imgSize.width)
    scale = size.height / imgSize.height;
  else scale = size.width / imgSize.width;
  const constSize = { width: imgSize.width * scale, height: imgSize.height * scale };
  const contPos = {
    x: pos.x - (size.width - constSize.width) / 2,
    y: pos.y - (size.height - constSize.height) / 2,
  };
  return { x: contPos.x / scale, y: contPos.y / scale };
}

/** Determines the drag type based on the click and the circle position */
function determineDragType(circle: Circle, radius: number, click: Position) {
  /* Check if the click is near the corner radius */
  const clickRad = Math.pow(circle.x - click.x, 2) + Math.pow(circle.y - click.y, 2);
  if (clickRad >= (radius * radius * 3) / 4 && clickRad <= (radius * radius * 3) / 2)
    return DragType.Scale;
  else if (clickRad < radius * radius) return DragType.Shift;
  return undefined;
}

/** Limits a position to the valid values */
function limitPosition(size: Size, radius: number, pos: Position): Position {
  return {
    x: Math.max(radius, Math.min(pos.x, size.width - radius)),
    y: Math.max(radius, Math.min(pos.y, size.height - radius)),
  };
}
/** Determines a new shifted position */
function shiftedPosition(size: Size, circle: Circle, orig: Position, now: Position) {
  return {
    ...limitPosition(size, circle.radius, {
      x: circle.x + now.x - orig.x,
      y: circle.y + now.y - orig.y,
    }),
    radius: circle.radius,
  };
}
/** Determines a new scaled position */
function scaledPosition(size: Size, circle: Circle, orig: Position, now: Position) {
  let radius = Math.floor(
    circle.radius *
      (Math.sqrt(Math.pow(circle.x - now.x, 2) + Math.pow(circle.y - now.y, 2)) /
        Math.sqrt(Math.pow(circle.x - orig.x, 2) + Math.pow(circle.y - orig.y, 2)))
  );
  const minSize = Math.min(size.width, size.height);
  radius = Math.max(minSize / 8, Math.min(radius, minSize / 2));
  return { ...limitPosition(size, radius, circle), radius: radius };
}

/** Reduces an image size by a factor of 2 */
function downSample(image: HTMLCanvasElement): HTMLCanvasElement {
  const reduced = document.createElement('canvas');
  reduced.width = image.width / 2;
  reduced.height = image.height / 2;
  const ctx = reduced.getContext('2d');
  if (ctx)
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, reduced.width, reduced.height);
  return reduced;
}

/** Extracts the selected region from a canvas */
function extractImage(photo: HTMLCanvasElement, circle: Circle): HTMLCanvasElement {
  /* Determine the right size of the area so that it can be downscaled by exact 2x2 -> 1 pixel mappings */
  let radius = circle.radius,
    scale = 1;
  const maxImageSize = 512;
  while (radius * 2 > maxImageSize) {
    radius = Math.floor((radius + 1) / 2);
    scale *= 2;
  }
  radius *= scale;
  if (radius > photo.width || radius > photo.height) radius -= scale;
  const pos = limitPosition({ width: photo.width, height: photo.height }, radius, circle);
  /* Create image, perform the first downsample step with it, if applicable */
  let extracted = document.createElement('canvas');
  extracted.width = scale > 1 ? radius : radius * 2;
  extracted.height = scale > 1 ? radius : radius * 2;
  const ctx = extracted.getContext('2d');
  if (ctx)
    ctx.drawImage(
      photo,
      pos.x - radius,
      pos.y - radius,
      radius * 2,
      radius * 2,
      0,
      0,
      extracted.width,
      extracted.height
    );
  /* Further reduce in size if applicable */
  while (extracted.width > maxImageSize) extracted = downSample(extracted);
  return extracted;
}

/** Updates the overlay */
function drawOverlay(photo: HTMLCanvasElement, overlay: HTMLCanvasElement, pos: Circle) {
  if (pos === undefined) return;
  if (overlay.width !== photo.width || overlay.height !== photo.height) {
    overlay.width = photo.width;
    overlay.height = photo.height;
  }
  const ctx = overlay.getContext('2d');
  if (ctx) {
    ctx.drawImage(photo, 0, 0);
    ctx.fillStyle = '#00000080';
    ctx.beginPath();
    ctx.rect(0, 0, overlay.width, overlay.height);
    ctx.ellipse(pos.x, pos.y, pos.radius, pos.radius, 0, 0, 2 * Math.PI, true);
    ctx.fill();
    ctx.strokeStyle = '#1cade4';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.ellipse(pos.x, pos.y, pos.radius, pos.radius, 0, 0, 2 * Math.PI);
    ctx.stroke();
  }
}

export function MugshotPage(props: MugshotPageProps) {
  const dispatcher = useContext(OperationContext);
  const overlay = useRef<HTMLCanvasElement>(null);
  const textDict = useTextResources();
  const imgSize = { width: props.photo.width, height: props.photo.height };
  const [perc, setPerc] = useState<number | undefined>();
  const pos = useRef<Circle | undefined>();
  const mouse = useRef<[DragType, Position, Circle] | undefined>();
  /* Renders the overlay into the overlay canvas */
  useEffect(() => {
    /* Init position */
    const imgSize = { width: props.photo.width, height: props.photo.height };
    pos.current = {
      x: imgSize.width / 2,
      y: imgSize.height / 2,
      radius: Math.min(imgSize.width / 2, imgSize.height / 2),
    };
    if (overlay.current) drawOverlay(props.photo, overlay.current, pos.current);
  }, [props.photo]);
  return (
    <TitlePage
      className={styles['page']}
      title={textDict.get('cut_profile_picture')}
      onClose={props.onClose}
    >
      <div className={styles['image-container']}>
        <canvas
          ref={overlay}
          className={styles['image']}
          onPointerDown={(ev) => {
            ev.preventDefault();
            mouse.current = undefined;
            if (!overlay.current || perc !== undefined || pos.current === undefined) return;
            const clientRect = overlay.current.getBoundingClientRect();
            const clickPos = { x: ev.clientX - clientRect.left, y: ev.clientY - clientRect.top };
            const coord = translateCoord(
              clickPos,
              { width: clientRect.width, height: clientRect.height },
              imgSize
            );
            const dragType = determineDragType(pos.current, pos.current.radius, coord);
            if (dragType !== undefined) mouse.current = [dragType, coord, pos.current];
          }}
          onPointerMove={(ev) => {
            ev.preventDefault();
            if (!overlay.current || !mouse.current) return;
            const clientRect = overlay.current.getBoundingClientRect();
            const clickPos = { x: ev.clientX - clientRect.left, y: ev.clientY - clientRect.top };
            const coord = translateCoord(
              clickPos,
              { width: clientRect.width, height: clientRect.height },
              imgSize
            );
            if (mouse.current[0] === DragType.Shift)
              pos.current = shiftedPosition(imgSize, mouse.current[2], mouse.current[1], coord);
            else pos.current = scaledPosition(imgSize, mouse.current[2], mouse.current[1], coord);
            drawOverlay(props.photo, overlay.current, pos.current);
          }}
          onPointerUp={(ev) => {
            ev.preventDefault();
            mouse.current = undefined;
          }}
        />
      </div>
      <div className={styles['bottombar']}>
        <HighlightButton
          disabled={perc !== undefined}
          onClick={() => {
            setPerc(0);
            setTimeout(() => {
              if (pos.current === undefined) return;
              extractImage(props.photo, pos.current).toBlob((blob) => {
                if (blob) {
                  const uploadUp = dispatcher.start(
                    UploadMedia(now(), 'image/jpeg', new Bytes(blob)),
                    undefined,
                    setPerc
                  ) as AddOperation;
                  uploadUp.stateChange.on((state) => {
                    if (isFinal(state)) {
                      if (uploadUp.finalId) dispatcher.start(SetProfilePicture(uploadUp.finalId));
                      props.onClose();
                    }
                  });
                }
              }, 'image/jpeg');
            }, 50);
          }}
        >
          {perc
            ? perc + textDict.get('percentage_uploaded')
            : textDict.get(perc !== undefined ? 'processing_image' : 'take_over')}
        </HighlightButton>
      </div>
    </TitlePage>
  );
}

export default MugshotPage;
