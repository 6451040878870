import { FactoryProps } from '@sqior/react/factory';
import { DashboardStage } from '@sqior/react/uivisual';
import { ListViewInfos } from '@sqior/viewmodels/visual';
import styles from './bed-allocation-dashboard.module.css';

export type BedAllocationDashboardProps = FactoryProps<ListViewInfos>;

export function BedAllocationDashboard(props: BedAllocationDashboardProps) {
  return (
    <div
      className={styles['container']}
      style={{
        gridTemplateColumns:
          props.data.lists.length < 4 ? `repeat(auto-fill, minmax(250px, 1fr))` : undefined,
      }}
    >
      {props.data.lists.map((list, index) => (
        <DashboardStage
          key={list.path}
          className={styles['min-width']}
          title={list.title}
          path={list.path}
          separator={index + 1 < props.data.lists.length}
          containerStyle={{ height: '100vh' }}
        />
      ))}
    </div>
  );
}

export default BedAllocationDashboard;
