import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { VisualEntities } from './visual-definitions';
import { ColorCodes } from '@sqior/viewmodels/visual';

/** Entity storing a color code */
export type ColorCodeEntity = EntityHeader & { color: string };
export const ColorCodeModel: EntityModel = {
  type: VisualEntities.ColorCode,
  props: ['color'],
  unclassified: true,
};
export function makeColorCode(color: ColorCodes): ColorCodeEntity {
  return { entityType: ColorCodeModel.type, color: color };
}
