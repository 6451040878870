import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel } from './entity';
import { makeImmutable } from '@sqior/js/data';

export const SQIORAssistantModel: EntityModel = {
  type: CoreEntities.SQIORAssistant,
  props: [],
  keys: [],
  extends: CoreEntities.Key,
  unclassified: true,
};
export const SQIORAssistant = makeImmutable({ entityType: CoreEntities.SQIORAssistant } as Entity);
