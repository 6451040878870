import { ensureArray } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { CommandEntity, CoreEntities, EntityModel } from '@sqior/js/meta';
import { TimeEntities } from './time-definitions';

/* Command setting a timer that - once it elapses - triggers the provided command entities */

export type SetTimerEntity = CommandEntity & { timeout: Entity; entities: Entity[] };
export const SetTimerEntityModel: EntityModel = {
  type: TimeEntities.SetTimer,
  props: ['timeout', 'entities'],
  extends: CoreEntities.Command,
};
export function setTimer(timeout: Entity, entity: Entity | Entity[]): SetTimerEntity {
  return { entityType: TimeEntities.SetTimer, timeout: timeout, entities: ensureArray(entity) };
}
