import { State } from '@sqior/js/state';

export type ViewportSize = { width: number; height: number };

export class ViewportState extends State {
  constructor() {
    super();
    window.addEventListener('load', () => {
      this.set({ width: window.innerWidth, height: window.innerHeight });
    });
    window.addEventListener('resize', () => {
      this.set({ width: window.innerWidth, height: window.innerHeight });
    });
  }
}
