import { classes } from '@sqior/react/utils';
import React, { MouseEvent, useState } from 'react';
import styles from './highlight-button.module.css';
import { Logo, LogoType } from '../logo/logo';
import { motion } from 'framer-motion';

/* eslint-disable-next-line */
export interface HighlightButtonProps {
  children: React.ReactNode;
  secondary?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
  animated?: boolean;
  onClick: (e: MouseEvent) => void;
  style?: React.CSSProperties;
}

const ANIMATION_DURATION = 75;

const ANIMATION = {
  DURATION: 75,
  DELAY: 500,
  PADDING: 10,
};

export function HighlightButton({
  children,
  secondary,
  disabled,
  additionalClassName,
  animated = false,
  onClick,
  style,
}: HighlightButtonProps) {
  const [animationWidth, setAnimationWidth] = useState<number>(0);
  const [clicked, setClicked] = useState<boolean>(false);

  function handleClick(e: React.MouseEvent) {
    if (!disabled) onClick(e);
  }
  const handleLocaleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setClicked(true);
    if (!secondary) {
      setTimeout(() => handleClick(e), ANIMATION_DURATION);
      setTimeout(() => setClicked(false), ANIMATION_DURATION + ANIMATION.DELAY);
      return;
    }
    handleClick(e);
  };

  const handleButtonReferenceChange = (node: HTMLButtonElement) => {
    if (!node) return;
    setAnimationWidth(node.getBoundingClientRect().width - ANIMATION.PADDING);
  };

  return (
    <button
      ref={handleButtonReferenceChange}
      className={classes(styles[secondary ? 'button-secondary' : 'button'], additionalClassName)}
      onClick={handleLocaleOnClick}
      disabled={disabled || false}
      style={{ position: 'relative', ...style }}
    >
      {clicked && animated && !secondary && (
        <motion.div
          className={styles['animation-container']}
          initial={{ width: 0 }} // Start from left
          animate={{ width: animationWidth }} // Move to right and disappear
          transition={{ duration: ANIMATION.DURATION / 1000 }}
        >
          <Logo type={LogoType.LogoOnly} className={styles['logo']} />
        </motion.div>
      )}
      {children}
    </button>
  );
}

export default HighlightButton;
