import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';

export type EmployeeId = Entity & { id: string };
export const EmployeeIdModel: EntityModel = {
  type: UserEntities.EmployeeId,
  props: ['id'],
  keys: ['id'],
  unclassified: false,
};
export function makeEmployeeId(id: string): EmployeeId {
  return { entityType: UserEntities.EmployeeId, id: id };
}
