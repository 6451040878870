import styles from './analytic-header.module.css';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React from 'react';
import { createTimeRangeArray } from '../demo-analytics-data';
import RangePicker, { TimeRangeSelect } from '../range-picker/range-picker';
import { SvgCalendar } from '@sqior/react/uibase';
import { WithSelection } from '../utils';

const BORDER_COLOR = 'rgba(48, 56, 89, 1)';

const SX = {
  // backgroundColor: '#121930',
  borderWidth: 2,
  borderRadius: '6px',
  minWidth: '120px',
  height: '35px',
  '& .MuiSelect-icon': {
    color: '#51535E', // You can use theme colors or any CSS color value
  },
  '.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: BORDER_COLOR,
      borderRadius: '6px',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: BORDER_COLOR,
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: BORDER_COLOR,
      borderWidth: '1px',
    },
  },
};

export interface AnalyticHeaderProps {
  tabs: WithSelection<string[]>;
  stations: WithSelection<string[]>;
  timeRanges: WithSelection<TimeRangeSelect[]>;

  onTabChange?: (index: number) => void;
  onStationChange?: (index: number) => void;
  onTimeRangeChange?: (index: number) => void;
}

const TIME_RANGES_OPTIONS = createTimeRangeArray();

export function AnalyticHeader({
  tabs,
  stations,
  timeRanges,
  onTabChange,
  onStationChange,
  onTimeRangeChange,
}: AnalyticHeaderProps) {
  const handleTabChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    if (!newValue) return;
    const foundIndex = tabs.value.findIndex((v) => v === newValue);
    if (foundIndex === -1) return;
    onTabChange?.(foundIndex);
  };

  const handleStationChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (!value) return;
    const foundIndex = stations.value.findIndex((v) => v === value);
    if (foundIndex === -1) return;
    onStationChange?.(foundIndex);
  };

  const handleTimeRangeChange = (value: TimeRangeSelect) => {
    const foundIndex = timeRanges.value.findIndex((v) => v.label === value.label);
    if (foundIndex === -1) return;
    onTimeRangeChange?.(foundIndex);
  };

  return (
    <div className={styles['container']}>
      <ToggleButtonGroup
        color="primary"
        value={tabs.value[tabs.selected]}
        exclusive
        onChange={handleTabChange}
        aria-label="Platform"
        style={{ height: 35 }}
      >
        {tabs.value.map((option) => {
          return (
            <ToggleButton key={option} value={option} style={{ padding: '0px 20px' }}>
              {option}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <div className={styles['actions']}>
        <FormControl sx={SX}>
          <Select
            value={stations.value[stations.selected]}
            onChange={handleStationChange}
            style={{ height: 35 }}
          >
            {stations.value.map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <RangePicker
          icon={<SvgCalendar />}
          options={TIME_RANGES_OPTIONS}
          onChange={handleTimeRangeChange}
          value={timeRanges.value[timeRanges.selected]}
          style={{ height: 35, borderColor: BORDER_COLOR, width: 320 }}
          menuWidth={320}
        />
      </div>
    </div>
  );
}

export default AnalyticHeader;
