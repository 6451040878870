import { Dispatcher, StreamOperation } from '@sqior/js/operation';
import { EntityHTML } from './entity-html';

export type UndoInfoVM = {
  text: EntityHTML;
  undoInterpreterKey: string;
  timestamp: number;
};

export function doUndo(dispatcher: Dispatcher, undoInterpreterKey: string) {
  const op = new StreamOperation({ interpreterKey: undoInterpreterKey, finalize: '' });
  dispatcher.handle(op, `context-command/${undoInterpreterKey}`);
}
