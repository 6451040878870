import styles from './list-header-view.module.css';
import { ListHeader } from '@sqior/viewmodels/visual';
import { FactoryProps } from '@sqior/react/factory';

export type ListHeaderViewProps = FactoryProps<ListHeader>;

export function ListHeaderView(props: ListHeaderViewProps) {
  return <div className={styles['container']}>{props.data.title}</div>;
}

export default ListHeaderView;
