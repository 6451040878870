import { ReadConfirmationStatus } from '@sqior/viewmodels/communication';
import styles from './thread-item-status.module.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';

/* eslint-disable-next-line */
export interface ThreadItemStatusProps {
  className?: string;
  readConfirmation?: ReadConfirmationStatus;
}

export function ThreadItemStatus(props: ThreadItemStatusProps) {
  const getMessageStatus = (confirmation?: ReadConfirmationStatus) => {
    switch (confirmation) {
      case ReadConfirmationStatus.ImplicitPartially:
        return <DoneAllIcon style={{ fontSize: 10 }} />;
      case ReadConfirmationStatus.Implicit:
        return <DoneAllIcon style={{ fontSize: 10 }} />;
      case ReadConfirmationStatus.ExplicitPartially:
        return <DoneAllIcon style={{ fontSize: 10 }} />;
      case ReadConfirmationStatus.Explicit:
        return <DoneAllIcon style={{ fontSize: 10 }} color="primary" />;
      default:
        return <DoneIcon style={{ fontSize: 10 }} />;
    }
  };

  return (
    <div className={props.className} style={{ display: 'flex', alignItems: 'center' }}>
      <div className={styles['container']}>{getMessageStatus(props.readConfirmation)}</div>
    </div>
  );
}

export default ThreadItemStatus;
