import { CacheState, CacheTracer, CombinedCacheState } from '@sqior/js/cache';
import { ArraySource, clone, ensureArray, TimerInterface } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';
import { DomainInterface, MetaContext } from './domain-interface';
import { EntityRecord } from './entity';
import { Undefined } from './function';
import { MappingContextKeys, mappingContextKeysAdd } from './entity-mapping-context-keys';
import { EntityMappingTrace } from './entity-mapping';
import { EntityMappingCacheResult } from './entity-mapping-cache';
import { applyAfter } from '@sqior/js/async';

export type EntityMappingFunc<
  FromEntity extends Entity = Entity,
  ToEntity extends Entity = Entity
> = (
  entity: FromEntity,
  mapper: TrackingDomainInterface
) => ToEntity | undefined | Promise<ToEntity | undefined>;
export type BasicSyncMappingFunc<
  FromEntity extends Entity = Entity,
  ToEntity extends Entity = Entity
> = (entity: FromEntity) => ToEntity | undefined;
export type SyncMappingFunc<
  FromEntity extends Entity = Entity,
  ToEntity extends Entity = Entity
> = (entity: FromEntity, mapper: ContextInterface & CacheTracer) => ToEntity | undefined;

export type SyncMappingOptions = {
  weight?: number;
  context?: string | string[];
};
export type EntityMappingOptions = SyncMappingOptions & {
  cache?: boolean;
  valueComparison?: boolean;
};
export type EntityMappings = [EntityMappingFunc, EntityMappingOptions?][];

/* Interface for accessing context properties */
export interface ContextInterface {
  context<Type extends Entity>(name: string): Type;
  tryContext<Type extends Entity>(name: string): Type | undefined;
}

/* Derived class that tracks all cache states that are accessed via mappings called from within the mapping */
export class TrackingDomainInterface
  extends DomainInterface
  implements CacheTracer, ContextInterface
{
  constructor(
    meta: MetaContext,
    context: EntityRecord,
    mappings: EntityMappings,
    trace: EntityMappingTrace,
    timer: TimerInterface,
    displayTimer?: TimerInterface
  ) {
    super(meta);
    this.contextEnts = context;
    this.mappings = mappings;
    this.mappingTrace = trace;
    this.theTimer = timer;
    this.theDisplayTimer = displayTimer;
  }

  override mapInternal(
    entity: Entity,
    type: string,
    context?: EntityRecord
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    /* Call base class, use parent context if none is specified */
    return applyAfter(
      super.mapInternal(entity, type, context ? context : this.forwardContext),
      (res) => {
        /* Register context accesses if parent context is provided */
        if (!context || context['forward'])
          this.contextKeys = mappingContextKeysAdd(this.contextKeys, res.contextKeys);
        /* Register the access to the cache */
        if (res.cache) {
          res.cache.incRef();
          this.cacheAccess(res.cache);
        }
        return res;
      }
    );
  }

  /** Returns the context property registered for the name and checks if the access is declared */
  private accessContext(name: string) {
    /* Check that the access to this context property was declared */
    if (!this.declaredContextEnts)
      this.declaredContextEnts = new Set<string>(ensureArray(this.mappings[0][1]?.context));
    if (!this.declaredContextEnts.has(name))
      throw new Error('Undeclared access to context property: ' + name);
    return this.contextEnts[name];
  }

  context<Type extends Entity>(name: string): Type {
    const ce = this.accessContext(name);
    /* Access to this context key needs to be registered, even though we will throw */
    this.contextKeys = mappingContextKeysAdd(this.contextKeys, name);
    if (!ce) throw new Error('Context entity not found - name provided: ' + name);
    return ce as Type;
  }
  tryContext<Type extends Entity>(name: string): Type | undefined {
    const ce = this.accessContext(name);
    this.contextKeys = mappingContextKeysAdd(this.contextKeys, name);
    return ce ? (ce as Type) : undefined;
  }

  async superseded<Type extends Entity = Entity>(
    entity: Entity,
    context?: EntityRecord
  ): Promise<Type> {
    if (this.mappings.length <= 1) throw new Error('There is no more superseded mapping to call');
    /* Establish sub-interface */
    const subDom = new TrackingDomainInterface(
      this.meta,
      context ? context : this.contextEnts,
      this.mappings.slice(1),
      this.mappingTrace || {},
      this.theTimer,
      this.theDisplayTimer
    );
    /* Call actual mapping */
    let res: Entity | undefined;
    try {
      res = await this.mappings[1][0](entity, subDom);
    } catch (e) {
      /* treat as undefined */
    }
    /* Take over the cache and context accessed */
    const cache = subDom.combinedCacheState();
    if (cache) this.cacheAccess(cache);
    if (!context || context['forward'])
      this.contextKeys = mappingContextKeysAdd(this.contextKeys, subDom.contextKeys);
    if (!res) throw new Error('The superseded mapping did not provide a result');
    return res as Type;
  }

  async trySuperseded<Type extends Entity = Entity>(
    entity: Entity,
    context?: EntityRecord
  ): Promise<Type | undefined> {
    if (this.mappings.length <= 1) return undefined;
    /* Establish sub-interface */
    const subDom = new TrackingDomainInterface(
      this.meta,
      context ? context : this.contextEnts,
      this.mappings.slice(1),
      this.mappingTrace || {},
      this.theTimer,
      this.theDisplayTimer
    );
    /* Call actual mapping */
    let res: Entity | undefined;
    try {
      res = await this.mappings[1][0](entity, subDom);
    } catch (e) {
      /* treat as undefined */
    }
    /* Take over the cache and context accessed */
    const cache = subDom.combinedCacheState();
    if (cache) this.cacheAccess(cache);
    if (!context || context['forward'])
      this.contextKeys = mappingContextKeysAdd(this.contextKeys, subDom.contextKeys);
    return res ? (res as Type) : undefined;
  }

  forward(cps: string | string[], autoForward = true): EntityRecord {
    const context = autoForward ? clone(this.forwardContext) : {};
    /* Mark this context as forwarded */
    context['forward'] = Undefined;
    for (const name of ensureArray(cps)) {
      const cp = this.accessContext(name);
      if (cp !== undefined) context[name] = cp;
    }
    return context;
  }

  private get forwardContext() {
    if (this.forwardContextEnts) return this.forwardContextEnts;
    /* Determine all automatically forward context parameters */
    this.forwardContextEnts = {};
    for (const key in this.contextEnts)
      if (key !== 'forward') {
        const cpm = this.meta.contextProperties.get(key);
        if (cpm === undefined)
          Logger.error(['Unregistered context property provided to mapping:', key]);
        else if (cpm.autoForward) this.forwardContextEnts[key] = this.contextEnts[key];
      }
    return this.forwardContextEnts;
  }

  get steadyTimer(): TimerInterface {
    return this.theTimer;
  }
  get displayTimer(): TimerInterface {
    return this.theDisplayTimer ? this.theDisplayTimer : this.theTimer;
  }

  /** Registers an access to an external cache state */
  cacheAccess(cs: CacheState) {
    if (this.cacheStates) this.cacheStates.push(cs);
    else this.cacheStates = [cs];
  }

  /** Returns the combined cache state */
  combinedCacheState() {
    return this.cacheStates ? CombinedCacheState.combine(...this.cacheStates) : undefined;
  }

  private cacheStates?: CacheState[];
  private contextEnts: EntityRecord;
  private mappings: EntityMappings;
  contextKeys: MappingContextKeys;
  private declaredContextEnts?: Set<string>;
  private forwardContextEnts?: EntityRecord;
  private theTimer: TimerInterface;
  private theDisplayTimer?: TimerInterface;
}

/* Stripped down interface only providing access to meta data and context */
export class SyncMappingInterface implements CacheTracer, ContextInterface {
  constructor(meta: MetaContext, declaredContext: ArraySource<string>, context: EntityRecord) {
    this.meta = meta;
    this.declaredContext = declaredContext;
    this.contextEnts = context;
  }

  /** Returns the context property registered for the name and checks if the access is declared */
  private accessContext(name: string) {
    /* Check that the access to this context property was declared */
    if (!this.declaredContextEnts)
      this.declaredContextEnts = new Set<string>(ensureArray(this.declaredContext));
    if (!this.declaredContextEnts.has(name))
      throw new Error('Undeclared access to context property: ' + name);
    return this.contextEnts[name];
  }

  context<Type extends Entity>(name: string): Type {
    const ce = this.accessContext(name);
    /* Access to this context key needs to be registered, even though we will throw */
    this.contextKeys = mappingContextKeysAdd(this.contextKeys, name);
    if (!ce) throw new Error('Context entity not found - name provided: ' + name);
    return ce as Type;
  }
  tryContext<Type extends Entity>(name: string): Type | undefined {
    const ce = this.accessContext(name);
    this.contextKeys = mappingContextKeysAdd(this.contextKeys, name);
    return ce ? (ce as Type) : undefined;
  }

  /** Registers an access to an external cache state */
  cacheAccess(cs: CacheState) {
    if (this.cacheStates) this.cacheStates.push(cs);
    else this.cacheStates = [cs];
  }

  /** Returns the combined cache state */
  combinedCacheState() {
    return this.cacheStates ? CombinedCacheState.combine(...this.cacheStates) : undefined;
  }

  readonly meta: MetaContext;
  private contextEnts: EntityRecord;
  private declaredContext: ArraySource<string>;
  contextKeys: MappingContextKeys;
  private declaredContextEnts?: Set<string>;
  private cacheStates?: CacheState[];
}
