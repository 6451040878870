import styles from './checkbox.module.css';

/* eslint-disable-next-line */
export interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  onChanged?: (checked: boolean) => void;
  label?: string;
  additionalClassName?: string;
}

export function Checkbox(props: CheckboxProps) {
  return (
    <div
      className={`${props.additionalClassName} ${
        styles[
          props.checked ? 'checked-container' : props.disabled ? 'disabled-container' : 'container'
        ]
      }`}
      onClick={() => {
        if (props.onChanged) props.onChanged(!props.checked);
      }}
    >
      {props.label && (
        <div className={styles[props.checked ? 'checked-label' : 'label']}>{props.label}</div>
      )}
    </div>
  );
}

export default Checkbox;
