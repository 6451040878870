/* Entities */

import { IdEntity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';

export enum VisualEntities {
  EntityHTML = 'EntityHTML',
  HTML = 'HTML',
  HTMLTemplate = 'HTMLTemplate',
  Overview = 'Overview',
  List = 'ListView',
  ListRoots = 'ListViewRoots',
  ListInfo = 'ListViewInfo',
  ListInfos = 'ListViewInfos',
  ItemState = 'ItemState',
  URL = 'URL', // URL for accessing an object
  ColorCode = 'ColorCode', // String representing an abstract color
  StateHandlerType = 'StateHandlerType', // State handler type name found in factory
  Attachments = 'AttachmentsContainer', // Container for attachments
  TrafficLight = 'TrafficLight', // States of a traffic light
  AnonymousHTML = 'AnonymousHTML', // HTML text not containing classified data
  SessionId = 'SessionId', // Generic ID which is session specific
  URLTemplate = 'URLTemplate', // Template URL with separate ID parameters
}

/* Interfaces */

export enum VisualInterfaces {
  Title = 'Title',
  Dashboard = 'Dashboard',
  IsChallenged = 'IsChallenged', // Checks whether there are challenges associated with the source entity, so that e.g. a warning indiciator shall be displayed
  Attachments = 'Attachments', // Interface to get attachments
  AnonymizedHTML = 'AnonymizedHTML', // Interface returning anonymous HTML for an entity
  AnonymizedEntityHTML = 'AnonymizedEntityHTML', // Interface returning anonymous entity HTML
  ViewModel = 'ViewModel', // Interface returning a view model representation of an entity
  ViewResult = 'ViewResult', // Interface returning whether an entity qualifies as a result from a view
}

export const AttachmentModel: Interface = {
  type: VisualInterfaces.Attachments,
  represents: [VisualEntities.Attachments],
};

/** Interface returning a view model representation */

export const ViewModelInterface: Interface = { type: VisualInterfaces.ViewModel };

/** Session specific ID */

export const SessionIdModel: EntityModel = {
  type: VisualEntities.SessionId,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};
export function makeSessionId(id: string): IdEntity {
  return { entityType: SessionIdModel.type, id };
}

/** Interface returning whether an entity is a valid view result */

export const ViewResultInterface: Interface = { type: VisualInterfaces.ViewResult };
