import { Entity, EntityHeader } from '@sqior/js/entity';

/* Entity representing the input parameter options of a transport */

export const TransportOrderInputType = 'TransportOrderInput';
export type TransportOrderLocationInput = { label: string; select: Entity };
export type TransportOrderInput = EntityHeader & {
  from: TransportOrderLocationInput[];
  to: TransportOrderLocationInput[];
};

/* Entity representing the selected input parameters of a transport order */

export const TransportOrderParametersType = 'TransportOrderParameters';
export type TransportOrderParameters = EntityHeader & {
  from: Entity;
  to: Entity;
  priority: number;
  infectious: boolean;
  comment?: string;
};
export function makeTransportOrderParameters(
  from: Entity,
  to: Entity,
  priority: number,
  infectious: boolean,
  comment?: string
): TransportOrderParameters {
  const res: TransportOrderParameters = {
    entityType: TransportOrderParametersType,
    from,
    to,
    priority,
    infectious,
  };
  if (comment) res.comment = comment;
  return res;
}
