import styles from './on-off-switch.module.css';

/* eslint-disable-next-line */
export interface OnOffSwitchProps {
  active: boolean;
  onChanged: (active: boolean) => void;
}

export function OnOffSwitch(props: OnOffSwitchProps) {
  return (
    <div
      className={styles['container']}
      onClick={() => {
        props.onChanged(!props.active);
      }}
    >
      <div className={styles[props.active ? 'base-active' : 'base']} />
      <input type="checkbox" className={styles['knob']} checked={props.active} readOnly={true} />
    </div>
  );
}

export default OnOffSwitch;
