import { useEffect, useRef } from 'react';

export enum SwipeDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

const DEFAULT_MIN_SWIPE_DISTANCE = 50;
const DEFAULT_THRESHOLD = 50;

interface UseSwipeProps {
  ref: React.RefObject<HTMLElement>;
  onSwipe?: (direction: SwipeDirection) => void;
  onMove?: (x: number) => void;
  onReset?: () => void;
  options?: {
    minSwipeDistance?: number;
    clampDistance?: number;
    threshold?: number;
  };
}

export const useSwipe = ({ ref, onSwipe, onMove, onReset, options }: UseSwipeProps) => {
  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);

  useEffect(() => {
    const minSwipeDistance = options?.minSwipeDistance || DEFAULT_MIN_SWIPE_DISTANCE;

    const targetElement = ref.current;
    if (!targetElement) return;

    const onTouchStart = (e: TouchEvent) => {
      touchEndX.current = null;
      touchStartX.current = e.targetTouches[0].clientX;
    };

    const onTouchMove = (e: TouchEvent) => {
      if (!touchStartX.current) return;
      touchEndX.current = e.targetTouches[0].clientX;
      const distance = touchStartX.current - touchEndX.current;
      const threshold = options?.threshold || DEFAULT_THRESHOLD;
      if (Math.abs(distance) < threshold) return;
      onMove?.(touchEndX.current - touchStartX.current);
    };

    const onTouchEnd = () => {
      if (!touchStartX.current || !touchEndX.current) return;
      const direction = getDirection();

      // get width of the container
      const containerWidth = targetElement.getBoundingClientRect().width;
      const clampDistance = options?.clampDistance || containerWidth / 2;
      const swipeDistance = touchStartX.current - touchEndX.current;

      if (Math.abs(swipeDistance) < clampDistance) {
        onReset?.();
        return;
      }
      if (!direction) return;
      touchStartX.current = null;
      touchEndX.current = null;
      onSwipe?.(direction);
    };

    const getDirection = (): SwipeDirection | undefined => {
      if (!touchStartX.current || !touchEndX.current) return undefined;
      const swipeDistance = touchStartX.current - touchEndX.current;
      let direction;
      if (swipeDistance > minSwipeDistance) {
        direction = SwipeDirection.LEFT;
      } else if (swipeDistance < -minSwipeDistance) {
        direction = SwipeDirection.RIGHT;
      }
      return direction;
    };

    targetElement.addEventListener('touchstart', onTouchStart);
    targetElement.addEventListener('touchmove', onTouchMove);
    targetElement.addEventListener('touchend', onTouchEnd);

    return () => {
      targetElement.removeEventListener('touchstart', onTouchStart);
      targetElement.removeEventListener('touchmove', onTouchMove);
      targetElement.removeEventListener('touchend', onTouchEnd);
    };
  }, [ref, onSwipe]);
};

export default useSwipe;
