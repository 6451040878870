import { Domain, DomainInterface, Interface } from '@sqior/js/meta';
import { UserContextProperty, UserInterfaces } from './user-definitions';
import { Roles, RolesEntity, intersectRoles, makeRoleEntity, makeRolesEntity } from './role-entity';
import { Entity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';
import { arrayFilterAsync } from '@sqior/js/async';
import { ArraySource, ensureArray } from '@sqior/js/data';

export const HasUserAccessModel: Interface = { type: UserInterfaces.HasUserAccess };
export const UserAccessibleEntityModel: Interface = { type: UserInterfaces.UserAccessibleEntity };

const CleaningRole = makeRoleEntity(Roles.CleaningStaff);
const NewEmployeeRole = makeRoleEntity(Roles.NewEmployee);

export class UserAccessDomain extends Domain {
  constructor() {
    super('UserAccess', { interfaces: [HasUserAccessModel, UserAccessibleEntityModel] });

    /* Default implementation forbiddung cleaning stuff to access objects */
    this.addEntityMapping(
      UserInterfaces.UserAccessibleEntity,
      UserInterfaces.HasUserAccess,
      async (entity, mapper) => {
        const userRoles = await mapper.map<RolesEntity>(
          mapper.context(UserContextProperty),
          UserInterfaces.EffectiveRoles
        );
        const rolesExceptCleaning = await arrayFilterAsync(userRoles.roles, async (r) => {
          const baseRole = await mapper.tryMap(r, UserInterfaces.BaseRole);
          return (
            baseRole !== undefined &&
            !(
              (await mapper.isEquivalent(baseRole, CleaningRole)) ||
              (await mapper.isEquivalent(baseRole, NewEmployeeRole))
            )
          );
        });
        const accessGranted = rolesExceptCleaning.length > 0;
        if (!accessGranted)
          Logger.debug([
            'HasUserAccessMapping',
            JSON.stringify(mapper.tryContext(UserContextProperty)),
            JSON.stringify(entity),
            accessGranted,
          ]);
        return (accessGranted && entity) || undefined;
      },
      { context: UserContextProperty, weight: 100 }
    );
  }

  /** Checks whether the specified user has access to the desired object */
  static async hasUserAccess(domain: DomainInterface, user: Entity, object: Entity) {
    const userAccessibleEntity = await domain.tryMap(object, UserInterfaces.UserAccessibleEntity, {
      user: user,
    });
    if (!userAccessibleEntity)
      Logger.warn([
        `Entity of type ${object.entityType} is not mappable to ${UserInterfaces.UserAccessibleEntity} - hasUserAccess() will never return true`,
      ]);
    const result =
      userAccessibleEntity &&
      (await domain.tryMap(userAccessibleEntity, UserInterfaces.HasUserAccess, { user: user })) !==
        undefined;
    if (!result) Logger.debug(['hasUserAccess', user, object, '->', userAccessibleEntity, result]);
    return result;
  }

  static async hasUserAllRoles(mapper: DomainInterface, user: Entity, roles: ArraySource<Roles>) {
    const userRoles = await mapper.tryMap<RolesEntity>(user, UserInterfaces.EffectiveRoles);
    const hasRole = userRoles && intersectRoles(userRoles, ensureArray(roles)).length > 0;
    return hasRole;
  }

  static async hasUserAnyRole(mapper: DomainInterface, user: Entity, roles: ArraySource<Roles>) {
    const userRoles =
      (await mapper.tryMap<RolesEntity>(user, UserInterfaces.EffectiveRoles)) ||
      makeRolesEntity([]);
    for (const role of ensureArray(roles))
      if (intersectRoles(userRoles, [role]).length > 0) return true;
    return false;
  }
}
