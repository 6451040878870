import { Value } from '@sqior/js/data';
import { DataOperation } from './data-operation';
import { OperationType } from './operation';

export class AddOperation<DataType extends Value = Value> extends DataOperation {
  constructor(data: DataType) {
    super(OperationType.Add, data);
  }
  static fromJSON<DataType extends Value = Value>(json: DataType) {
    return new AddOperation<DataType>(json);
  }
}
