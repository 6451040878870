import { Entity } from '@sqior/js/entity';
import { DomainInterface } from './domain-interface';
import { RelatedDataModel } from './related-data-model';
import { isEqual, KeyPairMap } from '@sqior/js/data';
import { CacheHolder, CacheTracer } from '@sqior/js/cache';
import { Logger } from '@sqior/js/log';
import { EntityRecord } from './entity';
import { CoreEntities } from './core-definitions';

export type RelatedDataLibraryItem = { anchor: Entity; data: EntityRecord };

/** Class exposing a library of related data items */

export class RelatedDataRegistry {
  constructor(mapper: DomainInterface, models: RelatedDataModel[]) {
    this.mapper = mapper;
    for (const model of models) this.models.set(model.prop, model);
  }

  /** Sets the contents of the registry */
  set(items: RelatedDataLibraryItem[]) {
    /* Synchronize the data provided */
    const anchors = new Set<string>();
    const anchorIndex = new KeyPairMap<string, string, Entity[]>();
    for (const item of items) {
      const anchorKey = this.mapper.makeKey(item.anchor);
      anchors.add(anchorKey);
      /* Loop all data provided */
      for (const key in item.data) {
        /* Ignore undefined values */
        const value = item.data[key];
        if (value.entityType === CoreEntities.Undefined) continue;
        /* Get model, if not registered this does not deal with this kind of data */
        const model = this.models.get(key);
        if (!model) continue;
        /* Validate element, if desired */
        if (model.type && Logger.validate) this.mapper.validateType(value, model.type);
        /* Get existing entry, if applicable */
        const entry = this.elements.get(anchorKey, key);
        if (!entry) this.elements.set(anchorKey, key, { value });
        else if (!this.mapper.isEqual(entry.value, value)) {
          entry.value = value;
          entry.cache?.touch();
        }
        /* Check if this data needs to be indexed */
        if (!model.index) continue;
        if (model.multiValueEntityProp)
          for (const multiValue of value[model.multiValueEntityProp] as Entity[]) {
            const indexKey = this.mapper.makeKey(multiValue);
            const indexEntry = anchorIndex.get(key, indexKey);
            if (indexEntry) indexEntry.push(item.anchor);
            else anchorIndex.set(key, indexKey, [item.anchor]);
          }
        else {
          const indexKey = this.mapper.makeKey(value);
          const indexEntry = anchorIndex.get(key, indexKey);
          if (indexEntry) indexEntry.push(item.anchor);
          else anchorIndex.set(key, indexKey, [item.anchor]);
        }
      }
      /* Look out for data no longer provided */
      const exElements = this.elements.map.get(anchorKey);
      if (exElements)
        for (const el of exElements)
          if (!item.data[el[0]])
            if (!el[1].cache) exElements.delete(el[0]);
            else if (el[1].value) {
              delete el[1].value;
              el[1].cache.touch();
            }
    }
    /* Look for anchors that are no longer contained */
    for (const exAnchor of this.elements.map)
      if (!anchors.has(exAnchor[0])) {
        for (const el of exAnchor[1])
          if (!el[1].cache) exAnchor[1].delete(el[0]);
          else if (el[1].value) {
            delete el[1].value;
            el[1].cache.touch();
          }
        if (!exAnchor[1].size) this.elements.map.delete(exAnchor[0]);
      }
    /* Synchronize the anchors index */
    for (const aip of anchorIndex.map) {
      /* Synchronize known values */
      for (const value of aip[1]) {
        const exValue = this.anchorIndex.get(aip[0], value[0]);
        if (!exValue) this.anchorIndex.set(aip[0], value[0], { anchors: value[1] });
        else if (!isEqual(value[1], exValue.anchors)) {
          exValue.anchors = value[1];
          if (exValue.cache) exValue.cache.touch();
        }
      }
      /* Remove unknown values */
      const exAip = this.anchorIndex.map.get(aip[0]);
      if (exAip) {
        for (const exValue of exAip)
          if (!aip[1].has(exValue[0]))
            if (!exValue[1].cache) exAip.delete(exValue[0]);
            else if (exValue[1].anchors) {
              delete exValue[1].anchors;
              exValue[1].cache.touch();
            }
        if (!exAip.size) this.anchorIndex.map.delete(aip[0]);
      }
    }
    /* Remove completely unknown properties from the anchors index */
    for (const aip of this.anchorIndex.map)
      if (!anchorIndex.map.has(aip[0])) {
        for (const exValue of aip[1])
          if (!exValue[1].cache) aip[1].delete(exValue[0]);
          else if (exValue[1].anchors) {
            delete exValue[1].anchors;
            exValue[1].cache.touch();
          }
        if (!aip[1].size) this.anchorIndex.map.delete(aip[0]);
      }
  }

  /** Returns the value associated with a specified anchor and property */
  get(anchor: Entity, prop: string, tracer?: CacheTracer) {
    const anchorKey = this.mapper.makeKey(anchor);
    /* Check if the element is present */
    const entry = this.elements.get(anchorKey, prop);
    if (entry) {
      /* Register cache access, if applicable */
      if (tracer) {
        if (!entry.cache) entry.cache = new CacheHolder();
        entry.cache.access(tracer);
      }
      return entry.value;
    }
    /* Nothing to be done, if no tracer is provided */
    if (!tracer) return undefined;
    /* Register access */
    const cache = new CacheHolder();
    cache.access(tracer);
    this.elements.set(anchorKey, prop, { cache });
    return undefined;
  }

  /** Returns the anchors featuring a particular property */
  anchors(prop: string, value: Entity, tracer?: CacheTracer) {
    const valueKey = this.mapper.makeKey(value);
    /* Check if this is registered */
    const entry = this.anchorIndex.get(prop, valueKey);
    if (entry) {
      /* Register cache access, if applicable */
      if (tracer) {
        if (!entry.cache) entry.cache = new CacheHolder();
        entry.cache.access(tracer);
      }
      return entry.anchors;
    }
    /* Nothing to be done, if no tracer is provided */
    if (!tracer) return undefined;
    /* Register access */
    const cache = new CacheHolder();
    cache.access(tracer);
    this.anchorIndex.set(prop, valueKey, { cache });
    return undefined;
  }

  private mapper: DomainInterface;
  private models = new Map<string, RelatedDataModel>();
  private elements = new KeyPairMap<string, string, { value?: Entity; cache?: CacheHolder }>();
  private anchorIndex = new KeyPairMap<
    string,
    string,
    { anchors?: Entity[]; cache?: CacheHolder }
  >();
}
