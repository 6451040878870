import { EnrichedChatVM } from '@sqior/viewmodels/communication';
import styles from './chat-item.module.css';
import { getPrettyTimeOrDayString } from '@sqior/react/utils';
import ChatPicture from '../chat-picture/chat-picture';
import { FC, useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export interface ChatItemProps {
  item: EnrichedChatVM;
}

export function ChatItem(props: ChatItemProps) {
  const dispatcher = useContext(OperationContext);
  function onItemClicked() {
    if (props.item.select) dispatcher.start(props.item.select);
  }

  return (
    <div className={styles['container']} onClick={onItemClicked}>
      <div className={styles['icon']}>
        <ChatPicture type={props.item.type} url={props.item.typeUrl?.url} />
        {props.item.unread > 0 && <div className={styles['badge']}>{props.item.unread}</div>}
      </div>
      <div className={styles['title']}>{props.item.listName}</div>
      {typeof props.item.listSubtitle === 'string' && (
        <div className={styles['sub-title']}>
          <TextWithIcon text={props.item.listSubtitle} />
        </div>
      )}
      {props.item.listSubtitle instanceof Array && (
        <div className={styles['sub-title-container']}>
          <div>{props.item.listSubtitle[0]}</div>
          <div>{props.item.listSubtitle[1]}</div>
        </div>
      )}
      {props.item.timestamp && (
        <div className={styles['time']}>{getPrettyTimeOrDayString(props.item.timestamp)}</div>
      )}
    </div>
  );
}

export default ChatItem;

type Props = {
  text: string;
};

const TextWithIcon: FC<Props> = ({ text }) => {
  const imagePattern = /\{image\}/g;

  const hasImagePattern = imagePattern.test(text);

  if (hasImagePattern) {
    const cleanText = text.replace(imagePattern, '');
    const returnText = cleanText.length > 0 ? cleanText : 'Bild';

    return (
      <div className={styles['text-with-icon']}>
        <PhotoCameraIcon fontSize="small" />
        {returnText}
      </div>
    );
  }
  return <div>{text}</div>;
};
