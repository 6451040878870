import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { PushDevice, PushSystem } from '@sqior/js/push';
import { DeviceEntities } from './device-definitions';
import { ArraySource, ensureArray } from '@sqior/js/data';

/* Entity representing a push notification receiving device */

export type PushDeviceEntity = Entity & PushDevice;
export const PushDeviceEntityModel: EntityModel = {
  type: DeviceEntities.PushDevice,
  props: ['system', 'token'],
  keys: ['system', 'token'],
};
export function makePushDevice(
  deviceOrSystem: PushSystem | PushDevice,
  token?: string
): PushDeviceEntity {
  if (typeof deviceOrSystem === 'object' && 'token' in deviceOrSystem)
    return {
      entityType: DeviceEntities.PushDevice,
      system: deviceOrSystem.system,
      token: deviceOrSystem.token,
    };

  if (token !== undefined)
    return { entityType: DeviceEntities.PushDevice, system: deviceOrSystem, token: token };

  throw new Error(`makePushDevice() called with wrong parameters`);
}

/* Entity representing a list of push notification receiving devices */

export type PushDevicesEntity = Entity & { pushDevices: Entity[] };
export const PushDevicesEntityModel: EntityModel = {
  type: DeviceEntities.PushDevices,
  props: ['pushDevices'],
};
export function makePushDevices(devices: ArraySource<Entity>): PushDevicesEntity {
  return { entityType: DeviceEntities.PushDevices, pushDevices: ensureArray(devices) };
}
