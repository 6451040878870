import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-overview-procedure.module.css';
import {
  EmergencyClassificationVM,
  ORWorkflowOverviewDashboardProcedureData,
  ORWorkflowOverviewDashboardStage,
  ORWorkflowOverviewDashboardState,
} from '@sqior/viewmodels/orworkflow';
import { Cave, CountdownTimer, InterweaveExt, SvgIconGroup } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { addMinutes } from '@sqior/js/data';
import { ItemState } from '@sqior/viewmodels/visual';
import { motion } from 'framer-motion';
import AutoScroller, { IAutoScrollerSection } from '../auto-scroller/auto-scroller';
import SpecialtyView from '../specialty-view/specialty-view';
import { memo, useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { OperationSpec } from '@sqior/js/operation';
import { Button } from '@mui/material';

export type ORWorkflowOverviewProcedureProps =
  FactoryProps<ORWorkflowOverviewDashboardProcedureData>;

type Border =
  | {
      color: string | undefined;
      opacity: number | undefined;
    }
  | undefined;

const stepStyle = (stage: ORWorkflowOverviewDashboardStage) => {
  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return styles['step-anesthesia'];
    case ORWorkflowOverviewDashboardStage.Surgery:
      return styles['step-surgery'];
    case ORWorkflowOverviewDashboardStage.Normal:
      return styles['step-normal'];
  }
  return undefined;
};

const getStageClass = (stage: ORWorkflowOverviewDashboardStage): string | undefined => {
  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return classes(styles['stage-anesthesia'], styles['stage-highlighted']);
    case ORWorkflowOverviewDashboardStage.Surgery:
      return classes(styles['stage-surgery'], styles['stage-highlighted']);
    case ORWorkflowOverviewDashboardStage.Normal:
      return classes(styles['stage-normal'], styles['stage-highlighted']);
  }
  return undefined;
};

const getContainerClassName = (state: ORWorkflowOverviewDashboardState): string => {
  if (state === ORWorkflowOverviewDashboardState.Completed)
    return classes(styles['container'], styles['completed']);
  return classes(styles['container']);
};

const getEmergencyTextStyles = (emergencyClassification: EmergencyClassificationVM | undefined) => {
  if (!emergencyClassification || !emergencyClassification.opacity) return undefined;
  return {
    color: getEmergencyColorWithOpacity(emergencyClassification.opacity),
  };
};

const getBorderColor = (state: ORWorkflowOverviewDashboardState, border: Border) => {
  if (!border || !border.opacity) return { zIndex: 20 };
  return {
    border: `2px solid ${getEmergencyColorWithOpacity(border.opacity)}`,
    zIndex: 20,
  };
};

const getBorder = (border: Border, preliminary?: boolean) => {
  if (preliminary && !border) return undefined;
  if (!border || !border.opacity) return '2px solid #303859';
  return `2px solid ${getEmergencyColorWithOpacity(border.opacity)}`;
};

const getCountDownState = (
  stage: ORWorkflowOverviewDashboardStage,
  emergencyClassification: EmergencyClassificationVM | undefined
) => {
  if (emergencyClassification?.emergencyCountdown) return ItemState.Emergency;

  switch (stage) {
    case ORWorkflowOverviewDashboardStage.Anesthesia:
      return ItemState.Hot;
    case ORWorkflowOverviewDashboardStage.Surgery:
      return ItemState.Highlighted;
    case ORWorkflowOverviewDashboardStage.Normal:
      return ItemState.Highlighted;
  }
  return ItemState.Highlighted;
};

const getEmergencyColorWithOpacity = (opacity: undefined | number = 1): string => {
  return `rgba(${EMERGENCY_BASE_COLOR}, ${opacity})`;
};

const EMERGENCY_BASE_COLOR = '245, 93, 93';

export function ORWorkflowOverviewProcedure(props: ORWorkflowOverviewProcedureProps) {
  const {
    data: {
      name,
      sex,
      dob,
      countdown,
      duration = 0,
      stage,
      step,
      description,
      state,
      team,
      caveSeverity,
      specialty,
      countdownDuration,
      emergencyClassification,
      border,
      select,
      shortcutCommand,
      preSurgicalWard,
      postSurgicalWard,
      postSurgicalCareLevel,
    },
  } = props;

  const autoScroll = factoryGetProp<boolean>('autoScroll', props);
  const showChallenges = factoryGetProp<boolean | undefined>('showChallenges', props);
  const motionLayout = factoryGetProp<boolean>('motionLayout', props);
  const zoomLevel = factoryGetProp<number>('zoomLevel', props);
  const preliminary = factoryGetProp<boolean>('preliminary', props);
  const doppleBorder = factoryGetProp<boolean>('doppleBorder', props);
  const noBottomSpace = factoryGetProp<boolean>('noBottomSpace', props);

  const setTabAnimation = factoryGetProp<(tabAnimation: boolean) => void>('setTabAnimation', props);
  const dispatcher = useContext(OperationContext);

  const [sections, setSections] = useState<IAutoScrollerSection[]>([]);

  const handleSectionRef = (ref: HTMLDivElement | null, index: number) => {
    if (!ref) return;

    const foundSectionIndex = sections.findIndex((section) => section.index === index);

    if (foundSectionIndex === -1) {
      setSections([
        ...sections,
        {
          index,
          ref,
        },
      ]);
      return;
    }
    const existingSectionHeight = sections[foundSectionIndex].ref.clientHeight;
    const currentSectionHeight = ref.clientHeight;

    if (existingSectionHeight === currentSectionHeight) return;

    const newSections = [...sections];
    newSections[foundSectionIndex] = {
      index,
      ref,
    };
    setSections(newSections);
  };

  const handleNavigate = (select: OperationSpec) => {
    dispatcher.start(select);
  };

  const showSurgicalWarn = () => {
    const separator = <>&nbsp;&nbsp;&nbsp;|&nbsp;</>;
    const arrow = <>&rarr;</>;
    if (preSurgicalWard && postSurgicalWard) {
      if (preSurgicalWard === postSurgicalWard)
        return (
          <>
            {preSurgicalWard} {separator}
          </>
        );
      else
        return (
          <>
            {preSurgicalWard} {arrow} {postSurgicalWard} {separator}
          </>
        );
    }
    if (preSurgicalWard && !postSurgicalWard) {
      return (
        <>
          {preSurgicalWard}
          {separator}
        </>
      );
    }
    if (!preSurgicalWard && postSurgicalWard) {
      return (
        <>
          {postSurgicalWard}
          {separator}
        </>
      );
    }
    return null;
  };
  const handleButtonPressStart = () => {
    setTabAnimation && setTabAnimation(false);
  };
  const handleButtonPressEnd = () => {
    setTabAnimation && setTabAnimation(true);
  };

  const handleOnClick = (event: React.MouseEvent) => {
    // check if the right mouse button is pressed
    if (event.button === 2) return;
    handleNavigate(select);
  };

  const getBorderRight = () => {
    if (preliminary && !border) return undefined;
    if (preliminary && border) return getBorder(border);
    return undefined;
  };

  return (
    <motion.div
      onMouseDown={handleOnClick}
      className={getContainerClassName(state)}
      style={{
        zIndex: 20,
        borderTop: getBorder(border, preliminary),
        borderBottom: getBorder(border, preliminary),
        borderLeft: getBorder(border, preliminary),
        borderRight: getBorderRight(),
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: preliminary ? 4 : 0,
        borderBottomRightRadius: preliminary ? 4 : 0,
      }}
    >
      <AutoScroller
        sections={sections}
        autoScroll={autoScroll}
        motionLayout={motionLayout}
        zoomLevel={zoomLevel}
      >
        <div
          className={styles['content-container']}
          style={{
            paddingBottom: noBottomSpace && !getBorder(border, preliminary) ? 0 : undefined,
          }}
        >
          <div
            className={styles['auto-scroll-name-container']}
            ref={(ref) => handleSectionRef(ref, 0)}
            style={{
              paddingRight: doppleBorder ? 4 : 2,
            }}
          >
            <div className={styles['top-container']}>
              <div className={styles['top-left-container']}>
                <div className={classes(styles['name-container'], styles['brakeWord'])}>
                  <span
                    className={classes(
                      styles['brake-word'],
                      showChallenges &&
                        props.data.challenged &&
                        props.data.state !== ORWorkflowOverviewDashboardState.Completed
                        ? styles['challenged']
                        : undefined
                    )}
                  >
                    {emergencyClassification?.category && (
                      <span style={getEmergencyTextStyles(emergencyClassification)}>
                        {emergencyClassification?.category.toUpperCase()}
                      </span>
                    )}
                    {props.data.prioGroup && (
                      <span
                        className={
                          styles[
                            props.data.prioGroup.state === ItemState.Challenged
                              ? 'transfer-group-warning'
                              : 'transfer-group-normal'
                          ]
                        }
                      >
                        {emergencyClassification?.category && ' '}
                        {props.data.prioGroup.label}
                      </span>
                    )}{' '}
                    {name}{' '}
                    {caveSeverity && (
                      <span>
                        <Cave severity={caveSeverity} />
                      </span>
                    )}
                  </span>
                </div>

                <div className={styles['name-sub-container']}>
                  {(preSurgicalWard || postSurgicalWard) && (
                    <span className={styles['sex']}>{showSurgicalWarn()}</span>
                  )}
                  <InterweaveExt content={`${sex} *${dob}`} className={styles['sex']} />
                  {postSurgicalCareLevel && (
                    <InterweaveExt
                      content={`&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;${postSurgicalCareLevel}&nbsp;&nbsp;`}
                      className={styles['sex']}
                    />
                  )}

                  <SpecialtyView specialty={specialty} style={{ lineHeight: '140%' }} />
                </div>
                {preliminary && (
                  <div className={styles['preliminary-description']}>{description}</div>
                )}
              </div>
              <div className={styles['top-right-container']}>
                {countdown && (
                  <CountdownTimer
                    className={styles['countdown-timer']}
                    timeout={countdown === 'Infinity' ? 0 : countdown}
                    duration={countdownDuration ? countdownDuration : addMinutes(60)}
                    state={getCountDownState(stage, emergencyClassification)}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className={styles['auto-scroll-py']}
            style={{
              paddingRight: doppleBorder ? 2 : 2,
            }}
          >
            {!preliminary && (
              <div
                className={classes(
                  styles['description'],
                  duration >= (countdown ? 105 : 95)
                    ? styles['description-long']
                    : styles['description-short']
                )}
              >
                {shortcutCommand && !autoScroll ? (
                  <div style={{ margin: '10px 0' }}>
                    <Button
                      onMouseDown={handleButtonPressStart}
                      onMouseUp={handleButtonPressEnd}
                      onTouchStart={handleButtonPressStart}
                      onTouchEnd={handleButtonPressEnd}
                      fullWidth
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        dispatcher.start(shortcutCommand.select);
                      }}
                      variant="contained"
                      style={{
                        borderRadius: 30,
                        height: 30,
                      }}
                    >
                      <div className={styles['shortcut-command-text']}>{shortcutCommand.text}</div>
                    </Button>
                  </div>
                ) : (
                  <div className={styles['spacer']} />
                )}

                {description}
              </div>
            )}
          </div>

          {step && (
            <div className={styles['auto-scroll-py']}>
              <div className={classes(styles['step-container'], getStageClass(stage))}>
                <div className={classes(styles['step'], stepStyle(stage))}>{step}</div>
              </div>
            </div>
          )}

          {team && (
            <div
              className={styles['auto-scroll-py-pb']}
              style={{
                paddingBottom: noBottomSpace ? 0 : undefined,
              }}
            >
              <div
                className={classes(
                  styles['team-container'],
                  duration >= (countdown ? 95 : 85)
                    ? styles['description-long']
                    : styles['description-short']
                )}
              >
                <div>
                  <SvgIconGroup className={styles['team-icon']} />
                </div>
                <span className={styles['team']}>{team.join(', ')}</span>
              </div>
            </div>
          )}
        </div>
      </AutoScroller>
    </motion.div>
  );
}

export default memo(ORWorkflowOverviewProcedure);
