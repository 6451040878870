import { Entity, IdEntity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { LocationEntities, LocationInterfaces } from './location-definitions';

/* Interface representing either a concrete location or a location cluster */

export const LocationOrCluster: Interface = {
  type: LocationInterfaces.LocationOrCluster,
  requires: [LocationInterfaces.Key, LocationInterfaces.ClusterKey],
};

/* ID reprenting a location cluster */

export const LocationClusterIdModel: EntityModel = {
  type: LocationEntities.ClusterId,
  props: ['id'],
  keys: ['id'],
  extends: CoreEntities.Key,
  unclassified: true,
};
export function makeLocationClusterId(id: string): IdEntity {
  return { entityType: LocationEntities.ClusterId, id: id };
}

/* Type containing multiple clusters */

export type LocationClusterEntity = Entity & { cluster: Entity };
export type LocationClustersEntity = Entity & { locationclusters: Entity[] };

/* Location cluster function */

export enum LocationClusterFunction {
  AllOperatingRooms = 'AllOperatingRooms', // contains all operating rooms, there shall only be one cluster of this fuction
  ICU = 'ICU', // Intensive care unit
  ER = 'ER', // Emergency Room unit
  SDS = 'SDS', // Same Day Surgery unit
  OperatingRoomWard = 'OperatingRoomWard', // A ward of operating rooms, e.g. AOZ or ZOZ or ORs of level 2
  OperatingRoomCluster = 'OperatingRoomCluster', // Any other partition / segmentation of ORs, e.g. by specialty
  ORAssociatedRooms = 'ORAssociatedRooms', // specific associated rooms belonging to operating rooms, e.g. specific Holdings / AWR's
  PatientWard = 'PatientWard', // Ward for patients e.g. Station 1 or Ward A-2
  PatientWardCluster = 'PatientWardCluster', // Cluster of patient wards, e.g. all wards in the west wing, all wards related to neurosurgery
}

export type LocationClusterFunctionEntity = Entity & { function: string };
export const LocationClusterFunctionModel: EntityModel = {
  type: LocationEntities.ClusterFunction,
  props: ['function'],
  keys: ['function'],
  unclassified: true,
};
export function makeLocationClusterFunction(func: string): LocationClusterFunctionEntity {
  return { entityType: LocationEntities.ClusterFunction, function: func };
}
