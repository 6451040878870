import { SelectionEntities, SelectionMenuType } from '@sqior/viewmodels/input';
import { EntityModel, Interface, TextEntity } from '@sqior/js/meta';
import { InputEntities, InputInterfaces } from './input-definitions';
import { Entity } from '@sqior/js/entity';
import { VisualInterfaces } from '@sqior/plugins/visual';

/* Entity representing a selection menu */

export const SelectionMenuModel: EntityModel = {
  type: SelectionMenuType,
  props: ['title', 'selection', 'multiSelect', 'operation'],
};

/* Entity specifying the expected selection text to match against for an entity */

export const SelectionMatchModel: EntityModel = {
  type: InputEntities.SelectionMatch,
  props: ['text'],
};
export function makeSelectionMatch(text: string): TextEntity {
  return { entityType: InputEntities.SelectionMatch, text: text };
}

/* Entity specifying the expected selection text to display for an entity */

export const SelectionDisplayModel: EntityModel = {
  type: InputEntities.SelectionDisplay,
  props: ['text'],
};
export function makeSelectionDisplay(text: string): TextEntity {
  return { entityType: InputEntities.SelectionDisplay, text: text };
}

/* Interface providing the entity to use for selection feedback */

export const SelectionFeedbackModel: Interface = {
  type: InputInterfaces.SelectionFeedback,
};

/* Base class for selection entities that are not embedded into a selection page */

export const SelectionControlBaseModel: EntityModel = {
  type: InputEntities.SelectionControlBase,
  props: ['operation'],
};

/* Interface providing a UI control entity representing the specified input */

export const SelectionControlModel: Interface = {
  type: InputInterfaces.SelectionControl,
  requires: VisualInterfaces.ViewModel,
};

/* Interface providing an optional header representing the specified input */

export const SelectionHeaderModel: Interface = {
  type: InputInterfaces.SelectionHeader,
  requires: VisualInterfaces.ViewModel,
};

/* Entity representing an input option from multiple text options */

export type SelectionOption = { text: Entity; value: Entity };
export type SelectionOptionsEntity = Entity & { options: SelectionOption[] };
export const SelectionOptionsModel: EntityModel = {
  type: SelectionEntities.Options,
  props: ['options'],
};
export function makeSelectionOptions(options: SelectionOption[]): SelectionOptionsEntity {
  return { entityType: SelectionEntities.Options, options: options };
}

export const SelectionFromOptionsModel: EntityModel = {
  type: SelectionEntities.OptionsViewModel,
  props: ['options'],
};

/* Entity representing a numerical input option */

export const NumericalInputModel: EntityModel = {
  type: SelectionEntities.Numerical,
  props: ['default', 'min', 'max', 'defaultTitle', 'unit', 'numericalResult'],
};

/** Entity representing a numerical result */

export const NumericalResultModel: EntityModel = {
  type: SelectionEntities.NumericalResult,
  props: ['number'],
};

/** Entity representing a text selection */

export const TextSelectionModel: EntityModel = { type: SelectionEntities.Text, props: ['text'] };

/* Entity representing a QR Scanner input option */

export const QRScannerInputModel: EntityModel = {
  type: SelectionEntities.QRScanner,
  props: ['text'],
};
export const QRScannerResultModel: EntityModel = {
  type: SelectionEntities.QRScannerResult,
  props: ['qrcode'],
};
