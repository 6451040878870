import { Bytes, LogicalTimestamp, Value, ValueObject } from '@sqior/js/data';
import { IdOperation } from './id-operation';
import { OperationType } from './operation';

export type DeleteResult = LogicalTimestamp;

export class DeleteOperation extends IdOperation {
  constructor(id: string, value?: Value) {
    super(OperationType.Delete, id);
    this.requestData = value;
  }

  override toJSON() {
    const obj: ValueObject = { id: this.id };
    if (this.requestData) obj['data'] = this.requestData;
    return JSON.stringify(obj);
  }

  static fromJSON(json: Value) {
    if (typeof json === 'string' && json.length) {
      if (json[0] === '{') {
        const obj = JSON.parse(json);
        if (typeof obj === 'object' && !(obj instanceof Bytes) && !(obj instanceof Array)) {
          const id = obj['id'];
          if (id && typeof id === 'string') return new DeleteOperation(id, obj['data']);
        }
      } else return new DeleteOperation(json as string);
    } else if (typeof json === 'object' && !(json instanceof Bytes) && !(json instanceof Array)) {
      const id = json['id'];
      if (id && typeof id === 'string') return new DeleteOperation(id, json['data']);
    }

    throw new Error('Provided value cannot be deserialized to DeleteOperation');
  }

  requestData?: Value;
}
