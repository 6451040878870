import { useMediaQuery } from '@mui/material';
import ORWorkflowOverviewDashboardTablet from '../orworkflow-overview-dashboard-tablet/orworkflow-overview-dashboard-tablet';
import ORWorkflowOverviewDashboard, {
  ORWorkflowOverviewDashboardProps,
} from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { isSqiorMobileClient } from '@sqior/react/uiapp';

export function ORWorkflowOverviewDashboardResponsive(props: ORWorkflowOverviewDashboardProps) {
  const isTablet = useMediaQuery('(max-width: 1200px)');
  const isAppNative = isSqiorMobileClient();

  if (isTablet || isAppNative) {
    return <ORWorkflowOverviewDashboardTablet {...props} />;
  }
  return <ORWorkflowOverviewDashboard {...props} />;
}

export default ORWorkflowOverviewDashboardResponsive;
