import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useIsMobile } from '@sqior/react/hooks';
import { MessengerTab } from '@sqior/viewmodels/app';

interface UIGlobalStateType {
  messengerOpen: boolean;
  previewSrc?: string;
  bmShowSpi: boolean;
  bmShowInsurance: boolean;
  bmShowSpeciality: boolean;
  bmRightDrawerOpen: boolean;
  messengerTab?: MessengerTab;
  // Add other keys here as needed
}

interface UIGlobalStateContextProps {
  UIGlobalState: UIGlobalStateType;
  setUIGlobalState: Dispatch<SetStateAction<UIGlobalStateType>>;
}

const UIGlobalStateContext = createContext<UIGlobalStateContextProps | undefined>(undefined);

const LOCAL_STORAGE_KEY = 'UIGlobalState';

const INITIAL_STATE: UIGlobalStateType = {
  messengerOpen: true,
  bmShowSpi: false,
  bmShowInsurance: false,
  bmShowSpeciality: true,
  bmRightDrawerOpen: true,
};

const getInitialUIGlobalState = (): UIGlobalStateType => {
  try {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);

    return storedState ? JSON.parse(storedState) : INITIAL_STATE;
  } catch (e) {
    console.error('Error getting initial state from local storage::UIGlobalState', e);
    return INITIAL_STATE;
  }
};

interface UIGlobalStateProviderParams {
  children: ReactNode;
}

export const UIGlobalStateProvider = ({ children }: UIGlobalStateProviderParams) => {
  const isMobile = useIsMobile();
  const [UIGlobalState, setUIGlobalState] = useState<UIGlobalStateType>(getInitialUIGlobalState());

  const updateGlobalStateWithChecks = (stateUpdater: SetStateAction<UIGlobalStateType>) => {
    setUIGlobalState((prevState) => {
      // Determine the new state
      let newState = typeof stateUpdater === 'function' ? stateUpdater(prevState) : stateUpdater;

      if (isMobile) {
        newState = { ...newState, messengerOpen: true };
      }

      // persist the new state
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));

      return newState;
    });
  };

  return (
    <UIGlobalStateContext.Provider
      value={{ UIGlobalState, setUIGlobalState: updateGlobalStateWithChecks }}
    >
      {children}
    </UIGlobalStateContext.Provider>
  );
};

export const useUIGlobalState = () => {
  const context = useContext(UIGlobalStateContext);
  if (!context) {
    throw new Error('useUIGlobalState must be used within a UIGlobalStateProvider');
  }
  return context;
};
