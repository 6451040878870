import styles from './react-uidemo.module.css';

/* eslint-disable-next-line */
export interface ReactUidemoProps {}

export function ReactUidemo(props: ReactUidemoProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUidemo!</h1>
    </div>
  );
}

export default ReactUidemo;
