import { FactoryProps, factoryGetProp } from '@sqior/react/factory';
import Thread, { ThreadPageProps } from '../thread/thread';
import {
  ClosablePage,
  ContainerWithShadow,
  ImageInfo,
  useTextResources,
} from '@sqior/react/uibase';
import { PageHeader } from '@sqior/react/uiadvanced';

import styles from './standard-info-thread-page.module.css';
import { classes } from '@sqior/react/utils';
import { ReadConfirmationTypes } from '@sqior/viewmodels/communication';

export function StandardInfoThreadPage(props: FactoryProps<ThreadPageProps>) {
  const textRes = useTextResources();
  return (
    <ClosablePage
      className={classes(factoryGetProp<string>('className', props), styles['thread-override'])}
      onClose={() => {
        props.onClose?.();
      }}
    >
      <PageHeader title={textRes.get('information_messages')} icon={<ImageInfo />} />
      <ContainerWithShadow>
        <Thread
          stateBasePath={props.data.data.stateBasePath}
          autoMarkReadAs={ReadConfirmationTypes.Explicit}
        />
      </ContainerWithShadow>
    </ClosablePage>
  );
}
