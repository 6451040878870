import { useMediaQuery } from '@mui/material';

/* eslint-disable-next-line */
export interface UseIsTabletProps {}

const TABLET_BRAKE_POINT = 1950;

export function useIsTablet(props?: UseIsTabletProps) {
  const isTablet = useMediaQuery(`(max-width: ${TABLET_BRAKE_POINT}px)`);
  return isTablet;
}

export default useIsTablet;
