import { makeImmutable } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { LanguageEntities } from '@sqior/plugins/language';
import { LocationEntities, makeLocationClusterFunction } from '@sqior/plugins/location';
import { TimeEntities } from '@sqior/plugins/time';
import { VisualEntities } from '@sqior/plugins/visual';
import { OnlineStatus } from '@sqior/viewmodels/communication';
import { PermanentDeviceSettingsEditMode, UserSettingsVM } from '@sqior/viewmodels/user';

export enum UserEntities {
  EMail = 'EMailAddress',
  EmployeeId = 'EmployeeId', // The empoyee id, mainly used for identifying the user via an QR code
  OidcId = 'OidcId', // The OIDC identifier (subject)
  AccountName = 'AccountName', // The login name, might be the same as email, but can also be different. Depends on the underlaying Identity Management System
  Role = 'Role',
  Roles = 'Roles',
  RoleUser = 'RoleUser',
  ActiveLocationRoles = 'ActiveLocationRoles', // Roles related to the current active locations of the user
  ActiveLocations = 'ActiveLocations',
  LocationRole = 'LocationRole',
  SpecialtyLocationRole = 'SpecialtyLocationRole',
  OnlineStatus = 'UserOnlineStatus', // Represents the user's online status
  Addresses = 'Addresses',
  AddressInfo = 'AddressInfo',
  AddressesInfo = 'AddressesInfo',
  OnBehalf = 'OnBehalfOf',
  CommunicationRoles = 'CommunicationRoles', // Roles used for communication optionally with group information
  RealUser = 'RealUser',
  RealUsers = 'RealUsers', // Representing real users with meta information behind an address e.g. behind a role/functionrole
  RealUserMapOptions = 'RealUserMapOptions', // Options specifying how to map into RealUser
  TestUser = 'TestUser', // User key of a user for testing purposes
  GroupId = 'UserGroupId', // Basic ID of a user group
  Groups = 'UserGroups', // List of user groups
  RolesOptions = 'RolesOptions', // List of alternative role sets
  NameBackup = 'UserNameBackup', // Combination of a user identification (e.g. account name) and a name which is used for presentation in case that the user cannot be resolved
  Users = 'Users', // List of users
  SearchUserCluster = 'SearchUserCluster', // Interface to represent and derive the UserSearch instance for a search query
  Kiosk = 'KioskUser', // Technical user for kios displays
  NullAddress = 'NullAddress', // Address to send obsolete information to
  UserClusterFunction = 'UserClusterFunction', // Combines a user, cluster and function for e.g. lookups
  CalledUsers = 'CalledUsers', // List of called users with additional information about the initiator and timestamp of the call
  UserCallInitiator = 'UserCallInitiator', // Extracts the initiator of a user call from a list of called users
  Settings = 'UserSettings',
  PINCode = 'PINCode', // PIN code in non-readable format
  TelemetryAddress = 'TelemetryAddress', // Address to use in telemetry
  WardAlternativeUser = 'WardAlternativeUser',
}

export enum UserInterfaces {
  Key = 'UserKey',
  Address = 'AddressKey',
  Sender = 'Sender',
  ActualSender = 'ActualSender',
  ReplyToAddress = 'ReplyToAddress', // Each Sender is mapped to an ReplyToAddress which represents a valid address to reply - mostly Role if available or the individum
  AccessibleAddresses = 'AccessibleAddresses', // All addresses a user has access to (UserKey, Role, LocationRole, Chats, ...)
  BaseRole = 'BaseRole', // The basic role of a more complex role e.g. 'ORNurse' is the base role of the location role 'ORNurse of OR #12'
  AssociatedRole = 'AssociatedRole',
  RoleIfAvailable = 'RoleifAvailable',
  EffectiveRole = 'EffectiveRole', // A concrete role to use, e.g. a plain role, a location role or a specialty location role
  ActiveLocationLabel = 'ActiveLocationLabel',
  AvailableRecoveryLocations = 'AvailableRecoveryLocations',
  UserCommandTemplates = 'UserCommandTemplates',
  HasRole = 'HasRole', // Checks if a user possesses the specified role
  AvailableRoles = 'AvailableRoles', // All roles that a user can potentially have
  LastConnect = 'UserLastConnect', // Last Connect Timestamp of user
  PermanentLocation = 'PermanentLocation', // Location that is permanently attached to a role
  PersonalizedDevice = 'PersonalizedDevice', // Checks if the user has a personalized or a shared device
  EffectiveRoles = 'EffectiveRoles', // All effective roles of a user including plain (non-location specific) roles, active location roles and roles permanently associated with a location
  AvailableLocations = 'AvailableLocations', // Locations for a user or role that are available for selection
  AvailableLocationsForUser = 'UserAvailableLocations', // Locations for a role that are available for selection for a specific user
  AllowedLocations = 'AllowedLocations', // Interface to get the allowed locations
  ByRoleText = 'ByUserRoleText', // Text template representing a text module referring to a role or user
  EffectiveLocation = 'EffectiveLocation', // Location that is effectively contributing to the user roles
  EffectiveLocations = 'EffectiveLocations', // All locations that are effectively contributing to the user roles
  GroupKey = 'UserGroupKey', // Key of a user group
  ProfilePicture = 'UserProfilePictureType', // Interface representing a profile picture of a user
  SearchUsers = 'SearchUsers', // Searching for users matching the specified source entity
  AddressSearchRolesToConsider = 'AddressSearchRolesToConsider', // Interface to inject roles that shall be considered in AddressSearch
  ChangeAvailable = 'UserChangeAvailable', // Interface for checking if the user change functionality shall be presented to a user
  FixedPushDevicesAddress = 'FixedPushDevicesAddress', // Interface for checking if an address is bound to selected push devices only
  UserAccessibleEntity = 'Access.UserAccesEntity',
  HasUserAccess = 'Access.HasUserAccess',
  DeviceDynamicRoles = 'DeviceDynamicRoles', // Returns the roles that can be dynamically assigned with a device
  EffectiveDeviceDynamicRoles = 'EffectiveDeviceDynamicRoles', // Returns the effective device dynamic roles
  EMail = 'GetEMailAddress', // Returns the associated E-mail address
  ServerAdministrator = 'ServerAdministrator', // Right to administer the server
  MaxSessionDuration = 'MaxUserSessionDuration', // Maximum allowed duration of a user session
  ActiveLocations = 'UserCurrentActiveLocations', // Active locations of a user
  UsersAsSubjects = 'UsersAsSubjects', // Converts a set of users to a set of subject
  PINAvailable = 'PINAvailable', // Checks if the PIN mechanism shall be made available
  SelectableRolesOptions = 'SelectableRolesOptions', // List of selectable alternative role sets, typically a sub-set of UserEntities.RolesOptions
  PermanentDeviceSettingsEditMode = 'PermanentDeviceSettingsEditMode', // Checks whether the logged in user is allowed to administrate the fixed device settings (i.e. settings are not applied to himself)
  AvailableDeviceDefaultLocations = 'AvailableDeviceDefaultLocations', // Available locations which can be used to set device to
  ProfilePictureURLTemplate = 'ProfilePictureURLTemplate', // Returns a URL template for access to the profile picture of a user
  RoleText = 'UserRolesText', // Returns the role(s) of a user as text
}

export const UserContextProperty = 'user';
export const RoleContextProperty = 'role';
export const RealUserContextProperty = 'realUserOption';

/* Entity of multiple users */

export type UsersEntity = Entity & { users: Entity[] };

export const UsersAsSubjectsModel: Interface = {
  type: UserInterfaces.UsersAsSubjects,
  requires: CoreEntities.Subjects,
};

export const LastConnectModel: Interface = {
  type: UserInterfaces.LastConnect,
  requires: [TimeEntities.Timestamp],
};

export type OnlineStatusEntity = Entity & {
  onlineStatus: OnlineStatus;
};
export function makeOnlineStatus(onlineStatus: OnlineStatus): OnlineStatusEntity {
  return { entityType: UserEntities.OnlineStatus, onlineStatus: onlineStatus };
}
export const OnlineStatusModel: EntityModel = {
  type: UserEntities.OnlineStatus,
  props: ['onlineStatus'],
};

/* Entity representing the combination of a user identification and a name which is used as back-up for presentation in case that user
   cannot be resolved because e.g. there was no log-in action so far */

export type UserNameBackup = EntityHeader & { user: Entity; name: Entity };
export const UserNameBackupModel: EntityModel = {
  type: UserEntities.NameBackup,
  props: ['user', 'name'],
  keys: ['user'],
};
export function makeUserNameBackup(user: Entity, name: Entity): UserNameBackup {
  return { entityType: UserNameBackupModel.type, user: user, name: name };
}

/* Interface to determine if a user has a personalized communication device */

export const PersonalizedDeviceModel: Interface = {
  type: UserInterfaces.PersonalizedDevice,
  requires: [UserInterfaces.Key],
};

/** Interface returning a text module referring to a role or user */

export const ByUserRoleModel: Interface = {
  type: UserInterfaces.ByRoleText,
  requires: [CoreEntities.TextTemplate],
};

/* Interface representing a profile picture */

export const UserProfilePictureModel: Interface = { type: UserInterfaces.ProfilePicture };

/* Interface performing a user search */

export const UserSearchModel: Interface = {
  type: UserInterfaces.SearchUsers,
  requires: UserEntities.Addresses,
};

/* Entity to represent and derive the UserSearch instance for a search */
export type SearchUserCluster = EntityHeader & { cluster: string };
export const SearchUserClusterModel: EntityModel = {
  type: UserEntities.SearchUserCluster,
  props: ['cluster'],
};
export function makeSearchUserCluster(cluster: string): SearchUserCluster {
  return { entityType: UserEntities.SearchUserCluster, cluster: cluster };
}

/* Interface provide roles to search */

export const AddressSearchRolesToConsiderModel: Interface = {
  type: UserInterfaces.AddressSearchRolesToConsider,
  requires: UserEntities.Roles,
};

/* Interface specifying whether the user change functionality is supposed to be availble */

export const UserChangeModel: Interface = { type: UserInterfaces.ChangeAvailable };

/** Interface for checking if an address is bound to selected push devices only */

export const FixedPushDevicesAddressModel: Interface = {
  type: UserInterfaces.FixedPushDevicesAddress,
};

/** Interface for determining the roles that can dynamically be assigned by using a device */

export const DeviceDynamicRolesModel: Interface = {
  type: UserInterfaces.DeviceDynamicRoles,
  requires: UserEntities.Roles,
};

/** Interface for determining the effective device dynamic roles */

export const EffectiveDeviceDynamicRolesModel: Interface = {
  type: UserInterfaces.EffectiveDeviceDynamicRoles,
  requires: UserEntities.Roles,
};

/* Interface for checking if the user has the right to administer the server */

export const ServerAdministratorModel: Interface = { type: UserInterfaces.ServerAdministrator };

/** Null address used to ignore information */

export const NullAddressModel: EntityModel = { type: UserEntities.NullAddress, props: [] };
export const NullAddress = makeImmutable({ entityType: NullAddressModel.type });
export type UserLocationClusterFunction = EntityHeader & {
  user?: Entity;
  cluster?: Entity; // Super Cluster
  function: Entity;
};

/** User location cluster function model */

export const UserLocationClusterFunctionModel: EntityModel = {
  type: UserEntities.UserClusterFunction,
  props: ['user', 'function', 'cluster'],
};
export function makeUserLocationClusterFunction(
  func: string | Entity,
  opt?: {
    user?: Entity;
    cluster?: Entity; // Super Cluster
  }
): UserLocationClusterFunction {
  const res: UserLocationClusterFunction = {
    entityType: UserEntities.UserClusterFunction,
    function: typeof func === 'string' ? makeLocationClusterFunction(func) : func,
  };
  if (opt?.user) res.user = opt?.user;
  if (opt?.cluster) res.cluster = opt?.cluster;
  return res;
}

/** List of called users including initiator and timestamps */

export type CalledUserInfo = { user: Entity; initiator: Entity; timestamp: Entity };
export type CalledUsersEntity = EntityHeader & { users: CalledUserInfo[] };
export const CalledUsersModel: EntityModel = { type: UserEntities.CalledUsers, props: ['users'] };
export function makeCalledUsers(users: CalledUserInfo[]): CalledUsersEntity {
  return { entityType: CalledUsersModel.type, users };
}

/** Function extracting the initiator of a called user */

export type UserCallInitiatorEntity = EntityHeader & { users: Entity; called: Entity };
export const UserCallInitiatorModel: EntityModel = {
  type: UserEntities.UserCallInitiator,
  props: ['users', 'called'],
};
export function UserCallInitiator(users: Entity, called: Entity): UserCallInitiatorEntity {
  return { entityType: UserCallInitiatorModel.type, users, called };
}

export type UserSettingsEntity = EntityHeader & UserSettingsVM;
export const UserSettingsModel: EntityModel = {
  type: UserEntities.Settings,
  props: ['disableSlogans'],
};
export function makeUserSettings(): UserSettingsEntity {
  return { entityType: UserEntities.Settings };
}

// Specifies whether the logged in user only administrates the fixed device settings (i.e. settings are not applied to himself)
export const PermanentDeviceSettingsEditModeModel: EntityModel = {
  type: UserInterfaces.PermanentDeviceSettingsEditMode,
  props: ['mode'],
};
export type PermanentDeviceSettingsEditModeEntity = EntityHeader & {
  mode: PermanentDeviceSettingsEditMode;
};
export function makePermanentDeviceSettingsEditMode(
  mode: PermanentDeviceSettingsEditMode
): PermanentDeviceSettingsEditModeEntity {
  return { entityType: UserInterfaces.PermanentDeviceSettingsEditMode, mode };
}

// Specifies the available default locations for a device
export const AvailableDeviceDefaultLocationsModel: Interface = {
  type: UserInterfaces.AvailableDeviceDefaultLocations,
  requires: LocationEntities.Locations,
};

/** Profile picture URL template */
export const ProfilePictureURLTemplateModel: Interface = {
  type: UserInterfaces.ProfilePictureURLTemplate,
  requires: VisualEntities.URLTemplate,
};

/** User roles text */
export const UserRolesTextModel: Interface = {
  type: UserInterfaces.RoleText,
  requires: LanguageEntities.AnonymizedText,
};

/** Telemetry address */
export type TelemetryAddress = EntityHeader & { role: Entity; sub?: Entity };
export const TelemetryAddressModel: EntityModel = {
  type: UserEntities.TelemetryAddress,
  props: ['role', 'sub'],
};
export function makeTelemetryAddress(role: Entity, sub?: Entity): TelemetryAddress {
  const res: TelemetryAddress = { entityType: TelemetryAddressModel.type, role };
  if (sub) res.sub = sub;
  return res;
}
