import { LogLevel, LogRetainer, LogTextData, Logger, RetainedLogData } from '@sqior/js/log';

export const RetainedLogs = 'retained-logs';

/** Log retainer that stores and restores the logs from local or session storage, this done in order to get access to logs of previous sessions that crashed */
export class StorageLogRetainer extends LogRetainer {
  constructor(capacity = 100) {
    /* Select the storage to use */
    const storage = window.localStorage ? window.localStorage : window.sessionStorage;
    /* Load pot. retained logs from the previous run */
    const logItem = storage.getItem(RetainedLogs);
    let retainedLogs: RetainedLogData[] = [];
    if (logItem)
      try {
        retainedLogs = JSON.parse(logItem);
      } catch (e) {
        console.log('Exception when loading retained logs - discarding: ' + Logger.exception(e));
      }
    /* Init */
    super(capacity, retainedLogs);
    this.storage = storage;
    /* Log that logs have been restored, if applicable */
    if (retainedLogs.length)
      this.log({
        level: LogLevel.Info,
        content: [
          'Logs from previous run(s) have been restored, new logs starting after this:',
          retainedLogs.length,
        ],
      });
  }

  /** Stores the logs */
  private store() {
    this.storage.setItem(RetainedLogs, JSON.stringify(this.logs));
  }

  /** Adds a log entry */
  override logText(data: LogTextData) {
    super.logText(data);
    this.store();
  }

  /** Purges all retained log entires up to (and including) a certain sequence number */
  purge(sequenceNumber: number) {
    super.purge(sequenceNumber);
    this.store();
  }

  private storage: Storage;
}
