import { FactoryProps } from '@sqior/react/factory';
import styles from './qrscanner-input.module.css';
import { QRScannerInput as QRScannerInputType, SelectionEntities } from '@sqior/viewmodels/input';
import { SelectionAreaProps } from '../selection-area';
import { FacingMode, QRScannerControl } from '@sqior/react/qrcode';

/* eslint-disable-next-line */
export type QRScannerInputProps = FactoryProps<QRScannerInputType> & SelectionAreaProps;

export function QRScannerInput(props: QRScannerInputProps) {
  function onQRCode(code: string) {
    props.onSelection({ entity: { entityType: SelectionEntities.QRScannerResult, qrcode: code } });
  }

  return (
    <div className={styles['container']}>
      <QRScannerControl detected={onQRCode} facing={FacingMode.Environment} />
    </div>
  );
}

export default QRScannerInput;
