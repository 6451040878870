import { classes } from '@sqior/react/utils';
import styles from './image-info.module.css';
import { ReactComponent as InfoIcon } from './image-info.svg';

/* eslint-disable-next-line */
export interface ImageInfoProps {
  className?: string;
}

export function ImageInfo(props: ImageInfoProps) {
  return <InfoIcon className={classes(styles['icon'], props.className)} viewBox="0 0 48 48" />;
}

export default ImageInfo;
