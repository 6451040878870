import styles from './specialty-view.module.css';
import { classes } from '@sqior/react/utils';
import React, { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { SpecialtyVM } from '@sqior/viewmodels/patient';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3a506b',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3a506b',
  },
}));

const getSpecialtyContainerStyle = (specialtyColor: string | undefined) => {
  return classes(styles['specialty-container'], styles[`specialty-${specialtyColor}`]);
};

export interface SpecialtyViewProps {
  specialty?: SpecialtyVM;
  style?: CSSProperties;
  className?: string;
  fontSize?: number;
  fullWidth?: boolean;
  uppercase?: boolean;
}

export const SpecialtyView: FC<SpecialtyViewProps> = ({
  specialty,
  style,
  className,
  fontSize,
  fullWidth,
  uppercase,
}) => {
  if (!specialty) return null;

  return (
    <BootstrapTooltip title={specialty.specialtyLong}>
      <div
        className={classes(getSpecialtyContainerStyle(specialty.specialtyColor), className)}
        style={{
          fontSize: fontSize || 9,
          width: fullWidth ? '100%' : undefined,
          padding: '2px 4px',
          borderRadius: 2,
          ...style,
        }}
      >
        <div
          className={styles['specialty-content']}
          style={{
            textTransform: uppercase ? 'uppercase' : undefined,
          }}
        >
          {specialty.specialty}
        </div>
      </div>
    </BootstrapTooltip>
  );
};

export default SpecialtyView;
