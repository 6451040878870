import styles from './card-layout.module.css';

export interface CardLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export function CardLayout({ children, ...rest }: CardLayoutProps) {
  return (
    <div className={styles['container']} {...rest}>
      {children}
    </div>
  );
}

export default CardLayout;
