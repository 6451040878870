import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import {
  AddChecklistSectionComment,
  ChecklistConfirmRole,
  ChecklistSetData,
  ChecklistViewModel,
  ConfirmChecklistSection,
  SetChecklistItem,
  SetChecklistItemComment,
  SetChecklistItemPhotos,
  SetChecklistSectionPhotos,
  UndoChecklistSection,
  UndoChecklistSectionComment,
} from '@sqior/viewmodels/checklist';
import { useContext, useRef } from 'react';
import ChecklistSectionControl from '../checklist-section-control/checklist-section-control';
import styles from './checklist-control.module.css';
import { ConfirmCredentials } from '@sqior/viewmodels/user';
import { HighlightButton, SqiorFileUpload } from '@sqior/react/uibase';
import { useUIGlobalState } from '@sqior/react/state';
import { OperationSpec } from '@sqior/js/operation';
import { sqiorOpenExternal } from '@sqior/js/url';
import { ReactComponent as IconDownload } from './download.svg';
import { ConfigContext } from '@sqior/react/utils';

export type ChecklistControlProps = FactoryProps<ChecklistViewModel>;

/** Transforms the selection to an operation */
function routeSelection(
  props: ChecklistControlProps,
  section: number,
  paragraph: number | undefined,
  item: number | undefined,
  data: ChecklistSetData
): OperationSpec | undefined {
  /* Item checks */
  if ('check' in data && paragraph !== undefined && item !== undefined)
    return SetChecklistItem(
      props.data.ref,
      section,
      paragraph,
      item,
      data.option,
      data.check,
      data.value
    );
  /* Photos in item or section */
  if ('photos' in data)
    if (paragraph !== undefined && item !== undefined)
      return SetChecklistItemPhotos(props.data.ref, section, paragraph, item, data.photos);
    else if (paragraph === undefined && item === undefined)
      return SetChecklistSectionPhotos(props.data.ref, section, data.photos);
  /* Comments */
  if ('comment' in data)
    if (paragraph !== undefined && item !== undefined)
      return SetChecklistItemComment(props.data.ref, section, paragraph, item, data.comment);
    else if (paragraph === undefined && item === undefined)
      return AddChecklistSectionComment(props.data.ref, section, data.comment);
  return undefined;
}

export function ChecklistControl(props: ChecklistControlProps) {
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const dispatcher = useContext(OperationContext);
  const configContext = useContext(ConfigContext);
  const downloadLink = useRef<HTMLAnchorElement>(null);
  return (
    <div className={styles['container']}>
      {props.data.title && <div className={styles['title']}>{props.data.title}</div>}
      {props.data.sections.map((section, index) => (
        <ChecklistSectionControl
          data={section}
          key={index}
          select={(paragraph, item, data) => {
            const opSpec = routeSelection(props, index, paragraph, item, data);
            if (opSpec) dispatcher.start(opSpec);
          }}
          confirm={(role: ChecklistConfirmRole, credentials?: ConfirmCredentials) => {
            dispatcher.start(ConfirmChecklistSection(props.data.ref, index, role, credentials));
          }}
          undo={(prep) => {
            dispatcher.start(UndoChecklistSection(props.data.ref, index, prep));
          }}
          undoComment={(text) => {
            dispatcher.start(UndoChecklistSectionComment(props.data.ref, index, text));
          }}
        />
      ))}
      {props.data.documentURL && (
        <a
          ref={downloadLink}
          href={sqiorOpenExternal(configContext.getEndpoint(props.data.documentURL.url).toString())}
          target="_blank"
          rel="noopener noreferrer"
          className={styles['download-link']}
        >
          {' '}
        </a>
      )}
      {props.data.documentURL && (
        <div className={styles['download-container']}>
          {' '}
          <HighlightButton
            secondary={true}
            onClick={() => {
              downloadLink.current?.click();
            }}
            additionalClassName={styles['download-button']}
          >
            <span>Dokument herunterladen</span>
            <IconDownload className={styles['download']} />
          </HighlightButton>
        </div>
      )}
      {UIGlobalState.previewSrc && (
        <SqiorFileUpload.Preview
          src={UIGlobalState.previewSrc}
          setSrc={(newValue) => {
            setUIGlobalState((prev) => ({ ...prev, previewSrc: newValue }));
          }}
        />
      )}
    </div>
  );
}

export default ChecklistControl;
