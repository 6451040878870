import styles from './vertical-line.module.css';

/* eslint-disable-next-line */
export interface VerticalLineProps {
  className: string;
}

export function VerticalLine(props: VerticalLineProps) {
  return <div className={`${props.className} ${styles['line']}`} />;
}

export default VerticalLine;
