import styles from './procedure-view.module.css';
import { FC } from 'react';
import { ORWorkflowOverviewDashboardProcedureData } from '@sqior/viewmodels/orworkflow';
import { ORWorkflowOverviewProcedure } from '../orworkflow-overview-procedure/orworkflow-overview-procedure';

export interface ProcedureViewProps {
  data: ORWorkflowOverviewDashboardProcedureData;
  minWidth?: number;
  maxWidth?: number;
  borderRight?: boolean;
  noBottomSpace?: boolean;
}

export const ProcedureView: FC<ProcedureViewProps> = ({
  data,
  borderRight,
  minWidth,
  maxWidth,
  noBottomSpace,
}) => {
  return (
    <div
      className={styles['container']}
      style={{ borderRight: getBorderRight(borderRight), flexShrink: 0, minWidth, maxWidth }}
    >
      <ORWorkflowOverviewProcedure data={data} autoScroll={false} preliminary noBottomSpace />
    </div>
  );
};

export default ProcedureView;

const getBorderRight = (borderRight?: boolean) => {
  return borderRight ? '1px solid rgba(52,59,89,0.6)' : 'none';
};
