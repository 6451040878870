import { AddOperation, Dispatcher, OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';

/** Paths */

export const PinCodePath = 'pin-code';
export const PinVerifySubPath = 'verify';
export const PinVerifyPath = joinPath(PinCodePath, PinVerifySubPath);
export const PinSetSubPath = 'set';
export const PinSetPath = joinPath(PinCodePath, PinSetSubPath);

/** Data type specifying credentials for confirmation */

export enum ConfirmCredentialsType {
  PIN = 'PIN',
  Token = 'token',
}
export type ConfirmCredentials = { type: string; data: string };

/** Operation verifying a pin code for a user */

export async function VerifyConfirmCredentials(
  dispatcher: Dispatcher,
  credentials: ConfirmCredentials
): Promise<boolean> {
  const op = dispatcher.start({
    type: OperationType.Add,
    path: PinVerifyPath,
    data: credentials,
  }) as AddOperation;
  return (await op.result())[0] !== '';
}

/** Operation setting a pin code for a user */

export function SetPinCode(
  code: string,
  length: number,
  credentials: ConfirmCredentials
): OperationSpec<{ code: string; length: number; credentials: ConfirmCredentials }> {
  return { type: OperationType.Add, path: PinSetPath, data: { code, length, credentials } };
}
