import { AuthProvider } from '@sqior/js/authbase';
import { ClockTimestamp, StdTimer, TimerHolder, TimerInterface } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';
import { TextResourceAccess } from '@sqior/js/language';
import { Logger } from '@sqior/js/log';
import { ConnectionState, HasConnectionState } from '@sqior/js/wsbase';
import React, { useContext, useEffect, useState } from 'react';

export enum ConnectionError {
  None,
  Connection,
  Authentication,
}

/* Timeouts */
const StdErrorTimeout = 2000;
const WakeUpErrorTimeout = 4000;

/** Server connection observer */
export class ServerConnectionObserver {
  constructor(conn?: HasConnectionState, autoAuth?: AuthProvider, timer = new StdTimer()) {
    this.conn = conn;
    this.autoAuth = autoAuth;
    this.timer = timer;
    this.wakeUpTimestamp = timer.now;
    this.lastState = ConnectionState.NOT_CONNECTED;
    this.errorChanged = new Emitter<[ConnectionError]>();
    /* Observe state of connection */
    if (conn) {
      this.updateState(conn.state);
      conn.stateChanged.on((state) => {
        this.updateState(state);
      });
    } else this.updateState(ConnectionState.ERROR);
  }

  /** Resets any error condition */
  private resetError() {
    this.errorTimer.reset();
    this.reconnectAttempt = 0;
    this.errorChanged.emit((this.error = this.lastError = ConnectionError.None));
  }

  /** Called after the app comes to the foreground, resets the connection error for some time to
   *  give time for a reconnect without a connection error popping up
   */
  reinitialize() {
    if (!this.conn) return;
    Logger.debug('Re-initializing connection if not already active');
    /* Reset error */
    this.resetError();
    this.lastState = ConnectionState.NOT_CONNECTED;
    /* Try to connect again */
    this.conn.tryConnect(false);
    /* Update with current state to restart timer, if applicable */
    this.wakeUpTimestamp = this.timer.now;
    this.updateState(this.conn.state);
  }

  private updateState(state: ConnectionState) {
    /* Some states are rather transient - they do not influence the displayed state */
    if (state === ConnectionState.NOT_CONNECTED || state === ConnectionState.CONNECTED_HANDSHAKE)
      return;

    /* Determine the error */
    let error = ConnectionError.None;
    if (state === ConnectionState.ERROR) error = ConnectionError.Connection;
    else if (
      state === ConnectionState.ERROR_AUTH_FAILED ||
      state === ConnectionState.ERROR_AUTH_RESET ||
      state === ConnectionState.ERROR_INCOMPATIBLE
    ) {
      /* Perform a reload of the app to allow for a check of SSO, as otherwise there is a chance to get
         a "you are already logged in" error in the log-in page */
      this.timer.schedule(() => {
        if (
          state === ConnectionState.ERROR_AUTH_FAILED ||
          state === ConnectionState.ERROR_INCOMPATIBLE
        )
          window.location.reload();
        else this.autoAuth?.logOut();
      }, this.autoAuth?.authFailedReloadPeriod() ?? 0);
      error = ConnectionError.Authentication;
    } else if (state === ConnectionState.ERROR_AUTH_EXPIRED) {
      /* Try to re-establish the connection as the authentication has expired but may work after token refresh */
      error = ConnectionError.Authentication;
    }

    /* Check if there is no error */
    if (error === ConnectionError.None) this.resetError();
    else if (this.error === ConnectionError.None) {
      if (!this.errorTimer.isSet)
        /* Start a timer to inform about the error with some delay */
        this.errorTimer.set(
          this.timer.schedule(
            () => {
              this.errorChanged.emit((this.error = this.lastError));
              this.errorTimer.reset();
            },
            this.timer.now - this.wakeUpTimestamp < WakeUpErrorTimeout
              ? WakeUpErrorTimeout
              : StdErrorTimeout
          )
        );
      this.lastError = error;
    } else
    /* Emit directly */
      this.errorChanged.emit((this.lastError = this.error = error));
    /* Remember last state */
    this.lastState = state;
  }

  reconnect() {
    Logger.debug('Reconnecting forcefully');
    if (
      this.lastState === ConnectionState.ERROR_AUTH_FAILED ||
      this.lastState === ConnectionState.ERROR_INCOMPATIBLE
    )
      this.autoAuth?.tryLogIn();
    else if (this.lastState === ConnectionState.ERROR_AUTH_RESET) this.autoAuth?.logOut();
    else if (this.conn && !this.reconnectAttempt++) this.conn.tryConnect(true);
    else window.location.reload();
  }

  errorString(textDict: TextResourceAccess) {
    let resource: string | undefined;
    if (this.error === ConnectionError.Connection) resource = 'cannot_establish_connection';
    else if (this.error === ConnectionError.Authentication) resource = 'authentication_error';
    return resource ? textDict.get(resource) : undefined;
  }

  private readonly conn?: HasConnectionState;
  private readonly timer: TimerInterface;
  private readonly autoAuth?: AuthProvider;
  private lastError = ConnectionError.None;
  private lastState: ConnectionState;
  private wakeUpTimestamp: ClockTimestamp;
  private readonly errorTimer = new TimerHolder();
  private reconnectAttempt = 0;

  error = ConnectionError.None;
  readonly errorChanged: Emitter<[ConnectionError]>;
}

/* Helper for using the context and providing a react state variable */

export function useConnectionError() {
  /* Get context */
  const connContext = useContext(ServerConnectionContext);
  /* Get state variable */
  const [connError, setConnError] = useState<boolean>(connContext.error !== ConnectionError.None);
  /* Use an effect to observer the error state */
  useEffect(() => {
    /* Observe the error */
    return connContext.errorChanged.on((error) => {
      setConnError(error !== ConnectionError.None);
    });
  }, [connContext]);
  return connError;
}

/** Server connecttion context */
export const ServerConnectionContext = React.createContext(new ServerConnectionObserver());
