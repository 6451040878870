import styles from './sqior-file-upload-gallery.module.css';
import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { FileAttachment } from '../sqior-photo-shoot/sqior-photo-shoot';
import React, { FC, useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { DeleteMedia } from '@sqior/js/media';
import { motion } from 'framer-motion';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { classes } from '@sqior/react/utils';
import { useUIGlobalState } from '@sqior/react/state';

export enum FileAttachmentStatus {
  NO_ATTACHMENT = 'NO_ATTACHMENT', // No attachment
  LOCAL = 'LOCAL', // Upload has not started yet
  PENDING = 'PENDING', // Upload is in progress
  DONE = 'DONE', // Upload is done
}

export const getAttachmentStatus = (attachment: FileAttachment): FileAttachmentStatus => {
  if (attachment.progress === undefined) return FileAttachmentStatus.LOCAL;
  if (attachment.progress === 100 && attachment.finalId) {
    return FileAttachmentStatus.DONE;
  }
  return FileAttachmentStatus.PENDING;
};

export const getMultiAttachmentStatus = (attachments: FileAttachment[]): FileAttachmentStatus => {
  if (attachments.length === 0) return FileAttachmentStatus.NO_ATTACHMENT;
  if (attachments.every((_) => getAttachmentStatus(_) === FileAttachmentStatus.DONE))
    return FileAttachmentStatus.DONE;
  if (attachments.every((_) => getAttachmentStatus(_) === FileAttachmentStatus.LOCAL))
    return FileAttachmentStatus.LOCAL;
  return FileAttachmentStatus.PENDING;
};

interface GalleryProps {
  readonly?: boolean;
  attachments: FileAttachment[];
  removeAttachment: (id: string) => void;

  containerStyle?: React.CSSProperties;
  containerClassName?: string;

  itemWrapperStyle?: React.CSSProperties;
  itemWrapperClassName?: string;

  itemStyle?: React.CSSProperties;
  itemClassName?: string;
}

export const SqiorFileUploadGallery: FC<GalleryProps> = ({
  attachments,
  removeAttachment,
  containerStyle,
  containerClassName,
  readonly,
  itemWrapperStyle,
  itemWrapperClassName,
  itemStyle,
  itemClassName,
}) => {
  const dispatcher = useContext(OperationContext);
  const { setUIGlobalState } = useUIGlobalState();

  const onRemoveAttachment = (id: string) => {
    const finalId = attachments.find((_) => _.id === id)?.finalId;
    if (finalId) dispatcher.start(DeleteMedia(finalId));
    removeAttachment(id);
  };

  return (
    <div
      className={classes(styles['attachments'], containerClassName)}
      style={{
        padding: attachments.length > 0 ? 8 : 0,
        ...containerStyle,
      }}
    >
      {attachments.map((attachment) => (
        <div
          key={attachment.id}
          className={classes(styles['attachment-item-wrapper'], itemWrapperClassName)}
          style={itemWrapperStyle}
        >
          <img
            src={attachment.imageURL}
            alt="attachment"
            className={classes(styles['attachment-item'], itemClassName)}
            style={itemStyle}
            onClick={() => {
              setUIGlobalState((prev) => ({
                ...prev,
                previewSrc: attachment.imageURL,
              }));
            }}
          />
          <LoadingProgress attachment={attachment} />
          <CloseButton id={attachment.id} onRemove={onRemoveAttachment} readonly={readonly} />
        </div>
      ))}
    </div>
  );
};

export default SqiorFileUploadGallery;

type CloseButtonProps = {
  id: string;
  readonly?: boolean;
  onRemove: (id: string) => void;
};

const CloseButton: FC<CloseButtonProps> = ({ onRemove, readonly, id }) => {
  if (readonly) return null;
  const onCloseClick = () => onRemove(id);
  return (
    <motion.div
      className={styles['attachment-item-close-container']}
      whileTap={{ scale: 0.9 }}
      onClick={onCloseClick}
    >
      <CloseRoundedIcon fontSize="small" color="primary" />
    </motion.div>
  );
};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type LoadingProgressProps = {
  attachment: FileAttachment;
};

const LoadingProgress: FC<LoadingProgressProps> = ({ attachment }) => {
  if (attachment.progress === undefined) return null;
  if (getAttachmentStatus(attachment) !== FileAttachmentStatus.PENDING) return null;
  return (
    <div className={styles['loading-overlay']}>
      <CircularProgressWithLabel value={attachment.progress} />
    </div>
  );
};
