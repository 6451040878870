import { NumPad, TitlePage, useTextResources } from '@sqior/react/uibase';
import styles from './pin-entry-page.module.css';
import { useTimer } from '@sqior/react/state';
import { useContext, useEffect, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { addMilliseconds, addSeconds, isEqual } from '@sqior/js/data';
import { ConfirmCredentials, SetPinCode } from '@sqior/viewmodels/user';
import { encodePIN } from '@sqior/js/authbase';
import { OperationState } from '@sqior/js/operation';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { AuthContext, PinNumber } from '@sqior/react/uiauth';

export interface PinEntryPageProps {
  onClose: () => void;
}

export function PinEntryPage(props: PinEntryPageProps) {
  const { timer } = useTimer();
  const dispatcher = useContext(OperationContext);
  const selContext = useContext(SelectionPageContext);
  const authContext = useContext(AuthContext);
  const textDict = useTextResources();
  const pinLength = 4;
  const indexes: number[] = [];
  for (let i = 0; i < pinLength; i++) indexes.push(i);
  const [pinNumbers, setPinNumbers] = useState<number[]>([]);
  const [firstNumbers, setFirstNumbers] = useState<number[]>([]);
  /* Definition of the phase of PIN entry (first/second round) */
  const [round, setRound] = useState(0);
  /* Error message */
  const [error, setError] = useState('');
  useEffect(() => {
    return timer.schedule(() => {
      setError('');
    }, addSeconds(3));
  }, [error, setError, timer]);
  return (
    <TitlePage
      title={round ? textDict.get('pin_confirm') : textDict.get('pin_enter')}
      onClose={() => {
        props.onClose();
      }}
      className={styles['page']}
    >
      <div className={styles['container']}>
        <div className={styles['number-outer-container']}>
          <div className={styles['number-container']}>
            {indexes.map((index) => (
              <PinNumber key={index} num={pinNumbers[index]} />
            ))}{' '}
          </div>
          {error && (
            <div className={styles['error-container']}>
              <span>{error}</span>
            </div>
          )}
        </div>
        <NumPad
          select={(num) => {
            if (pinNumbers.length >= pinLength) return;
            const fullPin = pinNumbers.concat(num);
            setPinNumbers(fullPin);
            /* Start the verification process in case that the full PIN was entered */
            if (fullPin.length === pinLength)
              timer.schedule(() => {
                /* Check if this is thhe first or second round */
                if (!round) {
                  /* Remember entered numbers */
                  setFirstNumbers(fullPin);
                  setPinNumbers([]);
                  setRound(1);
                } else if (!isEqual(firstNumbers, fullPin)) {
                  setError(textDict.get('pin_not_match'));
                  setPinNumbers([]);
                  setRound(0);
                } else {
                  selContext(
                    { entityType: 'ConfirmIdentityPage', title: textDict.get('pin_enter_old') },
                    (ok, data) => {
                      if (ok)
                        dispatcher.start(
                          SetPinCode(
                            encodePIN(
                              authContext.provider.userInfo.userId ?? '',
                              firstNumbers.join()
                            ),
                            firstNumbers.length,
                            data as ConfirmCredentials
                          ),
                          (state) => {
                            setError(textDict.get('pin_changed'));
                            if (state === OperationState.Completed)
                              timer.schedule(() => {
                                props.onClose();
                              }, addSeconds(2));
                            else if (state === OperationState.Failed)
                              setError(textDict.get('pin_change_failed'));
                          }
                        );
                      else props.onClose();
                    }
                  );
                }
              }, addMilliseconds(800));
          }}
          back={() => {
            if (pinNumbers.length) setPinNumbers(pinNumbers.slice(0, -1));
          }}
        />
      </div>
    </TitlePage>
  );
}

export default PinEntryPage;
