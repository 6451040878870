import { memo, ReactNode, useEffect, useRef } from 'react';
import { AnimationDefinition, motion, useAnimation } from 'framer-motion';
import { MotionLayout } from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { useDynamicStateRaw, useSyncAnimation } from '@sqior/react/state';
import { ViewportSize } from '@sqior/web/utils';

export interface AutoScrollerProps {
  children: ReactNode;
  sections?: IAutoScrollerSection[];
  autoScroll?: boolean;
  motionLayout?: MotionLayout;
  zoomLevel?: number;
}

export interface IAutoScrollerSection {
  index: number;
  ref: HTMLDivElement;
}

const TOLERANCE = 14;

export function AutoScroller({
  children,
  sections = [],
  autoScroll = true,
  motionLayout,
  zoomLevel,
}: AutoScrollerProps) {
  const { addStartTime } = useSyncAnimation();
  const viewport = useDynamicStateRaw<ViewportSize>('app/viewport');
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  const getOverflow = (): number => {
    const container = containerRef.current;
    const content = contentRef.current;
    if (!container || !content) return 0;
    const diffOverflowY = content.scrollHeight - container.clientHeight;
    return diffOverflowY > 0 ? diffOverflowY : 0;
  };

  const createSectionAnimationConfig = (sections: IAutoScrollerSection[]): AnimationDefinition => {
    const firstSection = sections.find((section) => section.index === 0);
    if (!firstSection) return { y: 0 };
    const clientHeight = firstSection.ref.clientHeight;
    return {
      y: [0, -(clientHeight + 4)],
      transition: {
        duration: 1,
        ease: 'linear',
        repeat: Infinity,
        flip: true,
        repeatDelay: 2,
        repeatType: 'reverse',
      },
    };
  };

  const createNormalAnimationConfig = (overflow: number): AnimationDefinition => {
    return {
      y: [0, -(overflow - TOLERANCE + 4)],
      transition: {
        duration: 1,
        ease: 'linear',
        repeat: Infinity,
        flip: true,
        repeatDelay: 2,
        repeatType: 'reverse',
      },
    };
  };

  const startAnimation = async (overflow: number, sections: IAutoScrollerSection[]) => {
    if (overflow <= TOLERANCE) return controls.start({ y: 0 });
    const animationConfig = createNormalAnimationConfig(overflow);
    addStartTime();
    await controls.start(animationConfig);
  };

  useEffect(() => {
    const observeTarget = contentRef.current;
    if (!observeTarget) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === observeTarget) {
          if (!autoScroll) return;
          startAnimation(getOverflow(), sections);
        }
      }
    });

    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [sections, autoScroll, sections, viewport, controls, zoomLevel]);

  return (
    <div ref={containerRef}>
      <motion.div layout={motionLayout} animate={controls} ref={contentRef}>
        {children}
      </motion.div>
    </div>
  );
}

export default memo(AutoScroller);
