import styles from './performance-indicators.module.css';
import CardLayout from '../card-layout/card-layout';
import PerformanceIndicatorItem from '../performance-indicator-item/performance-indicator-item';

export type TrendType = 'up' | 'down' | 'equal';

export type PerformanceIndicator = {
  title: string;
  value: number;
  valueUnit?: string;
  trend?: TrendType;
};

/* eslint-disable-next-line */
export interface PerformanceIndicatorsProps {
  data: PerformanceIndicator[];
}

export function PerformanceIndicators({ data }: PerformanceIndicatorsProps) {
  return (
    <CardLayout>
      <div className={styles['container']}>
        {data.map((indicator) => (
          <PerformanceIndicatorItem key={indicator.title} data={indicator} />
        ))}
      </div>
    </CardLayout>
  );
}

export default PerformanceIndicators;
