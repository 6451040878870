import { DateTime } from 'luxon';

export function getPrettyDateString(timestamp: number | undefined) {
  if (timestamp === undefined) return undefined;

  const dt = DateTime.fromMillis(timestamp);

  return dt.toLocaleString(DateTime.DATE_HUGE);
}

export function getPrettyTimeString(timestamp: number | undefined) {
  if (timestamp === undefined) return undefined;

  const dt = DateTime.fromMillis(timestamp);
  const dtNow = DateTime.fromMillis(Date.now());

  const today = dt.day === dtNow.day && dt.month === dtNow.month && dt.year === dtNow.year;

  return dt.toLocaleString(today ? DateTime.TIME_SIMPLE : DateTime.DATETIME_SHORT);
}

export function getPrettyTimeOrDayString(timestamp: number): string {
  const dt = DateTime.fromMillis(timestamp);
  const dtNow = DateTime.fromMillis(Date.now());
  const today = dt.day === dtNow.day && dt.month === dtNow.month && dt.year === dtNow.year;
  return dt.toLocaleString(today ? DateTime.TIME_SIMPLE : { month: '2-digit', day: '2-digit' });
}

export function getPrettyTimeDiffString(timestamp: number, reference: number = Date.now()) {
  if (timestamp === undefined) return undefined;

  const d = DateTime.fromMillis(timestamp);
  const text = d.toRelative({ style: 'short' });
  return text || undefined;
}

export function getPrettyTimeDiffParts(
  timestamp: number,
  reference: number = Date.now()
): [number, string] | undefined {
  if (timestamp === undefined) return undefined;

  if (timestamp === Number.POSITIVE_INFINITY) return [Number.POSITIVE_INFINITY, '---'];

  const round = Math.round;
  const sign = timestamp - reference < 0 ? -1 : +1;

  let diff = round(Math.abs(timestamp - reference) / 1000);
  if (diff <= 90) return [sign * diff, 'sek'];

  diff = round(diff / 60);
  if (diff <= 90) return [sign * diff, 'min'];

  diff = round(diff / 60);
  if (diff < 24) return [sign * diff, 'Std'];

  diff = round(diff / 24);
  if (diff < 24) return [sign * diff, diff > 1 ? 'Tage' : 'Tag'];

  return undefined;
}
