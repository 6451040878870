import { mixInSelectionOption, SelectionMenu } from '@sqior/viewmodels/input';
import { isEqual, ValueObject } from '@sqior/js/data';
import { ComponentFactory, FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { ClosablePage } from '@sqior/react/uibase';
import { useContext, useEffect, useRef } from 'react';
import styles from './selection-page.module.css';
import { AddressInfoVM } from '@sqior/viewmodels/communication';
import { Entity } from '@sqior/js/entity';
import { useDynamicState } from '@sqior/react/state';
import { MessengerTabPath, MessengerTabStack } from '@sqior/viewmodels/app';

export type SelectionPageProps = FactoryProps<SelectionMenu>;

export function SelectionPage(props: SelectionPageProps) {
  const FactoryComponent = useContext(ComponentFactory);
  const { multiSelect } = props.data;

  /* Automatically close if the messenger tab stack changes */
  const messengerTabStack = useDynamicState<MessengerTabStack>(MessengerTabPath, []);
  const initialTabStack = useRef(messengerTabStack);
  const close = props.onClose;
  useEffect(() => {
    /* Init value if it was not set before */
    if (!initialTabStack.current.length) {
      initialTabStack.current = messengerTabStack;
      return;
    }
    /* Compare with the previous value, automatically close if stack changed */
    if (close && !isEqual(messengerTabStack, initialTabStack.current)) close(false);
  }, [messengerTabStack, close]);

  /* Operation emitted */
  const dispatcher = useContext(OperationContext);
  const confirm = (data: ValueObject) => {
    if (props.data.operation) {
      dispatcher.start(mixInSelectionOption(props.data.operation, data));
    }
    if (props.onClose) props.onClose(true, data);
  };

  const onGroupChatSelection = (addresses: AddressInfoVM[], title?: string) => {
    const getAddresses = (addresses: AddressInfoVM[]) => {
      const result: Entity[] = [];
      for (const address of addresses) {
        if (address.chatAddress) result.push(address.chatAddress);
      }
      return result;
    };

    if (props.onClose)
      props.onClose(true, { addresses: getAddresses(addresses), title: title ?? '' });
  };

  return (
    <ClosablePage
      hideHeader={multiSelect}
      onClose={() => {
        if (props.onClose) props.onClose(false);
      }}
    >
      {props.data.header && <FactoryComponent data={props.data.header} />}
      <div className={styles[props.data.header ? 'container-for-header' : 'container']}>
        {props.data.selection.entityType !== 'AddressSelection' && (
          <div className={styles[props.data.header ? 'title-for-header' : 'title']}>
            {props.data.title}
          </div>
        )}
        <FactoryComponent
          data={props.data.selection}
          onSelection={(value: ValueObject | undefined) => {
            if (value !== undefined) confirm(value);
            else if (props.onClose) props.onClose(false);
          }}
          multiSelect={multiSelect}
          onGroupChatSelection={onGroupChatSelection}
          title={props.data.title}
          onClose={() => {
            if (props.onClose) props.onClose(false);
          }}
        />
      </div>
    </ClosablePage>
  );
}

export default SelectionPage;
