import { FactoryProps } from '@sqior/react/factory';
import { DashboardStage } from '@sqior/react/uivisual';
import styles from './orworkflow-dashboard.module.css';
import { DashboardEntity } from '@sqior/viewmodels/visual';

export type ORWorkflowDashboardProps = FactoryProps<DashboardEntity>;

function style(index: number, total: number) {
  if ((index === 0 && total > 6) || (index === 2 && total > 7)) return 'sds';
  if ((index === 1 && total > 6) || (index === 3 && total > 7)) return 'ward';
  if (index === total - 4) return 'transfer';
  if (index === total - 3) return 'induction';
  return 'std';
}

export function ORWorkflowDashboard(props: ORWorkflowDashboardProps) {
  return (
    <div className={styles['container']}>
      {props.data.lists.map((list, index) => (
        <DashboardStage
          key={list.path}
          className={styles[style(index, props.data.lists.length)]}
          title={list.title}
          path={list.path}
          separator={index + 1 < props.data.lists.length}
          containerStyle={{ height: '100%' }}
        />
      ))}
    </div>
  );
}

export default ORWorkflowDashboard;
