import styles from './react-uichecklist.module.css';

/* eslint-disable-next-line */
export interface ReactUichecklistProps {}

export function ReactUichecklist(props: ReactUichecklistProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactUichecklist!</h1>
    </div>
  );
}

export default ReactUichecklist;
