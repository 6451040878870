import { ValueObject } from '@sqior/js/data';

/* Types with sequence number */

export const UndefinedSequenceNumber = -1;

export type Sequenced = { sequenceNumber: number };
export type SequencedObject = Sequenced & ValueObject;

/* Ensures an object has the specified sequence number by setting or adding it if required */

export function ensureSequenceNumber<Type extends Partial<Sequenced> & ValueObject = ValueObject>(
  obj: Type,
  seq: number
): Sequenced & Type {
  if (obj.sequenceNumber) {
    if (obj.sequenceNumber !== seq) obj.sequenceNumber = seq;
    return obj as Sequenced & Type;
  } else return { ...obj, sequenceNumber: seq };
}
