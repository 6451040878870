import { AuthSystem, UserInfo } from '@sqior/js/authbase';
import {
  CoreEntities,
  makeTextEntity,
  RelatedDataDomain,
  RelatedDataModel,
  TextEntity,
  TextTemplate,
  Domain,
  AnchorModel,
  RelatedDataSet,
  DomainInterface,
  extractTextOrDefault,
  EntityRecord,
  makeSubjects,
  CoreInterfaces,
} from '@sqior/js/meta';
import {
  createPersonName,
  PersonEntities,
  PersonInterfaces,
  PersonSexEntity,
} from '@sqior/plugins/person';
import {
  Gender,
  LanguageEntities,
  LanguageInterfaces,
  makeAnonymizedText,
  makeGender,
  makeTexts,
  resourceTextTemplate,
  textResource,
  TextResource,
} from '@sqior/plugins/language';
import {
  ListView,
  ListViewRoots,
  makeListViewInfo,
  makeListViewInfos,
  makeListViewRoots,
  VisualEntities,
  makeAnonymousHTML,
} from '@sqior/plugins/visual';
import { AccountName, AccountNameModel, makeAccountName } from './account-name';
import {
  EMailAddress,
  EMailAddressModel,
  GetEMailAddressModel,
  makeEMailAddress,
} from './email-address';
import { EmployeeIdModel } from './employee-id';
import { makeOidcClaims, makeOidcId, OidcId, OidcModel } from './oidc-definitions';
import {
  ActiveLocationLabel,
  ActiveLocationRolesModel,
  ActiveLocationsModel,
  EffectiveRolesModel,
  LocationRole,
  LocationRoleModel,
  makeActiveLocations,
  makeLocationRole,
  makeRolesEntity,
  RoleEntity,
  RoleEntityModel,
  AssociatedRoleModel,
  Roles,
  RolesEntity,
  RolesEntityModel,
  RoleIfAvailableModel,
  AvailableLocationsModel,
  SpecialtyLocationRole,
  SpecialtyLocationRoleModel,
  hasRole,
  HasRoleModel,
  AvailableRolesModel,
  makeRoleEntity,
  RolesList,
  RoleTexts,
  BaseRoleModel,
  PermanentLocationModel,
  AvailableLocationsForUserModel,
  LocationRoleTemplate,
  EffectiveLocationsModel,
  RolesOptionsModel,
  RolesOptions,
  extractRoles,
  intersectRoles,
  makeRolesOptions,
  EffectiveRoleModel,
  makeSpecialtyLocationRole,
  EffectiveLocationModel,
  UserActiveLocationsModel,
  SelectableRolesOptionsModel,
  AllowedLocationsModel,
} from './role-entity';
import { RoleUser, RoleUserModel } from './role-user';
import {
  AddressSearchRolesToConsiderModel,
  AvailableDeviceDefaultLocationsModel,
  ByUserRoleModel,
  CalledUsersEntity,
  CalledUsersModel,
  DeviceDynamicRolesModel,
  EffectiveDeviceDynamicRolesModel,
  PermanentDeviceSettingsEditModeEntity,
  PermanentDeviceSettingsEditModeModel,
  FixedPushDevicesAddressModel,
  LastConnectModel,
  makePermanentDeviceSettingsEditMode,
  makeOnlineStatus,
  OnlineStatusModel,
  PersonalizedDeviceModel,
  ProfilePictureURLTemplateModel,
  RoleContextProperty,
  SearchUserCluster,
  SearchUserClusterModel,
  ServerAdministratorModel,
  UserCallInitiatorEntity,
  UserCallInitiatorModel,
  UserChangeModel,
  UserContextProperty,
  UserEntities,
  UserInterfaces,
  UserLocationClusterFunction,
  UserLocationClusterFunctionModel,
  UserNameBackup,
  UserNameBackupModel,
  UserProfilePictureModel,
  UsersAsSubjectsModel,
  UserSearchModel,
  UsersEntity,
  UserSettingsModel,
  UserRolesTextModel,
} from './user-definitions';
import { Logger } from '@sqior/js/log';
import {
  LocationAnchor,
  LocationClusterAnchor,
  LocationClusterFunction,
  LocationClusterFunctionEntity,
  LocationClusterRelatedFunction,
  LocationClustersEntity,
  LocationEntities,
  LocationFunction,
  LocationInterfaces,
  LocationsEntity,
  makeLocationClusterFunction,
  makeLocationFunction,
  makeRoomFunction,
  RoomFunction,
  RoomFunctions,
} from '@sqior/plugins/location';
import {
  FunctionQueue,
  arrayConcatAsync,
  arrayFilterAsync,
  arrayTransformAsync,
} from '@sqior/js/async';
import { BarcodeHandlerRegistry } from './barcode-handler-registry';
import {
  DeviceAnchor,
  DeviceEntities,
  DeviceInterfaces,
  DeviceLastConnect,
  DeviceLastDisconnect,
  DevicesEntity,
  PhoneNumber,
  makePhoneNumbers,
} from '@sqior/plugins/device';
import { addHours, replaceChar } from '@sqior/js/data';
import {
  SpecialtiesEntity,
  SpecialtyEntities,
  SpecialtyInterfaces,
  makeSpecialties,
} from '@sqior/plugins/specialty';
import { Entity, IdEntity } from '@sqior/js/entity';
import { ListHeaderType, ListViewInfo, ListViewsStatePath } from '@sqior/viewmodels/visual';
import { makeTimestampEntity, TimestampEntity } from '@sqior/plugins/time';
import { HoursUntilOffline } from './address-domain';
import { OnlineStatus } from '@sqior/viewmodels/communication';
import { joinUrlPath, URLContext } from '@sqior/js/url';
import { makeTestUser, TestUserModel } from './test-user';
import {
  makeUserGroups,
  UserGroupIdModel,
  UserGroupModel,
  UserGroupsEntity,
  UserGroupsModel,
} from './user-group';
import { UserSearch } from './user-search';
import { DatabaseInterface } from '@sqior/js/db';
import { PersonSex } from '@sqior/viewmodels/person';
import { KioskUserModel, makeKioskUser } from './kiosk-user';
import { makeAddresses } from './addresses';
import { MaxUserSessionDurationModel } from './user-session-context';
import { CommunicationRolesModel } from './real-user';
import { PINAvailableModel, PINCodeModel } from './pin-entity';
import { PermanentDeviceSettingsEditMode } from '@sqior/viewmodels/user';

export const UserDomainName = 'User';
export const UserAnchor = new AnchorModel(UserDomainName, UserInterfaces.Key);
export const UserAccountName = new RelatedDataModel(
  UserAnchor,
  'AccountName',
  UserEntities.AccountName,
  true
);
export const UserActiveLocations = new RelatedDataModel(
  UserAnchor,
  'ActiveLocations',
  UserEntities.ActiveLocations,
  true,
  'locations',
  LocationInterfaces.LocationOrCluster
);
export const UserAllowedLocations = new RelatedDataModel(
  UserAnchor,
  'AllowedLocations',
  LocationEntities.Locations,
  true,
  'locations',
  LocationInterfaces.LocationOrCluster
);
export const UserEmail = new RelatedDataModel(UserAnchor, 'Email', UserEntities.EMail, true);
export const UserEmployeeId = new RelatedDataModel(
  UserAnchor,
  'EmployeeId',
  UserEntities.EmployeeId,
  true
);
export const UserPersonName = new RelatedDataModel(
  UserAnchor,
  'PersonName',
  PersonEntities.PersonName,
  true
);
export const UserPhoneNumbers = new RelatedDataModel(
  UserAnchor,
  'PhoneNumbers',
  DeviceEntities.PhoneNumbers,
  true,
  'phoneNumbers',
  DeviceEntities.PhoneNumber
);
export const UserProfilePicture = new RelatedDataModel(
  UserAnchor,
  'ProfilePicture',
  UserInterfaces.ProfilePicture,
  true
);
export const UserSettings = new RelatedDataModel(
  UserAnchor,
  'Preferences',
  UserEntities.Settings,
  true
);

export const UserRoles = new RelatedDataModel(
  UserAnchor,
  'Role',
  UserEntities.Roles,
  true,
  'roles',
  UserEntities.Role
);
export const UserSex = new RelatedDataModel(UserAnchor, 'Sex', PersonEntities.PersonSex);
export const UserSpecialties = new RelatedDataModel(
  UserAnchor,
  'Specialties',
  SpecialtyEntities.Specialties,
  true,
  'specialties',
  SpecialtyEntities.Specialty
);
/** The user of the device as long as the device is online */
export const DeviceCurrentUser = new RelatedDataModel(
  DeviceAnchor,
  'CurrentUser',
  UserInterfaces.Key,
  true
);
export const DeviceDynamicRole = new RelatedDataModel(
  DeviceAnchor,
  'DynamicRole',
  UserEntities.Role
);
/** The last user of the device */
export const DeviceLastUser = new RelatedDataModel(
  DeviceAnchor,
  'LastUser',
  UserInterfaces.Key,
  true
);
export const DeviceFixedRoles = new RelatedDataModel(
  DeviceAnchor,
  'FixedRoles',
  UserEntities.Roles,
  true,
  'roles',
  UserEntities.Role
);
export const DeviceFixedPushRoles = new RelatedDataModel(
  DeviceAnchor,
  'FixedPushRoles',
  UserEntities.Roles,
  true,
  'roles',
  UserEntities.Role
);
export const UserPINCode = new RelatedDataModel(UserAnchor, 'PINCode', UserEntities.PINCode);

export class UserDomain extends RelatedDataDomain {
  constructor(context: URLContext) {
    /* Register entities and interfaces */
    super(UserAnchor, {
      entities: [
        EMailAddressModel,
        EmployeeIdModel,
        OidcModel,
        AccountNameModel,
        RoleEntityModel,
        RolesEntityModel,
        RoleUserModel,
        OnlineStatusModel,
        ActiveLocationRolesModel,
        ActiveLocationsModel,
        LocationRoleModel,
        SpecialtyLocationRoleModel,
        TestUserModel,
        UserGroupIdModel,
        UserGroupsModel,
        RolesOptionsModel,
        UserNameBackupModel,
        SearchUserClusterModel,
        KioskUserModel,
        UserLocationClusterFunctionModel,
        CommunicationRolesModel,
        CalledUsersModel,
        UserCallInitiatorModel,
        UserSettingsModel,
        PINCodeModel,
        PermanentDeviceSettingsEditModeModel,
      ],
      interfaces: [
        ActiveLocationLabel,
        AssociatedRoleModel,
        RoleIfAvailableModel,
        EffectiveRoleModel,
        HasRoleModel,
        AvailableRolesModel,
        LastConnectModel,
        BaseRoleModel,
        PermanentLocationModel,
        PersonalizedDeviceModel,
        EffectiveRolesModel,
        AvailableLocationsModel,
        AvailableLocationsForUserModel,
        AllowedLocationsModel,
        ByUserRoleModel,
        EffectiveLocationsModel,
        UserGroupModel,
        UserProfilePictureModel,
        UserSearchModel,
        UserChangeModel,
        AddressSearchRolesToConsiderModel,
        FixedPushDevicesAddressModel,
        DeviceDynamicRolesModel,
        EffectiveDeviceDynamicRolesModel,
        GetEMailAddressModel,
        ServerAdministratorModel,
        MaxUserSessionDurationModel,
        EffectiveLocationModel,
        UserActiveLocationsModel,
        UsersAsSubjectsModel,
        PINAvailableModel,
        SelectableRolesOptionsModel,
        AvailableDeviceDefaultLocationsModel,
        ProfilePictureURLTemplateModel,
        UserRolesTextModel,
      ],
      relatedData: [
        UserRoles,
        UserActiveLocations,
        UserAllowedLocations,
        UserPersonName,
        UserEmail,
        UserAccountName,
        UserEmployeeId,
        UserSpecialties,
        UserPhoneNumbers,
        UserProfilePicture,
        UserSex,
        UserSettings,
        UserPINCode,
      ],
      contextProperties: [
        { name: UserContextProperty, autoForward: false, mandatory: true },
        { name: RoleContextProperty, autoForward: false },
      ],
    });

    /* Map the E-Mail address to the user key interface to use it as such */
    this.addTrivialMapping(UserEntities.OidcId, UserInterfaces.Key);

    this.addEntityMapping(UserInterfaces.Key, PersonInterfaces.Name, async (entity, mapper) => {
      return mapper.map(entity, UserPersonName.interface.type);
    });
    this.addEntityMapping(UserInterfaces.Key, UserEntities.EmployeeId, async (entity, mapper) => {
      return mapper.map(entity, UserEmployeeId.interface.type);
    });
    this.addEntityMapping(UserInterfaces.Key, UserInterfaces.EMail, async (entity, mapper) => {
      return mapper.map(entity, UserEmail.interface.type);
    });
    this.addEntityMapping(
      UserInterfaces.Key,
      UserEntities.AccountName,
      async (entity, mapper) => {
        return mapper.map(entity, UserAccountName.interface.type);
      },
      { weight: 20 }
    );
    this.addEntityMapping<OidcId>(
      UserEntities.OidcId,
      CoreEntities.Text,
      async (entity, mapper) => {
        let nameText = await mapper.tryMapChain(entity, [
          PersonInterfaces.Name,
          PersonInterfaces.FluentName,
        ]);
        if (!nameText)
          nameText = await mapper.tryMapChain(entity, [
            UserEmail.interface.type,
            CoreEntities.Text,
          ]);
        if (!nameText)
          nameText = await mapper.tryMapChain(entity, [
            UserAccountName.interface.type,
            CoreEntities.Text,
          ]);
        if (!nameText) nameText = makeTextEntity(entity.sub);

        return nameText;
      }
    );
    this.addTrivialMapping(UserEntities.EMail, UserInterfaces.EMail);
    this.addBasicMapping<EMailAddress, TextEntity>(
      UserEntities.EMail,
      CoreEntities.Text,
      (entity) => {
        return makeTextEntity(entity.email);
      }
    );
    this.addBasicMapping<AccountName, TextEntity>(
      UserEntities.AccountName,
      CoreEntities.Text,
      (entity) => {
        return makeTextEntity(entity.accountName);
      }
    );

    /* Converts users to subjects */
    this.addEntityMapping<UsersEntity>(
      UserEntities.Users,
      UserInterfaces.UsersAsSubjects,
      async (usersEnt) => {
        return makeSubjects(usersEnt.users);
      }
    );
    this.addEntityMapping<CalledUsersEntity>(
      UserEntities.CalledUsers,
      UserInterfaces.UsersAsSubjects,
      async (usersEnt) => {
        return makeSubjects(
          usersEnt.users.map((ui) => {
            return ui.user;
          })
        );
      }
    );

    /* User identification that is backed-up by a name in case that the user cannot be resolved */
    this.addEntityMapping<UserNameBackup>(
      UserEntities.NameBackup,
      UserInterfaces.Key,
      async (un, mapper) => {
        return mapper.tryMap(un.user, UserInterfaces.Key);
      },
      { weight: 1.5 }
    );
    this.addEntityMapping<UserNameBackup>(
      UserEntities.NameBackup,
      PersonInterfaces.Name,
      async (un, mapper) => {
        /* Try to extract the name from the user */
        const userName = await mapper.tryMap(un.user, PersonInterfaces.Name);
        return userName ? userName : mapper.tryMap(un.name, PersonInterfaces.Name);
      }
    );
    this.addEntityMapping<UserNameBackup>(
      UserEntities.NameBackup,
      CoreEntities.Text,
      async (un, mapper) => {
        /* Try to extract the name from the user */
        let userName = await mapper.tryMapChain(un.user, [
          PersonInterfaces.Name,
          PersonInterfaces.FluentName,
          CoreEntities.Text,
        ]);
        if (!userName)
          userName = await mapper.tryMapChain(un.name, [
            PersonInterfaces.Name,
            PersonInterfaces.FluentName,
            CoreEntities.Text,
          ]);
        return userName ? userName : mapper.tryMap(un.name, CoreEntities.Text);
      }
    );

    /* Kiosk user mappings */
    this.addTrivialMapping(UserEntities.Kiosk, UserInterfaces.Key);
    this.addEntityMapping<IdEntity, TextEntity>(
      UserEntities.Kiosk,
      LanguageEntities.AnonymizedText,
      async (user) => {
        return makeAnonymizedText('Kiosk-' + user.id);
      },
      { weight: 0.5 }
    );

    /* Map the test user */
    this.addTrivialMapping(UserEntities.TestUser, UserInterfaces.Key);
    this.addEntityMapping<Entity, TextEntity>(
      UserEntities.TestUser,
      LanguageEntities.AnonymizedText,
      async (user, mapper) => {
        /* Name the user according to the roles */
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
        if (!rolesEnt || !rolesEnt.roles) return makeAnonymizedText('Test');
        const res = await mapper.map<TextEntity>(makeTexts(rolesEnt.roles), CoreEntities.Text);
        return makeAnonymizedText(res.text);
      },
      { weight: 0.5 }
    );

    /* Map the special sqior Assistant user */
    const sqiorAssistantText = makeAnonymizedText('sqior Assistant');
    this.addEntityMapping<Entity, TextEntity>(
      CoreEntities.SQIORAssistant,
      LanguageEntities.AnonymizedText,
      async () => {
        return sqiorAssistantText;
      },
      { weight: 0.5 }
    );
    const sqiorAssistantHTML = makeAnonymousHTML(
      `<img src='${joinUrlPath(
        context.baseURL,
        'logo192.png'
      )}' width=18 style='vertical-align: middle;'/> <span style='color: #1cade4;'>sqior Assistant</span>`
    );
    this.addEntityMapping<Entity>(
      CoreEntities.SQIORAssistant,
      VisualEntities.AnonymousHTML,
      async () => {
        return sqiorAssistantHTML;
      }
    );

    /* Group mappings */
    this.addTrivialMapping(UserEntities.GroupId, UserInterfaces.GroupKey);
    this.addEntityMapping(
      UserInterfaces.GroupKey,
      UserEntities.Groups,
      async (group) => {
        return makeUserGroups(group);
      },
      { weight: 100 }
    );
    this.addEntityMapping<UserGroupsEntity>(
      UserEntities.Groups,
      UserEntities.RolesOptions,
      async (groupsEnt, mapper) => {
        return makeRolesOptions(
          mapper.eliminateDuplicates(
            await arrayConcatAsync(groupsEnt.groups, async (group) => {
              const rolesOptionsEnt = await mapper.tryMap<RolesOptions>(
                group,
                UserEntities.RolesOptions
              );
              return rolesOptionsEnt ? rolesOptionsEnt.options : [];
            })
          )
        );
      }
    );
    // Per default, all UserEntities.RolesOptions are selectable
    this.addTrivialMapping(UserEntities.RolesOptions, UserInterfaces.SelectableRolesOptions, 2);

    /* Map a user to his/her roles as text */
    this.addEntityMapping(UserInterfaces.Key, UserInterfaces.RoleText, async (user, mapper) => {
      /* Map user to roles */
      const roles = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
      /* Determine gender, if applicable */
      const context = await UserDomain.getGenderContext(mapper, user);
      let text = '';
      if (roles && roles.roles.length > 5)
        return mapper.tryMap(textResource('super_user'), LanguageInterfaces.Anonymized, context);
      else if (roles)
        for (const role of roles.roles) {
          const textEnt = await mapper.tryMap<TextEntity>(
            role,
            LanguageInterfaces.Anonymized,
            context
          );
          if (!textEnt) continue;
          if (text.length > 0) text += '/';
          text += textEnt.text;
        }
      if (text.length > 0) return makeAnonymizedText(text);
      return undefined;
    });

    /* Map a user to anonymized text */
    this.addEntityMapping(
      UserInterfaces.Key,
      LanguageInterfaces.Anonymized,
      async (user, mapper) => {
        /* Use roles text, if available */
        const roleText = await mapper.tryMap(user, UserInterfaces.RoleText);
        if (roleText) return roleText;
        /* Determine gender, if applicable */
        const context = await UserDomain.getGenderContext(mapper, user);
        return mapper.tryMap(textResource('user'), LanguageInterfaces.Anonymized, context);
      }
    );

    /* Map the roles */
    this.addTrivialMapping(UserRoles.interface.type, UserEntities.Roles);
    this.addTrivialMapping(UserEntities.Role, UserInterfaces.BaseRole);
    this.addTrivialMapping(UserEntities.Role, UserInterfaces.AssociatedRole);
    this.addTrivialMapping(UserEntities.LocationRole, UserInterfaces.AssociatedRole);
    this.addEntityMapping<RoleUser>(
      UserEntities.LocationRole,
      UserInterfaces.BaseRole,
      async (locRoleEnt) => {
        return locRoleEnt.role;
      }
    );
    this.addEntityMapping<SpecialtyLocationRole>(
      UserEntities.SpecialtyLocationRole,
      UserInterfaces.BaseRole,
      async (specLocRoleEnt) => {
        return specLocRoleEnt.role;
      }
    );
    this.addEntityMapping<RoleUser>(
      UserInterfaces.AssociatedRole,
      UserInterfaces.BaseRole,
      async (locRoleEnt, mapper) => {
        return mapper.tryMap(locRoleEnt.role, UserInterfaces.BaseRole);
      }
    );
    this.addBasicMapping<RoleUser>(
      UserEntities.RoleUser,
      UserInterfaces.AssociatedRole,
      (entity) => {
        return entity.role;
      }
    );
    this.addEntityMapping(
      UserInterfaces.Address,
      UserInterfaces.RoleIfAvailable,
      async (entity, mapper) => {
        const role = await mapper.tryMap(entity, UserInterfaces.AssociatedRole);
        return role ? role : entity;
      }
    );
    this.addBasicMapping<RoleEntity, TextResource>(
      UserEntities.Role,
      LanguageEntities.TextResource,
      (entity) => {
        const res = RoleTexts.get(entity.role);
        if (res) return textResource(res);
        Logger.warn(['Missing text resource for role:', entity.role]);
        return textResource('undefined');
      },
      2
    );
    /* Checks if a user has a personalized device */
    this.addEntityMapping<RolesEntity, RolesEntity>(
      UserInterfaces.Key,
      UserInterfaces.PersonalizedDevice,
      async (user, mapper) => {
        const rolesEnt = await mapper.map<RolesEntity>(user, UserEntities.Roles);
        for (const role of rolesEnt.roles) {
          if (role.entityType !== UserEntities.Role) continue;
          const roleName = (role as RoleEntity).role;
          /* Physicians and anesthesists typically have a personalized device */
          if (
            roleName === Roles.Surgeon ||
            roleName === Roles.WardPhysician ||
            roleName === Roles.Anesthesist ||
            roleName === Roles.AnesthesistDirector ||
            roleName === Roles.AnesthesiaNurseDirector ||
            roleName === Roles.AnesthesiaNurseWardLead
          )
            return user;
        }
        return undefined;
      }
    );
    this.addEntityMapping<RoleUser, TextTemplate>(
      UserEntities.RoleUser,
      CoreEntities.TextTemplate,
      async (roleUser, mapper) => {
        /* Determine gender, if applicable */
        const context = await UserDomain.getGenderContext(mapper, roleUser.user);
        const roleText = context['gender']
          ? await mapper.tryMap(roleUser.role, LanguageInterfaces.Anonymized, context)
          : undefined;
        return resourceTextTemplate('role_user', {
          role: roleText ? roleText : roleUser.role,
          user: roleUser.user,
        });
      }
    );
    this.addEntityMapping<LocationRole, TextTemplate>(
      UserEntities.LocationRole,
      CoreEntities.TextTemplate,
      async (entity, mapper) => {
        /* Check if the location is obvious, then only the role is named */
        if (await mapper.tryMap(entity.location, LocationInterfaces.Obvious))
          return mapper.tryMap(entity.role, CoreEntities.TextTemplate);
        return resourceTextTemplate('role_of_location', {
          role: entity.role,
          loc: entity.location,
        });
      }
    );
    this.addEntityMapping<SpecialtyLocationRole, TextTemplate>(
      UserEntities.SpecialtyLocationRole,
      CoreEntities.TextTemplate,
      async (entity, mapper) => {
        const specialtyrole = await mapper.mapChain(entity.specialty, [
          SpecialtyInterfaces.SpecialtyNameAsPerson,
          LanguageEntities.AnonymizedText,
        ]);
        return resourceTextTemplate('specialtyrole_of_location', {
          specialtyrole: specialtyrole,
          loc: entity.location,
        });
      }
    );

    this.addEntityMapping<RolesEntity>(
      UserEntities.Roles,
      LanguageEntities.AnonymizedText,
      async (roles, mapper) => {
        const rolesOther: Entity[] = [];
        const rolesLoc = new Map<string, { role: Entity; locs: Entity[] }>();
        const rolesLocSpec = new Map<string, { role: Entity; loc: Entity; specs: Entity[] }>();

        // Split different role types
        for (const r of roles.roles) {
          if (r.entityType === UserEntities.LocationRole) {
            const lr = r as LocationRole;
            const i = rolesLoc.get(mapper.makeKey(lr.role));
            if (i) i.locs.push(lr.location);
            else rolesLoc.set(mapper.makeKey(lr.role), { role: lr.role, locs: [lr.location] });
          } else if (r.entityType === UserEntities.SpecialtyLocationRole) {
            const slr = r as SpecialtyLocationRole;
            const key = `${mapper.makeKey(slr.role)}|${mapper.makeKey(slr.location)}`;
            const i = rolesLocSpec.get(key);
            if (i) i.specs.push(slr.specialty);
            else
              rolesLocSpec.set(key, { role: slr.role, loc: slr.location, specs: [slr.specialty] });
          } else {
            rolesOther.push(r);
          }
        }

        // Glue together
        const texts: Entity[] = [];
        for (const r of rolesLoc) {
          const locs = makeTexts(r[1].locs);
          texts.push(resourceTextTemplate('role_of_location', { role: r[1].role, loc: locs }));
        }
        for (const r of rolesLocSpec) {
          const specs = makeTexts(r[1].specs);
          texts.push(
            resourceTextTemplate('role_of_location_of_specialty', {
              role: r[1].role,
              loc: r[1].loc,
              spec: specs,
            })
          );
        }
        for (const r of rolesOther) {
          texts.push(r);
        }

        const res = makeTexts(texts);

        return mapper.map(res, LanguageInterfaces.Anonymized);
      }
    );

    /* Mappings from user and roles to a text module referring to this user or role */
    this.addEntityMapping(
      UserInterfaces.Key,
      UserInterfaces.ByRoleText,
      async (user) => {
        return resourceTextTemplate('by_user', { user: user });
      },
      { weight: 10 }
    );
    this.addEntityMapping(UserEntities.Role, UserInterfaces.ByRoleText, async (role) => {
      return resourceTextTemplate('by_role', { role: role });
    });
    this.addEntityMapping(UserEntities.LocationRole, UserInterfaces.ByRoleText, async (role) => {
      return resourceTextTemplate('by_role', { role: role });
    });
    this.addEntityMapping(
      UserEntities.SpecialtyLocationRole,
      UserInterfaces.ByRoleText,
      async (role) => {
        return resourceTextTemplate('by_role', { role: role });
      }
    );

    /* Returns the effective location of a role */
    this.addEntityMapping<LocationRole>(
      UserEntities.LocationRole,
      UserInterfaces.EffectiveLocation,
      async (locRole) => {
        return locRole.location;
      }
    );
    this.addEntityMapping<SpecialtyLocationRole>(
      UserEntities.SpecialtyLocationRole,
      UserInterfaces.EffectiveLocation,
      async (specLocRole) => {
        return specLocRole.location;
      }
    );
    /* Returns the active locations of a user that are effectively contributing to a role */
    this.addEntityMapping<RolesEntity>(
      UserInterfaces.EffectiveRoles,
      UserInterfaces.EffectiveLocations,
      async (rolesEnt, mapper) => {
        return LocationAnchor.makeContainer(
          mapper.eliminateDuplicates(
            await arrayTransformAsync(rolesEnt.roles, async (role) => {
              return mapper.tryMap(role, UserInterfaces.EffectiveLocation);
            })
          )
        );
      }
    );

    // Returns all (sub)clusters specified by a ClusterFunction a user has access to via EffectiveLocation
    this.addEntityMapping(
      LocationEntities.ClusterFunction,
      LocationEntities.Clusters,
      async (clusterFunc, mapper) => {
        const user = mapper.tryContext(UserContextProperty);
        if (user) {
          const rootLocs = await mapper.map<LocationsEntity>(
            user,
            UserInterfaces.EffectiveLocations
          );
          const clusters = await arrayTransformAsync(rootLocs.locations, async (loc) => {
            if (!mapper.represents(loc, LocationInterfaces.ClusterKey)) return undefined;
            const clusters = await mapper.map<LocationClustersEntity>(
              loc,
              LocationInterfaces.ContainingClusters
            );
            return clusters.locationclusters;
          });
          const flatClusters = mapper.eliminateDuplicates(
            clusters.reduce<Entity[]>((prev, curr) => prev.concat(curr), [])
          );
          const filteredClusters = await arrayFilterAsync(flatClusters, async (c) => {
            const func = await mapper.tryMap(c, LocationClusterRelatedFunction.interface.type);
            return mapper.isEqual(func, clusterFunc);
          });
          return LocationClusterAnchor.makeContainer(filteredClusters);
        } else {
          // Fallback in case user is not provided
          return await mapper.map<LocationClustersEntity>(
            clusterFunc,
            LocationClusterRelatedFunction.anchorsType
          );
        }
      },
      { context: UserContextProperty }
    );

    // Returns all (sub)clusters specified by a ClusterFunction a user has access to via EffectiveLocation
    this.addEntityMapping<UserLocationClusterFunction>(
      UserEntities.UserClusterFunction,
      LocationEntities.Clusters,
      async (ulcf, mapper) => {
        if (ulcf.user || ulcf.cluster) {
          // Return all clusters, the user has access (if specified) and is a sub-cluster of ulcf.cluster
          const userClusters: Entity[] = [];
          const subClusters: Entity[] = [];
          if (ulcf.user) {
            const rootLocs =
              ulcf.user &&
              (await mapper.map<LocationsEntity>(ulcf.user, UserInterfaces.EffectiveLocations));
            const clusters = await arrayTransformAsync(rootLocs.locations, async (loc) => {
              if (!mapper.represents(loc, LocationInterfaces.ClusterKey)) return undefined;
              const clusters = await mapper.map<LocationClustersEntity>(
                loc,
                LocationInterfaces.ContainingClusters
              );
              return clusters.locationclusters;
            });
            const flatClusters = mapper.eliminateDuplicates(
              clusters.reduce<Entity[]>((prev, curr) => prev.concat(curr), [])
            );
            userClusters.push(...flatClusters);
          }
          if (ulcf.cluster) {
            const clusters = await mapper.map<LocationClustersEntity>(
              ulcf.cluster,
              LocationInterfaces.ContainingClusters
            );
            subClusters.push(...clusters.locationclusters);
          }
          const combinedClusters =
            ulcf.cluster && ulcf.user
              ? mapper.intersect(userClusters, subClusters)
              : [...userClusters, ...subClusters];
          const filteredClusters = await arrayFilterAsync(combinedClusters, async (c) => {
            const func = await mapper.tryMap(c, LocationClusterRelatedFunction.interface.type);
            return mapper.isEqual(func, ulcf.function);
          });
          return LocationClusterAnchor.makeContainer(filteredClusters);
        } else {
          // if user and cluster are not specified
          return await mapper.map<LocationClustersEntity>(
            ulcf.function,
            LocationClusterRelatedFunction.anchorsType
          );
        }
      }
    );

    this.addEntityMapping(
      UserInterfaces.Key,
      UserInterfaces.ActiveLocationLabel,
      async (user, mapper) => {
        /* Check if the user has any locations available at all */
        const availLocs = await mapper.tryMap<LocationsEntity>(
          user,
          UserInterfaces.AvailableLocations
        );
        if (!availLocs || !availLocs.locations.length) return makeActiveLocations([]);
        /* Check if there are active locations defined for this user */
        const activeLocs = await mapper.tryMap<LocationsEntity>(
          user,
          UserInterfaces.EffectiveLocations
        );
        if (activeLocs) {
          /* Build map of available locations, do not display locations that are not available for selection */
          const availLocsSet = mapper.makeKeySet(availLocs.locations);
          const selectableLocs = await arrayTransformAsync(activeLocs.locations, async (loc) => {
            return availLocsSet.has(mapper.makeKey(loc)) ? loc : undefined;
          });
          if (selectableLocs.length) return makeActiveLocations(selectableLocs);
        }
        /* Show an information that no location is currently active */
        return makeActiveLocations([textResource('no_location_assigned')]);
      }
    );

    /* This mapping defines, which roles are location or cluster specific */
    this.addEntityMapping<RoleEntity>(
      UserEntities.Role,
      UserInterfaces.AvailableLocations,
      async (roleEnt, mapper) => {
        /* Check for roles that are OR specific */
        let availableLocations: Entity[] = [];
        if (
          roleEnt.role === Roles.ORNurse ||
          roleEnt.role === Roles.Anesthesist ||
          roleEnt.role === Roles.AnesthesiaNurse
        )
          availableLocations = (
            await mapper.map<LocationsEntity>(
              makeRoomFunction(RoomFunctions.Operating),
              LocationFunction.anchorsType
            )
          ).locations;
        /* Check if this is an anesthesist who is also signing in at recovery rooms */
        if (roleEnt.role === Roles.Anesthesist)
          availableLocations = availableLocations.concat(
            (
              await mapper.map<LocationsEntity>(
                makeRoomFunction(RoomFunctions.Recovery),
                LocationFunction.anchorsType
              )
            ).locations
          );
        /* Check for ward specific roles */
        if (roleEnt.role === Roles.WardNurse || roleEnt.role === Roles.WardPhysician) {
          /* Get all patient wards */
          const wards = await mapper.tryMap<LocationClustersEntity>(
            makeLocationClusterFunction(LocationClusterFunction.PatientWard),
            LocationClusterRelatedFunction.anchorsType
          );
          if (wards) availableLocations = availableLocations.concat(wards.locationclusters);
          /* Physicians can also be assigned to ER's */
          if (roleEnt.role === Roles.WardPhysician) {
            const ers = await mapper.tryMap<LocationsEntity>(
              makeRoomFunction(RoomFunctions.ER),
              LocationFunction.anchorsType
            );
            if (ers) availableLocations = availableLocations.concat(ers.locations);
          }
        }
        return LocationAnchor.makeContainer(availableLocations);
      },
      { weight: 2 }
    );

    /* User-specific mapping of role to available locations - defaults to the non-user specific version */
    this.addEntityMapping(
      UserEntities.Role,
      UserInterfaces.AvailableLocationsForUser,
      async (role, mapper) => {
        return mapper.tryMap(role, UserInterfaces.AvailableLocations);
      },
      { weight: 2 }
    );

    /* This determines all possible locations or clusters that the user has specific roles for */
    this.addEntityMapping(
      UserInterfaces.Key,
      UserInterfaces.AvailableLocations,
      async (user, mapper) => {
        let availableLocations: Entity[] = [];
        /* Loop all roles and get their respective available locations */
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
        if (rolesEnt)
          for (const role of rolesEnt.roles)
            availableLocations = availableLocations.concat(
              (await mapper.map<LocationsEntity>(role, UserInterfaces.AvailableLocationsForUser))
                .locations
            );
        // Limit to allowed locations, if UserAllowedLocations is not set, there is no limitation
        const allowedLocations = await mapper.tryMap<LocationsEntity>(
          user,
          UserInterfaces.AllowedLocations
        );
        if (allowedLocations !== undefined)
          availableLocations = mapper.intersect(availableLocations, allowedLocations.locations);

        return LocationAnchor.makeContainer(mapper.eliminateDuplicates(availableLocations));
      }
    );

    /* Mapping related data to active and allowed locations by default */
    this.addTrivialMapping(UserActiveLocations.interface.type, UserInterfaces.ActiveLocations);
    this.addTrivialMapping(UserAllowedLocations.interface.type, UserInterfaces.AllowedLocations);

    /* Find out the active location roles by intersecting the roles with the active locations of a user */
    this.addEntityMapping<Entity, RolesEntity>(
      UserInterfaces.Key,
      UserEntities.ActiveLocationRoles,
      async (user, mapper) => {
        let actRoles: Entity[] = [];
        /* Get the active locations of the user */
        const actLocs = await mapper.map<LocationsEntity>(user, UserInterfaces.ActiveLocations);
        if (actLocs.locations.length > 0)
          actRoles = await determineActiveLocationRoles(mapper, user, actLocs.locations);
        return makeRolesEntity(actRoles, UserEntities.ActiveLocationRoles);
      }
    );

    /* Find out the effective roles of a user by extending the regular roles by active location roles */
    this.addEntityMapping<Entity, RolesEntity>(
      UserInterfaces.Key,
      UserInterfaces.EffectiveRoles,
      async (user, mapper) => {
        let roles: Entity[] = [];
        /* Get the normal roles of the user, filter out the location specific roles */
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
        if (rolesEnt) {
          for (const role of rolesEnt.roles) {
            const availLocs = await mapper.tryMap<LocationsEntity>(
              role,
              UserInterfaces.AvailableLocationsForUser // Hier
            );
            if (!availLocs || !availLocs.locations.length) {
              /* Check if a location is permanently assigned to this role */
              const permanentLocation = await mapper.tryMap(
                role,
                UserInterfaces.PermanentLocation,
                { user: user }
              );
              roles.push(permanentLocation ? makeLocationRole(permanentLocation, role) : role);
            }
          }
        }
        /* Add the active location specific roles if applicable */
        const actLocRoles = await mapper.tryMap<RolesEntity>(
          user,
          UserEntities.ActiveLocationRoles
        );
        if (actLocRoles) roles = roles.concat(actLocRoles.roles);
        /* Add effective device dynamic roles, if applicable */
        const deviceDynamicRoles = await mapper.tryMap<RolesEntity>(
          user,
          UserInterfaces.EffectiveDeviceDynamicRoles
        );
        if (deviceDynamicRoles) roles = roles.concat(deviceDynamicRoles.roles);

        // Add Specialty Location Roles
        const actSpecialties = await mapper.tryMap<SpecialtiesEntity>(
          user,
          UserSpecialties.interface.type
        );
        const specLocRoles = await determineActiveSpecialtyLocationRoles(
          mapper,
          actLocRoles?.roles ?? [],
          actSpecialties?.specialties ?? []
        );
        roles = roles.concat(specLocRoles);

        return makeRolesEntity(roles);
      }
    );

    /* Determines whether a plain role or a location role shall be used */
    this.addEntityMapping<LocationRole>(
      UserEntities.LocationRole,
      UserInterfaces.EffectiveRole,
      async (locAndRole, mapper) => {
        const mappedLoc = await mapper.tryMap(
          locAndRole.location,
          LocationInterfaces.LocationOrCluster
        );
        if (!mappedLoc) return undefined;
        const locKey = mapper.makeKey(mappedLoc);
        /* Get the possible locations for the role */
        const availLocs = await mapper.tryMap<LocationsEntity>(
          locAndRole.role,
          UserInterfaces.AvailableLocations
        );
        if (availLocs && availLocs.locations.length > 0) {
          for (const locOrCluster of availLocs.locations) {
            /* Return the role directly if the cluster matchhes */
            if (mapper.makeKey(locOrCluster) === locKey) return locAndRole;
            /* Get the concrete locations related to this cluster */
            const concLocs = await mapper.tryMap<LocationsEntity>(
              locOrCluster,
              LocationEntities.Locations
            );
            if (concLocs)
              for (const concLoc of concLocs.locations)
                if (mapper.makeKey(concLoc) === locKey)
                  return makeLocationRole(locOrCluster, locAndRole.role);
          }
          return undefined;
        }
        return locAndRole.role;
      }
    );

    /* Checks if a user has a role */
    this.addEntityMapping<RoleEntity>(
      UserEntities.Role,
      UserInterfaces.HasRole,
      async (roleEnt, mapper) => {
        /* Get all roles of user */
        const rolesEnt = await mapper.map<RolesEntity>(
          mapper.context(UserContextProperty),
          UserEntities.Roles
        );
        return hasRole(rolesEnt, roleEnt.role) ? roleEnt : undefined;
      },
      { context: UserContextProperty }
    );

    /* Returns all possible roles */
    this.addEntityMapping(UserInterfaces.Key, UserInterfaces.AvailableRoles, async () => {
      return makeRolesEntity(
        RolesList.map((role) => {
          return makeRoleEntity(role);
        })
      );
    });

    /* This determines all possible locations or clusters that the user has specific roles for */
    this.addEntityMapping(
      UserInterfaces.Key,
      SpecialtyInterfaces.AvailableSpecialties,
      async (user, mapper) => {
        let availableSpecialties: Entity[] = [];
        /* Loop all roles and get their respective available specialties */
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserInterfaces.EffectiveRoles);
        for (const role of rolesEnt?.roles ?? [])
          availableSpecialties = availableSpecialties.concat(
            (await mapper.tryMap<SpecialtiesEntity>(role, SpecialtyInterfaces.AvailableSpecialties))
              ?.specialties ?? []
          );
        return makeSpecialties(mapper.eliminateDuplicates(availableSpecialties));
      }
    );

    /* Reverse mappings from user related data such as E-Mail or Accountnames to the user key */
    this.addEntityMapping<EMailAddress>(
      UserEntities.EMail,
      UserInterfaces.Key,
      async (entity, mapper) => {
        const usersEnt = await mapper.map<UsersEntity>(entity, UserEmail.anchorsType);
        if (!usersEnt.users.length)
          Logger.info(
            `User '...' cannot be found; mapping to OdicId not possible`,
            `User '${entity.email}' cannot be found; mapping to OdicId not possible`
          );
        else if (usersEnt.users.length > 1)
          Logger.info(
            `User '...' mapping to OdicId is ambiguous`,
            `User '${entity.email}' mapping to OdicId is ambiguous`
          );
        return usersEnt.users.length === 1 ? usersEnt.users[0] : undefined;
      },
      { weight: 10 }
    );
    this.addEntityMapping<AccountName>(
      UserEntities.AccountName,
      UserInterfaces.Key,
      async (entity, mapper) => {
        const usersEnt = await mapper.map<UsersEntity>(entity, UserAccountName.anchorsType);
        if (!usersEnt.users.length)
          Logger.info(
            `User '...' cannot be found; mapping to OdicId not possible`,
            `User '${entity.accountName}' cannot be found; mapping to OdicId not possible`
          );
        else if (usersEnt.users.length > 1)
          Logger.info(
            `User '...' mapping to OdicId is ambiguous`,
            `User '${entity.accountName}' mapping to OdicId is ambiguous`
          );
        return usersEnt.users.length === 1 ? usersEnt.users[0] : undefined;
      },
      { weight: 10 }
    );
    this.addEntityMapping(
      UserEntities.EmployeeId,
      UserInterfaces.Key,
      async (entity, mapper) => {
        const usersEnt = await mapper.map<UsersEntity>(entity, UserEmployeeId.anchorsType);
        if (!usersEnt.users.length)
          Logger.info(
            `User '...' cannot be found; mapping to OdicId not possible`,
            `User '${mapper.makeKey(entity)}' cannot be found; mapping to OdicId not possible`
          );
        else if (usersEnt.users.length > 1)
          Logger.info(
            `User '...' mapping to OdicId is ambiguous`,
            `User '${mapper.makeKey(entity)}' mapping to OdicId is ambiguous`
          );
        return usersEnt.users.length === 1 ? usersEnt.users[0] : undefined;
      },
      { weight: 10 }
    );

    /* Define the base for gathering the list view roots  */
    this.addEntityMapping(
      UserInterfaces.Key,
      VisualEntities.ListRoots,
      async () => {
        return makeListViewRoots([]);
      },
      { weight: 10000000 }
    );

    /* Mapping list roots to list view infos */
    this.addEntityMapping(UserInterfaces.Key, VisualEntities.ListInfos, async (user, mapper) => {
      /* Get list roots */
      const rootsEnt = await mapper.tryMap<ListViewRoots>(user, VisualEntities.ListRoots);
      if (!rootsEnt) return undefined;
      return mapper.tryMap(rootsEnt, VisualEntities.ListInfos, { user: user });
    });
    this.addEntityMapping<ListViewRoots>(
      VisualEntities.ListRoots,
      VisualEntities.ListInfos,
      async (rootsEnt, mapper) => {
        return makeListViewInfos(
          await arrayTransformAsync(rootsEnt ? rootsEnt.roots : [], async (root) => {
            /* Check first if a specific list view info is provided */
            const listViewInfo = await mapper.tryMap<ListViewInfo>(root, VisualEntities.ListInfo);
            if (listViewInfo) return listViewInfo;
            const list = await mapper.tryMap<ListView>(root, VisualEntities.List);
            return list
              ? makeListViewInfo(
                  ListViewsStatePath + '/' + replaceChar(mapper.makeKey(root), '/', '_'),
                  await extractTextOrDefault(mapper, list.title, ''),
                  list.items.filter((item) => {
                    return item.entityType !== ListHeaderType;
                  }).length
                )
              : undefined;
          })
        );
      }
    );

    // Calculate the last-connect timestamp based on devices the user used
    this.addEntityMapping(UserInterfaces.Key, UserInterfaces.LastConnect, async (user, mapper) => {
      const devices = await mapper.map<DevicesEntity>(
        user,
        DeviceLastUser.anchorsInterface?.type || ''
      );
      const lastConnects = await arrayTransformAsync(
        (
          await devices
        ).devices,
        async (dev) =>
          (
            await mapper.tryMap<TimestampEntity>(dev, DeviceLastConnect.interface.type)
          )?.timestamp
      );
      if (lastConnects.length > 0) {
        lastConnects.sort();
        return makeTimestampEntity(lastConnects[lastConnects.length - 1]);
      }
      return undefined;
    });
    // Calculate the OnlineStatus based on user's devices last-connect/last-disconnect timestamps
    this.addEntityMapping(UserInterfaces.Key, UserEntities.OnlineStatus, async (user, mapper) => {
      const devicesEnt = await mapper.map<DevicesEntity>(
        user,
        DeviceLastUser.anchorsInterface?.type || ''
      );
      const connectData = await arrayTransformAsync(devicesEnt.devices, async (dev) => {
        const lastConnect =
          (await mapper.tryMap<TimestampEntity>(dev, DeviceLastConnect.interface.type))
            ?.timestamp || 0;
        const lastDisconnect =
          (await mapper.tryMap<TimestampEntity>(dev, DeviceLastDisconnect.interface.type))
            ?.timestamp || 0;
        return {
          lastConnect: lastConnect,
          lastDisconnect: lastDisconnect,
          offline: lastDisconnect >= lastConnect,
        };
      });
      let onlineStatus: OnlineStatus = OnlineStatus.NeverSeen;
      if (connectData.length > 0) {
        connectData.sort((a, b) => a.lastConnect - b.lastConnect);
        for (let i = connectData.length - 1; i >= 0; i--) {
          const onlineSince = Date.now() - connectData[i].lastConnect;
          if (onlineSince < addHours(HoursUntilOffline) && !connectData[i].offline) {
            onlineStatus = OnlineStatus.Online;
            break; // It will not be better than online
          } else if (onlineSince < addHours(HoursUntilOffline) && connectData[i].offline) {
            onlineStatus = OnlineStatus.Away;
          } else if (
            onlineSince >= addHours(HoursUntilOffline) &&
            onlineStatus === OnlineStatus.NeverSeen
          ) {
            onlineStatus = OnlineStatus.Offline;
          }
        }
        return makeOnlineStatus(onlineStatus);
      }
      return makeOnlineStatus(OnlineStatus.NeverSeen);
    });

    /* Performs a user search */
    this.addEntityMapping<TextEntity>(
      CoreEntities.Text,
      UserInterfaces.SearchUsers,
      async (textEnt, mapper) => {
        const user = mapper.tryContext(UserContextProperty);
        const searchCluster = user && (await mapper.tryMap(user, UserEntities.SearchUserCluster));
        const userSearch = this.userSearch.get(
          searchCluster ? mapper.makeKey(searchCluster) : '_default_'
        );
        if (!userSearch) {
          Logger.warn([
            'no UserSearch for user found',
            searchCluster || 'searchcluster for user not defined',
          ]);
          return undefined;
        }
        return makeAddresses(userSearch.search(textEnt.text, 10, mapper));
      },
      { context: UserContextProperty }
    );

    /* By default all testers are allowed to administer the server */
    this.addEntityMapping(
      UserInterfaces.Key,
      UserInterfaces.ServerAdministrator,
      async (user, mapper) => {
        const rolesEnt = await mapper.tryMap<RolesEntity>(user, UserEntities.Roles);
        return rolesEnt && hasRole(rolesEnt, Roles.Tester) ? user : undefined;
      },
      { weight: 2 }
    );

    /* Extracts the initiator of a user call, if found */
    this.addEntityMapping<UserCallInitiatorEntity>(
      UserEntities.UserCallInitiator,
      CoreInterfaces.Result,
      async (uci, mapper) => {
        /* Get called users and the user to check for */
        const usersEnt = await mapper.tryMap<CalledUsersEntity>(
          uci.users,
          UserEntities.CalledUsers
        );
        if (usersEnt)
          for (const ui of usersEnt.users)
            if (await mapper.isEquivalent(ui.user, uci.called)) return ui.initiator;
        return undefined;
      }
    );

    // Users are allowed to change the own's device permanent settings (per default)
    this.addEntityMapping(
      UserInterfaces.Key,
      UserInterfaces.PermanentDeviceSettingsEditMode,
      async () => {
        return makePermanentDeviceSettingsEditMode(PermanentDeviceSettingsEditMode.User);
      },
      { weight: 2 }
    );

    /** Returns the available default locations which can be set to the device. Those are:
     *  - either the available location for a user in non-admin mode
     *  - or the available locations for the fixed device role in the context of the admin (logged on user)
     */
    this.addEntityMapping(
      DeviceInterfaces.Key,
      UserInterfaces.AvailableDeviceDefaultLocations,
      async (device, mapper) => {
        const user = await mapper.tryMap(device, DeviceCurrentUser.interface.type);
        const adminMode =
          (
            await mapper.tryMap<PermanentDeviceSettingsEditModeEntity>(
              user,
              UserInterfaces.PermanentDeviceSettingsEditMode
            )
          )?.mode === PermanentDeviceSettingsEditMode.Admin;

        // Return the users available roles in case of non-admin mode
        if (!adminMode) return mapper.tryMap(user, UserInterfaces.AvailableLocations);

        // Otherwise return the possible locations for the current fixed roles of the device
        const fixedRoles = await mapper.tryMap<RolesEntity>(
          device,
          DeviceFixedRoles.interface.type
        );
        const availableLocations = await arrayConcatAsync(fixedRoles?.roles ?? [], async (r) => {
          const locs = await mapper.tryMap<LocationsEntity>(
            r,
            UserInterfaces.AvailableLocationsForUser
          );
          return locs?.locations ?? [];
        });
        return LocationAnchor.makeContainer(availableLocations);
      }
    );
  }

  /** Adds an UserSearch specific to an searchCluster
   *  Note: There must be a mapping from the user performing the search to UserInterfaces.SearchUserCluster.
   */
  addUserSearch(userSearchId: SearchUserCluster, userSearch: UserSearch) {
    this.userSearch.set(this.makeKey(userSearchId), userSearch);
  }

  /** Called when the domain gets activated */
  override activate(db: DatabaseInterface, mapper: DomainInterface) {
    if (this.userSearch.size === 0) this.userSearch.set('_default_', new UserSearch());
    this.userSearch.forEach((us) => {
      us.activate(db, mapper);
    });
  }

  override async close(): Promise<void> {
    await arrayTransformAsync(Array.from(this.userSearch.values()), async (us) => await us.close());
    await this.queue.close();
    await super.close();
  }

  static async constructRoles(
    mapper: DomainInterface,
    loc: Entity,
    templateRoles: LocationRoleTemplate[]
  ): Promise<Entity[]> {
    return arrayTransformAsync(templateRoles, async (roleTemplate) => {
      return this.constructRole(mapper, loc, roleTemplate);
    });
  }

  static async constructRole(
    mapper: DomainInterface,
    loc: Entity,
    roleTemplate: LocationRoleTemplate
  ): Promise<Entity | undefined> {
    if (typeof roleTemplate === 'string')
      return await mapper.tryMap(
        makeLocationRole(loc, makeRoleEntity(roleTemplate)),
        UserInterfaces.EffectiveRole
      );
    else {
      const roleLoc = await mapper.tryMap(
        makeLocationFunction(loc, makeRoomFunction(roleTemplate[1])),
        LocationInterfaces.Key
      );
      return roleLoc ? makeLocationRole(roleLoc, makeRoleEntity(roleTemplate[0])) : undefined;
    }
  }

  static async intersectRoles(
    mapper: DomainInterface,
    rolesEnt: RolesEntity,
    templateRoles: LocationRoleTemplate[]
  ): Promise<[Entity, LocationRoleTemplate][]> {
    return arrayConcatAsync(rolesEnt.roles, async (roleEnt) => {
      let role: string, location: Entity;
      if (roleEnt.entityType === UserEntities.Role) role = (roleEnt as RoleEntity).role;
      else if (roleEnt.entityType === UserEntities.LocationRole) {
        const locRole = roleEnt as LocationRole;
        role = (locRole.role as RoleEntity).role;
        location = locRole.location;
      } else return [];
      return await arrayTransformAsync(templateRoles, async (templ) => {
        /* Check if a pure role is specified */
        if (typeof templ === 'string') return templ === role ? [roleEnt, templ] : undefined;
        /* If a location function is specified, check if this is provided and compare if applicable */
        if (templ[0] !== role) return undefined;
        if (!location) return [roleEnt, templ];
        const funcEnt = await mapper.tryMap<RoomFunction>(
          location,
          LocationFunction.interface.type
        );
        let func = '';
        if (!funcEnt) {
          const locFuncEnt = await mapper.tryMap<LocationClusterFunctionEntity>(
            location,
            LocationClusterRelatedFunction.interface.type
          );
          if (locFuncEnt) func = locFuncEnt.function;
        } else func = funcEnt.func;
        return func.length > 0 && func === templ[1] ? [roleEnt, templ] : undefined;
      });
    });
  }

  /** Creates a user entity from user information */
  static createUserEntity(userInfo: UserInfo): Entity {
    return userInfo.iss !== AuthSystem.Demo
      ? userInfo.iss === AuthSystem.Kiosk
        ? makeKioskUser(userInfo.sub)
        : makeOidcId(userInfo.sub, userInfo.iss)
      : makeTestUser(userInfo.sub);
  }

  /** Updates user roles based on role options */
  static async updateUserRoles(
    domain: Domain,
    user: Entity,
    groups: Entity[],
    fixed?: string
  ): Promise<RolesEntity | undefined> {
    /* Determine the current user roles */
    const rolesEnt = await domain.tryMap<RolesEntity>(user, UserRoles.interface.type);
    const origRoles = rolesEnt ? rolesEnt.roles : [];
    let roles: Entity[] = [];
    /* Determine the roles options associated with the groups */
    const rolesOptionsList = await arrayTransformAsync(groups, async (group) => {
      return await domain.tryMap(group, UserEntities.RolesOptions);
    });
    /* Iterate the roles options provided with the groups */
    for (const rolesOptionsEl of rolesOptionsList) {
      const rolesOptions = rolesOptionsEl as RolesOptions;
      /* Check if a pot. fixed roles option is contained */
      let fixedFound = false;
      for (const rolesOption of rolesOptions.options)
        if (domain.makeKey(rolesOption) === fixed) {
          roles = roles.concat((rolesOption as RolesEntity).roles);
          fixedFound = true;
          break;
        }
      /* Check if any option is consistent with the existing roles, check back to front - choose first option if none are found */
      if (!fixedFound)
        for (let i = rolesOptions.options.length - 1; i >= 0; i--) {
          const rolesOptEnt = rolesOptions.options[i] as RolesEntity;
          if (
            i === 0 ||
            (rolesEnt &&
              intersectRoles(rolesEnt, extractRoles(rolesOptEnt)).length ===
                rolesOptEnt.roles.length)
          ) {
            roles = roles.concat(rolesOptEnt.roles);
            break;
          }
        }
    }
    /* Check if the tester role is provided */
    if (
      roles.find((role) => {
        return role.entityType === UserEntities.Role && (role as RoleEntity).role === Roles.Tester;
      })
    )
      /* Retain the original roles, make sure all mapped roles are part of it */
      roles = origRoles.concat(roles);
    /* Check if there is any change */
    roles = domain.eliminateDuplicates(roles);
    if (!rolesEnt || !domain.equalSet(roles, origRoles)) return makeRolesEntity(roles);
    return undefined;
  }

  // Sets the allowed locations of a user
  static async updateUserAllowedLocations(
    domain: Domain,
    user: Entity,
    locations: Entity[] | undefined
  ) {
    const allowedLocsOld = await domain.tryMap<LocationsEntity>(
      user,
      UserAllowedLocations.interface.type
    );
    if (
      (allowedLocsOld === undefined && locations === undefined) ||
      (locations !== undefined &&
        allowedLocsOld != undefined &&
        domain.equalSet(locations, allowedLocsOld.locations))
    ) {
      Logger.debug('updateUserAllowedLocations - nothing todo');
      return;
    }

    if (locations === undefined) {
      await domain.invoke(UserAllowedLocations.reset(user));
    } else {
      // Set the allowed locations
      await domain.invoke(UserAllowedLocations.set(user, LocationAnchor.makeContainer(locations)));

      // Adjust the active locations, remove those that are not allowed anymore
      const actLocs =
        (await domain.tryMap<LocationsEntity>(user, UserActiveLocations.interface.type))
          ?.locations || [];
      const locationsToRemove: Entity[] = [];
      const allowedLocationsSet = domain.makeKeySet(locations);
      for (const loc of actLocs)
        if (!allowedLocationsSet.has(domain.makeKey(loc))) locationsToRemove.push(loc);
      for (const loc of locationsToRemove)
        await domain.invoke(UserActiveLocations.remove(user, loc), user);

      // If there is just one allowed location, add it as active location if not yet set
      if (locations.length === 1 && !domain.contains(actLocs, locations[0])) {
        await domain.invoke(UserActiveLocations.add(user, locations[0]));
      }
    }
  }

  /** Updates the user related information based on the provided user information */
  static async updateUser(domain: Domain, user: Entity, userInfo: UserInfo) {
    /* Convert std set of user information */
    const oidcClaims = userInfo.originalClaims
      ? makeOidcClaims(userInfo.originalClaims)
      : undefined;
    const userData: RelatedDataSet = [];
    if (userInfo.email) userData.push([UserEmail, makeEMailAddress(userInfo.email)]);
    if (userInfo.preferred_username)
      userData.push([UserAccountName, makeAccountName(userInfo.preferred_username)]);
    else if (userInfo.name) userData.push([UserAccountName, makeAccountName(userInfo.name)]);
    if (userInfo.given_name || userInfo.family_name)
      userData.push([
        UserPersonName,
        createPersonName(userInfo.given_name || '', userInfo.family_name || ''),
      ]);
    else if (userInfo.name) {
      const names = userInfo.name.split(' ');
      const lastName = names[names.length - 1];
      const firstNames = names.slice(0, -1).join(' ');
      userData.push([UserPersonName, createPersonName(firstNames || '', lastName || '')]);
    }
    /* Convert info pot. found in std claims */
    if (oidcClaims) {
      /* Phone number */
      const phoneNumber = await domain.tryMap<PhoneNumber>(oidcClaims, DeviceEntities.PhoneNumber);
      if (phoneNumber) userData.push([UserPhoneNumbers, makePhoneNumbers(phoneNumber)]);
      /* EmployeeId */
      const empId = await domain.tryMap(oidcClaims, UserEntities.EmployeeId);
      if (empId) userData.push([UserEmployeeId, empId]);
      /* Gender */
      const genderEnt = await domain.tryMap(oidcClaims, PersonEntities.PersonSex);
      if (genderEnt) userData.push([UserSex, genderEnt]);
      /* Specialties */
      const specialtiesEnt = await domain.tryMap(oidcClaims, SpecialtyEntities.Specialties);
      if (specialtiesEnt) userData.push([UserSpecialties, specialtiesEnt]);
      // Update the UserRoles according to the OIDC claims
      const groupsEnt = await domain.tryMap<UserGroupsEntity>(oidcClaims, UserEntities.Groups);
      if (groupsEnt) {
        const userRoles = await UserDomain.updateUserRoles(domain, user, groupsEnt.groups);
        if (userRoles) userData.push([UserRoles, userRoles]);
      }
      // Update the UserRoles according to the OIDC claims
      const allowedLocationsEnt = await domain.tryMap<LocationsEntity>(
        oidcClaims,
        UserInterfaces.AllowedLocations
      );
      await UserDomain.updateUserAllowedLocations(domain, user, allowedLocationsEnt?.locations);
    }
    /* Invoke */
    await domain.setChangedRelatedData(UserAnchor, user, userData);
  }

  /** Creates a user entity from user information and updates it */
  static async createAndUpdateUser(domain: Domain, userInfo: UserInfo) {
    return UserDomain.updateUser(domain, UserDomain.createUserEntity(userInfo), userInfo);
  }

  /** Get gender context for mapping */
  static async getGenderContext(mapper: DomainInterface, user: Entity): Promise<EntityRecord> {
    const context: EntityRecord = {};
    const sexEnt = await mapper.tryMap<PersonSexEntity>(user, UserSex.interface.type);
    if (sexEnt && (sexEnt.sex === PersonSex.Male || sexEnt.sex === PersonSex.Female))
      context['gender'] =
        sexEnt.sex === PersonSex.Male ? makeGender(Gender.Male) : makeGender(Gender.Female);
    return context;
  }

  readonly barcodeHandlers = new BarcodeHandlerRegistry();
  private userSearch = new Map<string, UserSearch>();
  readonly queue = new FunctionQueue();
}

export async function determineActiveLocationRoles(
  mapper: DomainInterface,
  user: Entity,
  actLocs: Entity[]
): Promise<Entity[]> {
  const actRoles: Entity[] = [];
  /* Build a map of location or cluster keys to roles specific to that */
  const locToRoleMap = new Map<string, Entity[]>();
  const userRoles = await mapper.map<RolesEntity>(user, UserEntities.Roles);
  for (const role of userRoles.roles) {
    /* Get the available locations or clusters for this role */
    const availLocs = await mapper.map<LocationsEntity>(
      role,
      UserInterfaces.AvailableLocationsForUser // Hier
    );
    for (const loc of availLocs.locations) {
      const locKey = mapper.makeKey(loc);
      const entry = locToRoleMap.get(locKey);
      if (entry) entry.push(role);
      else locToRoleMap.set(locKey, [role]);
    }
  }
  /* Loop all active locations and check if they are specific to one of the roles */
  for (const actLoc of actLocs) {
    const specRoles = locToRoleMap.get(mapper.makeKey(actLoc));
    if (specRoles)
      for (const specRole of specRoles) actRoles.push(makeLocationRole(actLoc, specRole));
  }
  return actRoles;
}

export async function determineActiveSpecialtyLocationRoles(
  mapper: DomainInterface,
  actLocs: Entity[],
  actSpecialities: Entity[]
): Promise<Entity[]> {
  // Add roles w/ specialty if
  // a) role supports it - LocationRole => AvailableSpecialties
  // b) location has it - LocationRole => Location => Speciality
  // c) actSpeciality contains it (i.e. the user has it)
  const actSpecSet = mapper.makeKeySet(actSpecialities);
  const specLocRoles: SpecialtyLocationRole[] = [];
  for (const lr of actLocs) {
    // "Cast" to LocationRole
    const locRole = await mapper.map<LocationRole>(lr, UserEntities.LocationRole);
    // Get Available Specialties
    const availSpec = await mapper.tryMap<SpecialtiesEntity>(
      lr,
      SpecialtyInterfaces.AvailableSpecialties
    );
    // Get Specialties from Location
    const locSpec = await mapper.tryMap<SpecialtiesEntity>(
      locRole.location,
      SpecialtyEntities.Specialties
    );
    const locSpecSet = mapper.makeKeySet(locSpec?.specialties || []);

    for (const spec of availSpec?.specialties || []) {
      if (actSpecSet.has(mapper.makeKey(spec)) && locSpecSet.has(mapper.makeKey(spec)))
        specLocRoles.push(makeSpecialtyLocationRole(spec, locRole.location, locRole.role));
    }
  }
  return specLocRoles;
}
