import styles from './search-input.module.css';
import { ChangeEvent, FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { IconButton } from '@mui/material';

/* eslint-disable-next-line */
export interface SearchInputProps {
  value: string;
  setValue: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({ value, setValue }) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onClear = () => setValue('');

  return (
    <div className={styles['container']}>
      <div className={styles['search-icon']}>
        <SearchIcon />
      </div>
      <input
        className={styles['search-input']}
        placeholder="Suche..."
        value={value}
        onChange={onChange}
        type="text"
      />
      {value.length > 0 && (
        <div className={styles['clear-icon']}>
          <IconButton size="small" onClick={onClear}>
            <ClearRoundedIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
