import styles from './current-timer.module.css';
import { formatTime, isToday } from '../orworkflow-overview-dashboard/utils';
import { ZIndex } from '@sqior/react/utils';
import React, { FC, useEffect, useRef } from 'react';
import { BaseIntervalAllowed, MENU_WIDTH } from '../orworkflow-overview-dashboard/constants';
import { DemoModePath } from '@sqior/viewmodels/app';
import { useDynamicState, useTimer } from '@sqior/react/state';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { useMousePosition } from '@sqior/react/hooks';
import { addMilliseconds } from '@sqior/js/data';

export interface CurrentTimerProps {
  currentTime: number;
  selectedDate: {
    day: number;
    month: number;
    year: number;
  };
  interval: BaseIntervalAllowed;
  currentPos: string;
  timeCalc?: TimePositionCalculator;
  contentHeight: number;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  dragging: boolean;
  setDragging: (dragging: boolean) => void;
  tablet: boolean;
}

const getCursorMode = (demoMode: boolean, isGrabbing: boolean): string => {
  if (!demoMode) return 'none';
  if (isGrabbing) return 'grabbing';
  return 'grab';
};

export const CurrentTimer: FC<CurrentTimerProps> = ({
  currentPos,
  currentTime,
  selectedDate,
  interval,
  timeCalc,
  contentHeight,
  scrollContainerRef,
  dragging,
  setDragging,
  tablet,
}) => {
  const { timer, setTimerValue } = useTimer();
  const demoMode = useDynamicState<boolean>(DemoModePath, false);
  const { dy } = useMousePosition({ includeTouch: true });

  const ref = useRef<HTMLDivElement | null>(null);

  const onMouseDown = () => {
    setDragging(true);
  };

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setDragging(true);
  };

  const handleMovement = (deltaY: number) => {
    if (!timeCalc) return;
    const { start, end } = timeCalc;
    const movementY = deltaY; // in px
    const containerHeight = contentHeight;
    const allMilliseconds = end - start;

    const pxPerMillisecond = Math.ceil(allMilliseconds / containerHeight);
    const millisecondsPerMovementY = Math.ceil(pxPerMillisecond * movementY);

    setTimerValue(addMilliseconds(timer.now, millisecondsPerMovementY));
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (!dragging) return;
    if (dy === 0) return;
    tablet && handleMovement(dy);
  }, [dragging, dy]);

  useEffect(() => {
    // add listener for mouse click
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!demoMode) return;
    if (dragging) document.body.style.cursor = 'grabbing';
    else document.body.style.cursor = 'default';
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [demoMode, dragging]);

  useEffect(() => {
    if (!scrollContainerRef?.current) return;
    const disableScroll = (e: Event) => {
      e.preventDefault();
    };
    if (dragging) {
      scrollContainerRef.current.addEventListener('touchmove', disableScroll, { passive: false });
    } else {
      scrollContainerRef.current.removeEventListener('touchmove', disableScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('touchmove', disableScroll);
      }
    };
  }, [dragging]);

  return (
    <>
      {isToday({
        day: selectedDate.day,
        month: selectedDate.month,
        year: selectedDate.year,
        currentTime,
        interval,
      }) && (
        <div
          ref={ref}
          className={styles['current-time']}
          style={{
            top: currentPos,
            width: MENU_WIDTH,
            zIndex: ZIndex.DashboardTargetIcon,
            cursor: getCursorMode(demoMode, dragging),
          }}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onMouseUp}
        >
          {formatTime(new Date(currentTime).getHours(), new Date(currentTime).getMinutes())}
        </div>
      )}
    </>
  );
};

export default CurrentTimer;
