import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { DeviceEntities } from './device-definitions';

/** Device functions */

export enum DeviceFunction {
  IntraoperativeMonitoring = 'IntraoperativeMonitoring',
}
export type DeviceFunctionEntity = EntityHeader & { func: string };
export const DeviceFunctionModel: EntityModel = {
  type: DeviceEntities.Function,
  props: ['func'],
};
export function makeDeviceFunction(func: DeviceFunction): DeviceFunctionEntity {
  return { entityType: DeviceFunctionModel.type, func };
}
