import { now } from '@sqior/js/data';
import { EntityHeader } from '@sqior/js/entity';
import { EntityModel } from './entity';

export enum DebugEntities {
  Watchdog = 'Watchdog',
}
export type Watchdog = EntityHeader & {
  timestamp: number;
};
export const WatchdogModel: EntityModel = { type: DebugEntities.Watchdog, props: ['timestamp'] };
export function createWatchdog(): Watchdog {
  return { entityType: DebugEntities.Watchdog, timestamp: now() };
}
