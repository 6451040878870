import { factoryGetProp, FactoryProps } from '@sqior/react/factory';
import styles from './address-selection-control.module.css';
import {
  ContainerWithShadow,
  HighlightButton,
  InputControl,
  ProfilePicture,
  SvgIconPerson,
  SVGRoles,
  useTextResources,
} from '@sqior/react/uibase';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { isFinal, StreamOperation } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import { AddressSearch, AddressSearchPath } from '@sqior/viewmodels/user';
import { useDynamicState } from '@sqior/react/state';
import { SelectionAreaProps } from '@sqior/react/uiselection';
import { Entity } from '@sqior/js/entity';
import { Avatar, Badge, Checkbox, IconButton } from '@mui/material';
import { ConfigContext } from '@sqior/react/utils';
import { motion } from 'framer-motion';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { AddressInfoVM, AddressInfoVMType } from '@sqior/viewmodels/communication';

enum StartGroupChatState {
  selectChat = 'selectChat',
  selectTitle = 'selectTitle',
}

export type AddressSelectionControlProps = FactoryProps & SelectionAreaProps;

export function AddressSelectionControl(props: AddressSelectionControlProps) {
  const configContext = useContext(ConfigContext);
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const multiSelect = factoryGetProp<boolean>('multiSelect', props);
  const simpleSelect = factoryGetProp<boolean>('simpleSelect', props);
  const initTitle = factoryGetProp<boolean>('title', props);

  const [search, setSearch] = useState('');
  const [chatPartners, setChatPartners] = useState<AddressInfoVM[]>([]);
  const [title, setTitle] = useState<string>('');
  const [groupChatState, setGroupChatState] = useState<StartGroupChatState | undefined>(
    multiSelect ? StartGroupChatState.selectChat : undefined
  );

  /* View model containing search results */
  const addresses = useDynamicState<AddressInfoVM[]>(AddressSearchPath, []);
  /* Search operation that is supposed to be stopped if this gets destructed */
  const searchOp = useRef<StreamOperation | undefined>();
  useEffect(() => {
    return () => {
      searchOp.current?.close();
    };
  }, []);

  const handleSingleSelection = (address?: Entity) => {
    if (!address) return;
    if (props.onSelection === undefined) return;
    props.onSelection(address);
  };

  const handleMultiSelection = (item: AddressInfoVM) => {
    setChatPartners((prev) => {
      const found = prev.find((_) => _.key === item.key);
      if (found) {
        if (prev.length === 1) setGroupChatState(StartGroupChatState.selectChat);
        return prev.filter((_) => _.key !== item.key);
      }
      return [...prev, item];
    });
  };

  const handleStartGroupChat = () => {
    if (props.onGroupChatSelection === undefined) return;
    props.onGroupChatSelection(chatPartners, title);
  };

  const stringName = (name: string) => {
    const nameSplit = name.split(' ');
    return `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}`;
  };

  const handleOnChangeTitle = (ev: ChangeEvent<HTMLInputElement>) => {
    setTitle(ev.currentTarget.value);
  };

  const getGroupChatButtonText = () => {
    switch (groupChatState) {
      case StartGroupChatState.selectChat:
        return '';
      case StartGroupChatState.selectTitle:
        return textDict.get('button_start_group_chat');
      default:
        return undefined;
    }
  };

  const handleSelectTitle = () => setGroupChatState(StartGroupChatState.selectTitle);

  const getGroupChatTitle = () => {
    switch (groupChatState) {
      case StartGroupChatState.selectChat:
        return initTitle;
      case StartGroupChatState.selectTitle:
        return textDict.get('group_chat_title_selection');
      default:
        return initTitle;
    }
  };

  const handleOnClickFab = () => {
    if (simpleSelect) return handleStartGroupChat();
    if (chatPartners.length === 0) return;
    if (groupChatState === StartGroupChatState.selectChat) handleSelectTitle();
    else handleStartGroupChat();
  };

  const handleSearchInputChange = (value: string) => {
    setSearch(value);
    /* Send search parameter to server */
    if (!searchOp.current || isFinal(searchOp.current.state))
      searchOp.current = dispatcher.start(AddressSearch(value)) as StreamOperation;
    else searchOp.current.send(value);
  };

  const getFabMultiSelectIcon = () => {
    if (simpleSelect) return <AddRoundedIcon style={{ fontSize: 14 }} />;
    return <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />;
  };

  const getAvatarIcon = (item: AddressInfoVM) => {
    if (item.profilePicture?.url) {
      return <ProfilePicture className={styles['profile']} url={item.profilePicture.url} />;
    }

    if (item.type === AddressInfoVMType.Role) {
      return (
        <div className={styles['icon-role-container']}>
          <SVGRoles className={styles['icon-role']} />
        </div>
      );
    }

    return <SvgIconPerson className={styles['icon']} />;
  };

  const getMultiSelectButtonTitle = () => {
    switch (groupChatState) {
      case StartGroupChatState.selectChat:
        return 'Weiter';
      case StartGroupChatState.selectTitle:
        return 'Erstellen';
      default:
        return null;
    }
  };

  return (
    <div className={styles['main-container']}>
      {multiSelect && (
        <div className={styles['header']}>
          <HighlightButton
            secondary
            additionalClassName={styles['header-button']}
            onClick={() => props.onClose?.()}
          >
            Abbrechen
          </HighlightButton>
          <HighlightButton
            disabled={chatPartners.length === 0}
            additionalClassName={styles['header-button']}
            onClick={handleOnClickFab}
          >
            {getMultiSelectButtonTitle()}
          </HighlightButton>
        </div>
      )}

      <p className={styles['title']}>{getGroupChatTitle()}</p>
      {chatPartners.length > 0 && (
        <motion.div
          className={styles['chat-partners-container-motion']}
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: chatPartners.length > 0 ? 1 : 0,
            height: chatPartners.length > 0 ? 'auto' : 0,
          }}
        >
          {chatPartners.map((item) => (
            <div key={item.key} className={styles['chat-partner-avatar']}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  <IconButton
                    size="small"
                    style={{ backgroundColor: '#090f23', border: '2px solid #1cade4' }}
                    onClick={() => {
                      handleMultiSelection(item);
                    }}
                  >
                    <CloseRoundedIcon fontSize="small" color="primary" />
                  </IconButton>
                }
              >
                <Avatar
                  alt={item.name}
                  src={
                    item.profilePicture
                      ? configContext.getEndpoint(item.profilePicture.url).toString()
                      : ''
                  }
                  onClick={() => {
                    setChatPartners((prev) => prev.filter((_) => _.id !== item.id));
                  }}
                  style={{ cursor: 'pointer' }}
                  sx={{ width: 56, height: 56, backgroundColor: '#26344d', color: 'white' }}
                >
                  {stringName(item.name)}
                </Avatar>
              </Badge>
              <p lang={textDict.language()} className={styles['chat-partner-name']}>
                {item.name}
              </p>
            </div>
          ))}
        </motion.div>
      )}

      {multiSelect && groupChatState === StartGroupChatState.selectTitle && (
        <div className={styles['name-input-container']}>
          <InputControl placeholder="Name" value={title} onChange={handleOnChangeTitle} />
          <p className={styles['group_chat_title_selection_description']}>
            {textDict.get('group_chat_title_selection_description')}
          </p>
        </div>
      )}

      {groupChatState !== StartGroupChatState.selectTitle && (
        <div className={styles['container']}>
          <div className={styles['search-container']}>
            <InputControl
              className={styles['input']}
              value={search}
              placeholder={textDict.get('input_search_chat_partner')}
              autoFocus={true}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                handleSearchInputChange(ev.currentTarget.value);
              }}
            />
            {search.length > 0 && (
              <IconButton
                onClick={() => {
                  handleSearchInputChange('');
                }}
                style={{
                  position: 'absolute',
                  right: 0,
                }}
              >
                <CloseRoundedIcon fontSize="small" color="primary" />
              </IconButton>
            )}
          </div>

          <ContainerWithShadow className={styles['result-container']}>
            <div className={styles['inner-result-container']}>
              {addresses.map((item) => {
                return (
                  <div
                    key={item.key}
                    className={styles['result']}
                    onClick={() => {
                      multiSelect
                        ? handleMultiSelection(item)
                        : handleSingleSelection(item.chatAddress);
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      {multiSelect && (
                        <Checkbox checked={Boolean(chatPartners.find((_) => _.key === item.key))} />
                      )}
                      {getAvatarIcon(item)}
                    </div>

                    <div className={styles['result-text-container']}>
                      <div className={styles['name']}>{item.name}</div>
                      <div className={styles['role']}>{item.subname}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ContainerWithShadow>
        </div>
      )}
    </div>
  );
}

export default AddressSelectionControl;
