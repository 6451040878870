import { useEffect, useRef, MutableRefObject } from 'react';

/** Similar to useRef() - however, it supports destruction if the component owning this reference gets deleted
 *
 * @param initializer A function called to create the object to reference
 * @param destructor  A fuction called to destroy the referenced object
 */
export function useRefWithDestructor<Type>(
  initializer: () => Type,
  destructor?: (value: Type) => void
): MutableRefObject<Type> {
  const ref = useRef<Type>(initializer());

  // Register a destructor via useEffect() if one is desired
  // (Note: return value of useEffect-function is the clean-up code of the effect, thus destructor)
  useEffect(() => {
    const object = ref.current;
    return () => {
      if (destructor) destructor(object);
    };
  }, []);

  return ref;
}
