import styles from './chat-settings.module.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { IconButton } from '@mui/material';
import {
  ButtonClose,
  HighlightButton,
  SqiorIOSConfirmDialog,
  SvgIconPerson,
  SVGRoles,
} from '@sqior/react/uibase';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  AddAddresses,
  AddressInfoVM,
  AddressInfoVMType,
  ChatDetailsVM,
  ChatVMType,
  DeleteAddresses,
  DeleteChat,
} from '@sqior/viewmodels/communication';
import AddressSelectionControl from '../address-selection-control/address-selection-control';
import { useDynamicStateRaw } from '@sqior/react/state';
import { OperationContext } from '@sqior/react/operation';
import { ValueObject } from '@sqior/js/data';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ConfigContext } from '@sqior/react/utils';

type UIAddresses = {
  self: AddressInfoVM | undefined;
  others: AddressInfoVM[];
};

export const getStructuredAddresses = (chatDetails?: ChatDetailsVM): UIAddresses => {
  if (chatDetails === undefined) return { self: undefined, others: [] };
  const addresses = chatDetails.addresses ?? [];

  const getSelfAddress = () => {
    if (chatDetails.ownAddressIndex === undefined) return undefined;
    return addresses[chatDetails.ownAddressIndex];
  };

  return {
    self: getSelfAddress(),
    others: addresses.filter((_, index) => index !== chatDetails.ownAddressIndex),
  };
};

interface ConfirmData {
  title: string;
  confirm: string;
  cancel: string;
  data: unknown;
  operation?: 'delete' | 'remove' | 'leave';
}

interface Props {
  closeChat?: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChatSettings: FC<Props> = ({ closeChat, open, setOpen }) => {
  const configContext = useContext(ConfigContext);
  const dispatcher = useContext(OperationContext);
  const chatDetails = useDynamicStateRaw<ChatDetailsVM>('current-chat/selected-details');
  const [addParticipant, setAddParticipant] = useState(false);

  const [confirmData, setConfirmData] = useState<ConfirmData | null>(null);

  const addresses = getStructuredAddresses(chatDetails);
  const chatId = chatDetails?.chatVM.id;

  const toggleSettings = () => setOpen((prev) => !prev);
  const toggleAddParticipant = () => setAddParticipant((prev) => !prev);

  const onChatLeave = () => {
    if (!chatId) return console.error('ChatId not defined');
    const selfAddress = addresses.self?.chatAddress as ValueObject;
    if (!selfAddress) return console.error('Self address not defined');

    dispatcher.start(DeleteAddresses(chatId, [selfAddress as ValueObject]));
    closeChat?.();
  };

  const onChatDelete = () => {
    if (!chatId) return console.error('ChatId not defined');
    dispatcher.start(DeleteChat(chatId));
    closeChat?.();
  };

  const onRemoveParticipant = (item: AddressInfoVM) => {
    if (!chatId) return console.error('ChatId not defined');
    dispatcher.start(DeleteAddresses(chatId, [item.chatAddress as ValueObject]));
  };

  const onAddParticipants = (items?: AddressInfoVM[]) => {
    if (items === undefined || !chatId) return;
    toggleAddParticipant();
    dispatcher.start(
      AddAddresses(
        chatId,
        items.map((_) => _.chatAddress as ValueObject)
      )
    );
  };

  const onResetConfirmData = () => setConfirmData(null);

  const onConfirmData = () => {
    const operation = confirmData?.operation;

    if (!operation) return onResetConfirmData();

    if (operation === 'delete') onChatDelete();
    if (operation === 'remove') onRemoveParticipant(confirmData.data as AddressInfoVM);
    if (operation === 'leave') onChatLeave();

    onResetConfirmData();
  };

  const getAvatarIcon = (item: AddressInfoVM) => {
    if (item.profilePicture?.url) {
      return (
        <img
          src={configContext.getEndpoint(item.profilePicture.url).toString()}
          alt=""
          style={{
            width: 48,
            height: 48,
            borderRadius: '50%',
          }}
        />
      );
    }

    if (item.type === AddressInfoVMType.Role) {
      return (
        <div className={styles['icon-role-container']}>
          <SVGRoles className={styles['icon-role']} />
        </div>
      );
    }

    return <SvgIconPerson className={styles['icon']} />;
  };

  const getChatType = () => {
    switch (chatDetails?.chatVM.type) {
      case ChatVMType.Group:
        return chatDetails?.chatVM.pageName ?? 'Gruppenchat';
      case ChatVMType.PatientRelated:
        return chatDetails?.chatVM.pageName ?? 'Patientenchat';
      default:
        return chatDetails?.chatVM.pageName;
    }
  };

  return (
    <>
      <IconButton onClick={toggleSettings}>
        <MoreHorizIcon />
      </IconButton>
      {open && (
        <div className={styles['container']}>
          <div className={styles['header']}>
            <div className={styles['title-container']}>
              <div>{getChatType()}</div>
            </div>
            <div className={styles['header-actions']}>
              <ButtonClose onClick={toggleSettings} />
            </div>
          </div>
          <div className={styles['partner-operations-wrapper']}>
            <div className={styles['title-container']}>
              <div className={styles['participants-title']}>
                {addresses.others.length > 1 && <div>{addresses.others.length} Mitglieder</div>}
              </div>
            </div>
          </div>
          <div className={styles['participants-list']}>
            {addresses.others.map((item) => (
              <div className={styles['participant']} key={item.key}>
                <div className={styles['remove-icon']}>
                  <RemoveCircleOutlineOutlinedIcon
                    color="primary"
                    onClick={() =>
                      setConfirmData({
                        title: `Mitglied aus der Gruppe entfernen?`,
                        confirm: 'Mitglied aus der Gruppe entfernen',
                        cancel: 'Abbrechen',
                        data: item,
                        operation: 'remove',
                      })
                    }
                  />
                </div>

                <div className={styles['no-shrink']}>{getAvatarIcon(item)}</div>

                <div className={styles['participant-name']}>
                  <div>{item.name}</div>
                  <div className={styles['role']}>{item.subname}</div>
                </div>
              </div>
            ))}
          </div>

          <div className={styles['actions-bottom-container']}>
            <HighlightButton secondary={true} onClick={toggleAddParticipant}>
              <div className={styles['add-button']}>
                <AddCircleOutlineOutlinedIcon />
                <div>Mitglieder hinzufügen</div>
              </div>
            </HighlightButton>
            {addresses.others.length > 2 && addresses.self && (
              <HighlightButton
                secondary={true}
                onClick={() =>
                  setConfirmData({
                    title: 'Sind Sie sicher, dass Sie diesen Chat verlassen möchten?',
                    confirm: 'Chat Verlassen',
                    cancel: 'Abbrechen',
                    data: addresses.self,
                    operation: 'leave',
                  })
                }
              >
                <div>Chat verlassen</div>
              </HighlightButton>
            )}
            <HighlightButton
              secondary={true}
              onClick={() =>
                setConfirmData({
                  title: 'Sind Sie sicher, dass Sie diesen Chat löschen möchten?',
                  confirm: 'Chat löschen',
                  cancel: 'Abbrechen',
                  data: 'fake data',
                  operation: 'delete',
                })
              }
            >
              <div>Chat löschen</div>
            </HighlightButton>
          </div>
        </div>
      )}

      <SqiorIOSConfirmDialog
        title={confirmData?.title}
        data={confirmData?.data}
        cancel={confirmData?.cancel}
        confirm={confirmData?.confirm}
        operation={confirmData?.operation}
        onConfirm={onConfirmData}
        onCancel={onResetConfirmData}
      />

      {addParticipant && (
        <div className={styles['selection-control']}>
          <AddressSelectionControl
            onClose={toggleAddParticipant}
            hideFab={true}
            simpleSelect
            multiSelect
            data={{ entityType: 'simple component' }}
            onGroupChatSelection={onAddParticipants}
          />
        </div>
      )}
    </>
  );
};

export default ChatSettings;
