import { ClockTimestamp } from '@sqior/js/data';
import { LogBackend } from './log-backend';
import { LogContent, LogData, LogLevel } from './log-data';

export type LogTextData = { level: LogLevel; text: string; timestamp?: ClockTimestamp };

/** Intermediate base class converting the log content into text for further processing */
export abstract class TextLogBackend implements LogBackend {
  abstract logText(data: LogTextData): void;

  /** Implementation of log interface */
  log(data: LogData) {
    /* Convert content into text and pass on */
    this.logText({
      level: data.level,
      text: TextLogBackend.convert(data.content),
      timestamp: data.timestamp,
    });
  }

  /** Converts the logging content into text */
  static convert(content: LogContent) {
    /* Check if this is already text */
    if (typeof content === 'string') return content;
    let text = '';
    for (const item of content)
      text +=
        (text.length > 0 ? ' ' : '') + (typeof item === 'string' ? item : JSON.stringify(item));
    return text;
  }
}
