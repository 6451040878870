/* Verbosity levels */

import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { LanguageEntities } from './language-definitions';

export enum Verbosity {
  Normal = 'normal',
  Short = 'short',
  Verbose = 'verbose',
}

/* Verbosity entity */

export type VerbosityEntity = Entity & { verbosity: Verbosity };
export const VerbosityModel: EntityModel = {
  type: LanguageEntities.Verbosity,
  props: ['verbosity'],
};
export function makeVerbosity(verbosity: Verbosity): VerbosityEntity {
  return { entityType: LanguageEntities.Verbosity, verbosity: verbosity };
}
