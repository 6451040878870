import { Checkbox, SqiorClockProgress } from '@sqior/react/uibase';
import styles from './nursing-complexity-control.module.css';

/* eslint-disable-next-line */
export interface NursingComplexityControlProps {
  score: number;
  onChanged?: (score: number) => void;
}

export function NursingComplexityControl(props: NursingComplexityControlProps) {
  const scores = [1, 2, 3, 4];

  const onClick = (score: number) => {
    props?.onChanged?.(score);
  };

  return (
    <div className={styles['container']}>
      {scores.map((item) => (
        <div className={styles['progress-checked']} key={item.toString()}>
          {item !== props.score && (
            <div style={{ position: 'relative', height: '2em', width: '2em' }}>
              <SqiorClockProgress
                value={item}
                crossColor="#090f23"
                onClick={() => {
                  onClick(item);
                }}
                fontSize={14}
              />
            </div>
          )}
          {item === props.score && (
            <Checkbox
              checked={item === props.score}
              onChanged={(checked) => {
                onClick(checked ? item : 0);
              }}
              label={item.toString()}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default NursingComplexityControl;
