import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { DomainInterface } from './domain-interface';
import { EntityModel, EntityRecord } from './entity';

/* Definition of the basic text entity */

export type TextEntity = Entity & { text: string };
export const TextEntityModel: EntityModel = { type: CoreEntities.Text, props: ['text'] };
export function makeTextEntity(text: string): TextEntity {
  return { entityType: CoreEntities.Text, text: text };
}
export async function extractText(
  domain: DomainInterface,
  entity: Entity,
  context?: EntityRecord
): Promise<string> {
  return (await domain.map<TextEntity>(entity, CoreEntities.Text, context)).text;
}
export async function extractTextOrDefault(
  domain: DomainInterface,
  entity: Entity,
  def: string,
  context?: EntityRecord
): Promise<string> {
  const textEnt = await domain.tryMap<TextEntity>(entity, CoreEntities.Text, context);
  return textEnt ? textEnt.text : def;
}

/* Definition of a text entity with dynamic parameters */

export type TextTemplate = Entity & { text: Entity; params: EntityRecord };
export const TextTemplateModel: EntityModel = {
  type: CoreEntities.TextTemplate,
  props: ['text', 'params'],
};
export function makeTextTemplate(text: string | Entity, params: EntityRecord): TextTemplate {
  return {
    entityType: CoreEntities.TextTemplate,
    text: typeof text === 'string' ? makeTextEntity(text) : text,
    params: params,
  };
}

export async function substituteTextParams(
  text: string,
  func: (param: string, options?: Record<string, string>) => Promise<string>
): Promise<string> {
  let startIndex = 0;
  for (;;) {
    /* Find insertion point */
    let options: Record<string, string> | undefined;
    for (;;) {
      startIndex = text.indexOf('<e', startIndex);
      if (startIndex < 0) return text;
      if (text[startIndex + 2] === '>' || text[startIndex + 2] === ' ') break;
      startIndex += 2;
    }
    let startEndIndex;
    if (text[startIndex + 2] === '>') startEndIndex = startIndex + 3;
    else if (text.substring(startIndex + 2, startIndex + 8) === ' case=') {
      startEndIndex = text.indexOf('>', startIndex + 8);
      if (startIndex < 0)
        throw new Error('Malformed template - option provided but no tag end found: ' + text);
      options = { case: text.substring(startIndex + 8, startEndIndex) };
      startEndIndex++;
    } else
      throw new Error(
        'Malformed template - option provided but no grammatical case found: ' + text
      );
    const endIndex = text.indexOf('</e>', startEndIndex);
    if (endIndex < 0 || endIndex < startIndex)
      throw new Error('Malformed template - not matching start and end tags: ' + text);
    const param = text.substring(startEndIndex, endIndex);
    let subst = '...';
    try {
      subst = await func(param, options);
    } catch (e) {
      /* Not logging here as this would also be used during conversion of the debug logs
      Logger.warn("Exception when substituting parameter: " + param + " - Exception: " + e,
                  "Exception when substituting parameter: " + param + " - Original text: " + text +  " - Exception: " + e, ) */
    }
    const replaceLength = endIndex - startIndex;
    text = text.substring(0, startIndex) + subst + text.substring(endIndex + 4);
    startIndex = endIndex + subst.length - replaceLength;
  }
}

/* Definition of the console entity model */

export type ConsoleTextEntity = Entity & { text: string };
export const ConsoleEntityModel: EntityModel = { type: CoreEntities.Console, props: ['text'] };
export function createConsoleTextEntity(text: string): ConsoleTextEntity {
  return { entityType: CoreEntities.Console, text: text };
}
