import { ClockTimestamp } from '@sqior/js/data';
import { ComponentFactory } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { HighlightButton, SvgCheckBox, SvgTodo } from '@sqior/react/uibase';
import { SelectionPageContext } from '@sqior/react/uiselection';
import {
  WorkflowPathStepLabelType,
  WorkflowPathStepModel,
  WorkflowPathStepState,
} from '@sqior/viewmodels/workflow';
import { InterweaveExt } from '@sqior/react/uibase';
import { useContext, useEffect, useRef } from 'react';
import HorizontalLine from '../horizontal-line/horizontal-line';
import VerticalLine from '../vertical-line/vertical-line';
import { ReactComponent as ProblemIcon } from './problem.svg';
import styles from './workflow-path-step.module.css';
import { classes } from '@sqior/react/utils';
import WarningIcon from '@mui/icons-material/ReportOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export interface WorkflowPathStepProps {
  model: WorkflowPathStepModel;
  topLine: boolean;
  activeTop: boolean;
  bottomLine: boolean;
  timestamp: ClockTimestamp;
  additionalClassName?: string;
}

const WorkflowPathCommentTypes = new Set<string>([
  WorkflowPathStepState.Comment,
  WorkflowPathStepState.AffirmativeComment,
  WorkflowPathStepState.ChallengedComment,
]);

const TextStyles = new Map<string, string>([
  [WorkflowPathStepState.Done, 'active-text'],
  [WorkflowPathStepState.Problem, 'problem-text'],
  [WorkflowPathStepState.Todo, 'text'],
  [WorkflowPathStepState.Comment, 'comment-text'],
  [WorkflowPathStepState.AffirmativeComment, 'icon-affirmative-text'],
  [WorkflowPathStepState.ChallengedComment, 'icon-challenged-text'],
]);

export function WorkflowPathStep(props: WorkflowPathStepProps) {
  const dispatcher = useContext(OperationContext);
  const selContext = useContext(SelectionPageContext);
  const container = useRef<HTMLDivElement>(null);
  const FactoryComponent = useContext(ComponentFactory);

  /* Scroll to the active button, if applicable */
  const stepType = props.model.label.type;
  const stepState = props.model.state;
  useEffect(() => {
    if (
      stepType === WorkflowPathStepLabelType.Button &&
      stepState === WorkflowPathStepState.Todo &&
      container.current
    )
      container.current.scrollIntoView();
  }, [stepType, stepState]);

  return (
    <div ref={container} className={styles['container']}>
      <div className={styles['icon-container']}>
        {props.topLine && (
          <VerticalLine className={styles[props.activeTop ? 'active-line' : 'line']} />
        )}
        {!props.topLine && <div className={styles['line']} />}
        {props.model.state === WorkflowPathStepState.Done && (
          <SvgCheckBox className={styles['active-icon']} />
        )}
        {props.model.state === WorkflowPathStepState.Problem && (
          <ProblemIcon className={styles['problem-icon']} />
        )}
        {props.model.state === WorkflowPathStepState.Todo && <SvgTodo className={styles['icon']} />}
        {props.bottomLine && !WorkflowPathCommentTypes.has(props.model.state) && (
          <VerticalLine
            className={
              styles[props.model.state === WorkflowPathStepState.Done ? 'active-line' : 'line']
            }
          />
        )}
        {!props.bottomLine && !WorkflowPathCommentTypes.has(props.model.state) && (
          <div className={styles['line']} />
        )}
      </div>
      <div className={classes(styles['text-container'], props.additionalClassName)}>
        {props.model.state === WorkflowPathStepState.Problem && (
          <HorizontalLine className={styles['problem-line']} />
        )}
        {props.model.state === WorkflowPathStepState.AffirmativeComment && (
          <DoneAllIcon className={styles['doneall-icon-small']} />
        )}
        {props.model.state === WorkflowPathStepState.ChallengedComment && (
          <WarningIcon className={styles['problem-icon-small']} />
        )}
        {props.model.label.type === WorkflowPathStepLabelType.Text && (
          <InterweaveExt
            className={styles[TextStyles.get(props.model.state) ?? '']}
            content={props.model.label.text}
          />
        )}
        {props.model.label.type === WorkflowPathStepLabelType.Text &&
          props.model.label.addText &&
          props.model.label.addText.length > 0 && (
            <span className={styles['add-text']}>{props.model.label.addText}</span>
          )}
        {props.model.label.type === WorkflowPathStepLabelType.Button && (
          <HighlightButton
            animated={true}
            additionalClassName={styles['button']}
            disabled={props.model.label.pending}
            secondary={props.model.state !== WorkflowPathStepState.Todo}
            onClick={() => {
              if (props.model.label.operation)
                if ('entityType' in props.model.label.operation)
                  selContext(props.model.label.operation, () => {
                    /* nothing to do */
                  });
                else dispatcher.start(props.model.label.operation);
            }}
          >
            {props.model.label.text}
          </HighlightButton>
        )}
        {props.model.label.type === WorkflowPathStepLabelType.Custom && props.model.label.custom && (
          <div className={styles['custom-container']}>
            <FactoryComponent data={props.model.label.custom} />
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkflowPathStep;
