import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { PersonSex } from '@sqior/viewmodels/person';
import { CaveInfoVM, CaveSeverity } from './cave-info-vm';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { SpecialtyVM } from './specialty-vm';

export const PatientOverviewPath = 'patient-overview';
export const PagePatientOverviewType = 'page.patient-overview';

export type PatientInfo = Entity & {
  name: string;
  id?: string;
  dob?: string;
  sex?: PersonSex;
  spi?: number;
  spiColorCode?: ColorCodes;
  caveSeverity?: CaveSeverity;
  caveInfos?: CaveInfoVM[];
  insurance?: string;
  specialty?: SpecialtyVM;
};

export type PatientOverview = {
  info: PatientInfo;
  content?: Entity;
};

/* Command when a patient overview is selected or closed */

export const SelectPatientPath = joinPath(PatientOverviewPath, 'select-patient');

export function SelectPatient(overview: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: SelectPatientPath, data: overview };
}
export function ClosePatient(): OperationSpec<string> {
  return { type: OperationType.Delete, path: SelectPatientPath, data: 'patient' };
}
