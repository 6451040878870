import { ConsoleLogger } from './console-logger';
import { LogLevel } from './log-data';
import { LogInterface } from './log-interface';
import { StdLogger } from './std-logger';

/** Global logging object to use */
export let Logger: LogInterface = new StdLogger({ level: LogLevel.Info }, new ConsoleLogger());

/** Sets the global logging instance */
export function SetLogger(logger: LogInterface) {
  Logger = logger;
}
