import { isSubsetEqual, Value } from './value';

export type Predicate<Type> = (first: Type, second: Type) => boolean;
export function lessThanPred<Type>(a: Type, b: Type) {
  return a < b;
}

export class SortedArray {
  /* Performs binary search in a sorted array - returns the index of the first element that is not less then the
     provided value based on the provided predicate - returns the length of the array if there is no such element*/
  static findFirstGreaterOrEqual<Type = unknown>(
    array: Type[],
    value: Type,
    lessThan: Predicate<Type>,
    start = 0,
    size = array.length
  ) {
    /* Loop until search range is empty */
    while (size > 0) {
      /* Define element for comparison, it is the middle element for uneven sizes or the one behind the middle for even sizes */
      const inc = Math.floor(size / 2);
      if (lessThan(array[start + inc], value))
        if (inc === 0)
          /* if the middle element is still smaller, we will focus on the second half */
          return start + 1;
        else {
          start += inc;
          size -= inc;
        }
      /* Focus on the first half */ else size = inc;
    }
    return start;
  }

  /* Inserts a value into a sorted array according to the less than predicate */
  static insert<Type = unknown>(array: Type[], value: Type, lessThan: Predicate<Type>) {
    /* Find insertion point by binary search */
    const index = this.findFirstGreaterOrEqual(array, value, lessThan);
    array.splice(index, 0, value);
  }

  /* Modifies a specified value of sorted array according to the less than predicate */
  static modify<Type = unknown>(
    array: Type[],
    index: number,
    value: Type,
    lessThan: Predicate<Type>
  ) {
    /* Check if the value is to be found before the current index */
    if (lessThan(value, array[index])) {
      if (index > 0) {
        const insPos = this.findFirstGreaterOrEqual(array, value, lessThan, 0, index - 1);
        if (insPos < index) {
          array.splice(index, 1);
          array.splice(insPos, 0, value);
          return;
        }
      }
    } else if (lessThan(array[index], value)) {
    /* Check if the value is to be found after the current index */
      const insPos = this.findFirstGreaterOrEqual(
        array,
        value,
        lessThan,
        index + 1,
        array.length - index - 1
      );
      if (insPos > index + 1) {
        array.splice(insPos, 0, value);
        array.splice(index, 1);
        return;
      }
    }

    /* Replace at its current position */
    array[index] = value;
  }

  /* Finds an element index by a template from a sorted array according to the less than predicate, returns -1 if not found */
  static findExact<SubType extends Value, Type extends SubType = SubType>(
    array: Type[],
    query: SubType,
    lessThan: Predicate<SubType>
  ) {
    /* Find start point by binary search */
    let index = this.findFirstGreaterOrEqual(array, query, lessThan);

    /* There could be multiple matches according to the ordering predicate, go through all matches to find exact */
    for (; index < array.length && !lessThan(query, array[index]); index++)
      if (isSubsetEqual(query, array[index])) return index;

    return -1;
  }
}
