import styles from './warn-specialties-chart.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { WardSpecialtiesStatsInfoEntity } from '@sqior/viewmodels/occupancy';
import BedChartStatistic from '../ward-overview-dashboard/components/bed-chart-statistic/bed-chart-statistic';
import FreeBedsSexChart from '../free-beds-sex-chart/free-beds-sex-chart';

export type WarnSpecialtiesChartProps = FactoryProps<WardSpecialtiesStatsInfoEntity>;

export function WarnSpecialtiesChart(props: WarnSpecialtiesChartProps) {
  return (
    <div className={styles['container']}>
      {/*       <FreeBedsSexChart
        male={props.data.totals.freeBedsLaterMale}
        female={props.data.totals.freeBedsLaterFemale}
        diverse={props.data.totals.freeBedsLaterUnisex}
      />
 */}
      <BedChartStatistic data={props.data} />
      <div>
        <span>Freie Betten nach Belegung: </span>
        <span
          style={
            props.data.totals.freeBedsLaterWarning
              ? {
                  color: '#f9c80e',
                }
              : undefined
          }
        >
          {props.data.totals.freeBedsLater}
        </span>
      </div>
    </div>
  );
}

export default WarnSpecialtiesChart;
