import { SqiorTooltip } from '@sqior/react/uibase';
import { BedAllocationWardListItemBed } from '@sqior/viewmodels/occupancy';
import { useIsTablet } from '@sqior/react/hooks';
import { FC, useContext, useState } from 'react';
import PillTooltipTitle from './components/pill-tooltip-title';
import { OperationContext } from '@sqior/react/operation';
import PillIcon from './components/pill-icon';
import MultiPillIcon from './components/multi-pill-icon';
import { OperationSpec } from '@sqior/js/operation';
import { ValueObject } from '@sqior/js/data';
import { Badge, BadgeProps, styled } from '@mui/material';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 3,
    width: 10,
    height: 10,
    borderRadius: 100,
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  },
}));

const createSelectionForOtherPatient = (bed: BedAllocationWardListItemBed) => {
  if (!hasDoubleAllocation(bed)) return null;
  if (!bed.other[0].id) return null;
  const otherPatientId = getOtherPatientId(bed);
  if (!otherPatientId) return null;
  if (!bed.select?.data) return null;

  const select: OperationSpec<ValueObject> = {
    ...bed.select,
    data: { ...bed.select.data, id: otherPatientId },
  };

  return select;
};

const hasDoubleAllocation = (bed: BedAllocationWardListItemBed) => bed.other.length > 0;
const getOtherPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.other[0]?.id;
const getCurrentPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.current.patient?.id;

export interface Props {
  bed: BedAllocationWardListItemBed;
  disableTooltip?: boolean;
  backgroundColor?: string;
}

export const PillView: FC<Props> = ({ bed, disableTooltip, backgroundColor }) => {
  const dispatcher = useContext(OperationContext);
  const isTablet = useIsTablet();

  const [selectedId, setSelectedId] = useState<string>('');

  const onBedClick = () => {
    const currentPatientId = getCurrentPatientId(bed);
    const otherPatientId = getOtherPatientId(bed);

    if (hasDoubleAllocation(bed) && otherPatientId && selectedId === currentPatientId) {
      const otherSelect = createSelectionForOtherPatient(bed);
      if (!otherSelect) return;
      setSelectedId(otherPatientId);
      dispatcher.start(otherSelect);
      return;
    }
    if (!currentPatientId || !bed.select) return;
    setSelectedId(currentPatientId);
    dispatcher.start(bed.select);
  };

  // Use this flag to make a annotated bullet...
  const annotated = bed.current.dischargeInfo?.annotated === true;

  return (
    <SqiorTooltip
      disableHoverListener={disableTooltip}
      disableTouchListener={isTablet}
      enterTouchDelay={0}
      placement="bottom"
      title={<PillTooltipTitle bed={bed} />}
      PopperProps={{
        style: {
          pointerEvents: 'none',
        },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, isTablet ? -18 : -8],
            },
          },
        ],
      }}
    >
      <StyledBadge color="secondary" variant="dot" invisible={!annotated}>
        <div onClick={onBedClick} style={{ cursor: 'pointer', backgroundColor, width: 64, height: 20, borderRadius: 10 }}>
          {hasDoubleAllocation(bed) ? <MultiPillIcon bed={bed} /> : <PillIcon bed={bed} />}
        </div>
      </StyledBadge>
    </SqiorTooltip>
  );
};

export default PillView;
