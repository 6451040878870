import { OccupancyDashboardSelect, OccupancyDashboardVM } from '@sqior/viewmodels/occupancy';
import styles from './occupancy-dashboard.module.css';
import { ComponentFactory, FactoryProps } from '@sqior/react/factory';
import React, { useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { Checkbox, Menu, MenuItem, SelectChangeEvent } from '@mui/material';
import { SqiorSelect, SvgAnzeige } from '@sqior/react/uibase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useUIGlobalState } from '@sqior/react/state';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { motion } from 'framer-motion';

const getCurrentSelectedWard = (data: OccupancyDashboardVM) => {
  if (data.currentWardSelection) {
    return data.currentWardSelection.wards[data.currentWardSelection.selected];
  }
  return undefined;
};

/* eslint-disable-next-line */
export type OccupancyDashboardProps = FactoryProps<OccupancyDashboardVM>;

export function OccupancyDashboard(props: OccupancyDashboardProps) {
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const FactoryComponent = useContext(ComponentFactory);
  const dispatcher = useContext(OperationContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedWard = getCurrentSelectedWard(props.data);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    dispatcher.start(OccupancyDashboardSelect(value));
  };

  const onOpenSelection = (event: React.MouseEvent) => {
    if (selectedWard?.id === '123') return;
    const htmlElement = event.currentTarget as HTMLElement;
    setAnchorEl(htmlElement);
  };

  const onCloseSelection = () => {
    setAnchorEl(null);
  };

  const onSpiToggle = () => {
    setUIGlobalState((prev) => ({
      ...prev,
      bmShowSpi: !prev.bmShowSpi,
    }));
  };

  const onInsuranceToggle = () => {
    setUIGlobalState((prev) => ({
      ...prev,
      bmShowInsurance: !prev.bmShowInsurance,
    }));
  };

  const onToggleRightDrawer = () => {
    if (selectedWard?.id === '123') return;
    setUIGlobalState((prev) => ({
      ...prev,
      bmRightDrawerOpen: !prev.bmRightDrawerOpen,
    }));
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseSelection}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div>
          <MenuItem onClick={onInsuranceToggle}>
            <div className={styles['menu-item']}>
              <div>Versicherung</div>
              <Checkbox checked={UIGlobalState.bmShowInsurance} />
            </div>
          </MenuItem>
          <MenuItem onClick={onSpiToggle}>
            <div className={styles['menu-item']}>
              <div>SPI</div>
              <Checkbox checked={UIGlobalState.bmShowSpi} />
            </div>
          </MenuItem>
        </div>
      </Menu>
      <div className={styles['container']}>
        <div className={styles['header']}>
          {props.data.currentWardSelection?.wards && selectedWard?.id && (
            <SqiorSelect value={selectedWard.id} onChange={handleChange}>
              {props.data.currentWardSelection.wards.map((w) => (
                <MenuItem key={w.id} value={w.id}>
                  {w.label}
                </MenuItem>
              ))}
            </SqiorSelect>
          )}

          <div
            className={styles['multiselect-button']}
            onClick={onOpenSelection}
            style={{ opacity: selectedWard?.id === '123' ? 0.5 : 1 }}
          >
            <SvgAnzeige
              style={{
                width: 18,
                height: 18,
              }}
            />
            <div>Anzeige</div>
            {open ? (
              <ArrowDropUpIcon style={{ color: '#51535E' }} />
            ) : (
              <ArrowDropDownIcon style={{ color: '#51535E' }} />
            )}
          </div>

          <motion.div
            onClick={onToggleRightDrawer}
            whileTap={{ scale: 0.9 }}
            className={styles['multiselect-button']}
            style={{
              padding: 10,
              justifyContent: 'center',
              opacity: selectedWard?.id === '123' ? 0.5 : 1,
            }}
          >
            {UIGlobalState.bmRightDrawerOpen ? <CloseRoundedIcon /> : <MoreHorizIcon />}
          </motion.div>
        </div>

        {props.data.mainDashboard && <FactoryComponent data={props.data.mainDashboard} />}
      </div>
    </>
  );
}

export default OccupancyDashboard;
