import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { UserEntities } from './user-definitions';

export type RoleUser = Entity & { role: Entity; user: Entity };
export const RoleUserModel: EntityModel = {
  type: UserEntities.RoleUser,
  props: ['role', 'user'],
  keys: ['role', 'user'],
};
export function makeRoleUser(role: Entity, user: Entity): RoleUser {
  return { entityType: UserEntities.RoleUser, role: role, user: user };
}
