import { ExternalPromise } from '@sqior/js/async';
import { LogicalTimestamp, Value, ValueObject, ValueOrNothing } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';

export enum OperationType {
  Add = 'add',
  Stream = 'stream',
  Read = 'read',
  Delete = 'delete',
}

export enum OperationState {
  NotStarted,
  Running,
  Completed,
  Failed,
}
export function isFinal(state: OperationState) {
  return state === OperationState.Completed || state === OperationState.Failed;
}

export type OperationSpec<Type = Value> = { type: string; path: string; data: Type };
export type OperationStateJSON = ValueObject & {
  state: OperationState;
  timestamp?: LogicalTimestamp;
};

export class Operation {
  constructor(type: string, contentFinal: boolean) {
    this.type = type;
    this.state = OperationState.NotStarted;
    this.error = null;
    this.progress = 0;
    this.contentFinal = contentFinal;
    this.stateChange = new Emitter<[OperationState]>();
    this.progressChange = new Emitter<[number]>();
    this.contentChange = new Emitter<[boolean]>();
  }

  setState(state: OperationState) {
    /* Check validity */
    if (state === this.state) return;
    if (isFinal(this.state)) throw new Error('Operation state cannot be modified once finished');
    if (state === OperationState.NotStarted)
      throw new Error('Operation state cannot be reset to not started once running or finished');

    /* Progress if this is a final state */
    if (isFinal(state)) this.setProgress(100);

    /* Remember state */
    this.state = state;

    /* Call listeners */
    this.stateChange.emit(state);
  }

  complete(timestamp: LogicalTimestamp) {
    this.completionTimestamp = timestamp;
    this.setState(OperationState.Completed);
  }

  completion(): Promise<void> {
    const exprom = new ExternalPromise();
    this.stateChange.on((state) => {
      if (state === OperationState.Completed) exprom.resolve();
      else if (state == OperationState.Failed) exprom.reject(this.error);
    });
    return exprom.promise;
  }

  fail(err: Error | undefined = undefined) {
    this.error = err;
    this.setState(OperationState.Failed);
  }

  setProgress(progress: number) {
    /* Check for an actual change */
    if (progress === this.progress) return;

    /* Remember progress */
    this.progress = progress;

    /* Inform listeners */
    this.progressChange.emit(progress);
  }

  toJSON(): ValueOrNothing {
    return undefined;
  }
  resultToJSON(): OperationStateJSON {
    const res: OperationStateJSON = { state: this.state };
    if (this.completionTimestamp) res.timestamp = this.completionTimestamp;
    return res;
  }
  completeFromJSON(msg: OperationStateJSON) {
    this.completionTimestamp = msg.timestamp;
    this.setState(msg.state);
  }

  protected contentChanged(final: boolean) {
    this.contentFinal = final;
    this.contentChange.emit(final);
  }

  readonly type: string;
  state: OperationState;
  error: Error | null | undefined;
  progress: number;
  contentFinal: boolean;

  completionTimestamp?: LogicalTimestamp;

  readonly stateChange: Emitter<[OperationState]>;
  readonly progressChange: Emitter<[number]>;
  readonly contentChange: Emitter<[boolean]>;
}
