import styles from './app-menu.module.css';
import { ReactComponent as MenuButton } from './button-menu.svg';

/* eslint-disable-next-line */
export interface AppMenuProps {
  children?: React.ReactNode;
  onClick: () => void;
}

export function AppMenu(props: AppMenuProps) {
  return <MenuButton className={styles['button']} onClick={props.onClick} viewBox="0 0 50 50" />;
}

export default AppMenu;
